import React from 'react';
import { Route } from 'react-router-dom';

import Comments from '../containers/comments/Comments';

const CommentRoutes = () => {
  return (
    <>
      <Route exact={true} path="/comments/:courseId" component={Comments} />
    </>
  );
};

export default CommentRoutes;
