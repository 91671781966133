import React from 'react';
import { Divider, Table } from 'antd';
import MapExistingClinicModal from './OrganizationMapExistingClinicModal';
import AddNewClinic from './OrganizationCreateNewClinic';
import { Link } from 'react-router-dom';

const columns = [
  {
    title: 'Clinic Name',
    dataIndex: 'name',
    key: 'name',
    render: (clinic, record) => {
      return (
        <>
          <Link to={`/group-management/group/${record.id}`}>{record.name}</Link>{' '}
        </>
      );
    }
  },
  {
    title: 'Subscriptions',
    dataIndex: 'subscriptions',
    key: 'subscriptions'
  },
  {
    title: 'Members',
    dataIndex: 'members',
    key: 'members'
  },
  {
    title: 'User Added',
    dataIndex: 'userAdded',
    key: 'userAdded'
  }
];

function OrganizationClinicsSection(props) {
  const {
    clinics,
    organizationId,
    preselectedClinics,
    refreshParent,
    manager,
    managers,
    orgType //MEDICAL OR EDUCATIONAL
  } = props;

  if (orgType !== 'MEDICAL') {
    columns[0].title = 'Cohort Name';
  }

  return (
    <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
      <h6 style={{ fontWeight: 'bold' }}>
        {orgType === 'MEDICAL' ? 'Clinic' : 'Cohort'}
        <br />
      </h6>
      <Table columns={columns} dataSource={preselectedClinics} />
      <Divider />
      <MapExistingClinicModal
        preselectedClinics={preselectedClinics}
        refreshParent={refreshParent}
        organizationId={organizationId}
        clinics={clinics}
        orgType={orgType}
      />
      <span style={{ marginLeft: '2rem' }}>
        <AddNewClinic
          manager={manager}
          managers={managers}
          refreshParent={refreshParent}
          organizationId={organizationId}
          orgType={orgType}
        />
      </span>
      <h6 style={{ fontStyle: 'italic', marginTop: '1rem' }}>
        All changes are automatically saved
      </h6>
      <Divider />
    </div>
  );
}

export default OrganizationClinicsSection;
