import React from 'react';
import { useHistory } from 'react-router-dom';

import BreadcrumbConstants from '../../../routes/BreadcrumbConstants';
import QuestionAddEdit from './QuestionAddEdit';
import FillMainView from '../../../components/FillMainView';

import './QuestionView.css';

export default function QuestionView({ location }) {
  let isNew = false;
  if (location && location.state && location.state.isNew) {
    isNew = location.state.isNew;
  }
  const history = useHistory();

  const questionsBreadcrumb = [
    BreadcrumbConstants.HippoAdmin,
    BreadcrumbConstants.Content,
    BreadcrumbConstants.Questions,
    {
      to: '/content/questions',
      title: isNew ? 'Add New Question' : 'Question Detail'
    }
  ];

  const questionId = location.pathname.replace('/content/questions/', '');

  return (
    <FillMainView
      onClose={() => history.push('/content/questions')}
      closeButtonText="Back to Qbank & Assessment Questions"
      breadcrumbData={questionsBreadcrumb}
    >
      <QuestionAddEdit
        questionId={questionId}
        isNew={isNew}
        onSave={() => history.push('/content/questions')}
      />
    </FillMainView>
  );
}
