import { gql } from '@apollo/client';

const UPDATE_PRICE_MUTATION = gql`
  mutation UpdatePrice($id: UUID!, $price: BigFloat!, $updatedAt: Datetime) {
    updatePrice(
      input: { patch: { price: $price, updatedAt: $updatedAt }, id: $id }
    ) {
      clientMutationId
    }
  }
`;

export default UPDATE_PRICE_MUTATION;
