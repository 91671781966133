import React, { useState } from 'react';
import { SendOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';

import * as API from '../API';

export default function SendResetPasswordUrlButton({
  accountEmail,
  onSuccess
}) {
  const [sending, setSending] = useState(false);

  const handleCopyResetPasswordUrl = async () => {
    setSending(true);
    const res = await API.raw('POST', 'api/account/forgot-password', {
      email: accountEmail
    });
    notification.success({
      message: `Password reset email sent to ${accountEmail}`
    });
    setSending(false);
    onSuccess && onSuccess(res.data.resetUrl);
  };

  return (
    <Button
      icon={<SendOutlined />}
      size="small"
      title="Send Reset Password Email"
      className="account__forgot-password-button"
      onClick={handleCopyResetPasswordUrl}
    >
      {sending ? 'Sending Reset Email...' : 'Send Password Reset Email'}
    </Button>
  );
}
