import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Alert, Button, message } from 'antd';
import { Label } from 'reactstrap';

import * as API from '../../API';
import AttachmentCourseGuideItem from './AttachmentCourseGuideItem';
import AttachmentUpload from '../Upload/AttachmentUpload';
import { useFileUpload } from '../../containers/attachments/hooks/useAttachmentHooks';

import './AttachmentsInput.css';

const AttachmentsCourseGuideInput = ({
  attachments,
  onCreateItem,
  onUpdateItem,
  updateActivatedAt,
  updateExpiredAt,
  onDeleteItem,
  showCmeFields,
  canEdit
}) => {
  const defaultFileData = {
    key: '',
    fileName: '',
    fileSize: '',
    mimeType: '',
    isContentSummary: false
  };
  const [addingNew, setAddingNew] = useState(false);
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [activityTimeInSeconds, setActivityTimeInSeconds] = useState(300);
  const [isContentSummary, setIsContentSummary] = useState(
    showCmeFields && !attachments.length > 0
  );
  const [fileData, setFileData] = useState(defaultFileData);
  const [newAttachmentError, setNewAttachmentError] = useState('');
  const handleFileUpload = useFileUpload(setFileData, { isContentSummary });

  const resetForm = () => {
    setTitle('');
    setSubtitle('');
    setActivityTimeInSeconds(300);
    setIsContentSummary(showCmeFields && !attachments.length > 0);
    setFileData(defaultFileData);
    setNewAttachmentError('');
  };

  const deleteAttachment = id => API.attachment.delete({ id });

  const createNewAttachment = async () => {
    try {
      setNewAttachmentError('');
      const attachmentData = await API.attachment.create({
        attributes: {
          title,
          subtitle,
          activityTimeInSeconds: showCmeFields ? activityTimeInSeconds : 0,
          isContentSummary: showCmeFields ? isContentSummary : false,
          ...fileData
        },
        relationships: {}
      });

      const attachment = (await attachmentData).data;
      resetForm();
      setAddingNew(false);
      if (onCreateItem) {
        onCreateItem(attachment.id);
        message.success(`Course attachment "${title}" saved successfully.`);
      }
    } catch (e) {
      message.error(
        title === ''
          ? 'Please include a title for the attachment.'
          : 'Error while updating attachment.',
        2
      );
    }
  };

  return (
    <div>
      <div>
        {attachments &&
          attachments.map(item => {
            const attachment = item.attachment;
            const courseAttachmentBinding = {
              id: item.id,
              activatedAt: item.activatedAt,
              expiredAt: item.expiredAt
            };
            return (
              <AttachmentCourseGuideItem
                key={attachment.id}
                item={attachment}
                courseAttachmentBinding={courseAttachmentBinding}
                onAttachmentUpdate={onUpdateItem}
                updateActivatedAt={updateActivatedAt}
                updateExpiredAt={updateExpiredAt}
                onDelete={async () => {
                  await deleteAttachment(attachment.id);
                  if (onDeleteItem) {
                    onDeleteItem(item);
                  }
                }}
                showCmeFields={showCmeFields}
                canEdit={canEdit}
              />
            );
          })}
        <div className="new-attachment-form">
          {addingNew && (
            <>
              <div className="new-attachment-form__title">
                <Label>Title: </Label>
                <input
                  onChange={e => setTitle(e.target.value)}
                  className="new-attachment-form__title-input"
                />
              </div>
              <AttachmentUpload
                filename={fileData.fileName}
                canEdit={canEdit}
                onComplete={handleFileUpload}
              />
              {newAttachmentError && (
                <Alert
                  message={newAttachmentError}
                  type="error"
                  showIcon={true}
                />
              )}
            </>
          )}
          <span className="attachments-input__buttons">
            {addingNew && (
              <Button onClick={() => createNewAttachment()}>Save</Button>
            )}
            {canEdit && (
              <Button
                onClick={() => {
                  resetForm();
                  setAddingNew(!addingNew);
                }}
              >
                {addingNew ? 'Close' : 'New Attachment'}
              </Button>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

AttachmentsCourseGuideInput.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      key: PropTypes.string
    })
  ),
  onCreateItem: PropTypes.func,
  onUpdateItem: PropTypes.func,
  onDeleteItem: PropTypes.func,
  showCmeFields: PropTypes.bool,
  canEdit: PropTypes.bool
};

AttachmentsCourseGuideInput.defaultProps = {
  attachments: [],
  onCreateItem: null,
  onUpdateItem: null,
  onDeleteItem: null,
  showCmeFields: false,
  canEdit: false
};

export default AttachmentsCourseGuideInput;
