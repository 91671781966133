import React from 'react';
import { Route } from 'react-router-dom';

import EvaluationReport from '../containers/report/EvaluationReport';
import AcepReport from '../containers/report/AcepReport';

const ReportRoutes = () => {
  return (
    <>
      <Route path="/report/evaluation" component={EvaluationReport} />
      <Route path="/report/acep" component={AcepReport} />
    </>
  );
};

export default ReportRoutes;
