export const CourseTypeTitles = {
  QBANK: 'QBank',
  ASSESSMENT: 'Assessment',
  PRACTICE_EXAM: 'Practice Exam',
  REFERENCE_GUIDE: 'Reference Guides',
  PODCAST: 'Podcast',
  VIDEO: 'Video',
  BOOTCAMP_ASSESSMENT: 'Bootcamp Assessment',
  OTHER: 'Other'
};
