import React, { useEffect, useState } from 'react';
import { Collapse, Badge, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import BreadcrumbConstants from '../../routes/BreadcrumbConstants';
import NewCourseModal from './NewCourseModal';
import CourseItems from './CourseItems';
import ContentHeader from '../content/ContentHeader';
import { useQuery } from '@apollo/client';
import GET_PRODUCT_ITEMS_QUERY from './queries/GetProductItemsQuery';

import './products.css';

const { Panel } = Collapse;

export default function Products() {
  const [productItems, setProductItems] = useState([]);
  const [showingNewCourseModal, setShowingNewCourseModal] = useState(false);

  const { data } = useQuery(GET_PRODUCT_ITEMS_QUERY);

  useEffect(() => {
    if (data && data.itemsList) {
      const productCourseItems = data.productsList
        .reduce((productCourseItem, product) => {
          const courseAndItems = data.coursesList
            .filter(course => course.product.id === product.id)
            .reduce((courseAndItems, course) => {
              const courseItems = data.itemsList.filter(
                item => item.course && course.id === item.course.id
              );
              return courseItems.length > 0
                ? [
                    ...courseAndItems,
                    {
                      ...course,
                      items: courseItems
                    }
                  ]
                : courseAndItems;
            }, []);

          if (courseAndItems.length > 0) {
            productCourseItem = [
              ...productCourseItem,
              {
                ...product,
                courses: courseAndItems
              }
            ];
          }
          return productCourseItem;
        }, [])
        .sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0));
      setProductItems(productCourseItems);
    }
  }, [data]);

  return (
    <div>
      <div className="products__header">
        <ContentHeader
          breadCrumb={[
            BreadcrumbConstants.HippoAdmin,
            BreadcrumbConstants.Products
          ]}
          hasTitle={true}
          title="Products"
        />
        <Button
          className="products__create-button"
          icon={<PlusOutlined high={true} style={{ paddingBottom: '4px' }} />}
          onClick={() => {
            setShowingNewCourseModal(!showingNewCourseModal);
          }}
        >
          Create New Course
        </Button>
        <NewCourseModal
          visible={showingNewCourseModal}
          products={productItems}
          title="Create a New Course"
          onClose={() => setShowingNewCourseModal(false)}
        />
      </div>
      <Collapse>
        {productItems &&
          productItems.map((product, index) => {
            return (
              product.courses.length > 0 && (
                <Panel
                  key={product.id}
                  header={
                    <span>
                      {`${product.title} `}
                      <Badge
                        showZero={true}
                        count={`${product.courses.length} Courses`}
                        style={{ backgroundColor: '#2d89f3' }}
                      />
                    </span>
                  }
                >
                  <div className="products__course-list">
                    {product.courses.map(course => {
                      return (
                        course.items.length > 0 && (
                          <CourseItems
                            key={course.id}
                            course={course}
                            verticalId={product.vertical.id}
                          />
                        )
                      );
                    })}
                  </div>
                </Panel>
              )
            );
          })}
      </Collapse>
    </div>
  );
}
