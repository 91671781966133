import { CopyOutlined } from '@ant-design/icons';
import { Button, Input, notification } from 'antd';
import React, { useMemo } from 'react';

export default function CopyPasswordResetUrl({
  passwordTag,
  passwordTagExpiredAt
}) {
  const link = useMemo(
    () =>
      `${process.env.REACT_APP_JSONAPI_SERVER}/sign-in/reset-password/${passwordTag}`,
    [passwordTag]
  );
  const [copied, setCopied] = React.useState(false);

  const doCopy = async () => {
    await navigator.clipboard.writeText(link);
    if (!copied) {
      notification.success({
        message: 'Password reset URL copied to clipboard'
      });
      setCopied(true);
      setTimeout(() => setCopied(false), 5000);
    }
  };

  return (
    <div>
      <label htmlFor="reset-password-url">
        Reset Password URL -{' '}
        <strong style={{ color: 'red' }}>
          Expires{' '}
          {new Intl.DateTimeFormat(undefined, {
            dateStyle: 'short'
          }).format(new Date(passwordTagExpiredAt))}
        </strong>
      </label>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Input
          id="reset-password-url"
          style={{ backgroundColor: '#dfe8f2' }}
          contentEditable={false}
          onClick={doCopy}
          value={link}
        />
        <Button
          icon={<CopyOutlined />}
          size="large"
          title="Copy Reset Password URL"
          onClick={doCopy}
        />
      </div>
    </div>
  );
}
