import { v4 as uuidv4 } from 'uuid';
import { notify } from 'react-notify-toast';
const handleNewSection = async (
  newSectionTitle,
  courseId,
  sectionsData,
  createAttachmentSection,
  createCourseAttachmentSectionBinding,
  setShowNewSectionModal,
  setNewSectionTitle,
  refetch
) => {
  if (!newSectionTitle) {
    notify.show('Section title cannot be empty', 'error');
    return;
  }

  try {
    const displayOrder = sectionsData.length ?? 0;

    const { data: newSection } = await createAttachmentSection({
      variables: {
        id: uuidv4(),
        title: newSectionTitle,
        displayOrder,
        createdAt: new Date(),
        updatedAt: new Date()
      }
    });

    const attachmentSection =
      newSection.createAttachmentSection.attachmentSection;
    await createCourseAttachmentSectionBinding({
      variables: {
        id: uuidv4(),
        CourseId: courseId,
        AttachmentSectionId: attachmentSection.id,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      }
    });

    setShowNewSectionModal(false);
    setNewSectionTitle('');
    await refetch();
  } catch (error) {
    notify.show(`Error creating new section: ${error.message}`, 'error');
  }
};

const handleDeleteSection = async (
  sectionId,
  deleteAttachmentSection,
  refetch
) => {
  if (!window.confirm('Are you sure you want to delete this section?')) {
    return;
  }

  try {
    await deleteAttachmentSection({ variables: { id: sectionId } });
    await refetch();
  } catch (error) {
    notify.show(
      `Error deleting section ${sectionId}: ${error.message}`,
      'error'
    );
  }
};

const handleDateChange = async (
  bindingId,
  field,
  date,
  updateCourseAttachmentBinding,
  refetch
) => {
  const updatedAt = new Date().toISOString();
  const patch = { [field]: date, updatedAt };

  try {
    await updateCourseAttachmentBinding({
      variables: {
        id: bindingId,
        ...patch
      }
    });
    await refetch();
  } catch (error) {
    notify.show(`Error updating date: ${error.message}`, 'error');
  }
};

const handleCheckboxChange = async (
  attachmentId,
  field,
  checked,
  updateAttachment,
  refetch
) => {
  const patch = { [field]: checked };
  try {
    await updateAttachment({
      variables: {
        id: attachmentId,
        ...patch
      }
    });
    await refetch();
  } catch (error) {
    notify.show(`Error updating checkbox: ${error.message}`, 'error');
  }
};

const handleDeleteAttachment = async (
  attachmentToDelete,
  deleteAttachment,
  deleteCourseAttachmentBinding,
  refetch
) => {
  if (!window.confirm('Are you sure you want to delete this Attachment?')) {
    return;
  }

  const binding =
    attachmentToDelete.courseAttachmentBindingsList &&
    attachmentToDelete.courseAttachmentBindingsList[0];

  if (binding) {
    try {
      await deleteCourseAttachmentBinding({ variables: { id: binding.id } });
    } catch (error) {
      notify.show(
        `Error deleting attachment binding: ${error.message}`,
        'error'
      );
      return;
    }
  }

  try {
    await deleteAttachment({ variables: { id: attachmentToDelete.id } });
    await refetch();
  } catch (error) {
    notify.show(`Error deleting attachment: ${error.message}`, 'error');
  }
};

const handleCreateAttachment = async (
  sectionId,
  attachmentId,
  sectionsData,
  courseId,
  updateAttachment,
  createCourseAttachmentBinding,
  refetch
) => {
  const section = sectionId
    ? sectionsData.find(section => section.id === sectionId)
    : null;

  const positionWithinSection = section ? section.attachments.length : null;

  const createdAt = new Date().toISOString();
  const updatedAt = createdAt;

  try {
    const attachmentSectionId = sectionId !== 'unsectioned' ? sectionId : null;
    await updateAttachment({
      variables: {
        id: attachmentId,
        attachmentSectionId,
        positionWithinSection,
        updatedAt
      }
    });

    await createCourseAttachmentBinding({
      variables: {
        id: uuidv4(),
        courseId,
        attachmentId,
        createdAt,
        updatedAt
      }
    });

    await refetch();
  } catch (error) {
    notify.show(`Error creating attachment: ${error.message}`, 'error');
  }
};

const handleUpdateAttachmentSection = async (
  updateVariables,
  updateAttachmentSection,
  refetch
) => {
  try {
    await updateAttachmentSection(updateVariables);
    await refetch();
  } catch (error) {
    notify.show(`Error updating attachment section: ${error.message}`, 'error');
  }
};

const handleUpdateAttachment = async (
  updateVariables,
  updateAttachment,
  refetch
) => {
  try {
    await updateAttachment(updateVariables);
    await refetch();
  } catch (error) {
    notify.show(`Error updating attachment: ${error.message}`, 'error');
  }
};

const updateAttachmentPositions = async (section, updateAttachment) => {
  try {
    await Promise.all(
      section.attachments.map(async (attachment, index) => {
        attachment.positionWithinSection = index;
        attachment.attachmentSectionId =
          section.id === 'unsectioned' ? null : section.id;
        await updateAttachment({
          variables: {
            id: attachment.id,
            positionWithinSection: attachment.positionWithinSection,
            attachmentSectionId: attachment.attachmentSectionId
          }
        });
      })
    );
  } catch (error) {
    notify.show(
      `Error updating attachment positions: ${error.message}`,
      'error'
    );
  }
};

const reorderAttachments = async (
  event,
  allSectionsData,
  updateAttachmentPositions,
  updateAttachment,
  refetch
) => {
  if (event.destination === null) {
    // if user drags attachment out of droppable area
    // or picks up and drops in the same place
    return;
  }
  const sourceSectionId = event.source.droppableId;
  const destinationSectionId = event.destination.droppableId;

  try {
    let sourceSection = allSectionsData.find(
      section => section.id === sourceSectionId
    );
    let destinationSection = allSectionsData.find(
      section => section.id === destinationSectionId
    );

    if (sourceSectionId === destinationSectionId) {
      // Reorder within the same section
      const attachments = [...sourceSection.attachments];
      const [movedAttachment] = attachments.splice(event.source.index, 1);
      attachments.splice(event.destination.index, 0, movedAttachment);

      sourceSection = { ...sourceSection, attachments };

      await updateAttachmentPositions(sourceSection, updateAttachment);
    } else {
      // Move attachment from one section to another
      const sourceAttachments = [...sourceSection.attachments];
      const destinationAttachments = [...destinationSection.attachments];

      const [movedAttachment] = sourceAttachments.splice(event.source.index, 1);
      destinationAttachments.splice(
        event.destination.index,
        0,
        movedAttachment
      );

      sourceSection = { ...sourceSection, attachments: sourceAttachments };
      destinationSection = {
        ...destinationSection,
        attachments: destinationAttachments
      };

      await updateAttachmentPositions(sourceSection, updateAttachment);
      await updateAttachmentPositions(destinationSection, updateAttachment);
    }

    await refetch();
  } catch (error) {
    notify.show(`Error reordering attachments: ${error.message}`, 'error');
  }
};

const reorderSections = async (
  event,
  allSectionsData,
  updateAttachmentSection,
  refetch
) => {
  try {
    if (event.destination === null) {
      return;
    }

    const sections = [...allSectionsData];
    const removedSection = sections.splice(event.source.index, 1);
    sections.splice(event.destination.index, 0, removedSection[0]);
    await Promise.all(
      sections.map(async (section, index) => {
        if (section.id === 'unsectioned') {
          return section;
        }
        section.displayOrder = index;
        return updateAttachmentSection({
          variables: {
            id: section.id,
            title: section.title,
            displayOrder: section.displayOrder
          }
        });
      })
    );
    await refetch();
  } catch (error) {
    notify.show(`Error updating section position: ${error.message}`, 'error');
  }
};
export const handlers = {
  handleNewSection,
  handleDeleteSection,
  handleDateChange,
  handleCheckboxChange,
  handleDeleteAttachment,
  handleCreateAttachment,
  handleUpdateAttachmentSection,
  handleUpdateAttachment,
  updateAttachmentPositions,
  reorderAttachments,
  reorderSections
};
