import { gql } from '@apollo/client';

const CREATE_NEW_PRODUCT_COURSE = gql`
  mutation CreateNewProduct(
    $productId: ID
    $productVertical: String!
    $courseTitle: String!
    $courseShortname: String!
    $courseHasCme: Boolean
    $courseType: String!
    $courseMaxCredits: String
    $itemTitle: String!
    $itemShortname: String!
    $itemPrice: String
    $itemIsArb: Boolean
    $itemIsCmeEnabled: Boolean
    $itemDescription: String!
    $itemSubscriptionLength: String
    $parentItemId: String
  ) {
    createNewProduct(
      input: {
        productId: $productId
        productVertical: $productVertical
        courseTitle: $courseTitle
        courseShortname: $courseShortname
        courseHasCme: $courseHasCme
        courseType: $courseType
        courseMaxCredits: $courseMaxCredits
        itemTitle: $itemTitle
        itemShortname: $itemShortname
        itemPrice: $itemPrice
        itemIsArb: $itemIsArb
        itemIsCmeEnabled: $itemIsCmeEnabled
        itemDescription: $itemDescription
        itemSubscriptionLength: $itemSubscriptionLength
        parentItemId: $parentItemId
      }
    )
  }
`;

export default CREATE_NEW_PRODUCT_COURSE;
