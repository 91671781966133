import { gql } from '@apollo/client';

const UPDATE_ORGANIZATION_NOTES_MUTATION = gql`
  mutation UpdateOrganization(
    $id: UUID!
    $notes: String!
    $updatedAt: Datetime!
  ) {
    updateOrganization(
      input: { patch: { notes: $notes, updatedAt: $updatedAt }, id: $id }
    ) {
      clientMutationId
    }
  }
`;

export default UPDATE_ORGANIZATION_NOTES_MUTATION;
