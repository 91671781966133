import { gql } from '@apollo/client';

const GET_PROMOTION_ORDERS_QUERY = gql`
  query promotionOrderStats($promotionId: ID!) {
    promotionOrderStats(promotionId: $promotionId) {
      promotion {
        id
        title
      }
      roiData {
        lineItems {
          monthyear
          numOrders
          grossSalesTotal
          totalAmountDiscounted
          totalNumNewAccountsCreated
          averageNumItemsPurchased
          averageTotalPerOrder
          totalCustomersThatHaveUsedPromo
        }
      }
    }
  }
`;

export { GET_PROMOTION_ORDERS_QUERY };
