const config = {
  enabled: {
    canManageGroupsPermissionId: '7f03fe31-6be1-475b-b57a-5c05c9a63fbd'
  },
  defaults: {
    defaultQuestionCourseId: '4cde7eec-82c3-4362-a5b9-433cf9bb5816',
    defaultQuestionProductId: '4ca57e1b-8c00-4395-aa03-4493cd51b9dd',
    defaultTagTypeId: 'b5c8cf55-3fa5-4b8b-95b1-d590f9d46007',
    defaultChapterImageId: '50053860-f385-4d65-9464-ab51262daf27',
    defaultCountry: '25d34ee0-2009-46d5-8db5-c03893d3f4ea',
    defaultSubscriptionTemplateMapping: {
      // Required for any course that has more than one subscription template
      // PA Podcast
      '80c46f3f-0e73-4c85-9aa2-39340d724d5d':
        '5ab11a5d-52d5-481a-acee-2841e2745b2d',
      // ERcast
      'ff31fbe1-de28-43a6-8c4c-2b7ddf8fa281':
        'd0ff1c2e-6e49-4f55-b0e0-1af0c9590f68',
      // UC Bootcamp
      '4443427f-9d87-4eb4-9bca-169bf10d8fcd':
        'ab8e5426-edf3-44c5-ae8e-c36a1ee2c292',
      // UC Bootcamp Assessment
      'cedc67ba-8c8c-4888-b6c6-3d832ec5dd04':
        '36297781-733d-4de8-8848-a527e25e2c73',
      //Peds Qbank
      '03427ba2-ad9a-4fbd-bfc8-3a10f9c18725':
        '85663d08-9ee3-4f06-85f1-1aad10b0f952'
    }
  },
  permissions: {
    canManageGroups: '7f03fe31-6be1-475b-b57a-5c05c9a63fbd'
  },
  environments: {
    braintreeUrl: process.env.REACT_APP_BRAINTREE_URL
  }
};

export default config;
