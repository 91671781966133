import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ApolloConsumer } from '@apollo/client';
import { Empty, notification } from 'antd';

import { useLazyQuery, gql } from '@apollo/client';
import config from '../../../config';
import * as API from '../../../API';
import getSlug from '../../../utils/getSlug';
import BreadcrumbConstants from '../../../routes/BreadcrumbConstants';
import FillMainView from '../../../components/FillMainView';
import Chapter from '../../course/Chapter';
import ChapterListItemHeader from '../../../components/ChapterListItem/ChapterListItemHeader';
import ChapterListItem from '../../../components/ChapterListItem/ChapterListItem';
import PaginatedList from '../../../components/PaginatedList/PaginatedList';
import ListHeaderActionItems from '../../../components/PaginatedList/ListHeaderActionItems';
import ContentHeader from '../ContentHeader';

import './AudioContentView.css';

const GET_BINDINGS_FOR_CHAPTER = gql`
  query getChapterBindings($chapterId: UUID!) {
    chapter(id: $chapterId) {
      episodeChapterBindingsList {
        id
      }
      chapterTagBindingsList {
        id
      }
      chapterAttachmentBindingsList {
        id
      }
      chapterEditorBindingsList {
        id
      }
      chapterFacultyBindingsList {
        id
      }
      accountCmeAttemptsList {
        id
      }
    }
  }
`;

export default function AudioContentView() {
  const { chapterId } = useParams();
  const [editingChapterId, setEditingChapterId] = useState(chapterId);
  const [courseTitles, setCourseTitles] = useState({});

  useEffect(() => {
    loadCourseTitles();
  }, []);

  const audioContentBreadcrumb = [
    BreadcrumbConstants.HippoAdmin,
    BreadcrumbConstants.Content,
    BreadcrumbConstants.Chapter
  ];

  const history = useHistory();
  const [getChapterBindings] = useLazyQuery(GET_BINDINGS_FOR_CHAPTER);

  const defaultOptions = {
    include:
      'episodeChapterBindings.episode,chapterFacultyBindings.faculty,chapterEditorBindings.faculty,chapterTagBindings.tag,media'
  };

  const sortOptions = {
    title: 'Title A-Z',
    '-title': 'Title Z-A',
    '-createdAt': 'Date New-Old',
    createdAt: 'Date Old-New'
  };

  const filterOptions = {
    all: 'All',
    active: 'Released',
    inactive: 'Unreleased',
    ...courseTitles
  };

  const loadCourseTitles = async () => {
    const podcastCourseType = (
      await API.courseType.where({
        filter: { shortname: 'podcast' }
      })
    ).data.pop();
    const filter = podcastCourseType
      ? { courseType: podcastCourseType.id }
      : {};

    let courseTitleValues = {};
    (
      await API.course.where({
        filter,
        options: {
          sort: 'title'
        }
      })
    ).data.forEach(course => {
      courseTitleValues = Object.assign(courseTitleValues, {
        [course.id]: course.attributes.title
      });
    });

    setCourseTitles(courseTitleValues);
  };

  const getResourceOptions = () => ({
    sort: '-createdAt',
    include: 'course'
  });

  const filterChapters = (newValue, actions) => {
    switch (newValue) {
      case 'all':
        actions.setOption('_chapterFilter', null);
        break;
      case 'inactive':
        actions.setOption('_chapterFilter', newValue);
        break;
      case 'active':
        actions.setOption('_chapterFilter', newValue);
        break;
      default:
        actions.setOption('_chapterFilter', newValue);
    }
  };

  const stopEditing = () => {
    history.push(`/content/chapter/${editingChapterId}`);
    history.replace('/content/chapters');
    setEditingChapterId(null);
  };

  const createChapter = async () => {
    const title = 'New Chapter';
    const response = await API.chapter.create({
      attributes: {
        title,
        urlSlug: getSlug(null, title, []),
        allowComments: true,
        description: ''
      },
      relationships: {
        image: {
          data: {
            type: 'image',
            id: config.defaults.defaultChapterImageId
          }
        }
      }
    });
    setEditingChapterId(await response.data.id);
  };

  const deleteChapter = async (chapterId, chapterTitle) => {
    if (
      !window.confirm(
        `Are you sure you want to delete chapter ${chapterTitle}?`
      )
    ) {
      return;
    }
    try {
      const { data: chapterBindingsData } = await getChapterBindings({
        variables: { chapterId }
      });
      const hasExistingCMEAttachedToChapter =
        chapterBindingsData &&
        Object.keys(chapterBindingsData.chapter).some(
          key =>
            key === 'accountCmeAttemptsList' &&
            chapterBindingsData.chapter[key].length > 0
        );

      if (hasExistingCMEAttachedToChapter) {
        notification.error({
          message:
            'This chapter could not be deleted because it has exisiting cme tied to it.'
        });
        return;
      } else {
        const bindingData =
          chapterBindingsData &&
          Object.keys(chapterBindingsData.chapter).reduce((result, key) => {
            if (key.endsWith('BindingsList')) {
              chapterBindingsData.chapter[key].forEach(binding => {
                result.push({
                  apiResorceClientName:
                    binding.__typename[0].toLowerCase() +
                    binding.__typename.slice(1), //update string to match apiResorceClientName
                  id: binding.id
                });
              });
            }
            return result;
          }, []);

        await Promise.all(
          bindingData.map(async binding => {
            if (API[binding.apiResorceClientName]) {
              await API[binding.apiResorceClientName].delete({
                id: binding.id
              });
            } else {
              // eslint-disable-next-line no-console
              console.log(
                `Client ${binding.apiResorceClientName} not found in API module`
              );
            }
          })
        );

        await API.chapter.delete({
          id: chapterId
        });

        notification.success({
          message: 'The chapter has been successfully deleted'
        });
      }
    } catch (e) {
      notification.error({
        message: 'An error occured and this Chapter can not be deleted',
        description: e.data.errors[0]?.detail
      });
    }
  };

  const editingChapterBreadcrumb = [
    BreadcrumbConstants.HippoAdmin,
    BreadcrumbConstants.Content,
    { to: '/content/chapters', title: 'Chapters', onClick: stopEditing },
    { title: 'Chapter' }
  ];

  return editingChapterId ? (
    <FillMainView
      onClose={stopEditing}
      closeButtonText="Back to Audio Chapters"
      breadcrumbData={editingChapterBreadcrumb}
    >
      <ApolloConsumer>
        {client => (
          <Chapter
            getSlug={getSlug}
            client={client}
            chapterId={editingChapterId}
            updateEpisodeBindings={() =>
              getChapterBindings({
                variables: { editingChapterId }
              })
            }
          />
        )}
      </ApolloConsumer>
    </FillMainView>
  ) : (
    <>
      <ContentHeader
        breadCrumb={audioContentBreadcrumb}
        hasTitle={true}
        title="Audio Chapters"
      />
      <PaginatedList
        resource={API.chapter}
        onLoadRows={resource => API.simplifyResource(resource)}
        defaultOptions={defaultOptions}
        renderHeader={actions => (
          <>
            <ListHeaderActionItems
              actions={actions}
              onCreateNew={createChapter}
              createButtonText="Chapter"
              filterOptions={filterOptions}
              sortOptions={sortOptions}
              getResourceOptions={getResourceOptions}
              filter={newValue => filterChapters(newValue, actions)}
            />
            <ChapterListItemHeader hideIndex={true} showDate={true} />
          </>
        )}
        renderListItem={(resourceItem, refresh, i) => {
          return (
            <ChapterListItem
              chapter={resourceItem}
              key={i}
              index={i}
              hideIndex={true}
              showDate={true}
              refresh={refresh}
              deleteItem={async (chapterId, chapterTitle) => {
                deleteChapter(chapterId, chapterTitle).then(() => refresh());
              }}
              onClickItem={chapterId => {
                history.push(`/content/chapter/${chapterId}`);
                setEditingChapterId(chapterId);
              }}
              className="audio-content-list__item"
            />
          );
        }}
        renderEmptyList={() => <Empty description="No results to show" />}
        {...{}}
      />
    </>
  );
}
