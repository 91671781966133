import { gql } from '@apollo/client';

export const FETCH_LECTURES = gql`
  query lectureAdmin($id: UUID!) {
    lecture(id: $id) {
      id
      title
      description
      urlSlug
      hasCme
      lastReviewedAt
      media {
        id
        originalFileName
        posterFrameUrl
        scrubberImageSignedUrl
        scrubberImageVttSignedUrl
        mediaEncodingsList {
          key
          title
          signedUrl
        }
        vttFileAttachment {
          id
          fileName
          title
          subtitle
          key
          signedUrl
        }
      }
      topic {
        id
        title
        topicGroup {
          id
          title
          course {
            id
            title
          }
        }
      }
      parent {
        id
        title
      }
      pearlsList {
        id
        text
        timestamp
        displayOrder
      }
      lecturesByParentIdList {
        id
        title
        displayOrder
        topic {
          title
          topicGroup {
            id
            title
            course {
              id
              title
            }
          }
        }
      }
      lectureFacultyBindingsList {
        id
        displayOrder
        faculty {
          id
          fullName
        }
      }
    }
  }
`;
