import _ from 'lodash';

import * as API from '../API';
import config from '../config';

export const getSubscriptionTemplate = async courseId => {
  const templates = API.simplifyResource(
    await API.subscriptionTemplate.where({
      filter: {
        course: courseId
      }
    })
  );

  let template = templates.length > 0 ? templates[0] : null;

  if (templates.length > 1) {
    template = templates.find(
      subscriptionTemplate =>
        subscriptionTemplate.id ===
        config.defaults.defaultSubscriptionTemplateMapping[courseId]
    );
  }

  if (template) {
    template = _.pick(template, [
      'title',
      'isMediaDownloadingEnabled',
      'isFileDownloadingEnabled',
      'isCMEEnabled',
      'isVideoEnabled',
      'isAudioEnabled',
      'isLiveStreamingEnabled',
      'isShippingComponentEnabled',
      'isAttendance',
      'isMediaAccessEnabled'
    ]);
  }

  return template;
};

export const createSubscription = async (
  courseId,
  startDate,
  endDate,
  accountId,
  programCourseBindingId
) => {
  const course = API.simplifyResource(
    await API.course.where({
      filter: {
        id: courseId
      }
    })
  );

  if (course.length === 0) {
    // Course is not found
    return false;
  }

  const subscriptionTemplate = await getSubscriptionTemplate(course[0].id);

  const attributes = {
    startedAt: startDate,
    endedAt: endDate,
    ...subscriptionTemplate
  };

  // Catch for courses that don't have a subscription template added yet. This
  // shouldn't be an issue, but this will keep an error from occurring just in case.
  if (!subscriptionTemplate) {
    attributes.title = course[0].title;
    attributes.isCMEEnabled = true;
  }

  const relationships = {
    course: { data: { id: course[0].id, type: 'course' } }
  };

  // Add account, if provided
  if (accountId) {
    relationships.account = {
      data: {
        id: accountId,
        type: 'account'
      }
    };
  }

  // Add program course binding, if provided
  if (programCourseBindingId) {
    relationships.programCourseBinding = {
      data: {
        id: programCourseBindingId,
        type: 'programCourseBinding'
      }
    };
  }

  await API.subscription.create({
    attributes,
    relationships
  });

  return true;
};
