import React from 'react';
import PropTypes from 'prop-types';
import { LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import BreadcrumbNavigation from './BreadcrumbNavigation/BreadcrumbNavigation';

import './FillMainView.css';

const FillMainView = ({
  children,
  closeButtonText,
  onClose,
  breadcrumbData
}) => (
  <div className="fill-main">
    <BreadcrumbNavigation data={breadcrumbData} />
    <Button
      className="fill-main__close-button"
      type="link"
      onClick={onClose}
      icon={<LeftOutlined />}
    >
      {closeButtonText}
    </Button>
    {children}
  </div>
);

FillMainView.propTypes = {
  children: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  closeButtonText: PropTypes.string,
  breadcrumbData: PropTypes.array
};

FillMainView.defaultProps = {
  closeButtonText: 'Back'
};

export default FillMainView;
