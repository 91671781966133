import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import { Pagination, Spin } from 'antd';
import MessageLoader from '../MessageLoader';

const DEFAULT_PAGE_LIMIT = 10;
const DEBOUNCE_MS = 500;

export default class PaginatedList extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    pageLimit: PropTypes.number,
    withMessage: PropTypes.bool,

    // Supports resources wrapped by @reststate/client
    resource: PropTypes.shape({
      name: PropTypes.string.isRequired,
      api: PropTypes.func.isRequired
    }),
    defaultFilter: PropTypes.any,
    defaultOptions: PropTypes.any,

    onLoadRows: PropTypes.func,

    renderHeader: PropTypes.func,
    renderList: PropTypes.func,
    renderListItem: PropTypes.func,
    renderEmptyList: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,

      pageLimit: this.props.pageLimit || DEFAULT_PAGE_LIMIT,
      pageIndex: -1,
      totalItems: 0,

      queryOptions: {},
      queryFilters: {},

      rows: []
    };
  }

  componentDidMount() {
    this.loadPage(0);
  }

  componentDidUpdate(prevProps) {
    if (this.props.defaultFilter !== prevProps.defaultFilter) {
      this.loadPage(0);
    }
  }

  setFilter = debounce((keyOrObject, value) => {
    const queryFilters =
      typeof keyOrObject === 'object' ? keyOrObject : this.state.queryFilters;

    if (typeof keyOrObject === 'string') {
      if (!value) {
        delete queryFilters[keyOrObject];
      } else {
        queryFilters[keyOrObject] = value;
      }
    }

    this.setState({ queryFilters }, this.loadPage);
  }, DEBOUNCE_MS);

  setOption = debounce((keyOrObject, value) => {
    const queryOptions =
      typeof keyOrObject === 'object' ? keyOrObject : this.state.queryOptions;

    if (typeof keyOrObject === 'string') {
      if (!value) {
        delete queryOptions[keyOrObject];
      } else {
        queryOptions[keyOrObject] = value;
      }
    }

    this.setState({ pageIndex: 0, queryOptions }, this.loadPage);
  }, DEBOUNCE_MS);

  loadPage = (pageIndex = 0) => {
    this.setState({ isLoading: true }, async () => {
      const options = Object.assign(
        {},
        this.props.defaultOptions || {},
        this.state.queryOptions,
        {
          'page[offset]': this.state.pageLimit * pageIndex,
          'page[limit]': this.state.pageLimit
        }
      );

      const filter = Object.assign(
        {},
        this.props.defaultFilter || {},
        this.state.queryFilters || {}
      );

      const resource = await this.props.resource.where({ filter, options });

      this.setState({ isLoading: false }, () => {
        const totalItems = resource.meta.page ? resource.meta.page.total : 0;
        const rows = this.props.onLoadRows
          ? this.props.onLoadRows(resource)
          : resource.data;
        this.setState({ totalItems, pageIndex, rows });
      });
    });
  };

  refresh = returnToPage0 => {
    this.loadPage(returnToPage0 ? 0 : this.state.pageIndex);
  };

  onShowSizeChange(current, pageSize) {
    this.setState({
      pageLimit: pageSize
    });
  }

  render() {
    return (
      <div className={this.props.className}>
        {this.props.renderHeader &&
          this.props.renderHeader(
            {
              setFilter: this.setFilter,
              setOption: this.setOption
            },
            this.refresh
          )}
        {this.state.rows.length > 0 ? (
          <>
            {this.props.renderList
              ? this.props.renderList(this.state.rows, this.refresh)
              : this.state.rows.map((item, i) => {
                  return this.props.renderListItem(item, this.refresh, i);
                })}

            <Pagination
              onShowSizeChange={this.onShowSizeChange.bind(this)}
              current={this.state.pageIndex + 1}
              onChange={pageNumber => this.loadPage(pageNumber - 1)}
              total={this.state.totalItems}
              pageSize={this.state.pageLimit}
            />
          </>
        ) : (
          <>
            {!this.state.isLoading ? (
              this.props.renderEmptyList()
            ) : this.props.withMessage ? (
              <MessageLoader />
            ) : (
              <Spin />
            )}
          </>
        )}
      </div>
    );
  }
}
