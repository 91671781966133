import React from 'react';
import { Row, Col, Input, DatePicker } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import * as API from '../../API';

export default function BundleCard({ bundleId, title, startedAt, endedAt }) {
  const handleUpdateBundle = debounce(async (value, field) => {
    await API.bundle.update({
      id: bundleId,
      attributes: {
        [field]: value
      }
    });
  });

  return (
    <Row gutter={[16, 4]}>
      <Col sm={24} lg={18}>
        <small>
          <b>Bundle title: </b>
        </small>
        <Input
          defaultValue={title}
          onChange={event => handleUpdateBundle(event.target.value, 'title')}
        />
      </Col>
      <Col sm={24} lg={18}>
        <small>
          <em>
            *note: this title will appear on the cart price breakdown for users!
          </em>
        </small>
      </Col>
      <Col sm={24} lg={18}>
        <small>
          <b>Start date (optional): </b>
        </small>
        <DatePicker
          defaultValue={startedAt ? moment(startedAt) : null}
          onChange={startedAt => handleUpdateBundle(startedAt, 'startedAt')}
          selected={startedAt || null}
        />
      </Col>
      <Col sm={24} lg={18}>
        <small>
          <em>
            *note: set this only if you want to restrict the visibility of a
            bundle to a certain time frame.
          </em>
        </small>
      </Col>
      <Col sm={24} lg={18}>
        <small>
          <b>End date (optional): </b>
        </small>
        <DatePicker
          defaultValue={endedAt ? moment(endedAt) : null}
          onChange={endedAt => handleUpdateBundle(endedAt, 'endedAt')}
          selected={endedAt || null}
        />
      </Col>
      <Col sm={24} lg={18}>
        <small>
          <em>
            *note: set this only if you want to restrict the visibility of a
            bundle to a certain time frame.
          </em>
        </small>
      </Col>
    </Row>
  );
}
