import React, { useState, useEffect } from 'react';
import { notify } from 'react-notify-toast';
import moment from 'moment';

import { getDecodedTokenFromCookie } from '../../utils/cookie';
import { getBindingAPIFromType } from '../../utils/note';

import { Note } from './Note';

const getBindingFilterFromType = (bindingType, bindingTypeId) => {
  let bindingFilter;
  switch (bindingType) {
    case 'account':
      bindingFilter = {
        account: bindingTypeId
      };
      break;
    case 'program':
      bindingFilter = {
        program: bindingTypeId
      };
      break;

    case 'organization':
      bindingFilter = {
        OrganizationId: bindingTypeId
      };
      break;

    default:
      break;
  }
  return bindingFilter;
};

export const ListNotes = ({
  bindingType,
  bindingTypeId,
  fetchNotes,
  setFetchNotes
}) => {
  const [notes, setNotes] = useState([]);
  const [authors, setAuthors] = useState([]);
  const token = getDecodedTokenFromCookie();
  const filter = getBindingFilterFromType(bindingType, bindingTypeId);
  const api = getBindingAPIFromType(bindingType);

  useEffect(() => {
    const fetchData = async () => {
      if (fetchNotes) {
        try {
          const noteBindings = await api.where({
            filter,
            options: {
              include: ['note', 'note.author']
            }
          });

          const queriedNotes = noteBindings.included.filter(
            include => include.type === 'note'
          );
          const queriedAuthors = noteBindings.included.filter(
            include => include.type === 'account'
          );
          setNotes(queriedNotes);
          setAuthors(queriedAuthors);
          setFetchNotes(false);
        } catch (error) {
          if (error.data) {
            notify.show(error.data.errors[0].detail[0].message, 'error');
          } else {
            throw error;
          }
        }
      }
    };
    fetchData();
  }, [api, filter, fetchNotes, setFetchNotes]);

  return (
    <div className="list-notes__content">
      {notes
        .sort(
          (a, b) =>
            moment(b.attributes.createdAt) - moment(a.attributes.createdAt)
        )
        .map(note => {
          const author = authors.find(
            author => author.id === note.relationships.author.data.id
          );
          const name =
            author &&
            `${author.attributes.firstName} ${author.attributes.lastName}`;

          return (
            <Note
              key={note.id}
              name={name}
              date={note.attributes.createdAt}
              body={note.attributes.bodyText}
              canEdit={author && author.id === token.account}
              noteId={note.id}
              setFetchNotes={setFetchNotes}
            />
          );
        })}
    </div>
  );
};
