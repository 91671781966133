import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ContentHeader from '../content/ContentHeader';
import BreadcrumbConstants from '../../routes/BreadcrumbConstants';
import './Organizations.css';
import { debounce, isNull } from 'lodash';
import DELETE_ORGANIZATION_MUTATION from './queries/DeleteOrganizationMutation';
import GET_ORGANIZATIONS_QUERY from './queries/GetOrganizationsGQLQuery';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Empty, Input, Popconfirm, Table, Pagination } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import AddOrganizationsModal from './AddOrganizationsModal';
const { Column } = Table;

export default function Organizations() {
  const [
    searchOrganizationNameFilter,
    setSearchOrganizationNameFilter
  ] = useState('');
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);

  const commentsBreadcrumb = [
    BreadcrumbConstants.HippoAdmin,
    BreadcrumbConstants.GroupManagement,
    { title: 'Organizations' }
  ];
  const history = useHistory();

  const { data, fetchMore, refetch, loading } = useQuery(
    GET_ORGANIZATIONS_QUERY,
    {
      variables: {
        limit,
        offset
      }
    }
  );

  useEffect(() => {
    if (!isNull(searchOrganizationNameFilter)) {
      refetch({
        limit,
        first: offset,
        filter: { name: { includesInsensitive: searchOrganizationNameFilter } }
      });
    }
  }, [refetch, searchOrganizationNameFilter, limit, offset]);

  const [deleteOrganization] = useMutation(DELETE_ORGANIZATION_MUTATION, {
    onCompleted: async (d, clientOptions) => {
      await refetch({
        limit,
        first: offset,
        filter: { name: { includesInsensitive: searchOrganizationNameFilter } }
      });
    }
  });

  const newOrganizationCallback = async () => {
    await refetch({
      limit,
      first: offset,
      filter: { name: { includesInsensitive: searchOrganizationNameFilter } }
    });
  };

  const handleDeleteOrganization = async idToDelete => {
    await deleteOrganization({
      variables: {
        id: idToDelete
      }
    });
  };

  const debouncedSearchFilterChanged = debounce(async incomingSearchValue => {
    setSearchOrganizationNameFilter(incomingSearchValue);
  }, 500);

  const handleSearchFilterChange = useCallback(debouncedSearchFilterChanged, [
    debouncedSearchFilterChanged
  ]);

  return (
    <div>
      <ContentHeader
        breadCrumb={commentsBreadcrumb}
        hasTitle={true}
        title="Organizations"
      />
      <div className="organizations-menu-bar">
        <Input
          className="organizations-menu-bar__search"
          allowClear={true}
          placeholder="Search Organizations by name"
          onChange={e => handleSearchFilterChange(e.target.value)}
        />
        <div className="organizations add-organization-modal">
          <AddOrganizationsModal callback={newOrganizationCallback} />
        </div>
      </div>
      {!loading && data ? (
        <>
          <Table
            dataSource={data.organizations.nodes.map(
              organization => organization
            )}
            pagination={false}
            rowKey="name"
            onRow={record => ({
              onClick: event => {
                if (event.target.className === 'ant-table-cell') {
                  history.push(`/group-management/organization/${record.id}`, {
                    organizationId: record.id
                  });
                }
              }
            })}
          >
            <Column dataIndex="name" key="name" title="Organization Name" />
            <Column
              dataIndex={['manager', 'email']}
              key="managerEmail"
              title="Hippo Organizational Manager"
            />
            <Column
              title="Groups"
              key="groupCount"
              render={organization =>
                organization.organizationProgramBindingsList.length
              }
            />
            <Column
              width="1vw"
              title=""
              dataIndex=""
              render={(_, { id }) => {
                return (
                  <Popconfirm
                    placement="topRight"
                    title="Delete this organization?"
                    onConfirm={e => handleDeleteOrganization(id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      type="danger"
                      icon={<DeleteOutlined />}
                      size="small"
                    />
                  </Popconfirm>
                );
              }}
            />
          </Table>
          <Pagination
            current={offset / limit + 1}
            onChange={(pageNumber, pageSize) => {
              setLimit(pageSize);
              setOffset(pageSize * (pageNumber - 1));
              fetchMore({
                variables: { offset, first: limit }
              });
            }}
            total={data.organizations.totalCount}
            pageSize={limit}
            showSizeChanger={true}
          />
        </>
      ) : (
        <Empty description="No organizations found" />
      )}
    </div>
  );
}
