import React from 'react';
import { notify } from 'react-notify-toast';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Input, Modal } from 'antd';

import { getTokenFromCookie } from '../utils/cookie';

class CmeAccreditorModal extends React.Component {
  state = {
    open: false,
    onOk() {},
    onCancel() {}
  };

  handleServerError(json) {
    if (json.errors && json.errors.length > 0) {
      this.setState({ error: json.errors, loading: false });
      notify.show(
        `Error: ${json.errors[0].title} - ${json.errors[0].detail[0].message}`,
        'error'
      );
      return true;
    }
    return false;
  }

  async handleOk(e) {
    e.preventDefault();
    try {
      await this.props.form.validateFields();
    } catch (error) {
      return;
    }

    try {
      const accreditorName = this.props.form.getFieldValue('accreditorName');
      const formData = {
        data: {
          type: 'cmeAccreditor',
          attributes: {
            name: accreditorName
          },
          relationships: {}
        }
      };

      const response = await fetch(
        process.env.REACT_APP_JSONAPI_SERVER + '/cmeAccreditor/',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getTokenFromCookie()
          },
          body: JSON.stringify(formData)
        }
      );

      const json = await response.json();
      if (this.handleServerError(json)) {
        return;
      }

      notify.show(
        `Accreditor "${accreditorName}" was saved successfully.`,
        'success'
      );

      this.props.form.setFieldsValue({ accreditorName: '' });
      this.setState({
        open: false
      });
      this.state.onOk(json.data, e);
    } catch (error) {
      notify.show(`Error: ${error.message}`, 'error');
    }
  }

  async handleCancel(e) {
    this.props.form.setFieldsValue({ accreditorName: '' });
    this.setState({
      open: false
    });
    this.state.onCancel(e);
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        title="Add Accreditor"
        visible={this.state.open}
        onOk={this.handleOk.bind(this)}
        onCancel={this.handleCancel.bind(this)}
      >
        <Form>
          <Form.Item label="Accreditor Name">
            {getFieldDecorator('accreditorName', {
              initialValue: '',
              rules: [
                {
                  type: 'string',
                  required: true,
                  message: 'Please enter an accreditor name'
                }
              ]
            })(<Input />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }

  componentWillReceiveProps(props) {
    this.setState({
      open: props.open,
      onOk: props.onOk,
      onCancel: props.onCancel
    });
  }
}

export default Form.create()(CmeAccreditorModal);
