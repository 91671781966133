import React from 'react';
import { Button } from 'antd';
import {
  CaretUpOutlined,
  CaretDownOutlined,
  DeleteOutlined,
  MenuOutlined
} from '@ant-design/icons';
import { Draggable } from 'react-beautiful-dnd';
import EditableLabel from '../EditableLabel/EditableLabel';
import ResourceLibraryAttachmentView from './ResourceLibraryAttachmentView';
import AttachmentHeader from './ResourceLibraryAttachmentHeaderView';

const getRowStyle = (isDragging, draggableStyle) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 2,
  justifyContent: 'space-between',
  userSelect: 'none',
  padding: 12,
  background: isDragging ? '#eee' : '#fff',
  boxShadow: isDragging ? '0 10px 6px -6px #777' : 'none',
  ...draggableStyle
});

const ResourceLibraryAttachmentSectionView = ({
  section,
  index,
  expandedSections,
  loading,
  handleToggleSection,
  handleUpdateAttachmentSection,
  handleDeleteSection,
  handleToggleAttachmentInput,
  handleCreateAttachment,
  handleUpdateAttachment,
  handleDateChange,
  handleCheckboxChange,
  handleDeleteAttachment,
  replaceFileOnAttachment,
  attachmentInputVisible
}) => (
  <div key={section.id}>
    <Draggable draggableId={section.id} index={index} type="section">
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getRowStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          <div className="column-span-small">
            <Button
              type="primary"
              icon={
                expandedSections[section.id] ? (
                  <CaretDownOutlined />
                ) : (
                  <CaretUpOutlined />
                )
              }
              style={{ lineHeight: '100%' }}
              onClick={() => handleToggleSection(section.id)}
            />
          </div>
          <div
            className="column-span-large"
            style={{ flexGrow: 1, marginLeft: '1em' }}
          >
            {section.id === 'unsectioned' ? (
              <span>{section.title}</span>
            ) : (
              <EditableLabel
                defaultValue={section.title}
                onChange={async value => {
                  try {
                    await handleUpdateAttachmentSection({
                      variables: {
                        id: section.id,
                        title: value,
                        displayOrder: section.displayOrder
                      }
                    });
                  } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error('Error updating section title:', error);
                  }
                }}
              />
            )}
          </div>
          <div className="column-span-medium button-column">
            {section.id !== 'unsectioned' && (
              <>
                <Button
                  type="danger"
                  icon={<DeleteOutlined />}
                  style={{ lineHeight: '100%' }}
                  onClick={() => handleDeleteSection(section.id)}
                />
                <MenuOutlined
                  style={{ marginLeft: '2rem', fontSize: '1.25rem' }}
                />
              </>
            )}
          </div>
        </div>
      )}
    </Draggable>
    {expandedSections[section.id] && (
      <div className="attachment-view">
        <AttachmentHeader loading={loading} />
        <ResourceLibraryAttachmentView
          section={section}
          loading={loading}
          handleToggleAttachmentInput={handleToggleAttachmentInput}
          handleCreateAttachment={handleCreateAttachment}
          handleUpdateAttachment={handleUpdateAttachment}
          handleDateChange={handleDateChange}
          handleCheckboxChange={handleCheckboxChange}
          handleDeleteAttachment={handleDeleteAttachment}
          replaceFileOnAttachment={replaceFileOnAttachment}
          attachmentInputVisible={attachmentInputVisible}
        />
      </div>
    )}
  </div>
);

export default ResourceLibraryAttachmentSectionView;
