import React, { useEffect, useState } from 'react';
import { Col, FormFeedback, FormGroup, Input, Row, Label } from 'reactstrap';
import { Modal, Typography } from 'antd';

import { v4 as uuidv4 } from 'uuid';

import * as API from '../../API';
import { emailExists, validateEmail } from '../../utils/email';

const { Text } = Typography;

export default function UserAddModal({
  group,
  isAdmin,
  modalVisible,
  onSuccess,
  setModalVisible
}) {
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [memberIdentifier, setMemberIdentifier] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [emailState, setEmailState] = useState('');

  const createNewUser = async () => {
    const id = uuidv4();
    const isEmailExist = await emailExists(email);

    if (isEmailExist) {
      setErrorMessage('An account with this email address already exists.');
      return;
    } else {
      setErrorMessage(null);
    }

    const newUser = await API.account.create({
      attributes: {
        id,
        firstName,
        lastName,
        email
      }
    });

    if (group) {
      await API.programAccountBinding.create({
        attributes: {
          id: uuidv4(),
          isAdmin,
          isMember: !isAdmin,
          hasAccepted: true,
          memberIdentifier
        },
        relationships: {
          program: {
            data: {
              type: 'program',
              id: group.id
            }
          },
          account: {
            data: {
              type: 'account',
              id
            }
          }
        }
      });
    }

    setModalVisible(false);
    onSuccess && onSuccess(newUser);
    return newUser;
  };

  useEffect(() => {
    if (!email || validateEmail(email)) {
      setEmailState('has-success');
    } else {
      setEmailState('has-danger');
    }
  }, [email]);

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (
    <Modal
      title="Add New User"
      visible={modalVisible}
      width={1000}
      okText="Save"
      onOk={() => createNewUser()}
      onCancel={handleCancel}
      okButtonProps={{
        disabled:
          !firstName ||
          !lastName ||
          !email ||
          firstName.length === 0 ||
          lastName.length === 0 ||
          email.length === 0 ||
          emailState === 'has-danger' ||
          errorMessage
      }}
    >
      <Row>
        <Col xs="4">
          <FormGroup>
            <Label for="firstName">First name</Label>
            <Input
              type="text"
              bsSize="sm"
              onChange={e => setFirstName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="lastName">Last name</Label>
            <Input
              type="text"
              bsSize="sm"
              onChange={e => setLastName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email address</Label>
            <Input
              type="email"
              bsSize="sm"
              invalid={emailState === 'has-danger'}
              onChange={e => {
                setErrorMessage(null);
                setEmail(e.target.value.toLowerCase().trim());
                validateEmail();
              }}
            />
            <FormFeedback invalid="true">
              Uh oh! Looks like there is an issue with your email. Please input
              a correct email.
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="memberIdentifier">Group Member Identifier</Label>
            <Input
              type="text"
              bsSize="sm"
              onChange={e => setMemberIdentifier(e.target.value)}
            />
          </FormGroup>
          {errorMessage && <Text type="danger">{errorMessage}</Text>}
        </Col>
      </Row>
    </Modal>
  );
}
