import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import QuestionsList from './QuestionsList';

import * as API from '../../../API';

import './QuestionSelectModal.css';

export default function QuestionSelectModal({
  assessment,
  modalVisible,
  setModalVisible
}) {
  const history = useHistory();
  const [selectedQuestions, setSelectedQuestions] = useState(null);

  const handleCancel = () => {
    setModalVisible(false);
  };

  const getNextDisplayOrder = displayOrders => {
    if (!displayOrders) {
      return 0;
    }
    const max = Math.max(...displayOrders);
    return max <= 0 ? 0 : max + 10;
  };

  const createAssessmentQuestionBindings = async () => {
    const nextDisplayOrder = getNextDisplayOrder(
      assessment.assessmentQuestionBindings?.map(
        binding => binding.displayOrder
      )
    );

    await Promise.all(
      selectedQuestions.map((question, index) =>
        API.assessmentQuestionBinding.create({
          attributes: {
            displayOrder: nextDisplayOrder + index * 10,
            assessmentVersion: assessment.currentVersion
          },
          relationships: {
            assessment: {
              data: {
                type: 'assessment',
                id: assessment.id
              }
            },
            question: {
              data: {
                type: 'question',
                id: question.id
              }
            }
          }
        })
      )
    );
    setModalVisible(false);
    history.go(0);
  };

  const onSelectionHandler = selectedRows => {
    setSelectedQuestions(selectedRows);
  };

  return (
    <Modal
      title="QBank & Assessment Questions"
      visible={modalVisible}
      width={1000}
      onCancel={handleCancel}
      footer={[
        <Button
          key="back"
          className="question-select_modal__cancel"
          onClick={handleCancel}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          className="question-select_modal__add"
          icon={<PlusOutlined />}
          type="primary"
          onClick={createAssessmentQuestionBindings}
        >
          Add Questions to Assessment
        </Button>
      ]}
    >
      <QuestionsList
        onSelectionHandler={onSelectionHandler}
        disabledQuestionIds={assessment.assessmentQuestionBindings?.map(
          binding => binding.question.id
        )}
      />
    </Modal>
  );
}
