import * as API from '../API';

export async function getResetPasswordUrl(email) {
  const response = await API.raw('POST', 'api/account/forgot-password', {
    email,
    shouldSkipEmail: true
  });
  let passwordResetUrl = null;
  if (response.status === 200) {
    passwordResetUrl = response.data.resetUrl;
  }
  return passwordResetUrl;
}
