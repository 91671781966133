import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, List, Modal, Spin, Input } from 'antd';
import { Label } from 'reactstrap';
import MediaItem from '../MediaItem';
import { fetchAllMedia } from '../../utils/media';

import './AttachMediaModal.css';

const AttachMediaModal = ({
  visible,
  isLoading,
  title,
  onClose,
  onAttach,
  courseType
}) => {
  const [filter, setFilter] = useState('');
  const [media, setMedia] = useState(null);
  const filterRegExp = new RegExp(filter, 'i');

  useEffect(() => {
    if (!media && visible) {
      const fetchMedia = async () => {
        const fetchedMedia = (await fetchAllMedia()).filter(mediaToFilter => {
          if (courseType === 'video') {
            return mediaToFilter.originalFileName.includes('.mp4');
          } else if (courseType === 'podcast') {
            return mediaToFilter.originalFileName.includes('.mp3');
          } else {
            return false;
          }
        });
        setMedia(fetchedMedia);
      };
      fetchMedia();
    }
  }, [media, visible, courseType]);

  return (
    <Modal
      wrapClassName="attach-media-modal"
      width="60vw"
      visible={visible}
      title={title}
      onOk={onClose}
      onCancel={onClose}
      footer={
        <>
          {isLoading && <Spin />}

          <Button onClick={onClose} disabled={isLoading}>
            Close
          </Button>
        </>
      }
    >
      {media ? (
        <div>
          <Label htmlFor="mediaModalSearchFilter">
            <span className="attach-media-modal__filter-label">Filter:</span>
            <Input
              allowClear={true}
              id="mediaModalSearchFilter"
              value={filter}
              onChange={e => setFilter(e.target.value)}
            />
          </Label>
          <div className="attach-media-modal__media-list">
            <List
              dataSource={media.filter(item =>
                item.originalFileName.match(filterRegExp)
              )}
              renderItem={item => (
                <MediaItem
                  media={item}
                  onSelect={onAttach}
                  disabled={isLoading}
                />
              )}
            />
          </div>
        </div>
      ) : (
        <Spin />
      )}
    </Modal>
  );
};

AttachMediaModal.propTypes = {
  visible: PropTypes.bool,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  onAttach: PropTypes.func,
  courseType: PropTypes.string
};

AttachMediaModal.defaultProps = {
  visible: false,
  isLoading: false,
  title: '',
  onClose: () => null,
  onAttach: () => null,
  courseType: 'video'
};

export default AttachMediaModal;
