import React, { useState, useEffect } from 'react';
import { Button, List, Typography } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import EditDefaultItemsModal from './EditDefaultItemsModal';
import DefaultItemsSummaryModal from './DefaultItemsSummaryModal';
import { useMutation, useQuery } from '@apollo/client';
import GET_VERTICAL_ITEM_LIST_QUERY from './queries/GetVerticalItemListQuery';
import CREATE_ORGANIZATION_DEFAULT_SUBSCRIPTION_MUTATION from './queries/CreateOrganizationDefaultSubscriptionMutation';
import DELETE_ORGANIZATION_DEFAULT_SUBSCRIPTION_MUTATION from './queries/DeleteOrganizationDefaultSubscriptionMutation';

const { Text } = Typography;

const DefaultItems = props => {
  const { refreshParent, previouslySelectedItems } = props;
  const [showEditModal, setShowEditModal] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [changedItems, setChangedItems] = useState([]);
  const [queryDate] = useState(new Date());
  const [verticals, setVerticals] = useState([]);
  const [currentDefaultItems, setCurrentDefaultItems] = useState([]);

  const { data } = useQuery(GET_VERTICAL_ITEM_LIST_QUERY, {
    variables: {
      todaysDate: queryDate
    }
  });
  const sortArrByTitle = item => {
    item.sort((a, b) => {
      if (a.title < b.title) {
        return -1;
      } else if (a.title > b.title) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  const [addOrganizationDefaultSubscription] = useMutation(
    CREATE_ORGANIZATION_DEFAULT_SUBSCRIPTION_MUTATION,
    {
      onCompleted: async (data, clientOptions) => {
        if (refreshParent) {
          await refreshParent();
        }
      }
    }
  );
  const [deleteOrganizationDefaultSubscription] = useMutation(
    DELETE_ORGANIZATION_DEFAULT_SUBSCRIPTION_MUTATION,
    {
      onCompleted: async (data, clientOptions) => {
        if (refreshParent) {
          await refreshParent();
        }
      }
    }
  );

  useEffect(() => {
    if (data && verticals.length === 0) {
      const defaultSelectedItems = [];
      const verts = data.verticalsList.map(vertical => {
        const items = vertical.productsList
          .flatMap(productsList => {
            return productsList.itemsList;
          })
          .map(item => {
            const isSelected =
              previouslySelectedItems.findIndex(
                prevItem => item.id === prevItem.subscribedCourseId
              ) >= 0;
            if (isSelected) {
              defaultSelectedItems.push(item);
            }
            return { ...item, isSelected };
          });
        sortArrByTitle(items);
        return {
          vertical: vertical.title,
          items
        };
      });
      sortArrByTitle(verts);
      setVerticals(verts);

      sortArrByTitle(defaultSelectedItems);
      setCurrentDefaultItems(defaultSelectedItems);
    }
  }, [data, previouslySelectedItems, verticals]);

  const handleEditButtonClick = () => {
    setShowEditModal(true);
  };

  const handleEditModalCancel = () => {
    setShowEditModal(false);
  };

  const handleEditModalConfirm = async (pendingItems, changedItems) => {
    const verticalsWithSelectedItems = pendingItems.filter(vertical => {
      return vertical.items.findIndex(item => item.isSelected) >= 0;
    });
    const selectedItems = verticalsWithSelectedItems.flatMap(vertical => {
      return vertical.items.filter(item => item.isSelected);
    });

    sortArrByTitle(selectedItems);

    setCurrentDefaultItems(selectedItems);

    selectedItems.forEach(async item => {
      if (
        previouslySelectedItems.findIndex(
          prevItem => prevItem.subscribedCourseId === item.id
        ) >= 0
      ) {
        // do nothing -- already added
      } else {
        // must add
        await addOrganizationDefaultSubscription({
          variables: {
            id: uuidv4(),
            subscribedCourseId: item.id,
            organizationId: props.organizationId,
            createdAt: new Date(),
            updatedAt: new Date(),
            isEnabled: true
          }
        });
      }
    });
    previouslySelectedItems.forEach(async prevItem => {
      if (
        selectedItems.findIndex(
          item => item.id === prevItem.subscribedCourseId
        ) >= 0
      ) {
        // do nothing -- already added
      } else {
        // is in the db but was removed -- must delete
        await deleteOrganizationDefaultSubscription({
          variables: {
            id: prevItem.id
          }
        });
      }
    });
    sortArrByTitle(changedItems);

    setVerticals(pendingItems);
    setChangedItems(changedItems);
    setShowEditModal(false);
    setShowSummaryModal(true);
  };

  const handleSummaryModalDone = () => {
    setShowSummaryModal(false);
  };
  return (
    <div>
      <span style={{ display: 'inline-grid', width: '25%' }}>
        <Button type="primary" onClick={handleEditButtonClick}>
          Edit Default Items
        </Button>
      </span>
      <span
        style={{ display: 'inline-grid', marginLeft: '2rem', width: '40%' }}
      >
        <Text>
          Current Default Items:{' '}
          {currentDefaultItems.length === 0 ? 'None' : ''}
        </Text>
        <List
          dataSource={currentDefaultItems}
          renderItem={item => {
            return <List.Item>{item.title}</List.Item>;
          }}
        />
      </span>
      <span style={{ display: 'inline-grid', width: '30%', float: 'right' }}>
        <Text>
          These are items that will be globally added to all self-onboarded
          clinicians
        </Text>
      </span>
      {showEditModal && (
        <EditDefaultItemsModal
          visible={showEditModal}
          onCancel={handleEditModalCancel}
          onConfirm={handleEditModalConfirm}
          items={verticals}
        />
      )}
      {showSummaryModal && (
        <DefaultItemsSummaryModal
          visible={showSummaryModal}
          onDone={handleSummaryModalDone}
          updatedItemList={currentDefaultItems}
          changedItems={changedItems}
        />
      )}
    </div>
  );
};

export default DefaultItems;
