import React from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import { notify } from 'react-notify-toast';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, DatePicker, Button } from 'antd';

import * as API from '../../API';
import EditorInput from '../../components/EditorInput/EditorInput';
import BreadcrumbConstants from '../../routes/BreadcrumbConstants';
import BreadcrumbNavigation from '../../components/BreadcrumbNavigation/BreadcrumbNavigation';

import './CmeDisclosure.css';

const EPISODE = 'episode';
const COURSE = 'course';

class CmeDisclosure extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disclosure: null,
      attachedItemId: null,
      breadcrumbData: null,
      course: null,
      isNew: true,
      loading: true,
      error: null
    };
  }

  handleError() {
    const { error } = this.state;
    if (error) {
      notify.show(`Error: ${error}`, 'error');
    }
  }

  onClickLink(link) {
    this.props.history.push(link);
  }

  async componentDidMount() {
    const { disclosureId, course, episodeId } = this.props.match.params;
    this.setState({ loading: true });
    await this.setAttachedItem(course, episodeId);

    // Load Disclosure
    if (disclosureId) {
      await this.getDisclosure(disclosureId, course ? COURSE : EPISODE);
    } else {
      await this.setState({
        disclosure: this.generateDefaultDisclosure(course),
        isNew: true,
        loading: false
      });
    }

    this.loadBreadcrumbs();
  }

  setAttachedItem = async (courseId, episodeId) => {
    // Make this extensible so that other items can be added in the future
    if (courseId) {
      this.setState({ disclosureType: COURSE, attachedItemId: courseId });
      await this.loadCourse(courseId);
    } else if (episodeId) {
      this.setState({ disclosureType: EPISODE, attachedItemId: episodeId });
    }
  };

  loadCourse = async courseId => {
    try {
      const course = API.simplifyResource(
        await API.course.find({
          id: courseId,
          options: {
            include: 'courseType'
          }
        })
      );

      this.setState({
        course
      });
    } catch (error) {
      this.setState({ error });
    }
  };

  getDisclosure = async (disclosureId, disclosureType) => {
    try {
      const disclosure = API.simplifyResource(
        await API.cmeDisclosure.find({
          id: disclosureId,
          options: {
            include: disclosureType
          }
        })
      );

      this.setState({
        disclosure,
        disclosureType,
        isNew: false,
        loading: false
      });
    } catch (error) {
      this.setState({ error });
      this.handleError();
    }
  };

  loadBreadcrumbs = () => {
    const { disclosureType, attachedItemId, isNew, course } = this.state;
    const { pathname } = this.props.location;

    if (disclosureType === EPISODE) {
      const breadcrumbData = [
        BreadcrumbConstants.HippoAdmin,
        BreadcrumbConstants.Courses,
        BreadcrumbConstants.Episode,
        {
          to: `/content/episode/${attachedItemId}`,
          title: 'Episode'
        },
        {
          to: `/content/episode/${attachedItemId}/cme`,
          title: 'Manage Episode CME'
        },
        {
          to: pathname,
          title: isNew ? 'New CME Disclosure' : 'Edit CME Disclosure'
        }
      ];
      this.setState({ breadcrumbData });
    } else if (disclosureType === COURSE) {
      const breadcrumbData = [
        BreadcrumbConstants.HippoAdmin,
        BreadcrumbConstants.Courses,
        {
          to: `/course/${course.courseType.title}`,
          title: `${course.courseType.title}`
        },
        { to: `/course/${course.id}`, title: `${course.title}` },
        { to: `/course/${attachedItemId}/cme`, title: 'Manage CME' },
        {
          to: pathname,
          title: isNew ? 'New CME Disclosure' : 'Edit CME Disclosure'
        }
      ];
      this.setState({ breadcrumbData });
    }
  };

  checkIfCmeManagementIsEnabled = () => {
    const { disclosureType } = this.state;

    // Only allow access to this page if CME management is enabled for this course
    if (disclosureType !== EPISODE && disclosureType !== COURSE) {
      this.props.history.push(`/course/${this.state.course.id}`);
    }
  };

  createNewDisclosure = async attributes => {
    const { disclosureType, attachedItemId } = this.state;

    const relationships = {};
    const relationshipData = {
      data: {
        type: disclosureType,
        id: attachedItemId
      }
    };
    relationships[disclosureType] = relationshipData;
    try {
      const response = await API.cmeDisclosure.create({
        attributes,
        relationships
      });
      this.setState({
        disclosure: {
          ...response.data.attributes,
          id: response.data.id
        },
        isNew: false
      });
      this.loadBreadcrumbs();
      // Change new url to appropriate edit url
      this.props.history.replace(
        `${this.props.location.pathname}/${response.data.id}`
      );
    } catch (error) {
      await this.setState({ error });
      this.handleError();
    }
  };

  updateDisclosure = async attributes => {
    const { disclosure } = this.state;
    try {
      const disclosureToEdit = (
        await API.cmeDisclosure.find({
          id: disclosure.id,
          options: {}
        })
      ).data;

      await API.cmeDisclosure.update({
        id: disclosureToEdit.id,
        attributes,
        relationships: disclosureToEdit.relationships
      });
    } catch (error) {
      await this.setState({ error });
      this.handleError();
    }
  };

  generateDefaultDisclosure = course => {
    // Default to midnight today and midnight a year from now in the app timezone
    const start = moment()
      .tz(process.env.REACT_APP_DISPLAY_TIMEZONE)
      .startOf('day')
      .utc();
    const end = moment()
      .add(3, 'year')
      .tz(process.env.REACT_APP_DISPLAY_TIMEZONE)
      .startOf('day')
      .utc();

    const defaultEpisodeBody = `<h4>LEARNING OBJECTIVES:</h4>
    <p><br></p>
    <h4>FINANCIAL RELATIONSHIP DISCLOSURES:</h4>
    <p>None of the planners or faculty have anything to disclose.</p>
  `;

    return {
      type: 'cmeDisclosure',
      title: 'New Activity',
      body: course ? '' : defaultEpisodeBody,
      activatedAt: start.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      expiredAt: end.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
    };
  };

  async handleSubmit(e) {
    const { isNew } = this.state;
    try {
      e.preventDefault();
      this.setState({ loading: true });

      const attributes = {
        title: this.props.form.getFieldValue('title'),
        // body cannot be an empty field
        body:
          this.props.form.getFieldValue('body') === ''
            ? ' '
            : this.props.form.getFieldValue('body'),
        activatedAt: this.props.form
          .getFieldValue('activatedAt')
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        expiredAt: this.props.form
          .getFieldValue('expiredAt')
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      };
      if (isNew) {
        this.createNewDisclosure(attributes);
      } else {
        this.updateDisclosure(attributes);
      }

      this.setState({ loading: false });
      this.props.form.setFieldsValue({
        activatedAt: this.props.form
          .getFieldValue('activatedAt')
          .tz(process.env.REACT_APP_DISPLAY_TIMEZONE)
      });

      this.props.form.setFieldsValue({
        expiredAt: this.props.form
          .getFieldValue('expiredAt')
          .tz(process.env.REACT_APP_DISPLAY_TIMEZONE)
      });
      notify.show('Disclosure was saved successfully.', 'success');
    } catch (error) {
      await this.setState({ error, loading: false });
      this.handleError();
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      disclosure,
      breadcrumbData,
      loading,
      disclosureType,
      attachedItemId
    } = this.state;

    return loading ? (
      <p>Loading...</p>
    ) : (
      <div className="cme-disclosure">
        <BreadcrumbNavigation data={breadcrumbData} />

        <h5 className="cme-disclosure__title">CME Disclosure</h5>

        <Form onSubmit={this.handleSubmit.bind(this)}>
          <Form.Item label="Title">
            {getFieldDecorator('title', {
              initialValue: disclosure ? disclosure.title : '',
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: 'Please enter a title.'
                }
              ]
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Activation Date (PST)">
            {getFieldDecorator('activatedAt', {
              initialValue: disclosure
                ? moment(disclosure.activatedAt).tz(
                    process.env.REACT_APP_DISPLAY_TIMEZONE
                  )
                : moment()
                    .tz(process.env.REACT_APP_DISPLAY_TIMEZONE)
                    .startOf('day')
                    .utc(),
              rules: [
                {
                  type: 'object',
                  required: true,
                  whitespace: true,
                  message: 'Please enter the date that the disclosure begins.'
                }
              ]
            })(
              <DatePicker
                showTime={{ use12Hours: true, format: 'HH:mm a' }}
                format="YYYY-MM-DD HH:mm a"
              />
            )}
          </Form.Item>
          <Form.Item label="Expiry Date (PST)">
            {getFieldDecorator('expiredAt', {
              initialValue: disclosure
                ? moment(disclosure.expiredAt).tz(
                    process.env.REACT_APP_DISPLAY_TIMEZONE
                  )
                : moment()
                    .tz(process.env.REACT_APP_DISPLAY_TIMEZONE)
                    .startOf('day')
                    .utc(),
              rules: [
                {
                  type: 'object',
                  required: true,
                  whitespace: true,
                  message: 'Please enter the date that the disclosure expires.'
                }
              ]
            })(
              <DatePicker
                showTime={{ use12Hours: true, format: 'HH:mm a' }}
                format="YYYY-MM-DD HH:mm a"
              />
            )}
          </Form.Item>
          <Form.Item label="Body">
            {getFieldDecorator('body', {
              initialValue: disclosure ? disclosure.body : '',
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: 'Please enter the disclosure body.'
                }
              ]
            })(<EditorInput />)}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
            <Button
              type="secondary"
              onClick={this.onClickLink.bind(
                this,
                disclosureType === COURSE
                  ? `/course/${attachedItemId}/cme`
                  : `/content/episode/${attachedItemId}/cme`
              )}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default Form.create()(CmeDisclosure);
