import React from 'react';
import { Route } from 'react-router-dom';

import Bundles from '../containers/bundles/Bundles';
import Bundle from '../containers/bundles/Bundle';

const BundleRoutes = () => {
  return (
    <>
      <Route exact={true} path="/bundles" component={Bundles} />
      <Route exact={true} path="/bundle/:id" component={Bundle} />
    </>
  );
};

export default BundleRoutes;
