import React from 'react';
import { Spin } from 'antd';
import { getRandomLoadingMessage } from '../utils/loadingMessageSelector';

import './MessageLoader.css';

export default function MessageLoader({
  message = '',
  withRandom = true,
  className
}) {
  return (
    <div className={className || 'loader'}>
      <div className="loader-message">
        <div className="loader-spinner">
          <Spin />
        </div>
        {message}
        {withRandom && `${getRandomLoadingMessage()}...`}
      </div>
    </div>
  );
}
