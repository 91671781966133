import * as React from 'react';
const SvgGroupIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <g
      stroke="#000"
      fill="none"
      strokeLinecap="round"
      strokeWidth={1.3}
      opacity={0.85}
    >
      <rect width={8} height={8} rx={2} transform="matrix(1 0 0 -1 1 15)" />
      <path d="M9 12h1a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v1" />
      <path d="M12 9h1a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v1" />
    </g>
  </svg>
);
export default SvgGroupIcon;
