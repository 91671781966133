import * as API from '../../API';

export default async function handleActionButtonClick(
  comment,
  action,
  rowKeysBeingRepliedTo = null
) {
  const refreshOptions = {};
  const commentId = comment.key ? comment.key : comment.id;
  action =
    action === null || action === undefined
      ? comment.action.toLowerCase()
      : action.toLowerCase();

  if (action === 'publish') {
    await API.comment.update({
      id: commentId,
      attributes: { status: 'published' }
    });
  } else if (action === 'unpublish') {
    await API.comment.update({
      id: commentId,
      attributes: { status: 'pending' }
    });
  } else if (action === 'archive') {
    await API.comment.update({
      id: commentId,
      attributes: { status: 'unpublished' }
    });
  } else if (action === 'update') {
    comment.isBeingEdited = false;
    await API.comment.update({
      id: commentId,
      attributes: { bodyText: comment.commentText.bodyText }
    });
    comment.action = 'Edit';
    refreshOptions.softRefresh = true;
  } else if (action === 'unmark comment as faculty') {
    await API.comment.update({
      id: commentId,
      attributes: { FacultyAccountId: null }
    });
  } else if (action === 'mark comment as faculty') {
    const facultyResult = await API.faculty.where({
      filter: {
        account: comment.accountId
      }
    });
    await API.comment.update({
      id: commentId,
      attributes: { FacultyAccountId: facultyResult.data[0].id }
    });
  } else if (action === 'submit') {
    const newRowKeys = rowKeysBeingRepliedTo.filter(id => {
      return id !== commentId;
    });
    comment.isBeingRepliedTo = false;
    comment.action = 'Reply';
    refreshOptions.softRefresh = true;
    refreshOptions.newRowKeys = newRowKeys;
  }

  // these choices below don't require a refresh so we chain the refresh call to the else to refresh all the other actions
  if (action === 'reply') {
    rowKeysBeingRepliedTo.push(commentId);
    comment.isBeingRepliedTo = true;
    comment.action = 'Cancel';
    refreshOptions.softRefresh = true;
  } else if (action === 'cancel') {
    const newRowKeys = rowKeysBeingRepliedTo.filter(id => {
      return id !== commentId;
    });

    comment.isBeingRepliedTo = false;
    comment.action = 'Reply';
    refreshOptions.softRefresh = true;
    refreshOptions.newRowKeys = newRowKeys;
  } else if (action === 'edit') {
    comment.isBeingEdited = true;
    comment.action = 'Update';
    refreshOptions.softRefresh = true;
  } else {
    refreshOptions.hardRefresh = true;
  }

  return refreshOptions;
}
