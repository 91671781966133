// this helper from https://stackoverflow.com/a/39077686
export const rgbToHex = (r, g, b) => {
  return (
    '#' +
    [r, g, b]
      .map(x => {
        const value = x > 255 ? 255 : x;
        const hex = value.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
      })
      .join('')
  );
};

export const hexToRgb = hex => {
  return hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => '#' + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map(x => parseInt(x, 16));
};
