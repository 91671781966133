import React from 'react';
import { isString } from 'lodash';

const CourseSection = ({ title, className, children }) => {
  return (
    <div className={className || null}>
      {title && (
        <div className="section-header">
          {isString(title) ? (
            <h5>{title}</h5>
          ) : React.isValidElement(title) ? (
            title
          ) : null}
        </div>
      )}
      {children}
    </div>
  );
};

export default CourseSection;
