import React from 'react';
import { Route, Switch } from 'react-router-dom';

import TagsView from '../containers/content/tags/TagsView';
import TagsMergeView from '../containers/content/tags/TagsMergeView';
import QuestionsView from '../containers/content/questions/QuestionsView';
import QuestionView from '../containers/content/questions/QuestionView';
import AudioChapterView from '../containers/content/audioContent/AudioContentView';
import AudioEpisodeView from '../containers/content/audioContent/AudioEpisodeView';
import AssessmentsView from '../containers/assessments/AssessmentsView';
import Assessment from '../containers/assessments/Assessment';
import CmeManage from '../containers/cme/CmeManage';
import CmeBucket from '../containers/cme/CmeBucket';
import CmeDisclosure from '../containers/cme/CmeDisclosure';

const ContentRoutes = () => {
  return (
    <Switch>
      <Route exact={true} path="/content/tags/:tagId?" component={TagsView} />
      <Route
        exact={true}
        path="/content/tags/merge/:tagId/:tagId2?"
        component={TagsMergeView}
      />
      <Route
        exact={true}
        path={['/content/chapters', '/content/chapter/:chapterId']}
        component={AudioChapterView}
      />
      <Route
        exact={true}
        path="/content/episode/:episodeId/cme/"
        component={CmeManage}
      />
      <Route
        exact={true}
        path={[
          '/content/episode/:episodeId/cme_disclosure/',
          '/content/episode/:episodeId/cme_disclosure/:disclosureId/'
        ]}
        component={CmeDisclosure}
      />
      <Route
        exact={true}
        path={[
          '/content/episode/:episodeId/cme_bucket/',
          '/content/episode/:episodeId/cme_bucket/:bucketId'
        ]}
        component={CmeBucket}
      />
      <Route
        exact={true}
        path={[
          '/content/episodes',
          '/content/episode/:episodeId',
          '/content/episode/:episodeId/:chapterId'
        ]}
        component={AudioEpisodeView}
      />
      <Route exact={true} path="/content/questions" component={QuestionsView} />
      <Route
        exact={true}
        path="/content/questions/:id"
        component={QuestionView}
      />
      <Route
        exact={true}
        path="/content/assessments/:assessmentId"
        render={({ match }) => (
          <Assessment assessmentId={match.params.assessmentId} />
        )}
      />
      <Route
        exact={true}
        path="/content/assessments"
        component={AssessmentsView}
      />
    </Switch>
  );
};

export default ContentRoutes;
