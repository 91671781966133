import _ from 'underscore';
import React from 'react';
import moment from 'moment';
import isBefore from 'date-fns/isBefore';
import bt from 'braintree-web';
import { Link, withRouter } from 'react-router-dom';
import { notify } from 'react-notify-toast';

import { Label } from 'reactstrap';
import {
  Alert,
  Breadcrumb,
  Button,
  Collapse,
  DatePicker,
  Input,
  Modal,
  Row,
  Col,
  Checkbox,
  Select,
  Space,
  Spin,
  Popconfirm,
  Table,
  Tag,
  Badge,
  Divider
} from 'antd';

import {
  DeleteOutlined,
  ToolOutlined,
  PlusOutlined,
  WarningOutlined,
  MailOutlined,
  ForkOutlined,
  CrownOutlined,
  CrownFilled
} from '@ant-design/icons';

import EditableLabel from '../components/EditableLabel/EditableLabel';
import { ResourceSelectorModal } from '../components/ResourceSelectorModal';
import CustomEmailModal from '../components/CustomEmailModal/CustomEmailModal';
import AccountMergeModal from '../components/AccountMergeModal/AccountMergeModal';
import { AddSubscription } from './course/AddSubscriptionModal/AddSubscription';
import Notes from '../components/Notes/Notes';
import CmeCertificateDownload from './cme/CmeCertificateDownload';
import { OrdersTable } from './account/OrdersTable';
import { SubscriptionTable } from './account/SubscriptionTable';
import DELETE_SUBSCRIPTION_MUTATION from './account/DeleteSubscriptionMutation';

import * as API from '../API';
import config from '../config';
import { getTokenFromCookie } from '../utils/cookie';

import { emailExists, validateEmail } from '../utils/email';
import { gql, ApolloConsumer } from '@apollo/client';

import './Account.css';
import SendResetPasswordUrlButton from './SendResetPasswordUrlButton';
import CopyPasswordResetUrl from './CopyPasswordResetUrl';

const { Panel } = Collapse;

function badgeForBoolean(bool) {
  return bool ? <Tag color="green">On</Tag> : <Tag color="red">Off</Tag>;
}

// TODO: put both of these instances into a reuseable HostedFields object
const hide = { display: 'none' };
const ELEMENT_IDS = {
  ACCOUNT: 'card-number',
  CVV: 'cvv',
  EXP: 'expiration-date'
};
const inputContainer = {
  height: 40,
  border: '1px solid #ccc',
  borderRadius: 3,
  marginBottom: 12
};
const urlBase = process.env.REACT_APP_JSONAPI_SERVER;

const userTableTitleCss = {
  fontSize: '1rem',
  fontWeight: 'bold'
};

class Account extends React.Component {
  state = {
    account: null,
    subscriptions: [],
    orders: [],
    groupedSubscriptions: [],
    activeTab: null,
    groupName: null,
    error: null,
    loading: true,
    modalSubscription: null, // editing payment method

    modalSubscriptionEndDate: null,
    modalEndDateSuccess: null,
    modalEndDateError: null,

    modalSubscriptionStartDate: null,
    modalStartDateSuccess: null,
    modalStartDateError: null,

    selectedStartDateInModal: moment(),
    selectedEndDateInModal: moment(),

    transactionsToShow: null,

    hostedFieldsInstance: null,
    isValidCardInfo: false,

    loadingNotificationSettings: true,
    notificationSettings: [],

    modalLoading: false,
    modalError: null,
    modalSuccess: null,
    newPaymentMethodNonce: null,
    allProfessions: [],
    allSpecialties: [],
    allCountries: [],
    allProvStates: [],
    showAddToGroup: false,
    showEmailModal: false,
    showMergeModal: false,
    showAddSubscription: false
  };

  async cancelBraintreeSubscription(id) {
    if (
      window.confirm(
        'Are you sure you want to cancel the auto renewal for this subscription?'
      )
    ) {
      const response = await fetch(
        `${urlBase}/api/subscription/${id}/itemAutoRenew`,
        {
          method: 'DELETE',
          credentials: 'same-origin',
          headers: {
            Authorization: `Bearer ${getTokenFromCookie()}`
          }
        }
      );
      if (response.status === 200) {
        notify.show('Subscription canceled in Braintree', 'success');
        window.setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        notify.show(
          'Error canceling Braintree subscription. Check Braintree directly to confirm, or to complete this cancellation.',
          'error'
        );
      }
    }
  }

  async deleteSubscription(id, deletedAt) {
    if (
      window.confirm(
        deletedAt
          ? 'Are you sure you want to PERMANENTLY REMOVE this subscription?'
          : 'Are you sure you want to delete this subscription and cancel any corresponding auto renewal?'
      )
    ) {
      if (deletedAt) {
        const response = await this.props.client.mutate({
          mutation: DELETE_SUBSCRIPTION_MUTATION,
          variables: {
            subscriptionId: id,
            force: !!deletedAt
          }
        });
        if (response.data.adminDeleteSubscription) {
          notify.show(
            'Subscription successfully PERMANENTLY deleted from account!',
            'success'
          );
          window.setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          notify.show('Error PERMANENTLY deleting subscription', 'error');
        }
      } else {
        const res = await fetch(`${urlBase}/api/subscription/${id}`, {
          method: 'DELETE',
          credentials: 'same-origin',
          headers: {
            Authorization: `Bearer ${getTokenFromCookie()}`
          }
        });
        const apiMessage = await res.json();
        if (res.status === 200) {
          const durationOfMsToReadMessage =
            apiMessage.message.length > 60 ? 10000 : 2000;
          notify.show(apiMessage.message, 'success', durationOfMsToReadMessage);
          window.setTimeout(() => {
            window.location.reload();
          }, durationOfMsToReadMessage);
        } else {
          notify.show(apiMessage.error, 'error');
        }
      }
    }
  }

  async fetchNotificationSettings() {
    this.setState({ loadingNotificationSettings: true });
    const settingsResponse = await fetch(
      `${urlBase}/api/account/notifications?AccountId=${this.props.match.params.id}`,
      {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          Authorization: `Bearer ${getTokenFromCookie()}`
        }
      }
    );
    if (settingsResponse.code >= 400) {
      throw new Error('Error fetching notification settings.');
    }
    const notificationSettings = await settingsResponse.json();

    this.setState({
      loadingNotificationSettings: false,
      notificationSettings
    });
  }

  isResident() {
    return this.state.account.profession?.title.startsWith('Resident');
  }

  async componentDidMount() {
    try {
      /*****************************************************/
      /*  Fetch the account                                */
      /*****************************************************/
      this.setState({ loading: true });

      const ADMIN_ACCOUNT_PAGE_INFO_QUERY = gql`
        query AdminAccountPageInfo {
          professionsList(orderBy: DISPLAY_ORDER_ASC) {
            id
            title
          }
          specialtiesList(orderBy: DISPLAY_ORDER_ASC) {
            id
            title
          }
          coursesList(orderBy: TITLE_ASC) {
            id
            title
          }
          countriesList(orderBy: TITLE_ASC) {
            id
            title
          }
          provStatesList(orderBy: TITLE_ASC) {
            id
            title
          }
        }
      `;

      const adminAccountPageInfo = await this.props.client.query({
        query: ADMIN_ACCOUNT_PAGE_INFO_QUERY
      });

      const [response, account, orders] = await Promise.all([
        fetch(`${urlBase}/api/account/${this.props.match.params.id}`, {
          method: 'GET',
          credentials: 'same-origin',
          headers: {
            Authorization: `Bearer ${getTokenFromCookie()}`
          }
        }),
        this.loadAccount(),
        this.loadOrders(),
        this.fetchNotificationSettings()
      ]);

      const data = await response.json();

      const groupName = account.programBindings
        .filter(groupBinding => groupBinding.deletedAt === null)
        .map(groupBinding => groupBinding.program.name);

      const groupedSubscriptions = this.groupSubscriptions(
        data.subscriptions,
        adminAccountPageInfo.data.coursesList
      );
      const coursesMap = this.createCoursesMap(
        adminAccountPageInfo.data.coursesList
      );

      this.setState({
        loading: false,
        account,
        subscriptions: data.subscriptions,
        orders,
        groupedSubscriptions,
        courses: coursesMap,
        allProfessions: adminAccountPageInfo.data.professionsList,
        allSpecialties: adminAccountPageInfo.data.specialtiesList,
        allCountries: adminAccountPageInfo.data.countriesList,
        allProvStates: adminAccountPageInfo.data.provStatesList,
        groupName: groupName.length === 1 ? groupName[0] : null
      });

      /*****************************************************/
      /*  Fetch client token                               */
      /*****************************************************/
      const clientTokenResponse = await fetch(
        `${urlBase}/api/braintree/client-token?forAccountId=${data.account.id}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getTokenFromCookie()
          },
          credentials: 'same-origin'
        }
      );
      const token = await clientTokenResponse.text();

      /*****************************************************/
      /*  Init hosted fields                               */
      /*****************************************************/
      const client = await bt.client.create({
        authorization: token
      });
      const hostedFieldsInstance = await bt.hostedFields.create({
        client,
        styles: {
          input: {
            'font-size': '14pt',
            color: '#3A3A3A',
            padding: '0 0 0 4px',
            height: 40,
            border: '1px solid #ccc',
            'border-radius': '3px'
          },
          '.number': {
            'font-family': 'monospace',
            width: '250px'
          },
          '.valid': {
            color: 'limegreen'
          },
          '.invalid': {
            color: 'tomato'
          },
          '#cvv': {
            width: 60
          }
        },
        fields: {
          number: {
            selector: `#${ELEMENT_IDS.ACCOUNT}`,
            placeholder: '1111 1111 1111 1111'
          },
          cvv: {
            selector: `#${ELEMENT_IDS.CVV}`,
            placeholder: '•••'
          },
          expirationDate: {
            selector: `#${ELEMENT_IDS.EXP}`,
            placeholder: '12/34'
          }
        }
      });
      this.setState({ hostedFieldsInstance });

      hostedFieldsInstance.on('validityChange', hf => {
        this.setState({
          isValidCardInfo: Object.keys(hf.fields).reduce(
            (isValid, field) => isValid && hf.fields[field].isValid,
            true
          )
        });
      });
    } catch (error) {
      this.setState({ error, loading: false });
    }
  }

  createCoursesMap(courses) {
    return courses.reduce((result, course) => {
      result[course.id] = { title: course.title };
      return result;
    }, {});
  }

  handleTabChange(courseId) {
    this.setState({ activeTab: courseId });
  }

  groupSubscriptions(subscriptions, validCourses) {
    const validCourseMap = {};
    validCourses.forEach(course => {
      validCourseMap[course.id] = course.title;
    });
    const groupedSubscriptions = subscriptions
      .filter(subscription => validCourseMap[subscription.hippoSub.CourseId])
      .sort((a, b) =>
        validCourseMap[a.hippoSub.CourseId].localeCompare(
          validCourseMap[b.hippoSub.CourseId]
        )
      )
      .reduce((result, subscription) => {
        // ensure that every subscription is for a valid course, first
        if (validCourseMap[subscription.hippoSub.CourseId]) {
          if (result[subscription.hippoSub.CourseId]) {
            result[subscription.hippoSub.CourseId] = [
              ...result[subscription.hippoSub.CourseId],
              subscription
            ];
          } else {
            result[subscription.hippoSub.CourseId] = [subscription];
          }
        }

        return result;
      }, {});

    this.setState({ activeTab: Object.keys(groupedSubscriptions)[0] });

    return groupedSubscriptions;
  }

  async loadAccount() {
    const accountResult = await API.account.find({
      id: this.props.match.params.id,
      options: {
        include:
          'profession,programBindings,programBindings.program,specialties,specialties.specialty,shippingAddress,billingAddress,shippingAddress.country,shippingAddress.provState,billingAddress.country,billingAddress.provState'
      }
    });

    const account = API.simplifyResource(accountResult);

    const facultyResult = await API.faculty.where({
      filter: {
        account: this.props.match.params.id
      }
    });

    const faculty = API.simplifyResource(facultyResult);
    if (faculty.length > 0) {
      account.faculty = faculty[0];
    }

    return account;
  }

  async reloadAccount() {
    const account = await this.loadAccount();
    this.setState({
      account
    });
  }

  async loadOrders() {
    const url = new URL(`${urlBase}/api/order`);
    url.search = new URLSearchParams({
      AccountId: this.props.match.params.id
    }).toString();

    const ordersResult = await fetch(url, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${getTokenFromCookie()}`
      }
    });

    const orderData = await ordersResult.json();

    const sortedOrders = orderData.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    return sortedOrders;
  }

  async handleRoleChange(newRoleId) {
    await API.account.update({
      id: this.state.account.id,
      relationships: {
        profession: {
          data: {
            type: 'profession',
            id: newRoleId
          }
        }
      }
    });
    this.reloadAccount();
  }

  async turnOffAllNotifications() {
    try {
      const clientTokenResponse = await fetch(
        `${urlBase}/api/account/notifications/disable?AccountId=${this.props.match.params.id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getTokenFromCookie()}`
          },
          credentials: 'same-origin'
        }
      );

      const status = clientTokenResponse.status;
      const data = await clientTokenResponse.json();

      if (status >= 400) {
        notify.show(data.message, 'error');
      } else {
        notify.show(
          'All notifications have been turned off for this user.',
          'success'
        );
        setTimeout(this.fetchNotificationSettings.bind(this), 1000);
      }
    } catch (e) {
      notify.show(e.message, 'error');
    }
  }

  async updatePaymentInfo() {
    try {
      this.setState({
        modalSuccess: null,
        modalError: null,
        modalLoading: true
      });

      const payload = await this.state.hostedFieldsInstance.tokenize();
      const paymentMethodNonce = payload.nonce;

      await fetch(`${urlBase}/pages/settings/payment-methods`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getTokenFromCookie()}`
        },
        body: JSON.stringify({
          paymentMethodNonce,
          btSubId: this.state.modalSubscription.btSub.id,
          hippoSubId: this.state.modalSubscription.hippoSub.id,
          accountId: this.state.account.id
        })
      });

      this.setState({
        modalSuccess: 'Successfully updated payment info.',
        modalLoading: false
      });
      window.setTimeout(() => {
        this.setState({
          modalSuccess: null,
          modalSubscription: null,
          modalLoading: false
        });
      }, 2500);
    } catch (e) {
      this.setState({ modalError: e.message });
    }
  }

  editStartDate(subscription) {
    this.setState({
      modalSubscriptionStartDate: subscription,
      selectedStartDateInModal: moment(subscription.hippoSub.startedAt)
    });
  }

  editEndDate(subscription) {
    this.setState({
      modalSubscriptionEndDate: subscription,
      selectedEndDateInModal: moment(subscription.hippoSub.endedAt)
    });
  }

  async saveNewStartDate() {
    try {
      const subscriptionId = this.state.modalSubscriptionStartDate.hippoSub.id;

      const response = await fetch(
        `${urlBase}/api/subscription/${subscriptionId}`,
        {
          method: 'PUT',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getTokenFromCookie()}`
          },
          body: JSON.stringify({
            startedAt: this.state.selectedStartDateInModal
          })
        }
      );

      const status = response.status;
      const data = await response.json();
      if (status < 400) {
        this.setState({
          modalStartDateSuccess: 'Successfully updated date'
        });
        window.setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        this.setState({ modalStartDateError: data.error });
      }
    } catch (e) {
      this.setState({ modalStartDateError: e.message });
    }
  }

  async saveNewEndDate() {
    try {
      const subscriptionId = this.state.modalSubscriptionEndDate.hippoSub.id;

      const response = await fetch(
        `${urlBase}/api/subscription/${subscriptionId}`,
        {
          method: 'PUT',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getTokenFromCookie()}`
          },
          body: JSON.stringify({
            endedAt: this.state.selectedEndDateInModal
          })
        }
      );

      const status = response.status;
      const data = await response.json();
      if (status < 400) {
        this.setState({
          modalEndDateSuccess: 'Successfully updated date'
        });
        window.setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        this.setState({ modalEndDateError: data.error });
      }
    } catch (e) {
      this.setState({ modalEndDateError: e.message });
    }
  }

  updateAccount = async (field, value) => {
    try {
      await API.account.update({
        id: this.state.account.id,
        attributes: {
          [field]: value
        }
      });

      this.reloadAccount();
    } catch (error) {
      if (error.data) {
        notify.show(error.data.errors[0].detail[0].message, 'error');
      } else {
        throw error;
      }
    }
  };

  handleSpecialtyChange = async value => {
    const existingSpecialties = this.state.account.specialties;
    try {
      if (value.length < existingSpecialties.length) {
        // A specialty was removed.
        const specialtyToRemove = existingSpecialties.find(
          specialty => !value.includes(specialty.specialty.id)
        );
        await API.accountSpecialtyBinding.delete({
          id: specialtyToRemove.id
        });
      } else {
        // A specialty was added.
        const specialtyIds = this.state.account.specialties.map(
          specialty => specialty.specialty.id
        );
        const specialtyToAdd = value.find(
          specialty => !specialtyIds.includes(specialty)
        );
        await API.accountSpecialtyBinding.create({
          relationships: {
            account: {
              data: {
                type: 'account',
                id: this.state.account.id
              }
            },
            specialty: {
              data: {
                type: 'specialty',
                id: specialtyToAdd
              }
            }
          }
        });
      }
      this.reloadAccount();
      if (this.state.account.faculty) {
        await this.onFacultyUpdate();
      }
    } catch (error) {
      if (error.data) {
        notify.show(error.data.errors[0].detail[0].message, 'error');
      } else {
        throw error;
      }
    }
  };

  createAddress = async type => {
    const addressResult = await API.accountAddress.create({
      attributes: {
        firstName: this.state.account.firstName,
        lastName: this.state.account.lastName,
        suffix: this.state.account.suffix,
        company: this.state.account.company,
        phone: this.state.account.phone,
        city: 'N/A',
        line1: 'N/A'
      },
      relationships: {
        country: {
          data: {
            type: 'country',
            id: config.defaults.defaultCountry
          }
        }
      }
    });
    const newAddress = API.simplifyResource(addressResult);
    this.setState({ [type]: newAddress });
    await API.account.update({
      id: this.state.account.id,
      relationships: {
        [type]: {
          data: {
            type: 'accountAddress',
            id: newAddress.id
          }
        }
      }
    });
    return newAddress.id;
  };

  updateMemberIdentifier = async (programAccountBindingId, value) => {
    await API.programAccountBinding.update({
      id: programAccountBindingId,
      attributes: {
        memberIdentifier: value
      }
    });
  };

  updateAddress = async (type, id, key, value) => {
    let addressId = id;
    if (!addressId) {
      addressId = await this.createAddress(type);
    }
    await API.accountAddress.update({
      id: addressId,
      attributes: {
        [key]: value
      }
    });

    this.reloadAccount();
  };

  updateAddressRelationship = async (type, id, key, value) => {
    let addressId = id;
    if (!addressId) {
      addressId = await this.createAddress(type);
    }
    await API.accountAddress.update({
      id: addressId,
      relationships: {
        [key]: {
          data: {
            type: key,
            id: value
          }
        }
      }
    });

    this.reloadAccount();
  };

  createGroupMemberIdentifierLabel = (
    programAccountBindingId,
    defaultValue
  ) => {
    return (
      <Space>
        <Input
          defaultValue={defaultValue}
          onChange={e => {
            this.updateMemberIdentifier(
              programAccountBindingId,
              e.target.value
            );
          }}
        />
      </Space>
    );
  };

  createAddressLabel = (type, title, key) => {
    return (
      <Space>
        <Input
          defaultValue={
            this.state.account[type] && this.state.account[type][key]
              ? this.state.account[type][key]
              : 'N/A'
          }
          onChange={e => {
            if (e.target.value !== 'N/A') {
              this.updateAddress(
                type,
                this.state.account[type]?.id,
                key,
                e.target.value
              );
            }
          }}
        />
      </Space>
    );
  };

  createAddressDropdown = (type, possibleValues, title, key) => {
    const address = this.state.account[type];
    return (
      <Space>
        <Select
          defaultValue={
            this.state.account[type] && this.state.account[type][key]?.id
              ? this.state.account[type][key].id
              : key === 'country'
              ? config.defaults.defaultCountry
              : 'State/Province'
          }
          className="account__property-select"
          onChange={value => {
            if (value !== 'State/Province') {
              this.updateAddressRelationship(type, address?.id, key, value);
            }
          }}
        >
          {possibleValues.map(option => (
            <Select.Option key={option.id}>{option.title}</Select.Option>
          ))}
        </Select>
      </Space>
    );
  };

  fillAddress = type => {
    return (
      <Space direction="horizontal">
        {this.createAddressLabel(type, 'Street Address:', 'line1')}
        {this.createAddressLabel(type, 'City:', 'city')}
        {this.createAddressDropdown(
          type,
          this.state.allProvStates,
          'State/Province:',
          'provState'
        )}
        {this.createAddressDropdown(
          type,
          this.state.allCountries,
          'Country:',
          'country'
        )}
      </Space>
    );
  };

  removeAccount = async bindingId => {
    await API.programAccountBinding.update({
      id: bindingId,
      attributes: {
        deletedAt: new Date()
      }
    });
    this.reloadAccount();
  };

  fillGroupsTable = () => {
    return (
      <Table
        style={{ backgroundColor: '#fff' }}
        pagination={false}
        columns={[
          {
            title: 'Group Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
              <Link to={'/group-management/group/' + record.programId}>
                {record.name}
              </Link>
            )
          },
          {
            title: 'Added',
            dataIndex: 'created',
            key: 'created'
          },
          {
            title: 'Group Member ID',
            dataIndex: 'memberIdentifier',
            key: 'memberIdentifier',
            render: (text, record) => {
              return this.createGroupMemberIdentifierLabel(
                record.id,
                record.memberIdentifier
              );
            }
          },
          {
            title: '',
            key: 'delete',
            render: (text, record) => (
              <>
                <Popconfirm
                  title="Remove this member from this group?"
                  icon={<WarningOutlined style={{ color: 'red' }} />}
                  onConfirm={() => this.removeAccount(record.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    type="danger"
                    icon={<DeleteOutlined />}
                    size="small"
                    title="Remove member from group"
                  />
                </Popconfirm>
              </>
            )
          }
        ]}
        dataSource={this.state.account.programBindings
          .filter(binding => binding.deletedAt === null)
          .map(binding => {
            return {
              key: binding.id,
              id: binding.id,
              programId: binding.program.id,
              name: binding.program.name,
              created: moment(binding.createdAt).format('MM/DD/YY'),
              memberIdentifier: binding.memberIdentifier
            };
          })}
      />
    );
  };

  addToGroups = async groups => {
    await Promise.all(
      groups.map(group =>
        API.programAccountBinding.create({
          attributes: {
            isAdmin: false,
            isMember: true,
            hasAccepted: true
          },
          relationships: {
            account: {
              data: {
                id: this.state.account.id,
                type: 'account'
              }
            },
            program: { data: { id: group, type: 'program' } }
          }
        })
      )
    );
    this.setState({
      showAddToGroup: false
    });
    this.reloadAccount();
  };

  getResourceSelectorModalProps = () => {
    return {
      title: 'Groups',
      allowNullSelection: true,
      multiSelect: true,
      apiResource: API.program,
      additionalPaginatedListProps: {},
      searchPlaceholder: 'Search by Group Name',
      searchAction: (actions, value) =>
        actions.setFilter('name', value && `:${value}`),
      renderHeader: () => (
        <Row>
          <Col span={2}>Select</Col>
          Title
        </Row>
      ),
      renderItem: (index, program, isSelected, onClick) => (
        <Row onClick={onClick}>
          <Col span={2}>
            <Checkbox checked={isSelected} />
          </Col>
          {program.name}
        </Row>
      ),
      disabledIds: this.state.account.programBindings.map(
        binding => binding.program.id
      ),
      initialSelectedIds: [],
      visible: true,
      okButtonText: 'Select Group',
      onSelect: this.addToGroups,
      onClose: () => {
        this.setState({ showAddToGroup: false });
      }
    };
  };

  onFacultyUpdate = async () => {
    await API.faculty.update({
      id: this.state.account.faculty.id,
      attributes: {
        fullName: `${this.state.account.firstName} ${this.state.account.lastName}`,
        title: [],
        imageIconName: 'default',
        specialty: this.state.account.specialties.map(
          specialty => specialty.specialty.title
        ),
        currentInstitution: this.state.account.institution,
        isArchived: false,
        hasNoDisclosures: false
      }
    });
    await this.reloadAccount();
  };

  createFaculty = async () => {
    await API.faculty.create({
      attributes: {
        fullName: `${this.state.account.firstName} ${this.state.account.lastName}`,
        title: [],
        imageIconName: 'default',
        specialty: this.state.account.specialties.map(
          specialty => specialty.specialty.title
        ),
        currentInstitution: this.state.account.institution
          ? [this.state.account.institution]
          : [],
        isArchived: false,
        hasNoDisclosures: false
      },
      relationships: {
        account: {
          data: {
            type: 'account',
            id: this.state.account.id
          }
        }
      }
    });
    await this.reloadAccount();
  };

  render() {
    const requestedMarketingEmailsDate = this.state.account
      ? new Date(this.state.account.requestedMarketingEmailsUpdatedAt)
      : null;

    return this.state.loading ? (
      <>
        <Spin />
        <p>Loading...</p>
      </>
    ) : (
      <div className="account">
        <Breadcrumb tag="nav" style={{ padding: '20px 0' }}>
          <Breadcrumb.Item>
            <Link to="/dashboard">Hippo Admin</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={{
                pathname: '/account/search',
                state: { ...this.props.location.state }
              }}
            >
              Account Search
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active="true">
            <Link to={`/account/${this.state.account.id}`}>
              {this.state.account.firstName} {this.state.account.lastName}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <Collapse defaultActiveKey={['1']}>
          <Panel header="Account Info" key="1">
            <Space direction="vertical">
              <Space direction="horizontal" className="account__page-header">
                <Button
                  icon={<ForkOutlined />}
                  size="small"
                  title="Merge Account"
                  onClick={() => {
                    this.setState({
                      showMergeModal: true
                    });
                  }}
                >
                  Merge Account
                </Button>
                <Button
                  icon={<MailOutlined />}
                  size="small"
                  title="Send email"
                  className="account__email-button"
                  onClick={() => {
                    this.setState({
                      showEmailModal: true
                    });
                  }}
                >
                  Send Email
                </Button>

                <CustomEmailModal
                  accountIds={[this.state.account.id]} // pass in as an array as this can technically be multiple users via group view
                  groupName={
                    this.state.groupName ? this.state.groupName : 'a group'
                  }
                  showModal={this.state.showEmailModal}
                  setShowModal={state =>
                    this.setState({ showEmailModal: state })
                  }
                />
                <AccountMergeModal
                  accountId={this.state.account.id}
                  showModal={this.state.showMergeModal}
                  setShowModal={state =>
                    this.setState({ showMergeModal: state })
                  }
                />
              </Space>
              <Space className="account__reset-password-container">
                <SendResetPasswordUrlButton
                  accountEmail={this.state.account.email}
                  onSuccess={() => this.reloadAccount()}
                />
                {this.state.account.passwordTag &&
                isBefore(
                  new Date(),
                  new Date(this.state.account.passwordTagExpiredAt)
                ) ? (
                  <CopyPasswordResetUrl
                    passwordTag={this.state.account.passwordTag}
                    passwordTagExpiredAt={
                      this.state.account.passwordTagExpiredAt
                    }
                  />
                ) : null}
              </Space>
              <Table
                pagination={false}
                columns={[
                  {
                    title: <div style={userTableTitleCss}>Name</div>,
                    dataIndex: 'name',
                    key: 'name',
                    render: (text, record) => {
                      return (
                        <>
                          <EditableLabel
                            defaultValue={
                              record.firstName ? record.firstName : 'N/A'
                            }
                            onChange={value => {
                              if (value !== 'N/A') {
                                this.updateAccount('firstName', value);
                              }
                            }}
                          />
                          <span> </span>
                          <EditableLabel
                            defaultValue={
                              record.lastName ? record.lastName : 'N/A'
                            }
                            onChange={value => {
                              if (value !== 'N/A') {
                                this.updateAccount('lastName', value);
                              }
                            }}
                          />
                        </>
                      );
                    }
                  },
                  {
                    title: <div style={userTableTitleCss}>Email</div>,
                    dataIndex: 'email',
                    key: 'email',
                    render: (text, record) => {
                      return (
                        <EditableLabel
                          defaultValue={record.email ? record.email : 'N/A'}
                          onChange={async value => {
                            const cleanEmail = value.toLowerCase().trim();
                            const emailAlreadyExists = await emailExists(
                              cleanEmail
                            );
                            if (value !== 'N/A') {
                              if (validateEmail(cleanEmail)) {
                                if (emailAlreadyExists) {
                                  notify.show(
                                    'An account with this email address already exists.',
                                    'error'
                                  );
                                } else {
                                  this.updateAccount('email', cleanEmail);
                                }
                              } else {
                                notify.show('Invalid email address', 'error');
                              }
                            }
                          }}
                        />
                      );
                    }
                  },
                  {
                    title: <div style={userTableTitleCss}>Account Created</div>,
                    dataIndex: 'created',
                    key: 'created'
                  },
                  {
                    title: <div style={userTableTitleCss}>Designation(s)</div>,
                    key: 'suffix',
                    render: (text, record) => {
                      return (
                        <EditableLabel
                          defaultValue={
                            record.suffix || record.suffix.length
                              ? record.suffix
                              : 'N/A'
                          }
                          onChange={value => {
                            if (value !== 'N/A') {
                              this.updateAccount('suffix', value);
                            }
                          }}
                        />
                      );
                    }
                  },
                  {
                    title: <div style={userTableTitleCss}>Role</div>,
                    key: 'role',
                    render: (text, record) => (
                      <Select
                        defaultValue={
                          this.state.account.profession?.id
                            ? this.state.account.profession.id
                            : 'Select member role'
                        }
                        className="account__property-select"
                        onChange={value => this.handleRoleChange(value)}
                      >
                        {this.state.allProfessions.map(profession => (
                          <Select.Option key={profession.id}>
                            {profession.title}
                          </Select.Option>
                        ))}
                      </Select>
                    )
                  }
                ]}
                dataSource={[
                  {
                    key: `${this.state.account.id}`,
                    name: `${this.state.account.firstName} ${this.state.account.lastName}`,
                    firstName: this.state.account.firstName,
                    lastName: this.state.account.lastName,
                    email: this.state.account.email.toLowerCase().trim(),
                    created: moment(this.state.account.createdAt).format(
                      'MMMM Do YYYY, h:mm:ss a'
                    ),
                    suffix: this.state.account.suffix || '',
                    role: this.state.account.profession?.title || ''
                  }
                ]}
              />
              <Space>
                <Button
                  size="small"
                  onClick={() => {
                    this.updateAccount('isAdmin', !this.state.account.isAdmin);
                  }}
                >
                  {this.state.account.isAdmin ? (
                    <CrownFilled />
                  ) : (
                    <CrownOutlined />
                  )}
                  {this.state.account.isAdmin ? 'Is an Admin' : 'Not an Admin'}
                </Button>
                {this.state.account.faculty != null ? (
                  <Button size="small">
                    <Link
                      to={`/accounts/faculty/${this.state.account.faculty.id}`}
                    >
                      View Faculty Record
                    </Link>
                  </Button>
                ) : (
                  <Button
                    size="small"
                    onClick={() => {
                      this.createFaculty();
                    }}
                  >
                    Add to Faculty
                  </Button>
                )}
                {!this.isResident() && (
                  <div>
                    <Label className="account__graduated-at-label">
                      Graduation Date
                    </Label>
                    <DatePicker
                      defaultValue={
                        this.state.account.graduatedAt
                          ? moment(this.state.account.graduatedAt)
                          : ''
                      }
                      allowClear={true}
                      format="MM/DD/YYYY"
                      onChange={value =>
                        this.updateAccount('graduatedAt', value)
                      }
                    />
                  </div>
                )}
              </Space>
              {this.state.account.requestedMarketingEmails !== null && (
                <Space>
                  {`User requested to be
                  ${
                    this.state.account.requestedMarketingEmails
                      ? 'subscribed to'
                      : 'unsubscribed from'
                  }
                  marketing emails as of ${requestedMarketingEmailsDate.getMonth() +
                    1}/${requestedMarketingEmailsDate.getDate()}/${requestedMarketingEmailsDate.getFullYear()}`}
                </Space>
              )}
            </Space>
            <Divider />
            {this.isResident() && (
              <>
                <Space direction="vertical">
                  <h5>Residency Info</h5>
                  <Label>Graduation Date</Label>
                  <DatePicker
                    defaultValue={
                      this.state.account.graduatedAt
                        ? moment(this.state.account.graduatedAt)
                        : ''
                    }
                    allowClear={false}
                    format="MM/DD/YYYY"
                    onChange={value => this.updateAccount('graduatedAt', value)}
                  />
                </Space>
                <hr />
              </>
            )}
            <Space direction="vertical">
              <h5>Groups</h5>
              {this.fillGroupsTable()}
              <Button
                type="primary"
                size="small"
                onClick={() => this.setState({ showAddToGroup: true })}
              >
                <PlusOutlined />
                Add to Group
              </Button>
            </Space>
            <hr />
            <div style={{ width: '100%', height: '25px' }} />
          </Panel>
          <Panel header="Billing Info" key="2">
            <Space direction="vertical">
              <Space direction="vertical" className="account__address-block">
                <Label>Billing Address</Label>
                {this.fillAddress('billingAddress')}
                <Label>Shipping Address</Label>
                {this.fillAddress('shippingAddress')}
              </Space>
              <Space>
                <div className="account__account-details-title">
                  <Label>Current Institution</Label>
                </div>
                <EditableLabel
                  defaultValue={
                    this.state.account.institution
                      ? this.state.account.institution
                      : 'N/A'
                  }
                  onChange={async value => {
                    if (value !== 'N/A') {
                      await this.updateAccount('institution', value);
                      if (this.state.account.faculty) {
                        await this.onFacultyUpdate();
                      }
                    }
                  }}
                />
              </Space>
              <Space>
                <div className="account__account-details-title">
                  <Label>Specialties</Label>
                </div>
                <Select
                  mode="multiple"
                  className="account__property-select"
                  defaultValue={this.state.account.specialties.map(
                    specialty => specialty.specialty.id
                  )}
                  onChange={value => this.handleSpecialtyChange(value)}
                >
                  {this.state.allSpecialties.map(specialty => (
                    <Select.Option key={specialty.id}>
                      {specialty.title}
                    </Select.Option>
                  ))}
                </Select>
              </Space>
              <Space>
                <div className="account__account-details-title">
                  <Label>AAPA Number</Label>
                </div>
                <EditableLabel
                  defaultValue={
                    this.state.account.aapaMemberId
                      ? this.state.account.aapaMemberId
                      : 'N/A'
                  }
                  onChange={value => {
                    if (value !== 'N/A') {
                      this.updateAccount('aapaMemberId', value);
                    }
                  }}
                />
              </Space>
            </Space>
          </Panel>
          <Panel header="CME" key="3">
            <CmeCertificateDownload
              accountId={this.state.account.id}
              courses={this.state.subscriptions.reduce(
                (subscriptions, subscription) => {
                  const course = subscriptions.find(
                    addedCourse =>
                      addedCourse.id === subscription.hippoSub.CourseId
                  );
                  if (!course) {
                    subscriptions.push({
                      id: subscription.hippoSub.CourseId,
                      title: subscription.hippoSub.title
                    });
                  }
                  return subscriptions;
                },
                []
              )}
              startDates={this.state.subscriptions
                .filter(
                  subscription => subscription.hippoSub.startedAt !== null
                )
                .map(subscription => subscription.hippoSub.startedAt)}
              onError={message => notify.show(message, 'error')}
            />

            <div style={{ width: '100%', height: '25px' }} />
            <Button
              type="primary"
              size="small"
              onClick={() =>
                this.props.history.push(
                  `/account/${this.props.match.params.id}/cme`
                )
              }
            >
              <ToolOutlined />
              Manage CME
            </Button>
          </Panel>
          <Panel header="Subscriptions" key="4">
            <Collapse
              onChange={this.handleTabChange.bind(this)}
              accordion={true}
              defaultActiveKey={this.state.activeTab}
            >
              {Object.keys(this.state.groupedSubscriptions).map(courseId => {
                const count = this.state.groupedSubscriptions[courseId].reduce(
                  (count, subscription) => {
                    if (
                      (subscription.hippoSub.endedAt &&
                        new Date() <=
                          new Date(subscription.hippoSub.endedAt)) ||
                      subscription.hippoSub.endedAt === null // i.e for a subscription that has no endedAt, like UCB
                    ) {
                      count.active += 1;
                    }

                    if (
                      subscription.hippoSub.endedAt &&
                      new Date() > new Date(subscription.hippoSub.endedAt)
                    ) {
                      count.inactive += 1;
                    }

                    return count;
                  },
                  { active: 0, inactive: 0 }
                );

                return (
                  <Panel
                    header={
                      <span>
                        {this.state.courses[courseId]
                          ? this.state.courses[courseId].title
                          : this.state.groupedSubscriptions[courseId]
                          ? this.state.groupedSubscriptions[courseId].title
                          : ''}{' '}
                        <Badge
                          count={count.active}
                          style={{ backgroundColor: '#52c41a' }}
                        />
                        {count.inactive && count.inactive > 0 ? (
                          <>
                            {' - '}
                            <Badge
                              count={count.inactive}
                              style={{ backgroundColor: '#f5222d' }}
                            />
                          </>
                        ) : null}
                      </span>
                    }
                    key={courseId}
                  >
                    <SubscriptionTable
                      subscriptionsForCourse={
                        this.state.groupedSubscriptions[this.state.activeTab]
                          ? this.state.groupedSubscriptions[
                              this.state.activeTab
                            ]
                          : {}
                      }
                      handleEditStartDate={subscription =>
                        this.editStartDate(subscription)
                      }
                      handleEditEndDate={subscription =>
                        this.editEndDate(subscription)
                      }
                      handleShowBilling={subscription =>
                        this.setState({ modalSubscription: subscription })
                      }
                      handleCancelSubscription={subscriptionId =>
                        this.cancelBraintreeSubscription(subscriptionId)
                      }
                      handleDeleteSubscription={(subscriptionId, deletedAt) =>
                        this.deleteSubscription(subscriptionId, deletedAt)
                      }
                    />
                  </Panel>
                );
              })}
            </Collapse>
            <div style={{ width: '100%', height: '25px' }} />
            <Button
              type="primary"
              size="small"
              onClick={() => this.setState({ showAddSubscription: true })}
            >
              <PlusOutlined />
              Add Subscription
            </Button>
          </Panel>
          <Panel header="Orders" key="5">
            <OrdersTable
              orders={this.state.orders}
              accountId={this.props.match.params.id}
            />
          </Panel>
          <Panel header="Notifications" key="6">
            <Table
              style={{ backgroundColor: '#fff' }}
              pagination={false}
              columns={[
                {
                  title: 'Product',
                  dataIndex: 'product',
                  key: 'product'
                },
                {
                  title: 'New content',
                  dataIndex: 'isNewContentEmailEnabled',
                  key: 'isNewContentEmailEnabled'
                },
                {
                  title: 'Upgrade reminder',
                  dataIndex: 'isUpgradeReminderEmailEnabled',
                  key: 'isUpgradeReminderEmailEnabled'
                },
                {
                  title: 'Comment notification',
                  dataIndex: 'isCommentNotificationEmailEnabled',
                  key: 'isCommentNotificationEmailEnabled'
                },
                {
                  title: 'Expiry reminder',
                  dataIndex: 'isExpiryReminderEmailEnabled',
                  key: 'isExpiryReminderEmailEnabled'
                }
              ]}
              dataSource={this.state.notificationSettings.map(
                (settings, index) => {
                  return {
                    product: settings.Product.title,
                    isNewContentEmailEnabled: badgeForBoolean(
                      !!_.findWhere(settings.settings, {
                        isNewContentEmailEnabled: true
                      })
                    ),
                    isUpgradeReminderEmailEnabled: badgeForBoolean(
                      !!_.findWhere(settings.settings, {
                        isUpgradeReminderEmailEnabled: true
                      })
                    ),
                    isCommentNotificationEmailEnabled: badgeForBoolean(
                      !!_.findWhere(settings.settings, {
                        isCommentNotificationEmailEnabled: true
                      })
                    ),
                    isExpiryReminderEmailEnabled: badgeForBoolean(
                      !!_.findWhere(settings.settings, {
                        isExpiryReminderEmailEnabled: true
                      })
                    )
                  };
                }
              )}
            />
            {this.state.notificationSettings.length > 0 ? (
              <div className="disable__notifications">
                <Button onClick={this.turnOffAllNotifications.bind(this)}>
                  Turn all off
                </Button>
              </div>
            ) : null}
            <div style={{ width: '100%', height: '25px' }} />
          </Panel>
          <Panel header="Notes" key="7">
            {this.props.match.params.id && (
              <Notes
                bindingType="account"
                bindingTypeId={this.props.match.params.id}
                title="Account Notes"
              />
            )}
          </Panel>
        </Collapse>
        <Modal
          visible={
            !this.state.hostedFieldsInstance || !!this.state.modalSubscription
          }
          title={
            !this.state.hostedFieldsInstance
              ? 'Loading billing interface'
              : 'Update billing information'
          }
          onOk={() => this.setState({ modalSubscription: null })}
          onCancel={() => this.setState({ modalSubscription: null })}
          okButtonProps={{
            disabled: !this.state.hostedFieldsInstance
          }}
          cancelButtonProps={{
            disabled: !this.state.hostedFieldsInstance
          }}
        >
          {!this.state.hostedFieldsInstance ? (
            <p>
              Connecting with Braintree could take up to 10 seconds. Do not
              close this window.
            </p>
          ) : null}
          {this.state.modalError ? (
            <Alert message={this.state.modalError} type="error" />
          ) : null}
          {this.state.modalSuccess ? (
            <Alert type="success" message={this.state.modalSuccess} />
          ) : null}
          <div style={this.state.hostedFieldsInstance ? null : hide}>
            <div>Account number</div>
            <div style={inputContainer} id={ELEMENT_IDS.ACCOUNT} />
            <div>Expiration</div>
            <div style={inputContainer} id={ELEMENT_IDS.EXP} />
            <div>CVV</div>
            <div style={inputContainer} id={ELEMENT_IDS.CVV} />
          </div>
          <div style={this.state.hostedFieldsInstance ? hide : null}>
            <Spin />
          </div>
          <Button
            color="primary"
            style={{ marginRight: 6, marginTop: 18 }}
            disabled={!this.state.hostedFieldsInstance}
            onClick={this.updatePaymentInfo.bind(this)}
          >
            Update
          </Button>
        </Modal>
        {this.state.showAddToGroup && (
          <ResourceSelectorModal {...this.getResourceSelectorModalProps()} />
        )}
        {this.state.showAddSubscription && (
          <AddSubscription
            visible={true}
            account={this.state.account}
            onCancel={() => this.setState({ showAddSubscription: false })}
          />
        )}
        <Modal
          visible={!!this.state.modalSubscriptionStartDate}
          title="Edit start date"
          onOk={() => this.saveNewStartDate()}
          onCancel={() => this.setState({ modalSubscriptionStartDate: null })}
        >
          {this.state.modalSubscriptionStartDate ? (
            <div>
              {this.state.modalSubscriptionStartDate.hippoItem.title}
              &nbsp;
              {this.state.modalStartDateSuccess ? (
                <Alert message={this.state.modalStartDateSuccess} />
              ) : null}
              {this.state.modalStartDateError ? (
                <Alert type="error" message={this.state.modalStartDateError} />
              ) : null}
              <DatePicker
                onChange={date =>
                  this.setState({ selectedStartDateInModal: date })
                }
                selected={this.state.selectedStartDateInModal}
              />
            </div>
          ) : null}
        </Modal>
        <Modal
          visible={!!this.state.modalSubscriptionEndDate}
          title="Edit end date"
          onOk={() => this.saveNewEndDate()}
          onCancel={() => this.setState({ modalSubscriptionEndDate: null })}
        >
          {this.state.modalSubscriptionEndDate ? (
            <div>
              {this.state.modalSubscriptionEndDate.hippoItem.title}
              &nbsp;
              {this.state.modalEndDateSuccess ? (
                <Alert message={this.state.modalEndDateSuccess} />
              ) : null}
              {this.state.modalEndDateError ? (
                <Alert type="error" message={this.state.modalEndDateError} />
              ) : null}
              <DatePicker
                onChange={date =>
                  this.setState({ selectedEndDateInModal: date })
                }
                selected={this.state.selectedEndDateInModal}
              />
            </div>
          ) : null}
        </Modal>
      </div>
    );
  }
}

export default withRouter(props => (
  <ApolloConsumer>
    {client => <Account {...props} client={client} />}
  </ApolloConsumer>
));
