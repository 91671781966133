import { gql } from '@apollo/client';

const DELETE_ORGANIZATION_PROGRAM_BINDING_MUTATION = gql`
  mutation DeleteOrganizationProgramBinding($id: UUID!) {
    deleteOrganizationProgramBinding(input: { id: $id }) {
      clientMutationId
    }
  }
`;

export default DELETE_ORGANIZATION_PROGRAM_BINDING_MUTATION;
