import { gql } from '@apollo/client';

export const GET_COURSE_TYPES_QUERY = gql`
  query getCourseTypes {
    courseTypesList {
      id
      title
    }
  }
`;

export const GET_COURSE_IDS_BY_COURSE_TYPE_WITH_WEB_INTERNAL = gql`
  query GetCourseIdsByCourseTypeAndShowOnWebInternalTrue(
    $courseTypeTitlesToInclude: [String!]
  ) {
    courseTypesList(
      filter: { title: { in: $courseTypeTitlesToInclude } }
      orderBy: TITLE_ASC
    ) {
      title
      coursesList(condition: { showOnWebInternal: true }, orderBy: TITLE_ASC) {
        id
        title
      }
    }
  }
`;
