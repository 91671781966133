import React from 'react';
import debounce from 'lodash/debounce';
import { Editor } from '@tinymce/tinymce-react';

import 'tinymce';
import 'tinymce/themes/silver';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/table';
import 'tinymce/icons/default';

import 'tinymce/skins/ui/oxide/skin.css';

const settings = {
  // FOR FUTURE USE: If you need a different editor plugin configuraiton, add additional named configurations here.
  default: {
    height: 500,
    menubar: false,
    plugins: ['lists', 'paste', 'table', 'link'],
    toolbar: [
      'undo redo | formatselect | bold italic underline superscript subscript ' +
        '| bullist numlist outdent indent | removeformat '
    ],
    // use this to whitelist addtional style properties that the default paste configuration strips out
    paste_retain_style_properties: 'text-decoration'

    // For future debugging of issues with losing format while pasting, uncomment the below settings
    // paste_enable_default_filters: false, // this turns off all of the cleanup tinymce tries to do
    // paste_preprocess: function(plugin, args) {
    //   console.log(args); // filtering occurs before this, so this logs the html that's getting through the filters and into the paste function.
    //   args.content += ' preprocess'; // content-to-be-pasted can be further modified in-place here if just whitelisting styles and tags isn't enough
    // }
    // paste_word_valid_elements: "" // can be used to whitelist additional elements, the default element list can be found by searching for "defaultValidElements" in /node_modules/tinymce/plugins/paste/plugin.js
    // For further reading https://www.tiny.cloud/docs/plugins/opensource/paste/
  }
};

const RichTextEditor = ({
  className,
  defaultValue,
  onChange,
  configurationName = 'default',
  debounceOnChange = true,
  disabled = false
}) => {
  const onEditorChange =
    (debounceOnChange && debounce(onChange, 600)) || onChange;
  return (
    <div className={className}>
      <Editor
        initialValue={defaultValue}
        disabled={disabled}
        init={Object.assign(
          {
            // Editor css is included in webpack via above imports.
            // This setting prevents the component from trying to load a static file that doesn't exist.
            skin: false,
            // Content is edited in an injected iframe with content styles loaded on-demand,
            // since CRA's webpack won't allow us to pack this in a separate file dynamically
            // it's manually placed in /public and specified explicitly here
            content_css: process.env.PUBLIC_URL + '/tinymce/content.min.css'
          },
          settings[configurationName]
        )}
        onEditorChange={onEditorChange}
      />
    </div>
  );
};

export default RichTextEditor;
