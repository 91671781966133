import React, { useState } from 'react';
import orderBy from 'lodash/orderBy';
import { notify } from 'react-notify-toast';
import moment from 'moment';
import _ from 'underscore';
import { PlusOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Table, Card, Select, Collapse } from 'antd';
import EditableLabel from '../../components/EditableLabel/EditableLabel';
import { AddGroupSubscriptionModal } from '../course/AddSubscriptionModal/AddGroupSubscriptionModal';
import * as API from '../../API';

import './Group.css';

const { Panel } = Collapse;

export default function GroupSubscriptions({
  group,
  subscriptions,
  accountManagers,
  loadAccountManagers,
  loadSubscriptions,
  loadingSubscriptions
}) {
  const [showAddSubscription, setShowAddSubscription] = useState(false);

  const updateProgramCourseBinding = async (
    programCourseBindingId,
    field,
    value
  ) => {
    try {
      const programCourseBinding = await API.programCourseBinding.find({
        id: programCourseBindingId
      });

      const attributes = _.clone(programCourseBinding.data.attributes);
      const relationships = {};
      if (field === 'accountManager') {
        relationships.accountManager = {
          data: { id: value, type: 'account' }
        };
      } else if (field === 'notes') {
        attributes[field] = value;
      }

      await API.programCourseBinding.update({
        id: programCourseBindingId,
        attributes,
        relationships
      });

      field === 'accountManager' ? loadAccountManagers() : loadSubscriptions();
    } catch (error) {
      if (error.data) {
        notify.show(error.data.errors[0].detail[0].message, 'error');
      } else {
        throw error;
      }
    }
  };

  const getStartDate = startDate =>
    startDate ? moment(startDate).format('MM[/]DD[/]YY') : 'No date';

  const getEndDate = endDate =>
    endDate ? moment(endDate).format('MM[/]DD[/]YY') : 'No date';

  const getStatus = record => {
    const now = moment();
    const isActive =
      moment(record.startDate).isBefore(now) &&
      moment(record.endDate).isAfter(now);
    if (isActive) {
      return 'Active';
    } else {
      return 'Expired';
    }
  };

  const getSeats = subscriptions =>
    subscriptions.reduce((seats, subscription) => {
      if (!Array.isArray(subscription)) {
        return (seats += 1);
      }
      return seats;
    }, 0);

  const getEnrolled = subscriptions =>
    subscriptions.reduce((enrolled, subscription) => {
      if (!Array.isArray(subscription) && subscription.account !== null) {
        return (enrolled += 1);
      }
      return enrolled;
    }, 0);

  const getSubscriptionTableColumns = () => {
    return [
      {
        title: 'Title',
        key: 'title',
        dataIndex: ['course', 'title']
      },
      {
        title: 'Start Date',
        key: 'startDate',
        render: (text, record) => getStartDate(record.startDate)
      },
      {
        title: 'End Date',
        key: 'endDate',
        render: (text, record) => getEndDate(record.endDate)
      },
      {
        title: 'Status',
        key: 'status',
        render: (text, record) => getStatus(record)
      },
      {
        title: 'Seats',
        key: 'seats',
        render: (text, record) => getSeats(record.subscriptions)
      },
      {
        title: 'Enrolled',
        key: 'seats',
        render: (text, record) => getEnrolled(record.subscriptions)
      },
      {
        title: 'Account Manager',
        key: 'accountManager',
        render: (text, record) => (
          <Select
            placeholder="None"
            defaultValue={
              record.accountManager ? record.accountManager.id : null
            }
            onSelect={value =>
              updateProgramCourseBinding(record.id, 'accountManager', value)
            }
          >
            {accountManagers.map(account => (
              <Select.Option key={account.id} value={account.id}>
                {account.firstName}
              </Select.Option>
            ))}
          </Select>
        )
      },
      {
        title: 'Notes',
        key: 'notes',
        render: (text, record) => (
          <EditableLabel
            className="group__subscriptions-notes"
            defaultValue={
              record.notes && record.notes !== '' ? record.notes : 'None'
            }
            onChange={value =>
              updateProgramCourseBinding(record.id, 'notes', value)
            }
          />
        )
      }
    ];
  };

  const subscriptionTableData = orderBy(subscriptions, 'course.title');
  const subscriptionTableColumns = getSubscriptionTableColumns();

  const downloadTable = () => {
    let csv =
      subscriptionTableColumns
        .filter(
          column => column.key !== 'accountManager' && column.key !== 'notes'
        )
        .map(column => column.title)
        .toString() + '\n';

    subscriptionTableData.forEach(subscription => {
      csv += `${subscription.course.title},${getStartDate(
        subscription.startDate
      )},${getEndDate(subscription.endDate)},${getStatus(
        subscription
      )},${getSeats(subscription.subscriptions)},${getEnrolled(
        subscription.subscriptions
      )}\n`;
    });

    const fileURL = encodeURI(csv);
    const temporaryLink = document.createElement('a');
    temporaryLink.download = `${group.attributes.shortname}_subscriptions.csv`;
    temporaryLink.href = `data:text/csv;charset=utf-8,${fileURL}`;
    document.body.appendChild(temporaryLink);
    temporaryLink.click();
    document.body.removeChild(temporaryLink);
  };

  return (
    <div className="group__form-item">
      {subscriptions ? (
        <Collapse className="group__table">
          <Panel header="Subscriptions" key="1">
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              size="small"
              onClick={() => downloadTable()}
            >
              Download Table
            </Button>
            <Table
              dataSource={subscriptionTableData}
              rowKey="id"
              pagination={false}
              columns={getSubscriptionTableColumns()}
              loading={loadingSubscriptions}
            />
          </Panel>
        </Collapse>
      ) : (
        <Card className="group__table"> No Subscriptions Assigned</Card>
      )}
      <Button
        type="primary"
        icon={<PlusOutlined />}
        size="small"
        onClick={() => setShowAddSubscription(true)}
      >
        Add Subscription
      </Button>
      {showAddSubscription && (
        <AddGroupSubscriptionModal
          visible={true}
          onSelect={() => {
            loadAccountManagers();
            loadSubscriptions();
          }}
          onClose={() => setShowAddSubscription(false)}
          programId={group.id}
        />
      )}
    </div>
  );
}
