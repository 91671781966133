import React, { useState, useEffect } from 'react';
import { Button, Card, Dropdown, Menu, message, Spin } from 'antd';
import { CheckCircleOutlined, WarningFilled } from '@ant-design/icons';
import Paragraph from 'antd/lib/typography/Paragraph';
import { useMutation, gql } from '@apollo/client';

export default function ChangeAccreditorMenu({
  accountId,
  courseId,
  selectedAccreditorId,
  cmeAccreditorsList
}) {
  const [loading, setLoading] = useState(false);

  const CHANGE_ACCREDITOR_MUTATION = gql`
    mutation AdminChangeAccreditor(
      $accountId: ID!
      $courseId: ID!
      $newAccreditorId: ID!
    ) {
      convertAccountCmeToAccreditor(
        input: {
          accountId: $accountId
          courseId: $courseId
          cmeAccreditorId: $newAccreditorId
        }
      )
    }
  `;

  const [changeAccreditor, { data, error }] = useMutation(
    CHANGE_ACCREDITOR_MUTATION
  );

  const updateAccreditor = async newAccreditorId => {
    changeAccreditor({
      variables: {
        accountId,
        courseId,
        newAccreditorId
      }
    });

    setLoading(true);
  };

  useEffect(() => {
    if (loading && data) {
      message.info('Accreditor Updated. Reloading page.', 2);
      setTimeout(() => window.location.reload(), 2500);
    } else if (error) {
      message.error('Error updating accreditor' + error);
    }
  }, [data, error, loading]);

  return cmeAccreditorsList.length > 1 ? (
    <Dropdown
      trigger="click"
      overlay={
        <Menu
          onClick={({ key }) => {
            updateAccreditor(key);
          }}
        >
          {cmeAccreditorsList.map(accreditor => (
            <Menu.Item key={accreditor.id}>
              {accreditor.name}
              {accreditor.id === selectedAccreditorId ? (
                <>
                  {' '}
                  <CheckCircleOutlined />
                </>
              ) : null}
            </Menu.Item>
          ))}
          <Card>
            <Paragraph type="warning">
              <WarningFilled />
              This will merge <b>all</b> CME for this course to the selected
              accreditor. <br /> This is not reversible
            </Paragraph>
          </Card>
        </Menu>
      }
    >
      {loading ? (
        <Spin />
      ) : (
        <Button type="ghost">Convert Earned Credits to Accreditor</Button>
      )}
    </Dropdown>
  ) : null;
}
