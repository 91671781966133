import React, { useState } from 'react';
import { Tabs, Radio, Spin } from 'antd';
import { useQuery, gql } from '@apollo/client';
import SingleImageInput from '../Upload/SingleImageInput';

import RichTextEditor from '../RichTextEditor';

import './QuestionOption.css';

export const GET_ANSWER_EXPLANATION_IMAGES = gql`
  query getAnswerExplanationImagesList($imageIds: [UUID!]) {
    imagesList(filter: { id: { in: $imageIds } }) {
      id
      url
    }
  }
`;

export default function QuestionOption({ option, index, updateOption }) {
  const [onAnswer, setOnAnswer] = useState(true);
  const { data, loading } = useQuery(GET_ANSWER_EXPLANATION_IMAGES, {
    skip: option.explanationImages?.length === 0,
    variables: { imageIds: option.explanationImages }
  });

  return loading ? (
    <div className="">
      <Spin />
    </div>
  ) : (
    <div className={`question-option question-option-${index}`}>
      <div className="question-option__radio-button">
        <Radio value={index}>Correct Option</Radio>
      </div>
      <Tabs defaultActiveKey={1} onChange={() => setOnAnswer(!onAnswer)}>
        <Tabs.TabPane tab="Answer" key="1">
          <RichTextEditor
            className="question-option__option-body"
            defaultValue={option.body}
            onChange={value => updateOption({ ...option, body: value })}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Explanation" key="2">
          <RichTextEditor
            className="question-option__option-explanation"
            defaultValue={option.explanation}
            onChange={value => updateOption({ ...option, explanation: value })}
          />
        </Tabs.TabPane>
        {data && data.imagesList && (
          <Tabs.TabPane tab="Image" key="3">
            {data.imagesList.map(imageListItem => (
              // eslint-disable-next-line react/jsx-key
              <SingleImageInput
                name={imageListItem.id}
                value={imageListItem}
                // onChange={image => {
                //   //TODO
                // }}
                path="assessments/images"
              />
            ))}
          </Tabs.TabPane>
        )}
      </Tabs>
    </div>
  );
}
