import { gql } from '@apollo/client';

export const CREATE_COURSE_ATTACHMENT_SECTION_BINDING = gql`
  mutation CreateCourseAttachmentSectionBinding(
    $id: UUID!
    $CourseId: UUID!
    $AttachmentSectionId: UUID!
    $createdAt: Datetime!
    $updatedAt: Datetime!
  ) {
    createCourseAttachmentSectionBinding(
      input: {
        courseAttachmentSectionBinding: {
          id: $id
          courseId: $CourseId
          attachmentSectionId: $AttachmentSectionId
          createdAt: $createdAt
          updatedAt: $updatedAt
        }
      }
    ) {
      clientMutationId
      courseAttachmentSectionBinding {
        id
        courseId
        attachmentSectionId
      }
    }
  }
`;

export const DELETE_COURSE_ATTACHMENT_SECTION_BINDING = gql`
  mutation DeleteCourseAttachmentSectionBinding($id: UUID!) {
    deleteCourseAttachmentSectionBinding(id: $id)
  }
`;
