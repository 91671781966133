import moment from 'moment';

export const secondsToMinutesAndSeconds = seconds => {
  if (!seconds) {
    return '0 min';
  }
  const minutes = Math.floor(seconds / 60);
  const secondsRemaining = seconds % 60;
  return `${minutes}:${secondsRemaining} min`;
};

export const formatTimeHoursMinutesSeconds = time => {
  const m = moment.duration(parseInt(time, 10), 'seconds').asMilliseconds();
  if (m >= 3600000) {
    // Include hours in the response
    return moment.utc(m).format('HH:mm:ss');
  } else {
    return moment.utc(m).format('mm:ss');
  }
};

// Take in a response in seconds, mm:ss, or hh:mm:ss and return seconds
export const parseTimeInput = input => {
  if (input) {
    const increments = input.toString().split(':');
    if (increments.length === 1) {
      // This is already a seconds input, return input
      return input;
    } else if (increments.length === 2) {
      // This is a mm:ss input, return total seconds
      return moment
        .duration({
          seconds: increments[1],
          minutes: increments[0]
        })
        .asSeconds();
    } else if (increments.length === 3) {
      // This is a hh:mm:ss input, return total seconds
      return moment
        .duration({
          seconds: increments[2],
          minutes: increments[1],
          hours: increments[0]
        })
        .asSeconds();
    }
  }
};
