import React from 'react';
import { Descriptions } from 'antd';
import CourseSection from './CourseSection';

const CourseDescriptionSection = ({ title, children }) => {
  return (
    <CourseSection title={title}>
      <Descriptions
        size="middle"
        column={1}
        bordered={true}
        style={{
          backgroundColor: '#fff',
          borderRadius: '5px'
        }}
      >
        {children}
      </Descriptions>
    </CourseSection>
  );
};

export default CourseDescriptionSection;
