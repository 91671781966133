import React from 'react';
import { Spin } from 'antd';

const ResourceLibrarySectionHeaderView = ({ loading }) => (
  <div className="section-header">
    <div className="column-span-small">{loading && <Spin />}</div>
    <div
      className="column-span-large"
      style={{ flexGrow: 1, marginLeft: '1em' }}
    >
      <b>Sections</b>
    </div>
    <div className="column-span-medium" />
  </div>
);

export default ResourceLibrarySectionHeaderView;
