import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Divider, Table } from 'antd';
import CreateNewAdmin from './OrganizationCreateNewAdmin';
import MapExistingAdmin from './OrganizationMapExistingAdminModal';
import DELETE_ORGANIZATION_ADMIN_BINDING_MUTATION from './queries/DeleteOrganizationAdminBindingMutation';

const { Column } = Table;

const renewSelected = () => {};
const emailSelected = () => {};

function OrganizationAdminSection(props) {
  const { admins, organizationId, preselectedAdmins, refreshParent } = props;
  const [checkedRowKeys, setCheckedRowKeys] = useState([]);

  const [deleteOrganizationAdmin] = useMutation(
    DELETE_ORGANIZATION_ADMIN_BINDING_MUTATION,
    {
      onCompleted: async (data, clientOptions) => {
        if (refreshParent) {
          await refreshParent();
        }
      }
    }
  );

  const deleteSelected = target => {
    checkedRowKeys.forEach(async adminId => {
      await deleteOrganizationAdmin({
        variables: {
          id: adminId
        }
      });
    });
    setCheckedRowKeys([]);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setCheckedRowKeys(selectedRowKeys);
    }
  };
  return (
    <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
      <span style={{ marginBottom: '1rem' }}>
        <h6 style={{ fontWeight: 'bold', display: 'inline-block' }}>Admins</h6>
        <Button
          style={{ marginLeft: '1rem', display: 'inline-block' }}
          onClick={renewSelected}
          disabled={checkedRowKeys.length === 0}
        >
          Renew Selected
        </Button>
        <Button
          style={{
            marginLeft: '1rem',
            marginBottom: '1rem',
            display: 'inline-block'
          }}
          onClick={emailSelected}
          disabled={checkedRowKeys.length === 0}
        >
          E-mail Selected Admins
        </Button>
        <Button
          style={{
            marginLeft: '1rem',
            marginBottom: '1rem',
            display: 'inline-block',
            float: 'right'
          }}
          type="danger"
          onClick={deleteSelected}
          disabled={checkedRowKeys.length === 0}
        >
          Delete Selected Admin(s)
        </Button>
      </span>
      {preselectedAdmins && (
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection
          }}
          dataSource={preselectedAdmins}
        >
          <Column
            title="Organization Admins"
            width="50%"
            render={(row, { firstName }) => {
              return (
                <>
                  {row.firstName +
                    ' ' +
                    row.lastName +
                    ' ' +
                    (row.suffix ? row.suffix : '')}
                </>
              );
            }}
          />
          <Column
            title="Email"
            width="50%"
            render={_ => {
              return <>{_.email}</>;
            }}
          />
        </Table>
      )}
      <MapExistingAdmin
        preselectedAdmins={preselectedAdmins}
        refreshParent={refreshParent}
        organizationId={organizationId}
        admins={admins}
      />
      <span style={{ marginLeft: '1rem' }}>
        <CreateNewAdmin
          refreshParent={refreshParent}
          organizationId={organizationId}
        />
      </span>
      <h6 style={{ fontStyle: 'italic', marginTop: '1rem' }}>
        All changes are automatically saved
      </h6>
      <Divider />
    </div>
  );
}

export default OrganizationAdminSection;
