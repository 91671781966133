import React from 'react';
import { Form, Input, Select, Button } from 'antd';
import SortableList from '../SortableList';
import { DeleteTwoTone, MenuOutlined } from '@ant-design/icons';
import { ICONS } from '../../containers/items/icons';

const { Option } = Select;

export default function ItemIncludes({
  activeItem,
  handleCreate,
  handleUpdate,
  handleReorder,
  handleDelete
}) {
  return (
    <div className="item__field-sections">
      <Form.Item
        label="What's Included? (Icon name + Description)"
        name="itemIncludes"
        extra={
          <div>
            Visit{' '}
            <a
              href="https://stage.hippoeducation.com/showcase"
              target="__blank"
            >
              the icon showcase
            </a>{' '}
            to see all available icons. You can add valid HTML in descriptions!
          </div>
        }
      >
        <Input.Group compact={true}>
          {activeItem.itemIncludesList.length > 0 ? (
            <SortableList
              items={activeItem.itemIncludesList}
              itemType="itemIncludes"
              className="item__sortable-list-container"
              onDragEnd={e => handleReorder(e)}
              renderItem={(item, index) => (
                <div key={index} className="item__sortable-list-item">
                  <MenuOutlined style={{ width: '5%' }} twoToneColor="red" />
                  <Select
                    style={{ width: '20%' }}
                    defaultValue={item.icon}
                    onChange={e => handleUpdate(e, 'icon', item.id)}
                  >
                    {ICONS.sort((a, b) => a.localeCompare(b)).map(icon => (
                      <Option key={icon} value={icon}>
                        {icon}
                      </Option>
                    ))}
                  </Select>
                  <Input
                    style={{ width: '70%' }}
                    defaultValue={item.text.replace(/<\/?p[^>]*>/g, '')}
                    onChange={e =>
                      handleUpdate(e.target.value, 'text', item.id)
                    }
                  />
                  <DeleteTwoTone
                    style={{ width: '5%' }}
                    onClick={() => handleDelete(item.id, item.displayOrder)}
                    twoToneColor="red"
                    size="large"
                  />
                </div>
              )}
            />
          ) : (
            <i className="item__sortable-list-container">none</i>
          )}
          <Button style={{ margin: '1rem 0' }} onClick={handleCreate}>
            + Add more
          </Button>
        </Input.Group>
      </Form.Item>
    </div>
  );
}
