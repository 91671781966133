import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Button, Collapse, Divider, Dropdown, Empty, Menu, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { CourseCmePanel } from './CourseCmePanel/CourseCmePanel';
import { CourseCmeActionPanel } from './CourseCmeActionPanel';

import { useQuery, gql } from '@apollo/client';

const { Panel } = Collapse;

const COURSES_QUERY = gql`
  query CoursesQuery($accountId: UUID!, $date: Datetime) {
    cmeAccreditorsList {
      id
      name
      cmeBucketsList(filter: { expiredAt: { greaterThan: $date } }) {
        courseId
      }
    }
    accountCourseCmeAccreditorBindingsList(
      condition: { accountId: $accountId }
    ) {
      courseId
      cmeAccreditor {
        id
        name
        creditType
      }
    }
    accountEarnedCmesList(condition: { accountId: $accountId }) {
      cmeBucket {
        id
        title
        maxCredits
        cmeAccreditor {
          id
          name
          creditType
          creditTypePlural
        }
        course {
          id
          title
          shortname
          courseType {
            shortname
          }
        }
        activatedAt
        expiredAt
      }
      credits
      course {
        id
        title
        shortname
        courseType {
          shortname
        }
      }
      product {
        id
        title
      }
    }
  }
`;

export default function AccountCmeCourseList({
  accountId,
  courseId: courseIdFromRoute
}) {
  const [courseData, setCourseData] = useState(null);
  const [groupBy, setGroupBy] = useState('bucket');
  const [selectedAccreditors, setSelectedAccreditors] = useState({});
  const [accreditorsByCourse, setAccreditorsByCourse] = useState([]);
  const history = useHistory();

  const { data } = useQuery(COURSES_QUERY, {
    variables: { accountId, date: moment(new Date()).format() }
  });

  function formatCourseData(data) {
    const {
      cmeAccreditorsList,
      accountCourseCmeAccreditorBindingsList,
      accountEarnedCmesList
    } = data;

    setAccreditorsByCourse(
      _.chain(cmeAccreditorsList)
        .flatMap(accreditor =>
          _.uniqBy(accreditor.cmeBucketsList, 'courseId').map(bucket => ({
            id: accreditor.id,
            name: accreditor.name,
            courseId: bucket.courseId
          }))
        )
        .groupBy('courseId')
        .value()
    );

    setSelectedAccreditors(
      accountCourseCmeAccreditorBindingsList.reduce((map, binding) => {
        map[binding.courseId] = binding.cmeAccreditor;
        return map;
      }, {})
    );

    const earnedByCourseOrProduct = _.groupBy(
      accountEarnedCmesList,
      earned =>
        // I don't get how Bootcamp Assessments are hooked up :(
        earned.course?.id || earned.cmeBucket?.course.id || earned.product?.id
    );

    const courseData = _.chain(accountEarnedCmesList)
      .map(earned => ({
        ...(earned.course || earned.cmeBucket?.course || earned.product),
        isLegacyQBank: !!earned.product && !earned.course
      }))
      .uniqBy(courseOrProduct => courseOrProduct?.id || null)
      .map(courseOrProduct => {
        const { totalCredits, creditsByAccreditor } = earnedByCourseOrProduct[
          courseOrProduct.id
        ].reduce(
          ({ totalCredits, creditsByAccreditor }, e) => {
            if (!creditsByAccreditor[e.cmeBucket?.cmeAccreditor.id]) {
              creditsByAccreditor[e.cmeBucket?.cmeAccreditor.id] = {
                ...e.cmeBucket?.cmeAccreditor,
                totalCredits: 0
              };
            }
            creditsByAccreditor[
              e.cmeBucket?.cmeAccreditor.id
            ].totalCredits += parseFloat(e.credits);

            return {
              totalCredits: totalCredits + parseFloat(e.credits),
              creditsByAccreditor
            };
          },
          { totalCredits: 0, creditsByAccreditor: {} }
        );
        return {
          ...courseOrProduct,
          totalCredits,
          creditsByAccreditor,
          cmeBucketList: _.uniqBy(
            earnedByCourseOrProduct[courseOrProduct.id]
              .map(earned => earned.cmeBucket)
              .filter(bucket => !!bucket),
            'id'
          )
        };
      })
      .sortBy(courseOrProduct => courseOrProduct.title)
      .value();

    setCourseData(courseData);
  }

  useEffect(() => {
    if (data) {
      formatCourseData(data);
    }
  }, [data]);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Dropdown
        overlay={
          <Menu
            defaultSelectedKeys={groupBy}
            onClick={({ key }) => setGroupBy(key)}
          >
            <Menu.Item key="bucket">Bucket/Date</Menu.Item>
            <Menu.Item key="content">Content</Menu.Item>
          </Menu>
        }
      >
        <Button>
          Group By : {_.capitalize(groupBy)}
          <DownOutlined />
        </Button>
      </Dropdown>

      {courseData && courseData.length > 0 ? (
        <Collapse
          accordion={true}
          defaultActiveKey={
            courseIdFromRoute
              ? courseIdFromRoute
              : // Only open the first course if it's the only one.
              // We also shouldn't try to open legacy qbanks
              !courseData[0].isLegacyQBank &&
                courseData[0].courseType.shortname !== 'assessment' &&
                courseData[0].courseType.shortname === 'practice-exam' &&
                courseData[0].courseType.shortname === 'other'
              ? [courseData[0].id]
              : null
          }
          onChange={key => {
            history.push(`/account/${accountId}/cme/${key || ''}`);
            return true;
          }}
        >
          {courseData.map(
            (course, index) =>
              course.courseType && (
                <Panel
                  header={course.title}
                  key={course.id}
                  collapsible={
                    course.isLegacyQBank ||
                    course.courseType.shortname === 'assessment' ||
                    course.courseType.shortname === 'practice-exam' ||
                    course.courseType.shortname === 'other'
                      ? 'disabled'
                      : null
                  }
                  extra={
                    <span>
                      <strong>Credits</strong>: {course.totalCredits}
                    </span>
                  }
                >
                  {!course.isLegacyQBank ? (
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <CourseCmeActionPanel
                        accountId={accountId}
                        course={course}
                        selectedAccreditors={selectedAccreditors}
                        accreditorsByCourse={accreditorsByCourse}
                      />
                      <Divider />
                      <CourseCmePanel
                        accountId={accountId}
                        courseOrProductId={course.id}
                        buckets={course.cmeBucketList}
                        groupBy={groupBy}
                        courseType={course.courseType.shortname}
                      />
                    </Space>
                  ) : null}
                </Panel>
              )
          )}
        </Collapse>
      ) : (
        <Empty />
      )}
    </Space>
  );
}
