import React from 'react';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  ApolloProvider,
  InMemoryCache
} from '@apollo/client';

import { getTokenFromCookie } from './cookie';
import { setContext } from '@apollo/client/link/context';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHILE_SERVER
});

const withToken = setContext(() => {
  const token = getTokenFromCookie();

  return { token };
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const { token } = operation.getContext();

  operation.setContext(() => ({
    headers: {
      Authorization: token ? `Bearer ${token}` : ''
    }
  }));
  return forward(operation);
});

const client = new ApolloClient({
  link: ApolloLink.from([withToken, authMiddleware, httpLink]),
  cache: new InMemoryCache()
});

export const GraphQLClientProvider = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
