import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import './ChapterListItem.css';

export default class ChapterListItemHeader extends React.Component {
  static propTypes = {
    hideIndex: PropTypes.bool,
    showDate: PropTypes.bool
  };

  render() {
    const { hideIndex, showDate } = this.props;

    return (
      <div className="chapter-list-header">
        <Row gutter={[4, 0]}>
          {!hideIndex && <Col span={1} />}
          <Col span={4}>Title</Col>
          <Col span={2}>Audio</Col>
          <Col span={3}>Faculty</Col>
          <Col span={3}>Medical Editor</Col>
          <Col span={3}>Show Notes</Col>
          <Col span={1}>CME</Col>
          <Col span={1}>PDF</Col>
          <Col span={1}>Art</Col>
          <Col span={3}>Tags</Col>
          {showDate && <Col span={1.5}>Date Created</Col>}
          <Col span={2} />
        </Row>
      </div>
    );
  }
}
