import React, { useEffect, useState, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { debounce } from 'lodash';
import moment from 'moment';
import { Card, Select, Row, Col, InputNumber, Input, DatePicker } from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';
import BreadcrumbNavigation from '../../components/BreadcrumbNavigation/BreadcrumbNavigation';
import BreadcrumbConstants from '../../routes/BreadcrumbConstants';
import { GET_ITEMS_LIST_QUERY } from '../../containers/items/queries/GetItemsListQueries';
import { useQuery } from '@apollo/client';
import { filterActiveItemIfProduction } from '../../utils/filters';
import * as API from '../../API';

import './Associations.css';

const { Option } = Select;

export default function Association() {
  const params = useParams();
  const associationId = params.id;
  const [activeAssociation, setActiveAssociation] = useState(null);
  const [activeAssociationItems, setActiveAssociationItems] = useState([]);
  const [activeItems, setActiveItems] = useState([]);
  const [allItems, setAllItems] = useState([]);

  const { data: itemData } = useQuery(GET_ITEMS_LIST_QUERY);

  useEffect(() => {
    if (itemData && itemData.itemsList) {
      const filteredActiveItems = itemData.itemsList.filter(item =>
        filterActiveItemIfProduction(item)
      );
      setAllItems(filteredActiveItems);
    }
  }, [itemData]);

  const filterOption = (input, option) => {
    return option.children[0].toLowerCase().includes(input.toLowerCase());
  };

  const fetchAssociationAndItems = useCallback(async () => {
    const association = await API.association.find({
      id: associationId
    });

    const associationItems = await API.associationItemBinding.where({
      filter: {
        association: associationId
      },
      options: {
        include: 'item'
      }
    });

    setActiveAssociation(association.data);
    setActiveAssociationItems(associationItems);
    setActiveItems(associationItems.included);
  }, [associationId]);

  useEffect(() => {
    fetchAssociationAndItems();
  }, [fetchAssociationAndItems]);

  const handleAddItemToAssociation = async itemId => {
    await API.associationItemBinding.create({
      attributes: {
        title: 'New association binding title',
        description: 'New association binding description'
      },
      relationships: {
        item: {
          data: {
            type: 'item',
            id: itemId
          }
        },
        association: {
          data: {
            type: 'association',
            id: associationId
          }
        }
      }
    });

    fetchAssociationAndItems();
  };

  const handleUpdateAssociationItem = async (
    value,
    associationItemId,
    field
  ) => {
    await API.associationItemBinding.update({
      id: associationItemId,
      attributes: {
        [field]: value
      }
    });
  };

  const handleDeleteItemFromAssociation = async associationItemId => {
    await API.associationItemBinding.delete({
      id: associationItemId
    });

    fetchAssociationAndItems();
  };

  const handleUpdateAssociation = debounce(async (value, field) => {
    await API.association.update({
      id: associationId,
      attributes: {
        [field]: value
      }
    });
  });

  const itemFinder = itemId => activeItems.find(item => item.id === itemId);

  if (!activeAssociation) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <BreadcrumbNavigation
        data={[
          BreadcrumbConstants.HippoAdmin,
          BreadcrumbConstants.Associations,
          {
            title:
              activeAssociation &&
              activeAssociation.attributes &&
              activeAssociation.attributes.title
          }
        ]}
      />
      <hr />
      <div className="association-page__title">
        <Col span={8}>
          <small>Association Title:</small>
          <Input
            defaultValue={
              activeAssociation && activeAssociation.attributes.title
            }
            onChange={event =>
              handleUpdateAssociation(event.target.value, 'title')
            }
          />
          <small>
            <em>
              Note: this title will appear on the cart price breakdown for
              users!
            </em>
          </small>
        </Col>
      </div>
      <Card
        title="Items"
        extra={
          <Select
            placeholder="+ Add Item To Association"
            style={{ width: 500 }}
            onChange={item => handleAddItemToAssociation(item)}
            filterOption={filterOption}
            showSearch={true}
          >
            {allItems &&
              allItems.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.title} <small>({item.sku})</small>
                </Option>
              ))}
          </Select>
        }
      >
        <Row>
          <Col xs={24} sm={12} lg={2}>
            <h6>Delete?</h6>
          </Col>
          <Col xs={24} sm={12} lg={5}>
            <h6>Item</h6>
          </Col>
          <Col xs={24} sm={12} lg={4}>
            <h6>Start Date</h6>
          </Col>
          <Col xs={24} sm={12} lg={4}>
            <h6>End Date</h6>
          </Col>
          <Col xs={24} sm={12} lg={4}>
            <h6>Discount Amount ($)</h6>
          </Col>
          <Col xs={24} sm={12} lg={4}>
            <h6>Discount Percentage (%)</h6>
          </Col>
        </Row>
        <hr />
        {activeAssociationItems &&
          activeAssociationItems.data &&
          activeAssociationItems.data.map(associationItem => {
            const matchingItem = itemFinder(
              associationItem.relationships.item.data.id
            );
            return (
              <Row
                key={associationItem.id}
                className="association-page__association"
              >
                <Col xs={24} sm={12} lg={2}>
                  <DeleteTwoTone
                    onClick={() =>
                      handleDeleteItemFromAssociation(associationItem.id)
                    }
                    twoToneColor="red"
                    size="large"
                  />
                </Col>
                <Col xs={24} sm={12} lg={5}>
                  <Link to={`/item/${matchingItem && matchingItem.id}`}>
                    {matchingItem ? matchingItem.attributes.title : ''}
                  </Link>
                </Col>
                <Col xs={24} sm={12} lg={4}>
                  <DatePicker
                    defaultValue={
                      associationItem.attributes.startedAt !== null
                        ? moment(associationItem.attributes.startedAt)
                        : null
                    }
                    onChange={date =>
                      handleUpdateAssociationItem(
                        date,
                        associationItem.id,
                        'startedAt'
                      )
                    }
                  />
                </Col>
                <Col xs={24} sm={12} lg={4}>
                  <DatePicker
                    defaultValue={
                      associationItem.attributes.endedAt !== null
                        ? moment(associationItem.attributes.endedAt)
                        : null
                    }
                    onChange={date =>
                      handleUpdateAssociationItem(
                        date,
                        associationItem.id,
                        'endedAt'
                      )
                    }
                  />
                </Col>
                <Col xs={24} sm={12} lg={4}>
                  <InputNumber
                    defaultValue={associationItem.attributes.discountAmount}
                    onChange={value =>
                      handleUpdateAssociationItem(
                        value,
                        associationItem.id,
                        'discountAmount'
                      )
                    }
                  />
                </Col>
                <Col xs={24} sm={12} lg={4}>
                  <InputNumber
                    defaultValue={
                      associationItem.attributes.discountPercentage * 100
                    }
                    onChange={value =>
                      handleUpdateAssociationItem(
                        value / 100,
                        associationItem.id,
                        'discountPercentage'
                      )
                    }
                  />
                </Col>
              </Row>
            );
          })}
      </Card>
      <span>
        <em>
          <strong>Note: </strong>If both a discount amount ($) and a discount
          percentage are set, then discount amount will always take priority and
          the percentage will be ignored.
        </em>
      </span>
    </div>
  );
}
