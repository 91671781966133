import React from 'react';
import moment from 'moment';
import { Table, Button } from 'antd';
import { Link } from 'react-router-dom';
import CourseSection from './CourseSection';

const CourseCmeDetail = ({ course, cmeDetails }) => {
  return (
    <CourseSection
      title={
        <>
          <h5>CME Details</h5>
          <Button>
            <Link to={`/course/${course.id}/cme`}>Manage</Link>
          </Button>
        </>
      }
    >
      <Table
        style={{ backgroundColor: '#fff' }}
        pagination={false}
        columns={[
          {
            title: '',
            dataIndex: 'title',
            key: 'title'
          },
          {
            title: 'Starts',
            dataIndex: 'activatedAt',
            key: 'activatedAt',
            render: date =>
              moment(date).isValid()
                ? moment(date)
                    .tz(process.env.REACT_APP_DISPLAY_TIMEZONE)
                    .format('lll')
                : date
          },
          {
            title: 'Ends',
            dataIndex: 'expiredAt',
            key: 'expiredAt',
            render: date =>
              moment(date).isValid()
                ? moment(date)
                    .tz(process.env.REACT_APP_DISPLAY_TIMEZONE)
                    .format('lll')
                : date
          }
        ]}
        dataSource={cmeDetails}
      />
    </CourseSection>
  );
};

export default CourseCmeDetail;
