import { gql } from '@apollo/client';

const UPDATE_LECTURE_HIERARCHY_MUTATION = gql`
  mutation UpdateLectureHierarchyMutation(
    $lectureId: UUID!
    $topicId: UUID
    $displayOrder: Int
    $parentId: UUID
    $updatedAt: Datetime
  ) {
    updateLecture(
      input: {
        patch: {
          parentId: $parentId
          topicId: $topicId
          displayOrder: $displayOrder
          updatedAt: $updatedAt
        }
        id: $lectureId
      }
    ) {
      clientMutationId
    }
  }
`;

export { UPDATE_LECTURE_HIERARCHY_MUTATION };
