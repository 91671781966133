import React, { useState } from 'react';
import classNames from 'classnames';
import { without } from 'lodash';
import { Button, Modal, Empty, Input, Card } from 'antd';
import PaginatedList from './PaginatedList/PaginatedList';
import * as API from '../API';

import './ResourceSelectorModal.css';

export const ResourceSelectorModal = ({
  title,
  visible,
  allowNullSelection = false,
  multiSelect = true,
  apiResource,
  defaultOptions = {},
  additionalPaginatedListProps = {},
  renderHeader,
  renderItem,
  searchPlaceholder = 'Search',
  searchAction,
  initialSelectedIds = [],
  isItemDisabled = null,
  disabledIds = [],
  onSelect,
  onClose,
  okButtonText = 'OK',
  cancelButtonText = 'Cancel'
}) => {
  const [selected, setSelected] = useState(initialSelectedIds);

  const handleItemChange = itemId => {
    if (!multiSelect) {
      if (isSelected(itemId) && allowNullSelection) {
        setSelected(without(selected, itemId));
      } else {
        setSelected([itemId]);
      }
    } else {
      if (isSelected(itemId)) {
        setSelected(without(selected, itemId));
      } else {
        setSelected([...selected, itemId]);
      }
    }
  };

  const isSelected = itemId => {
    return selected.indexOf(itemId) >= 0;
  };

  const isDisabled = item => {
    return isItemDisabled
      ? isItemDisabled(item)
      : disabledIds.indexOf(item.id) >= 0;
  };

  const cleanupAndClose = () => {
    setSelected([]);
    onClose();
  };

  return (
    <Modal
      wrapClassName="resource-selector-modal"
      visible={visible}
      title={title}
      onCancel={cleanupAndClose}
      footer={
        <>
          <Button
            disabled={!allowNullSelection && selected.length === 0}
            onClick={() => {
              if (!multiSelect) {
                onSelect(selected && selected[0]);
              } else {
                onSelect([...selected]);
              }
              setSelected([]);
            }}
          >
            {okButtonText}
          </Button>

          <Button onClick={cleanupAndClose}>{cancelButtonText}</Button>
        </>
      }
    >
      <PaginatedList
        resource={apiResource}
        onLoadRows={resource => API.simplifyResource(resource)}
        pageLimit={10}
        defaultOptions={defaultOptions}
        renderHeader={(actions, refresh) => (
          <div className="resource-selector-modal__header">
            {searchAction && (
              <Input
                placeholder={searchPlaceholder}
                onChange={e => searchAction(actions, e.target.value)}
              />
            )}
            <Card
              size="small"
              className="resource-selector-modal__header-title"
            >
              {renderHeader()}
            </Card>
          </div>
        )}
        renderListItem={(resourceItem, refresh, i) => (
          <Card
            size="small"
            className={classNames('resource-selector-modal__item', {
              'resource-selector-modal__item--disabled': isDisabled(
                resourceItem
              )
            })}
            key={resourceItem.id}
            onClick={() =>
              !isDisabled(resourceItem) && handleItemChange(resourceItem.id)
            }
          >
            {renderItem(
              i,
              resourceItem,
              isSelected(resourceItem.id),
              () =>
                !isDisabled(resourceItem) && handleItemChange(resourceItem.id)
            )}
          </Card>
        )}
        renderEmptyList={() => <Empty description="No results to show" />}
        {...additionalPaginatedListProps}
      />
    </Modal>
  );
};
