import { gql } from '@apollo/client';

export const GET_AVAILABLE_TAGS_FOR_QUESTION = gql`
  query getAvailableTagsForQuestion($itemId: UUID!) {
    question(id: $itemId) {
      course {
        title
        courseTagTypeBindingsList {
          tagType {
            id
            name
            tagsList(orderBy: TITLE_ASC) {
              id
              title
            }
          }
          isSecondary
          isPrimary
        }
      }
      questionUniversalTagBindingsList {
        id
        tag {
          id
          tagTypeId
          title
        }
      }
    }
  }
`;
export const CREATE_QUESTION_TAG_BINDING = gql`
  mutation CreateQuestionTagBinding(
    $id: UUID!
    $createdAt: Datetime!
    $updatedAt: Datetime!
    $tagId: UUID!
    $itemId: UUID!
  ) {
    createQuestionUniversalTagBinding(
      input: {
        questionUniversalTagBinding: {
          id: $id
          createdAt: $createdAt
          updatedAt: $updatedAt
          questionId: $itemId
          tagId: $tagId
        }
      }
    ) {
      clientMutationId
    }
  }
`;
export const DELETE_QUESTION_TAG_BINDING = gql`
  mutation DeleteQuestionTagBinding($id: UUID!) {
    deleteQuestionUniversalTagBinding(input: { id: $id }) {
      clientMutationId
    }
  }
`;
