import axios from 'axios';
import * as API from '../API';
import { getTokenFromCookie } from './cookie';

export async function mergeTags(sourceTagId, targetTagId) {
  let data;
  let error;
  try {
    const response = await API.raw('POST', 'api/tag/merge', {
      sourceTagId,
      targetTagId
    });

    if (response.status === 200) {
      data = response.data;
    } else {
      error = `An error occurred when trying to merge ${sourceTagId} into ${targetTagId}. ${response.status}: ${response.data}`;
    }
  } catch (e) {
    error = `An error occurred when trying to merge ${sourceTagId} into ${targetTagId}.`;
  }
  return { data, error };
}

export async function mergeAccounts(sourceAccountId, targetAccountId) {
  let data;
  let error;
  let status;
  try {
    const response = await API.raw(
      'POST',
      'api/account/merge',
      {
        sourceAccountId,
        targetAccountId
      },
      5 * 60 * 1000 // 5 minute timeout
    );

    status = response.status;
    if (response.status === 200) {
      data = response.data;
    }
  } catch (e) {
    error = `An error occurred when trying to merge ${sourceAccountId} into ${targetAccountId}.`;
  }
  return { data, error, status };
}

export async function getMergeData(firstAccountId, secondAccountId) {
  let data;
  let error;
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_JSONAPI_SERVER}/api/account/merge`,
      {
        params: {
          sourceAccountId: firstAccountId,
          targetAccountId: secondAccountId
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + getTokenFromCookie()
        }
      }
    );

    if (response.status === 200) {
      data = response.data;
    }
  } catch (e) {
    error = `Getting account details for the merge of accounts ${firstAccountId} and ${secondAccountId} failed.`;
  }
  return { data, error };
}
