import { gql } from '@apollo/client';

const DELETE_ORGANIZATION_DEFAULT_SUBSCRIPTION_MUTATION = gql`
  mutation deleteOrganizationDefaultSubscription($id: UUID!) {
    deleteOrganizationDefaultSubscription(input: { id: $id }) {
      clientMutationId
    }
  }
`;

export default DELETE_ORGANIZATION_DEFAULT_SUBSCRIPTION_MUTATION;
