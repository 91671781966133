import { gql } from '@apollo/client';

const CREATE_SUBSCRIPTION_TEMPLATE = gql`
  mutation CreateSubscriptionTemplate(
    $courseId: ID!
    $productId: ID!
    $courseTypeId: ID!
    $courseTitle: String!
  ) {
    createSubscriptionTemplate(
      input: {
        courseId: $courseId
        productId: $productId
        courseTypeId: $courseTypeId
        courseTitle: $courseTitle
      }
    ) {
      id
    }
  }
`;

export default CREATE_SUBSCRIPTION_TEMPLATE;
