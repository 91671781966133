import _ from 'underscore';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import * as API from '../../API';
import { GET_CART_ITEMS_LIST_QUERY } from '../items/queries/GetItemsListQueries';
import { useQuery } from '@apollo/client';
import { filterActiveItemIfProduction } from '../../utils/filters';
import { getTokenFromCookie } from '../../utils/cookie';

export default function Step2({ onComplete, purchaseData }) {
  const [products, setProducts] = useState([]);
  const [items, setItems] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [subscriptions, setSubscriptions] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);

  const { data } = useQuery(GET_CART_ITEMS_LIST_QUERY);

  useEffect(() => {
    if (data && data.itemsForCreateOrder) {
      setAllItems(data.itemsForCreateOrder);
    }
  }, [data]);

  useEffect(() => {
    (async () => {
      const products = await API.fetchAllAndSimplify(
        API.product.all({ options: { sort: 'title' } })
      );
      const filteredProducts = products.filter(
        product => !product.title.includes('Resident In Service')
      );
      setProducts(filteredProducts);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const products = await API.fetchAllAndSimplify(
        API.product.all({ options: { sort: 'title' } })
      );
      const filteredProducts = products.filter(
        product => !product.title.includes('Resident In Service')
      );
      setProducts(filteredProducts);

      const AccountId = purchaseData.account.id;
      const response2 = await fetch(
        `${process.env.REACT_APP_JSONAPI_SERVER}/api/subscription?AccountId=${AccountId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getTokenFromCookie()}`
          },
          credentials: 'same-origin'
        }
      );
      const subscriptionResults = (
        await response2.json()
      ).subscriptions.sort((a, b) => a.title.localeCompare(b.title));
      setHasSearched(true);
      setSubscriptions(subscriptionResults);
    })();
  }, [purchaseData.account.id]);

  const changeItem = e => {
    setSelectedItem(_.findWhere(items, { id: e.target.value }));
  };

  const changeProduct = async e => {
    const productId = e.target.value;
    setSelectedItem(null);
    setItems([]);
    const allItemsFromSelectedProduct = allItems
      .filter(
        item =>
          item.productId === productId &&
          filterActiveItemIfProduction(item, productId)
      )
      .sort((a, b) => a.title.localeCompare(b.title));
    const simplifiedItems = [];
    for (const item of allItemsFromSelectedProduct) {
      if (
        !item.parentId ||
        (item.parentId && !item.subscriptionTemplates[0].isCMEArchiveEnabled) ||
        (item.parentId &&
          item.subscriptionTemplates[0].isCMEArchiveEnabled &&
          subscriptions
            .map(subscription => subscription.CourseId)
            .includes(item.courseId))
      ) {
        const simplifiedItem = {
          id: item.id,
          sku: item.sku,
          price: item.prices.length > 0 ? item.prices[0].price : 0,
          title: item.title,
          description: item.description
        };
        simplifiedItems.push(simplifiedItem);
      }
    }
    setItems(simplifiedItems);
  };

  const checkoutStyles = {
    backgroundColor: '#e9ecef',
    padding: 18,
    borderRadius: 3,
    marginBottom: 10
  };

  return (
    <Row>
      <Col xs="6">
        <Form>
          <FormGroup>
            <Label for="exampleSelect">Select product&nbsp;</Label>
            <select onChange={e => changeProduct(e)}>
              <option />
              {products.map((product, index) => {
                return (
                  <option value={product.id} key={index}>
                    {product.title}
                  </option>
                );
              })}
            </select>
          </FormGroup>
          <FormGroup>
            <Label for="exampleSelect">Select item&nbsp;</Label>
            <select onBlur={e => changeItem(e)}>
              <option />
              {items.map((item, index) => {
                return (
                  <option value={item.id} key={index}>
                    {item.sku} - ${item.price}
                  </option>
                );
              })}
            </select>
          </FormGroup>
        </Form>
        <Button
          color="primary"
          style={{ marginTop: 20 }}
          disabled={!selectedItem}
          onClick={() => onComplete({ item: selectedItem })}
        >
          Continue
        </Button>
      </Col>
      <Col xs="6">
        <div style={checkoutStyles}>
          Existing subscriptions for{' '}
          <strong>{purchaseData.account.email}</strong>:
          {hasSearched && subscriptions.length > 0 ? (
            <ul style={{ margin: 0 }}>
              {subscriptions.map((subscription, index) => {
                return <li key={index}>{subscription.title}</li>;
              })}
            </ul>
          ) : null}
          {hasSearched && subscriptions.length === 0 ? (
            <p>&lt;none&gt;</p>
          ) : null}
        </div>
      </Col>
    </Row>
  );
}
