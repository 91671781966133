import React from 'react';
import { Alert, Button, Checkbox, Input } from 'antd';
import { Label } from 'reactstrap';
import AttachmentUpload from '../Upload/AttachmentUpload';
import {
  parseTimeInput,
  formatTimeHoursMinutesSeconds
} from '../../utils/time';
import EditableLabel from '../EditableLabel/EditableLabel';
import * as API from '../../API';
import { useFileUpload } from '../../containers/attachments/hooks/useAttachmentHooks';

const AttachmentFileInputUpload = ({
  title,
  subtitle,
  activityTimeInSeconds,
  isContentSummary,
  fileData,
  showCmeFields,
  showSubtitleInput,
  setFileData,
  setTitle,
  setSubtitle,
  setActivityTimeInSeconds,
  setIsContentSummary,
  newAttachmentError,
  setNewAttachmentError,
  resetForm,
  onCreateItem,
  onClose = () => {},
  showCloseButton = false
}) => {
  const handleFileUpload = useFileUpload(setFileData, { isContentSummary });

  const createNewAttachment = async () => {
    setNewAttachmentError('');
    const attachmentData = await API.attachment.create({
      attributes: {
        title,
        subtitle,
        activityTimeInSeconds: showCmeFields ? activityTimeInSeconds : 0,
        isContentSummary: showCmeFields ? isContentSummary : false,
        ...fileData
      },
      relationships: {}
    });

    if (attachmentData.status >= 400) {
      setNewAttachmentError('Error');
    } else {
      const attachment = (await attachmentData).data;
      resetForm();
      if (onCreateItem) {
        onCreateItem(attachment.id);
      }
      onClose();
    }
  };

  return (
    <div className="new-attachment-form">
      <Label id="attachment_title" htmlFor="title">
        Title
        <Input
          type="text"
          id="title"
          name="title"
          onChange={e => setTitle(e.target.value)}
          value={title}
        />
      </Label>
      {showSubtitleInput && (
        <Label id="attachment_subtitle" htmlFor="subtitle">
          Subtitle
          <Input
            type="text"
            id="subtitle"
            name="subtitle"
            onChange={e => setSubtitle(e.target.value)}
            value={subtitle}
          />
        </Label>
      )}
      {showCmeFields && (
        <div className="attachments-input__rap-inputs">
          <div className="attachments-input__rap-inputs-container">
            <Label
              id="attachment_activityTimeInSeconds"
              htmlFor="activityTimeInSeconds"
              className="attachments-input__rap-inputs-label"
            >
              Activity Time
            </Label>
            <EditableLabel
              defaultValue={activityTimeInSeconds}
              formatLabel={value => formatTimeHoursMinutesSeconds(value)}
              onChange={value => {
                if (value === '') {
                  setActivityTimeInSeconds(0);
                } else {
                  const time = parseTimeInput(value);
                  setActivityTimeInSeconds(time);
                }
              }}
              className="attachments-input__activity-time"
            />
          </div>
          <div className="attachments-input__rap-inputs-container">
            <Label
              id="attachment_isContentSummary"
              htmlFor="isContentSummary"
              className="attachments-input__rap-inputs-label"
            >
              Is Content Summary
            </Label>
            <Checkbox
              id="isContentSummary"
              name="isContentSummary"
              onChange={e => setIsContentSummary(!isContentSummary)}
              checked={isContentSummary}
            />
          </div>
        </div>
      )}
      <AttachmentUpload
        filename={fileData.fileName}
        canEdit={true}
        hideReplace={false}
        onComplete={handleFileUpload}
      />
      {newAttachmentError && (
        <Alert message={newAttachmentError} type="error" showIcon={true} />
      )}
      <Button onClick={() => createNewAttachment()}>Save</Button>
      {showCloseButton ? (
        <Button
          onClick={() => {
            resetForm();
            onClose();
          }}
        >
          Close
        </Button>
      ) : (
        <Button onClick={resetForm}>Reset</Button>
      )}
    </div>
  );
};

export default AttachmentFileInputUpload;
