import { gql } from '@apollo/client';

const CREATE_ORGANIZATION_MUTATION = gql`
  mutation addOrganizationDefaultSubscription(
    $isEnabled: Boolean!
    $id: UUID!
    $organizationId: UUID!
    $subscribedCourseId: UUID!
    $updatedAt: Datetime!
    $createdAt: Datetime!
  ) {
    createOrganizationDefaultSubscription(
      input: {
        organizationDefaultSubscription: {
          id: $id
          organizationId: $organizationId
          subscribedCourseId: $subscribedCourseId
          updatedAt: $updatedAt
          createdAt: $createdAt
          isEnabled: $isEnabled
        }
      }
    ) {
      clientMutationId
    }
  }
`;

export default CREATE_ORGANIZATION_MUTATION;
