import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import CREATE_SUBSCRIPTION_TEMPLATE from './queries/CreateSubscriptionTemplateMutation';
import GET_PRODUCT_ITEMS_QUERY from './queries/GetProductItemsQuery';
import MessageLoader from '../../components/MessageLoader';
import * as Sentry from '@sentry/browser';

export default function CourseItems({ course, verticalId }) {
  const [redirect, setRedirect] = useState(null);
  const [loading, setLoading] = useState(false);

  const [createSubscriptionTemplate] = useMutation(
    CREATE_SUBSCRIPTION_TEMPLATE,
    {
      onCompleted: (data, clientOptions) => {
        setLoading(false);
        if (data.createSubscriptionTemplate) {
          setRedirect('/item/' + data.createSubscriptionTemplate.id);
        } else {
          notification.error({
            message: 'Uh-oh, something went wrong...'
          });
          Sentry.captureException({ data });
        }
      },
      refetchQueries: [GET_PRODUCT_ITEMS_QUERY]
    }
  );

  const createNewItem = async () => {
    try {
      setLoading(true);
      await createSubscriptionTemplate({
        variables: {
          courseId: course.id,
          productId: course.product.id,
          courseTitle: course.title,
          courseTypeId: course.courseType.id
        }
      });
    } catch (e) {
      setLoading(false);
      notification.error({
        message: 'Uh-oh, something went wrong...'
      });
      Sentry.captureException(e);
    }
  };

  return redirect ? (
    <Redirect to={{ pathname: redirect, state: { isNewItem: true } }} />
  ) : !loading ? (
    <div>
      <span className="course-items__section-title">
        {`${course.title} `}
        <Badge
          showZero={false}
          count={`${course.items.length} Items`}
          style={{ backgroundColor: '#2d89f3' }}
        />
      </span>
      <div className="course-items__item-list">
        {course.items.length > 0 ? (
          course.items.map(item => (
            <li key={item.id}>
              <Link to={`item/${item.id}`}>
                {item.title}
                <small> : ({item.sku})</small>
              </Link>
            </li>
          ))
        ) : (
          <i>no items for this course</i>
        )}
        <Button
          className="course-items__create-button"
          onClick={createNewItem}
          icon={<PlusOutlined />}
        >
          Create New Item
        </Button>
      </div>
    </div>
  ) : (
    <MessageLoader withRandom={true} />
  );
}
