import _ from 'underscore';
import React from 'react';
import {
  Alert,
  Badge,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import { Link } from 'react-router-dom';

import DatePicker from 'react-datepicker';
import moment from 'moment';

import { Breadcrumb, Checkbox, Spin } from 'antd';

import { getTokenFromCookie } from '../utils/cookie';
import * as API from '../API';

export default class CreateOrEditPromotion extends React.Component {
  state = {
    action: this.props.method,
    products: [],
    promotion: {
      title: '',
      publicNote: '',
      ProductId: '',
      subscriptionEndedAt: '',
      hasCme: false,
      isLimited: false,
      itemLimitPerTransaction: 1,
      restriction: null,
      discountAmount: '',
      discountPercentage: '',
      postPromoDiscount: null,
      isArb: true,
      promotionLength: null
    },
    itemIds: [],
    itemsForSelectedProduct: [],
    successMessage: '',
    errorMessage: '',
    loading: true
  };

  async componentDidMount() {
    this.loadData();
  }

  async loadData() {
    try {
      const products = (
        await API.fetchAllAndSimplify(
          API.product.all({ options: { sort: 'title' } })
        )
      ).filter(product => !product.title.includes('Resident In Service'));
      this.setState({ products });

      if (this.state.action === 'edit') {
        const editResponse = await fetch(
          `${process.env.REACT_APP_JSONAPI_SERVER}/api/promotion/${this.props.id}`,
          {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
              Authorization: `Bearer ${getTokenFromCookie()}`
            }
          }
        );
        if (editResponse.ok) {
          const editData = await editResponse.json();
          const promotion = editData.promotion;

          promotion.startedAt = promotion.startedAt
            ? moment(new Date(promotion.startedAt))
            : null;

          promotion.endedAt = promotion.startedAt
            ? moment(new Date(promotion.endedAt))
            : null;

          promotion.subscriptionEndedAt = promotion.subscriptionEndedAt
            ? moment(new Date(promotion.subscriptionEndedAt))
            : null;

          const itemIds = promotion.PromotionItemBindings.map(
            (binding, index) => {
              return binding.Item.id;
            }
          );
          this.setState({
            promotion,
            itemIds
          });
          if (promotion.ProductId) {
            this.updateItems(promotion.ProductId);
          } else {
            this.updateItems('all');
          }
        } else {
          throw new Error(`${editResponse.status} Error loading promotion`);
        }
      }
    } catch (e) {
      throw new Error(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  async handleSubmit(e) {
    e.preventDefault();
    const submittedPromotion = {};
    if (this.state.itemIds.length === 0) {
      this.setState({
        errorMessage: 'You must associate this promotion with an item.'
      });
    }

    Object.keys(this.state.promotion).forEach(key => {
      const value = this.state.promotion[key];
      const isString = typeof this.state.promotion[key] === 'string';

      if (
        (key === 'discountAmount' || key === 'discountPercentage') &&
        !value
      ) {
        submittedPromotion[key] = null;
      } else if (!(!value && isString)) {
        submittedPromotion[key] = value;
      }
    });

    const token = await getTokenFromCookie();
    if (this.state.action === 'create') {
      const createResponse = await fetch(
        `${process.env.REACT_APP_JSONAPI_SERVER}/api/promotion`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          method: 'POST',
          credentials: 'same-origin',
          body: JSON.stringify({
            itemIds: this.state.itemIds,
            promotion: submittedPromotion
          })
        }
      );
      const createData = await createResponse.json();
      if (createResponse.ok) {
        this.setState({
          successMessage: 'Successfully created promotion'
        });
        this.setState({ loading: false });
        this.props.history.push(`/promotion/${createData.id}/view`);
      } else {
        this.setState({ errorMessage: createData.message });
      }
    } else if (this.state.action === 'edit') {
      const editResponse = await fetch(
        `${process.env.REACT_APP_JSONAPI_SERVER}/api/promotion/${this.props.id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          method: 'POST',
          credentials: 'same-origin',
          body: JSON.stringify({
            itemIds: this.state.itemIds,
            promotion: submittedPromotion
          })
        }
      );
      if (editResponse.ok) {
        this.setState({
          successMessage: 'Successfully updated promotion'
        });
      } else {
        this.setState({
          errorMessage: 'Error updating promotion. Promotion not saved.'
        });
      }
      setTimeout(() => {
        this.setState({ successMessage: '', errorMessage: '' });
      }, 3000);
    }
  }

  updateField(key, value) {
    if (key === 'ProductId' && value === 'all') {
      value = null;
    }
    const promotion = this.state.promotion;
    promotion[key] = value;
    this.setState({ promotion });
  }

  async updateItems(productId) {
    const productFilter = productId !== 'all' ? `ProductId=${productId}` : '';

    const updateItemResponse = await fetch(
      `${process.env.REACT_APP_JSONAPI_SERVER}/api/item?${productFilter}`,
      {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          Authorization: `Bearer ${getTokenFromCookie()}`
        }
      }
    );

    if (updateItemResponse.ok) {
      const updateItemData = await updateItemResponse.json();
      this.setState({ itemsForSelectedProduct: updateItemData });
    } else {
      this.setState({ loading: false });
      this.setState({
        errorMessage: 'Error getting item(s) associated to promotion.'
      });
      setTimeout(() => {
        this.setState({ successMessage: '', errorMessage: '' });
      }, 3000);
    }
  }

  // NOTE this will only be called when we're dealing with one item (as opposed
  // to trying to set numBillingCycles for a group of items)
  //
  // EDIT 1-14-19: Always # months
  setNumBillingCycles(num) {
    const currentPromo = this.state.promotion;
    currentPromo.promotionLength = num;
    this.setState({ promotion: currentPromo });
  }

  render() {
    return this.state.loading ? (
      <>
        <Spin />
        <p>Loading...</p>
      </>
    ) : (
      <div>
        <Row>
          <Col>
            <Breadcrumb style={{ margin: '20px 0' }}>
              <Breadcrumb.Item>
                <Link to="/dashboard">Hippo Admin</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/promotions">Promotions</Link>
              </Breadcrumb.Item>
              {this.state.action === 'edit' && this.state.promotion.title ? (
                <Breadcrumb.Item>
                  <Link to={`/promotion/${this.state.promotion.id}/view`}>
                    {window.asTitle(this.state.promotion.title)}
                  </Link>
                </Breadcrumb.Item>
              ) : null}
              <Breadcrumb.Item active="true">
                <Link to={window.location.pathname}>
                  {window.asTitle(this.state.action)}
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Form>
          <Row>
            <Col>
              <Badge color="primary">Basic info</Badge>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form>
                <FormGroup>
                  <Label>Title</Label>
                  <Input
                    type="text"
                    bsSize="sm"
                    value={this.state.promotion.title}
                    onChange={e =>
                      this.updateField.bind(this)('title', e.target.value)
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Public note</Label>
                  <Input
                    type="textarea"
                    bsSize="sm"
                    value={this.state.promotion.publicNote || ''}
                    onChange={e =>
                      this.updateField.bind(this)('publicNote', e.target.value)
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Product</Label>
                  {this.state.products == null ? (
                    <Input type="select" name="select" bsSize="sm">
                      <option>Loading...</option>
                    </Input>
                  ) : (
                    <Input
                      type="select"
                      name="select"
                      bsSize="sm"
                      value={
                        this.state.promotion.ProductId ||
                        (this.state.promotion.PromotionItemBindings && 'all')
                      }
                      onChange={e => {
                        const productId = e.target.value;
                        this.setState({ itemIds: [] }, () => {
                          this.updateField.bind(this)('ProductId', productId);
                          this.updateItems.bind(this)(productId);
                        });
                      }}
                    >
                      <option />
                      <option value="all">Multiple Products</option>
                      {this.state.products.map((product, index) => {
                        return (
                          <option key={index} value={product.id}>
                            {product.title}
                          </option>
                        );
                      })}
                    </Input>
                  )}
                </FormGroup>
                <FormGroup style={{ margin: '12px 0' }}>
                  <h6>Restriction</h6>
                  <Input
                    type="select"
                    name="select"
                    bsSize="sm"
                    value={this.state.promotion.restriction || ''}
                    onChange={e => {
                      const promotion = this.state.promotion;
                      promotion.restriction =
                        e.target.value === 'NULL' ? null : e.target.value;
                      this.setState({ promotion });
                    }}
                  >
                    <option value="NULL" />
                    <option value="NEW">NEW</option>
                    <option value="EXISTING">EXISTING</option>
                  </Input>
                </FormGroup>
              </Form>
            </Col>
            <Col>
              {this.state.itemsForSelectedProduct.length > 0 ? (
                <FormGroup>
                  <h6>Items</h6>
                  {this.state.itemsForSelectedProduct.map((item, index) => {
                    const isChecked = this.state.itemIds.indexOf(item.id) > -1;
                    return (
                      <div key={item.id}>
                        <Checkbox
                          onChange={() => {
                            if (isChecked) {
                              this.setState({
                                itemIds: _.without(this.state.itemIds, item.id)
                              });
                            } else {
                              this.state.itemIds.push(item.id);
                              this.setState({ itemIds: this.state.itemIds });
                            }
                          }}
                          checked={isChecked}
                        >
                          {item.sku}
                        </Checkbox>
                      </div>
                    );
                  })}
                </FormGroup>
              ) : null}
              <FormGroup check={true}>
                <Label check={true}>
                  <Input
                    type="checkbox"
                    checked={this.state.promotion.isLimited}
                    onChange={e =>
                      this.updateField.bind(this)('isLimited', e.target.checked)
                    }
                  />
                  One-time use?
                </Label>
              </FormGroup>
              <FormGroup check={true}>
                <Label check={true}>
                  <Label check={true}>
                    <Input
                      type="checkbox"
                      checked={
                        this.state.promotion.itemLimitPerTransaction === 0
                      }
                      onChange={e => {
                        if (e.target.checked) {
                          this.updateField.bind(this)(
                            'itemLimitPerTransaction',
                            0
                          );
                        } else {
                          this.updateField.bind(this)(
                            'itemLimitPerTransaction',
                            1
                          );
                        }
                      }}
                    />
                    Apply to all items in cart?
                  </Label>
                  {this.state.promotion.itemLimitPerTransaction !== 0 ? (
                    <>
                      <br />

                      <Label>Item Limit Per Transaction</Label>
                      <Input
                        type="text"
                        style={{ width: 120, marginBottom: 16 }}
                        value={
                          this.state.promotion.itemLimitPerTransaction === 0
                            ? 0
                            : this.state.promotion.itemLimitPerTransaction || ''
                        }
                        onChange={e =>
                          this.updateField.bind(this)(
                            'itemLimitPerTransaction',
                            e.target.value
                          )
                        }
                      />
                    </>
                  ) : null}
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </Form>
        <hr />
        <Form>
          <Row>
            <Col>
              <Badge color="primary">Discounted items</Badge>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                {'Subscription Expiry Date for Discounted Items: '}
                <DatePicker
                  onChange={date =>
                    this.updateField.bind(this)('subscriptionEndedAt', date)
                  }
                  selected={this.state.promotion.subscriptionEndedAt || null}
                />
                <p style={{ lineHeight: 1 }}>
                  <small>
                    If an expiry date is set, items purchased with this
                    promotion will have their subscriptions expire on this date
                    instead of the default subscription length.
                  </small>
                </p>
              </FormGroup>
              <FormGroup check={true}>
                <Label check={true} style={{ marginBottom: 16 }}>
                  <Input
                    type="checkbox"
                    checked={this.state.promotion.hasCme}
                    onChange={e =>
                      this.updateField.bind(this)('hasCme', e.target.checked)
                    }
                  />
                  Discounted Items Receive CME
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </Form>
        <hr />
        <Form>
          <Row>
            <Col>
              <Badge color="primary">Dates & Cycles</Badge>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Start Date</Label>
                <div>
                  <DatePicker
                    onChange={date =>
                      this.updateField.bind(this)('startedAt', date)
                    }
                    selected={this.state.promotion.startedAt || null}
                  />
                </div>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>End Date</Label>
                <div>
                  <DatePicker
                    onChange={date =>
                      this.updateField.bind(this)('endedAt', date)
                    }
                    selected={this.state.promotion.endedAt || null}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <small>
                Start Date and End Date are in PDT and are inclusive.
              </small>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup check={true}>
                <Label check={true}>
                  <Input
                    type="checkbox"
                    checked={!!this.state.promotion.isArb}
                    onChange={e => {
                      const currentPromo = this.state.promotion;
                      currentPromo.isArb = e.target.checked;
                      this.setState({ promotion: currentPromo });
                    }}
                  />
                  Is auto-recurring billing (ARB)
                </Label>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Number of billing cycles (months)</Label>
                <br />
                {/* eslint-disable-next-line jsx-a11y/no-onchange */}
                <select
                  value={this.state.promotion.promotionLength || 1}
                  onChange={e =>
                    this.setNumBillingCycles(
                      e.target.value ? parseInt(e.target.value, 10) : null
                    )
                  }
                >
                  <option />
                  {_.range(24).map(index => {
                    return (
                      <option key={index} value={index + 1}>
                        {index + 1}
                      </option>
                    );
                  })}
                </select>
              </FormGroup>
            </Col>
          </Row>
        </Form>

        <hr />
        <Row>
          <Col>
            <Badge color="primary">Discounts</Badge>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>Fixed discount amount</Label>
              <Input
                type="text"
                style={{ width: 120 }}
                value={this.state.promotion.discountAmount || ''}
                onChange={e =>
                  this.updateField.bind(this)('discountAmount', e.target.value)
                }
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>Percentage discount</Label>
              <Input
                type="text"
                style={{ width: 120 }}
                value={this.state.promotion.discountPercentage || ''}
                onChange={e =>
                  this.updateField.bind(this)(
                    'discountPercentage',
                    e.target.value
                  )
                }
              />
            </FormGroup>
          </Col>
        </Row>
        {this.state.promotion.discountPercentage &&
        parseInt(this.state.promotion.discountPercentage, 10) === 100 ? (
          <Row style={{ marginTop: 25 }}>
            <Col>
              <FormGroup>
                <Label color="primary">"Post-promo" discount</Label> (e.g. 50%)
                <Input
                  type="text"
                  style={{ width: 120 }}
                  value={this.state.promotion.postPromoDiscount || ''}
                  onChange={e =>
                    this.updateField.bind(this)(
                      'postPromoDiscount',
                      parseInt(e.target.value, 10)
                    )
                  }
                />
              </FormGroup>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col>
            <ul>
              <li>
                <small>For a free subscription enter a 100% discount.</small>
              </li>
              <li>
                <small>
                  If both a fixed amount discount and a percentage discount are
                  set, only the fixed amount discount will be applied.
                </small>
              </li>
            </ul>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Button color="primary" onClick={this.handleSubmit.bind(this)}>
              Submit
            </Button>
            {this.state.successMessage ? (
              <Alert style={{ marginTop: 10 }} color="success">
                {this.state.successMessage}
              </Alert>
            ) : null}
            {this.state.errorMessage ? (
              <Alert style={{ marginTop: 10 }} color="danger">
                {this.state.errorMessage}
              </Alert>
            ) : null}
          </Col>
        </Row>
      </div>
    );
  }
}
