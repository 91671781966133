import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { Table, Typography, Button } from 'antd';
import BreadcrumbNavigation from '../../components/BreadcrumbNavigation/BreadcrumbNavigation';
import BreadcrumbConstants from '../../routes/BreadcrumbConstants';
import * as API from '../../API';

import { getTokenFromCookie } from '../../utils/cookie';

import './AcepReport.css';

const { Text } = Typography;

const SORT_DIRECTION = {
  ASC: 'ascend',
  DESC: 'descend'
};

const AcepReport = () => {
  const [reports, setReports] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTION.DESC);

  const sortResultsByQuarter = useCallback(
    (a, b) => {
      return sortDirection === SORT_DIRECTION.ASC
        ? moment(a.attributes.metadata.quarter) -
            moment(b.attributes.metadata.quarter)
        : moment(b.attributes.metadata.quarter) -
            moment(a.attributes.metadata.quarter);
    },
    [sortDirection]
  );

  const fetchReportData = useCallback(async () => {
    setLoading(true);
    await API.fetchAllPages(
      API.report.where({
        filter: {
          type: 'acep'
        }
      })
    )
      .then(async res => {
        setLoading(false);
        if (!res.length) {
          return setError(
            'No ACEP reports found. Try clicking the "Generate Missing Report(s)" button'
          );
        }
        const acepReports = res
          .sort(sortResultsByQuarter)
          .map(report => Object.assign({ key: report.id }, report));
        setReports(acepReports);
      })
      .catch(async error => {
        const errorMessage = error.message;
        setLoading(false);
        if (error.data?.errors?.length > 1) {
          error.data.errors.forEach((error, index) => {
            index > 0 && errorMessage.concat(`: ${error.message}`);
          });
        }
        setError(errorMessage);
      });
  }, [sortResultsByQuarter]);

  useEffect(() => {
    if (!reports && !(loading || error)) {
      fetchReportData();
    }
  }, [loading, reports, error, fetchReportData]);

  const tableColumns = [
    {
      title: 'Title',
      dataIndex: ['attributes', 'title'],
      render: (text, record) => {
        return text;
      },
      key: 'title'
    },
    {
      title: 'Quarter Start Date',
      dataIndex: ['attributes', 'metadata', 'quarter'],
      key: 'dates',
      sorter: true
    },
    {
      title: 'Download CSV',
      key: 'download',
      render: (text, record) => {
        if (record.attributes.key === 'No ACEP orders for this quarter') {
          return <i>no ACEP orders</i>;
        } else {
          return <a href={record.attributes.url}>Download</a>;
        }
      }
    }
  ];

  const handleChange = (pagination, filters, sorter, extra) => {
    sorter.columnKey === 'dates' && sortDirection === SORT_DIRECTION.DESC
      ? setSortDirection(SORT_DIRECTION.ASC)
      : setSortDirection(SORT_DIRECTION.DESC);

    const reportsSorted = reports.sort(sortResultsByQuarter);
    setReports(reportsSorted);
  };

  const generateNewReport = async () => {
    setLoading(true);
    await callGenerateNewReport().then(async res => {
      setLoading(false);
      await fetchReportData();
    });
  };

  async function callGenerateNewReport() {
    const response = await fetch(
      `${process.env.REACT_APP_JSONAPI_SERVER}/api/reports/acep-reports`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getTokenFromCookie()}`
        },
        credentials: 'same-origin',
        method: 'POST'
      }
    );
    return response;
  }

  return (
    <div className="acep-report__container">
      <div>
        <BreadcrumbNavigation
          data={[
            BreadcrumbConstants.HippoAdmin,
            BreadcrumbConstants.AcepReport
          ]}
        />
        <hr />
      </div>
      {error && (
        <Text className="acep-report__error" type="danger">
          {error}
        </Text>
      )}
      <div>
        <Button
          className="acep-report__button"
          onClick={() => generateNewReport()}
        >
          Generate Missing Report(s)
        </Button>
      </div>
      <Table
        style={{ backgroundColor: '#fff' }}
        dataSource={reports}
        columns={tableColumns}
        pagination={false}
        onChange={(pagination, filters, sorter, extra) =>
          handleChange(pagination, filters, sorter, extra)
        }
        loading={loading}
      />
    </div>
  );
};

export default AcepReport;
