import _ from 'underscore';
import React from 'react';
import { Badge, Button, Col, FormGroup, Label, Input, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { Breadcrumb, Table } from 'antd';

import { Link } from 'react-router-dom';

import 'react-datepicker/dist/react-datepicker.css';

import { getTokenFromCookie } from '../utils/cookie';

export default class OrderSearch extends React.Component {
  state = {
    startDate: null,
    endDate: null,
    isSearching: false,
    alreadySearched: false,
    orderSearchResults: [],
    products: null,
    searchParams: {
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      zip: undefined,
      ProductId: undefined,
      promotionCode: undefined,
      startDate: undefined,
      endDate: undefined,
      hasComments: false
    }
  };

  componentDidMount() {
    fetch(`${process.env.REACT_APP_JSONAPI_SERVER}/api/product`, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${getTokenFromCookie()}`
      }
    })
      .then(response => {
        const status = response.status;

        response.json().then(result => {
          if (status < 400) {
            this.setState({ products: result });
          } else {
            window.setError(result);
          }
        });
      })
      .catch(window.setError);
  }

  setSearchParams(key, value) {
    const searchParams = this.state.searchParams;
    searchParams[key] = value;
    this.setState({ searchParams });
  }

  onChangeStartDate(startDate) {
    const searchParams = this.state.searchParams;
    searchParams.startDate = encodeURIComponent(new Date(startDate));
    this.setState({ startDate, searchParams });
  }

  // 2018-04-04 17:01:53.092+02
  onChangeEndDate(endDate) {
    const searchParams = this.state.searchParams;
    searchParams.endDate = encodeURIComponent(new Date(endDate));
    this.setState({ endDate, searchParams });
  }

  searchOrders(e) {
    e && e.preventDefault();

    this.setState({ isSearching: true });

    const baseUrl = '/api/order';

    const keyValueAsString = Object.keys(this.state.searchParams).map(key => {
      if (this.state.searchParams[key]) {
        return key + '=' + this.state.searchParams[key];
      }
      return undefined;
    });
    const searchUrl = '?' + _.compact(keyValueAsString).join('&');

    const orderSearchQuery = searchUrl === '?' ? baseUrl : baseUrl + searchUrl;

    fetch(`${process.env.REACT_APP_JSONAPI_SERVER}${orderSearchQuery}`, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${getTokenFromCookie()}`
      }
    })
      .then(response => {
        return response.json().then(response => {
          this.setState({
            orderSearchResults: response,
            alreadySearched: true
          });
        });
      })
      .catch(err => {
        window.setError(err);
        this.setState({ orderSearchResults: [] });
      })
      .finally(() => {
        this.setState({ isSearching: false });
      });
  }

  render() {
    return (
      <div>
        <Row>
          <Col>
            <Breadcrumb style={{ padding: '20px 0' }}>
              <Breadcrumb.Item>
                <Link to="/dashboard">Hippo Admin</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active={true}>
                <Link to="/order/search">Order Search</Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Search orders</h3>
          </Col>
        </Row>
        <hr />
        <Row>
          <form style={{ flex: 1 }} onSubmit={this.searchOrders.bind(this)}>
            <Col xs="6">
              <Badge color="primary">Customer Info</Badge>
              <FormGroup>
                <Label for="exampleEmail">First name</Label>
                <Input
                  type="text"
                  bsSize="sm"
                  onChange={e =>
                    this.setSearchParams('firstName', e.target.value)
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleEmail">Last name</Label>
                <Input
                  type="text"
                  bsSize="sm"
                  onChange={e =>
                    this.setSearchParams('lastName', e.target.value)
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="examplePassword">Email address</Label>
                <Input
                  type="text"
                  bsSize="sm"
                  onChange={e => {
                    this.setSearchParams(
                      'email',
                      encodeURIComponent(e.target.value.toLowerCase().trim())
                    );
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="examplePassword">Zip / postal code</Label>
                <Input
                  type="text"
                  bsSize="sm"
                  onChange={e => this.setSearchParams('zip', e.target.value)}
                />
              </FormGroup>
            </Col>
            <hr />
            <Col xs="4">
              <Badge color="primary">Order options</Badge>
              <FormGroup>
                <Label for="exampleEmail">Ordered on or after</Label>
                <DatePicker
                  selected={this.state.startDate}
                  onChange={this.onChangeStartDate.bind(this)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleEmail">Ordered before</Label>
                <DatePicker
                  selected={this.state.endDate}
                  onChange={this.onChangeEndDate.bind(this)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleSelect">Product</Label>
                {this.state.products == null ? (
                  <Input type="select" name="select" bsSize="sm">
                    <option>Loading...</option>
                  </Input>
                ) : (
                  <Input
                    type="select"
                    name="select"
                    bsSize="sm"
                    onChange={e => {
                      this.setSearchParams.bind(this)(
                        'ProductId',
                        e.target.value
                      );
                    }}
                  >
                    <option />
                    {this.state.products.map((product, index) => {
                      return (
                        <option key={index} value={product.id}>
                          {product.title}
                        </option>
                      );
                    })}
                  </Input>
                )}
              </FormGroup>
              <FormGroup check={true}>
                <Label check={true}>
                  <Input
                    type="checkbox"
                    onChange={e =>
                      this.setSearchParams('hasComments', e.target.checked)
                    }
                    value={this.state.searchParams.hasComments}
                  />
                  Has comments
                </Label>
              </FormGroup>
            </Col>
            <hr />
            <Col xs="6">
              <Badge color="primary">Promotions</Badge>
              <FormGroup>
                <Label for="exampleEmail">Promotion code</Label>
                <Input
                  type="text"
                  bsSize="sm"
                  onChange={e =>
                    this.setSearchParams('promotionCode', e.target.value)
                  }
                />
              </FormGroup>
            </Col>
            <Col>
              {this.state.isSearching ? (
                <Button color="primary" disabled={true}>
                  Searching...
                </Button>
              ) : (
                <Button color="primary">Search</Button>
              )}
            </Col>
          </form>
        </Row>
        {this.state.orderSearchResults.length === 0 &&
        this.state.alreadySearched ? (
          <p>No results to display</p>
        ) : null}
        <Table
          style={{ backgroundColor: '#fff', margin: '10px 0 20px 0' }}
          pagination={true}
          columns={[
            {
              title: 'Order ID',
              key: 'hippo id',
              render: order => {
                return <Link to={'/orders/' + order.id}>{order.id}</Link>;
              }
            },
            {
              title: 'Customer name',
              key: 'name',
              render: order => {
                return (
                  <span>
                    {order.Account.firstName} {order.Account.lastName}
                  </span>
                );
              }
            },
            {
              title: 'Email',
              key: 'email',
              render: order => {
                return <span>{order.Account.email}</span>;
              }
            },
            {
              title: 'Placed',
              key: 'placed',
              render: order => {
                return (
                  <span>
                    {moment(order.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                  </span>
                );
              }
            },
            {
              title: 'Customer comments',
              key: 'customer comments',
              render: order => {
                return (
                  <span>{order.comments ? <span>&#10003;</span> : ''}</span>
                );
              }
            },
            {
              title: 'Cancelled',
              key: 'cancelled',
              render: order => {
                return (
                  <span>
                    {order.cancelledAt
                      ? moment(order.cancelledAt).format(
                          'MMMM Do YYYY, h:mm:ss a'
                        )
                      : ''}
                  </span>
                );
              }
            }
          ]}
          dataSource={this.state.orderSearchResults}
        />
      </div>
    );
  }
}

// {
//   this.state.orderSearchResults.length > 0 ?
//     <Row>
//       <Col>
//         <hr/>
//         <Table bordered>
//           <thead>
//             <tr>
//               <th>Name</th>
//               <th>Email</th>
//               <th>Placed</th>
//               <th>Total</th>
//               <th>Customer comments?</th>
//               <th>Cancelled</th>
//             </tr>
//           </thead>
//           <tbody>
//           {
//             this.state.orderSearchResults.map((order, index) => {
//               return (
//                 <tr key={ index }>
//                   <td>
//                     <Link to={ '/orders/' + order.id }>{ order.Account.firstName } { order.Account.lastName }</Link>
//                   </td>
//                   <td>{ order.Account.email }</td>
//                   <td>{ moment(order.createdAt).format('MMMM Do YYYY, h:mm:ss a') }</td>
//                   <td>${ order.total } USD</td>
//                   <td>{ order.comments ? <span>&#10003;</span> : '' }</td>
//                   <td>{ order.cancelledAt ? moment(order.cancelledAt).format('MMMM Do YYYY, h:mm:ss a') : '-' }</td>
//                 </tr>
//               );
//             })
//           }
//           </tbody>
//         </Table>
//       </Col>
//     </Row>
//   :
//     null
// }
