import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Tooltip } from 'antd';

import AttachmentUpload from '../Upload/AttachmentUpload';
import EditableLabel from '../EditableLabel/EditableLabel';
import {
  formatTimeHoursMinutesSeconds,
  parseTimeInput
} from '../../utils/time';
import { useAttachmentMutator } from '../../containers/attachments/hooks/useAttachmentHooks';

const AttachmentItem = ({
  item,
  onUpdate,
  onDelete,
  showCmeFields,
  canEdit,
  hideReplace = false,
  hideTitle = false,
  disableSubtitleEdit = false
}) => {
  const { replaceFileOnAttachment, updateAttachment } = useAttachmentMutator();

  const updateAttachmentField = useCallback(
    async (id, field, value) => {
      if (field === 'activityTimeInSeconds') {
        value = parseInt(value, 10) ?? 0;
        if (isNaN(value)) {
          // this happens if user clicks on the field while its 0 and then clicks out without entering a value
          value = 0;
        }
      }
      await updateAttachment({
        variables: {
          id,
          [field]: value
        }
      });
      if (onUpdate) {
        onUpdate(item);
      }
    },
    [updateAttachment, onUpdate, item]
  );
  const disableDelete = item.hasAccountCmeAttempts; // item cannot be deleted if it has associated CME attempts
  return (
    <div key={item.id} className="attachment-item">
      {!hideTitle && (
        <span className="attachment-item-field attachment-item-field__title">
          <EditableLabel
            defaultValue={item.title}
            onChange={value => updateAttachmentField(item.id, 'title', value)}
            disabled={!canEdit}
          />
        </span>
      )}
      <span
        className={classNames(
          'attachment-item-field attachment-item-field__subtitle',
          {
            'attachment-item-field__placeholder': item.subtitle === ''
          }
        )}
      >
        <EditableLabel
          defaultValue={item.subtitle}
          formatLabel={value => value || 'No Subtitle'}
          onChange={value => updateAttachmentField(item.id, 'subtitle', value)}
          disabled={!canEdit || disableSubtitleEdit}
        />
      </span>
      {showCmeFields ? (
        <span className="attachment-item-field attachment-item-field__activityTimeInSeconds">
          <EditableLabel
            defaultValue={item.activityTimeInSeconds}
            formatLabel={value => formatTimeHoursMinutesSeconds(value)}
            onChange={value => {
              const time = parseTimeInput(value);
              updateAttachmentField(item.id, 'activityTimeInSeconds', time);
            }}
          />
        </span>
      ) : null}
      {showCmeFields ? (
        <span className="attachment-item-field attachment-item-field__isContentSummary">
          <Checkbox
            checked={item.isContentSummary}
            onChange={() =>
              updateAttachmentField(
                item.id,
                'isContentSummary',
                !item.isContentSummary
              )
            }
          />
        </span>
      ) : null}
      <div className="attachment-item-buttons">
        <AttachmentUpload
          filename={item.fileName}
          hideReplace={hideReplace}
          type="inline"
          onComplete={async fileData =>
            replaceFileOnAttachment(fileData, item, onUpdate)
          }
          canEdit={canEdit}
        />
        {item.url && (
          <Button href={item.url}>
            <DownloadOutlined />
            <span>Download</span>
          </Button>
        )}
        {canEdit && (
          <Tooltip
            title={
              disableDelete
                ? "This item can't be deleted because it has associated CME attempts."
                : ''
            }
          >
            <Button
              disabled={disableDelete}
              type="danger"
              onClick={() => onDelete && onDelete(item)}
            >
              <DeleteOutlined />
            </Button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

AttachmentItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    key: PropTypes.string
  }).isRequired,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  showCmeFields: PropTypes.bool
};

AttachmentItem.defaultProps = {
  onUpdate: null,
  onDelete: null
};

export default AttachmentItem;
