import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { sortBy } from 'lodash';

import * as API from '../../API';

export default function CourseSelector({
  onCourseSelected,
  courseIdList,
  defaultValue
}) {
  const [courseList, setCourseList] = useState([]);

  const getCourses = async () => {
    // Optionally filter courses to a specific list of courses
    const filter = {};
    if (courseIdList) {
      filter.id = courseIdList;
    }
    const courses = await API.course.where({
      filter
    });
    setCourseList(sortBy(courses.data, 'attributes.title'));
    if (courses.data.length === 1) {
      onCourseSelected(courses.data[0].id);
    }
  };

  useEffect(() => {
    if (courseList && courseList.length === 0) {
      getCourses();
    }
  });

  return (
    <div>
      <Select
        placeholder="Select Course"
        defaultValue={defaultValue}
        onSelect={value => {
          onCourseSelected(value);
        }}
      >
        {courseList &&
          courseList.map(course => (
            <Select.Option key={course.id} value={course.id}>
              {course.attributes.title}
            </Select.Option>
          ))}
      </Select>
    </div>
  );
}
