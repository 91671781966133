import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { notify } from 'react-notify-toast';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import {
  DeleteOutlined,
  EditOutlined,
  PaperClipOutlined,
  PlaySquareOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  ApartmentOutlined
} from '@ant-design/icons';
import { Button, Tooltip, Spin } from 'antd';
import * as API from '../../API';
import getSlug from '../../utils/getSlug';
import EditableLabel from '../../components/EditableLabel/EditableLabel';
import UpdateLectureHierarchy from './UpdateLectureHierarchy';

import './LectureList.css';
import { LectureRowLastReviewedAtPicker } from './LectureRowLastReviewedAtPicker';

const getRowStyle = (isDragging, draggableStyle) => ({
  display: 'flex',
  marginBottom: 2,
  userSelect: 'none',
  justifyContent: 'space-between',
  padding: 12,
  background: isDragging ? '#eee' : '#fff',
  boxShadow: isDragging ? 'box-shadow: 0 10px 6px -6px #777' : 'none',
  ...draggableStyle
});

const getTopicLectures = async topicId => {
  const lectures = await API.lecture.where({
    filter: {
      activatedAt: '@any',
      expiredAt: '@any',
      topic: topicId
    }
  });

  return lectures.data;
};

export default class LectureList extends React.Component {
  static propTypes = {
    courseId: PropTypes.string,
    parent: PropTypes.shape({
      type: PropTypes.string,
      id: PropTypes.string,
      attributes: PropTypes.shape({}),
      relationships: PropTypes.shape({}),
      links: PropTypes.shape({}),
      setLectureListRef: PropTypes.func,
      onQueryStart: PropTypes.func,
      onQueryEnd: PropTypes.func
    })
  };

  static defaultProps = {
    parent: undefined
  };

  constructor(props) {
    super(props);

    this.state = {
      lectures: [],
      lectureOpen: {},
      pendingQueries: 0,
      error: null,
      showUpdateHierarchyModal: false,
      lectureToModify: null
    };
  }

  componentDidMount() {
    this.fetchLectures();
  }

  getParentLectureId = () => {
    if (this.props.parent.type === 'topic') {
      return undefined;
    }
    return this.props.parent.id;
  };

  isSubLecture = id => this.props.parent.type === 'lecture';

  onQueryStart = count => {
    const pendingQueries = this.state.pendingQueries + count;
    this.setState({ pendingQueries });
  };

  onQueryEnd = () => {
    const pendingQueries = this.state.pendingQueries - 1;
    this.setState({ pendingQueries });
  };

  getTopicId = parentId => {
    if (this.props.parent.type === 'topic') {
      return this.props.parent.id;
    }
    return this.props.parent.relationships.topic.data.id;
  };

  moveItem = (sourceIndex, targetIndex) => {
    if (sourceIndex === targetIndex) {
      return;
    }

    const { lectures } = this.state;
    const lecture = lectures[sourceIndex];
    lectures.splice(sourceIndex, 1);
    lectures.splice(targetIndex, 0, lecture);
    lectures.map((child, i) =>
      this.updateLecture(child.id, 'displayOrder', i * 10)
    );

    this.setState({ lectures });
  };

  insertItem = async (targetIndex, lecture) => {
    const { lectures } = this.state;
    lectures.splice(targetIndex, 0, lecture);
    this.updateLecture(
      lecture.id,
      'urlSlug',
      getSlug(
        lecture.id,
        lecture.attributes.urlSlug,
        await getTopicLectures(this.getTopicId())
      )
    );
    lectures.map((child, i) =>
      this.updateLecture(child.id, 'displayOrder', i * 10)
    );
    this.setState({ lectures });
  };

  removeItem = sourceIndex => {
    const { lectures } = this.state;
    lectures.splice(sourceIndex, 1);
    lectures.map((child, i) =>
      this.updateLecture(child.id, 'displayOrder', i * 10)
    );
    this.setState({ lectures });
  };

  getLecture = id => _.findWhere(this.state.lectures, { id });

  getRelationships = () => {
    const relationships = {};

    // Override parent info
    relationships.topic = {
      data: {
        type: 'topic',
        id: this.getTopicId()
      }
    };
    if (this.getParentLectureId()) {
      relationships.parent = {
        data: {
          type: 'lecture',
          id: this.getParentLectureId()
        }
      };
    }
    return relationships;
  };

  createLecture = async () => {
    const title = `New ${this.isSubLecture() ? 'Child ' : ''}Lecture`;
    try {
      this.onQueryStart(1);
      await API.lecture.create({
        attributes: {
          title,
          urlSlug: getSlug(
            null,
            title,
            await getTopicLectures(this.getTopicId())
          ),
          displayOrder: this.state.lectures.length * 10,
          activatedAt: null,
          expiredAt: null
        },
        relationships: this.getRelationships()
      });
      this.onQueryEnd();
      this.fetchLectures();
    } catch (error) {
      this.setState({ error });
    }
  };

  deleteLecture = async lecture => {
    try {
      if (
        !window.confirm(
          `Are you sure you want to delete lecture ${lecture.attributes.title}?`
        )
      ) {
        return;
      }
      this.onQueryStart(1);
      await API.lecture.delete({
        id: lecture.id
      });
      this.onQueryEnd();
      this.fetchLectures();
    } catch (error) {
      this.setState({ error });
    }
  };

  updateLecture = async (id, field, value) => {
    try {
      const { lectures } = this.state;
      const lectureIndex = _.findIndex(lectures, { id });
      const lecture = _.clone(lectures[lectureIndex]);
      lecture.attributes[field] = value;

      if (field === 'title' || field === 'urlSlug') {
        lecture.attributes.urlSlug = await getSlug(
          id,
          value,
          await getTopicLectures(this.getTopicId())
        );
      }

      const relationships = this.getRelationships();
      if (!this.getParentLectureId()) {
        // This is a hack for an issue in the jsonapi server
        // If we set the relationship to null, it simply errors out.
        lecture.attributes.ParentId = null;
      }

      this.onQueryStart(1);
      await API.lecture.update({
        id: lecture.id,
        attributes: lecture.attributes,
        relationships
      });
      this.onQueryEnd();
      this.setState({ lectures });
    } catch (error) {
      if (error.data) {
        this.onQueryEnd();
        notify.show(error.data.errors[0].detail[0].message, 'error');
      } else {
        throw error;
      }
    }
  };

  fetchLectures = async () => {
    try {
      if (!this.props.parent) {
        return;
      }
      this.onQueryStart(1);
      const filter = Object.assign(
        {
          activatedAt: '@any',
          expiredAt: '@any'
        },
        this.getParentLectureId()
          ? {
              parent: this.props.parent.id
            }
          : {
              topic: this.props.parent.id,
              ParentId: '@isNull'
            }
      );

      const lectures = await API.lecture.where({ filter });
      this.onQueryEnd();
      this.setState({ lectures: lectures.data ?? [] });
    } catch (error) {
      this.setState({ error });
    }
  };

  isLectureOpen = id => {
    if (this.state.lectureOpen[id] === undefined) {
      return false;
    }
    return this.state.lectureOpen[id];
  };

  toggleLectureOpen = id => {
    const { lectureOpen } = this.state;
    if (lectureOpen[id] === undefined) {
      lectureOpen[id] = true;
    } else {
      lectureOpen[id] = !lectureOpen[id];
    }
    this.setState({ lectureOpen });
  };

  render() {
    if (this.state.error !== null) {
      throw this.state.error;
    }
    const droppableId = this.props.parent ? this.props.parent.id : null;
    return (
      <div className="lecture-view">
        <div className="lecture-header">
          <div className="column-span-small">
            {this.state.pendingQueries > 0 && <Spin />}
          </div>
          <div className="column-span-large">
            <b>Lecture Title</b>
          </div>
          <div className="column-span-large">URL Slug</div>
          <div className="column-span-medium">Starts</div>
          <div className="column-span-medium">Ends</div>
          <div className="column-span-medium">Last Reviewed</div>
          <div className="column-span-medium" />
        </div>
        {this.state.lectures.length === 0 && (
          <div style={getRowStyle(false)}>No data</div>
        )}
        <div>
          <Droppable
            droppableId={droppableId}
            type={this.isSubLecture() ? 'sublecture' : 'lecture'}
          >
            {providedDrop => (
              <div {...providedDrop.droppableProps} ref={providedDrop.innerRef}>
                {this.state.lectures.map((child, index) => (
                  <div key={child.id}>
                    {/* Lecture info */}
                    <Draggable
                      key={child.id}
                      draggableId={child.id}
                      index={index}
                      type={this.isSubLecture() ? 'sublecture' : 'lecture'}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getRowStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <div className="column-span-small">
                            {!this.isSubLecture() && (
                              <Button
                                type="primary"
                                icon={
                                  this.isLectureOpen(child.id) ? (
                                    <CaretDownOutlined />
                                  ) : (
                                    <CaretUpOutlined />
                                  )
                                }
                                ghost={true}
                                style={{ lineHeight: '100%' }}
                                onClick={() => this.toggleLectureOpen(child.id)}
                              />
                            )}
                          </div>
                          <div className="column-span-large">
                            <EditableLabel
                              defaultValue={child.attributes.title}
                              onChange={value =>
                                this.updateLecture(child.id, 'title', value)
                              }
                            />
                          </div>
                          <div className="column-span-large">
                            <EditableLabel
                              defaultValue={child.attributes.urlSlug}
                              validation={/^[\w\d-]+$/}
                              errorMessage="Must be letters, numbers, or -"
                              onChange={value =>
                                this.updateLecture(child.id, 'urlSlug', value)
                              }
                            />
                          </div>
                          <div className="column-span-medium">
                            <EditableLabel
                              defaultValue={child.attributes.activatedAt}
                              inputType="date"
                              errorMessage="Must be a date"
                              onChange={value =>
                                this.updateLecture(
                                  child.id,
                                  'activatedAt',
                                  value
                                )
                              }
                            />
                          </div>
                          <div className="column-span-medium">
                            <EditableLabel
                              defaultValue={child.attributes.expiredAt}
                              inputType="date"
                              errorMessage="Must be a date"
                              onChange={value =>
                                this.updateLecture(child.id, 'expiredAt', value)
                              }
                            />
                          </div>

                          <div className="column-span-medium">
                            <LectureRowLastReviewedAtPicker
                              lecture={child}
                              onChange={value =>
                                this.updateLecture(
                                  child.id,
                                  'lastReviewedAt',
                                  value
                                )
                              }
                            />
                          </div>
                          <div className="column-span-medium button-column">
                            <div className="lecture-list--status-icons">
                              {child.relationships.media.data ? (
                                <Tooltip title="This lecture has media">
                                  <PlaySquareOutlined className="lecture-list--media-status" />
                                </Tooltip>
                              ) : (
                                <Tooltip title="This lecture has no media">
                                  <PlaySquareOutlined className="lecture-list--media-status lecture-list--media-status--disabled" />
                                </Tooltip>
                              )}
                              {child.relationships.attachments.data.length >
                              0 ? (
                                <Tooltip title="This lecture has attachments">
                                  <PaperClipOutlined className="lecture-list--attachment-status" />
                                </Tooltip>
                              ) : (
                                <Tooltip title="This lecture has no attachments">
                                  <PaperClipOutlined className="lecture-list--attachment-status lecture-list--attachment-status--disabled" />
                                </Tooltip>
                              )}
                            </div>
                            <Button
                              type="primary"
                              icon={<EditOutlined />}
                              ghost={true}
                              href={`/lecture/${child.id}`}
                            />
                            <Button
                              type="default"
                              icon={<ApartmentOutlined />}
                              onClick={() => {
                                this.setState({
                                  showUpdateHierarchyModal: true,
                                  lectureToModify: child.id
                                });
                              }}
                            />
                            <Button
                              type="danger"
                              icon={<DeleteOutlined />}
                              ghost={true}
                              style={{ lineHeight: '100%' }}
                              onClick={() => this.deleteLecture(child)}
                            />
                            <UpdateLectureHierarchy
                              visible={
                                this.state.showUpdateHierarchyModal &&
                                child.id === this.state.lectureToModify
                              }
                              parent={this.props.parent}
                              lectureId={child.id}
                              courseId={this.props.courseId}
                              handleClose={() =>
                                this.setState({
                                  showUpdateHierarchyModal: false
                                })
                              }
                            />
                          </div>
                        </div>
                      )}
                    </Draggable>
                    {/* End lecture info */}
                    {/* Lecture info -- only if expanded */}
                    {this.isLectureOpen(child.id) && (
                      <LectureList
                        parent={child}
                        courseId={this.props.courseId}
                        ref={ref => this.props.setLectureListRef(child.id, ref)}
                        setLectureListRef={this.props.setLectureListRef}
                        onQueryStart={this.onQueryStart}
                        onQueryEnd={this.onQueryEnd}
                      />
                    )}
                  </div>
                ))}
                {providedDrop.placeholder}
                <div className="lecture-footer">
                  <div className="column-span-small">
                    <Button
                      type="primary"
                      style={{ lineHeight: '100%' }}
                      onClick={this.createLecture}
                    >
                      New Lecture
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </Droppable>
        </div>
      </div>
    );
  }
}
