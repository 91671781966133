import React, { useState, useEffect } from 'react';
import { Button, Card, CardTitle, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import BreadcrumbConstants from '../routes/BreadcrumbConstants';
import ContentHeader from './content/ContentHeader';
import { GET_CART_ITEMS_LIST_QUERY } from './items/queries/GetItemsListQueries';
import { COURSE_SALES_PAGE_QUERY } from './course/queries/CourseQueries';
import { useQuery } from '@apollo/client';
import { notification, Select } from 'antd';

export default function GeneratePromoLink({ id, title, code, items }) {
  const [cartItems, setCartItems] = useState([]);
  const [linkType, setLinkType] = useState('');
  const [sku, setSku] = useState('');
  const [salesPageUrl, setSalesPageUrl] = useState('');
  const [additionalItemSkus, setAdditionalItemSkus] = useState('');
  const [showLink, setShowLink] = useState(false);
  const [link, setLink] = useState('');
  const [courseSalesPageUrls, setCourseSalesPageUrls] = useState([]);

  const { data } = useQuery(GET_CART_ITEMS_LIST_QUERY);
  const { data: courseData } = useQuery(COURSE_SALES_PAGE_QUERY);

  useEffect(() => {
    if (courseData) {
      setCourseSalesPageUrls(courseData.coursesList);
    }
  }, [courseData]);

  useEffect(() => {
    if (data && data.itemsForCreateOrder) {
      const filterBasedOnEnvironment = item => {
        if (process.env.REACT_APP_WEB_HOST === 'https://www.hippoed.com') {
          const now = new Date();
          return (
            item.startedAt < now &&
            (item.endedAt > now || item.endedAt === null)
          );
        }
        return item;
      };
      const activeSortedItems = data.itemsForCreateOrder
        .filter(item => filterBasedOnEnvironment(item))
        .sort((a, b) => a.sku.localeCompare(b.sku));
      setCartItems(activeSortedItems);
    }
  }, [data]);

  const breadCrumb = [
    BreadcrumbConstants.HippoAdmin,
    BreadcrumbConstants.Promotions,
    {
      to: `/promotion/${id}/view`,
      title
    },
    {
      to: `/promotion/${id}/generateLink`,
      title: code
    }
  ];

  const generateLink = () => {
    if (!sku || !linkType) {
      alert('Please choose an item and link type');
      return;
    }

    if (salesPageUrl && linkType !== 'cart') {
      setLink(`${salesPageUrl}?promo=${code}`);
    } else if (sku && linkType === 'cart') {
      const cartBaseLink = `https://www.hippoed.com/promotion/${code}/${sku}`;
      if (additionalItemSkus?.length > 0) {
        const buildSkuList = additionalItemSkus.reduce(
          (skuList, itemSku) =>
            (skuList = skuList ? `${skuList},${itemSku}` : itemSku),
          ''
        );
        setLink(`${cartBaseLink}?cartItems=${buildSkuList}`);
      } else {
        setLink(cartBaseLink);
      }
    } else {
      setLink('Item not yet available for new cart promo');
    }

    setShowLink(true);
  };

  return (
    <div>
      <Row>
        <Col className="generate-promo-link__breadcrumbs">
          <ContentHeader breadCrumb={breadCrumb} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card body={true} className="generate-promo-link__container">
            <CardTitle>Generate Link</CardTitle>
            <div className="generate-promo-link__select-container">
              <Select
                className="generate-promo-link__select"
                placeholder="Select main promo item"
                onChange={e => {
                  const selectedItem = items.find(item => item.Item.id === e);
                  const course = courseSalesPageUrls.find(
                    course => course.id === selectedItem?.Item?.CourseId
                  );
                  setSalesPageUrl(course?.salesPageUrl);
                  setSku(selectedItem?.Item?.sku);
                }}
              >
                {items.map(item => (
                  <Select.Option key={item.Item.id} value={item.Item.id}>
                    {item.Item.sku}
                  </Select.Option>
                ))}
              </Select>
              <Select
                className="generate-promo-link__select"
                placeholder="Select a link type"
                onChange={e => {
                  setLink('');
                  setShowLink(false);
                  setLinkType(e);
                }}
              >
                <Select.Option value="salesPage">Sales Page</Select.Option>
                <Select.Option value="cart">Direct to Cart</Select.Option>
              </Select>
              {linkType === 'cart' && (
                <Select
                  mode="multiple"
                  className="generate-promo-link__select"
                  placeholder="Select additional items to populate cart (optional)"
                  onChange={e => setAdditionalItemSkus(e)}
                >
                  {cartItems.map(item => (
                    <Select.Option key={item.id} value={item.sku}>
                      {item.sku}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </div>
            <Button color="primary" onClick={generateLink}>
              Create
            </Button>
            {!showLink ? null : (
              <div>
                <div
                  onClick={() => {
                    navigator.clipboard.writeText(link);
                    notification.success({
                      message: 'copied to clipboard'
                    });
                  }}
                  className="generate-promo-link__link"
                >
                  {link}
                </div>
                <i>Click anywhere within box to copy</i>
              </div>
            )}
            <Link
              className="generate-promo-link__back-button"
              to={`/promotion/${id}/view`}
            >
              Go back
            </Link>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
