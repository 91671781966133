import { gql } from '@apollo/client';

const GET_ORGANIZATIONS_QUERY = gql`
  query getOrganizations(
    $limit: Int
    $offset: Int
    $filter: OrganizationFilter
  ) {
    organizations(
      offset: $offset
      first: $limit
      filter: $filter
      orderBy: NAME_ASC
    ) {
      totalCount
      nodes {
        id
        name
        manager {
          email
        }
        organizationProgramBindingsList {
          id
        }
      }
    }
  }
`;

export default GET_ORGANIZATIONS_QUERY;
