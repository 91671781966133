import { gql } from '@apollo/client';

const UPDATE_SHORTNAME_ORGANIZATION_MUTATION = gql`
  mutation editOrganization($id: UUID!, $shortname: String) {
    updateOrganization(input: { patch: { shortname: $shortname }, id: $id }) {
      clientMutationId
    }
  }
`;

export default UPDATE_SHORTNAME_ORGANIZATION_MUTATION;
