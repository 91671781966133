import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button, List, Input, Checkbox, Pagination } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import sortByKey from '../../utils/sortByKey';
import { useMutation, useQuery } from '@apollo/client';
import { debounce } from 'lodash';

import GET_USERS_QUERY from './queries/GetUsersGQLQuery';
import CREATE_ORGANIZATION_ADMIN_BINDING_MUTATION from './queries/CreateOrganizationAdminBindingMutation';
import DELETE_ORGANIZATION_ADMIN_BINDING_MUTATION from './queries/DeleteOrganizationAdminBindingMutation';

const MapExistingAdminModal = props => {
  const { refreshParent, organizationId, preselectedAdmins } = props;
  const [admins, setAdmins] = useState();
  const [showModal, setShowModal] = useState(false);
  const [selectedAdmins, setSelectedAdmins] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const { data, fetchMore, refetch } = useQuery(GET_USERS_QUERY, {
    variables: {
      limit,
      offset
    }
  });

  const [addOrganizationAdminBinding] = useMutation(
    CREATE_ORGANIZATION_ADMIN_BINDING_MUTATION,
    {
      onCompleted: async (data, clientOptions) => {
        if (refreshParent) {
          await refreshParent();
        }
      }
    }
  );

  const openModal = () => {
    setShowModal(true);
    if (preselectedAdmins) {
      const initialSelection = preselectedAdmins.map(admin => {
        return {
          __typename: 'Account',
          id: admin.adminAccountId,
          firstName: admin.firstName,
          lastName: admin.lastName,
          suffix: admin.suffix,
          email: admin.email
        };
      });
      setSelectedAdmins(initialSelection);
    }
  };

  const [deleteOrganizationAdmin] = useMutation(
    DELETE_ORGANIZATION_ADMIN_BINDING_MUTATION,
    {
      onCompleted: async (data, clientOptions) => {
        if (refreshParent) {
          await refreshParent();
        }
      }
    }
  );

  const onSelectAdmin = admin => {
    const adminSelected =
      selectedAdmins.findIndex(selectedAdmin => {
        return selectedAdmin.id === admin.id;
      }) >= 0;
    if (adminSelected) {
      const selectAdmins = selectedAdmins.filter(selectedAdmin => {
        return selectedAdmin.id !== admin.id;
      });

      setSelectedAdmins(selectAdmins);
    } else {
      const newSelectedAdmins = [...selectedAdmins, admin];

      setSelectedAdmins(newSelectedAdmins);
    }
  };

  const onAddAdmins = () => {
    sortByKey(selectedAdmins, 'lastName');

    selectedAdmins.forEach(async admin => {
      if (
        preselectedAdmins.findIndex(
          prevClinic => prevClinic.adminAccountId === admin.id
        ) >= 0
      ) {
        // do nothing -- already added
      } else {
        // must add
        await addOrganizationAdminBinding({
          variables: {
            id: uuidv4(),
            organizationId,
            adminAccountId: admin.id
          }
        });
      }
    });
    preselectedAdmins.forEach(async propAdmin => {
      if (
        selectedAdmins.findIndex(
          selectedAdmin => selectedAdmin.id === propAdmin.adminAccountId
        ) >= 0
      ) {
        // do nothing -- already added
      } else {
        // is in the db but was removed -- must delete
        await deleteOrganizationAdmin({
          variables: {
            id: propAdmin.id
          }
        });
      }
    });

    setShowModal(false);
  };

  useEffect(() => {
    const filter =
      searchText.length > 0
        ? {
            or: [
              {
                firstName: { includesInsensitive: searchText }
              },
              { lastName: { includesInsensitive: searchText } }
            ]
          }
        : {};
    if (searchText.includes(' ')) {
      filter.or.push({
        and: [
          {
            firstName: { includesInsensitive: searchText.split(' ')[0] }
          },
          {
            lastName: { includesInsensitive: searchText.split(' ')[1] }
          }
        ]
      });
    }

    if (searchText) {
      refetch({
        limit,
        first: offset,
        filter
      });
    } else {
      refetch({
        limit,
        first: offset,
        filter: [{ firstName: { includesInsensitive: '' } }]
      });
    }
    if (data) {
      setAdmins(data.accounts.nodes);
    }
  }, [refetch, searchText, limit, offset, data]);

  const debouncedSearchFilterChanged = debounce(async incomingSearchValue => {
    setSearchText(incomingSearchValue);
  }, 500);

  const handleSearchFilterChange = useCallback(debouncedSearchFilterChanged, [
    debouncedSearchFilterChanged
  ]);

  return (
    <>
      <span style={{ display: 'inline-flex', width: '10rem' }}>
        <Button type="primary" onClick={openModal}>
          Map Existing Admin
        </Button>
      </span>
      <Modal
        width="600px"
        title="Admins"
        visible={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="add"
            type="primary"
            disabled={!selectedAdmins || selectedAdmins.length === 0}
            onClick={onAddAdmins}
          >
            Update Selected Admins
          </Button>
        ]}
      >
        <Input
          placeholder="Search Admins by Name"
          onChange={e => handleSearchFilterChange(e.target.value)}
        />
        <List
          size="small"
          header={
            <div>
              <span style={{ display: 'inline-flex' }}>
                <Checkbox />
              </span>
              <span style={{ display: 'inline', marginLeft: '2rem' }}>
                Admin Name
              </span>
              <span style={{ display: 'inline', float: 'right' }}>Email</span>
            </div>
          }
          bordered={true}
          dataSource={admins}
          renderItem={admin => {
            const checked =
              selectedAdmins.findIndex(selectedAdmin => {
                return selectedAdmin.id === admin.id;
              }) >= 0;
            return (
              <List.Item>
                <Checkbox
                  style={{ display: 'inline-flex' }}
                  checked={checked}
                  onChange={() => onSelectAdmin(admin)}
                />
                <span style={{ marginRight: 'auto', marginLeft: '2rem' }}>
                  {`${admin.firstName} ${admin.lastName} ${
                    admin.suffix ? admin.suffix : ''
                  }`}
                </span>
                <span style={{ display: 'inline', float: 'right' }}>
                  {admin.email}
                </span>
              </List.Item>
            );
          }}
          pagination={false}
        />
        <Pagination
          current={offset / limit + 1}
          onChange={(pageNumber, pageSize) => {
            setLimit(pageSize);
            setOffset(pageSize * (pageNumber - 1));
            fetchMore({
              variables: { offset, first: limit }
            });
          }}
          total={data?.accounts.totalCount}
          pageSize={limit}
          showSizeChanger={true}
        />
      </Modal>
    </>
  );
};
export default MapExistingAdminModal;
