import React, { useState } from 'react';
import { Button, Modal, Spin } from 'antd';
import Step2 from '../../../containers/createOrderSteps/Step2';
import Step3 from '../../../containers/createOrderSteps/Step3';

import './AddSubscriptionModal.css';
import _ from 'lodash';

const initialPurchaseState = {
  account: null,
  item: null,
  paymentMethodNonce: null,
  promotionCode: null,
  price: null,
  adminComments: ''
};

export const AddSubscriptionModal = ({
  loading,
  visible,
  account,
  onSubmit,
  cleanupAndClose
}) => {
  const [step, setStep] = useState(0);
  const [purchaseData, setPurchaseData] = useState({
    ...initialPurchaseState,
    account
  });

  const componentsForSteps = [Step2, Step3];
  const StepComponent = componentsForSteps[step];

  return (
    <Modal
      wrapClassName="add-subscription-modal"
      visible={visible}
      title="Add Subscription"
      onCancel={cleanupAndClose}
      footer={
        <>
          {loading ? (
            <Spin />
          ) : (
            <Button onClick={cleanupAndClose}>Cancel</Button>
          )}
        </>
      }
    >
      <div>
        <StepComponent
          purchaseData={purchaseData}
          prepaidOnly={true}
          onComplete={data => {
            const purchase = purchaseData;
            _.extend(purchase, data);
            setPurchaseData(purchase);

            step < componentsForSteps.length - 1
              ? setStep(step + 1)
              : onSubmit(purchaseData);
          }}
        />
      </div>
    </Modal>
  );
};
