import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'antd';
import BreadcrumbNavigation from '../../components/BreadcrumbNavigation/BreadcrumbNavigation';
import BreadcrumbConstants from '../../routes/BreadcrumbConstants';
import BundleCard from '../../components/Bundle/BundleCard';
import BundleItems from '../../components/Bundle/BundleItems';
import * as API from '../../API';

import './Bundles.css';

export default function Bundle({ title, startDate, endDate }) {
  const params = useParams();
  const bundleId = params.id;
  const [activeBundle, setActiveBundle] = useState(null);

  useEffect(() => {
    const getBundle = async () => {
      const bundle = await API.bundle.find({
        id: bundleId
      });

      setActiveBundle(bundle.data);
    };
    getBundle();
  }, [bundleId]);

  if (!activeBundle) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <BreadcrumbNavigation
        data={[
          BreadcrumbConstants.HippoAdmin,
          BreadcrumbConstants.Bundles,
          {
            title:
              activeBundle &&
              activeBundle.attributes &&
              activeBundle.attributes.title
          }
        ]}
      />
      <hr />
      <Card
        className="bundle__column-padded-lg"
        title={
          <BundleCard
            bundleId={bundleId}
            title={activeBundle && activeBundle.attributes.title}
            startedAt={activeBundle.attributes.startedAt}
            endedAt={activeBundle.attributes.endedAt}
          />
        }
      >
        <BundleItems bundleId={bundleId} />
      </Card>
    </div>
  );
}
