import { gql } from '@apollo/client';

const DELETE_ORGANIZATION_EMAIL_DOMAIN_MUTATION = gql`
  mutation DeleteOrganizationEmailDomain($id: UUID!) {
    deleteOrganizationEmailDomain(input: { id: $id }) {
      clientMutationId
    }
  }
`;

export default DELETE_ORGANIZATION_EMAIL_DOMAIN_MUTATION;
