import React from 'react';
import * as Sentry from '@sentry/browser';
import './ErrorBoundary.css';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, eventId: 'N/A (Sentry Disabled)' };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log(error);
    console.log(info);
    if (process.env.REACT_APP_SENTRY_DSN) {
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    }
  }

  render() {
    if (this.state.hasError) {
      // TODO Design/implement a generic error page
      return (
        <div className="error-boundary__wrapper">
          <h1>Something went wrong.</h1>
          <p>
            If you report this to a developer, please pass on this Sentry error
            code: {this.state.eventId}
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}
