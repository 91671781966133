const HippoAdmin = { to: '/dashboard', title: 'Hippo Admin' };
const Courses = { to: '/courses', title: 'Courses' };
const Podcast = { to: '/courses/podcast', title: 'Podcast' };
const Video = { to: '/courses/video', title: 'Video' };
const Qbank = { to: '/courses/qbank', title: 'QBank' };
const Content = { to: '/content', title: 'Content' };
const Assessments = { to: '/content/assessments', title: 'Assessments' };
const Questions = {
  to: '/content/questions',
  title: 'Qbank & Assessment Questions'
};
const Tags = { to: '/content/tags', title: 'Tags' };
const Episode = { to: '/content/episodes', title: 'Episodes' };
const Chapter = { to: '/content/chapters', title: 'Chapters' };
const User = { to: '/groups/member', title: 'Member' };
const Organizations = {
  to: '/group-management/organizations',
  title: 'Organizations'
};
const GroupManagement = { to: '/group-management', title: 'Group Management' };
const Promotions = { to: '/promotions', title: 'Promotions' };
const Products = { to: '/products', title: 'Products' };
const Comments = { to: '/comments', title: 'Comments' };
const Bundles = { to: '/bundles', title: 'Bundles' };
const Associations = { to: '/associations', title: 'Associations' };
const AcepReport = { to: '/report/acep', title: 'ACEP Report' };

const breadcrumbByCourseType = courseType => {
  switch (courseType) {
    case 'video':
      return Video;
    case 'podcast':
      return Podcast;
    case 'qbank':
      return Qbank;
    case 'assessment':
    case 'bootcamp-assessment':
      return Assessments;
    default:
      return null;
  }
};

const BreadcrumbConstants = {
  HippoAdmin,
  AcepReport,
  Assessments,
  Associations,
  Bundles,
  Chapter,
  Courses,
  Content,
  Episode,
  GroupManagement,
  Organizations,
  Podcast,
  Products,
  Comments,
  Promotions,
  Qbank,
  Questions,
  Tags,
  User,
  Video,
  breadcrumbByCourseType
};

export default BreadcrumbConstants;
