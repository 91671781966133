import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Breadcrumb, Table } from 'antd';
import { Spin } from 'antd';
import * as API from '../../API';

import './EvaluationReport.css';

export default class Courses extends React.Component {
  static propTypes = {
    courseType: PropTypes.string
  };

  state = {
    error: '',
    reports: [],
    loading: true
  };

  componentDidUpdate(prevProps) {}

  async componentDidMount() {
    this.loadReports();
  }

  async loadReports() {
    const reports = (
      await API.fetchAllPages(
        API.report.where({
          filter: {
            type: 'evaluation'
          },
          options: {
            sort: 'title'
          }
        })
      )
    ).map(report => Object.assign({ key: report.id }, report));

    const courseIds = reports.map(
      report => report.attributes.metadata.CourseId
    );

    if (courseIds.length > 0) {
      const uniqueCourseIds = [...new Set(courseIds)];
      const courses = await API.course.where({
        filter: { id: uniqueCourseIds }
      });

      reports.map(report => {
        report.course = courses.data.find(
          course => course.id === report.attributes.metadata.CourseId
        );
        return report;
      });
    }

    this.setState({ reports, loading: false });
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="loading">
          <Spin /> Loading...
        </div>
      );
    }

    const tableColumns = [
      {
        title: 'Course',
        dataIndex: ['course', 'attributes', 'title'],
        sorter: (a, b) => a.course.attributes.title > b.course.attributes.title,
        key: 'course'
      },
      {
        title: 'Quarter',
        dataIndex: ['attributes', 'metadata', 'quarter'],
        key: 'quarter',
        defaultSortOrder: 'descend',
        sorter: (a, b) =>
          moment(a.attributes.metadata.quarter).isAfter(
            moment(b.attributes.metadata.quarter)
          )
      },
      {
        title: 'Download',
        key: 'download',
        render: (text, record) => <a href={record.attributes.url}>Download</a>
      }
    ];

    return (
      <div>
        <Breadcrumb tag="nav" style={{ padding: '20px 0' }}>
          <Breadcrumb.Item>
            <Link to="/dashboard">Hippo Admin</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Evaluation Reports</Breadcrumb.Item>
        </Breadcrumb>
        <hr />
        <Table
          style={{ backgroundColor: '#fff' }}
          dataSource={this.state.reports}
          columns={tableColumns}
          pagination={false}
        />
      </div>
    );
  }
}
