import React from 'react';
import { Route } from 'react-router-dom';

import Order from '../containers/Order';
import OrderSearch from '../containers/OrderSearch';
import OrderCreate from '../containers/OrderCreate';

const CourseRoutes = () => {
  return (
    <>
      <Route path="/orders/:id" component={Order} />
      <Route
        exact={true}
        strict={true}
        path="/order/search"
        component={OrderSearch}
      />
      <Route
        exact={true}
        strict={true}
        path="/order/create"
        component={OrderCreate}
      />
    </>
  );
};

export default CourseRoutes;
