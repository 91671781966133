import PropTypes from 'prop-types';
import React from 'react';
import AWSUploadInput from './AWSUploadInput';

const AttachmentUpload = ({
  filename,
  onComplete,
  hideReplace,
  type,
  canEdit
}) => {
  if (!canEdit) {
    return null;
  }
  // NAND     because 1) do not show awsuploadInput if we have a file && hideReplace is true and
  // 2) show input regardless if hidereplace is false
  const hasFilename = !!filename;
  const showUploadInput = !(hasFilename && hideReplace);
  return (
    <>
      {filename && (
        <div className="attachment-upload__filename">{filename}</div>
      )}
      {showUploadInput && (
        <AWSUploadInput
          name="attachment_file"
          path="attachments"
          type={type}
          text={filename ? 'Replace File…' : 'Upload File…'}
          onComplete={onComplete}
        />
      )}
    </>
  );
};

AttachmentUpload.propTypes = {
  filename: PropTypes.string,
  onComplete: PropTypes.func.isRequired,
  type: PropTypes.string,
  canEdit: PropTypes.bool,
  hideReplace: PropTypes.bool
};

AttachmentUpload.defaultProps = {
  canEdit: true
};

export default AttachmentUpload;
