import React from 'react';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import { Alert, Button, Breadcrumb } from 'antd';

import moment from 'moment';

import { getTokenFromCookie } from '../utils/cookie';

export default class Order extends React.Component {
  state = {
    loading: false,
    order: null,
    transactions: [],
    orderItems: []
  };
  componentDidMount() {
    this.setState({ loading: true });

    fetch(
      process.env.REACT_APP_JSONAPI_SERVER +
        '/api/order/' +
        this.props.match.params.id,
      {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          Authorization: 'Bearer ' + getTokenFromCookie()
        }
      }
    )
      .then(response => {
        const responseCode = response.status;

        response.json().then(response => {
          if (responseCode >= 400) {
            this.setState({ loading: false });
            window.setError(response);
          } else {
            this.setState({
              order: response.order,
              transactions: response.transactions,
              orderItems: response.orderItems,
              loading: false
            });
          }
        });
      })
      .catch(err => {
        this.setState({ loading: false });
        window.setError(err);
      });
  }

  render() {
    return (
      <div>
        <Row>
          <Col>
            <Breadcrumb style={{ padding: '20px 0' }}>
              <Breadcrumb.Item>
                <Link to="/dashboard">Hippo Admin</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/orders">Order Search</Link>
              </Breadcrumb.Item>
              {this.state.order ? (
                <Breadcrumb.Item
                  tag="a"
                  href={'/orders/' + this.state.order.id}
                  active="true"
                >
                  {this.state.order.Account.firstName +
                    ' ' +
                    this.state.order.Account.lastName}
                </Breadcrumb.Item>
              ) : null}
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          {this.state.loading ? (
            <Col>
              <p>Loading...</p>
            </Col>
          ) : null}
          {this.state.order ? (
            <Col>
              <div className="flex-space-between-center">
                <h3>
                  {this.state.order.Account.firstName +
                    ' ' +
                    this.state.order.Account.lastName +
                    ': ' +
                    moment(this.state.order.createdAt).format(
                      'MM-DD-YYYY hh:mm a'
                    )}
                </h3>
              </div>
            </Col>
          ) : null}
          {this.state.successMessage ? (
            <Alert type="success" message={this.state.successMessage} />
          ) : null}
        </Row>
        <Row>
          {this.state.order && this.state.orderItems ? (
            <Col>
              <table>
                <tbody>
                  <tr>
                    <td className="order-table-left-column">
                      <strong>Order placed</strong>
                    </td>
                    <td>
                      {moment(this.state.order.createdAt).format(
                        'MMMM Do YYYY, h:mm:ss a'
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="order-table-left-column">
                      <strong>Account</strong>
                    </td>
                    <td>
                      {this.state.order.Account.firstName}{' '}
                      {this.state.order.Account.lastName}
                    </td>
                  </tr>
                  <tr>
                    <td className="order-table-left-column">
                      <strong>Email</strong>
                    </td>
                    <td>{this.state.order.Account.email}</td>
                  </tr>
                  <tr>
                    <td className="order-table-left-column">
                      <strong>AAPA Member ID</strong>
                    </td>
                    <td>
                      {this.state.order.Account.aapaMemberId || '<none listed>'}
                    </td>
                  </tr>
                  <tr>
                    <td className="order-table-left-column">
                      <strong>Employer</strong>
                    </td>
                    <td>
                      {this.state.order.Account.institution || '<none listed>'}
                    </td>
                  </tr>
                  {this.state.transactions.length > 0 ? (
                    <tr>
                      <td className="order-table-left-column">
                        <strong>Payment</strong>
                      </td>
                      <td>
                        {this.state.transactions[0].creditCard.last4 ? (
                          this.state.transactions[0].creditCard.maskedNumber
                        ) : (
                          <div>
                            <span className="order-table-paypal">
                              PayPal Account:
                            </span>
                            {` ${this.state.transactions[0].paypal.payerEmail}`}
                          </div>
                        )}
                      </td>
                    </tr>
                  ) : null}
                  {this.state.transactions.length > 0 &&
                  this.state.transactions[0].creditCard.last4 ? (
                    <tr>
                      <td className="order-table-left-column" />
                      <td>
                        Exp:{' '}
                        {this.state.transactions[0].creditCard.expirationDate}{' '}
                        {this.state.transactions[0].creditCard.cardholderName}
                      </td>
                    </tr>
                  ) : null}
                  {this.state.transactions.length > 0 ? (
                    <tr>
                      <td className="order-table-left-column">
                        <strong>Billing Address</strong>
                      </td>
                      {this.state.transactions[0].billing.id ? (
                        <td>
                          {this.state.transactions[0].billing.firstName}{' '}
                          {this.state.transactions[0].billing.lastName},{' '}
                          {this.state.transactions[0].billing.streetAddress}
                        </td>
                      ) : (
                        <td>&lt;none listed&gt;</td>
                      )}
                    </tr>
                  ) : null}
                  {this.state.transactions.length > 0 ? (
                    <tr>
                      <td className="order-table-left-column" />
                      <td>
                        {this.state.transactions[0].billing.locality}{' '}
                        {this.state.transactions[0].billing.region}{' '}
                        {this.state.transactions[0].billing.postalCode}{' '}
                        {this.state.transactions[0].billing.countryCodeAlpha2}
                      </td>
                    </tr>
                  ) : null}
                  {!this.state.transactions.length > 0 ? (
                    <tr>
                      <td className="order-table-left-column">
                        <strong>Payment</strong>
                      </td>
                      {this.state.order.isPrepaid ? (
                        <td>
                          This order was prepaid in the amount shown below.
                        </td>
                      ) : (
                        <td>
                          This order does not have a transaction associated with
                          it.
                        </td>
                      )}
                    </tr>
                  ) : null}
                  <tr>
                    <td className="order-table-left-column">
                      <strong>Admin comments</strong>
                    </td>
                    <td>
                      <textarea
                        style={{ width: 320 }}
                        defaultValue={this.state.order.adminComments || ''}
                        onChange={e => {
                          const order = this.state.order;
                          order.adminComments = e.target.value;
                          this.setState({ order });
                        }}
                      />
                      <br />
                      <Button
                        type="link"
                        className="button__link"
                        onClick={async () => {
                          await fetch(
                            process.env.REACT_APP_JSONAPI_SERVER +
                              '/api/order/' +
                              this.props.match.params.id,
                            {
                              method: 'POST',
                              credentials: 'same-origin',
                              headers: {
                                Authorization: 'Bearer ' + getTokenFromCookie(),
                                'Content-Type': 'application/json'
                              },
                              body: JSON.stringify({
                                adminComments: this.state.order.adminComments
                              })
                            }
                          );

                          this.setState({
                            successMessage:
                              'Successfully updated admin comment.'
                          });
                          window.setTimeout(
                            () => this.setState({ successMessage: null }),
                            2000
                          );
                        }}
                      >
                        save
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          ) : null}
        </Row>
        <hr />
        {!this.state.loading && this.state.orderItems.length === 0 ? (
          <p>No order items found.</p>
        ) : null}
        {this.state.orderItems.length > 0 ? (
          <Row>
            <Col>
              <h3>Courses</h3>
            </Col>
          </Row>
        ) : null}
        {this.state.orderItems.length > 0 ? (
          <Row>
            <Col>
              <table style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th />
                    <th>PRICE</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.orderItems.map((orderItem, index) => {
                    const list =
                      orderItem.Item && orderItem.Item.description
                        ? JSON.parse(orderItem.Item.description)
                        : [];

                    return (
                      <tr index={index} key={index}>
                        <td>
                          <div>
                            <strong>Title:</strong> {orderItem.productTitle}
                          </div>
                          <div>
                            <strong>Details:</strong>
                          </div>
                          <ul>
                            {list.map((listItem, index) => {
                              return <li key={index}>{listItem}</li>;
                            })}
                          </ul>
                        </td>
                        <td>
                          $
                          {parseFloat(
                            Math.round(orderItem.price * 100) / 100
                          ).toFixed(2)}
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td
                      style={{ textAlign: 'right', padding: 12, fontSize: 10 }}
                    >
                      TOTAL
                    </td>
                    <td>
                      $
                      {parseFloat(
                        Math.round(this.state.order.total * 100) / 100
                      ).toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        ) : null}
      </div>
    );
  }
}
