import React, { useState } from 'react';
import { Label } from 'reactstrap';
import { notify } from 'react-notify-toast';
import getSlug from '../../utils/getSlug';
import moment from 'moment';
import { Space, Select } from 'antd';
import EditableLabel from '../../components/EditableLabel/EditableLabel';
import MessageLoader from '../../components/MessageLoader';

import * as API from '../../API';

import './Group.css';

export default function GroupDetails({ group, updateGroup, accountManagers }) {
  const [shortnameErrorMessage, setShortnameErrorMessage] = useState(null);
  const [loadingDetails, setLoadingDetails] = useState(null);
  const handleShortnameError = shouldShowShortnameError => {
    if (shouldShowShortnameError) {
      setShortnameErrorMessage(
        'Error updating shortname - make sure shortname is unique to this group'
      );
      return true;
    } else {
      setShortnameErrorMessage(null);
      return false;
    }
  };

  const editGroup = async (field, value) => {
    try {
      setLoadingDetails(true);
      const groupToEdit = group;

      if (field === 'programManager') {
        await API.program.update({
          id: groupToEdit.id,
          relationships: {
            manager: {
              data: {
                type: 'account',
                id: value
              }
            }
          }
        });
      } else if (field === 'shortname') {
        const slugifiedValue = getSlug('', value, []);
        let response;
        let shouldResetShortname;
        try {
          response = await API.program.update({
            id: groupToEdit.id,
            attributes: { shortname: slugifiedValue }
          });

          shouldResetShortname = handleShortnameError(
            response.data.attributes.shortname !== slugifiedValue
          );
        } catch (e) {
          shouldResetShortname = handleShortnameError(true);
        }

        groupToEdit.attributes.shortname = shouldResetShortname
          ? groupToEdit.attributes.shortname
          : response.data.attributes.shortname;
      } else {
        groupToEdit.attributes[field] = value;
        await API.program.update({
          id: groupToEdit.id,
          attributes: groupToEdit.attributes
        });
      }

      updateGroup(groupToEdit);
    } catch (error) {
      if (error.data) {
        notify.show(error.data.errors[0].detail[0].message, 'error');
      } else {
        throw error;
      }
    } finally {
      setLoadingDetails(false);
    }
  };
  return (
    <div className="group__form-item group__details">
      <EditableLabel
        className="group__details__name"
        defaultValue={group.attributes.name}
        onChange={value => editGroup('name', value)}
      />

      <div>
        <Label>Hippo Account Manager:</Label>
        <Select
          placeholder="None"
          className="group__details__manager"
          defaultValue={
            group.relationships?.manager?.data?.id
              ? group.relationships.manager.data.id
              : null
          }
          onSelect={value => editGroup('programManager', value)}
        >
          {accountManagers.map(account => (
            <Select.Option key={account.id} value={account.id}>
              {account.firstName}
            </Select.Option>
          ))}
        </Select>
      </div>

      {loadingDetails ? (
        <MessageLoader />
      ) : (
        <>
          <div className="group__details__item">
            <Space>
              <Label>Created:</Label>
              <Label>{`${moment(group.attributes.createdAt).format(
                'MM/DD/YY'
              )}`}</Label>
            </Space>
          </div>
          <div className="group__details__shortname">
            <Space>
              <Label>Shortname:</Label>
              <EditableLabel
                defaultValue={group.attributes.shortname}
                onChange={value => editGroup('shortname', value)}
              />
              {shortnameErrorMessage && (
                <div className="group__short-name-error">
                  {shortnameErrorMessage}
                </div>
              )}
            </Space>
          </div>
        </>
      )}
    </div>
  );
}
