import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import './BreadcrumbNavigation.css';

const BreadcrumbNavigation = ({ data, backTo }) => (
  <div className="breadcrumb-navigation">
    {data && data.length > 0 && (
      <Breadcrumb tag="nav" className="breadcrumb-navigation__container">
        {data
          .filter(item => !!item)
          .map((item, index) => (
            <Breadcrumb.Item
              key={index}
              onClick={e => item.onClick && item.onClick(e)}
            >
              {item.to ? <Link to={item.to}>{item.title}</Link> : item.title}
            </Breadcrumb.Item>
          ))}
      </Breadcrumb>
    )}
    {backTo && (
      <div>
        <Link style={{ color: '#1890ff' }} to={backTo.to}>
          {backTo.title}
        </Link>
      </div>
    )}
  </div>
);

BreadcrumbNavigation.propTypes = {
  data: PropTypes.array
};

export default BreadcrumbNavigation;
