import React, { useState } from 'react';
import { Button, Modal, Checkbox, List } from 'antd';
import _ from 'lodash';

const EditDefaultItemsModal = ({ visible, onCancel, onConfirm, items }) => {
  const [pendingItems, setPendingItems] = useState(_.cloneDeep(items));
  const [changedItems, setChangedItems] = useState([]);

  const handleCheckboxChange = id => {
    const newChangedItems = [...changedItems];
    const foundItem = pendingItems
      // have to find the vertical with the item in it first,
      // then retrieve the item out of that box
      .find(vertical => {
        return vertical.items.find(item => item.id === id);
      })
      .items.find(item => item.id === id);

    foundItem.isSelected = !foundItem.isSelected;

    const changedItemIndex = newChangedItems.findIndex(item => item.id === id);
    if (changedItemIndex === -1) {
      newChangedItems.push(foundItem);
    }
    setChangedItems(newChangedItems);
    setPendingItems(pendingItems);
  };

  return (
    <Modal
      width="90vw"
      title="Default Items"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="confirm"
          type="primary"
          disabled={(() => {
            const verticalsWithSelectedChildren = pendingItems.filter(
              vertical => {
                return (
                  vertical.items.filter(item => item.isSelected).length > 0
                );
              }
            );
            return verticalsWithSelectedChildren.length === 0;
          })()}
          onClick={() => onConfirm(pendingItems, changedItems)}
        >
          Confirm Selection
        </Button>
      ]}
    >
      {pendingItems.map(vertical => {
        return (
          <div
            key={vertical.vertical}
            style={{
              display: 'inline-grid',
              width: '30%',
              marginLeft: '2rem'
            }}
          >
            <h4>
              <b>{vertical.vertical}</b>
            </h4>
            <List
              dataSource={vertical.items}
              renderItem={item => {
                return (
                  <List.Item key={item.id}>
                    <Checkbox
                      onClick={() => {
                        handleCheckboxChange(item.id);
                      }}
                      value={item.id}
                      checked={item.isSelected}
                    >
                      {item.title}
                    </Checkbox>
                  </List.Item>
                );
              }}
            />
          </div>
        );
      })}
    </Modal>
  );
};

export default EditDefaultItemsModal;
