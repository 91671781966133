export const filterActiveItemIfProduction = item => {
  if (process.env.REACT_APP_WEB_HOST === 'https://www.hippoed.com') {
    const now = new Date();
    const startDate = item.startedAt ? new Date(item.startedAt) : null;
    const endDate = item.endedAt ? new Date(item.endedAt) : null;

    return (
      (startDate === null || startDate < now) &&
      (endDate === null || endDate > now)
    );
  } else {
    return item;
  }
};
