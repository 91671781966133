import React, { useState, useEffect } from 'react';
import { Button, Modal, Select, DatePicker, Input, Spin } from 'antd';
import { Label } from 'reactstrap';
import moment from 'moment';

import * as API from '../../../API';
import config from '../../../config';
import { getAccountsByPermissionId } from '../../../utils/rolesAndPermissions';
import { createSubscription } from '../../../utils/subscription';

import './AddSubscriptionModal.css';

export const AddGroupSubscriptionModal = ({
  programId,
  visible,
  onSelect,
  onClose
}) => {
  const [courses, setCourses] = useState([]);
  const [accountManagers, setAccountManagers] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedManager, setSelectedManager] = useState(null);
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(null);
  const [seats, setSeats] = useState(0);
  const [notes, setNotes] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const getCourses = async () => {
    // Filter courses to only group dashboard courses
    const groupEnabledCourses = await API.course.where({
      filter: {
        enableForGroups: true
      },
      options: {
        sort: 'title'
      }
    });
    setCourses(groupEnabledCourses.data);
  };

  const getAccountManagers = async () => {
    const accounts = await getAccountsByPermissionId(
      config.permissions.canManageGroups
    );
    setAccountManagers(accounts);
  };

  useEffect(() => {
    if (courses.length === 0) {
      getCourses();
    }
  });

  useEffect(() => {
    if (accountManagers.length === 0) {
      getAccountManagers();
    }
  });

  const addSubscriptions = async () => {
    if (selectedCourse && seats > 0) {
      setLoading(true);
      try {
        const relationships = {
          course: { data: { id: selectedCourse, type: 'course' } },
          program: { data: { id: programId, type: 'program' } }
        };
        if (selectedManager) {
          relationships.accountManager = {
            data: { id: selectedManager, type: 'account' }
          };
        }
        const programCourseBinding = await API.programCourseBinding.create({
          attributes: {
            notes,
            startDate,
            endDate
          },
          relationships
        });
        // Add subscriptions for each seat
        for (let i = 0; i < seats; i++) {
          await createSubscription(
            selectedCourse,
            startDate,
            endDate,
            null,
            programCourseBinding.data.id
          );
        }
        cleanupAndClose();
      } catch (e) {
        if (e.data) {
          setError(e.data.errors[0].detail[0].message, 'error');
        } else {
          throw e;
        }
      }
    } else {
      setError('You must select a course and have seats to add a subscription');
    }
  };

  const cleanupAndClose = () => {
    setSelectedCourse(null);
    setSelectedManager(null);
    setStartDate(moment().format('YYYY-MM-DD'));
    setEndDate(null);
    setSeats(0);
    setNotes('');
    setError(null);
    setLoading(false);
    onClose();
  };

  return (
    <Modal
      wrapClassName="add-subscription-modal"
      visible={visible}
      title="Add Subscription"
      onCancel={cleanupAndClose}
      footer={
        <>
          {loading ? (
            <Spin />
          ) : (
            <>
              <Button
                disabled={!selectedCourse || seats <= 0}
                onClick={async () => {
                  await addSubscriptions();
                  onSelect();
                }}
              >
                Ok
              </Button>
              <Button onClick={cleanupAndClose}>Cancel</Button>
            </>
          )}
        </>
      }
    >
      <div className="add-subscription-modal__data">
        <div className="add-subscription-modal__data-entry add-subscription-modal__data-entry-course-select">
          <Label>Course</Label>
          <Select
            placeholder="Select Course"
            onSelect={value => setSelectedCourse(value)}
          >
            {courses.map(course => (
              <Select.Option key={course.id} value={course.id}>
                {course.attributes.title}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="add-subscription-modal__data-entry">
          <Label>Start Date</Label>
          <DatePicker
            defaultValue={moment(startDate)}
            onSelect={value => setStartDate(value.format('YYYY-MM-DD'))}
          />
        </div>
        <div className="add-subscription-modal__data-entry">
          <Label>End Date</Label>
          <DatePicker
            onSelect={value => setEndDate(value.format('YYYY-MM-DD'))}
          />
        </div>
        <div className="add-subscription-modal__data-entry add-subscription-modal__seats">
          <Label>Seats</Label>
          <Input
            defaultValue={seats}
            onChange={e => setSeats(e.target.value)}
          />
        </div>
        <div className="add-subscription-modal__data-entry add-subscription-modal__data-entry-select">
          <Label>Account Mgr.</Label>
          <Select
            placeholder="Select Manager"
            onSelect={value => setSelectedManager(value)}
          >
            {accountManagers.map(account => (
              <Select.Option key={account.id} value={account.id}>
                {account.firstName}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="add-subscription-modal__data-entry add-subscription-modal__notes">
          <Label>Notes</Label>
          <Input
            defaultValue={notes}
            onChange={e => setNotes(e.target.value)}
          />
        </div>
      </div>
      {error && <div>{error}</div>}
    </Modal>
  );
};
