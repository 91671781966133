import { gql } from '@apollo/client';

export const COURSE_CONTENT_QUERY = gql`
  query courseContentQuery($courseId: UUID!) {
    course(id: $courseId) {
      id
      title
      topicGroupsList(orderBy: DISPLAY_ORDER_ASC) {
        ...TopicGroupFragment
      }
      episodesList(orderBy: ACTIVATED_AT_DESC) {
        ...EpisodeFragment
      }
      assessmentsList {
        id
        title
      }
    }
  }

  fragment TopicGroupFragment on TopicGroup {
    id
    title
    displayOrder
    topicsList(orderBy: DISPLAY_ORDER_ASC) {
      id
      title
      displayOrder
      lecturesList(orderBy: DISPLAY_ORDER_ASC, condition: { parentId: null }) {
        id
        title
        media {
          duration
        }
        lectureAttachmentBindingsList {
          attachment {
            ...AttachmentFragment
          }
        }
        lecturesByParentIdList(orderBy: DISPLAY_ORDER_ASC) {
          id
          title
          media {
            duration
          }
          lectureAttachmentBindingsList {
            attachment {
              ...AttachmentFragment
            }
          }
        }
      }
    }
  }

  fragment EpisodeFragment on Episode {
    id
    title
    activatedAt
    episodeChapterBindingsList {
      chapter {
        id
        title
        media {
          duration
        }
        chapterAttachmentBindingsList {
          attachment {
            ...AttachmentFragment
          }
        }
      }
    }
  }

  fragment AttachmentFragment on Attachment {
    id
    title
    subtitle
    fileName
    isContentSummary
    activityTimeInSeconds
  }
`;
