import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Button, Modal, Select, Form, Input, Card, Empty } from 'antd';

import * as API from '../../API';
import PaginatedList from '../PaginatedList/PaginatedList';
import { secondsToMinutesAndSeconds } from '../../utils/time';

import './ProductLinkSelector.css';

export default function ProductLinkSelector({ productLinkId, onSave }) {
  const [productType, setProductType] = useState('legacy');
  const [modalOpen, setModalOpen] = useState(false);
  const [legacyProduct, setLegacyProduct] = useState({
    legacyProductTitle: '',
    url: ''
  });
  const [chapterId, setChapterId] = useState('');
  const [productLink, setProductLink] = useState(null);

  const loadProductLink = async () => {
    const loadedProductLink = await API.productLink.find({
      id: productLinkId
    });

    setProductLink(loadedProductLink);
    if (loadedProductLink.data.attributes.legacyProductTitle) {
      setProductType('legacy');
      setLegacyProduct({
        legacyProductTitle:
          loadedProductLink.data.attributes.legacyProductTitle,
        url: loadedProductLink.data.attributes.url
      });
    } else if (loadedProductLink.data.relationships.chapter.data) {
      setProductType('chapter');
      setChapterId(loadedProductLink.data.relationships.chapter.data.id);
    }
  };

  useEffect(() => {
    if (productLinkId && !productLink) {
      loadProductLink();
    }
  });

  const saveProductLink = async () => {
    let attributes = {};
    let relationships = {};
    if (productType === 'legacy') {
      attributes = {
        ...legacyProduct
      };
    } else if (productType === 'chapter') {
      relationships = {
        chapter: {
          data: {
            type: 'chapter',
            id: chapterId
          }
        }
      };
    }
    const savedProductLink = await API.productLink.create({
      attributes,
      relationships
    });

    onSave(savedProductLink.data.id);
    setModalOpen(false);
  };

  return (
    <div className="product-link-selector">
      <Button
        onClick={() => setModalOpen(true)}
        className="product-link-selector__select-button"
      >
        Link to related internal content
      </Button>
      <Modal
        visible={modalOpen}
        onOk={() => saveProductLink()}
        onCancel={() => setModalOpen(false)}
      >
        <Select
          onChange={value => setProductType(value)}
          placeholder="Select a product type"
          defaultValue={productType}
          className="product-link-selector__select"
        >
          <Select.Option value="legacy">
            Legacy Product (old platform)
          </Select.Option>
          <Select.Option value="chapter">RAP Chapter</Select.Option>
        </Select>
        {productType === 'legacy' && (
          <div>
            <Form
              onValuesChange={value =>
                setLegacyProduct({ ...legacyProduct, ...value })
              }
              initialValues={legacyProduct}
            >
              <Form.Item
                name="legacyProductTitle"
                label="Legacy Product Title"
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <Input placeholder="Legacy Product Title" />
              </Form.Item>
              <Form.Item
                name="url"
                label="Legacy Product Url"
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <Input placeholder="Legacy Product Url" />
              </Form.Item>
            </Form>
          </div>
        )}
        {productType === 'chapter' && (
          <PaginatedList
            resource={API.chapter}
            onLoadRows={resource => API.simplifyResource(resource)}
            defaultOptions={{
              include: 'media'
            }}
            pageLimit={10}
            renderHeader={(actions, refresh) => (
              <div className="product-link-selector__header">
                <Input
                  placeholder="Search Chapters"
                  onChange={e =>
                    actions.setFilter(
                      'title',
                      e.target.value && `:${e.target.value}`
                    )
                  }
                  className="product-link-selector__search"
                />
                <Card size="small">
                  <div className="product-link-selector__header-title">
                    <div>Title</div>
                    <div>Duration</div>
                  </div>
                </Card>
              </div>
            )}
            renderListItem={(resourceItem, refresh, i) => (
              <div
                className="product-link-selector__list-item"
                key={resourceItem.id}
              >
                <Card
                  size="small"
                  key={resourceItem.id}
                  className={classNames({
                    'product-link-selector__item--selected':
                      resourceItem.id === chapterId
                  })}
                  onClick={() => setChapterId(resourceItem.id)}
                >
                  <div className="product-link-selector__item">
                    <div>{resourceItem.title}</div>
                    <div>
                      {resourceItem.media
                        ? secondsToMinutesAndSeconds(
                            resourceItem.media.duration
                          )
                        : 'No media'}
                    </div>
                  </div>
                </Card>
              </div>
            )}
            renderEmptyList={() => <Empty description="No results to show" />}
          />
        )}
      </Modal>
    </div>
  );
}
