import React from 'react';
import { Form, Input, Select, Checkbox } from 'antd';

export default function SubscriptionTemplateFields({
  isNewItem,
  activeItem,
  handleUpdate
}) {
  return (
    <div className="item__field-sections">
      <Form.Item
        style={{ backgroundColor: isNewItem ? '#EFFD5F' : 'none' }}
        label="Subscription Title"
        name="subscriptionTemplateTitle"
        extra="How the Subscription will appear for a user after purchase"
      >
        <Input onChange={e => handleUpdate(e.target.value, 'title')} />
      </Form.Item>
      <Form.Item
        style={{ backgroundColor: isNewItem ? '#EFFD5F' : 'none' }}
        label="Length of Subscription"
        name="defaultSubscriptionLength"
      >
        <Select onChange={e => handleUpdate(e, 'defaultSubscriptionLength')}>
          <Select.Option value={null}>Non-expiring</Select.Option>
          <Select.Option value={1}>1 Month</Select.Option>
          <Select.Option value={3}>3 Months</Select.Option>
          <Select.Option value={12}>12 Months</Select.Option>
          <Select.Option value={24}>24 Months</Select.Option>
          <Select.Option value={36}>36 Months</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="isCmeEnabled"
        valuePropName="isCmeEnabled"
        style={{
          backgroundColor: isNewItem ? '#EFFD5F' : 'none'
        }}
      >
        <Checkbox
          onChange={e => handleUpdate(e.target.checked, 'isCmeEnabled')}
          checked={activeItem.subscriptionTemplatesList[0].isCmeEnabled}
        >
          Is CME Enabled?
        </Checkbox>
      </Form.Item>
      <Form.Item
        name="isCmeArchiveEnabled"
        valuePropName="isCmeArchiveEnabled"
        style={{
          backgroundColor: isNewItem ? '#EFFD5F' : 'none'
        }}
      >
        <Checkbox
          onChange={e => handleUpdate(e.target.checked, 'isCmeArchiveEnabled')}
          checked={activeItem.subscriptionTemplatesList[0].isCmeArchiveEnabled}
        >
          Is CME Archive Item?
        </Checkbox>
      </Form.Item>
      <Form.Item
        name="isFree"
        valuePropName="isFree"
        style={{
          backgroundColor: isNewItem ? '#EFFD5F' : 'none'
        }}
      >
        <Checkbox
          onChange={e => handleUpdate(e.target.checked, 'isFree')}
          checked={activeItem.subscriptionTemplatesList[0].isFree}
        >
          Is Free?
        </Checkbox>
      </Form.Item>
    </div>
  );
}
