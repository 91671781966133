import React, { useState, useEffect } from 'react';
import { Label } from 'reactstrap';
import SingleImageInput from '../../components/Upload/SingleImageInput';
import UPDATE_IMAGE_ID_ORGANIZATION_MUTATION from './queries/UpdateImageIdOrganizationMutation';
import { useMutation } from '@apollo/client';
import { InfoCircleFilled } from '@ant-design/icons';

export default function OrganizationLogoSection(props) {
  const MAX_WIDTH_LOGO_SIZE_PIXELS = 300;
  const MAX_HEIGHT_LOGO_SIZE_PIXELS = 300;
  const [groupLogo, setGroupLogo] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [editLogo] = useMutation(UPDATE_IMAGE_ID_ORGANIZATION_MUTATION, {
    onCompleted: async (d, clientOptions) => {
      await props.refreshParent();
    }
  });

  const updateOrganizationImage = async image => {
    const imageId = image ? image.id : null;
    if (image) {
      setGroupLogo({
        id: imageId,
        attributes: { url: image.attributes.url }
      });
    } else {
      setGroupLogo(null);
    }
    await editLogo({ variables: { id: props.id, imageId } });
  };

  useEffect(() => {
    if (groupLogo == null) {
      if (props.logoURL !== '') {
        setGroupLogo({
          id: props.imageId,
          attributes: { url: props.logoURL }
        });
        setInitialized(true);
      }
    } else if (props.logoURL === '') {
      setGroupLogo(null);
    }
  }, [groupLogo, initialized, props.imageId, props.logoURL]);

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className="organization__logo-selection">
          <Label>Organization Logo</Label>
          <div className="organization__logo">
            <SingleImageInput
              name="logo"
              value={groupLogo}
              onChange={image => {
                updateOrganizationImage(image);
              }}
              abort={() => {
                updateOrganizationImage(null);
              }}
              customAccept="image/png, image/jpg, image/jpeg"
              maxFileSizeBytes={1 * 1024 * 1024} // 1 MB
              maxWidth={MAX_WIDTH_LOGO_SIZE_PIXELS}
              maxHeight={MAX_HEIGHT_LOGO_SIZE_PIXELS}
              path={`organization/${props.id}`}
            />
          </div>
        </div>
        <span style={{ marginLeft: '20%' }}>
          <div>
            <span>
              <span style={{ marginRight: '1rem' }}>
                <InfoCircleFilled />
              </span>
              <div style={{ display: 'inline', float: 'right' }}>
                <h6>Logo uploading specs</h6>
                <p>Image type: PNG, JPG</p>
                <p>File size can't be larger than 1MB</p>
                <p>
                  Maximum dimensions: {MAX_WIDTH_LOGO_SIZE_PIXELS} x{' '}
                  {MAX_HEIGHT_LOGO_SIZE_PIXELS} pixels
                </p>
              </div>
            </span>
          </div>
        </span>
      </div>
    </>
  );
}
