import React from 'react';
import { Button } from 'antd';

export default class CommentsActionButton extends React.Component {
  render() {
    return (
      <Button
        className="actionButton"
        // antd doesn't play well with css at times, and their documentation says to style via their API like this
        style={{
          background: '#3386F9',
          color: '#FFFFFF',
          borderRadius: '2px',
          width: '65px',
          alignItems: 'center',
          margin: 'auto',
          padding: '0px'
        }}
        onClick={async () => {
          await this.props.handleActionButtonClick(
            this.props.comment,
            this.props.action
          );
        }}
      >
        {this.props.action}
      </Button>
    );
  }
}
