import React from 'react';
import {
  Alert,
  Button,
  Col,
  FormGroup,
  Label,
  Input,
  Table,
  Row
} from 'reactstrap';
import { notify } from 'react-notify-toast';
import { emailExists, validateEmail } from '../../utils/email';

import { getTokenFromCookie } from '../../utils/cookie';

export default class Step1 extends React.Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    error: '',
    useExisting: true,
    selectedAccount: null,
    accountSearchResults: []
  };

  async createAccountAndContinue() {
    try {
      if (!this.state.email || !this.state.firstName || !this.state.lastName) {
        throw new Error('You must fill out the form to create an account.');
      }

      const isEmailExist = await emailExists(this.state.email);
      const isEmailValid = await validateEmail(this.state.email);

      if (isEmailExist) {
        throw new Error('An account with this email address already exists.');
      } else if (!isEmailValid) {
        throw new Error('Please enter a valid email');
      }

      if (
        window.confirm(
          'Create a new account with email ' + this.state.email + '?'
        )
      ) {
        const params = {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email
        };

        const response = await fetch(
          `${process.env.REACT_APP_JSONAPI_SERVER}/api/account`,
          {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${getTokenFromCookie()}`
            },
            credentials: 'same-origin'
          }
        );
        const account = await response.json();
        if (response.status >= 400) {
          notify.show(`Error. ${account.message}`, 'error');
          return null;
        }

        const forgotPasswordUrl =
          process.env.REACT_APP_JSONAPI_SERVER + '/api/account/forgot-password';

        await fetch(forgotPasswordUrl, {
          headers: {
            Accept: 'Application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email: this.state.email }),
          method: 'POST',
          credentials: 'same-origin'
        });

        notify.show(
          `Success. A reset password email has been sent to ${this.state.email}`,
          'success'
        );

        this.props.onComplete({ account });
      }
    } catch (e) {
      notify.show(`Error: ${e.message}`, 'error');
    }
  }

  async searchAccounts() {
    if (!this.state.firstName && !this.state.lastName && !this.state.email) {
      return this.setState({
        error: 'You must enter search information first.'
      });
    }

    const query = {};
    if (this.state.firstName) {
      query.firstName = this.state.firstName;
    }
    if (this.state.lastName) {
      query.lastName = this.state.lastName;
    }
    if (this.state.email) {
      query.email = this.state.email;
    }
    const url = urlWithQuery(
      `${process.env.REACT_APP_JSONAPI_SERVER}/api/account`,
      query
    );

    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromCookie()}`
      },
      credentials: 'same-origin'
    });

    const accountSearchResults = (await response.json()).accounts;
    this.setState({
      hasSearched: true,
      accountSearchResults
    });
  }

  render() {
    return (
      <div>
        <Row>
          <Col>
            {this.state.error ? (
              <Alert color="danger">{this.state.error.message}</Alert>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup tag="fieldset">
              <FormGroup check={true}>
                <Label check={true}>
                  <Input
                    checked={!this.state.useExisting}
                    onChange={() =>
                      this.setState({
                        useExisting: false,
                        accountSearchResults: []
                      })
                    }
                    type="radio"
                    name="radio1"
                  />{' '}
                  Create a new account for this order.
                </Label>
              </FormGroup>
              <FormGroup check={true}>
                <Label check={true}>
                  <Input
                    checked={this.state.useExisting}
                    onChange={() => this.setState({ useExisting: true })}
                    type="radio"
                    name="radio1"
                  />{' '}
                  Use an existing account for this order.
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs="4">
            <FormGroup>
              <Label for="exampleEmail">First name</Label>
              <Input
                type="text"
                bsSize="sm"
                onChange={e => this.setState({ firstName: e.target.value })}
                value={this.state.firstName}
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex={1}
              />
            </FormGroup>
          </Col>
          <Col xs="4">
            <FormGroup>
              <Label for="exampleEmail">Last name</Label>
              <Input
                type="text"
                bsSize="sm"
                onChange={e => this.setState({ lastName: e.target.value })}
                value={this.state.lastName}
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex={2}
              />
            </FormGroup>
          </Col>
          <Col xs="4">
            <FormGroup>
              <Label for="examplePassword">Email address</Label>
              <Input
                type="text"
                bsSize="sm"
                onChange={e =>
                  this.setState({ email: e.target.value.toLowerCase().trim() })
                }
                value={this.state.email}
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex={3}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            {this.state.useExisting ? (
              <Button color="primary" onClick={this.searchAccounts.bind(this)}>
                Search
              </Button>
            ) : (
              <Button
                color="primary"
                onClick={this.createAccountAndContinue.bind(this)}
              >
                Create
              </Button>
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: 18 }}>
          <Col>
            {this.state.accountSearchResults.length === 0 &&
            this.state.hasSearched &&
            this.state.useExisting ? (
              <p>No results to show</p>
            ) : null}
            {this.state.accountSearchResults.length > 0 ? (
              <Table bordered={true} style={{ backgroundColor: '#fff' }}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {this.state.accountSearchResults.map((account, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {account.firstName} {account.lastName}
                        </td>
                        <td>{account.email}</td>
                        <td>
                          <Button
                            color="primary"
                            onClick={() => this.props.onComplete({ account })}
                          >
                            Use
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : null}
          </Col>
        </Row>
      </div>
    );
  }
}

function urlWithQuery(url, params) {
  let newUrl = url;

  Object.keys(params).forEach((key, index) => {
    if (index === 0) {
      newUrl += `?${key}=${params[key]}`;
    } else {
      newUrl += `&${key}=${params[key]}`;
    }
  });

  return newUrl;
}
