import Cookie from 'js-cookie';
import { base64URLEncode } from './base64';

const CODE_QUERY_PARAM = 'code';

export function getParamFromLocation(location, param) {
  const { search } = location;
  const searchParams = new URLSearchParams(search);
  return searchParams.get(param);
}

export function removeParamFromLocation(location, param) {
  const { origin, pathname, search } = location;
  const searchParams = new URLSearchParams(search);
  // Remove param if it exists.
  searchParams.delete(param);
  // Rebuild query string.
  const queryString = searchParams.toString();
  const prefixedQueryString = queryString ? `?${queryString}` : queryString;
  // Rebuild full URL.
  return `${origin}${pathname}${prefixedQueryString}`;
}

export function getCodeFromLocation(location) {
  return getParamFromLocation(location, CODE_QUERY_PARAM);
}

/**
 * Attempts to exchange auth code and code verifier for a bearer token
 */
export async function fetchTokenFromServer() {
  const code = getCodeFromLocation(window.location);
  // We got a code from the URL, now hide it from the URL so we don't get ugly
  // bookmarks.
  const cleanUrl = removeParamFromLocation(window.location, CODE_QUERY_PARAM);
  window.history.replaceState(null, '', cleanUrl);
  const codeVerifier = Cookie.get('code-verifier');
  if (!code || !codeVerifier) {
    return Promise.reject();
  }
  const response = await fetch(
    `${process.env.REACT_APP_JSONAPI_SERVER}/api/account/token`,
    {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        code,
        code_verifier: codeVerifier,
        grant_type: 'authorization_code',
        code_challenge_method: 'S256'
      })
    }
  );
  const data = await response.json();
  // Make sure we got a valid token response from the provided auth code.
  if (response.status >= 200 && response.status < 400) {
    const sessionStr =
      Cookie.get(process.env.REACT_APP_SESSION_COOKIE_NAME) || '{}';

    const session = JSON.parse(sessionStr);
    session.authenticated = data;
    Cookie.set(
      process.env.REACT_APP_SESSION_COOKIE_NAME,
      JSON.stringify(session),
      {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: parseInt(process.env.REACT_APP_COOKIE_EXP_DAYS, 10)
      }
    );

    return data.token;
  }
  // If we didn't get a valid response throw an error.
  throw new Error(data.error_description);
}

export function getSignInUrl(challenge) {
  const redirectUrl = window.location.href;
  return `${process.env.REACT_APP_SIGN_IN_URL}?challenge=${encodeURIComponent(
    challenge
  )}&redirect=${encodeURIComponent(redirectUrl)}&admin=true`;
}

export function generateCodeVerifier(length = 100) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let key = '';
  for (let i = 0; i < length; i += 1) {
    const charIndex = Math.floor(Math.random() * characters.length);
    key += characters[charIndex];
  }
  return key;
}

async function getCodeChallenge(codeVerifier) {
  const crypto = window.crypto || window.msCrypto;
  const data = Uint8Array.from(
    codeVerifier.split('').map(char => char.charCodeAt(0))
  );
  const digest = await crypto.subtle.digest('SHA-256', data);
  return base64URLEncode(digest);
}

export async function redirectToSignIn() {
  const verifier = generateCodeVerifier();
  const challenge = await getCodeChallenge(verifier);
  // Set code verifier cookie so we can verify after login.
  // This cookie is not passed to the login page.
  Cookie.set('code-verifier', verifier, {
    domain: process.env.REACT_APP_COOKIE_DOMAIN
  });
  window.location.href = `${getSignInUrl(challenge)}`;
}
