// Provides common logic for CME-related tasks
import moment from 'moment';
import * as API from '../API';

import { getTokenFromCookie } from './cookie';

function getCurrentParamString() {
  const now = moment.utc().format();
  return `filter[activatedAt]=<${now}&filter[expiredAt]=>${now}`;
}

export async function getDisclosures(course, currentOnly = false) {
  if (course.courseType === 'video') {
    return [];
  }

  let params = `sort=-activatedAt&filter[course]=${course.id}`;
  if (currentOnly) {
    params += `&${getCurrentParamString()}`;
  }

  const response = await fetch(
    `${process.env.REACT_APP_JSONAPI_SERVER}/cmeDisclosure?${params}`,
    {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${getTokenFromCookie()}`
      }
    }
  );
  const json = await response.json();
  return json.data;
}

export function isActive(obj) {
  const now = moment.utc().format();
  if (obj.attributes) {
    return (
      (!obj.attributes.activatedAt ||
        moment(obj.attributes.activatedAt).isBefore(now)) &&
      (!obj.attributes.expiredAt ||
        moment(obj.attributes.expiredAt).isAfter(now))
    );
  } else {
    return (
      (!obj.activatedAt || moment(obj.activatedAt).isBefore(now)) &&
      (!obj.expiredAt || moment(obj.expiredAt).isAfter(now))
    );
  }
}

export async function getCmeBuckets(course, currentOnly = false) {
  if (course.courseType === 'video') {
    return [];
  }

  let params = `sort=-activatedAt&include=cmeAccreditor&filter[course]=${course.id}`;
  if (currentOnly) {
    params += `&${getCurrentParamString()}`;
  }

  const response = await fetch(
    `${process.env.REACT_APP_JSONAPI_SERVER}/cmeBucket?${params}`,
    {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${getTokenFromCookie()}`
      }
    }
  );
  const json = await response.json();

  // Attach related accreditors to each bucket
  return json.data.map(bucket => {
    const fixedBucket = bucket;
    fixedBucket.relationships.cmeAccreditor = json.included.reduce(
      (finalValue, included) => {
        if (
          included.type === 'cmeAccreditor' &&
          included.id === bucket.relationships.cmeAccreditor.data.id
        ) {
          return included;
        }
        return finalValue;
      },
      null
    );
    return fixedBucket;
  });
}

export async function getCertificates(currentOnly = false) {
  const options = {
    sort: `-activatedAt${currentOnly ? '&' + getCurrentParamString() : ''}`
  };
  const allCertificates = await API.fetchAllPages(
    API.certificate.all({ options })
  );

  return allCertificates;
}

export async function getAccreditors() {
  const options = {
    sort: 'name'
  };
  const allAccreditors = await API.fetchAllPages(
    API.cmeAccreditor.all({ options })
  );

  return allAccreditors;
}
