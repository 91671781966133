import React, { useState, useEffect } from 'react';
import { Select, Space, Tooltip, Typography } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { notify } from 'react-notify-toast';

import * as API from '../../API';

import './TopicTags.css';

const { Option } = Select;
const { Text } = Typography;

export default function TopicTags({ topicId, courseId }) {
  const [primaryTags, setPrimaryTags] = useState([]);
  const [secondaryTags, setSecondaryTags] = useState([]);
  const [selectedPrimaryTag, setSelectedPrimaryTag] = useState(null);
  const [selectedSecondaryTags, setSelectedSecondaryTags] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const allTags = await API.fetchAllPages(API.tag.all());
      const tagTypeBindings = await API.fetchAllPages(
        API.courseTagTypeBinding.where({
          filter: {
            course: courseId
          }
        })
      );

      // Only show tags defined for that specific course
      const primaryTagTypeBindingId = tagTypeBindings.find(
        binding => binding.attributes.isPrimary
      )?.relationships.tagType.data.id;

      const tagTypeIds = tagTypeBindings.map(
        binding => binding.relationships.tagType.data.id
      );

      if (primaryTagTypeBindingId) {
        setPrimaryTags(
          allTags
            .filter(
              tag =>
                tag.relationships.tagType?.data?.id === primaryTagTypeBindingId
            )
            .sort((a, b) =>
              a.attributes.title.toLowerCase() <
              b.attributes.title.toLowerCase()
                ? -1
                : 1
            )
        );
      }
      setSecondaryTags(
        allTags.filter(tag =>
          tagTypeIds.includes(tag.relationships.tagType?.data?.id)
        )
      );
    }
    fetchData();
  }, [courseId]);

  useEffect(() => {
    async function fetchData() {
      const topicTagBindings = await API.fetchAllPages(
        API.topicTagBinding.where({
          filter: {
            topic: topicId
          }
        })
      );
      const primaryTag = topicTagBindings
        ? topicTagBindings.find(binding => binding.attributes.isPrimary)
            ?.relationships.tag.data.id
        : 'Select a tag';
      const secondaryTags = topicTagBindings
        ? topicTagBindings
            .filter(binding => !binding.attributes.isPrimary)
            .map(binding => binding.relationships.tag.data.id)
        : [];
      setSelectedPrimaryTag(primaryTag);
      setSelectedSecondaryTags(secondaryTags);
    }
    fetchData();
  }, [topicId]);

  const handlePrimaryTagSelect = async tag => {
    // Another primary tag exists
    if (selectedPrimaryTag) {
      await deleteBinding(selectedPrimaryTag);
      setSelectedSecondaryTags(
        selectedSecondaryTags.filter(secondaryTag => secondaryTag === tag)
      );
    }
    // Tag is already a secondary tag, make it primary
    if (selectedSecondaryTags.includes(tag)) {
      await deleteBinding(tag);
    }
    await createBinding(true, tag);
    setSelectedPrimaryTag(tag);
  };

  const handleSecondaryTagChange = async tags => {
    const tagToRemove = selectedSecondaryTags.filter(
      tag => !tags.includes(tag)
    );
    const tagToAdd = tags.filter(tag => !selectedSecondaryTags.includes(tag));
    if (tagToRemove.length > 0) {
      await deleteBinding(tagToRemove[0]);
    }
    if (tagToAdd.length > 0) {
      await createBinding(false, tagToAdd[0]);
    }
    setSelectedSecondaryTags(tags);
  };

  const deleteBinding = async tagId => {
    const binding = API.simplifyResource(
      await API.topicTagBinding.where({
        filter: {
          topic: topicId,
          tag: tagId
        }
      })
    );
    if (binding.length > 0) {
      await API.topicTagBinding.delete({
        id: binding[0].id
      });
    }
  };

  const createBinding = async (isPrimary, tagId) => {
    try {
      const bindingToCreate = {
        attributes: { isPrimary },
        relationships: {
          tag: {
            data: {
              type: 'tag',
              id: tagId
            }
          },
          topic: {
            data: {
              type: 'topic',
              id: topicId
            }
          }
        }
      };
      await API.topicTagBinding.create(bindingToCreate);
    } catch (error) {
      if (error.data) {
        notify.show(error.data.errors[0].detail[0].message, 'error');
      } else {
        throw error;
      }
    }
  };

  if (!secondaryTags.length && !primaryTags.length) {
    return null;
  }

  return (
    <Space direction="vertical">
      <Space>
        <Text strong={true}>Primary tag</Text>
        <Tooltip title="Select one primary tag for this topic. This tag will be the topic (with illustration) that displays to the user">
          <InfoCircleFilled className="topic__primary-tag-system-label-icon" />
        </Tooltip>
      </Space>
      <Select
        defaultValue={selectedPrimaryTag}
        onChange={tag => handlePrimaryTagSelect(tag)}
        className="topic__tag-select"
      >
        {primaryTags.map(tag => (
          <Select.Option key={tag.id}>{tag.attributes.title}</Select.Option>
        ))}
      </Select>
      <Text strong={true}>Secondary tags</Text>
      <Select
        defaultValue={selectedSecondaryTags}
        optionFilterProp="title"
        onChange={tag => handleSecondaryTagChange(tag)}
        mode="multiple"
        placeholder="Select additional tags"
        className="topic__tag-select"
      >
        {secondaryTags.map(tag => (
          <Option
            key={tag.id}
            title={tag.attributes.title}
            disabled={selectedPrimaryTag && selectedPrimaryTag === tag.id}
          >
            {tag.attributes.title}
          </Option>
        ))}
      </Select>
    </Space>
  );
}
