import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Empty, Input, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import CourseSelector from '../course/CourseSelector';
import BreadcrumbConstants from '../../routes/BreadcrumbConstants';
import PaginatedList from '../../components/PaginatedList/PaginatedList';
import ListHeaderMenu from '../../components/PaginatedList/ListHeaderMenu';
import AssessmentsTableView from './AssessmentsTableView';
import ContentHeader from '../content/ContentHeader';

import getSlug from '../../utils/getSlug';
import * as API from '../../API';
import { CourseTypeTitles } from '../../constants';
import { useGetDisplayableCourseIdsByCourseType } from '../courseTypes/hooks/useGetDisplayableCourseIdsByCourseType';

import './AssessmentsView.css';

export default function AssessmentsView() {
  const history = useHistory();
  const [courseId, setCourseId] = useState(null);
  const [courseFilter, setCourseFilter] = useState(null);

  const assessmentsBreadcrumb = [
    BreadcrumbConstants.HippoAdmin,
    BreadcrumbConstants.Content,
    BreadcrumbConstants.Assessments
  ];

  const sortOptions = {
    '-releaseDate': 'Date New-Old',
    releaseDate: 'Date Old-New'
  };

  const assessmentCourseTypeTitlesToDisplay = [
    CourseTypeTitles.BOOTCAMP_ASSESSMENT,
    CourseTypeTitles.ASSESSMENT
  ];
  const {
    courseIds: assessmentCourseIds,
    loading
  } = useGetDisplayableCourseIdsByCourseType(
    assessmentCourseTypeTitlesToDisplay
  );

  useEffect(() => {
    if (courseId) {
      setCourseFilter({ course: courseId });
    }
  }, [courseId]);

  const resourceOptions = {
    sort: '-releaseDate',
    include: 'assessmentQuestionBindings,course'
  };

  const createAssessment = async () => {
    const assessments = await API.fetchAllPages(API.assessment.all());
    const assessment = await API.assessment.create({
      attributes: {
        title: 'New assessment',
        shortname: getSlug(1, 'New assessment', assessments, 'shortname'),
        currentVersion: 1
      },
      relationships: {
        course: {
          data: {
            type: 'course',
            id: courseId
          }
        }
      }
    });
    history.push(`/content/assessments/${assessment.data.id}`);
  };

  const keepLatestVersion = assessments => {
    return assessments.map(assessment => {
      assessment.assessmentQuestionBindings = assessment.assessmentQuestionBindings.filter(
        binding => binding.assessmentVersion === assessment.currentVersion
      );
      return assessment;
    });
  };

  return (
    <div>
      <ContentHeader
        breadCrumb={assessmentsBreadcrumb}
        hasTitle={true}
        title="Assessments"
      />
      <PaginatedList
        resource={API.assessment}
        defaultOptions={resourceOptions}
        onLoadRows={resource => {
          const assessments = API.simplifyResource(resource);
          return keepLatestVersion(assessments);
        }}
        pageLimit={20}
        defaultFilter={courseFilter}
        renderHeader={(actions, refresh) => (
          <>
            <div className="assessments-menu-bar">
              <Input
                placeholder="Search assessments"
                onChange={e =>
                  actions.setFilter(
                    'title',
                    e.target.value && `:${e.target.value}`
                  )
                }
              />
              <div className="assessments-menu-bar__right">
                {assessmentCourseIds.length > 0 && !loading ? (
                  <CourseSelector
                    onCourseSelected={value => setCourseId(value)}
                    courseIdList={assessmentCourseIds}
                  />
                ) : (
                  <Spin />
                )}

                <ListHeaderMenu
                  className="assessments-menu-bar__sort"
                  buttonPrefix="Sort: "
                  options={sortOptions}
                  onChange={newValue => actions.setOption('sort', newValue)}
                />
              </div>
            </div>
            <div className="assessments-list__header">
              <Button
                icon={<PlusOutlined />}
                type="primary"
                onClick={() => {
                  createAssessment();
                }}
              >
                Create an assessment
              </Button>
            </div>
          </>
        )}
        renderList={(data, refresh) => (
          <AssessmentsTableView assessments={data} refresh={refresh} />
        )}
        renderEmptyList={() => <Empty description="No assessements to show" />}
      />
    </div>
  );
}
