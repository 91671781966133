import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Input, message } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import ADD_REPLY from './commentsGQLQueries/CommentsADD_REPLY';

const { TextArea } = Input;

export default function CommentsReplySection(props) {
  const { commentAsList } = props;

  const [commentAsValue, setCommentAsValue] = useState(
    props.currentUserAccount
  );
  const [bodyTextValue, setBodyTextValue] = useState('');

  const handleCancelButtonClick = () => {
    props.handleActionButtonClick(props.comment, 'cancel');
  };

  const handleBodyTextChange = event => {
    setBodyTextValue(event.target.value);
  };

  const handleCommentAsChange = event => {
    setCommentAsValue(event.target.value);
  };
  const [addReply] = useMutation(ADD_REPLY, {
    onCompleted: (data, clientOptions) => {
      props.handleActionButtonClick(props.comment, 'submit');
    }
  });

  const handleSubmitButtonClick = async => {
    const parentRecord = props.comment;
    addReply({
      variables: {
        id: uuidv4(),
        accountId: commentAsValue,
        lectureId: parentRecord.lectureId ? parentRecord.lectureId : null,
        chapterId: parentRecord.chapterId ? parentRecord.chapterId : null,
        bodyText: bodyTextValue,
        createdAt: new Date(),
        updatedAt: new Date(),
        status: 'PENDING',
        parentId: parentRecord.key,
        facultyAccountId: props.currentUserAccount
      }
    });

    if (addReply) {
      message.info('comment posted. refreshing data.', 2);
    } else {
      message.error('Error while posting comment', 2);
    }
  };

  return (
    <>
      <TextArea
        placeholder="Type your reply here"
        rows={4}
        value={bodyTextValue}
        onChange={handleBodyTextChange}
      />
      {commentAsList && commentAsList.length > 0 && (
        <label className="comments-comment-as-label">
          Comment As
          <select
            className="comments-comment-as-select"
            value={commentAsValue}
            onChange={handleCommentAsChange}
          >
            {commentAsList.map((val, ind) => {
              return (
                <option
                  key={val.commentAsFaculty.account.id}
                  value={val.commentAsFaculty.account.id}
                >
                  {val.commentAsFaculty.account.commentingAlias}
                </option>
              );
            })}
            <option value={props.currentUserAccount}>
              {commentAsList[0].faculty.fullName}
            </option>
          </select>
        </label>
      )}
      <span className="fr">
        <Button
          className="cancel-button"
          style={{
            borderRadius: '2px',
            width: '65px',
            alignItems: 'center',
            margin: '5px',
            padding: '0px'
          }}
          onClick={handleCancelButtonClick}
        >
          Cancel
        </Button>
        <Button
          className="actionButton"
          // antd doesn't play well with css at times, and their documentation says to style via their API like this
          style={{
            background: bodyTextValue.length === 0 ? '#83D6FF' : '#3386F9',
            color: '#FFFFFF',
            borderRadius: '2px',
            width: '65px',
            alignItems: 'center',
            margin: '5px',
            padding: '0px'
          }}
          disabled={bodyTextValue.length === 0}
          onClick={handleSubmitButtonClick}
        >
          Submit
        </Button>
      </span>
    </>
  );
}
