import React from 'react';
import PropTypes from 'prop-types';
import { FileImageOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import moment from 'moment';

import './MediaItem.css';

export default function MediaItem({ disabled, media, onSelect }) {
  return (
    <div className="media-item">
      <div className="media-item__content">
        {media.posterFrame ? (
          <img
            className="media-item__poster-frame"
            src={media.posterFrame}
            alt=""
          />
        ) : (
          <FileImageOutlined
            className="media-item__poster-frame"
            style={{ fontSize: '75px' }}
          />
        )}
        <div className="media-item__details">
          <div>
            <strong>{media.originalFileName}</strong>
          </div>
          <div>{moment(media.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</div>
        </div>
      </div>
      <div className="media-item__controls">
        {media.posterFrame && (
          <Button
            disabled={disabled}
            href={media.posterFrame}
            target="_blank"
            type="link"
            rel="noopener noreferrer"
          >
            Preview
          </Button>
        )}
        <Button disabled={disabled} onClick={() => onSelect(media)}>
          Select
        </Button>
      </div>
    </div>
  );
}
MediaItem.defaultProps = {
  disabled: false
};

MediaItem.propTypes = {
  disabled: PropTypes.bool,
  media: PropTypes.shape({
    posterFrame: PropTypes.string.isRequired,
    originalFileName: PropTypes.string.isRequired,
    createdAt: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string
    ])
  }).isRequired,
  onSelect: PropTypes.func.isRequired
};
