export default function makeListString(stringArray) {
  if (stringArray.length === 0) {
    return '';
  }

  const array = [...stringArray];
  const last = array.pop();

  if (array.length === 0) {
    return last;
  }

  const oxfordComma = array.length === 1 ? '' : ',';
  return array.join(', ') + oxfordComma + ' and ' + last;
}
