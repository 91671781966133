import { gql } from '@apollo/client';

const CREATE_ACCOUNT_MUTATION = gql`
  mutation CreateAccount(
    $id: UUID!
    $firstName: String!
    $lastName: String!
    $suffix: String = ""
    $email: String!
    $isAdmin: Boolean!
    $createdAt: Datetime!
    $updatedAt: Datetime!
  ) {
    createAccount(
      input: {
        account: {
          id: $id
          firstName: $firstName
          lastName: $lastName
          email: $email
          createdAt: $createdAt
          updatedAt: $updatedAt
          isAdmin: $isAdmin
          suffix: $suffix
        }
      }
    ) {
      clientMutationId
      account {
        id
      }
    }
  }
`;

export default CREATE_ACCOUNT_MUTATION;
