import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Spin, Space } from 'antd';
import { Label } from 'reactstrap';
import _ from 'underscore';

import FillMainView from '../../components/FillMainView';
import EditableLabel from '../../components/EditableLabel/EditableLabel';
import QuestionsTableView from './QuestionsTableView';
import QuestionsTableHeader from './QuestionsTableHeader';
import CourseSelector from '../course/CourseSelector';

import BreadcrumbConstants from '../../routes/BreadcrumbConstants';
import * as API from '../../API';
import { CourseTypeTitles } from '../../constants';
import { useGetDisplayableCourseIdsByCourseType } from '../courseTypes/hooks/useGetDisplayableCourseIdsByCourseType';

import './Assessment.css';

export default function Assessment({ assessmentId }) {
  const history = useHistory();
  const [assessment, setAssessment] = useState(null);
  const [shortnameErrorMessage, setShortnameErrorMessage] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(null);

  const assessmentCourseTypeTitlesToDisplay = [
    CourseTypeTitles.BOOTCAMP_ASSESSMENT,
    CourseTypeTitles.ASSESSMENT
  ];
  const {
    courseIds: assessmentCourseIds,
    loading
  } = useGetDisplayableCourseIdsByCourseType(
    assessmentCourseTypeTitlesToDisplay
  );

  useEffect(() => {
    async function fetchData() {
      const assessmentResult = await API.assessment.find({
        id: assessmentId,
        options: {
          include:
            'assessmentQuestionBindings,assessmentQuestionBindings.question,course.courseType'
        }
      });
      setAssessment(API.simplifyResource(assessmentResult));
    }
    fetchData();
  }, [assessmentId, forceRefresh]);

  const updateAssessment = async (field, value) => {
    const assessmentClone = _.clone(assessment);
    assessmentClone[field] = value;

    await API.assessment.update({
      id: assessment.id,
      attributes: {
        [field]: value
      }
    });

    setAssessment(assessmentClone);
  };

  // checks that the short name is unique before saving it
  const updateAssessmentShortname = async value => {
    const assessments = await API.fetchAllPages(API.assessment.all());
    const shortNames = assessments.map(
      assessment => assessment.attributes.shortname
    );
    if (shortNames.includes(value)) {
      setShortnameErrorMessage('Assessment shortnames must be unique!');
    } else {
      setShortnameErrorMessage(false);
      await updateAssessment('shortname', value);
    }
  };

  const updateCourse = async courseId => {
    await API.assessment.update({
      id: assessment.id,
      relationships: {
        course: {
          data: {
            type: 'course',
            id: courseId
          }
        }
      }
    });
  };

  return assessment ? (
    <FillMainView
      onClose={() => history.push(BreadcrumbConstants.Assessments.to)}
      closeButtonText="Back to Assessments"
      breadcrumbData={[
        BreadcrumbConstants.HippoAdmin,
        BreadcrumbConstants.Courses,
        BreadcrumbConstants.Assessments,
        { title: assessment.title || '' }
      ]}
    >
      <hr />
      <div className="assessment">
        <div className="assessment__form-item">
          <Label>Course</Label>
          {assessmentCourseIds.length > 0 && !loading ? (
            <CourseSelector
              defaultValue={assessment.course.id}
              onCourseSelected={value => updateCourse(value)}
              courseIdList={assessmentCourseIds}
            />
          ) : (
            <Spin />
          )}
        </div>
        <div className="assessment__form-item">
          <Label>Assessment Name</Label>
          <EditableLabel
            defaultValue={assessment.title}
            onChange={value => updateAssessment('title', value)}
          />
        </div>
        <div className="assessment__form-item">
          <Label>Shortname</Label>
          <EditableLabel
            defaultValue={assessment.shortname}
            onChange={value => updateAssessmentShortname(value)}
          />
          {shortnameErrorMessage && (
            <div className="assessment__short-name-error">
              {shortnameErrorMessage}
            </div>
          )}
        </div>
        <div className="assessment__form-item">
          <Label>Schedule Release Date</Label>
          <EditableLabel
            defaultValue={
              assessment.releaseDate ? assessment.releaseDate : null
            }
            inputType="date"
            errorMessage="Must be a date"
            onChange={value => updateAssessment('releaseDate', value)}
          />
        </div>
        <div className="assessment__form-item">
          <Label>Questions</Label>
          <Space direction="vertical" style={{ width: '100%' }} size="large">
            <QuestionsTableHeader assessment={assessment} />
            <QuestionsTableView
              assessment={assessment}
              refresh={() => setForceRefresh(Math.random())}
              showHeader={
                assessment.assessmentQuestionBindings.length > 0 ? true : false
              }
            />
          </Space>
        </div>
        <div className="assessment__save-notice">
          All changes are automatically saved
        </div>
      </div>
    </FillMainView>
  ) : (
    <Spin />
  );
}
