import React from 'react';
import { List, Button } from 'antd';
import { InfoCircleFilled, DeleteOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import DELETE_ORGANIZATION_PROGRAM_BINDING_MUTATION from './queries/DeleteOrganizationProgramBindingMutation';
import ClinicModal from './OrganizationMapExistingClinicModal';
const AddClinicSection = props => {
  const {
    clinics,
    refreshParent,
    preselectedClinics,
    organizationId,
    orgType
  } = props;

  const [deleteOrganizationDefaultSubscription] = useMutation(
    DELETE_ORGANIZATION_PROGRAM_BINDING_MUTATION,
    {
      onCompleted: async (data, clientOptions) => {
        if (refreshParent) {
          await refreshParent();
        }
      }
    }
  );

  const handleRemoveClinic = async clinicToRemove => {
    await deleteOrganizationDefaultSubscription({
      variables: {
        id: clinicToRemove.bindingId
      }
    });
  };

  return (
    <>
      {clinics && (
        <ClinicModal
          clinics={clinics}
          organizationId={organizationId}
          refreshParent={refreshParent}
          preselectedClinics={preselectedClinics}
          orgType={orgType}
        />
      )}
      <span
        style={{
          border: '1px solid #DDD',
          padding: '.75rem 2rem 2rem 2rem',
          display: 'inline-flex',
          marginLeft: '1rem',
          width: '23rem'
        }}
      >
        <div>
          <h6 style={{ textAlign: 'center' }}>
            <b>
              {orgType === 'MEDICAL' ? 'Mapped Clinic(s)' : 'Mapped Cohort(s)'}
            </b>
          </h6>
          {preselectedClinics.length === 0 ? (
            <p>
              Add at least one&nbsp;
              {props.orgType === 'MEDICAL' ? 'clinic' : 'cohort'} to enable the
              Group Sign on Tool
            </p>
          ) : (
            <List
              dataSource={preselectedClinics}
              renderItem={item => {
                return (
                  <List.Item
                    style={{
                      fontSize: '.85rem',
                      width: '19rem',
                      paddingTop: '.4rem',
                      paddingBottom: '.4rem'
                    }}
                  >
                    <span style={{ display: 'inline-block', width: '75%' }}>
                      {clinics.find(clinic => clinic.id === item.id)?.name}
                    </span>
                    <span
                      style={{
                        position: 'absolute',
                        display: 'inline-flex',
                        right: '0'
                      }}
                    >
                      <Button
                        style={{ marginLeft: '1rem', paddingBottom: '26px' }}
                        size="small"
                        type="danger"
                        onClick={() => handleRemoveClinic(item)}
                      >
                        <DeleteOutlined />
                      </Button>
                    </span>
                  </List.Item>
                );
              }}
            />
          )}
        </div>
      </span>
      <p
        style={{
          display: 'inline-flex',
          float: 'right',
          width: '11rem',
          margin: '.5rem'
        }}
      >
        <InfoCircleFilled /> Add at least one
        {props.orgType === 'MEDICAL' ? ' clinic' : ' cohort'} to enable the
        Group Sign On Tool
      </p>
    </>
  );
};

export default AddClinicSection;
