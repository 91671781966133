import { gql } from '@apollo/client';

const GET_ORGANIZATION_PAYLOAD_QUERY = gql`
  query GetOrganizationPayload($id: UUID!) {
    organization(id: $id) {
      id
      image {
        url
        id
      }
      isEnabled
      managerId
      manager {
        email
        commentingAlias
        lastName
        suffix
        firstName
      }
      shortname
      type
      createdAt
      name
      notes
      organizationAdminBindingsList {
        id
        adminAccountId
        organizationId
        adminAccount {
          firstName
          lastName
          suffix
          email
        }
      }
      organizationDefaultSubscriptionsList {
        subscribedCourseId
        organizationId
        id
        isEnabled
      }
      organizationEmailDomainsList {
        allowedEmailDomain
        id
        isEnabled
        organizationId
      }
      organizationProgramBindingsList {
        clinicName
        createdAt
        id
        isEnabled
        programId
      }
    }
    programsList {
      id
      name
      programAccountBindings {
        totalCount
      }
      programCourseBindings {
        totalCount
      }
    }
  }
`;

export default GET_ORGANIZATION_PAYLOAD_QUERY;
