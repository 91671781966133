import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Upload, Spin, message } from 'antd';
import uuidv4 from 'uuid/v4';
import classNames from 'classnames';
import uploadToS3 from '../../utils/uploadToS3';

import './AWSUploadInput.css';
import { DownloadOutlined } from '@ant-design/icons';
const { Dragger } = Upload;

export default function AWSUploadInput({
  name,
  icon,
  text,
  accept,
  path,
  type,
  className,
  transcode = false,
  onStart = null,
  customAccept = null,
  maxFileSizeBytes = null,
  maxWidth = null,
  maxHeight = null,
  onFileComplete = file =>
    message.success(`${file.name} file uploaded successfully.`),
  onError = file => message.error(`${file.name} file upload failed.`),
  onComplete = null,
  abort = null,
  ...rest
}) {
  const [isUploading, setIsUploading] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [fileList, setFileList] = useState([]);

  const isFileUnderMaxDimensions = async (file, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const image = new Image();
        image.src = reader.result;
        image.onload = () => {
          if (image.width > maxWidth || image.height > maxHeight) {
            resolve(false);
          }
          resolve(true);
        };
      };
    });
  };
  const props = {
    name,
    accept: customAccept ? customAccept : accept,
    showUploadList: {
      showPreviewIcon: true,
      showDownloadIcon: false,
      showRemoveIcon: false
    },
    fileList,
    beforeUpload: (file, fileList) => {
      return onStart === null ? true : onStart(file, fileList);
    },
    customRequest: async request => {
      const errorState = { isError: false, errorText: '' };
      if (maxFileSizeBytes && request.file.size > maxFileSizeBytes) {
        errorState.isError = true;
        errorState.errorText = `File size is too large. File size can't be larger than ${maxFileSizeBytes /
          1024} KB (${maxFileSizeBytes / (1024 * 1024)} MB)`;
      } else if (customAccept && !customAccept.includes(request.file.type)) {
        errorState.isError = true;
        errorState.errorText = `File type is wrong type. must be one of the following types: ${customAccept}`;
      } else if (maxWidth && maxHeight) {
        const validDimension = await isFileUnderMaxDimensions(
          request.file,
          maxWidth,
          maxHeight
        );
        if (!validDimension) {
          errorState.isError = true;
          errorState.errorText = `Image dimensions must be no larger than ${maxWidth} pixels wide by ${maxHeight} pixels tall`;
        }
      }
      if (errorState.isError) {
        onError(request.file);
        setErrorText(errorState.errorText);
        if (abort) {
          setFileList([]);
          abort();
        }
        return {
          abort() {}
        };
      }
      setIsUploading(true);

      const key = uuidv4();
      let uploadPath = `${path}/${key}`;
      if (transcode) {
        uploadPath += `/${request.file.name
          .replace(/[,#!$%&;:{}=\-_`~()]/g, '')
          .replace(' ', '_')}`;
      }
      const onProgress = e => {
        // antd upload components' progress bar looks for this
        e.percent = (e.loaded / e.total) * 100;
        request.onProgress(e);
      };

      // Antd component looks for `url` for Preview urls, but the link will be broken link if it's added now.
      // When upload is complete in `onChange` this value gets moved to the correct property.
      request.file.targetUrl = `${process.env.REACT_APP_CLOUDFRONT_DISTRIBUTION}/${uploadPath}`;
      request.file.key = key;
      request.file.uploadPath = uploadPath;

      return uploadToS3(uploadPath, request.file, onProgress, transcode)
        .then(request.onSuccess)
        .catch(request.onError);
    },
    onChange: info => {
      setFileList(info.fileList);
      setErrorText('');
      const { status } = info.file;
      if (status === 'done') {
        // Enable Preview link by copying tempUrl to url prop. Must alter fileList,
        // info.file has already been copied into it inside the antd component
        info.fileList.map(file =>
          file.uid === info.file.uid
            ? Object.assign(file, { url: file.originFileObj.targetUrl })
            : file
        );
        onFileComplete(info.file);
      } else if (status === 'error') {
        onError(info.file);
      }
      const stillUploading = info.fileList.reduce(
        (done, file) => (done = done || file.status === 'uploading'),
        false
      );
      if (!stillUploading) {
        setIsUploading(false);
        onComplete && onComplete(info.fileList);
      }
    },
    ...rest
  };

  return (
    <div
      className={classNames(
        'file-input',
        { 'file-input--inline': type === 'inline' },
        className
      )}
    >
      <Dragger {...props}>
        {isUploading ? (
          <React.Fragment>
            <p>Uploading…</p>
            <Spin />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <p>{text}</p>
            <p className="ant-upload-drag-icon">
              <DownloadOutlined />
            </p>
          </React.Fragment>
        )}
      </Dragger>
      <p style={{ color: 'crimson' }}>{errorText && errorText}</p>
    </div>
  );
}

AWSUploadInput.defaultProps = {
  name: 'file',
  icon: 'download',
  text: ' Click here or drag a file to upload',
  path: 'attachments',
  accept: ''
};

AWSUploadInput.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.string,
  path: PropTypes.string,
  accept: PropTypes.string
};
