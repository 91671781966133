// Provides common logic for notes
import * as API from '../API';

export const getBindingAPIFromType = bindingType => {
  let bindingAPI;
  switch (bindingType) {
    case 'account':
      bindingAPI = API.accountNoteBinding;
      break;
    case 'program':
      bindingAPI = API.programNoteBinding;
      break;
    case 'organization':
      bindingAPI = API.organizationNoteBinding;
      break;
    default:
      break;
  }
  return bindingAPI;
};
