import { gql } from '@apollo/client';

const GET_ITEMS_BY_VERTICAL_QUERY = gql`
  query GetItemsByVertical($todaysDate: Datetime!) {
    verticalsList(orderBy: TITLE_ASC) {
      title
      id
      productsList {
        itemsList(
          filter: {
            or: [
              { endedAt: { isNull: true } }
              { endedAt: { greaterThan: $todaysDate } }
            ]
          }
          orderBy: TITLE_ASC
        ) {
          id
          title
        }
      }
    }
  }
`;

export default GET_ITEMS_BY_VERTICAL_QUERY;
