import React from 'react';
import { Input, Table, Tag, Tooltip, message } from 'antd';
import moment from 'moment';
import CommentsActionButton from './CommentsActionButton';
import Comments3DotMenu from './Comments3DotMenu';
import CommentsFacultyFlair from './CommentsFacultyFlair';
import CommentsReplySection from '../../containers/comments/CommentsReplySection';
import { hexToRgb, rgbToHex } from '../../utils/hexRGBconversion';
const { TextArea } = Input;
const { Column } = Table;

export default function CommentsSubTable(props) {
  const childrenKeys = props.record.childrenComments
    ? props.record.childrenComments.map(child => {
        return child.key;
      })
    : [];

  const handleBodyTextChange = (event, record) => {
    record.commentText.bodyText = event.target.value;
  };

  const rgbParentColor = hexToRgb(props.BGColor);
  const colorOffset = 10;
  const childBGColor = rgbToHex(
    rgbParentColor[0] - colorOffset,
    rgbParentColor[1] - colorOffset,
    rgbParentColor[2] - colorOffset
  );

  return (
    <>
      {props.record.isBeingRepliedTo && (
        <CommentsReplySection
          comment={props.record}
          handleActionButtonClick={props.handleActionButtonClick}
          currentUserAccount={props.currentUserAccount}
          currentFacultyAccount={props.currentFacultyAccount}
          currentCourseId={props.currentCourseId}
          commentAsList={props.commentAsList}
        />
      )}
      {props.record.childrenComments && (
        <Table
          className="sub-table"
          showHeader={false}
          key={props.record.key}
          dataSource={props.record.childrenComments}
          pagination={false}
          expandable={{
            expandedRowRender: record => {
              if (record.childrenComments || record.isBeingRepliedTo) {
                return (
                  <CommentsSubTable
                    record={record}
                    handleActionButtonClick={props.handleActionButtonClick}
                    currentUserAccount={props.currentUserAccount}
                    BGColor={childBGColor}
                    commentAsList={props.commentAsList}
                  />
                );
              } else {
                return <></>;
              }
            },
            onExpand: props.onExpand,
            expandedRowKeys: childrenKeys,
            // rowExpandable: record => record.childrenComments !== undefined,
            // set to -1 to hide expand column; we automatically expand all with props.expandRowKeys
            expandIconColumnIndex: -1
          }}
        >
          <Column
            className="bodytext-column"
            dataIndex="bodyText"
            render={(comment, record) => {
              return (
                <>
                  {record.isBeingEdited ? (
                    <TextArea
                      placeholder="Type your reply here"
                      rows={4}
                      defaultValue={record.commentText.bodyText}
                      onChange={event => handleBodyTextChange(event, record)}
                    />
                  ) : (
                    <div className="comments-child-comment-bodyText">
                      {record.commentText.tags.map(tag => {
                        return (
                          <div key={tag.name} className={`tag ${tag.name}`}>
                            <Tag color={tag.color}>{tag.name}</Tag>
                          </div>
                        );
                      })}
                      <Tooltip
                        className="comments-info-icon"
                        title={record.email}
                      >
                        <span
                          className="comments-child-comment-alias"
                          onClick={e => {
                            navigator.clipboard.writeText(record.email);
                            message.info(
                              'Copied ' + record.email + ' to clipboard'
                            );
                            e.stopPropagation();
                          }}
                        >
                          {record.alias}
                          {record.isFacultyComment ? (
                            <CommentsFacultyFlair />
                          ) : (
                            ' – '
                          )}
                        </span>
                      </Tooltip>
                      {record.commentText.bodyText}
                    </div>
                  )}
                </>
              );
            }}
          />
          <Column
            className="date-column"
            width="2vw"
            render={comment => {
              return moment(comment.date).format('MM/DD/YYYY');
            }}
            sorter={(a, b) => {
              return moment(a.date) - moment(b.date);
            }}
            defaultSortOrder="ascend"
          />
          <Column
            width="2vw"
            className="action-column"
            render={(comment, { key, action }) => (
              <CommentsActionButton
                handleActionButtonClick={props.handleActionButtonClick}
                comment={comment}
                action={action}
              />
            )}
          />
          <Column
            width="1vw"
            className="menu-column"
            render={(comment, { key, menuItems }) => (
              <Comments3DotMenu
                handleActionButtonClick={props.handleActionButtonClick}
                comment={comment}
                menuItems={menuItems}
              />
            )}
          />
        </Table>
      )}
    </>
  );
}
