import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useLocation, useParams } from 'react-router-dom';
import { Label } from 'reactstrap';
import { Button, Descriptions, Empty, Table } from 'antd';
import moment from 'moment';

import * as API from '../../API';
import BreadcrumbConstants from '../../routes/BreadcrumbConstants';
import BreadcrumbNavigation from '../../components/BreadcrumbNavigation/BreadcrumbNavigation';
import PaginatedList from '../../components/PaginatedList/PaginatedList';

const { Column } = Table;

export default function Rating() {
  const location = useLocation();
  const [course, setCourse] = useState(null);
  const [content, setContent] = useState(null);
  const [ratingSummary, setRatingSummary] = useState(null);
  const { course: courseId, contentType, contentId } = useParams();

  useEffect(() => {
    if (contentId) {
      async function fetchData() {
        const courseResult = await API.course.find({
          id: courseId,
          options: {
            include: 'courseType'
          }
        });
        setCourse(API.simplifyResource(courseResult));

        const contentData = API.simplifyResource(
          await API[contentType].where({
            filter: { id: contentId },
            options: {}
          })
        );
        setContent(contentData[0]);

        setRatingSummary(location.state.ratingSummary);
      }

      fetchData();
    }
  }, [contentId, courseId, contentType, location.state.ratingSummary]);

  return (
    <div>
      {content && course && (
        <BreadcrumbNavigation
          data={[
            BreadcrumbConstants.HippoAdmin,
            BreadcrumbConstants.Courses,
            BreadcrumbConstants.Podcast,
            {
              to: `/course/${courseId}`,
              title: course ? course.title : 'course'
            },
            {
              title: `Overview of ${course.title} Ratings`,
              to: `/course/${courseId}/ratings`
            },
            {
              title: `${content.title || 'Question'} Ratings`,
              to: `/course/${courseId}/ratings/${contentId}`
            }
          ]}
        />
      )}
      {ratingSummary && (
        <Descriptions
          size="small"
          column={1}
          bordered={true}
          style={{
            marginTop: '10px',
            backgroundColor: '#fff',
            borderRadius: '5px'
          }}
        >
          <Descriptions.Item
            style={
              contentType !== 'chapter' && contentType !== 'lecture'
                ? { width: '60%' }
                : { width: '200px' }
            }
            label={
              contentType === 'chapter' || contentType === 'lecture'
                ? 'Title'
                : 'Question'
            }
          >
            <span>
              {contentType === 'chapter' || contentType === 'lecture' ? (
                ratingSummary.title
              ) : (
                <>
                  <span
                    dangerouslySetInnerHTML={{ __html: ratingSummary.body }}
                  />
                  <Button href={'/content/questions/' + ratingSummary.id}>
                    Edit Question
                  </Button>
                </>
              )}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="Average Rating">
            {_.round(ratingSummary.averageRating, 2)}
          </Descriptions.Item>
          <Descriptions.Item label="Total Number of Ratings">
            {ratingSummary.count}
          </Descriptions.Item>
          <Descriptions.Item label="Total Number of Comments">
            {ratingSummary.countWithComments}
          </Descriptions.Item>
          <Descriptions.Item label="Last Updated">
            {moment(content.updatedAt).format('MMMM Do, YYYY')}
          </Descriptions.Item>
        </Descriptions>
      )}
      <Label className="comments__comment-section">
        {_.capitalize(contentType)} Ratings
      </Label>
      {courseId && (
        <PaginatedList
          resource={API.accountRating}
          defaultFilter={{ [contentType]: contentId }}
          defaultOptions={{
            sort: '-createdAt'
          }}
          onLoadRows={resource => API.simplifyResource(resource)}
          pageLimit={20}
          renderList={(data, refresh) => (
            <Table
              className="ratings-table"
              dataSource={data}
              rowKey="id"
              pagination={false}
            >
              <Column
                title="Rating Date"
                key="createdAt"
                render={(value, rating) => (
                  <span>{moment(rating.createdAt).format('MMMM Do YYYY')}</span>
                )}
              />
              <Column
                title="Comment"
                key="comment"
                render={(value, rating) => <span>{rating.comment}</span>}
              />
              <Column
                title="Rating"
                key="rating"
                render={(value, rating) => rating.rating}
              />
            </Table>
          )}
          renderEmptyList={() => (
            <Empty description="`No ratings for this Content :(" />
          )}
        />
      )}
    </div>
  );
}
