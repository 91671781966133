import { useState, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/browser';

import { GET_COURSE_IDS_BY_COURSE_TYPE_WITH_WEB_INTERNAL } from '../queries/CourseTypes';

export const useGetDisplayableCourseIdsByCourseType = courseTypeTitles => {
  const [courseIds, setCourseIds] = useState([]);

  const { loading, error, data } = useQuery(
    GET_COURSE_IDS_BY_COURSE_TYPE_WITH_WEB_INTERNAL,
    {
      skip: !courseTypeTitles || courseTypeTitles.length === 0,
      variables: {
        courseTypeTitlesToInclude: courseTypeTitles
      }
    }
  );

  useEffect(() => {
    if (data && data.courseTypesList.length > 0) {
      const ids = data.courseTypesList.flatMap(courseTypeData => {
        return courseTypeData.coursesList.map(course => course.id);
      });

      setCourseIds(ids);
    } else {
      setCourseIds([]);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      Sentry.captureException(
        `Error fetching course IDs given CourseType: ${error}`
      );
      setCourseIds([]);
    }
  }, [error]);

  return { courseIds, loading };
};
