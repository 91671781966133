import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Alert, Button, List, Checkbox } from 'antd';
import { Label } from 'reactstrap';

import * as API from '../../API';
import AttachmentItem from './AttachmentItem';
import AttachmentUpload from '../Upload/AttachmentUpload';
import {
  parseTimeInput,
  formatTimeHoursMinutesSeconds
} from '../../utils/time';
import EditableLabel from '../EditableLabel/EditableLabel';
import { useFileUpload } from '../../containers/attachments/hooks/useAttachmentHooks';

import './AttachmentsInput.css';

const ClosedCaptionAttachmentsInput = ({
  attachments,
  onCreateItem,
  onUpdateItem,
  onDeleteItem,
  showCmeFields,
  canEdit
}) => {
  const defaultFileData = {
    key: '',
    fileName: '',
    fileSize: '',
    mimeType: '',
    isContentSummary: false
  };
  const [addingNew, setAddingNew] = useState(false);
  const [activityTimeInSeconds, setActivityTimeInSeconds] = useState(300);
  const [isContentSummary, setIsContentSummary] = useState(
    showCmeFields && !attachments?.length > 0
  );
  const [fileData, setFileData] = useState(defaultFileData);
  const [newAttachmentError, setNewAttachmentError] = useState('');
  const handleFileUpload = useFileUpload(setFileData, { isContentSummary });

  const resetForm = () => {
    setActivityTimeInSeconds(300);
    setIsContentSummary(showCmeFields && !attachments?.length > 0);
    setFileData(defaultFileData);
    setNewAttachmentError('');
  };

  const createNewClosedCaptionAttachment = async () => {
    setNewAttachmentError('');
    const attachmentData = API.attachment.create({
      attributes: {
        title: 'english vtt file',
        subtitle: 'en', //we only support 1 subtitle file and that file is always english
        activityTimeInSeconds: showCmeFields ? activityTimeInSeconds : 0,
        isContentSummary: showCmeFields ? isContentSummary : false,
        ...fileData
      },
      relationships: {}
    });

    if (attachmentData.status >= 400) {
      setNewAttachmentError('Error');
    } else {
      const attachment = (await attachmentData).data;
      resetForm();
      setAddingNew(false);
      if (onCreateItem) {
        onCreateItem(attachment.id);
      }
    }
  };

  return (
    <div>
      <List
        className="attachment-list"
        dataSource={attachments ? attachments : []}
        header={
          attachments?.length > 0 ? (
            <div
              className={
                showCmeFields
                  ? 'attachment-list__header--rap'
                  : 'attachment-list__header'
              }
            >
              <span className="attachment-list__header-language">Language</span>
              {showCmeFields ? (
                <>
                  <span className="attachment-list__header-activity-time">
                    Activity Time
                  </span>
                  <span className="attachment-list__header-content-summary">
                    Content Summary
                  </span>
                </>
              ) : null}
            </div>
          ) : null
        }
        renderItem={item => (
          <AttachmentItem
            item={item}
            hideReplace={true}
            onUpdate={onUpdateItem}
            onDelete={async () => {
              if (onDeleteItem) {
                onDeleteItem(item);
              }
            }}
            showCmeFields={showCmeFields}
            canEdit={canEdit}
            hideTitle={true}
            disableSubtitleEdit={true}
          />
        )}
      />
      <div className="new-attachment-form">
        {addingNew && (
          <>
            {showCmeFields && (
              <div className="attachments-input__rap-inputs">
                <div className="attachments-input__rap-inputs-container">
                  <Label
                    id="attachment_activityTimeInSeconds"
                    htmlFor="activityTimeInSeconds"
                    className="attachments-input__rap-inputs-label"
                  >
                    Activity Time
                  </Label>
                  <EditableLabel
                    defaultValue={activityTimeInSeconds}
                    formatLabel={value => formatTimeHoursMinutesSeconds(value)}
                    onChange={value => {
                      if (value === '') {
                        setActivityTimeInSeconds(0);
                      } else {
                        const time = parseTimeInput(value);
                        setActivityTimeInSeconds(time);
                      }
                    }}
                    className="attachments-input__activity-time"
                  />
                </div>
                <div className="attachments-input__rap-inputs-container">
                  <Label
                    id="attachment_isContentSummary"
                    htmlFor="isContentSummary"
                    className="attachments-input__rap-inputs-label"
                  >
                    Is Content Summary
                  </Label>
                  <Checkbox
                    id="isContentSummary"
                    name="isContentSummary"
                    onChange={e => setIsContentSummary(!isContentSummary)}
                    checked={isContentSummary}
                  />
                </div>
              </div>
            )}

            <AttachmentUpload
              filename={fileData.fileName}
              onComplete={handleFileUpload}
              hideReplace={true}
              canEdit={canEdit}
            />
            {newAttachmentError && (
              <Alert
                message={newAttachmentError}
                type="error"
                showIcon={true}
              />
            )}
          </>
        )}
        <span className="attachments-input__buttons">
          {addingNew && (
            <Button onClick={() => createNewClosedCaptionAttachment()}>
              Save
            </Button>
          )}
          {canEdit && attachments.length === 0 && (
            <Button
              onClick={() => {
                resetForm();
                setAddingNew(!addingNew);
              }}
            >
              {addingNew ? 'Close' : 'New Closed Caption File'}
            </Button>
          )}
        </span>
      </div>
    </div>
  );
};

ClosedCaptionAttachmentsInput.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      language: PropTypes.string,
      key: PropTypes.string
    })
  ),
  onCreateItem: PropTypes.func,
  onUpdateItem: PropTypes.func,
  onDeleteItem: PropTypes.func,
  showCmeFields: PropTypes.bool,
  canEdit: PropTypes.bool
};

ClosedCaptionAttachmentsInput.defaultProps = {
  attachments: [],
  onCreateItem: null,
  onUpdateItem: null,
  onDeleteItem: null,
  showCmeFields: false,
  canEdit: true
};

export default ClosedCaptionAttachmentsInput;
