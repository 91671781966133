import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Card } from 'antd';
import * as API from '../../API';
import BundleCard from '../../components/Bundle/BundleCard';
import BundleItems from '../../components/Bundle/BundleItems';

import '../bundles/Bundles.css';

export default function Bundles({ allItems, itemId, itemTitle }) {
  const [bundlesForItem, setBundlesForItem] = useState({});
  const [hasStaleData, setHasStaleData] = useState(true);

  const itemFinder = (items, resource, match) =>
    items.find(item => item.type === resource && item.id === match);

  useEffect(() => {
    const fetchBundles = async () => {
      const allBundleItems = await API.bundleItemBinding.where({
        filter: {},
        options: {
          include: 'bundle,item'
        }
      });

      const activeBundleIds = allBundleItems.data
        .filter(bundleItem => bundleItem.relationships.item.data.id === itemId)
        .map(bundleItem => bundleItem.relationships.bundle.data.id);

      // This is a bit of a beast, but we're basically just creating
      // an object of all bundles that contain this specific item
      // with the parent bundle id as the key
      const groupedBundleItems = allBundleItems.data.reduce(
        (result, bundleItem) => {
          const bundleId = bundleItem.relationships.bundle.data.id;
          const hippoItemId = bundleItem.relationships.item.data.id;
          const hippoItem = itemFinder(
            allBundleItems.included,
            'item',
            hippoItemId
          );
          const parentBundle = itemFinder(
            allBundleItems.included,
            'bundle',
            bundleId
          );

          if (activeBundleIds.includes(bundleId)) {
            if (result[bundleId]) {
              result[bundleId] = {
                bundleTitle: parentBundle.attributes.title,
                startedAt: parentBundle.attributes.startedAt,
                endedAt: parentBundle.attributes.endedAt,
                bundleItems: [
                  ...result[bundleId].bundleItems,
                  {
                    ...bundleItem,
                    attributes: {
                      ...bundleItem.attributes,
                      title: hippoItem.attributes.title
                    }
                  }
                ]
              };
            } else {
              result[bundleId] = {
                bundleTitle: parentBundle.attributes.title,
                startedAt: parentBundle.attributes.startedAt,
                endedAt: parentBundle.attributes.endedAt,
                bundleItems: [
                  {
                    ...bundleItem,
                    attributes: {
                      ...bundleItem.attributes,
                      title: hippoItem.attributes.title
                    }
                  }
                ]
              };
            }
          }

          return result;
        },
        {}
      );

      setBundlesForItem(groupedBundleItems);
    };

    if (hasStaleData) {
      fetchBundles();
      setHasStaleData(false);
    }
  }, [hasStaleData, itemId]);

  const handleCreateNewBundleFromItem = async () => {
    const newBundle = await API.bundle.create({
      attributes: {
        title: 'New bundle'
      }
    });

    await API.bundleItemBinding.create({
      attributes: {},
      relationships: {
        item: {
          data: {
            type: 'item',
            id: itemId
          }
        },
        bundle: {
          data: {
            type: 'bundle',
            id: newBundle.data.id
          }
        }
      }
    });

    setHasStaleData(true);
  };

  return (
    <div>
      <h2>Bundles</h2>
      <Button type="primary" onClick={handleCreateNewBundleFromItem}>
        + Create New Bundle With {itemTitle}
      </Button>
      <Row>
        <Col sm={24} xl={18}>
          {Object.keys(bundlesForItem).map(bundle => (
            <Card
              key={bundle}
              className="bundle__column-padded-lg"
              title={
                <BundleCard
                  bundleId={bundle}
                  title={bundlesForItem[bundle].bundleTitle}
                  startedAt={bundlesForItem[bundle].startedAt || null}
                  endedAt={bundlesForItem[bundle].endedAt || null}
                />
              }
            >
              <BundleItems bundleId={bundle} />
            </Card>
          ))}
        </Col>
      </Row>
    </div>
  );
}
