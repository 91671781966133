import React, { useState } from 'react';
import { Button } from 'antd';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AddSubscriptionModal } from './AddSubscriptionModal';

import './AddSubscriptionModal.css';

import { getTokenFromCookie } from '../../../utils/cookie';

const LOADING_IMAGE = require('../../../images/loading.gif');

export const AddSubscription = ({ visible, account, onCancel }) => {
  const [isVisible, setIsVisible] = useState(visible);
  const [loading, setLoading] = useState(false);
  const [receipt, setReceipt] = useState(null);
  const [error, setError] = useState(null);

  async function verifyAndSubmitPurchase(purchaseData) {
    try {
      const promotionCodes = purchaseData.promotionCode
        ? [purchaseData.promotionCode]
        : [];

      const requestData = {
        adminComments: purchaseData.adminComments,
        items: [purchaseData.item.id],
        price: purchaseData.price,
        promotionCodes,
        isAdminPurchase: true,
        isPrepaid: !!purchaseData.isPrepaid,
        paymentMethodNonce: purchaseData.paymentMethodNonce || 'NONE'
      };

      setLoading(true);
      const purchaseResponse = await fetch(
        `${process.env.REACT_APP_JSONAPI_SERVER}/api/purchase?forAccountId=${purchaseData.account.id}`,
        {
          method: 'POST',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getTokenFromCookie()}`
          },
          body: JSON.stringify(requestData)
        }
      );
      purchaseResponse.json().then(data => {
        setReceipt(data.receipt);
        setLoading(false);
        setIsVisible(false);
      });
    } catch (e) {
      setLoading(false);
      throw new Error(e);
    }
  }

  const cleanupAndClose = () => {
    setError(null);
    setLoading(false);
    onCancel();
  };

  return (
    <>
      <AddSubscriptionModal
        loading={loading}
        visible={isVisible}
        account={account}
        onSubmit={verifyAndSubmitPurchase}
        cleanupAndClose={cleanupAndClose}
      />
      {loading ? <img src={LOADING_IMAGE} alt="loading" /> : null}
      {error && <div>{error}</div>}
      {receipt !== null ? (
        <Modal
          isOpen={receipt !== null}
          modalTransition={{ timeout: 700 }}
          backdropTransition={{ timeout: 1300 }}
        >
          <ModalHeader>Order Placed</ModalHeader>
          <ModalBody>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}
            >
              <img
                style={{ width: 64, margin: 20 }}
                src={require('../../../containers/checkmark.png')}
                alt="checkmark"
              />
              <table>
                <tbody>
                  <tr>
                    <td style={{ paddingBottom: 12 }}>Account Information</td>
                    <td />
                  </tr>
                  <tr>
                    <td style={{ paddingRight: 50 }}>
                      <strong>Account ID</strong>
                    </td>
                    <td>{receipt.order.AccountId}</td>
                  </tr>
                  <tr>
                    <td style={{ paddingRight: 50 }}>
                      <strong>Email</strong>
                    </td>
                    <td>{receipt.order.email}</td>
                  </tr>
                  <tr>
                    <td style={{ paddingTop: 20, paddingBottom: 12 }}>
                      Order Information
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <td style={{ paddingRight: 50 }}>
                      <strong>Order ID</strong>
                    </td>
                    <td>{receipt.order.id}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                setReceipt(null);
                window.location.reload();
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      ) : null}
    </>
  );
};
