import { LeftOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AccountCmeCourseList from './account/accountCme/AccountCme';

export default function AccountCme() {
  const { id: accountId, courseId: courseIdFromLocation } = useParams();
  const history = useHistory();

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Button
        className="fill-main__close-button"
        type="link"
        icon={<LeftOutlined />}
        onClick={() => history.push(`/account/${accountId}`)}
      >
        Back to account
      </Button>
      <AccountCmeCourseList
        accountId={accountId}
        courseId={courseIdFromLocation}
      />
    </Space>
  );
}
