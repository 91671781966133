import { gql } from '@apollo/client';

const ACCOUNT_INFO = gql`
  fragment AccountInfo on Account {
    email
    id
    firstName
    lastName
    suffix
    commentingAlias
  }
`;

const CHAPTER_INFO = gql`
  fragment ChapterInfo on Chapter {
    urlSlug
    title
    id
    episodeChapterBindingsList {
      episode {
        title
        urlSlug
        id
      }
    }
  }
`;

const LECTURE_INFO = gql`
  fragment LectureInfo on Lecture {
    urlSlug
    topic {
      urlSlug
      id
    }
    title
    id
  }
`;

const COMMENT_FIELDS = gql`
  fragment CommentFields on Comment {
    id
    bodyText
    parentId
    createdAt
    status
    facultyAccountId
    account {
      ...AccountInfo
    }
    chapter {
      ...ChapterInfo
    }
    lecture {
      ...LectureInfo
    }
  }
  ${ACCOUNT_INFO}
  ${CHAPTER_INFO}
  ${LECTURE_INFO}
`;

const GET_COURSE_COMMENTS_QUERY = gql`
  ${ACCOUNT_INFO}
  ${CHAPTER_INFO}
  ${LECTURE_INFO}
  ${COMMENT_FIELDS}
  query getCourseCommentsQuery($courseIdForQuery: UUID!) {
    commentsList(
      orderBy: CREATED_AT_DESC
      filter: {
        courseId: { equalTo: $courseIdForQuery }
        parentId: { isNull: true }
        status: { notEqualTo: UNPUBLISHED }
      }
    ) {
      ...CommentFields
      commentsByParentIdList(filter: { status: { notEqualTo: UNPUBLISHED } }) {
        ...CommentFields
        commentsByParentIdList(
          filter: { status: { notEqualTo: UNPUBLISHED } }
        ) {
          ...CommentFields
          commentsByParentIdList(
            filter: { status: { notEqualTo: UNPUBLISHED } }
          ) {
            ...CommentFields
            commentsByParentIdList(
              filter: { status: { notEqualTo: UNPUBLISHED } }
            ) {
              ...CommentFields
            }
          }
        }
      }
    }
    course(id: $courseIdForQuery) {
      title
      shortname
    }
    facultiesList(filter: { accountId: { isNull: false } }) {
      accountId
    }
  }
`;

export default GET_COURSE_COMMENTS_QUERY;
