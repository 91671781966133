import React from 'react';
import { Link } from 'react-router-dom';

import '@ant-design/compatible/assets/index.css';

import { Breadcrumb } from 'antd';

import AccountSearch from './AccountSearch';

const AccountSearchPage = () => {
  return (
    <div>
      <Breadcrumb tag="nav" style={{ padding: '20px 0' }}>
        <Breadcrumb.Item>
          <Link to="/dashboard">Hippo Admin</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/account/search">Account Search</Link>
        </Breadcrumb.Item>
      </Breadcrumb>

      <AccountSearch
        includeDelete={true}
        selectLink={true}
        selectButton={() => null}
        disabledIds={[]}
      />
    </div>
  );
};

export default AccountSearchPage;
