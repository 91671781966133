import { gql } from '@apollo/client';

const UPDATE_ITEM_PARENT_MUTATION = gql`
  mutation UpdateItemParent(
    $id: UUID!
    $parentId: UUID
    $updatedAt: Datetime!
  ) {
    updateItem(
      input: { patch: { parentId: $parentId, updatedAt: $updatedAt }, id: $id }
    ) {
      parent {
        id
        sku
        title
      }
    }
  }
`;

const UPDATE_ITEM_MUTATION = gql`
  mutation UpdateItem(
    $addOnText: String
    $allowArb: Boolean
    $braintreePlanId: String
    $billingPeriod: Int
    $category: EnumItemsCategory
    $descriptionText: String
    $endedAt: Datetime
    $freeTrialDuration: Int
    $hasFreeTrial: Boolean
    $id: UUID!
    $isAddOn: Boolean
    $isRequiredAddon: Boolean
    $allowStandalone: Boolean
    $parentId: UUID
    $sku: String
    $startedAt: Datetime
    $subText: String
    $subscriptionTitle: String
    $title: String
    $updatedAt: Datetime
  ) {
    updateItem(
      input: {
        patch: {
          addOnText: $addOnText
          allowArb: $allowArb
          braintreePlanId: $braintreePlanId
          billingPeriod: $billingPeriod
          category: $category
          descriptionText: $descriptionText
          endedAt: $endedAt
          freeTrialDuration: $freeTrialDuration
          hasFreeTrial: $hasFreeTrial
          isAddOn: $isAddOn
          isRequiredAddon: $isRequiredAddon
          allowStandalone: $allowStandalone
          parentId: $parentId
          sku: $sku
          startedAt: $startedAt
          subText: $subText
          subscriptionTitle: $subscriptionTitle
          title: $title
          updatedAt: $updatedAt
        }
        id: $id
      }
    ) {
      item {
        addOnText
        allowArb
        billingPeriod
        braintreePlanId
        category
        descriptionText
        endedAt
        freeTrialDuration
        hasFreeTrial
        id
        isAddOn
        isRequiredAddon
        allowStandalone
        sku
        startedAt
        title
      }
    }
  }
`;

export { UPDATE_ITEM_PARENT_MUTATION, UPDATE_ITEM_MUTATION };
