import { gql } from '@apollo/client';

export const CREATE_ATTACHMENT_SECTION = gql`
  mutation CreateAttachmentSection(
    $id: UUID!
    $title: String!
    $displayOrder: Int!
    $createdAt: Datetime!
    $updatedAt: Datetime!
  ) {
    createAttachmentSection(
      input: {
        attachmentSection: {
          id: $id
          title: $title
          displayOrder: $displayOrder
          createdAt: $createdAt
          updatedAt: $updatedAt
        }
      }
    ) {
      clientMutationId
      attachmentSection {
        id
        title
        displayOrder
      }
    }
  }
`;

export const UPDATE_ATTACHMENT_SECTION = gql`
  mutation UpdateAttachmentSection(
    $id: UUID!
    $title: String
    $displayOrder: Int
  ) {
    updateAttachmentSection(
      input: { patch: { title: $title, displayOrder: $displayOrder }, id: $id }
    ) {
      attachmentSection {
        id
        title
        displayOrder
      }
    }
  }
`;

export const DELETE_ATTACHMENT_SECTION = gql`
  mutation DeleteAttachmentSection($id: UUID!) {
    deleteAttachmentSection(input: { id: $id }) {
      clientMutationId
    }
  }
`;
