import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { notify } from 'react-notify-toast';
import { FormGroup, Label, Input } from 'reactstrap';

import { getDecodedTokenFromCookie } from '../../utils/cookie';
import { getBindingAPIFromType } from '../../utils/note';
import * as API from '../../API';

import './AddNote.css';

const getBindingRelationshipsFromType = (
  bindingType,
  bindingId,
  noteId,
  relationshipId
) => {
  let bindingRelationship;
  switch (bindingType) {
    case 'account':
      bindingRelationship = {
        account: { data: { id: relationshipId, type: 'account' } },
        note: { data: { id: noteId, type: 'note' } }
      };
      break;
    case 'program':
      bindingRelationship = {
        program: { data: { id: relationshipId, type: 'program' } },
        note: { data: { id: noteId, type: 'note' } }
      };
      break;
    case 'organization':
      bindingRelationship = {
        note: { data: { id: noteId, type: 'note' } }
      };
      break;

    default:
      break;
  }
  return bindingRelationship;
};

export const AddNote = ({ type, id, setFetchNotes }) => {
  const handleKeyDownLabel = async event => {
    if (event.key === 'Enter') {
      event.persist();
      const token = getDecodedTokenFromCookie();
      const noteId = uuidv4();
      const bindingId = uuidv4();
      const bindingAPI = getBindingAPIFromType(type);
      const relationships = getBindingRelationshipsFromType(
        type,
        bindingId,
        noteId,
        id
      );

      try {
        await API.note.create({
          attributes: {
            id: noteId,
            bodyText: event.target.value
          },
          relationships: {
            author: { data: { id: token.account, type: 'account' } }
          }
        });

        const attributes =
          type === 'organization'
            ? {
                OrganizationId: id,
                id: bindingId
              }
            : {
                id: bindingId
              };

        await bindingAPI.create({
          attributes,
          relationships
        });

        event.target.value = '';
        setFetchNotes(true);
      } catch (error) {
        if (error.data) {
          notify.show(error.data.errors[0].detail[0].message, 'error');
        } else {
          throw error;
        }
      }
    }
  };

  return (
    <div className="add-note__content">
      <FormGroup>
        <Label className="add-note__label">
          <Input
            className="add-note__input"
            placeholder="Add Comment"
            onKeyDown={handleKeyDownLabel}
            type="note"
            name="note"
          />
        </Label>
      </FormGroup>
    </div>
  );
};
