import axios from 'axios';

import { getTokenFromCookie } from './cookie';

// Uploads a file to AWS and returns the url

/**
 * @param {string} key
 * @param {Blob} file
 *
 * @return {Promise<string>} uploaded file key.
 */
export default async function uploadToS3(
  key,
  file,
  progressCallback,
  transcode
) {
  const S3Bucket = transcode
    ? process.env.REACT_APP_PROCESSING_S3_BUCKET
    : process.env.REACT_APP_S3_BUCKET;

  // Split key into file name and folder name as required by our URL signing
  // API.
  const parts = key.split('/');
  const filename = parts.length > 1 ? parts.slice(-1) : key;
  const folder = parts.length > 1 ? `${parts.slice(0, -1).join('/')}/` : '/';

  // Get signed URL to upload data.
  const oauthResponse = await fetch(
    `${process.env.REACT_APP_JSONAPI_SERVER}/api/s3/get-signed-url`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromCookie()}`
      },
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify({
        filename,
        filetype: file.type,
        folder,
        bucket: S3Bucket
      })
    }
  );

  const oauthStatus = oauthResponse.status;

  if (oauthStatus !== 200) {
    const errorData = await oauthResponse.json();
    return this.props.onError(errorData.detail);
  }

  const signedUrl = await oauthResponse.text();

  // Upload the data to S3.
  const options = {
    headers: {
      'Content-Type': file.type
    },
    onUploadProgress: progressCallback || undefined
  };

  return axios.put(signedUrl, file, options);
}
