import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { notify } from 'react-notify-toast';

import { UserOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Input, Spin, Table } from 'antd';

import { getTokenFromCookie } from '../utils/cookie';
import UploadCSVModal from './groups/UploadCSVModal';
import UserAddModal from './account/UserAddModal';

const AccountSearch = ({
  includeDelete,
  selectLink,
  selectButton,
  disabledIds
}) => {
  const history = useHistory();
  const location = useLocation();

  const [firstName, setFirstName] = useState(
    selectLink ? location.state?.firstName : null
  );
  const [lastName, setLastName] = useState(
    selectLink ? location.state?.lastName : null
  );
  const [email, setEmail] = useState(selectLink ? location.state?.email : null);
  const [error, setError] = useState('');
  const [accountSearchResults, setAccountSearchResults] = useState('');
  const [hasSearched, setHasSearched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showUserAddModal, setShowUserAddModal] = useState(false);

  const searchAccounts = useCallback(
    async e => {
      e && e.preventDefault();

      if (!firstName && !lastName && !email) {
        return setError('You must enter search information first.');
      }

      setLoading(true);

      const query = {};
      if (firstName) {
        query.firstName = window.encodeURIComponent(firstName);
      }
      if (lastName) {
        query.lastName = window.encodeURIComponent(lastName);
      }
      if (email) {
        query.email = window.encodeURIComponent(email);
      }
      const url = urlWithQuery(
        `${process.env.REACT_APP_JSONAPI_SERVER}/api/account`,
        query
      );

      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getTokenFromCookie()}`
        },
        credentials: 'same-origin'
      });

      const accountSearchResults = (await response.json()).accounts;
      setAccountSearchResults(accountSearchResults);

      setHasSearched(true);
      setLoading(false);
    },
    [firstName, lastName, email]
  );

  useEffect(() => {
    if (location.state?.hasSearched) {
      setHasSearched(true);
      searchAccounts();
    }
  }, [location.state, searchAccounts]);

  const deleteAccount = async account => {
    try {
      if (
        !window.confirm(
          `Are you sure you want to delete account for ${account.firstName} ${account.lastName}? Please verify email: ${account.email}?`
        )
      ) {
        return;
      }
      setAccountSearchResults(
        accountSearchResults.filter(
          accountResult => accountResult.id !== account.id
        )
      );

      fetch(
        `${process.env.REACT_APP_JSONAPI_SERVER}/api/account?email=${account.email}&userId=${account.id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getTokenFromCookie()}`
          }
        }
      ).then(response => {
        if (!response.ok) {
          notify.show(
            `Error. Delete unsuccessful. Error type: ${response.statusText}`,
            error
          );
          return;
        }
        response.json().then(res => {
          notify.show(
            `${res.hippoDeleteMessage}  ${res.hubspotDeleteMessage}`,
            'success'
          );
        });
      });
    } catch (e) {
      notify.show(e.message, error);
    }
  };

  const tableColumns = [
    {
      title: 'First name',
      dataIndex: 'firstName',
      key: 'firstName'
    },
    {
      title: 'Last name',
      dataIndex: 'lastName',
      key: 'lastName'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Select',
      key: 'select',
      render: (text, record) =>
        selectLink ? (
          <Link
            to={{
              pathname: `/account/${record.id}`,
              state: {
                firstName,
                lastName,
                email,
                hasSearched
              }
            }}
          >
            Select
          </Link>
        ) : disabledIds.includes(record.id) ? (
          'Current Account'
        ) : (
          <Button
            type="ghost"
            onClick={async e => {
              selectButton(record);
            }}
          >
            Select
          </Button>
        )
    }
  ];

  if (includeDelete) {
    tableColumns.push({
      title: '',
      key: 'deleteAccount',
      render: (text, record) => (
        <Button
          type="danger"
          icon={<DeleteOutlined />}
          onClick={async e => {
            deleteAccount(record);
          }}
        />
      )
    });
  }

  const MINIMUM_LENGTH_FOR_PARTIAL_SEARCH = 3;

  const urlWithQuery = (url, params) => {
    let newUrl = url;

    Object.keys(params).forEach((key, index) => {
      // Add percent signs because API uses ILIKE and we want partial matches.
      // Only do a partial match if the query value length is equal to or greater than MINIMUM_LENGTH_FOR_PARTIAL_SEARCH.
      // This page isn't paginated and we don't want to overload the DB or admin client.
      const encodedPercentSign = encodeURIComponent('%');
      const value =
        params[key].length >= MINIMUM_LENGTH_FOR_PARTIAL_SEARCH
          ? `${encodedPercentSign}${params[key]}${encodedPercentSign}`
          : params[key];

      if (index === 0) {
        newUrl += `?${key}=${value}`;
      } else {
        newUrl += `&${key}=${value}`;
      }
    });

    return newUrl;
  };

  return (
    <div>
      <Form layout="inline">
        <Form.Item>
          <Input
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
            onPressEnter={searchAccounts}
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="First name"
          />
        </Form.Item>
        <Form.Item>
          <Input
            value={lastName}
            onChange={e => setLastName(e.target.value)}
            onPressEnter={searchAccounts}
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Last name"
          />
        </Form.Item>
        <Form.Item>
          <Input
            value={email}
            onChange={e => setEmail(e.target.value.toLowerCase().trim())}
            onPressEnter={searchAccounts}
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Email"
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="button" onClick={searchAccounts}>
            Submit
          </Button>
        </Form.Item>

        <Form.Item>
          <UploadCSVModal group={null} />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="button"
            onClick={() => setShowUserAddModal(true)}
          >
            Add New User
          </Button>
        </Form.Item>
      </Form>

      <hr />

      {hasSearched && !loading ? (
        <Table
          style={{ backgroundColor: '#fff' }}
          dataSource={accountSearchResults}
          columns={tableColumns}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'] }}
          rowKey="id"
          onRow={
            selectLink
              ? (record, rowIndex) => {
                  return {
                    onClick: event => {
                      history.push(`/account/${record.id}`, {
                        firstName,
                        lastName,
                        email,
                        hasSearched
                      });
                    }
                  };
                }
              : null
          }
        />
      ) : null}
      {loading && <Spin />}

      {showUserAddModal && (
        <UserAddModal
          group={null}
          isAdmin={false}
          modalVisible={showUserAddModal}
          setModalVisible={visible => {
            setShowUserAddModal(visible);
          }}
          onSuccess={newUser => {
            history.push(`/account/${newUser.data.id}`);
          }}
        />
      )}
    </div>
  );
};

export default AccountSearch;
