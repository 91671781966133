import { gql } from '@apollo/client';

const UPDATE_IMAGE_ID_ORGANIZATION_MUTATION = gql`
  mutation updateImageId($id: UUID!, $imageId: UUID) {
    updateOrganization(input: { patch: { imageId: $imageId }, id: $id }) {
      clientMutationId
    }
  }
`;

export default UPDATE_IMAGE_ID_ORGANIZATION_MUTATION;
