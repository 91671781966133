import { gql } from '@apollo/client';

const GET_ITEMS_LIST_QUERY = gql`
  query getItemsList {
    itemsList(orderBy: TITLE_ASC) {
      id
      title
      startedAt
      endedAt
      sku
      parentId
      braintreePlanId
    }
  }
`;

const GET_CART_ITEMS_LIST_QUERY = gql`
  query getItemsForAdmin {
    itemsForCreateOrder {
      id
      startedAt
      endedAt
      courseId
      parentId
      sku
      title
      description
      prices {
        id
        price
      }
      productId
      subscriptionTemplates {
        isCMEArchiveEnabled
        id
      }
    }
  }
`;

export { GET_ITEMS_LIST_QUERY, GET_CART_ITEMS_LIST_QUERY };
