import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Table } from 'antd';

import './AssessmentsView.css';

const { Column } = Table;

export default function AssessmentsTableView({ assessments, refresh }) {
  const history = useHistory();

  return (
    <Table
      pagination={false}
      dataSource={assessments}
      className="assessments-list__table"
      rowKey="id"
      onRow={record => ({
        onClick: event => {
          // don't open assessment editor when clicking expand button
          if (event.target.type !== 'button') {
            history.push('/content/assessments/');
            history.replace(`/content/assessments/${record.id}`);
          }
        }
      })}
      expandable={false}
    >
      <Column
        title="Product"
        key="product"
        width={200}
        align="center"
        render={assessment => <div>{assessment.course.title}</div>}
      />
      <Column title="Title" dataIndex="title" />
      <Column
        title="Live Date"
        key="releaseDate"
        render={assessment => (
          <div>
            {assessment.releaseDate
              ? moment(assessment.releaseDate)
                  .tz(process.env.REACT_APP_DISPLAY_TIMEZONE)
                  .format('MMM D, YYYY')
              : 'N/A'}
          </div>
        )}
      />
      <Column
        title="CME"
        key="cmeValue"
        render={assessment =>
          assessment.cmeValue ? `${assessment.cmeValue} AMA Credit` : 'N/A'
        }
      />
      <Column
        title="No. of Questions"
        key="noQuestions"
        render={assessment =>
          `${assessment.assessmentQuestionBindings.length} questions`
        }
      />
    </Table>
  );
}
