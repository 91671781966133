import React from 'react';
import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';
import { Tooltip, Tag, Input } from 'antd';

import './ListEditor.css';

export default class ListEditor extends React.Component {
  static propTypes = {
    list: PropTypes.array,
    title: PropTypes.string,
    handleListChange: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      inputValue: null,
      inputVisible: false,
      list: null
    };
  }

  componentDidMount() {
    const { list } = this.props;
    this.setState({ list });
  }

  handleListChange = list => {
    this.props.handleListChange(list);
    this.setState({ list });
  };

  handleListRemoval = removedItem => {
    const list = this.state.list.filter(item => item !== removedItem);
    this.handleListChange(list);
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = e => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    const { inputValue } = this.state;
    let { list } = this.state;
    if (inputValue) {
      if (list && list.indexOf(inputValue) === -1) {
        list = [...list, inputValue];
      } else if (!list) {
        list = [];
        list.push(inputValue);
      }
    }
    this.handleListChange(list);
    this.setState({ inputValue: null, inputVisible: false });
  };

  saveInputRef = input => (this.input = input);

  render() {
    const { title } = this.props;
    const { list, inputValue, inputVisible } = this.state;
    return (
      <div className="list-editor">
        <div className="list-editor__display">
          {list
            ? list.map((item, index) => (
                <Tooltip title={item} key={item}>
                  <Tag
                    key={index}
                    closable={true}
                    onClose={() => this.handleListRemoval(item)}
                    className="list-editor__display-item"
                  >
                    <span className="list-editor_display-item__inner-label">
                      {item}
                    </span>
                  </Tag>
                </Tooltip>
              ))
            : null}
        </div>
        <div className="list-editor__input">
          {inputVisible && (
            <Input
              ref={this.saveInputRef}
              type="text"
              size="small"
              value={inputValue}
              onChange={this.handleInputChange}
              onBlur={this.handleInputConfirm}
              onPressEnter={this.handleInputConfirm}
            />
          )}
          {!inputVisible && (
            <Tag onClick={this.showInput} className="list-editor__input-tag">
              <PlusOutlined /> Add {title}
            </Tag>
          )}
        </div>
      </div>
    );
  }
}
