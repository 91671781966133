import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Modal, Spin, Button, Typography } from 'antd';
import { Label } from 'reactstrap';

import AccountSearch from '../../containers/AccountSearch';
import { mergeAccounts, getMergeData } from '../../utils/merge';

import * as API from '../../API';

import './AccountMergeModal.css';

const { Text, Title, Paragraph } = Typography;

export default function AccountMergeModal({
  accountId,
  showModal,
  setShowModal
}) {
  const [sourceAccount, setSourceAccount] = useState(null);
  const [account, setAccount] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [targetAccount, setTargetAccount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(1);
  const [accountMergeDetailsResults, setAccountMergeDetailsResults] = useState(
    null
  );
  const [mergeComplete, setMergeComplete] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (accountId && !account && showModal) {
      const getAccount = async () => {
        try {
          const account = API.simplifyResource(
            await API.account.find({
              id: accountId
            })
          );
          setAccount(account);
        } catch (e) {
          setError(e);
        }
      };
      setError(null);
      getAccount();
      setLoading(false);
    }
  }, [accountId, account, showModal]);

  const getAccountMergeDetails = async selectedAccountId => {
    setLoading(true);
    const results = await getMergeData(accountId, selectedAccountId);
    if (results && results.error) {
      setError(results.error);
    } else if (results && results.data) {
      setError(null);
      setAccountMergeDetailsResults(results.data);
    }
    setLoading(false);
  };

  const sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  const attemptMerge = async (sourceId, targetId, retries = 2) => {
    let results;
    for (let i = 0; i < retries; i++) {
      results = await mergeAccounts(sourceId, targetId);
      if (results && !results.error) {
        break;
      }
      // it failed for timeout code, reattempt
      if (i < retries - 1 && results.status === 504) {
        await sleep(500);
      }
    }
    return results;
  };

  const mergeSourceAndTargetAccounts = async () => {
    setLoading(true);
    const results = await attemptMerge(sourceAccount.id, targetAccount.id);

    if (results && results.error) {
      setError(results.error);
    } else if (results && results.data) {
      setError(null);
      setMergeComplete(true);
      setStep(4);
    }

    setLoading(false);
  };

  const closeModal = () => {
    // Reset fields
    setSourceAccount(null);
    setSelectedAccount(null);
    setTargetAccount(null);
    setStep(1);
    setAccountMergeDetailsResults(null);
    setMergeComplete(null);

    setShowModal(false);
  };

  const handleOkay = async () => {
    closeModal();
  };

  const handleCancel = () => {
    closeModal();
  };

  return (
    <>
      <Modal
        title="Merge Account"
        visible={showModal}
        width={1000}
        okText="Okay"
        onOk={handleOkay}
        onCancel={handleCancel}
        okButtonProps={{
          disabled: !mergeComplete
        }}
      >
        {loading && (
          <div className="account-merge-modal__loading-spinner">
            <Spin />
          </div>
        )}

        {/* Search for Accounts Step */}
        {!loading && step === 1 && account && (
          <>
            <Title level={4}>Current Account:</Title>
            <Paragraph>
              <Text>
                Name: {account.firstName} {account.lastName}
                {account.suffix ? `, ${account.suffix}` : ''}
              </Text>
              <br />
              <Text>Email: {account.email}</Text>
              <br />
              <Text>
                Created On:{' '}
                {moment(account.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
              </Text>
            </Paragraph>

            <Title level={4}>Find Account to Merge:</Title>
            <AccountSearch
              includeDelete={false}
              selectLink={false}
              selectButton={async record => {
                setSelectedAccount(record);
                getAccountMergeDetails(record.id);
                setStep(2);
              }}
              disabledIds={[account.id]}
            />
          </>
        )}

        {/* Merge Confirmation Step */}
        {!loading && step === 2 && accountMergeDetailsResults && (
          <>
            <Title level={4}>Compare accounts</Title>
            <div className="account-merge-modal__compare-accounts-container">
              <div>
                <Label>Account 1:</Label>
                <Paragraph>
                  <Text>
                    Name:{' '}
                    {accountMergeDetailsResults.sourceAccount.account.firstName}{' '}
                    {accountMergeDetailsResults.sourceAccount.account.lastName}
                    {accountMergeDetailsResults.sourceAccount.account.suffix
                      ? `, ${accountMergeDetailsResults.sourceAccount.account.suffix}`
                      : ''}
                  </Text>
                  <br />
                  <Text>
                    Email:{' '}
                    {accountMergeDetailsResults.sourceAccount.account.email}
                  </Text>
                  <br />
                  <Text>
                    Created On:{' '}
                    {moment(
                      accountMergeDetailsResults.sourceAccount.account.createdAt
                    ).format('MMMM Do YYYY, h:mm:ss a')}
                  </Text>
                </Paragraph>
                <hr />
                <Paragraph>
                  <Text>
                    Assessment Attempts:{' '}
                    {
                      accountMergeDetailsResults.sourceAccount
                        .assessmentAttempts
                    }
                  </Text>
                  <br />
                  <Text>
                    Bookmarks:{' '}
                    {accountMergeDetailsResults.sourceAccount.bookmarks}
                  </Text>
                  <br />
                  <Text>
                    CME Activities:{' '}
                    {accountMergeDetailsResults.sourceAccount.cmeActivities}
                  </Text>
                  <br />
                  <Text>
                    Comments:{' '}
                    {accountMergeDetailsResults.sourceAccount.comments}
                  </Text>
                  <br />
                  <Text>
                    Notes: {accountMergeDetailsResults.sourceAccount.notes}
                  </Text>
                  <br />
                  <Text>
                    Orders: {accountMergeDetailsResults.sourceAccount.orders}
                  </Text>
                  <br />
                  <Text>
                    Podcast Listens:{' '}
                    {accountMergeDetailsResults.sourceAccount.podcastListens}
                  </Text>
                  <br />
                  <Text>
                    Questions Answered:{' '}
                    {accountMergeDetailsResults.sourceAccount.questionsAnswered}
                  </Text>
                  <br />
                  <Text>
                    Ratings: {accountMergeDetailsResults.sourceAccount.ratings}
                  </Text>
                  <br />
                  <Text>
                    Subscriptions:{' '}
                    {accountMergeDetailsResults.sourceAccount.subscriptions}
                  </Text>
                  <br />
                  <Text>
                    Video Watches:{' '}
                    {accountMergeDetailsResults.sourceAccount.videoWatches}
                  </Text>
                </Paragraph>

                <Button
                  onClick={() => {
                    setSourceAccount(selectedAccount);
                    setTargetAccount(account);
                    setStep(3);
                  }}
                >
                  Merge and keep{' '}
                  {accountMergeDetailsResults.sourceAccount.account.email}
                </Button>
              </div>

              <div>
                <Label>Account 2:</Label>
                <Paragraph>
                  <Text>
                    Name:{' '}
                    {accountMergeDetailsResults.targetAccount.account.firstName}{' '}
                    {accountMergeDetailsResults.targetAccount.account.lastName}
                    {accountMergeDetailsResults.targetAccount.account.suffix
                      ? `, ${accountMergeDetailsResults.targetAccount.account.suffix}`
                      : ''}
                  </Text>
                  <br />
                  <Text>
                    Email:{' '}
                    {accountMergeDetailsResults.targetAccount.account.email}
                  </Text>
                  <br />
                  <Text>
                    Created On:{' '}
                    {moment(
                      accountMergeDetailsResults.targetAccount.account.createdAt
                    ).format('MMMM Do YYYY, h:mm:ss a')}
                  </Text>
                </Paragraph>
                <hr />
                <Paragraph>
                  <Text>
                    Assessment Attempts:{' '}
                    {
                      accountMergeDetailsResults.targetAccount
                        .assessmentAttempts
                    }
                  </Text>
                  <br />
                  <Text>
                    Bookmarks:{' '}
                    {accountMergeDetailsResults.targetAccount.bookmarks}
                  </Text>
                  <br />
                  <Text>
                    CME Activities:{' '}
                    {accountMergeDetailsResults.targetAccount.cmeActivities}
                  </Text>
                  <br />
                  <Text>
                    Comments:{' '}
                    {accountMergeDetailsResults.targetAccount.comments}
                  </Text>
                  <br />
                  <Text>
                    Notes: {accountMergeDetailsResults.targetAccount.notes}
                  </Text>
                  <br />
                  <Text>
                    Orders: {accountMergeDetailsResults.targetAccount.orders}
                  </Text>
                  <br />
                  <Text>
                    Podcast Listens:{' '}
                    {accountMergeDetailsResults.targetAccount.podcastListens}
                  </Text>
                  <br />
                  <Text>
                    Questions Answered:{' '}
                    {accountMergeDetailsResults.targetAccount.questionsAnswered}
                  </Text>
                  <br />
                  <Text>
                    Ratings: {accountMergeDetailsResults.targetAccount.ratings}
                  </Text>
                  <br />
                  <Text>
                    Subscriptions:{' '}
                    {accountMergeDetailsResults.targetAccount.subscriptions}
                  </Text>
                  <br />
                  <Text>
                    Video Watches:{' '}
                    {accountMergeDetailsResults.targetAccount.videoWatches}
                  </Text>
                </Paragraph>

                <Button
                  onClick={() => {
                    setSourceAccount(account);
                    setTargetAccount(selectedAccount);
                    setStep(3);
                  }}
                >
                  Merge and keep{' '}
                  {accountMergeDetailsResults.targetAccount.account.email}
                </Button>
              </div>
            </div>
          </>
        )}

        {!loading && step === 3 && (
          <>
            <Title level={4}>Are you sure?</Title>
            <Text>
              This will transfer all items from {sourceAccount.email} to{' '}
              {targetAccount.email} and deactivate the unused account.
            </Text>
            <br />
            <Button onClick={async () => await mergeSourceAndTargetAccounts()}>
              Merge Accounts
            </Button>
          </>
        )}

        {!loading && step === 4 && (
          <>
            <Title level={4}>Success!</Title>
            <Text>
              Congratulations! {sourceAccount.email} has been successfully
              merged into {targetAccount.email}.
            </Text>
            <br />
          </>
        )}

        {error && (
          <>
            <Title level={4}>An error occurred!</Title>
            <Text>Error Message: {error}</Text>
            <br />
            <Text>Time: {moment().format('MMMM Do YYYY, h:mm:ss a')}</Text>
            <br />
            <Text>
              Please contact the tech team with the error message above so that
              we can help resolve it!
            </Text>
          </>
        )}
      </Modal>
    </>
  );
}
