import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import 'bootstrap/dist/css/bootstrap.css';
import 'antd/dist/antd.min.css';

import './index.css';
import App from './App';
import { ErrorBoundary } from './ErrorBoundary';
import { GraphQLClientProvider } from './utils/graphQLApolloClientProvider';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV
  });
}

ReactDOM.render(
  <BrowserRouter basename={process.env.REACT_APP_BASE_URL}>
    <ErrorBoundary>
      <GraphQLClientProvider>
        <App />
      </GraphQLClientProvider>
    </ErrorBoundary>
  </BrowserRouter>,
  document.getElementById('root')
);
