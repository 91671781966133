import { useMutation } from '@apollo/client';
import { UPDATE_ATTACHMENT, DELETE_ATTACHMENT } from '../mutations/Attachments';
import { useCallback } from 'react';

// Utility function to sanitize file names
const sanitizeFileName = fileName => {
  return fileName.replace(/[^A-Z0-9.-]+/gi, '_');
};

export const useAttachmentMutator = () => {
  const [updateAttachmentMutation] = useMutation(UPDATE_ATTACHMENT);
  const [deleteAttachmentMutation] = useMutation(DELETE_ATTACHMENT);

  const replaceFileOnAttachment = useCallback(
    async (fileData, item, onUpdate) => {
      const { key, name, size, type } = fileData[0].originFileObj;
      const attributes = {
        key,
        fileName: sanitizeFileName(name),
        fileSize: size,
        mimeType: type
      };

      await updateAttachmentMutation({
        variables: {
          id: item.id,
          ...attributes
        }
      });

      if (onUpdate) {
        onUpdate(item);
      }
    },
    [updateAttachmentMutation]
  );

  const updateAttachment = useCallback(
    async patch => {
      await updateAttachmentMutation(patch);
    },
    [updateAttachmentMutation]
  );

  const deleteAttachment = useCallback(
    async deleteVariable => {
      await deleteAttachmentMutation(deleteVariable);
    },
    [deleteAttachmentMutation]
  );

  return {
    replaceFileOnAttachment,
    updateAttachment,
    deleteAttachment
  };
};

export const useFileUpload = (setFileData, additionalData = {}) => {
  const handleFileUpload = useCallback(
    uploadedFileData => {
      const { key, name, size, type } = uploadedFileData[0].originFileObj;
      setFileData({
        key,
        fileName: sanitizeFileName(name),
        fileSize: size,
        mimeType: type,
        ...additionalData
      });
    },
    [setFileData, additionalData]
  );

  return handleFileUpload;
};
