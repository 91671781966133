import * as React from 'react';
const SvgOrganizationIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g opacity={0.75} fill="none">
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.3}
        d="M6 1h9v14H6V1Z"
      />
      <path
        fill="#000"
        d="M8 5h2V3H8v2Zm0 3h2V6H8v2Zm0 3h2V9H8v2Zm3-6h2V3h-2v2Zm0 3h2V6h-2v2Zm0 3h2V9h-2v2Z"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.3}
        d="M6 5H3v10h3V5Z"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth={1.3}
        d="M9 13h3v2H9v-2Zm8 2H1"
      />
    </g>
  </svg>
);
export default SvgOrganizationIcon;
