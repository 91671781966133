import React, { useEffect, useState } from 'react';
import { CourseTypeTitles } from '../../constants';
import { Select } from 'antd';
import { useQuery } from '@apollo/client';
import { GET_COURSE_TYPES_QUERY } from './queries/CourseTypes';

export const CourseTypeSelect = ({ handleCourseTypeChange }) => {
  const [courseTypeOptions, setCourseTypeOptions] = useState([]);
  const { data } = useQuery(GET_COURSE_TYPES_QUERY);

  useEffect(() => {
    if (data && data.courseTypesList) {
      const courseTypes = data?.courseTypesList
        .filter(courseType => courseType.title !== CourseTypeTitles.OTHER)
        .map(courseType => {
          const disabledCourseTypes = [
            CourseTypeTitles.ASSESSMENT,
            CourseTypeTitles.QBANK,
            CourseTypeTitles.PRACTICE_EXAM,
            CourseTypeTitles.REFERENCE_GUIDE
          ];
          return {
            disabled: disabledCourseTypes.includes(courseType.title),
            value: courseType.title,
            label: courseType.title
          };
        })
        .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));

      setCourseTypeOptions(courseTypes);
    }
  }, [data, setCourseTypeOptions]);

  return (
    <Select
      onChange={handleCourseTypeChange}
      style={{
        width: 320
      }}
      options={courseTypeOptions}
    />
  );
};
