import React from 'react';
import { Route } from 'react-router-dom';

import Organizations from '../containers/organizations/Organizations';
import Organization from '../containers/organizations/Organization';

const OrganizationRoutes = () => {
  return (
    <>
      <Route
        exact={true}
        path="/group-management/organizations"
        component={Organizations}
      />
      <Route
        exact={true}
        path="/group-management/organization/:id"
        component={Organization}
      />
    </>
  );
};

export default OrganizationRoutes;
