import React, { useRef, useEffect } from 'react';
import { Button, Modal, Input } from 'antd';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import ResourceLibraryAttachmentSectionView from './ResourceLibraryAttachmentSectionView';
import SectionHeader from './ResourceLibrarySectionHeaderView';
import './ResourceLibraryTableView.css';

const ResourceLibraryTableView = ({
  allSections,
  expandedSections,
  attachmentInputVisible,
  loading,
  showNewSectionModal,
  newSectionTitle,
  setNewSectionTitle,
  setShowNewSectionModal,
  handleNewSection,
  handleToggleSection,
  handleToggleAttachmentInput,
  handleDeleteSection,
  handleCreateAttachment,
  handleUpdateAttachment,
  handleUpdateAttachmentSection,
  handleDeleteAttachment,
  handleDateChange,
  handleCheckboxChange,
  replaceFileOnAttachment,
  reorderAttachments,
  reorderSections
}) => {
  const onDragEnd = event => {
    if (event.type === 'section') {
      reorderSections(event);
    } else if (event.type === 'attachment') {
      reorderAttachments(event);
    }
  };

  const inputRef = useRef(null);

  useEffect(() => {
    if (showNewSectionModal && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showNewSectionModal]);

  const openModal = () => {
    setShowNewSectionModal(true);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  };

  const closeModal = () => {
    setShowNewSectionModal(false);
    setNewSectionTitle('');
  };

  return (
    <div className="section-view table">
      <div className="header-row">
        <h5 className="header">Resource Library</h5>
      </div>
      <SectionHeader loading={loading} />
      {allSections.length === 0 && <div>No data</div>}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="sectionsDroppable" type="section">
          {(providedDrop, snapshotDrop) => (
            <div ref={providedDrop.innerRef} {...providedDrop.droppableProps}>
              {allSections.map((section, index) => (
                <ResourceLibraryAttachmentSectionView
                  key={section.id}
                  section={section}
                  index={index}
                  expandedSections={expandedSections}
                  loading={loading}
                  handleToggleSection={handleToggleSection}
                  handleUpdateAttachmentSection={handleUpdateAttachmentSection}
                  handleDeleteSection={handleDeleteSection}
                  handleToggleAttachmentInput={handleToggleAttachmentInput}
                  handleCreateAttachment={handleCreateAttachment}
                  handleUpdateAttachment={handleUpdateAttachment}
                  handleDateChange={handleDateChange}
                  handleCheckboxChange={handleCheckboxChange}
                  handleDeleteAttachment={handleDeleteAttachment}
                  replaceFileOnAttachment={replaceFileOnAttachment}
                  attachmentInputVisible={attachmentInputVisible}
                />
              ))}
              {providedDrop.placeholder}
              <div className="section-footer">
                <div className="column-span-small">
                  <Button
                    type="primary"
                    style={{ lineHeight: '100%' }}
                    onClick={openModal}
                  >
                    New Section
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Modal
        title="Add New Section"
        visible={showNewSectionModal}
        onCancel={closeModal}
        onOk={handleNewSection}
      >
        <Input
          ref={inputRef}
          value={newSectionTitle}
          onChange={e => setNewSectionTitle(e.target.value)}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              handleNewSection();
            }
          }}
          placeholder="Section Title"
          autoFocus={true}
        />
      </Modal>
    </div>
  );
};

export default ResourceLibraryTableView;
