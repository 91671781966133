import _ from 'underscore';
import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Breadcrumb,
  Checkbox,
  Modal,
  Empty,
  Input,
  Select,
  Row,
  Col,
  DatePicker
} from 'antd';
import { Link } from 'react-router-dom';
import * as API from '../../API';
import MediaInput from '../../components/MediaInput';
import { fetchMediaById, savePosterFrame } from '../../utils/media';
import { notify } from 'react-notify-toast';
import { gql } from '@apollo/client';

import AttachmentFileList from '../../components/Attachments/AttachmentFileList';
import AttachMediaModal from '../../components/Attachments/AttachMediaModal';
import ClosedCaptionAttachmentsInput from '../../components/Attachments/ClosedCaptionAttachmentsInput';

import SortableList from '../../components/SortableList';
import BoundItemSelector from '../../components/BoundItemSelector/BoundItemSelector';
import Heading from '../../components/Heading';

import { CREATE_CLOSED_CAPTION_ATTACHMENT_MUTATION } from './queries/CreateClosedCaptionAttachmentMutation';
import { SOFT_DELETE_CLOSED_CAPTION_ATTACHMENT_MUTATION } from './queries/SoftDeleteClosedCaptionAttachmentMutation';
import { FETCH_LECTURES } from './queries/FetchLectures';
import EditorInput from '../../components/EditorInput/EditorInput';

import './lecture.css';
import moment from 'moment';

const { Option } = Select;

const getChildItemStyle = (isDragging, draggableStyle) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  userSelect: 'none',
  padding: 18,
  marginBottom: 12,
  background: isDragging ? '#eee' : '#fff',
  boxShadow: isDragging ? 'box-shadow: 0 10px 6px -6px #777' : 'none',
  ...draggableStyle
});

const getPearlItemStyle = (isDragging, draggableStyle) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  userSelect: 'none',
  margin: '2px 0',
  background: isDragging ? '#eee' : '#fff',
  boxShadow: isDragging ? 'box-shadow: 0 10px 6px -6px #777' : 'none',
  ...draggableStyle
});

export default class Lecture extends React.Component {
  state = {
    target: null,
    children: [], // just immediate descendants
    descendants: [], // all descendants, used for changing topics
    pearlsList: [],
    // String displaying that there has been an upload error.
    uploadError: null,

    // used when creating a new lecture
    topics: [],

    // all media
    media: [],

    facultyBindings: undefined,
    // used for showing data when new poster is captured
    tempPosterFrame: '',

    // currently attached media (includes encodings)
    attachedMedia: null,

    // used for editing description
    description: null,

    savingMedia: false,
    showingMediaModal: false,
    savingPoster: false,

    // Corresponds to page states. Not data:
    editingTitle: false,
    editingDescription: false,
    editingSlug: false,
    editingTopic: false,
    loadingCreate: false,

    // buffer for selected course in dropdown
    _selectedTopicId: null,
    _selectedPearlTextId: null
  };

  async componentDidMount() {
    this.fetchLectures();

    // Fetch all topics
    const topics = (
      await API.graphql({
        query: `{
        topic{
            id,
            title,
            topicGroup{
              id,
              course{
                title
              }
            }
          }
        }`
      })
    ).data;

    this.setState({ topics });
  }

  onChildDragEnd = async result => {
    if (!result.destination) {
      return;
    }

    const { children } = this.state;
    const removedChild = children.splice(result.source.index, 1);
    children.splice(result.destination.index, 0, removedChild[0]);

    children.forEach((child, idx) => {
      child.displayOrder = idx;
    });

    // save the order of the children
    await Promise.all(
      children.map(child =>
        API.lecture.update({
          id: child.id,
          attributes: { displayOrder: child.displayOrder }
        })
      )
    );

    this.setState({ children });
  };

  onPearlDragEnd = result => {
    if (!result.destination) {
      return;
    }

    if (!this.state.target) {
      return;
    }

    const { pearlsList } = this.state;
    const removedChild = pearlsList.splice(result.source.index, 1);
    pearlsList.splice(result.destination.index, 0, removedChild[0]);

    pearlsList.forEach((pearl, idx) => {
      pearl.displayOrder = idx;
    });

    // save the order of the children
    pearlsList.map(pearl =>
      API.pearl.update({
        id: pearl.id,
        attributes: { displayOrder: pearl.displayOrder }
      })
    );

    this.setState({ pearlsList });
  };

  getSelectedMedia = () =>
    this.state.target && this.state.target.media
      ? this.state.target.media
      : null;

  fetchLectures = async () => {
    try {
      const id = this.props?.match?.params?.id;

      const response = await this.props.client.query({
        query: FETCH_LECTURES,
        variables: { id },
        fetchPolicy: 'network-only' // Doesn't check cache before making a network request
      });
      // .then(result => console.log(result));

      // Fetch all lectures with the current parentId from URL
      // NOTE: target.topic structure must match topics query above

      if (!response.loading) {
        const lecture = JSON.parse(JSON.stringify(response.data.lecture));

        const pearlsList = _.sortBy(lecture.pearlsList, 'displayOrder');

        const sortedFacultyBindings = lecture
          ? _.sortBy(lecture.lectureFacultyBindingsList, 'displayOrder')
          : undefined;

        const children = _.sortBy(
          lecture.lecturesByParentIdList,
          'displayOrder'
        );

        this.setState({
          attachedMedia: lecture ? lecture.media : null,
          facultyBindings: sortedFacultyBindings,
          target: lecture,
          description: lecture ? lecture.description : null,
          children,
          pearlsList,
          _children: children
        });
        this.fetchDescendants();
        this.fetchLectureAttachments();
      }
    } catch (e) {
      window.setError(e);
    }
  };

  fetchLectureAttachments = async () => {
    const GET_LECTURE_ATTACHMENTS = gql`
      query GetLectureAttachments($id: UUID!) {
        lecture(id: $id) {
          id
          lectureAttachmentBindingsList {
            attachment {
              id
              title
              subtitle
              isContentSummary
              activityTimeInSeconds
              fileName
              key
              signedUrl
              hasAccountCmeAttempts
            }
          }
        }
      }
    `;
    try {
      const response = await this.props.client.query({
        query: GET_LECTURE_ATTACHMENTS,
        variables: { id: this.state.target.id },
        fetchPolicy: 'network-only'
      });

      if (!response.loading) {
        const attachments = response.data.lecture.lectureAttachmentBindingsList.map(
          binding => binding.attachment
        );
        const target = {
          ...this.state.target,
          attachments
        };
        this.setState({ target });
      }
    } catch (error) {
      notify.show(`Error creating new section: ${error.message}`, 'error');
    }
  };

  fetchDescendants = () => {
    const topicId = this.state.target.topic.id;

    const getDescendants = (lectures, parentId) => {
      const childIds = lectures
        .filter(l => l.parent && l.parent.id === parentId)
        .map(l => l.id);

      return childIds.concat(...childIds.map(c => getDescendants(lectures, c)));
    };

    // Fetch all lectures with the topicId of the loaded lecture
    return API.graphql({
      query: `{
        lecture(topic:"${topicId}", activatedAt:"@any", expiredAt:"@any"){
          id,
          title,
          parent{
            id
          }
        }
      }`
    })
      .then(async response => {
        if (response.status === 200) {
          const data = response.data;
          const lectures = data.data.lecture;
          // Not all lectures in a topic are descendents of this parent.
          // Recursively build list of all descendant lecture ids
          const descendants = getDescendants(lectures, this.state.target.id);
          this.setState({ descendants });
        }
      })
      .catch(window.setError);
  };

  deleteLecture = id => {
    if (
      window.confirm('Are you sure? Children lectures will become unreachable.')
    ) {
      return API.lecture.delete({ id });
    }
    return null;
  };

  deleteResourceById = (type, id) =>
    API[type]
      .delete({ id })
      .then(() => this.fetchLectures())
      .catch(window.setError);

  createChildLecture = async () => {
    if (!this.state.target.topic.id) {
      window.alert('You must select a topic to continue!');
      return;
    }

    const parentId = this.props.match.params.id || null;

    const data = {
      attributes: {
        title: `New lecture with parent ${parentId}`,
        urlSlug: '/:slug'
      },
      relationships: {}
    };

    if (parentId) {
      data.relationships = {
        parent: {
          data: {
            type: 'lecture',
            id: parentId
          }
        }
      };
    }

    data.relationships.topic = {
      data: {
        type: 'topic',
        id: this.state.target.topic.id
      }
    };

    this.setState({ loadingCreate: true });
    await API.lecture.create(data);

    this.fetchLectures();
  };

  updateTopic = async topicId => {
    const { target } = this.state;
    const lectureIds = [target.id, ...this.state.descendants];

    const newTopic = _.findWhere(this.state.topics, {
      id: topicId
    });
    target.topic = newTopic;

    await Promise.all(
      lectureIds.map(id =>
        API.lecture({
          id,
          relationships: {
            topic: {
              data: {
                type: 'topic',
                id: topicId
              }
            }
          }
        })
      )
    );

    this.fetchLectures();
    this.setState({ editingTopic: false });
  };

  savePosterFrame = () => {
    this.setState({ savingPoster: true }, async () => {
      savePosterFrame(
        '#lecture-media video',
        this.state.attachedMedia,
        posterFrameMedia => {
          // Update currently attached media.
          const attachedMedia = this.state.attachedMedia
            ? {
                ...this.state.attachedMedia,
                posterFrame: posterFrameMedia.posterFrameUrl
              }
            : null;

          this.setState({
            attachedMedia,
            tempPosterFrame: '',
            savingPoster: false
          });
        },
        e => {
          window.alert(e.message);
          this.setState({ savingPoster: false });
        },
        tempPosterFrame => {
          this.setState({ tempPosterFrame });
        }
      );
    });
  };

  createPearl = async () => {
    const id = this.props.match.params.id;

    if (!id) {
      window.alert(
        "You shouldn't be able to create a pearl if you aren't viewing a lecture."
      );
      return;
    }

    const data = {
      type: 'pearl',
      attributes: {
        text: 'New pearl'
      },
      relationships: {
        lecture: {
          data: {
            type: 'lecture',
            id
          }
        }
      }
    };

    this.setState({ loadingCreate: true });
    await API.pearl.create(data);
    this.fetchLectures();
  };

  reorderBinding = async (bindingName, bindingType, bindings) => {
    const currentBindings = bindings || this.state.facultyBindings;
    if (
      currentBindings === null ||
      currentBindings === undefined ||
      currentBindings.length === 0 ||
      currentBindings.length === 1
    ) {
      return;
    }

    const facultyBindings = await Promise.all(
      currentBindings.map(async (binding, index) => {
        binding.displayOrder = index * 10;
        await API[bindingType].update({
          id: binding.id,
          attributes: { displayOrder: index * 10 }
        });
        return binding;
      })
    );
    this.setState({ facultyBindings });
    this.fetchLectures();
  };

  dropEventReorderBinding = async (dropEvent, bindingName, bindingType) => {
    if (!dropEvent.destination) {
      return;
    }

    const sourceIndex = dropEvent.source.index;
    const destinationIndex = dropEvent.destination.index;

    if (sourceIndex === destinationIndex) {
      return;
    }
    try {
      const bindings = [...this.state.facultyBindings];

      const [movedItem] = bindings.splice(sourceIndex, 1);
      bindings.splice(destinationIndex, 0, movedItem);
      this.setState({ [bindingName]: bindings });

      await this.reorderBinding(bindingName, bindingType, bindings);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error during reorder: ', error);
      this.setState({ error });
    }
  };

  render() {
    const { attachedMedia, facultyBindings } = this.state;

    return (
      <div style={{ paddingBottom: '4em' }}>
        <Breadcrumb style={{ padding: '20px 0' }}>
          <Breadcrumb.Item>
            <Link to="/dashboard">Hippo Admin</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/courses">Courses</Link>
          </Breadcrumb.Item>
          {this.state.target ? (
            <>
              <Breadcrumb.Item>
                <Link
                  to={`/course/${this.state.target.topic.topicGroup.course.id}`}
                >
                  {this.state.target.topic.topicGroup.course.title}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item title="Manage topic groups on the course page">
                {this.state.target.topic.topicGroup.title}
              </Breadcrumb.Item>
              <Breadcrumb.Item title="Manage topics on the course page">
                {this.state.target.topic.title}
              </Breadcrumb.Item>
              <Breadcrumb.Item title="You are here">
                {this.state.target.title}
              </Breadcrumb.Item>
            </>
          ) : null}
        </Breadcrumb>

        {/* Page header */}
        {this.state.target ? (
          <div className="dashboard-lectures-page-section">
            {this.state.target.parent ? (
              <div className="lecture-item">
                <strong>Parent lecture:</strong>&nbsp;
                <Link
                  to={`/lecture/${this.state.target.parent.id}`}
                  onClick={() => setTimeout(() => window.location.reload())}
                >
                  {this.state.target.parent.title}
                </Link>
              </div>
            ) : null}
            <div className="lecture-item">
              <strong>Title:</strong>&nbsp;
              {this.state.editingTitle ? (
                <Input
                  onBlur={() => {
                    API.lecture.update({
                      id: this.state.target.id,
                      attributes: { title: this.state.target.title }
                    });
                    this.setState({ editingTitle: false });
                  }}
                  onChange={e => {
                    const { target } = this.state;
                    target.title = e.target.value;
                    this.setState({ target });
                  }}
                  style={{ width: 300 }}
                  value={this.state.target.title}
                />
              ) : (
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.setState({ editingTitle: true })}
                >
                  {this.state.target.title}
                </span>
              )}
            </div>
            <div className="lecture-item">
              <strong>URL slug:</strong>&nbsp;
              {this.state.editingSlug ? (
                <Input
                  onBlur={() => {
                    API.lecture.update({
                      id: this.state.target.id,
                      attributes: { urlSlug: this.state.target.urlSlug }
                    });

                    this.setState({ editingSlug: false });
                  }}
                  onChange={e => {
                    const { target } = this.state;
                    target.urlSlug = e.target.value;
                    this.setState({ target });
                  }}
                  style={{ width: 300, cursor: 'pointer' }}
                  value={this.state.target.urlSlug}
                />
              ) : (
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.setState({ editingSlug: true })}
                >
                  {this.state.target.urlSlug}
                </span>
              )}
            </div>
            <div className="lecture-item">
              <strong>Topic:</strong>&nbsp;
              {this.state.editingTopic ? (
                <Select
                  value={this.state.target.topic.id}
                  style={{ width: 250 }}
                  onBlur={() => this.setState({ editingTopic: false })}
                  onChange={topicId => this.updateTopic(topicId)}
                >
                  {this.state.topics.map((topic, index) => (
                    <Option key={index} value={topic.id}>
                      {topic.title}
                    </Option>
                  ))}
                </Select>
              ) : (
                <span
                  style={this.state.target.parent ? {} : { cursor: 'pointer' }}
                  onClick={
                    this.state.target.parent
                      ? null
                      : () => {
                          this.setState({ editingTopic: true });
                        }
                  }
                >
                  {this.state.target.topic.title}{' '}
                  {this.state.target.parent && (
                    <small style={{ color: 'lightgray' }}>
                      (set by parent)
                    </small>
                  )}
                </span>
              )}
            </div>

            <div className="lecture-item">
              <strong>Last Reviewed Date:</strong>
              &nbsp;
              <DatePicker
                defaultValue={
                  this.state.target.lastReviewedAt
                    ? moment(this.state.target.lastReviewedAt)
                    : null
                }
                allowClear={true}
                format="MM/DD/YYYY"
                onChange={value => {
                  API.lecture.update({
                    id: this.state.target.id,
                    attributes: { lastReviewedAt: value }
                  });
                  this.setState({
                    target: { ...this.state.target, lastReviewedAt: value }
                  });
                }}
              />
            </div>

            <div className="lecture-description">
              <strong>Description:</strong>
              {this.state.editingDescription ? (
                <div className="editor-container">
                  <EditorInput
                    value={
                      this.state.description
                        ? this.state.description
                        : this.state.target?.description ?? ''
                    }
                    onChange={value => {
                      this.setState({
                        editingDescription: true,
                        description: value ?? null
                      });
                    }}
                  />
                  <div className="editor-action-buttons">
                    <div>
                      <Button
                        onClick={() =>
                          this.setState({
                            editingDescription: false,
                            description: this.state.target?.description
                          })
                        }
                      >
                        Cancel
                      </Button>
                      <Button
                        className="ant-btn ant-btn-primary"
                        disabled={
                          (!this.state.description &&
                            !this.state.target?.description) ||
                          (this.state.description &&
                            this.state.description ===
                              this.state.target?.description)
                        }
                        onClick={() => {
                          API.lecture.update({
                            id: this.state.target.id,
                            attributes: {
                              description: this.state.description ?? null
                            }
                          });
                          this.setState({
                            editingDescription: false,
                            description: this.state.description ?? null,
                            target: {
                              ...this.state.target,
                              description: this.state.description ?? null
                            }
                          });
                        }}
                      >
                        Save
                      </Button>
                    </div>
                    <Button
                      className="ant-btn ant-btn-danger"
                      disabled={!this.state.target?.description}
                      onClick={() => {
                        Modal.confirm({
                          title: 'Confirm delete description',
                          content:
                            'Are you sure you want to delete this description?',
                          onOk: () => {
                            API.lecture.update({
                              id: this.state.target.id,
                              attributes: {
                                description: null
                              }
                            });
                            this.setState({
                              editingDescription: false,
                              description: null,
                              target: {
                                ...this.state.target,
                                description: null
                              }
                            });
                          }
                        });
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              ) : (
                <div
                  onClick={() => this.setState({ editingDescription: true })}
                  dangerouslySetInnerHTML={{
                    __html:
                      this.state.target.description ??
                      'No description. Click to edit.'
                  }}
                />
              )}
            </div>

            <Heading>Media</Heading>
            <MediaInput
              selectedMedia={attachedMedia}
              isSignedUrl={true}
              posterFrame={
                this.state.tempPosterFrame ||
                (attachedMedia && attachedMedia.posterFrameUrl)
              }
              isSavingPoster={this.state.savingPoster}
              onSelectMedia={() => this.setState({ showingMediaModal: true })}
              onCapturePoster={this.savePosterFrame}
              onDetach={() => {
                this.setState({ savingMedia: true }, async () => {
                  try {
                    await API.lecture.update({
                      id: this.state.target.id,
                      attributes: { media: null }
                    });
                    this.setState({
                      attachedMedia: null,
                      savingMedia: false
                    });
                  } catch (e) {
                    window.alert(e.message);
                    this.setState({ savingMedia: false });
                  }
                });
              }}
            />

            <div className="lecture-item">
              <strong style={{ display: 'flex', alignItems: 'center' }}>
                <span>Faculty</span>&nbsp;
              </strong>
              {facultyBindings && facultyBindings.length > 0 && (
                <div className="chapter__faculty-selection-display-list chapter__faculty-selection-display-list-item">
                  <Row className="chapter__faculty-selection-display-list-header">
                    <Col span={6}>Order</Col>
                    <Col span={12}>Name</Col>
                  </Row>
                  <SortableList
                    items={facultyBindings}
                    itemType="faculty"
                    onDragEnd={e =>
                      this.dropEventReorderBinding(
                        e,
                        'lectureFacultyBindingsList',
                        'lectureFacultyBinding'
                      )
                    }
                    className="chapter__faculty-selection-display-list-sortable-list"
                    renderItem={(item, index) => (
                      <div
                        key={index}
                        className="chapter__faculty-selection-display-list-item"
                      >
                        <Row>
                          <Col span={6}>{index + 1}</Col>
                          <Col span={12}>{item.faculty.fullName}</Col>
                        </Row>
                      </div>
                    )}
                  />
                </div>
              )}
              <BoundItemSelector
                primaryItem={this.state.target}
                primaryResourceType="lecture"
                secondaryResourceType="faculty"
                secondaryResourceDisplayAttribute="fullName"
                bindingResourceType="lectureFacultyBinding"
                placeholder="Add faculty"
                defaultBindingResourceAttributes={{
                  displayOrder: (facultyBindings?.length + 1) * 10 || 0
                }}
                orderedDisplay={true}
                onBindingItemAdd={async () => {
                  await this.fetchLectures();
                  await this.reorderBinding(
                    'lectureFacultyBindingsList',
                    'lectureFacultyBinding'
                  );
                }}
                onBindingItemRemove={async item => {
                  await this.fetchLectures();
                  await this.reorderBinding(
                    'lectureFacultyBindingsList',
                    'lectureFacultyBinding'
                  );
                }}
              />
            </div>

            <div className="lecture-item">
              <strong style={{ display: 'flex', alignItems: 'center' }}>
                <span>Pearls</span>&nbsp;
                <PlusCircleOutlined
                  onClick={this.createPearl}
                  style={{
                    cursor: 'pointer',
                    color: '#ddd'
                  }}
                />
              </strong>

              {this.state.pearlsList && this.state.pearlsList.length > 0 ? (
                <DragDropContext onDragEnd={this.onPearlDragEnd}>
                  <Droppable droppableId="pearl_droppable">
                    {(provided, snapshot) => (
                      <ul
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="is-dragging-over"
                      >
                        {this.state.pearlsList.map((pearl, index) => (
                          <Draggable
                            key={pearl.id}
                            draggableId={pearl.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <li
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getPearlItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                  key={index}
                                >
                                  {this.state._selectedPearlTextId ===
                                  pearl.id ? (
                                    <input
                                      value={pearl.text}
                                      onChange={e => {
                                        const { pearlsList } = this.state;
                                        const _pearl = _.findWhere(pearlsList, {
                                          id: pearl.id
                                        });
                                        _pearl.text = e.target.value;
                                        this.setState({ pearlsList });
                                      }}
                                      onBlur={() => {
                                        const { pearlsList } = this.state;
                                        const _pearl = _.findWhere(pearlsList, {
                                          id: pearl.id
                                        });
                                        API.pearl.update({
                                          id: pearl.id,
                                          attributes: {
                                            text: _pearl.text
                                          }
                                        });

                                        this.setState({
                                          _selectedPearlTimestampId: null,
                                          _selectedPearlTextId: null
                                        });
                                      }}
                                    />
                                  ) : (
                                    <div
                                      onClick={() =>
                                        this.setState({
                                          _selectedPearlTextId: pearl.id
                                        })
                                      }
                                    >
                                      {pearl.text}
                                    </div>
                                  )}
                                  &nbsp;&nbsp;&nbsp;
                                  <div
                                    style={{
                                      color: '#aaa',
                                      fontStyle: 'italic',
                                      fontWeight: 100
                                    }}
                                  >
                                    at
                                  </div>
                                  &nbsp;&nbsp;&nbsp;
                                  {this.state._selectedPearlTimestampId ===
                                  pearl.id ? (
                                    <input
                                      value={pearl.timestamp}
                                      onChange={e => {
                                        const { pearlsList } = this.state;
                                        const _pearl = _.findWhere(pearlsList, {
                                          id: pearl.id
                                        });
                                        _pearl.timestamp = e.target.value;
                                        this.setState({ pearlsList });
                                      }}
                                      onBlur={() => {
                                        const { pearlsList } = this.state;
                                        const _pearl = _.findWhere(pearlsList, {
                                          id: pearl.id
                                        });

                                        if (
                                          !Number.parseFloat(_pearl.timestamp)
                                        ) {
                                          window.alert(
                                            'Failed to save: The timestamp must be a number.'
                                          );
                                        } else {
                                          API.pearl.update({
                                            id: pearl.id,
                                            attributes: {
                                              timestamp: parseFloat(
                                                _pearl.timestamp
                                              )
                                            }
                                          });
                                        }

                                        this.setState({
                                          _selectedPearlTimestampId: null,
                                          _selectedPearlTextId: null
                                        });
                                      }}
                                    />
                                  ) : (
                                    <div
                                      onClick={() =>
                                        this.setState({
                                          _selectedPearlTimestampId: pearl.id
                                        })
                                      }
                                    >
                                      {pearl.timestamp || 'No timestamp set'}
                                    </div>
                                  )}
                                  &nbsp;
                                  <Button
                                    type="link"
                                    className="button__link"
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      color: '#ddd'
                                    }}
                                    onClick={() =>
                                      this.deleteResourceById('pearl', pearl.id)
                                    }
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </div>
                              </li>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : null}
            </div>

            {attachedMedia?.id && (
              <div className="lecture-item">
                <strong style={{ display: 'flex', alignItems: 'center' }}>
                  <span>Closed Caption File</span>&nbsp;
                </strong>
                <ClosedCaptionAttachmentsInput
                  attachments={
                    this.state.target.media?.vttFileAttachment
                      ? [this.state.target.media?.vttFileAttachment]
                      : []
                  }
                  onCreateItem={async attachmentId => {
                    //update data on Media table
                    await this.props.client.mutate({
                      mutation: CREATE_CLOSED_CAPTION_ATTACHMENT_MUTATION,
                      variables: {
                        attachmentId,
                        id: attachedMedia.id
                      },
                      fetchPolicy: 'network-only'
                    });

                    this.fetchLectures();
                  }}
                  onUpdateItem={async () => {
                    this.fetchLectures();
                  }}
                  onDeleteItem={async () => {
                    await this.props.client.mutate({
                      mutation: SOFT_DELETE_CLOSED_CAPTION_ATTACHMENT_MUTATION,
                      variables: {
                        id: attachedMedia.id
                      },
                      fetchPolicy: 'network-only'
                    });
                    this.fetchLectures();
                  }}
                  showCmeFields={false}
                  canEdit={true}
                />
              </div>
            )}
            {!attachedMedia?.id && (
              <strong style={{ display: 'flex', alignItems: 'center' }}>
                <span>
                  Please attach a media file to enable Closed Captions
                </span>
                &nbsp;
              </strong>
            )}

            <div>
              <strong style={{ display: 'flex', alignItems: 'center' }}>
                <span>Attachments</span>&nbsp;
              </strong>
              {this.state.target && (
                <AttachmentFileList
                  attachments={this.state.target.attachments}
                  showCmeFields={true}
                  onCreateItem={async attachmentId => {
                    await API.lectureAttachmentBinding.create({
                      attributes: {
                        createdAt: new Date(),
                        updatedAt: new Date()
                      },
                      relationships: {
                        lecture: {
                          data: {
                            type: 'lecture',
                            id: this.state.target.id
                          }
                        },
                        attachment: {
                          data: {
                            type: 'attachment',
                            id: attachmentId
                          }
                        }
                      }
                    });
                    this.fetchLectureAttachments();
                  }}
                  onUpdateItem={() => {
                    this.fetchLectureAttachments();
                  }}
                  onDeleteItem={() => {
                    this.fetchLectureAttachments();
                  }}
                />
              )}
            </div>
            <div className="lecture__has-cme-indicator">
              <Checkbox
                onChange={e => {
                  API.lecture.update({
                    id: this.state.target.id,
                    attributes: { hasCme: !e.target.checked }
                  });
                  this.setState({
                    target: {
                      ...this.state.target,
                      hasCme: !e.target.checked
                    }
                  });
                }}
                checked={!this.state.target.hasCme}
              >
                No CME?
              </Checkbox>
            </div>
          </div>
        ) : null}

        {this.state.target ? (
          <h4 style={{ marginTop: 18 }}>Child lectures:</h4>
        ) : null}

        {/* Child lectures */}
        <div style={getChildItemStyle(false, {})}>
          <div className="column-span-large">
            <strong>Title</strong>
          </div>
          <div className="column-span-large">
            <strong>Course</strong>
          </div>
          <div className="column-span-large">
            <strong>Topic</strong>
          </div>
          <div className="column-span-medium" />
        </div>
        {this.state.children.length === 0 ? (
          <div className="dashboard-lectures-page-section">
            <Empty />
          </div>
        ) : (
          <DragDropContext onDragEnd={this.onChildDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="is-dragging-over"
                >
                  {this.state.children
                    .filter(
                      child =>
                        !this.state.course ||
                        child.topic.topicGroup.course.id ===
                          this.state.course.id
                    )
                    .map((child, index) => (
                      <Draggable
                        key={child.id}
                        draggableId={child.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getChildItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <div className="column-span-large">
                              <Link
                                to={`/lecture/${child.id}`}
                                onClick={() =>
                                  setTimeout(() => window.location.reload())
                                }
                              >
                                {child.title}
                              </Link>
                            </div>
                            <div className="column-span-large">
                              {child.topic.topicGroup.course.title}
                            </div>
                            <div className="column-span-large">
                              {child.topic.title}
                            </div>
                            <div className="column-span-medium">
                              <Button
                                type="danger"
                                onClick={() =>
                                  this.deleteResourceById('lecture', child.id)
                                }
                              >
                                Delete
                              </Button>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}

        {this.state.target && !this.state.target.parent && (
          <div className="dashboard-lectures-create-button-container">
            <Button
              type="primary"
              onClick={async () => {
                await this.createChildLecture();
              }}
            >
              Create
            </Button>
          </div>
        )}

        <AttachMediaModal
          courseType="video"
          visible={this.state.showingMediaModal}
          isLoading={this.state.savingMedia}
          title="Select Lecture Video"
          onClose={() => this.setState({ showingMediaModal: false })}
          onAttach={item => {
            this.setState({ savingMedia: true }, () => {
              Promise.all([
                API.lecture.update({
                  id: this.state.target.id,
                  attributes: { media: { type: 'media', id: item.id } }
                }),
                fetchMediaById(item.id)
              ])
                .then(([, loadedMedia]) => {
                  const newAttachedMedia = loadedMedia.data.media[0];
                  this.setState({
                    attachedMedia: newAttachedMedia,
                    savingMedia: false,
                    showingMediaModal: false
                  });
                })
                .catch(e => {
                  window.alert(e.message);
                  this.setState({ savingMedia: false });
                });
            });
          }}
        />
      </div>
    );
  }
}
