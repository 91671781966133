import * as API from '../API';

export const getAccountsByPermissionId = async permissionId => {
  const accounts = API.simplifyResource(
    await API.account.where({
      filter: {
        isAdmin: true
      },
      options: {
        include: 'roles,roles.permissions'
      }
    })
  );

  return accounts.filter(account =>
    account.roles.find(role =>
      role.permissions.find(permission => permission.id === permissionId)
    )
  );
};
