import Cookie from 'js-cookie';
import get from 'lodash/get';
import jwtDecode from 'jwt-decode';

export function getTokenFromCookie() {
  const sessionStr = Cookie.get(process.env.REACT_APP_SESSION_COOKIE_NAME);
  if (!sessionStr) {
    return undefined;
  }

  const session = JSON.parse(sessionStr);
  return get(session, 'authenticated.access_token');
}

export function getDecodedTokenFromCookie() {
  const token = getTokenFromCookie();
  if (!token) {
    return undefined;
  }

  const decodedToken: { account: string, iat: number } = jwtDecode(token);
  return decodedToken;
}
