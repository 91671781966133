import _, { first } from 'lodash';

function getAllCmeAttemptsFromEarnedCme(earnedCmeList) {
  return earnedCmeList.flatMap(earned =>
    earned.accountCmeAttempt
      ? [
          {
            type: 'attested',
            bucket: earned.cmeBucket,
            ...earned.accountCmeAttempt
          }
        ]
      : earned.accountEarnedCmeAttemptBindingsList.map(binding => ({
          type: 'accrued',
          bucket: earned.cmeBucket,
          ...binding.accountCmeAttempt
        }))
  );
}

export function groupEarnedCmesByBucket(accountEarnedCmesList) {
  const earnedCmeByBucket = _.groupBy(
    accountEarnedCmesList,
    earned => earned.cmeBucket?.id
  );

  return _.chain(accountEarnedCmesList)
    .filter(earned => earned.cmeBucket)
    .map(earned => earned.cmeBucket)
    .uniqBy(bucket => bucket.id)
    .orderBy(['cmeAccreditor.name', 'activatedAt'], ['asc', 'desc'])
    .map(bucket => ({
      ...bucket,
      totalCredits: earnedCmeByBucket[bucket.id].reduce(
        (sum, earned) => sum + parseFloat(earned.credits),
        0
      ),
      cmeAttempts: _.orderBy(
        getAllCmeAttemptsFromEarnedCme(earnedCmeByBucket[bucket.id]),
        attempt => attempt.createdAt,
        'desc'
      )
    }))
    .value();
}

export function groupEarnedCmesByContent(
  contentByGroup,
  accountEarnedCmesList,
  courseId
) {
  const allAttempts = getAllCmeAttemptsFromEarnedCme(accountEarnedCmesList).map(
    attempt => {
      const content =
        getEpisodeForCmeAttempt(attempt, courseId) ||
        getTopicForCmeAttempt(attempt, courseId) ||
        getAssessmentForCmeAttempt(attempt) ||
        getQbankForCmeAttempt(attempt);
      return {
        content,
        ...attempt
      };
    }
  );

  const attemptsByContent = _.groupBy(allAttempts, attempt => {
    return attempt.content ? attempt.content.id : '';
  });

  if ((!contentByGroup || contentByGroup.length === 0) && attemptsByContent) {
    const results = [];

    for (const [, cmeAttemptArr] of Object.entries(attemptsByContent)) {
      function processCmeAttemptNotTiedToContent(attempt) {
        const contentType = getCmeAttemptContentType(attempt);

        const attemptedAtPretty = new Date(
          attempt.attemptedAt
        ).toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric'
        });
        const contentTitle =
          contentType === 'qbank'
            ? `Correct Questions Answered on ${attemptedAtPretty}`
            : contentType === 'attachment'
            ? `Attachment viewed on ${attemptedAtPretty}`
            : null;

        return {
          ...attempt,
          title: contentTitle,
          totalTime: attempt.passed ? attempt.activityTimeInSeconds : 0,
          totalDuration: null,
          cmeAttempts: [attempt]
        };
      }

      results.push(
        ...cmeAttemptArr.map(attempt =>
          processCmeAttemptNotTiedToContent(attempt)
        )
      );
    }
    return results;
  }

  return contentByGroup?.map(group => {
    const attemptsForGroup = attemptsByContent[group.id];

    if (!attemptsForGroup) {
      return group;
    }

    // Attested episodes and topics should replace all the individual lecture/chapter placeholders
    // When episode/topic is attested, there should only be one attempt for the group
    if (first(attemptsForGroup).episode || first(attemptsForGroup).topic) {
      const attempt = first(attemptsForGroup);
      return {
        ...group,
        totalTime: attempt.passed ? attempt.activityTimeInSeconds : 0,
        totalCredits: attempt.passed ? attempt.credits : 0,
        cmeAttempts: [attempt]
      };
    }

    // Replace each placeholder null attempt for each chapter/lecture/attachment
    // with actual attempt(s).
    return {
      ...group,
      totalTime: attemptsForGroup.reduce(
        (sum, attempt) =>
          sum + (attempt.passed ? attempt.activityTimeInSeconds : 0),
        0
      ),
      cmeAttempts: group.cmeAttempts.flatMap(nullAttempt => {
        const attemptsForItem = attemptsForGroup.filter(
          item =>
            (item.lecture && item.lecture?.id === nullAttempt.lecture?.id) ||
            (item.chapter && item.chapter?.id === nullAttempt.chapter?.id) ||
            (item.attachment &&
              item.attachment?.id === nullAttempt.attachment?.id) ||
            (item.accountAssessmentSummariesList &&
              item.accountAssessmentSummariesList.length &&
              item.accountAssessmentSummariesList[0]?.assessment.id ===
                nullAttempt.assessment?.id)
        );
        return attemptsForItem.length
          ? _.orderBy(attemptsForItem, attempt => attempt.createdAt)
          : nullAttempt;
      })
    };
  });
}

export function getCmeAttemptContentType(cmeAttempt) {
  return _.chain(cmeAttempt)
    .toPairs()
    .filter(
      ([key, value]) =>
        [
          'episode',
          'chapter',
          'lecture',
          'attachment',
          'topic',
          'assessment',
          'accountAssessmentSummariesList'
        ].indexOf(key) >= 0 && !!value
    )
    .map(([key, value]) => {
      return key === 'accountAssessmentSummariesList' && value.length === 0
        ? 'qbank'
        : key === 'accountAssessmentSummariesList' && value.length >= 1
        ? 'assessment'
        : key;
    })
    .head()
    .value();
}

function getEpisodeForChapterCmeAttempt(cmeAttempt, courseId) {
  return _.head(
    cmeAttempt.chapter.episodeChapterBindingsList
      ?.filter(binding => binding.episode.courseId === courseId)
      ?.map(binding => binding.episode) || []
  );
}

function getEpisodeForAttachmentCmeAttempt(cmeAttempt, courseId) {
  return cmeAttempt.attachment.chapterAttachmentBindingsList?.length > 0
    ? getEpisodeForChapterCmeAttempt(
        {
          chapter:
            cmeAttempt.attachment.chapterAttachmentBindingsList[0].chapter
        },
        courseId
      )
    : null;
}

export function getEpisodeForCmeAttempt(cmeAttempt, courseId) {
  const contentType = getCmeAttemptContentType(cmeAttempt);
  return contentType === 'episode'
    ? cmeAttempt[contentType]
    : contentType === 'chapter'
    ? getEpisodeForChapterCmeAttempt(cmeAttempt, courseId)
    : contentType === 'attachment'
    ? getEpisodeForAttachmentCmeAttempt(cmeAttempt, courseId)
    : null;
}

function getTopicForAttachmentCmeAttempt(cmeAttempt, courseId) {
  return cmeAttempt.attachment.lectureAttachmentBindingsList?.length > 0
    ? cmeAttempt.attachment.lectureAttachmentBindingsList[0].lecture.topic
    : null;
}

function getTopicForCmeAttempt(cmeAttempt, courseId) {
  const contentType = getCmeAttemptContentType(cmeAttempt);
  return contentType === 'topic'
    ? cmeAttempt[contentType]
    : contentType === 'lecture'
    ? cmeAttempt.lecture.topic
    : contentType === 'attachment'
    ? getTopicForAttachmentCmeAttempt(cmeAttempt, courseId)
    : null;
}

export function getAssessmentForCmeAttempt(cmeAttempt) {
  const contentType = getCmeAttemptContentType(cmeAttempt);

  return contentType === 'assessment'
    ? cmeAttempt.assessment?.title ||
        cmeAttempt.accountAssessmentSummariesList.at(0)?.assessment
    : null;
}

export function getQbankForCmeAttempt(cmeAttempt) {
  const contentType = getCmeAttemptContentType(cmeAttempt);
  return contentType === 'qbank' ? cmeAttempt : null;
}

function nullCmeAttempt(type, content, key, item) {
  return {
    type,
    content,

    id: 'placeholder' + item.id,
    createdAt: null,
    bucket: null,
    activityTimeInSeconds: 0,
    credits: '0.00',
    passed: false,
    status: 'NOT ATTEMPTED',

    // item keys
    assessment: null,
    lecture: null,
    topic: null,
    chapter: null,
    episode: null,
    attachment: null,
    [key]: item // must be last. This overwrites the null item key above with a given item
  };
}

function contentPlaceholdersForLectureAndAttachmentAccrual(rawCourseContent) {
  return rawCourseContent.topicGroupsList
    .flatMap(group => group.topicsList)
    .map(topic => {
      const cmeContent = topic.lecturesList.flatMap(maybeAParentLecture =>
        maybeAParentLecture.lecturesByParentIdList.length
          ? maybeAParentLecture.lecturesByParentIdList.flatMap(lecture => [
              {
                ...lecture,
                title: maybeAParentLecture.title + ':' + lecture.title
              },
              ...lecture.lectureAttachmentBindingsList
                .filter(binding => binding.attachment.activityTimeInSeconds > 0)
                .map(binding => binding.attachment)
            ])
          : [
              maybeAParentLecture,
              ...maybeAParentLecture.lectureAttachmentBindingsList
                .filter(binding => binding.attachment.activityTimeInSeconds > 0)
                .map(binding => binding.attachment)
            ]
      );
      return {
        ...topic,
        totalTime: 0,
        totalDuration: cmeContent.reduce((total, lectureOrAttachment) => {
          return lectureOrAttachment.media
            ? total + parseInt(lectureOrAttachment.media.duration, 10)
            : total + lectureOrAttachment.activityTimeInSeconds;
        }, 0),
        cmeAttempts: cmeContent.map(content =>
          content.activityTimeInSeconds
            ? nullCmeAttempt('accrual', topic, 'attachment', content)
            : nullCmeAttempt('accrual', topic, 'lecture', content)
        )
      };
    });
}

function contentPlaceholdersForEpisodesAndChapters(rawCourseContent) {
  return rawCourseContent.episodesList.map(episode => {
    const cmeContent = episode.episodeChapterBindingsList.flatMap(binding => [
      binding.chapter,
      ...binding.chapter.chapterAttachmentBindingsList
        .filter(
          attachmentBinding =>
            attachmentBinding.attachment.activityTimeInSeconds > 0
        )
        .map(attachmentBinding => attachmentBinding.attachment)
    ]);

    return {
      ...episode,
      totalTime: 0,
      totalDuration: cmeContent.reduce((total, chapterOrAttachment) => {
        return chapterOrAttachment.media
          ? total + parseInt(chapterOrAttachment.media.duration, 10)
          : total + chapterOrAttachment.activityTimeInSeconds;
      }, 0),
      cmeAttempts: cmeContent.map(content =>
        content.activityTimeInSeconds
          ? nullCmeAttempt('accrual', episode, 'attachment', content)
          : nullCmeAttempt('accrual', episode, 'chapter', content)
      )
    };
  });
}

function contentPlaceholdersForAssessments(rawCourseContent) {
  return rawCourseContent.assessmentsList.map(assessment => {
    return {
      ...assessment,
      totalTime: 0,
      totalDuration: null,
      cmeAttempts: [
        nullCmeAttempt('accrual', assessment, 'assessment', assessment)
      ]
    };
  });
}

export function groupRawContent(rawCourseContent) {
  if (!rawCourseContent) {
    return null;
  }
  const result = [
    ...contentPlaceholdersForLectureAndAttachmentAccrual(rawCourseContent),
    // TODO:
    // ...contentPlaceholdersForTopicAttestation(rawCourseContent)
    ...contentPlaceholdersForEpisodesAndChapters(rawCourseContent),
    ...contentPlaceholdersForAssessments(rawCourseContent)
  ];

  return result;
}
