import { gql } from '@apollo/client';

export const UPDATE_ATTACHMENT = gql`
  mutation UpdateAttachment(
    $id: UUID!
    $attachmentSectionId: UUID
    $positionWithinSection: Int
    $isUpdatedSpotlightEnabled: Boolean
    $title: String
    $subtitle: String
    $key: String
    $fileName: String
    $fileSize: BigInt
    $activityTimeInSeconds: Int
    $mimeType: String
    $isContentSummary: Boolean
  ) {
    updateAttachment(
      input: {
        patch: {
          attachmentSectionId: $attachmentSectionId
          positionWithinSection: $positionWithinSection
          activityTimeInSeconds: $activityTimeInSeconds
          isUpdatedSpotlightEnabled: $isUpdatedSpotlightEnabled
          title: $title
          subtitle: $subtitle
          key: $key
          fileName: $fileName
          fileSize: $fileSize
          mimeType: $mimeType
          isContentSummary: $isContentSummary
        }
        id: $id
      }
    ) {
      attachment {
        id
        title
        subtitle
        attachmentSectionId
        activityTimeInSeconds
        positionWithinSection
        isUpdatedSpotlightEnabled
        key
        fileName
        fileSize
        mimeType
        isContentSummary
      }
    }
  }
`;

export const DELETE_ATTACHMENT = gql`
  mutation DeleteAttachment($id: UUID!) {
    deleteAttachment(input: { id: $id }) {
      clientMutationId
    }
  }
`;
