import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Table } from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';
import BreadcrumbNavigation from '../../components/BreadcrumbNavigation/BreadcrumbNavigation';
import BreadcrumbConstants from '../../routes/BreadcrumbConstants';
import * as API from '../../API';

export default function Associations() {
  const history = useHistory();
  const [allAssociations, setAllAssociations] = useState([]);

  const fetchAssociations = async () => {
    const associations = await API.fetchAllPages(
      API.association.all({ options: { sort: 'title' } })
    );
    setAllAssociations(associations);
  };

  useEffect(() => {
    fetchAssociations();
  }, []);

  const handleDeleteAssociation = async associationId => {
    await API.association.delete({
      id: associationId
    });

    fetchAssociations();
  };

  const handleCreateNewAssociation = async () => {
    const newAssociation = await API.association.create({
      attributes: {
        title: 'New Association',
        memberTitle: 'New Association Member Title'
      }
    });

    return history.push(`/association/${newAssociation.data.id}`);
  };

  return (
    <div>
      <BreadcrumbNavigation
        data={[BreadcrumbConstants.HippoAdmin, { title: 'Associations' }]}
      />
      <hr />
      <Button color="primary" onClick={handleCreateNewAssociation}>
        + Create New Association
      </Button>
      <Table
        pagination={{ defaultPageSize: 20, position: ['bottomLeft'] }}
        columns={[
          {
            title: 'Association Name',
            key: 'associationName',
            render: item => (
              <Link to={`/association/${item.id}`}>
                {item.attributes.title}
              </Link>
            )
          },
          {
            title: 'Member Title',
            key: 'memberTitle',
            render: item => item.attributes.memberTitle
          },
          {
            title: '# of Products',
            key: 'numberOfProducts',
            render: item =>
              item.relationships.associationItemBindings.data.length
          },
          {
            title: 'Delete?',
            key: 'delete',
            render: item => (
              <DeleteTwoTone
                twoToneColor="red"
                size="large"
                onClick={() => handleDeleteAssociation(item.id)}
              />
            )
          }
        ]}
        dataSource={allAssociations}
      />
    </div>
  );
}
