import { stringify } from 'querystring';
import mandrill from 'mandrill-api';
import moment from 'moment';
import toPairs from 'lodash/toPairs';

export function sendEmail(message) {
  const mandrillClient = new mandrill.Mandrill(
    process.env.REACT_APP_MANDRILL_API_KEY
  );
  return new Promise((resolve, reject) =>
    mandrillClient.messages.sendTemplate(message, resolve, reject)
  );
}

function getUtmString(utmCampaign, utmContent = '') {
  return stringify({
    utm_source: 'system-email',
    utm_campaign: utmCampaign,
    utm_medium: 'email',
    utm_content: utmContent !== '' ? utmContent : undefined
  });
}

export function getMergeVars(obj) {
  return toPairs(obj).map(([name, content]) => ({ name, content }));
}

function getSiteMergeVars(site_shortname) {
  switch (site_shortname) {
    case 'ercast':
      return {
        site_title: 'ERcast',
        site_title_nbsp: 'ERcast',
        site_shortname: 'ercast',
        brand_primary: '#3295d2'
      };
    default:
      return {
        site_title: 'Hippo',
        site_title_nbsp: 'Hippo',
        site_shortname: 'hippo',
        brand_primary: '#2d89f3'
      };
  }
}

export function getBaseTemplate(site_shortname, utmCampaign, utmContent = '') {
  // todo make merge vars generic to project
  const message = {
    from_email: 'support@hippoeducation.com',
    from_name: 'Hippo Education',
    merge: true,
    merge_language: 'handlebars',
    global_merge_vars: getMergeVars({
      base_href: 'https://hippoed.com/',
      ...getSiteMergeVars(site_shortname),
      current_year: moment().format('YYYY'),
      utm_string: getUtmString(utmCampaign, utmContent)
    }),
    tags: ['hippo-pay']
  };
  const async = false;
  const ip_pool = 'Main Pool';

  const send_at = moment().format('YYYY-MM-DD');

  return { message, async, ip_pool, send_at };
}

export function getEmailAddress(account) {
  // This allows us to override the actual account email for dev+test purposes
  if (process.env.REACT_APP_MANDRILL_TEST_EMAIL !== undefined) {
    return process.env.REACT_APP_MANDRILL_TEST_EMAIL;
  }
  return account.email;
}

export function getFullName(account) {
  return `${account.firstName} ${account.lastName}`;
}
