export const ICONS = [
  'activity',
  'arrow-up',
  'arrow-down',
  'arrow-right',
  'arrow-left',
  'arrow-up-right',
  'arrows-vertical',
  'back-narrow',
  'forward-narrow',
  'back',
  'book',
  'down',
  'bookmark',
  'trash',
  'account-checkmark',
  'calculator',
  'calendar',
  'answer-correct',
  'checkmark',
  'checklist',
  'close',
  'close-circle',
  'answer-wrong',
  'collapse',
  'comment',
  'download',
  'edit',
  'notes',
  'edit-pencil',
  'expand',
  'expand-alt',
  'external',
  'field-avatar',
  'field-calendar',
  'field-credit-card',
  'field-email',
  'field-lock',
  'field-warning',
  'field-success',
  'flask',
  'gear',
  'hamburger',
  'hamburger-list',
  'info',
  'list',
  'list-player',
  'minus',
  'more',
  'pdf',
  'qbank',
  'question-checkmark',
  'question-checkmark-no-circle',
  'pearl',
  'pause',
  'play',
  'play-triangle',
  'forward-10',
  'back-10',
  'player',
  'play-next',
  'play-previous',
  'plus',
  'printer',
  'search',
  'star',
  'shopping-bag',
  'assessment',
  'calendar-simple',
  'credit',
  'discussion',
  'document',
  'file-box',
  'heart-plus',
  'mobile-app',
  'thank-you',
  'time',
  'video-list',
  'books',
  'study',
  'sort-descending',
  'group',
  'complete',
  'triangle',
  'test',
  'hand-notebook',
  'person-brain',
  'writing-board',
  'brain',
  'xray',
  'plus-thick',
  'twitter',
  'facebook',
  'youtube',
  'logo-aapa-pcrap',
  'logo-pcrap',
  'logo-ucrap',
  'logo-pedsrap',
  'logo-rap',
  'logo-aemb',
  'logo-uc-bootcamp',
  'logo-peds-em-bootcamp',
  'logo-ercast',
  'logo-em',
  'logo-pa',
  'logo-peds',
  'logo-telemedicine',
  'logo-uc-llsa',
  'rap-nav-home',
  'rap-nav-library',
  'rap-nav-cme',
  'user',
  'user-gear',
  'gift',
  'subscription-type',
  'expiration',
  'credit-cards',
  'subscription-cancel',
  'subscription-success',
  'invoice',
  'zoom'
];
