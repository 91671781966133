import { gql } from '@apollo/client';

const GET_ITEM_QUERY = gql`
  query getItem($itemId: UUID!) {
    item(id: $itemId) {
      addOnText
      allowArb
      braintreePlanId
      billingPeriod
      category
      descriptionText
      endedAt
      freeTrialDuration
      hasFreeTrial
      id
      isAddOn
      isRequiredAddon
      allowStandalone
      sku
      startedAt
      subText
      subscriptionTitle
      title
      hasOrderItems

      parent {
        id
        title
      }
      course {
        id
        title
      }
      itemIncludesList(orderBy: DISPLAY_ORDER_ASC) {
        id
        displayOrder
        icon
        itemId
        text
      }
      suggestedItemsList(orderBy: DISPLAY_ORDER_ASC) {
        id
        displayOrder
        itemSuggested {
          id
          title
          sku
        }
      }
      pricesList {
        id
        price
      }
      subscriptionTemplatesList {
        id
        defaultSubscriptionLength
        isCmeEnabled
        isCmeArchiveEnabled
        isFree
        title
      }
    }
  }
`;

export default GET_ITEM_QUERY;
