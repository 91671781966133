import React from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import { Spin } from 'antd';

import FillMainView from '../../components/FillMainView';
import BreadcrumbConstants from '../../routes/BreadcrumbConstants';
import GroupMembers from './GroupMembers';
import GroupDetails from './GroupDetails';
import GroupSubscriptions from './GroupSubscriptions';
import { getAccountsByPermissionId } from '../../utils/rolesAndPermissions';
import Notes from '../../components/Notes/Notes';
import config from '../../config';

import * as API from '../../API';

import './Group.css';
class Group extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };
  constructor(props) {
    super(props);

    this.state = {
      group: null,
      subscriptions: [],
      accounts: [],
      accountManagers: [],
      loadingAccounts: false,
      loadingSubscriptions: false
    };
  }

  componentDidMount = async () => {
    const isNewGroup = this.props.history?.location?.state?.isNewGroup;

    //the isNewGroup property should only be true the first time this mounts
    //this ensures that data is loaded on refresh when you first create the group
    this.props.history.replace({
      ...this.props.history.location,
      state: {
        ...this.props.history.location.state,
        isNewGroup: false
      }
    });

    // Do not make unnecessary API calls if we came here from the Groups page by pressing the Create New Group button
    isNewGroup ? await this.loadGroup() : await this.loadAllInitialData();
  };

  componentWillUnmount = () => {
    window.onbeforeunload = null;
  };

  loadAllInitialData = async () => {
    try {
      // We need to load initial group data before loading any other data
      await this.loadGroup();
      this.loadAccountManagers();
      this.loadAccounts();
      this.loadSubscriptions();
    } catch (e) {
      throw e;
    }
  };

  loadGroup = async () => {
    try {
      const groupResult = await API.program.find({
        id: this.props.match.params.id,
        options: {
          'fields[program]': 'name,shortname,createdAt', // specify field so member progress is not calculated
          include: 'manager,users,users.account,users.courses' // field specification doesn't affect included relationships
        }
      });

      const group = groupResult.data;

      this.setState({ group });
    } catch (e) {
      throw e;
    }
  };

  loadAccounts = async () => {
    try {
      this.setState({
        loadingAccounts: true
      });
      const groupResult = await API.program.find({
        id: this.props.match.params.id,
        options: {
          'fields[program]': 'name,shortname', // specify field so member progress is not calculated
          include:
            'manager,users,users.account,users.account.subscriptions,users.account.subscriptions.course,users.courses' // field specification doesn't affect included relationships
        }
      });

      const { users } = API.simplifyResource(groupResult);

      this.setState({ accounts: users, loadingAccounts: false });
    } catch (e) {
      this.setState({
        loadingAccounts: false
      });
      throw e;
    }
  };

  loadSubscriptions = async () => {
    try {
      this.setState({
        loadingSubscriptions: true
      });
      const subscriptions = await API.fetchAllAndSimplify(
        API.programCourseBinding.where({
          filter: {
            program: this.state.group?.id
          },
          options: {
            include: 'course,accountManager,subscriptions'
          }
        })
      );

      this.setState({ subscriptions, loadingSubscriptions: false });
    } catch (e) {
      this.setState({
        loadingSubscriptions: false
      });
      throw e;
    }
  };

  loadAccountManagers = async () => {
    try {
      const accountManagers = await getAccountsByPermissionId(
        config.permissions.canManageGroups
      );

      this.setState({ accountManagers });
    } catch (e) {
      throw e;
    }
  };

  render() {
    const { group, subscriptions, accounts } = this.state;

    return (
      <FillMainView
        onClose={() =>
          this.props.history.push({
            pathname: '/group-management/groups',
            state: { ...this.props.location.state }
          })
        }
        closeButtonText="Back to Groups"
        breadcrumbData={[
          BreadcrumbConstants.HippoAdmin,
          BreadcrumbConstants.GroupManagement,
          { to: '/group-management/groups', title: 'Groups' },
          { title: group?.attributes.name || '' }
        ]}
      >
        <hr />
        {group ? (
          <div className="group">
            <GroupDetails
              group={group}
              updateGroup={newGroup => this.setState({ group: newGroup })}
              accountManagers={this.state.accountManagers}
            />
            <hr />
            <div className="group__form-item">
              <GroupMembers
                memberType="Admin"
                group={group}
                accounts={accounts}
                subscriptions={subscriptions}
                history={this.props.history}
                loadingAccounts={this.state.loadingAccounts}
                loadAccounts={this.loadAccounts}
                loadSubscriptions={this.loadSubscriptions}
              />
            </div>
            <hr />
            <GroupSubscriptions
              group={group}
              subscriptions={subscriptions}
              accountManagers={this.state.accountManagers}
              loadAccountManagers={this.loadAccountManagers}
              loadSubscriptions={this.loadSubscriptions}
              loadingSubscriptions={this.state.loadingSubscriptions}
            />
            <hr />
            <GroupMembers
              memberType="Member"
              group={group}
              accounts={accounts}
              subscriptions={subscriptions}
              history={this.props.history}
              loadingAccounts={this.state.loadingAccounts}
              loadAccounts={this.loadAccounts}
              loadSubscriptions={this.loadSubscriptions}
            />
          </div>
        ) : (
          <Spin />
        )}
        {this.state.group && (
          <Notes
            bindingType="program"
            bindingTypeId={this.state.group.id}
            title="Program Notes"
          />
        )}
      </FillMainView>
    );
  }
}

export default withRouter(Group);
