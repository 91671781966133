import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { DeleteOutlined } from '@ant-design/icons';
import { Popconfirm, Table, Button, Empty, Input } from 'antd';
import { notify } from 'react-notify-toast';
import moment from 'moment';

import * as API from '../../API';
import PaginatedList from '../../components/PaginatedList/PaginatedList';
import ListHeaderActionItems from '../../components/PaginatedList/ListHeaderActionItems';
import BreadcrumbNavigation from '../../components/BreadcrumbNavigation/BreadcrumbNavigation';
import MessageLoader from '../../components/MessageLoader';
import BreadcrumbConstants from '../../routes/BreadcrumbConstants';

import './Groups.css';

export default class Groups extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired
  };

  state = {
    error: '',
    groupNameSearch: this.props.location.state?.groupName,
    isLoading: false
  };

  getResourceOptions = () => ({
    sort: 'name',
    include: 'createdAt'
  });

  createGroup = async () => {
    try {
      this.setState({ isLoading: true });
      const group = await API.program.create({
        attributes: {
          name: 'New Group',
          shortname: `new-group-${uuidv4()}`
        }
      });
      this.props.history.push(`/group-management/group/${group.data.id}`, {
        isNewGroup: true
      });
    } catch (error) {
      notify.show(
        `Error. Create unsuccessful. Error status: ${error.status}`,
        'error'
      );
    } finally {
      this.setState({ isLoading: false });
    }
  };

  removeGroupAccountBinding = async groupId => {
    const groupMembershipsToDelete = await API.programAccountBinding.where({
      filter: {
        program: groupId
      }
    });
    const groupMemberships = API.simplifyResource(groupMembershipsToDelete);
    groupMemberships.forEach(async groupMember => {
      await API.programAccountBinding.delete({
        id: groupMember.id
      });
    });
    await API.fetchAllPages(API.program.all());
  };

  removeGroup = async groupId => {
    try {
      this.setState({ isLoading: true });
      await this.removeGroupAccountBinding(groupId);
      await API.program.delete({ id: groupId });
      window.location.reload();
    } catch (error) {
      notify.show(
        `Error. Delete unsuccessful. Error status: ${error.status}`,
        'error'
      );
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const tableColumns = [
      {
        title: 'Group Name',
        key: 'name',
        dataIndex: 'name'
      },
      {
        title: 'Hippo Account Manager',
        key: 'manager',
        dataIndex: ['manager', 'email']
      },
      {
        title: 'Subscriptions',
        key: 'courses',
        dataIndex: ['courses', 'length']
      },
      {
        title: 'Members',
        key: 'users',
        dataIndex: ['users', 'length']
      },
      {
        title: 'Date Created',
        key: 'createdAt',
        render: (text, record) =>
          moment(record.createdAt).format('MMM DD, YYYY')
      },
      {
        title: '',
        key: 'delete',
        render: (text, record) => (
          <Popconfirm
            title="Remove this group?"
            onConfirm={async e => await this.removeGroup(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger" icon={<DeleteOutlined />} size="small" />
          </Popconfirm>
        )
      }
    ];

    if (this.state.isLoading) {
      return <MessageLoader />;
    }

    const sortOptions = {
      name: 'Name A-Z',
      '-name': 'Name Z-A',
      createdAt: 'Date Created OLD - NEW',
      '-createdAt': 'Date Created NEW - OLD'
    };

    return (
      <div>
        <BreadcrumbNavigation
          data={[
            BreadcrumbConstants.HippoAdmin,
            BreadcrumbConstants.GroupManagement,
            { title: 'Groups' }
          ]}
        />
        <hr />
        <PaginatedList
          resource={API.program}
          defaultOptions={{ include: 'manager', sort: 'name' }}
          onLoadRows={resource => API.simplifyResource(resource)}
          pageLimit={20}
          withMessage={true}
          renderHeader={(actions, refresh) => (
            <div className="groups-header">
              <ListHeaderActionItems
                actions={actions}
                useCustomSearchChildren={true}
                onCreateNew={() => this.createGroup()}
                createButtonText="Group"
                sortOptions={sortOptions}
                getResourceOptions={this.getResourceOptions}
                filter={newValue => this.filterEpisodes(newValue, actions)}
              >
                <Input
                  placeholder="Search groups"
                  className="groups-header__search"
                  value={this.state.groupNameSearch}
                  onPressEnter={e => {
                    this.setState({ groupNameSearch: e.target.value });
                    actions.setFilter(
                      'name',
                      e.target.value && `:${e.target.value}`
                    );
                  }}
                  onChange={e => {
                    this.setState({ groupNameSearch: e.target.value });
                    actions.setFilter(
                      'name',
                      e.target.value && `:${e.target.value}`
                    );
                  }}
                />
              </ListHeaderActionItems>
            </div>
          )}
          renderList={(data, refresh) => (
            <Table
              className="groups-table"
              dataSource={data}
              rowKey="id"
              pagination={false}
              columns={tableColumns}
              onRow={record => ({
                onClick: event => {
                  //don't open group editor when clicking confirm delete
                  if (event.target.className === 'ant-table-cell') {
                    this.props.history.push({
                      pathname: `/group-management/group/${record.id}`,
                      state: { groupName: this.state.groupNameSearch }
                    });
                  }
                }
              })}
            />
          )}
          renderEmptyList={() => <Empty description="No groups to show" />}
        />
        <br />
      </div>
    );
  }
}
