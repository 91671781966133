import React from 'react';
import { Route } from 'react-router-dom';

import Products from '../containers/products/Products';

const ProductRoutes = () => {
  return (
    <>
      <Route exact={true} path="/products" component={Products} />
    </>
  );
};

export default ProductRoutes;
