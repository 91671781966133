import { gql } from '@apollo/client';

const GET_USERS_QUERY = gql`
  query GetUsers($offset: Int, $limit: Int, $filter: [AccountFilter!]) {
    accounts(
      offset: $offset
      first: $limit
      orderBy: [FIRST_NAME_ASC, LAST_NAME_ASC]
      filter: { deletedAt: { isNull: true }, and: $filter }
    ) {
      totalCount
      nodes {
        id
        firstName
        lastName
        suffix
        email
      }
    }
  }
`;

export default GET_USERS_QUERY;
