import { gql } from '@apollo/client';

const CREATE_ORGANIZATION_MUTATION = gql`
  mutation CreateOrganization(
    $type: EnumOrganizationsType!
    $name: String!
    $managerId: UUID!
    $id: UUID!
    $updatedAt: Datetime!
    $createdAt: Datetime!
  ) {
    createOrganization(
      input: {
        organization: {
          id: $id
          name: $name
          managerId: $managerId
          updatedAt: $updatedAt
          type: $type
          createdAt: $createdAt
        }
      }
    ) {
      clientMutationId
    }
  }
`;

export default CREATE_ORGANIZATION_MUTATION;
