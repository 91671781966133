import React, { useState } from 'react';
import { Input, List, Button, Popconfirm, Switch } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { useMutation } from '@apollo/client';
import CREATE_ORGANIZATION_EMAIL_DOMAIN_MUTATION from './queries/CreateOrganizationEmailDomainMutation';
import UPDATE_ORGANIZATION_EMAIL_DOMAIN_ISENABLED_MUTATION from './queries/UpdateOrganizationEmailDomainIsEnabledMutation';
import DELETE_ORGANIZATION_EMAIL_DOMAIN_MUTATION from './queries/DeleteOrganizationEmailDomainMutation';

const EmailDomainInput = props => {
  const { initialDomains = [], refreshParent, organizationId } = props;
  const [domain, setDomain] = useState('');
  const [errorText, setErrorText] = useState('');

  const [addEmailDomain] = useMutation(
    CREATE_ORGANIZATION_EMAIL_DOMAIN_MUTATION,
    {
      onCompleted: async (data, clientOptions) => {
        if (refreshParent) {
          await refreshParent();
        }
      }
    }
  );

  const [updateIsEnabled] = useMutation(
    UPDATE_ORGANIZATION_EMAIL_DOMAIN_ISENABLED_MUTATION,
    {
      onCompleted: async (data, clientOptions) => {
        if (refreshParent) {
          await refreshParent();
        }
      }
    }
  );
  const [deleteOrganizationEmailDomain] = useMutation(
    DELETE_ORGANIZATION_EMAIL_DOMAIN_MUTATION,
    {
      onCompleted: async (data, clientOptions) => {
        if (refreshParent) {
          await refreshParent();
        }
      }
    }
  );

  const isValidDomain = domain => {
    let isValid = false;
    if (domain.length === 0) {
      if (errorText !== '') {
        setErrorText('');
      }
      isValid = false;
    } else if (/^[a-zA-Z0-9-]*(?:\.[a-zA-Z0-9-]{2,})+$/.test(domain)) {
      if (errorText !== '') {
        setErrorText('');
      }
      isValid = true;
    } else if (!errorText) {
      setErrorText(
        'Your domain name is invalid. Example of valid domain: carbonhealth.com'
      );
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async () => {
    if (isValidDomain(domain)) {
      await addEmailDomain({
        variables: {
          id: uuidv4(),
          allowedEmailDomain: domain,
          organizationId,
          createdAt: new Date(),
          updatedAt: new Date(),
          isEnabled: true
        }
      });

      setDomain('');
    }
  };

  const handleDelete = async targetDomain => {
    await deleteOrganizationEmailDomain({
      variables: { id: targetDomain.id }
    });
  };

  const handleToggle = async targetDomain => {
    await updateIsEnabled({
      variables: { id: targetDomain.id, isEnabled: !targetDomain.isEnabled }
    });
  };

  return (
    <div>
      <span>
        <div
          style={{
            width: '20rem',
            display: 'inline-grid',
            marginBottom: '.5rem'
          }}
        >
          <Input
            placeholder="Type a domain including its extension"
            value={domain}
            onChange={e => setDomain(e.target.value)}
          />
          <p style={{ color: 'red' }}>{errorText}</p>
        </div>
      </span>

      <Button
        style={{ marginLeft: '.25rem' }}
        type="primary"
        disabled={!isValidDomain(domain)}
        onClick={handleSubmit}
      >
        Submit
      </Button>
      <List
        style={{ width: '30rem', marginLeft: '1.5rem', display: 'inline-grid' }}
        header={
          <div>
            <span>Current Email Domain(s)</span>
            <span style={{ marginLeft: '5rem' }}>Domain Enabled?</span>
          </div>
        }
        bordered={true}
        dataSource={initialDomains}
        renderItem={(item, index) => {
          return (
            <List.Item
              key={`${item.domain}-${index}`}
              actions={[
                <Switch
                  key={item + 'switch'}
                  checked={item.isEnabled}
                  onClick={() => handleToggle(item)}
                />,
                item.isEnabled ? (
                  <span style={{ color: 'green' }}>&#10003;</span>
                ) : (
                  <span style={{ color: 'red' }}>&#10005;</span>
                ),
                <Popconfirm
                  key={item + 'delete'}
                  title="Are you sure you want to delete this item?"
                  onConfirm={() => handleDelete(item)}
                >
                  <Button
                    style={{ marginLeft: '1rem', paddingBottom: '26px' }}
                    size="small"
                    type="danger"
                  >
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              ]}
            >
              {item.allowedEmailDomain}
            </List.Item>
          );
        }}
      />
    </div>
  );
};

export default EmailDomainInput;
