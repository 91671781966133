import React, { useState, useCallback, useEffect } from 'react';
import { notify } from 'react-notify-toast';
import moment from 'moment';
import AttachmentsInput from '../Attachments/AttachmentFileList';
import EditableLabel from '../EditableLabel/EditableLabel';

import * as API from '../../API';

import './Note.css';

export const Note = ({ name, date, body, canEdit, noteId, setFetchNotes }) => {
  const [attachments, setAttachments] = useState([]);

  const fetchAttachments = useCallback(async () => {
    try {
      const noteAttachmentBindings = await API.noteAttachmentBinding.where({
        filter: {
          note: noteId
        },
        options: {
          include: ['attachment']
        }
      });

      const attachments = noteAttachmentBindings.included
        .filter(include => include.type === 'attachment')
        .map(attachment => attachment.attributes);

      setAttachments(attachments);
    } catch (error) {
      if (error.data) {
        notify.show(error.data.errors[0].detail[0].message, 'error');
      } else {
        throw error;
      }
    }
  }, [noteId]);

  const updateNote = async value => {
    try {
      await API.note.update({
        id: noteId,
        attributes: {
          bodyText: value
        }
      });
      setFetchNotes(true);
    } catch (error) {
      if (error.data) {
        notify.show(error.data.errors[0].detail[0].message, 'error');
      } else {
        throw error;
      }
    }
  };

  useEffect(() => {
    fetchAttachments();
  }, [fetchAttachments]);

  return (
    <div className="display-note__content">
      <div className="display-note__top">
        <div className="display-note__name">{name}</div>
        <div className="display-note__date">
          {moment(date)
            .tz(process.env.REACT_APP_DISPLAY_TIMEZONE)
            .format('M/D/YY, h:mm A')}{' '}
          PST
        </div>
      </div>
      <div className="display-note__body">
        <EditableLabel
          className="group__subscriptions-notes"
          defaultValue={body}
          onChange={value => updateNote(value)}
          disabled={!canEdit}
        />
      </div>
      <AttachmentsInput
        attachments={attachments}
        onCreateItem={async attachmentId => {
          await API.noteAttachmentBinding.create({
            attributes: {
              createdAt: new Date(),
              updatedAt: new Date()
            },
            relationships: {
              note: {
                data: {
                  type: 'note',
                  id: noteId
                }
              },
              attachment: {
                data: {
                  type: 'attachment',
                  id: attachmentId
                }
              }
            }
          });
          fetchAttachments();
        }}
        onUpdateItem={() => {
          fetchAttachments();
        }}
        onDeleteItem={() => {
          fetchAttachments();
        }}
        canEdit={canEdit}
      />
    </div>
  );
};
