import { gql } from '@apollo/client';

const GET_PRODUCT_ITEMS_QUERY = gql`
  query getProductItems {
    itemsList {
      id
      title
      sku
      parentId
      isAddOn
      category
      course {
        id
        courseType {
          id
          title
          shortname
        }
      }
    }
    coursesList {
      id
      title
      shortname
      courseType {
        id
        title
        shortname
      }
      product {
        id
      }
    }
    productsList {
      id
      title
      vertical {
        id
        title
      }
    }
  }
`;

export default GET_PRODUCT_ITEMS_QUERY;
