import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'antd';
import { Link, Route } from 'react-router-dom';

class Dashboard extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    window.setError = err => {
      window.scrollTo(0, 0);
      throw err;
    };
  }

  render() {
    return (
      <>
        <Route
          path="/dashboard"
          exact={true}
          render={() => (
            <Breadcrumb tag="nav" style={{ padding: '20px 0' }}>
              <Breadcrumb.Item>
                <Link to="/dashboard" href="/dashboard">
                  Hippo Admin
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          )}
        />
      </>
    );
  }
}

export default Dashboard;
