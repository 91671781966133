import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, List } from 'antd';

import * as API from '../../API';
import AttachmentItem from './AttachmentItem';
import AttachmentFileInputUpload from './AttachmentFileInputUpload';

import './AttachmentsInput.css';

const AttachmentFileList = ({
  attachments,
  onCreateItem,
  onUpdateItem,
  onDeleteItem,
  showCmeFields,
  canEdit
}) => {
  const defaultFileData = {
    key: '',
    fileName: '',
    fileSize: '',
    mimeType: '',
    isContentSummary: false
  };
  const [addingNew, setAddingNew] = useState(false);
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [activityTimeInSeconds, setActivityTimeInSeconds] = useState(300);
  const [isContentSummary, setIsContentSummary] = useState(
    showCmeFields && !attachments?.length > 0
  );
  const [fileData, setFileData] = useState(defaultFileData);
  const [newAttachmentError, setNewAttachmentError] = useState('');

  const resetForm = () => {
    setTitle('');
    setSubtitle('');
    setActivityTimeInSeconds(300);
    setIsContentSummary(showCmeFields && !attachments.length > 0);
    setFileData(defaultFileData);
    setNewAttachmentError('');
  };

  const deleteAttachment = id => API.attachment.delete({ id });
  return (
    <div>
      <List
        className="attachment-list"
        dataSource={attachments}
        header={
          attachments?.length > 0 ? (
            <div
              className={
                showCmeFields
                  ? 'attachment-list__header--rap'
                  : 'attachment-list__header'
              }
            >
              <span className="attachment-list__header-title">Title</span>
              <span className="attachment-list__header-subtitle">Subtitle</span>
              {showCmeFields ? (
                <>
                  <span className="attachment-list__header-activity-time">
                    Activity Time
                  </span>
                  <span className="attachment-list__header-content-summary">
                    Content Summary
                  </span>
                </>
              ) : null}
            </div>
          ) : null
        }
        renderItem={item => (
          <AttachmentItem
            item={item}
            onUpdate={onUpdateItem}
            onDelete={async () => {
              await deleteAttachment(item.id);
              if (onDeleteItem) {
                onDeleteItem(item);
              }
            }}
            showCmeFields={showCmeFields}
            canEdit={canEdit}
          />
        )}
      />
      {addingNew && (
        <AttachmentFileInputUpload
          title={title}
          subtitle={subtitle}
          activityTimeInSeconds={activityTimeInSeconds}
          isContentSummary={isContentSummary}
          fileData={fileData}
          showCmeFields={showCmeFields}
          setFileData={setFileData}
          setTitle={setTitle}
          setSubtitle={setSubtitle}
          setActivityTimeInSeconds={setActivityTimeInSeconds}
          setIsContentSummary={setIsContentSummary}
          newAttachmentError={newAttachmentError}
          setNewAttachmentError={setNewAttachmentError}
          resetForm={resetForm}
          onCreateItem={onCreateItem}
        />
      )}
      <span className="attachments-input__buttons">
        {canEdit && (
          <Button
            onClick={() => {
              resetForm();
              setAddingNew(!addingNew);
            }}
          >
            {addingNew ? 'Close' : 'New Attachment'}
          </Button>
        )}
      </span>
    </div>
  );
};

AttachmentFileList.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      key: PropTypes.string
    })
  ),
  onCreateItem: PropTypes.func,
  onUpdateItem: PropTypes.func,
  onDeleteItem: PropTypes.func,
  showCmeFields: PropTypes.bool,
  canEdit: PropTypes.bool
};

AttachmentFileList.defaultProps = {
  attachments: [],
  onCreateItem: null,
  onUpdateItem: null,
  onDeleteItem: null,
  showCmeFields: false,
  canEdit: true
};

export default AttachmentFileList;
