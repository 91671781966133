import { gql } from '@apollo/client';

const CREATE_ORGANIZATION_PROGRAM_BINDING_MUTATION = gql`
  mutation createOrganizationProgramBinding(
    $clinicName: String!
    $createdAt: Datetime!
    $id: UUID!
    $isEnabled: Boolean!
    $organizationId: UUID!
    $programId: UUID!
    $updatedAt: Datetime!
  ) {
    createOrganizationProgramBinding(
      input: {
        organizationProgramBinding: {
          id: $id
          clinicName: $clinicName
          organizationId: $organizationId
          programId: $programId
          updatedAt: $updatedAt
          createdAt: $createdAt
          isEnabled: $isEnabled
        }
      }
    ) {
      clientMutationId
    }
  }
`;

export default CREATE_ORGANIZATION_PROGRAM_BINDING_MUTATION;
