import React from 'react';
import PropTypes from 'prop-types';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import HtmlToReact, { Parser as HtmlToReactParser } from 'html-to-react';

import { CodeOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';

import { Tabs } from 'antd';

import PlaceholderToolbar from './PlaceholderToolbar';

import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './EditorInput.css';

const { TabPane } = Tabs;

export default class EditorInput extends React.Component {
  static createEditorStateFromHtml(html) {
    if (!html) {
      return EditorState.createEmpty();
    }

    return EditorState.createWithContent(stateFromHTML(html));
  }

  static propTypes = {
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.string
  };

  static defaultProps = {
    onChange: () => {},
    onBlur: () => {},
    value: ''
  };

  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorInput.createEditorStateFromHtml(props.value),
      value: props.value,
      previewMd: true,
      placeholderOptions: [
        {
          key: 'firstName',
          value: '{{firstName}}',
          text: 'First Name',
          example: 'Jane'
        },
        {
          key: 'lastName',
          value: '{{lastName}}',
          text: 'Last Name',
          example: 'Smith'
        },
        {
          key: 'earned',
          value: '{{credits}}',
          text: 'Earned Credits',
          example: 0.25
        },
        {
          key: 'completionDate',
          value: '{{completionDate}}',
          text: 'CME Completion Date',
          example: 'December 10, 2019'
        },
        {
          key: 'ifMd',
          value:
            '{{ifMd}}\nEnter MD text here\n{{else}}\nEnter non-MD text here\n{{endif}}',
          pattern: /{{ifMd}}([\s\S]*){{else}}([\s\S]*){{endif}}/gi,
          text: 'If MD...',
          boolean: true
        }
      ]
    };
  }

  onEditorStateChange = editorState => {
    const value = stateToHTML(editorState.getCurrentContent());

    this.setState({
      editorState,
      value
    });

    this.triggerChange(value);
  };

  onRawEditorStateChange = event => {
    const { value } = event.target;
    const editorState = EditorInput.createEditorStateFromHtml(value);

    this.setState({
      editorState,
      value
    });

    this.triggerChange(value);
  };

  static getDerivedStateFromProps(props, state) {
    if ('value' in props && props.value !== state.value) {
      return {
        editorState: EditorInput.createEditorStateFromHtml(props.value),
        value: props.value
      };
    }
    return null;
  }

  triggerChange = value => {
    const { onChange } = this.props;
    onChange(value);
  };

  handlePastedText = () =>
    // todo handle rich text paste
    // if (html) {
    //   const { editorState } = this.state;
    //   const pastedState = EditorInput.createEditorStateFromHtml(html);
    //   const blockMap = pastedState.getCurrentContent().getBlockMap();
    //   const newState = Modifier.replaceWithFragment(editorState.getCurrentContent(), editorState.getSelection(), blockMap);
    //   this.setState({editorState: newState});
    //   return false;
    // }
    false;

  previewReplacement = (text, flags) => {
    const result = this.state.placeholderOptions.reduce(
      (currentText, placeholder) => {
        const searchPattern = placeholder.pattern
          ? placeholder.pattern
          : new RegExp(placeholder.value, 'g');

        if (placeholder.boolean) {
          // Replacetext is match 0 if true or match 1 if false
          const replaceText = flags[placeholder.key] ? '$1' : '$2';
          return currentText.replace(searchPattern, replaceText);
        }
        // Replacetext is placeholder.example
        return currentText.replace(searchPattern, placeholder.example);
      },
      text
    );

    return result;
  };

  generatePreview = () => {
    // todo match styling to frontend (allow styling to be passed in?)

    const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(
      React
    );
    const processingInstructions = [
      {
        shouldProcessNode() {
          return true;
        },
        processNode: processNodeDefinitions.processDefaultNode
      }
    ];

    const htmlToReactParser = new HtmlToReactParser();
    // todo allow toggling between md and non-md
    const reactComponent = htmlToReactParser.parseWithInstructions(
      this.previewReplacement(this.props.value, {
        if_md: this.state.previewMd
      }),
      () => true,
      processingInstructions
    );
    return reactComponent;
  };

  imageUploadCallBack = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      const img = new Image();
      reader.onload = function(e) {
        img.src = this.result;
        resolve({
          data: {
            link: img.src
          }
        });
      };
    });

  render() {
    return (
      <Tabs>
        <TabPane
          key="rich"
          tab={
            <span>
              <EditOutlined />
              Rich Text
            </span>
          }
        >
          <Editor
            editorState={this.state.editorState}
            onEditorStateChange={this.onEditorStateChange}
            onBlur={this.props.onBlur}
            wrapperClassName="editor-input-wrapper"
            editorClassName="editor-input-editor"
            handlePastedText={this.handlePastedText}
            toolbar={{
              options: ['inline', 'blockType', 'image', 'list', 'history'],
              blockType: {
                options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6']
              },
              inline: {
                options: ['bold', 'italic', 'underline', 'strikethrough']
              },
              image: {
                uploadEnabled: true,
                uploadCallback: this.imageUploadCallBack,
                urlEnabled: false,
                previewImage: true,
                inputAccept: 'image/*',
                alt: { present: false, mandatory: false },
                alignmentEnabled: true,
                defaultSize: {
                  height: '100',
                  width: '100'
                }
              }
            }}
            toolbarCustomButtons={[
              <PlaceholderToolbar
                key="toolbar"
                options={this.state.placeholderOptions}
              />
            ]}
          />
        </TabPane>
        <TabPane
          key="code"
          tab={
            <span>
              <CodeOutlined />
              HTML
            </span>
          }
        >
          <textarea
            className="editor-input-raw-editor"
            value={this.state.value}
            onChange={this.onRawEditorStateChange}
          />
        </TabPane>
        <TabPane
          key="eye"
          tab={
            <span>
              <EyeOutlined />
              Preview
            </span>
          }
        >
          <div className="previewPane">{this.generatePreview()}</div>
        </TabPane>
      </Tabs>
    );
  }
}
