import React from 'react';
import { Layout } from 'antd';
import Sidebar from './Sidebar';

import './SidebarPage.css';

const { Header, Content } = Layout;
const HIPPO_LOGO = require('../images/hippo.png');

const logoStyles = {
  height: '31px',
  backgroundColor: 'rgba(255,255,255,.2)',
  margin: '16px 24px 16px 0',
  cssFloat: 'left'
};

const relativeContainer = {
  position: 'relative'
};

const absoluteContainer = {
  position: 'fixed',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'stretch',
  width: '100%',
  height: '100%'
};

const SidebarPage = ({ defaultOpenKeys, logout, children }) => {
  return (
    <div style={relativeContainer}>
      <div style={absoluteContainer}>
        <Layout className="layout">
          <Header>
            <img src={HIPPO_LOGO} style={logoStyles} alt="Hippo Education" />
          </Header>
          <Layout>
            <Sidebar defaultOpenKeys={defaultOpenKeys} onLogout={logout} />
            <Content className="main-view">{children}</Content>
          </Layout>
        </Layout>
      </div>
    </div>
  );
};

export default SidebarPage;
