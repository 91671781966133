import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AWSUploadInput from './AWSUploadInput';
import * as API from '../../API';

import './AudioUpload.css';
import getSlug from '../../utils/getSlug';
import config from '../../config';

export default function AudioUpload({
  name,
  onChange,
  className,
  multiple = true,
  chapterId = null,
  newChapterHasCme = true,
  ...rest
}) {
  return (
    <div className={classNames('audio-upload', className)}>
      <AWSUploadInput
        name={name}
        accept="audio/*"
        multiple={multiple}
        icon="customer-service"
        path={process.env.REACT_APP_MEDIA_PATH}
        type="audio"
        transcode={true}
        text={
          multiple
            ? 'Drag and Drop multiple audio files here to begin building playlist'
            : 'Drag and Drop an audio file here to add or change the audio'
        }
        {...rest}
        onFileComplete={async file => {
          const media = (
            await API.media.create({
              attributes: {
                id: file.originFileObj.key,
                originalFileName: file.originFileObj.name,
                duration: 0,
                createdAt: new Date(),
                updatedAt: new Date(),
                pipelineError: 'processing'
              },
              relationships: {}
            })
          ).data;

          let chapter;

          if (!multiple && chapterId) {
            chapter = await API.chapter.update({
              id: chapterId,
              relationships: {
                media: {
                  data: {
                    type: 'media',
                    id: media.id
                  }
                }
              }
            });
          } else {
            let title = file.originFileObj.name;
            title = title.substring(0, title.lastIndexOf('.'));
            chapter = await API.chapter.create({
              attributes: {
                title,
                urlSlug: getSlug(null, title, []),
                description: ' ',
                hasCme: newChapterHasCme,
                allowComments: true
              },
              relationships: {
                media: {
                  data: {
                    type: 'media',
                    id: media.id
                  }
                },
                image: {
                  data: {
                    type: 'image',
                    id: config.defaults.defaultChapterImageId
                  }
                }
              }
            });
          }

          onChange && onChange(chapter.data, media, file);
        }}
      />
    </div>
  );
}

AudioUpload.defaultProps = {
  className: null,
  onChange: null,
  rest: null
};

AudioUpload.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  rest: PropTypes.any
};
