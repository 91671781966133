import { gql } from '@apollo/client';

const CREATE_PROGRAM_MUTATION = gql`
  mutation CreateProgram(
    $id: UUID!
    $name: String!
    $managerId: UUID!
    $shortname: String!
    $createdAt: Datetime!
    $updatedAt: Datetime!
  ) {
    createProgram(
      input: {
        program: {
          id: $id
          name: $name
          managerId: $managerId
          shortname: $shortname
          updatedAt: $updatedAt
          createdAt: $createdAt
        }
      }
    ) {
      clientMutationId
      program {
        id
        name
      }
    }
  }
`;

export default CREATE_PROGRAM_MUTATION;
