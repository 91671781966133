import _ from 'underscore';
import React from 'react';
import { notification, Select, Tag, Spin, Button, Space } from 'antd';
import { Label } from 'reactstrap';
import PropTypes from 'prop-types';

import * as API from '../../../API';
import EditableLabel from '../../../components/EditableLabel/EditableLabel';
import SingleImageInput from '../../../components/Upload/SingleImageInput';

import './TagsView.css';
import TagSelectModal from './TagSelectModal';
import { Link } from 'react-router-dom';
import { MergeCellsOutlined } from '@ant-design/icons';

export default class TagView extends React.Component {
  static propTypes = {
    tag: PropTypes.object,
    onChange: PropTypes.func
  };

  state = {
    tag: null,
    associatedTag: null,
    tagImage: null,
    tagTypes: [],
    loading: false
  };

  componentDidMount() {
    this.setState({ loading: true }, async () => {
      try {
        const options = {
          sort: 'name'
        };
        const tagTypes = await API.fetchAllAndSimplify(
          API.tagType.all({ options })
        );

        await this.fetchTag();

        await this.fetchAssociatedTag();

        await this.fetchImage();

        this.setState({
          tagTypes,
          loading: false
        });
      } catch (e) {
        this.setState({ loading: false });
      }
    });
  }

  fetchTag = async () => {
    if (this.props.tag.id) {
      const tag = API.simplifyResource(
        await API.tag.find({
          id: this.props.tag.id,
          options: {
            include: 'tagType'
          }
        })
      );
      this.setState({ tag });
    }
  };

  fetchAssociatedTag = async () => {
    if (this.state.tag.hippoTag) {
      const associatedTag = API.simplifyResource(
        await API.tag.find({
          id: this.state.tag.hippoTag.id
        })
      );
      this.setState({ associatedTag });
    }
  };

  fetchImage = async () => {
    const image = this.props.tag.relationships.image;
    if (image && image.data) {
      this.setState({
        tagImage: (
          await API.image.find({
            id: image.data.id,
            options: {}
          })
        ).data
      });
    }
  };

  updateImage = async (tagId, image) => {
    await API.tag.update({
      id: tagId,
      relationships: {
        image: {
          data: image
            ? {
                type: 'image',
                id: image.id
              }
            : null
        }
      }
    });
    this.setState({ tagImage: image });
  };

  updateTag = async (id, field, value) => {
    try {
      const tag = _.clone(this.props.tag);
      tag.attributes[field] = value;

      await API.tag.update({
        id,
        attributes: tag.attributes
      });

      await this.fetchTag();
      this.props.onChange && this.props.onChange();
    } catch (e) {
      notification.error({
        message: 'Error saving Tag',
        description: e.message
      });
    }
  };

  updateTagType = async (id, tagTypeId) => {
    try {
      const newTagType = {
        data: { id: tagTypeId, type: 'tagType' }
      };

      const tag = _.clone(this.state.tag);
      tag.tagType = { id: tagTypeId, type: 'tagType' };

      await API.tag.update({
        id,
        relationships: {
          tagType: newTagType
        }
      });

      await this.fetchTag();
      this.props.onChange && this.props.onChange();
    } catch (e) {
      notification.error({
        message: 'Error saving Tag',
        description: e.message
      });
    }
  };

  addAssociatedTag = async tagId => {
    await API.tag.update({
      id: this.props.tag.id,
      relationships: {
        hippoTag: {
          data: {
            type: 'tag',
            id: tagId
          }
        }
      }
    });
    await this.fetchTag();
    await this.fetchAssociatedTag();
  };

  removeAssociatedTag = async () => {
    await API.tag.update({
      id: this.props.tag.id,
      relationships: {
        hippoTag: {
          data: null
        }
      }
    });
    this.setState({ associatedTag: null });
  };

  render() {
    const { tag } = this.state;

    const tagType = tag ? tag.tagType : null;
    const tagTypeId = tagType ? tagType.id : null;

    const showImageInput = this.state.tagTypes.reduce((memo, tagType) => {
      if (tagTypeId === tagType.id && tagType.allowImages) {
        return true;
      }

      return memo;
    }, false);

    return tag ? (
      <div>
        <div className="tag-form-container">
          <div className="tag-form-container-item">
            <Label>Tag title</Label>
            <EditableLabel
              defaultValue={this.props.tag.attributes.title}
              onChange={value => this.updateTag(tag.id, 'title', value)}
            />
          </div>

          <div className="tag-form-container-item">
            <Label>Tag type</Label>
            <Select
              loading={this.state.loading}
              dropdownMatchSelectWidth={true}
              className="tag-form-input-box"
              value={tagTypeId}
              onSelect={value => this.updateTagType(tag.id, value)}
            >
              {this.state.tagTypes.map((tag, i) => {
                return (
                  <Select.Option key={i} value={tag.id}>
                    {tag.name}
                  </Select.Option>
                );
              })}
            </Select>
          </div>

          {tagType && tagType.name !== 'Hippo' && (
            <div className="tag-form-container-item">
              <Label>Associated Hippo Tag, if necessary: </Label>
              <div>
                {this.state.associatedTag && (
                  <Tag
                    closable={true}
                    onClose={() => this.removeAssociatedTag()}
                    className="tag-view__associated-tag"
                  >
                    {this.state.associatedTag.title}
                  </Tag>
                )}
                <TagSelectModal
                  onSelection={value => this.addAssociatedTag(value)}
                  disabled={this.state.associatedTag}
                />
              </div>
            </div>
          )}

          {showImageInput && (
            <div className="tag-form-container-item">
              <Label>Tag image</Label>
              <SingleImageInput
                name="tag image"
                value={this.state.tagImage}
                onChange={image => this.updateImage(tag.id, image)}
                path="rap/tags"
              />
            </div>
          )}

          <div className="tag-form-container-item">
            <Button title="Merge" default={true}>
              <Link to={`/content/tags/merge/${tag.id}`}>
                <Space direction="horizontal">
                  <MergeCellsOutlined />
                  Merge Tag
                </Space>
              </Link>
            </Button>
          </div>
        </div>
      </div>
    ) : (
      <Spin />
    );
  }
}
