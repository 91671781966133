import React, { useState, useEffect, useCallback } from 'react';
import URLSection from './URLSection';
import OrganizationLogoSection from './OrganizationLogoSection';
import OrganizationEmailSection from './OrganizationEmailDomainsSection';
import OrganizationDefaultItemsSection from './OrganizationDefaultItemsSection';
import OrganizationAddClinicSection from './OrganizationAddClinicSection';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import Notes from '../../components/Notes/Notes';

import sortByKey from '../../utils/sortByKey';
import useDebouncedCallback from '../../utils/useDebouncedCallback';
import SET_ISENABLED_ORGANIZATION_MUTATION from './queries/SetIsEnabledOrganizationMutation';
import UPDATE_ORGANIZATION_NOTES_MUTATION from './queries/UpdateOrganizationNotesMutation';
import GET_ORGANIZATION_PAYLOAD_QUERY from './queries/GetOrganizationPayloadGQLQuery';
import { useMutation, useQuery } from '@apollo/client';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  ArrowRightOutlined,
  LeftOutlined,
  RightOutlined,
  DownOutlined
} from '@ant-design/icons';
import ContentHeader from '../content/ContentHeader';
import BreadcrumbConstants from '../../routes/BreadcrumbConstants';
import './Organization.css';
import AddOrganizationsModal from './AddOrganizationsModal';
import { Button, Divider, Switch, Table, Popover } from 'antd';
import ClinicsSection from './OrganizationClinicsSection';
import AdminSection from './OrganizationAdminSection';
const { Column } = Table;

export default function Organization() {
  const location = useLocation();
  const id = location.pathname.split('/').pop();
  const [resetUI, setResetUI] = useState(true);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [clinicList, setClinicList] = useState([]);
  const [tableSections, setTableSections] = useState([]);
  const [orgEnabled, setOrgEnabled] = useState(false);
  const [orgIsEnableable, setOrgIsEnableable] = useState(false);
  const [organization, setOrganization] = useState(null);
  const [managerName, setManagerName] = useState('');
  const [expandRowKeys, setExpandRowKeys] = useState([]);
  const [groupURL, setGroupURL] = useState('');
  const [preselectedClinics, setPreSelectedClinics] = useState();
  const [notesValue, setNotesValue] = useState('');
  const [isNotesInitialized, setIsNotesInitialized] = useState(false);
  const commentsBreadcrumb = [
    BreadcrumbConstants.HippoAdmin,
    BreadcrumbConstants.Organizations,
    { title: 'Organization' }
  ];

  const { data, refetch } = useQuery(GET_ORGANIZATION_PAYLOAD_QUERY, {
    variables: {
      id
    }
  });

  if (data && isFirstRender) {
    setGroupURL(data.organization.shortname);
    const clinics = data.programsList.map(clinic => {
      return {
        id: clinic.id,
        name: clinic.name,
        subscriptions: clinic.programCourseBindings.totalCount,
        members: clinic.programAccountBindings.totalCount
      };
    });
    sortByKey(clinics, 'name');
    setClinicList(clinics);
    setIsFirstRender(false);
  }
  const refresh = useCallback(async () => {
    await refetch({
      $variables: {
        id
      }
    });
    setResetUI(true);
  }, [id, refetch]);

  const validate = useCallback(
    tableSectionsParam => {
      let allAreValid = true;
      if (tableSectionsParam || (tableSections && tableSections.length > 0)) {
        tableSectionsParam = tableSectionsParam
          ? tableSectionsParam
          : tableSections;
        for (let index = 0; index < tableSectionsParam.length; index++) {
          const element = tableSectionsParam[index];
          if (!element.rowValidated) {
            allAreValid = false;
          }
        }
        setOrgIsEnableable(allAreValid);
      }
    },
    [tableSections]
  );

  const mapPreSelectedClinics = useCallback(
    organization => {
      return organization?.organizationProgramBindingsList?.map(binding => {
        let clinic = clinicList.find(clinic => {
          return clinic.id === binding.programId;
        });
        if (!clinic) {
          // clinic was newly added and is not in our current list
          clinic = {
            id: binding.programId,
            members: 0,
            name: binding.clinicName,
            subscriptions: 0
          };
        }
        clinicList.push(clinic);

        return { ...clinic, bindingId: binding.id, key: clinic.id };
      });
    },
    [clinicList]
  );

  const getTableSections = useCallback(
    organization => {
      const shortname = organization ? organization.shortname : groupURL;
      const url =
        organization && organization.image ? organization.image.url : '';
      const domains = organization.organizationEmailDomainsList;
      const mappedPreselectedClinics = mapPreSelectedClinics(organization);
      setPreSelectedClinics(mappedPreselectedClinics);

      const URLSectionRow = {
        tableRender: (
          <URLSection
            shortname={shortname}
            id={organization ? organization.id : id}
            refreshParent={refresh}
          />
        ),
        rowValidated:
          shortname !== '' && shortname !== undefined && shortname !== null,
        title: 'Group Sign On Organization URL',
        key: 0
      };
      const logoSectionRow = {
        tableRender: (
          <OrganizationLogoSection
            logoURL={url}
            imageId={
              organization && organization.image ? organization.image.id : null
            }
            id={id}
            refreshParent={refresh}
          />
        ),
        rowValidated: url !== '',
        title: 'Organization Logo',
        key: 1
      };
      const emailSectionRow = {
        tableRender: (
          <OrganizationEmailSection
            initialDomains={domains}
            organizationId={id}
            refreshParent={refresh}
          />
        ),
        rowValidated: domains.filter(domain => domain.isEnabled).length > 0,
        title: 'Email Domain(s)',
        key: 2
      };
      const defaultItemsSectionRow = {
        tableRender: (
          <OrganizationDefaultItemsSection
            previouslySelectedItems={
              organization.organizationDefaultSubscriptionsList
            }
            organizationId={id}
            refreshParent={refresh}
          />
        ),
        rowValidated:
          organization.organizationDefaultSubscriptionsList.filter(
            organizationDefaultSubscription =>
              organizationDefaultSubscription.isEnabled
          ).length > 0,
        title: 'Default Item(s)',
        key: 3
      };
      const addClinicSectionRow = {
        tableRender: (
          <OrganizationAddClinicSection
            clinics={clinicList}
            organizationId={id}
            refreshParent={refresh}
            preselectedClinics={mappedPreselectedClinics}
            orgType={organization.type}
          />
        ),
        rowValidated: organization?.organizationProgramBindingsList.length > 0,
        title: organization.type === 'MEDICAL' ? 'Add Clinic' : 'Add Cohort',
        key: 4
      };

      return [
        URLSectionRow,
        logoSectionRow,
        emailSectionRow,
        defaultItemsSectionRow,
        addClinicSectionRow
      ];
    },
    [clinicList, groupURL, id, mapPreSelectedClinics, refresh]
  );

  const [setIsEnabled] = useMutation(SET_ISENABLED_ORGANIZATION_MUTATION, {
    onCompleted: async (d, clientOptions) => {
      await refetch({
        $variables: {
          id
        }
      });
    }
  });
  const [updateOrganizationNotes] = useMutation(
    UPDATE_ORGANIZATION_NOTES_MUTATION,
    {
      onCompleted: async (d, clientOptions) => {
        await refetch({
          $variables: {
            id
          }
        });
      }
    }
  );

  const expandAll = () => {
    setExpandRowKeys([0, 1, 2, 3, 4]);
  };

  const expandIncomplete = () => {
    const expRows = [];
    for (let index = 0; index < tableSections.length; index++) {
      if (!tableSections[index].rowValidated) {
        expRows.push(index);
      }
    }
    setExpandRowKeys(expRows);
  };

  const collapseAll = () => {
    setExpandRowKeys([]);
  };

  const [debouncedUpdateOrganizationNotes] = useDebouncedCallback(
    useCallback(
      notes => {
        if (
          data &&
          data.organization.notes !== notes &&
          notes !== null &&
          notes !== undefined
        ) {
          updateOrganizationNotes({
            variables: {
              id,
              notes,
              updatedAt: new Date()
            }
          });
        }
      },
      [data, id, updateOrganizationNotes]
    ),
    1000
  );

  const handleEnableOrgToggle = async () => {
    if (orgIsEnableable) {
      const isEnabled = !orgEnabled;
      await setIsEnabled({
        variables: {
          id,
          isEnabled
        }
      });
      setOrgEnabled(isEnabled);
    }
  };

  const onExpand = e => {};

  useEffect(() => {
    if (isNotesInitialized && data && notesValue !== data.organization.notes) {
      debouncedUpdateOrganizationNotes(notesValue);
    }
  }, [data, debouncedUpdateOrganizationNotes, isNotesInitialized, notesValue]);

  useEffect(() => {
    if (data) {
      if (organization !== data.organization) {
        setOrganization(data.organization);
      }
      if (resetUI) {
        setOrgEnabled(data.organization.isEnabled);
        const sections = getTableSections(data.organization);
        setTableSections(sections);
        setNotesValue(data.organization.notes);
        setIsNotesInitialized(true);
        validate(sections);
        setResetUI(false);
      }
    }
  }, [
    data,
    getTableSections,
    mapPreSelectedClinics,
    organization,
    resetUI,
    tableSections,
    validate
  ]);

  const newName = data
    ? data.organization.manager.commentingAlias
      ? data.organization.commentingAlias
      : `${data.organization.manager.firstName} ${data.organization.manager.lastName} ${data.organization.manager.suffix}`
    : '';

  if (managerName !== newName) {
    setManagerName(newName);
  }

  const rowsValid = tableSections.map(section => {
    return section.rowValidated;
  });
  const cohortOrClinicRequirementText =
    data?.organization?.type === 'MEDICAL'
      ? 'Add at least one Clinic'
      : 'Add at least one Cohort';

  const requirementsText = [
    'Add a Group Sign On Organization URL',
    'Upload an Organization Logo',
    'Add an Email Domain to validate against',
    'Add at least one Default Item',
    cohortOrClinicRequirementText
  ];
  const popoverContent = (
    <div style={{ width: '350px', fontFamily: 'sans-serif' }}>
      <h6>
        Requirements for enabling the Group Sign On Tool for this organization:
      </h6>
      {requirementsText.map((text, index) => {
        return (
          <p key={'requirement' + index}>
            {rowsValid[index] ? (
              <CheckCircleFilled
                style={{ color: 'green', verticalAlign: 'middle' }}
              />
            ) : (
              <CloseCircleFilled
                style={{ color: 'red', verticalAlign: 'middle' }}
              />
            )}
            <span
              style={{
                display: 'inline-block',
                marginLeft: '1rem',
                verticalAlign: 'middle'
              }}
            >
              {text}
            </span>
          </p>
        );
      })}
    </div>
  );

  return (
    <div className="organization--main-view">
      <ContentHeader
        breadCrumb={commentsBreadcrumb}
        backTo={{
          to: '/group-management/organizations',
          title: (
            <>
              <LeftOutlined style={{ display: 'contents' }} /> Back to
              Organizations
            </>
          )
        }}
      />
      {data && (
        <div>
          <span>
            <span style={{ float: 'right' }}>
              <AddOrganizationsModal
                managerId={data.organization.managerId}
                type={data.organization.type}
                name={data.organization.name}
                isUpdating={true}
                id={id}
                callback={refresh}
              />
            </span>
            <h6>
              <b>{data.organization.name}</b>
            </h6>
            {data.organization.type === 'MEDICAL'
              ? 'Medical Organization'
              : 'Educational Organization'}
            <br />
            Created: {moment(data.organization.createdAt).format('MM/DD/YYYY')}
            <br />
            Hippo Organization Manager: {managerName}
          </span>
          <Divider />
          <div style={{ marginBottom: '.5rem' }}>
            <b> Group Sign On Tool</b>
            <Button style={{ marginLeft: '1rem' }} onClick={expandAll}>
              Expand All
            </Button>
            <Button style={{ marginLeft: '1rem' }} onClick={expandIncomplete}>
              Expand Incomplete
            </Button>
            <Button style={{ marginLeft: '1rem' }} onClick={collapseAll}>
              Collapse All
            </Button>
            <span
              style={{
                float: 'right'
              }}
            >
              <Popover content={popoverContent}>
                <Switch
                  onChange={handleEnableOrgToggle}
                  style={{
                    backgroundColor: orgEnabled ? 'green' : 'red',
                    marginRight: '0.5rem'
                  }}
                  disabled={!orgIsEnableable}
                  defaultChecked={orgEnabled}
                />
              </Popover>
              Enable
            </span>
          </div>
          <div style={{ border: '1px solid #E6E5E4' }}>
            <Table
              className="organization--accordion-menu"
              key="loading-done"
              dataSource={tableSections}
              showExpandColumn={false}
              onChange={(pagination, filters, sorters) => {
                document.body.scrollTop = 100; // For Safari
                document.documentElement.scrollTop = 100;
              }}
              pagination={false}
              showHeader={false}
              onRow={record => ({
                onClick: event => {
                  const expKeys = expandRowKeys.slice(0);
                  if (expKeys.indexOf(record.key) >= 0) {
                    setExpandRowKeys(
                      expKeys.filter(key => {
                        return key !== record.key;
                      })
                    );
                  } else {
                    expKeys.push(record.key);
                    setExpandRowKeys(expKeys);
                  }
                }
              })}
              expandable={{
                expandedRowRender: record => {
                  return record.tableRender;
                },
                onExpand,
                expandedRowKeys: expandRowKeys,
                expandIcon: ({ expanded, onExpand, record }) =>
                  expanded ? (
                    <DownOutlined onClick={e => onExpand(record, e)} />
                  ) : (
                    <RightOutlined onClick={e => onExpand(record, e)} />
                  )
              }}
            >
              <Column width="90%" dataIndex="title" />
              <Column
                width="1vw"
                dataIndex="rowValidated"
                render={rowValid => {
                  return (
                    <>
                      {rowValid ? (
                        <CheckCircleFilled style={{ color: 'green' }} />
                      ) : (
                        <CloseCircleFilled style={{ color: 'red' }} />
                      )}
                    </>
                  );
                }}
              />
            </Table>
          </div>
          {!orgIsEnableable && (
            <>
              <div
                style={{
                  marginTop: '1rem',
                  fontSize: '13px',
                  fontStyle: 'italic',
                  marginBottom: 10
                }}
              >
                The Group Sign On Tool is disabled. Please complete all of the
                steps above to enable it:
                <span
                  style={{
                    marginLeft: 10,
                    display: 'inline-flex',
                    alignItems: 'center'
                  }}
                >
                  <span
                    style={{
                      display: 'inline-block',
                      width: 10,
                      height: 10,
                      borderRadius: '50%',
                      backgroundColor: 'red'
                    }}
                  />
                  <ArrowRightOutlined
                    style={{
                      marginLeft: 5,
                      marginRight: 5,
                      verticalAlign: 'middle'
                    }}
                  />
                  <span
                    style={{
                      display: 'inline-block',
                      width: 10,
                      height: 10,
                      borderRadius: '50%',
                      backgroundColor: 'green'
                    }}
                  />
                </span>
              </div>
              <Divider />
            </>
          )}
          {data?.organization && clinicList.length > 0 && (
            <ClinicsSection
              clinics={clinicList}
              organizationId={id}
              refreshParent={refresh}
              preselectedClinics={preselectedClinics}
              manager={data.organization.manager}
              orgType={data.organization.type}
            />
          )}

          {data?.organization && (
            <AdminSection
              organizationId={id}
              preselectedAdmins={data.organization.organizationAdminBindingsList
                .flatMap(admin => {
                  return { ...admin, ...admin.adminAccount };
                })
                .map(binding => {
                  return { key: binding.id, ...binding };
                })
                .sort((a, b) => {
                  // Compare first names
                  const aFirstName = a.firstName
                    .toLowerCase()
                    .replace(/^\s+/, ''); // some names in db have leading spaces; we don't want ' Brent Harp' to appear before
                  const bFirstName = b.firstName // 'Aaron Anderson' so we strip out whitespace from name for sorting purposes
                    .toLowerCase()
                    .replace(/^\s+/, '');
                  if (aFirstName < bFirstName) {
                    return -1;
                  }
                  if (aFirstName > bFirstName) {
                    return 1;
                  }

                  const aLastName = a.firstName
                    .toLowerCase()
                    .replace(/^\s+/, '');
                  const bLastName = b.firstName
                    .toLowerCase()
                    .replace(/^\s+/, '');
                  // First names are equal, compare last names
                  if (aLastName < bLastName) {
                    return -1;
                  }
                  if (aLastName > bLastName) {
                    return 1;
                  }

                  // Last names are also equal
                  return 0;
                })}
              refreshParent={refresh}
            />
          )}
          {data && (
            <Notes
              bindingType="organization"
              bindingTypeId={id}
              title="Organization Notes"
            />
          )}
        </div>
      )}
    </div>
  );
}
