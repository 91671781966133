import React from 'react';
import { Button, DatePicker, Checkbox, Tooltip } from 'antd';
import { DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import EditableLabel from '../EditableLabel/EditableLabel';
import ResourceLibraryAttachmentsInput from '../Attachments/ResourceLibraryAttachmentsInput';
import AttachmentUpload from '../Upload/AttachmentUpload';
import moment from 'moment';

const getRowStyle = (isDragging, draggableStyle) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 2,
  justifyContent: 'space-between',
  userSelect: 'none',
  padding: 12,
  background: isDragging ? '#eee' : '#fff',
  boxShadow: isDragging ? '0 10px 6px -6px #777' : 'none',
  ...draggableStyle
});

const ResourceLibraryAttachmentView = ({
  section,
  handleToggleAttachmentInput,
  handleCreateAttachment,
  handleUpdateAttachment,
  handleDateChange,
  handleCheckboxChange,
  handleDeleteAttachment,
  replaceFileOnAttachment,
  attachmentInputVisible
}) => (
  <div>
    {section.attachments.length === 0 && (
      <div style={getRowStyle(false)}>No data</div>
    )}
    <Droppable droppableId={section.id} type="attachment">
      {(providedDrop, snapshotDrop) => (
        <div {...providedDrop.droppableProps} ref={providedDrop.innerRef}>
          {section.attachments.map((attachment, index) => (
            <div key={attachment.id}>
              <Draggable
                draggableId={attachment.id}
                index={index}
                type="attachment"
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getRowStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <div className="column-span-large">
                      <EditableLabel
                        defaultValue={attachment.title ?? ''}
                        onChange={value =>
                          handleUpdateAttachment({
                            variables: { id: attachment.id, title: value }
                          })
                        }
                      />
                    </div>
                    <div className="column-span-large">
                      <DatePicker
                        defaultValue={
                          attachment.courseAttachmentBindingsList &&
                          attachment.courseAttachmentBindingsList[0]
                            ?.activatedAt
                            ? moment(
                                attachment.courseAttachmentBindingsList[0]
                                  ?.activatedAt
                              )
                            : ''
                        }
                        format="MM/DD/YYYY"
                        allowClear={true}
                        onChange={date =>
                          handleDateChange(
                            attachment.courseAttachmentBindingsList &&
                              attachment.courseAttachmentBindingsList[0]?.id,
                            'activatedAt',
                            date
                          )
                        }
                      />
                    </div>
                    <div className="column-span-large">
                      <DatePicker
                        defaultValue={
                          attachment.courseAttachmentBindingsList &&
                          attachment.courseAttachmentBindingsList[0]?.expiredAt
                            ? moment(
                                attachment.courseAttachmentBindingsList[0]
                                  ?.expiredAt
                              )
                            : ''
                        }
                        format="MM/DD/YYYY"
                        allowClear={true}
                        onChange={date =>
                          handleDateChange(
                            attachment.courseAttachmentBindingsList &&
                              attachment.courseAttachmentBindingsList[0]?.id,
                            'expiredAt',
                            date
                          )
                        }
                      />
                    </div>
                    <div className="column-span-medium">
                      <Checkbox
                        checked={attachment.isUpdatedSpotlightEnabled}
                        onChange={e =>
                          handleCheckboxChange(
                            attachment.id,
                            'isUpdatedSpotlightEnabled',
                            e.target.checked
                          )
                        }
                      />
                    </div>
                    <div className="column-span-large">
                      <div className="replace-file">
                        <AttachmentUpload
                          filename={attachment.fileName}
                          hideReplace={false}
                          type="inline"
                          onComplete={fileData => {
                            replaceFileOnAttachment(fileData, attachment);
                          }}
                          canEdit={true}
                        />
                      </div>
                    </div>
                    <div className="column-span-medium button-column">
                      <Tooltip
                        title={
                          !attachment.canBeDeleted
                            ? 'This attachment has user activity logged and cannot be deleted. Please either replace the file or expire the attachment and create a new one.'
                            : ''
                        }
                      >
                        <Button
                          type="danger"
                          icon={<DeleteOutlined />}
                          onClick={() => handleDeleteAttachment(attachment)}
                          disabled={!attachment.canBeDeleted}
                        />
                      </Tooltip>
                      <MenuOutlined
                        style={{ marginLeft: '2rem', fontSize: '1.25rem' }}
                      />
                    </div>
                  </div>
                )}
              </Draggable>
            </div>
          ))}
          {providedDrop.placeholder}
          <div className="attachment-input-footer">
            <div className="attachment-input column-span-small">
              {attachmentInputVisible[section.id] ? (
                <ResourceLibraryAttachmentsInput
                  onCreateItem={attachmentId =>
                    handleCreateAttachment(section.id, attachmentId)
                  }
                  handleToggleAttachmentInput={handleToggleAttachmentInput}
                  sectionId={section.id}
                />
              ) : (
                <Button
                  type="primary"
                  onClick={() => handleToggleAttachmentInput(section.id)}
                >
                  Add Attachment
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </Droppable>
  </div>
);

export default ResourceLibraryAttachmentView;
