import { useCallback, useRef } from 'react';
// used in organization.jsx for notes section, for an example of how to use

export default function useDebouncedCallback(callback, delay) {
  const timerRef = useRef(null);
  const latestArgsRef = useRef(null);

  const debouncedCallback = useCallback(
    (...args) => {
      latestArgsRef.current = args;
      if (timerRef.current !== null) {
        clearTimeout(timerRef.current);
      }
      const invoke = () => {
        timerRef.current = null;
        callback(...latestArgsRef.current);
      };
      timerRef.current = setTimeout(invoke, delay);
      if (timerRef.current === 0) {
        invoke();
      }
    },
    [callback, delay]
  );

  const cancel = useCallback(() => {
    if (timerRef.current !== null) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  }, []);

  return [debouncedCallback, cancel];
}
