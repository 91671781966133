import React from 'react';
import EpisodeView from '../../course/EpisodeView';
import ContentHeader from '../ContentHeader';
import BreadcrumbConstants from '../../../routes/BreadcrumbConstants';

export default function AudioEpisodeView() {
  return (
    <div>
      <ContentHeader
        breadCrumb={[
          BreadcrumbConstants.HippoAdmin,
          BreadcrumbConstants.Content,
          BreadcrumbConstants.Episode
        ]}
        hasTitle={true}
        title="Audio Episodes"
      />
      <EpisodeView />
    </div>
  );
}
