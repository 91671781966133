import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import * as API from '../../API';
import { Select, Spin } from 'antd';

import './BoundItemSelector.css';

const { Option } = Select;

export default class BoundItemSelector extends React.Component {
  static propTypes = {
    // First resource in the binding relationship
    primaryItem: PropTypes.shape({}).isRequired,
    primaryResourceType: PropTypes.string.isRequired,

    // Second resource in the binding relationship
    secondaryResourceType: PropTypes.string.isRequired,
    secondaryResourceDisplayAttribute: PropTypes.string,

    // Item binding the primary and secondary resource together
    bindingResourceType: PropTypes.string.isRequired,
    defaultBindingResourceAttributes: PropTypes.shape({}),

    // Methods that occur when adding or removing a binding item
    onBindingItemRemove: PropTypes.func,
    onBindingItemAdd: PropTypes.func,

    // Placeholder text for the select box
    placeholder: PropTypes.string.isRequired,

    // Indicates if the bindingResource has a displayOrder attribute that must be followed
    orderedDisplay: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      allItems: [],
      currentItems: [],
      currentItemBindings: [],
      loading: true,
      error: null
    };
  }

  async componentDidMount() {
    await this.loadAllItems();
    await this.loadCurrentItems();
    this.setState({ loading: false });
  }

  loadAllItems = async () => {
    const options = {};
    if (this.props.secondaryResourceDisplayAttribute) {
      options.sort = this.props.secondaryResourceDisplayAttribute;
    }
    const allItems = await API.fetchAllPages(
      API[this.props.secondaryResourceType].all({ options })
    );

    this.setState({ allItems });
  };

  loadCurrentItems = async () => {
    const {
      primaryResourceType,
      primaryItem,
      bindingResourceType,
      secondaryResourceType,
      secondaryResourceDisplayAttribute,
      orderedDisplay
    } = this.props;

    const bindingSearch = {
      filter: { [primaryResourceType]: primaryItem.id },
      options: {}
    };
    if (orderedDisplay) {
      bindingSearch.options.sort = 'displayOrder';
    }

    const currentItemBindings = await API.fetchAllPages(
      API[bindingResourceType].where(bindingSearch)
    );
    const currentItemIds = currentItemBindings.map(
      binding => binding.relationships[secondaryResourceType].data.id
    );
    const currentItems = this.state.allItems
      .filter(item => currentItemIds.indexOf(item.id) >= 0)
      .map(item => ({
        id: item.id,
        [secondaryResourceDisplayAttribute]:
          item.attributes[secondaryResourceDisplayAttribute]
      }));

    this.setState({ currentItemBindings, currentItems });
  };

  handleItemChange = async value => {
    const { currentItems } = this.state;
    const currentItemIds = currentItems.map(item => item.id);
    if (value.length > currentItems.length) {
      const itemId = _.difference(value, currentItemIds)[0];
      await this.createItemBinding(itemId);
      this.props.onBindingItemAdd && this.props.onBindingItemAdd(itemId);
    } else {
      const itemId = _.difference(currentItemIds, value)[0];
      await this.deleteItemBinding(itemId);
      this.props.onBindingItemRemove && this.props.onBindingItemRemove(itemId);
    }
  };

  createItemBinding = async bindingResourceId => {
    const {
      primaryResourceType,
      primaryItem,
      secondaryResourceType,
      bindingResourceType,
      defaultBindingResourceAttributes
    } = this.props;

    try {
      const bindingToCreate = {
        attributes: { ...defaultBindingResourceAttributes },
        relationships: {
          [primaryResourceType]: {
            data: {
              type: primaryResourceType,
              id: primaryItem.id
            }
          },
          [secondaryResourceType]: {
            data: {
              type: secondaryResourceType,
              id: bindingResourceId
            }
          }
        }
      };
      await API[bindingResourceType].create(bindingToCreate);

      await this.loadCurrentItems();
    } catch (error) {
      this.setState({ error });
    }
  };

  deleteItemBinding = async bindingResourceId => {
    const { currentItemBindings } = this.state;
    const { secondaryResourceType, bindingResourceType } = this.props;

    try {
      const itemBindingIdToDelete = currentItemBindings.find(
        t =>
          t.relationships[secondaryResourceType].data.id === bindingResourceId
      ).id;
      await API[bindingResourceType].delete({
        id: itemBindingIdToDelete
      });

      await this.loadCurrentItems();
    } catch (error) {
      this.setState({ error });
    }
  };

  render() {
    const { allItems, currentItems } = this.state;
    const { placeholder, secondaryResourceDisplayAttribute } = this.props;
    const currentItemIds = currentItems.map(item => item.id);

    return (
      <div className="bound-item-selector">
        {this.state.loading ? (
          <>
            <Spin />{' '}
            <span> Loading {this.props.secondaryResourceType} selector </span>
          </>
        ) : (
          <Select
            mode="multiple"
            placeholder={placeholder}
            className="bound-item-selector__selector"
            defaultValue={currentItemIds}
            onChange={this.handleItemChange}
            optionFilterProp="title"
          >
            {allItems.map(item => (
              <Option
                key={item.id}
                title={item.attributes[secondaryResourceDisplayAttribute]}
              >
                {item.attributes[secondaryResourceDisplayAttribute]}
              </Option>
            ))}
          </Select>
        )}
      </div>
    );
  }
}
