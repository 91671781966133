import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Button, Descriptions, message, Modal, Space, Spin } from 'antd';
import { useMutation, gql } from '@apollo/client';

import ChangeAccreditorMenu from './ChangeAccreditorMenu';

export const CourseCmeActionPanel = ({
  accountId,
  course,
  selectedAccreditors,
  accreditorsByCourse
}) => {
  const [loadingMessage, setLoadingMessage] = useState(null);

  const showAccreditorChange =
    accreditorsByCourse[course.id]?.length > 1 &&
    (course.courseType.shortname === 'podcast' ||
      course.courseType.shortname === 'video' ||
      course.courseType.shortname === 'bootcamp-assessment');

  const showCmeReset =
    course.courseType.shortname === 'video' ||
    course.courseType.shortname === 'qbank';

  const showRecalculate = group =>
    group.course?.courseType?.shortname === 'podcast';

  //
  // Reset CME
  //
  const RESET_COURSE_CME_MUTATION = gql`
    mutation AdminResetCourseCme(
      $accountId: String!
      $courseId: String!
      $includeProgress: Boolean!
    ) {
      adminResetCourseCme(
        accountId: $accountId
        courseId: $courseId
        includeProgress: $includeProgress
      )
    }
  `;
  const [resetCourseCmeMutation, resetResult] = useMutation(
    RESET_COURSE_CME_MUTATION
  );
  const resetCourseCme = includeProgress => {
    Modal.confirm({
      title: 'This action cannot be undone.',
      content: (
        <div>
          Are you sure you want to reset CME
          {includeProgress && ' and progress '} for{' '}
          <strong>{course.title}</strong>
        </div>
      ),
      onOk: () => {
        resetCourseCmeMutation({
          variables: {
            accountId,
            courseId: course.id,
            includeProgress
          }
        });
        setLoadingMessage('Resetting Cme');
      }
    });
  };

  //
  // Recalculate CME
  //
  const ADMIN_RECACLULATE_ACCOUNT_CME_MUTATION = gql`
    mutation AdminRecalculateAccountCme(
      $accountId: String!
      $courseId: String!
    ) {
      adminRecalculateAccountCme(accountId: $accountId, courseId: $courseId)
    }
  `;
  const [adminRecalculateAccountCme, recalculateResult] = useMutation(
    ADMIN_RECACLULATE_ACCOUNT_CME_MUTATION
  );
  const adminRecalculateAccountOnClick = async () => {
    await adminRecalculateAccountCme({
      variables: { accountId, courseId: course.id }
    });
    setLoadingMessage('Recalculating CME');
  };

  //
  // Messaging and reloading
  //

  useEffect(() => {
    const data = resetResult.data || recalculateResult.data;
    const error = resetResult.error || recalculateResult.error;
    if (loadingMessage) {
      message.info(
        `${loadingMessage}. This page will reload when complete.`,
        0
      );
      if (data) {
        setTimeout(() => window.location.reload(), 500);
      }
    } else if (error) {
      message.error(`Error while ${loadingMessage}: ${error}`);
    }
  }, [loadingMessage, recalculateResult, resetResult]);

  return (
    <Descriptions bordered={true} layout="vertical" size="small">
      <Descriptions.Item label="Selected Accreditor">
        {_.get(selectedAccreditors, course.id)?.name}{' '}
        <small>{_.get(selectedAccreditors, course.id)?.creditType}</small>
      </Descriptions.Item>

      <Descriptions.Item label="Total Earned Credits for Course">
        <Space direction="vertical">
          {Object.keys(course.creditsByAccreditor).map(accreditorId => (
            <div key={accreditorId}>
              <Space>
                <strong>
                  {course.creditsByAccreditor[accreditorId].totalCredits}
                </strong>
                <small>
                  {course.creditsByAccreditor[accreditorId].totalCredits === 1
                    ? course.creditsByAccreditor[accreditorId].creditType
                    : course.creditsByAccreditor[accreditorId].creditTypePlural}
                </small>
              </Space>
              <br />
            </div>
          ))}
          {showAccreditorChange ? (
            <ChangeAccreditorMenu
              accountId={accountId}
              courseId={course.id}
              selectedAccreditorId={_.get(selectedAccreditors, course.id)?.id}
              cmeAccreditorsList={accreditorsByCourse[course.id]}
            />
          ) : null}
        </Space>
      </Descriptions.Item>
      {showCmeReset || showRecalculate ? (
        <Descriptions.Item label="CME Actions">
          <Space direction="vertical">
            {showCmeReset ? (
              <>
                {loadingMessage ? (
                  <Spin />
                ) : (
                  <Button onClick={() => resetCourseCme(true)}>
                    Reset CME and Progress
                  </Button>
                )}
              </>
            ) : null}
            {showRecalculate ? (
              <>
                <Button onClick={adminRecalculateAccountOnClick}>
                  Recalculate Earned Cme
                </Button>
              </>
            ) : null}
          </Space>
        </Descriptions.Item>
      ) : null}
    </Descriptions>
  );
};
