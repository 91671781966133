import { gql } from '@apollo/client';

const SET_ISENABLED_ORGANIZATION_MUTATION = gql`
  mutation setIsEnabled($id: UUID!, $isEnabled: Boolean) {
    updateOrganization(input: { patch: { isEnabled: $isEnabled }, id: $id }) {
      clientMutationId
    }
  }
`;

export default SET_ISENABLED_ORGANIZATION_MUTATION;
