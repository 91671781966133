import React from 'react';
import { useState } from 'react';
import * as API from '../../API';
import { Button, DatePicker, Modal } from 'antd';

export function TopicLecturesLastReviewedAtModal({ topic, lectures, onClose }) {
  const [lecturesLastReviewedAt, setLecturesLastReviewedAt] = useState(null);

  const handleSetLecturesLastReviewedAt = async () => {
    await Promise.all(
      lectures
        // lastReviewedAt only applies to lectures with media
        .filter(lecture => lecture.relationships.media.data)
        .map(async ({ id }) =>
          API.lecture.update({
            id,
            attributes: {
              lastReviewedAt: lecturesLastReviewedAt
            }
          })
        )
    );
    onClose(true);
  };

  const handleCancel = () => {
    setLecturesLastReviewedAt(null);
    onClose();
  };

  return (
    <Modal
      title={`Set ${topic.attributes.title} Lectures Last Reviewed`}
      visible={true}
      footer={null}
      onOk={() => handleSetLecturesLastReviewedAt()}
      onCancel={handleCancel}
      width={600}
    >
      <div>
        <div>
          <strong>WARNING:</strong> this will override the last reviewed date
          for <u>all lectures</u> in this topic.
        </div>
        <div style={{ marginTop: '1rem' }}>
          <DatePicker
            defaultValue={null}
            onChange={setLecturesLastReviewedAt}
          />
          <Button type="primary" onClick={handleSetLecturesLastReviewedAt}>
            Set All
          </Button>
        </div>
      </div>
    </Modal>
  );
}
