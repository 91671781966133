import React from 'react';
import { Button, Modal, List } from 'antd';
import { MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons';

const DefaultItemsSummaryModal = ({
  visible,
  onDone,
  updatedItemList,
  changedItems
}) => {
  if (updatedItemList) {
    const addedItems = changedItems.filter(
      changedItem =>
        changedItems.find(item => item.id === changedItem.id).isSelected
    );
    const removedItems = changedItems.filter(
      changedItem =>
        !changedItems.find(item => changedItem.id === item.id)?.isSelected
    );
    const compactListItem = { borderBottomWidth: '0px', padding: '0px' };
    return (
      <Modal
        width="60vw"
        title="Default Items Summary"
        visible={visible}
        onCancel={onDone}
        footer={[
          <Button type="primary" key="done" onClick={onDone}>
            Done
          </Button>
        ]}
      >
        <span style={{ display: 'inline-grid', width: '49%' }}>
          {((addedItems && addedItems.length > 0) ||
            (removedItems && removedItems.length > 0)) && <b>Changes:</b>}
          {addedItems && addedItems.length > 0 && (
            <List
              dataSource={addedItems}
              renderItem={item => (
                <List.Item style={compactListItem}>
                  <PlusCircleFilled
                    style={{
                      color: 'green',
                      display: 'inline-flex',
                      alignItems: 'center',
                      marginRight: '.25rem'
                    }}
                  />
                  {item.title}
                </List.Item>
              )}
            />
          )}
          {removedItems && removedItems.length > 0 && (
            <List
              dataSource={removedItems}
              renderItem={item => (
                <List.Item style={compactListItem}>
                  <MinusCircleFilled
                    style={{
                      color: 'red',
                      display: 'inline-flex',
                      alignItems: 'center',
                      marginRight: '.25rem'
                    }}
                  />
                  {item.title}
                </List.Item>
              )}
            />
          )}
        </span>
        <span style={{ display: 'inline-grid', width: '49%' }}>
          <b>Updated Items List:</b>
          <List
            split={false}
            dataSource={updatedItemList}
            renderItem={item => (
              <List.Item style={compactListItem}>{item.title}</List.Item>
            )}
          />
        </span>
      </Modal>
    );
  } else {
    return <>no items</>;
  }
};

export default DefaultItemsSummaryModal;
