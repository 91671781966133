import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Courses from '../containers/Courses';
import Course from '../containers/Course';
import CmeDisclosure from '../containers/cme/CmeDisclosure';
import CmeBucket from '../containers/cme/CmeBucket';
import CmeManage from '../containers/cme/CmeManage';
import Ratings from '../containers/course/Ratings';
import Rating from '../containers/course/Rating';

const CourseRoutes = () => {
  return (
    <>
      <Switch>
        <Route
          exact={true}
          path={['/courses', '/courses/:courseType']}
          render={({ match }) => (
            <Courses courseType={match.params.courseType} />
          )}
        />
        <Route exact={true} path="/course/:id" component={Course} />
        <Route exact={true} path="/course/:course/cme" component={CmeManage} />
        <Route
          exact={true}
          path={[
            '/course/:course/cme_disclosure/',
            '/course/:course/cme_disclosure/:disclosureId'
          ]}
          component={CmeDisclosure}
        />
        <Route
          exact={true}
          path={[
            '/course/:course/cme_bucket/',
            '/course/:course/cme_bucket/:bucketId'
          ]}
          component={CmeBucket}
        />
        <Route
          exact={true}
          path={['/course/:course/ratings/']}
          component={Ratings}
        />
        <Route
          exact={true}
          path={['/course/:course/ratings/:contentType/:contentId']}
          component={Rating}
        />
      </Switch>
    </>
  );
};

export default CourseRoutes;
