import _ from 'underscore';
import { Checkbox, Button, Tag } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import { notify } from 'react-notify-toast';
import { withRouter } from 'react-router-dom';

import * as API from '../../API';
import EditableLabel from '../../components/EditableLabel/EditableLabel';
import ListEditor from '../../components/ListEditor/ListEditor';
import SingleImageInput from '../../components/Upload/SingleImageInput';
import FacultyAccountSearch from '../../components/FacultyAccountSearch/FacultyAccountSearch';
import RichTextEditor from '../../components/RichTextEditor';

import './FacultyMember.css';

class FacultyMember extends React.Component {
  static propTypes = {
    updateFaculty: PropTypes.func
  };
  constructor(props) {
    super(props);

    this.state = {
      facultyPhoto: null,
      account: null,
      error: null,
      chapters: [],
      courses: [],
      facultyMember: PropTypes.shape({
        id: PropTypes.string,
        attributes: PropTypes.shape({}),
        relationships: PropTypes.shape({}),
        links: PropTypes.shape({})
      })
    };
  }

  componentDidMount = async () => {
    await this.findFacultyMember();
  };

  findFacultyMember = async () => {
    try {
      const stateFacultyMember = await API.faculty.find({
        id: this.props.match.params.id,
        options: {
          include:
            'account,chapterFacultyBindings,chapterFacultyBindings.chapter,chapterFacultyBindings.chapter.episodeChapterBindings.episode.course'
        }
      });
      const chapters = stateFacultyMember.included.filter(
        includes => includes.type === 'chapter'
      );
      const account = stateFacultyMember.included.find(
        includes => includes.type === 'account'
      );
      const courses = stateFacultyMember.included.filter(
        includes => includes.type === 'course'
      );
      this.setState({
        facultyMember: stateFacultyMember.data,
        chapters,
        account,
        courses
      });
    } catch (e) {
      this.setState({ error: e });
    }
  };

  updateFacultyAccount = async () => {
    this.findFacultyMember();
  };

  updateFacultyMember = async (id, field, value) => {
    try {
      const facultyMember = _.clone(this.state.facultyMember);
      facultyMember.attributes[field] = value;

      await API.faculty.update({
        id: facultyMember.id,
        attributes: facultyMember.attributes
      });

      this.setState({ facultyMember });
      this.props.onChange && this.props.onChange();
    } catch (error) {
      if (error.data) {
        notify.show(error.data.errors[0].detail[0].message, 'error');
      } else {
        throw error;
      }
    }
  };

  render() {
    const { facultyMember, account, chapters, courses } = this.state;
    const { history } = this.props;

    return facultyMember.attributes ? (
      <div className="faculty-member">
        <div className="faculty-member__form-item">
          <div className="faculty-member__top-items">
            <div className="faculty-member__full-name">
              <Label>Full Name</Label>
              <EditableLabel
                defaultValue={facultyMember.attributes.fullName}
                onChange={value =>
                  this.updateFacultyMember(facultyMember.id, 'fullName', value)
                }
              />
            </div>
            <Button
              className="faculty-member__view-user-account-button"
              onClick={() => history.push(`/account/${account.id}`)}
              disabled={!account}
            >
              View User Account
            </Button>
          </div>
          <div className="faculty-member__form-item faculty-member__top-items">
            <div className="faculty-member__email-container">
              <Label>Email</Label>
              <div className="faculty-member__email">
                {account ? account.attributes.email : 'N/A'}
              </div>
            </div>
            <FacultyAccountSearch
              facultyMember={facultyMember}
              buttonClassName="faculty-member__user-account-button faculty-member__link-to-user-account-button"
              updateFaculty={() => this.updateFacultyAccount()}
            />
          </div>
        </div>
        <div className="faculty-member__form-item">
          <Label>Phone</Label>
          <div className="faculty-member__phone-number">
            {account ? account.attributes.phone : 'N/A'}
          </div>
        </div>
        <div className="faculty-member__form-item">
          <Label>Photo</Label>
          <SingleImageInput
            name="artwork"
            value={this.state.facultyPhoto}
            onChange={image => {
              /* TODO */
            }}
            className="faculty-member__photo"
            path="faculty/photo"
          />
        </div>
        <div className="faculty-member__form-item">
          <Label>Specialty</Label>
          <ListEditor
            list={facultyMember.attributes.specialty}
            title="Specialty"
            handleListChange={value =>
              this.updateFacultyMember(facultyMember.id, 'specialty', value)
            }
          />
        </div>
        <div className="faculty-member__form-item">
          <Label>Title/Affiliation</Label>
          <ListEditor
            list={facultyMember.attributes.title}
            title="Title"
            handleListChange={value =>
              this.updateFacultyMember(facultyMember.id, 'title', value)
            }
          />
        </div>
        <div className="faculty-member__form-item">
          <Label>Current Institution</Label>
          <ListEditor
            list={facultyMember.attributes.currentInstitution}
            title="Educational Institution"
            handleListChange={value =>
              this.updateFacultyMember(
                facultyMember.id,
                'currentInstitution',
                value
              )
            }
          />
        </div>
        <div className="faculty-member__form-item">
          <Label>Biography</Label>
          <RichTextEditor
            className="faculty-member__biography"
            defaultValue={facultyMember.attributes.biography}
            onChange={value => {
              this.updateFacultyMember(facultyMember.id, 'biography', value);
            }}
          />
        </div>
        <div className="faculty-member__form-item">
          <Label>Disclosures</Label>
          <Checkbox
            checked={facultyMember.attributes.hasNoDisclosures}
            onChange={() => {
              this.updateFacultyMember(
                facultyMember.id,
                'hasNoDisclosures',
                !facultyMember.attributes.hasNoDisclosures
              );
              this.updateFacultyMember(facultyMember.id, 'disclosure', '');
            }}
          >
            Faculty member has no financial relations to report
          </Checkbox>
          <RichTextEditor
            className="faculty-member__disclosures"
            defaultValue={facultyMember.attributes.disclosure}
            disabled={facultyMember.attributes.hasNoDisclosures}
            onChange={value => {
              this.updateFacultyMember(facultyMember.id, 'disclosure', value);
            }}
          />
        </div>
        <div className="faculty-member__form-item">
          <Label>Chapters</Label>
          {chapters.length > 0
            ? chapters.map(chapter => (
                <Tag key={chapter.id} closable={false}>
                  {chapter.attributes.title}
                </Tag>
              ))
            : 'No chapters assigned'}
          {}
        </div>
        <div className="faculty-member__form-item">
          <Label>Courses</Label>
          {courses.length > 0
            ? courses.map(course => (
                <Tag key={course.id} closable={false}>
                  {course.attributes.title}
                </Tag>
              ))
            : 'No courses assigned'}
        </div>
        <div className="faculty-member__save-notice">
          All changes are automatically saved
        </div>
      </div>
    ) : null;
  }
}

export default withRouter(FacultyMember);
