import React from 'react';
import { Spin } from 'antd';

const ResourceLibraryAttachmentHeaderView = ({ loading }) => (
  <div className="attachment-header">
    {loading && (
      <div className="column-span-small">
        <Spin />
      </div>
    )}
    <div className="column-span-large">Attachment Title</div>
    <div className="column-span-large">Activation Date</div>
    <div className="column-span-large">Expiration Date</div>
    <div className="column-span-medium">Updated Flag</div>
    <div className="column-span-large" />
    <div className="column-span-medium" />
  </div>
);

export default ResourceLibraryAttachmentHeaderView;
