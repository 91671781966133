import React, { useState, useEffect, useCallback } from 'react';
import { Button, Modal, Form, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import CREATE_PROGRAM_MUTATION from './queries/CreateProgramMutation';
import CREATE_ORGANIZATION_PROGRAM_BINDING_MUTATION from './queries/CreateOrganizationProgramBindingMutation';
import { useMutation } from '@apollo/client';
import config from '../../config';
import { getAccountsByPermissionId } from '../../utils/rolesAndPermissions';

const CreateClinicModal = ({
  manager,
  refreshParent,
  organizationId,
  orgType
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedManagerId, setSelectedManagerId] = useState(manager.id);
  const [clinicName, setClinicName] = useState('');
  const [shortName, setShortName] = useState('');
  const [accountManagers, setAccountManagers] = useState(null);
  const [formIsValid, setFormIsValid] = useState(false);
  const [managerIdErrorText, setManagerIdErrorText] = useState('');
  const [clinicNameErrorText, setClinicNameErrorText] = useState('');
  const [shortNameErrorText, setShortNameErrorText] = useState('');
  const alphanumericErrorText =
    'only alpha-numeric, hyphen(-), or underscore(_) characters are allowed';
  const validateForm = useCallback(
    validate => {
      const alphaNumericLimiter = /^([a-zA-Z0-9_-]+)$/;
      const alphaNumericSpaceLimiter = /^([a-zA-Z0-9_-\s]+)$/;
      let isShortNameValid = shortName
        ? shortName.match(alphaNumericLimiter)
        : false;
      if (isShortNameValid) {
        setShortNameErrorText('');
      } else {
        setShortNameErrorText(
          shortName.length > 0 ? alphanumericErrorText : '* required'
        );
        isShortNameValid = false;
      }

      let isManagerIdValid = selectedManagerId
        ? selectedManagerId.match(alphaNumericLimiter)
        : false;
      if (isManagerIdValid) {
        setManagerIdErrorText('');
      } else {
        setManagerIdErrorText('* required');
        isManagerIdValid = false;
      }

      let isClinicNameValid = clinicName
        ? clinicName.match(alphaNumericSpaceLimiter)
        : false;
      if (isClinicNameValid) {
        setClinicNameErrorText('');
      } else {
        setClinicNameErrorText(
          clinicName.length > 0 ? alphanumericErrorText : '* required'
        );
        isClinicNameValid = false;
      }

      setFormIsValid(isClinicNameValid && isShortNameValid && isManagerIdValid);
    },
    [shortName, selectedManagerId, clinicName, setFormIsValid]
  );

  useEffect(() => {
    if (!accountManagers) {
      const loadAccountManagers = async () => {
        try {
          const accountManagers = await getAccountsByPermissionId(
            config.permissions.canManageGroups
          );
          const defaultValue = {
            disabled: true,
            firstName: 'Select',
            id: 'select'
          };
          setAccountManagers([defaultValue, ...accountManagers]);
        } catch (e) {
          throw e;
        }
      };
      loadAccountManagers();
    }
    validateForm();
  }, [accountManagers, validateForm]);

  const [createProgramBinding] = useMutation(
    CREATE_ORGANIZATION_PROGRAM_BINDING_MUTATION,
    {
      onCompleted: async (data, clientOptions) => {
        if (refreshParent) {
          await refreshParent();
          setIsModalVisible(false);
        }
      }
    }
  );

  const [createProgram] = useMutation(CREATE_PROGRAM_MUTATION, {
    onCompleted: async (data, clientOptions) => {
      await createProgramBinding({
        variables: {
          id: uuidv4(),
          clinicName: data.createProgram.program.name,
          organizationId,
          createdAt: new Date(),
          updatedAt: new Date(),
          isEnabled: true,
          programId: data.createProgram.program.id
        }
      });
    }
  });

  const showModal = () => {
    setClinicName('');
    setShortName('');
    setSelectedManagerId('');
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      await createProgram({
        variables: {
          id: uuidv4(),
          name: clinicName,
          managerId: selectedManagerId,
          createdAt: new Date(),
          updatedAt: new Date(),
          shortname: shortName
        }
      });
    } catch (error) {
      // todo : decide how to error handle
    }
  };

  const handleManagerChange = value => {
    setSelectedManagerId(value);
    validateForm();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleClinicNameChange = event => {
    const name = event.target.value;
    setClinicName(name);
    setShortName(name.toLowerCase().replace(/ /g, '-'));
    validateForm();
  };

  const handleShortNameChange = event => {
    const inputText = event.target.value;
    const lowerCaseText = inputText.toLowerCase();
    const replacedText = lowerCaseText.replace(/ /g, '-');

    setShortName(replacedText);
    validateForm();
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        <PlusOutlined style={{ display: 'contents' }} />
        Create a New {orgType === 'MEDICAL' ? 'Clinic' : 'Cohort'}
      </Button>
      <Modal
        title={
          orgType === 'MEDICAL' ? 'Create a New Clinic' : 'Create a New Cohort'
        }
        visible={isModalVisible}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            disabled={!formIsValid}
          >
            {orgType === 'MEDICAL' ? 'Create Clinic' : 'Create Cohort'}
          </Button>
        ]}
      >
        <Form>
          <Form.Item
            label={orgType === 'MEDICAL' ? 'Clinic Name' : 'Cohort Name'}
          >
            <input
              type="text"
              value={clinicName}
              onChange={handleClinicNameChange}
            />
            {clinicNameErrorText && (
              <h6 style={{ color: 'red', fontSize: '1em' }}>
                {clinicNameErrorText}
              </h6>
            )}
          </Form.Item>
          <Form.Item label="Manager">
            <div>
              {accountManagers && (
                <Select
                  value={selectedManagerId}
                  className="group__details__manager"
                  defaultValue="select"
                  style={{
                    width: '100%',
                    marginBottom: '1rem'
                  }}
                  onChange={handleManagerChange}
                >
                  {accountManagers.map(account => (
                    <Select.Option
                      disabled={account.disabled}
                      key={account.id}
                      value={account.id}
                    >
                      {account.firstName}
                    </Select.Option>
                  ))}
                </Select>
              )}
              {managerIdErrorText && (
                <h6 style={{ color: 'red', fontSize: '1em' }}>
                  {managerIdErrorText}
                </h6>
              )}
            </div>
          </Form.Item>
          <Form.Item label="Short Name">
            <input
              type="text"
              value={shortName}
              onChange={handleShortNameChange}
            />
            {shortNameErrorText && (
              <h6 style={{ color: 'red', fontSize: '1em' }}>
                {shortNameErrorText}
              </h6>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateClinicModal;
