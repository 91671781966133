import React from 'react';
import PropTypes from 'prop-types';
import * as API from '../../API';
import { Button, Input, Modal, Table, Divider, Alert, Spin } from 'antd';

import './FacultyAccountSearch.css';
import { Label } from 'reactstrap';
const { Column } = Table;

export default class FacultyAccountSearch extends React.Component {
  static propTypes = {
    facultyMember: PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({}),
      relationships: PropTypes.shape({}),
      links: PropTypes.shape({})
    }),
    buttonClassName: PropTypes.string,
    updateFaculty: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      loading: false,
      hasLoaded: false,
      currentFacultyMemberAccount: null,
      firstName: null,
      lastName: null,
      email: null,
      accounts: [],
      facultyLinkedAccountNumbers: [],
      error: null
    };
    this.updateFaculty = this.props.updateFaculty.bind(this);
  }

  componentDidMount() {
    if (this.props.facultyMember.relationships.account.data) {
      this.loadAccount(this.props.facultyMember.relationships.account.data.id);
    }
    const fullName = this.props.facultyMember.attributes.fullName
      .replace(/[^\w\s]|_/g, '')
      .split(' ');
    this.setState({ firstName: fullName[0], lastName: fullName[1] });
    this.loadFacultyLinkedAccountNumbers();
  }

  loadAccount = async accountId => {
    const account = (await API.account.find({ id: accountId, options: {} }))
      .data;
    this.setState({
      currentFacultyMemberAccount: account,
      email: account.attributes.email
    });
  };

  loadFacultyLinkedAccountNumbers = async () => {
    const faculty = (await API.faculty.all({})).data;
    const facultyLinkedAccountNumbers = [];
    faculty.forEach(facultyMember => {
      if (facultyMember.relationships.account.data) {
        facultyLinkedAccountNumbers.push(
          facultyMember.relationships.account.data.id
        );
      }
    });
    this.setState({ facultyLinkedAccountNumbers });
  };

  showModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  search = async () => {
    const {
      firstName,
      lastName,
      email,
      facultyLinkedAccountNumbers
    } = this.state;
    this.setState({ loading: true });

    const filter = {};
    if (firstName) {
      filter.firstName = ':' + firstName;
    }

    if (lastName) {
      filter.lastName = ':' + lastName;
    }

    if (email) {
      filter.email = ':' + email;
    }

    const accounts = (
      await API.account.where({ filter, options: { sort: 'lastName' } })
    ).data;

    // Add status of whether an account is already linked to another account
    accounts.forEach(account => {
      if (facultyLinkedAccountNumbers.includes(account.id)) {
        account.attributes.linked = true;
      } else {
        account.attributes.linked = false;
      }
    });
    this.setState({ accounts, hasLoaded: true, loading: false });
  };

  linkAccount = async accountId => {
    const { facultyMember } = this.props;
    const { accounts } = this.state;

    const account = accounts.find(account => account.id === accountId);
    if (this.state.currentFacultyMemberAccount) {
      this.setState({
        error:
          'You cannot link an account to a faculty member that is already linked to an account. Please unlink the existing account prior to linking a new one.'
      });
    } else if (account.attributes.linked) {
      this.setState({
        error:
          'You cannot link and account that is already linked to another faculty member.'
      });
    } else {
      await API.faculty.update({
        id: facultyMember.id,
        relationships: {
          account: {
            data: {
              type: 'account',
              id: accountId
            }
          }
        }
      });
      this.loadAccount(accountId);
      this.updateFaculty();
      this.loadFacultyLinkedAccountNumbers();
    }
  };

  unlinkAccount = async () => {
    const { facultyMember } = this.props;

    await API.faculty.update({
      id: facultyMember.id,
      relationships: {
        account: {
          data: null
        }
      }
    });
    this.setState({ error: null, currentFacultyMemberAccount: null });
    this.updateFaculty();
    this.loadFacultyLinkedAccountNumbers();
  };

  render() {
    const {
      currentFacultyMemberAccount,
      firstName,
      lastName,
      email,
      loading,
      hasLoaded,
      accounts,
      error
    } = this.state;

    return (
      <div className="faculty-account-search">
        <Button
          className={`${this.props.buttonClassName} faculty-account-search__open-modal-button`}
          onClick={this.showModal}
        >
          {currentFacultyMemberAccount
            ? 'Change User Account'
            : 'Link to User Account'}
        </Button>
        <Modal
          title="User Account Search"
          visible={this.state.isModalOpen}
          onOk={this.closeModal}
          onCancel={this.closeModal}
        >
          <div className="faculty-account-search__search-parameters">
            {error && <Alert message={error} type="error" closable={true} />}
            <div className="faculty-account-search__current-account-options faculty-account-search__search-parameter-item">
              <Label>Current account: </Label>
              <Button
                onClick={this.unlinkAccount}
                disabled={currentFacultyMemberAccount ? false : true}
              >
                Unlink Account
              </Button>
            </div>
            <div className="faculty-account-search__search-parameter-item">
              {currentFacultyMemberAccount ? (
                <div>
                  <Label>Name: </Label>{' '}
                  {currentFacultyMemberAccount.attributes.firstName}{' '}
                  {currentFacultyMemberAccount.attributes.lastName}{' '}
                  <Label> Email: </Label>{' '}
                  {currentFacultyMemberAccount.attributes.email}
                </div>
              ) : (
                'No account attached'
              )}
            </div>

            <div className="faculty-account-search__search-form faculty-account-search__search-parameter-item">
              <Input
                placeholder="First name"
                defaultValue={firstName}
                className="faculty-account-search__search-form-item"
                onChange={e => this.setState({ firstName: e.target.value })}
                onPressEnter={this.search}
              />
              <Input
                placeholder="Last name"
                defaultValue={lastName}
                className="faculty-account-search__search-form-item"
                onChange={e => this.setState({ lastName: e.target.value })}
                onPressEnter={this.search}
              />
              <Input
                placeholder="Email"
                defaultValue={email}
                className="faculty-account-search__search-form-item"
                onChange={e =>
                  this.setState({ email: e.target.value.toLowerCase().trim() })
                }
                onPressEnter={this.search}
              />
              <Button type="primary" loading={loading} onClick={this.search}>
                Search
              </Button>
            </div>
          </div>
          {loading && (
            <div className="faculty-account-search__account-search-loading">
              <Divider />
              <Spin />
            </div>
          )}
          {hasLoaded && !loading && (
            <div className="faculty-account-search__account-display">
              <Divider />

              <Label>Results: </Label>

              <div className="faculty-account-search__account-display-list">
                {accounts && accounts.length > 0 && (
                  <Table
                    dataSource={accounts}
                    size="small"
                    onRow={account => {
                      return {
                        onClick: event => {
                          this.linkAccount(account.id);
                        }
                      };
                    }}
                    rowClassName={account => {
                      if (account.attributes.linked) {
                        return 'faculty-account-search__account-display-row-disabled';
                      } else {
                        return 'faculty-account-search__account-display-row';
                      }
                    }}
                    rowKey="id"
                  >
                    <Column
                      title="First Name"
                      dataIndex={['attributes', 'firstName']}
                    />
                    <Column
                      title="Last Name"
                      dataIndex={['attributes', 'lastName']}
                    />
                    <Column
                      title="Email Address"
                      dataIndex={['attributes', 'email']}
                    />
                  </Table>
                )}
              </div>
            </div>
          )}
        </Modal>
      </div>
    );
  }
}
