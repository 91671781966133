import { gql } from '@apollo/client';

const GET_ALL_TOPICS_AND_LECTURES_FOR_COURSE_QUERY = gql`
  query GetTopicsAndLecturesQuery($courseId: UUID!) {
    topicGroupsList(
      condition: { courseId: $courseId }
      orderBy: DISPLAY_ORDER_ASC
    ) {
      topicsList(orderBy: DISPLAY_ORDER_ASC) {
        lecturesList(orderBy: DISPLAY_ORDER_ASC) {
          displayOrder
          id
          title
          parentId
          topicId
          lastReviewedAt
        }
        id
        displayOrder
        title
        topicGroupId
      }
      displayOrder
      title
      id
    }
  }
`;

export { GET_ALL_TOPICS_AND_LECTURES_FOR_COURSE_QUERY };
