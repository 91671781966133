import { gql } from '@apollo/client';

const CREATE_ORGANIZATION_EMAIL_DOMAIN_MUTATION = gql`
  mutation AddOrganizationEmailDomain(
    $allowedEmailDomain: String!
    $createdAt: Datetime!
    $id: UUID!
    $organizationId: UUID!
    $updatedAt: Datetime!
    $isEnabled: Boolean!
  ) {
    createOrganizationEmailDomain(
      input: {
        organizationEmailDomain: {
          id: $id
          allowedEmailDomain: $allowedEmailDomain
          organizationId: $organizationId
          updatedAt: $updatedAt
          createdAt: $createdAt
          isEnabled: $isEnabled
        }
      }
    ) {
      clientMutationId
    }
  }
`;

export default CREATE_ORGANIZATION_EMAIL_DOMAIN_MUTATION;
