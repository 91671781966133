import React from 'react';
import {
  Alert,
  Button,
  Card,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import { Link } from 'react-router-dom';

import { Breadcrumb } from 'antd';

import { getTokenFromCookie } from '../utils/cookie';

export default class AddCode extends React.Component {
  state = {
    promotion: null,
    code: '',
    isLimitedUse: false,
    showSuccess: false,
    error: ''
  };

  componentDidMount() {
    fetch(
      `${process.env.REACT_APP_JSONAPI_SERVER}/api/promotion/${this.props.match.params.id}`,
      {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          Authorization: `Bearer ${getTokenFromCookie()}`
        }
      }
    )
      .then(response => {
        response
          .json()
          .then(promotion => this.setState({ promotion: promotion.promotion }));
      })
      .catch(window.setError);
  }

  async addCode() {
    if (!this.state.code) {
      return alert('You must provide a code.');
    }

    const params = {
      promotionId: this.props.match.params.id,
      isLimitedUse: this.state.isLimitedUse
    };

    const response = await fetch(
      `${process.env.REACT_APP_JSONAPI_SERVER}/api/promotion-code/${this.state.code}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getTokenFromCookie()}`
        },
        method: 'POST',
        credentials: 'same-origin',
        body: JSON.stringify(params)
      }
    );
    if (response.ok) {
      this.setState({ showSuccess: true });
      setTimeout(() => {
        this.setState({ showSuccess: false });
      }, 1500);
    } else {
      const error = await response.json();
      this.setState({ error: error.message });
    }
  }

  render() {
    if (!this.state.promotion) {
      return <div />;
    }

    return (
      <div>
        <Row>
          <Col>
            <Breadcrumb style={{ padding: '20px 0' }}>
              <Breadcrumb.Item>
                <Link to="/dashboard">Hippo Admin</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/promotions">Promotions</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/promotion/${this.state.promotion.id}/view`}>
                  {this.state.promotion.title}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active="true">
                <Link to={`/promotion/${this.state.promotion.id}/newcode`}>
                  New promotion code
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        {this.state.showSuccess ? (
          <Row>
            <Col>
              <Alert color="success">Successfully created promotion code</Alert>
            </Col>
          </Row>
        ) : null}
        {this.state.error ? (
          <Row>
            <Col>
              <Alert color="danger">{this.state.error}</Alert>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col>
            <Card body={true} style={{ width: 400 }}>
              <CardTitle>Add promotion code</CardTitle>
              <div>
                <Form>
                  <FormGroup>
                    <Label>Code (required)</Label>
                    <Input
                      bsSize="sm"
                      type="code"
                      name="code"
                      placeholder="New promotion code"
                      value={this.state.code}
                      onChange={e => this.setState({ code: e.target.value })}
                      onFocus={() => this.setState({ error: '' })}
                    />
                    <p style={{ fontSize: 12, paddingTop: 12 }}>
                      This is the code the customer enters to receive the
                      promotion. Must be unique. Not case-sensitive.
                    </p>
                  </FormGroup>
                  <FormGroup check={true}>
                    <Label check={true}>
                      <Input
                        type="checkbox"
                        value={this.state.isLimitedUse}
                        onChange={e =>
                          this.setState({ isLimitedUse: e.target.checked })
                        }
                      />
                      One-time use
                    </Label>
                  </FormGroup>
                </Form>
              </div>
              <Button color="primary" onClick={this.addCode.bind(this)}>
                Create
              </Button>
              <Link to={`/promotion/${this.state.promotion.id}/view`}>
                Go back
              </Link>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
