import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ATTACHMENTS_FOR_COURSE } from '../attachments/queries/GetAttachmentsAndSectionsForCourse';
import {
  CREATE_ATTACHMENT_SECTION,
  UPDATE_ATTACHMENT_SECTION,
  DELETE_ATTACHMENT_SECTION
} from '../attachments/mutations/AttachmentSections';
import {
  CREATE_COURSE_ATTACHMENT_BINDING,
  UPDATE_COURSE_ATTACHMENT_BINDING,
  DELETE_COURSE_ATTACHMENT_BINDING
} from '../attachments/mutations/CourseAttachmentBindings';
import { CREATE_COURSE_ATTACHMENT_SECTION_BINDING } from '../attachments/mutations/CourseAttachmentSectionBindings';
import { handlers } from './handlersLogic';
import ResourceLibraryTableView from './../../components/ResourceLibrary/ResourceLibraryTableView';
import { useAttachmentMutator } from '../attachments/hooks/useAttachmentHooks';

const ResourceLibraryManager = ({ courseId }) => {
  const [sectionsData, setSectionsData] = useState([]);
  const [unsectionedAttachments, setUnsectionedAttachments] = useState([]);
  const [expandedSections, setExpandedSections] = useState({});
  const [showNewSectionModal, setShowNewSectionModal] = useState(false);
  const [newSectionTitle, setNewSectionTitle] = useState('');
  const [attachmentInputVisible, setAttachmentInputVisible] = useState({});

  const { loading, data, refetch } = useQuery(GET_ATTACHMENTS_FOR_COURSE, {
    variables: { courseId }
  });

  const [createAttachmentSection] = useMutation(CREATE_ATTACHMENT_SECTION);
  const [createCourseAttachmentSectionBinding] = useMutation(
    CREATE_COURSE_ATTACHMENT_SECTION_BINDING
  );
  const [createCourseAttachmentBinding] = useMutation(
    CREATE_COURSE_ATTACHMENT_BINDING
  );
  const {
    replaceFileOnAttachment,
    updateAttachment,
    deleteAttachment
  } = useAttachmentMutator();
  const [deleteAttachmentSection] = useMutation(DELETE_ATTACHMENT_SECTION);
  const [updateAttachmentSection] = useMutation(UPDATE_ATTACHMENT_SECTION);
  const [updateCourseAttachmentBinding] = useMutation(
    UPDATE_COURSE_ATTACHMENT_BINDING
  );
  const [deleteCourseAttachmentBinding] = useMutation(
    DELETE_COURSE_ATTACHMENT_BINDING
  );

  useEffect(() => {
    if (data) {
      const {
        courseAttachmentSectionBindingsList,
        courseAttachmentBindingsList
      } = data;

      const sectionsMap = {};
      const unsectioned = [];

      courseAttachmentSectionBindingsList.forEach(({ attachmentSection }) => {
        sectionsMap[attachmentSection.id] = {
          id: attachmentSection.id,
          title: attachmentSection.title,
          displayOrder: attachmentSection.displayOrder,
          attachments: []
        };
      });

      courseAttachmentBindingsList.forEach(binding => {
        const attachment = binding.attachment;

        if (attachment.attachmentSectionId) {
          if (sectionsMap[attachment.attachmentSectionId]) {
            sectionsMap[attachment.attachmentSectionId].attachments.push({
              ...attachment,
              canBeDeleted: !attachment.hasAccountCmeAttempts
            });
          }
        } else {
          unsectioned.push({
            ...attachment,
            canBeDeleted: !attachment.hasAccountCmeAttempts
          });
        }
      });

      Object.values(sectionsMap).forEach(section => {
        section.attachments.sort(
          (a, b) =>
            a.positionWithinSection - b.positionWithinSection ||
            a.title.localeCompare(b.title)
        );
      });

      const normalizedSections = Object.values(sectionsMap).sort(
        (a, b) => a.displayOrder - b.displayOrder
      );

      setSectionsData(normalizedSections);
      setUnsectionedAttachments(
        unsectioned.sort(
          (a, b) =>
            a.positionWithinSection - b.positionWithinSection ||
            a.title.localeCompare(b.title)
        )
      );
    }
  }, [data]);

  const allSectionsData = useMemo(() => {
    return [
      ...sectionsData,
      {
        id: 'unsectioned',
        title: 'Unsectioned Attachments',
        attachments: unsectionedAttachments
      }
    ];
  }, [sectionsData, unsectionedAttachments]);

  const handleNewSection = useCallback(async () => {
    await handlers.handleNewSection(
      newSectionTitle,
      courseId,
      sectionsData,
      createAttachmentSection,
      createCourseAttachmentSectionBinding,
      setShowNewSectionModal,
      setNewSectionTitle,
      refetch
    );
  }, [
    newSectionTitle,
    courseId,
    sectionsData,
    createAttachmentSection,
    createCourseAttachmentSectionBinding,
    refetch
  ]);

  const handleToggleSection = useCallback(sectionId => {
    setExpandedSections(prev => ({
      ...prev,
      [sectionId]: !prev[sectionId]
    }));
  }, []);

  const handleToggleAttachmentInput = useCallback(sectionId => {
    setAttachmentInputVisible(prev => ({
      ...prev,
      [sectionId]: !prev[sectionId]
    }));
  }, []);

  const handleDeleteSection = useCallback(
    async sectionId => {
      await handlers.handleDeleteSection(
        sectionId,
        deleteAttachmentSection,
        refetch
      );
    },
    [deleteAttachmentSection, refetch]
  );
  const handleCreateAttachment = useCallback(
    async (sectionId, attachmentId) => {
      await handlers.handleCreateAttachment(
        sectionId,
        attachmentId,
        sectionsData,
        courseId,
        updateAttachment,
        createCourseAttachmentBinding,
        refetch
      );
    },
    [
      sectionsData,
      courseId,
      updateAttachment,
      createCourseAttachmentBinding,
      refetch
    ]
  );
  const handleUpdateAttachment = useCallback(
    async updateVariables => {
      await handlers.handleUpdateAttachment(
        updateVariables,
        updateAttachment,
        refetch
      );
    },
    [updateAttachment, refetch]
  );
  const handleUpdateAttachmentSection = useCallback(
    async updateVariables => {
      await handlers.handleUpdateAttachmentSection(
        updateVariables,
        updateAttachmentSection,
        refetch
      );
    },
    [updateAttachmentSection, refetch]
  );
  const handleDeleteAttachment = useCallback(
    async attachmentToDelete => {
      await handlers.handleDeleteAttachment(
        attachmentToDelete,
        deleteAttachment,
        deleteCourseAttachmentBinding,
        refetch
      );
    },
    [deleteAttachment, deleteCourseAttachmentBinding, refetch]
  );
  const handleDateChange = useCallback(
    async (bindingId, field, date) => {
      await handlers.handleDateChange(
        bindingId,
        field,
        date,
        updateCourseAttachmentBinding,
        refetch
      );
    },
    [updateCourseAttachmentBinding, refetch]
  );
  const handleCheckboxChange = useCallback(
    async (attachmentId, field, checked) => {
      await handlers.handleCheckboxChange(
        attachmentId,
        field,
        checked,
        updateAttachment,
        refetch
      );
    },
    [updateAttachment, refetch]
  );
  const reorderAttachments = useCallback(
    async event => {
      await handlers.reorderAttachments(
        event,
        allSectionsData,
        handlers.updateAttachmentPositions,
        updateAttachment,
        refetch
      );
    },
    [allSectionsData, updateAttachment, refetch]
  );
  const reorderSections = useCallback(
    async event => {
      await handlers.reorderSections(
        event,
        allSectionsData,
        updateAttachmentSection,
        refetch
      );
    },
    [allSectionsData, updateAttachmentSection, refetch]
  );

  const handlerProps = {
    setNewSectionTitle,
    setShowNewSectionModal,
    handleNewSection,
    handleToggleSection,
    handleToggleAttachmentInput,
    handleDeleteSection,
    handleCreateAttachment,
    handleUpdateAttachment,
    handleUpdateAttachmentSection,
    handleDeleteAttachment,
    handleDateChange,
    handleCheckboxChange,
    replaceFileOnAttachment,
    reorderAttachments,
    reorderSections
  };

  const stateProps = {
    allSections: allSectionsData,
    expandedSections,
    attachmentInputVisible,
    loading,
    showNewSectionModal,
    newSectionTitle
  };

  return (
    <div className="resource-library">
      <ResourceLibraryTableView {...stateProps} {...handlerProps} />
    </div>
  );
};

export default ResourceLibraryManager;
