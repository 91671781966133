import React from 'react';
import moment from 'moment';
import _ from 'underscore';
import { Link } from 'react-router-dom';
import ReactJson from 'react-json-view';
import config from '../../config';

import { Button, Modal, Table, Tag, Tooltip } from 'antd';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';

function badgeForStatus(status) {
  const mapping = {
    ACTIVE: <Tag color="green">Active</Tag>,
    CANCELLED: <Tag color="gold">Cancelled</Tag>,
    EXPIRED: <Tag color="red">Expired</Tag>,
    DELETED: <Tag color="red">Deleted</Tag>,
    PENDING: <Tag color="blue">Pending</Tag>
  };

  if (mapping[status]) {
    return mapping[status];
  }

  return <Tag>{status}</Tag>;
}

const statusFilters = [
  {
    text: 'Active',
    value: 'Active'
  },
  {
    text: 'Expired',
    value: 'Expired'
  },
  {
    text: 'Pending',
    value: 'Pending'
  },
  {
    text: 'Cancelled',
    value: 'Cancelled'
  }
];

export function SubscriptionTable({
  handleCancelSubscription,
  handleDeleteSubscription,
  handleEditStartDate,
  handleEditEndDate,
  handleShowBilling,
  subscriptionsForCourse
}) {
  return (
    <Table
      style={{ backgroundColor: '#fff' }}
      pagination={false}
      columns={[
        {
          title: 'Subscription ID',
          dataIndex: 'id',
          key: 'id',
          render: (text, record) => {
            return (
              <Tooltip title={`ID: ${text}`}>
                <InfoCircleOutlined />
              </Tooltip>
            );
          }
        },
        {
          title: 'Item',
          dataIndex: 'item',
          key: 'item',
          sorter: (a, b) => a.item.localeCompare(b.item),
          render: (text, record) => {
            return !record.hippoGroup.programId ? (
              <div>
                {text}{' '}
                {record && record.ended && record.ended.hippoSub.isTrial ? (
                  <sup className="account__free-trial-label">* Free Trial</sup>
                ) : null}
              </div>
            ) : (
              <>
                {`${text} - `}
                <Link
                  to={'/group-management/group/' + record.hippoGroup.programId}
                >
                  {`${record.hippoGroup.programName}`}
                </Link>
              </>
            );
          }
        },
        {
          title: 'Hippo status',
          dataIndex: 'hippostat',
          key: 'hippostat',
          filters: statusFilters,
          onFilter: (value, record) => record.hippostat.props.children === value
        },
        {
          title: 'Braintree status',
          dataIndex: 'btstat',
          key: 'btstat',
          filters: statusFilters,
          onFilter: (value, record) => record.btstat.props.children === value,
          render: (label, record) => {
            if (record.ended && record.ended.btSub && record.ended.btSub.id) {
              return (
                <Tooltip
                  color="blue"
                  title={
                    <a
                      style={{ color: 'white' }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${config.environments.braintreeUrl}subscriptions/${record.ended.btSub.id}`}
                    >
                      Visit Braintree: {record.ended.btSub.id}
                    </a>
                  }
                >
                  {label}
                </Tooltip>
              );
            } else {
              return label;
            }
          }
        },
        {
          title: 'Started',
          dataIndex: 'started',
          key: 'started',
          defaultSortOrder: 'descends',
          sorter: (a, b) =>
            new Date(a.ended.hippoSub.startedAt) -
            new Date(b.ended.hippoSub.startedAt),
          render: subscription => {
            return (
              <span>
                {moment(subscription.hippoSub.startedAt).format('ll')}
                &nbsp;
                <Button
                  type="link"
                  className="button__link"
                  onClick={() => handleEditStartDate(subscription)}
                >
                  edit
                </Button>
              </span>
            );
          }
        },
        {
          title: 'Ended',
          dataIndex: 'ended',
          key: 'ended',
          sorter: (a, b) =>
            new Date(a.ended.hippoSub.endedAt) -
            new Date(b.ended.hippoSub.endedAt),
          render: subscription => {
            const endedAt = subscription.hippoSub.endedAt
              ? moment(subscription.hippoSub.endedAt).format('ll')
              : 'N/A';
            return (
              <span>
                {endedAt}
                &nbsp;
                {!subscription.hippoSub.isCMEArchiveEnabled && (
                  <Button
                    type="link"
                    className="button__link"
                    disabled={subscription.hippoSub.isCMEArchiveEnabled}
                    onClick={() => handleEditEndDate(subscription)}
                  >
                    edit
                  </Button>
                )}
              </span>
            );
          }
        },
        {
          title: '',
          dataIndex: 'transactions',
          key: 'transactions',
          render: transactions => {
            return (
              <Button
                onClick={() => {
                  Modal.info({
                    title: 'Transaction history',
                    content: (
                      <ReactJson
                        collapsed={true}
                        displayDataTypes={false}
                        name="All transactions"
                        src={transactions}
                      />
                    )
                  });
                }}
              >
                Txs
              </Button>
            );
          }
        },
        {
          title: '',
          dataIndex: 'billing',
          key: 'billing',
          render: subscription => {
            return (
              <Button
                disabled={!subscription}
                onClick={() => handleShowBilling(subscription)}
              >
                Billing
              </Button>
            );
          }
        },
        {
          title: '',
          dataIndex: 'cancel',
          key: 'cancel',
          render: subscription => {
            return (
              <Button
                disabled={!subscription}
                onClick={() =>
                  handleCancelSubscription(subscription.hippoSub.id)
                }
              >
                Cancel
              </Button>
            );
          }
        },
        {
          title: '',
          dataIndex: 'delete',
          key: 'delete',
          render: subscription => (
            <Button
              type="danger"
              icon={<DeleteOutlined className="account__delete-icon" />}
              size="small"
              onClick={() => {
                handleDeleteSubscription(
                  subscription.hippoSub.id,
                  subscription.hippoSub.deletedAt
                );
              }}
            />
          )
        }
      ]}
      dataSource={_.sortBy(
        subscriptionsForCourse,
        sub => -new Date(sub.hippoSub.createdAt)
      ).map((subscription, index) => {
        const transactions = {
          Previous: {},
          Upcoming: {}
        };

        if (subscription.btSub && subscription.btSub.transactions) {
          subscription.btSub.transactions.forEach(transaction => {
            const src = {
              'Amount charged': '$' + transaction.amount
            };

            if (Object.keys(transaction.creditCard).length > 0) {
              src['Credit Card'] =
                '**** **** **** ' + transaction.creditCard.last4;
            }

            if (Object.keys(transaction.paypalAccount).length > 0) {
              src.Paypal = 'Yes';
            }

            transactions.Previous[
              'Transaction on ' + moment(transaction.createdAt).format('LLL')
            ] = src;
          });
        }

        let braintreeStatus = 'NONE';
        if (subscription.btSub) {
          braintreeStatus = subscription.btSub.status.toUpperCase();
          if (braintreeStatus === 'CANCELED') {
            braintreeStatus = 'CANCELLED';
          }
        }

        let hippoStatus = 'ACTIVE';
        if (
          subscription.hippoSub.startedAt &&
          new Date() < new Date(subscription.hippoSub.startedAt)
        ) {
          hippoStatus = 'PENDING';
        }

        if (
          subscription.hippoSub.endedAt &&
          new Date() > new Date(subscription.hippoSub.endedAt)
        ) {
          hippoStatus = 'EXPIRED';
        }

        if (subscription.hippoSub.deletedAt) {
          hippoStatus = 'DELETED';
        }

        if (braintreeStatus === 'CANCELLED') {
          transactions.Upcoming = {
            'Next billing date': moment(
              subscription.btSub.nextBillingDate
            ).format('LLL'),
            'Next billing amount': '$' + subscription.btSub.nextBillAmount
          };
        }

        let allowUpdate = false;
        if (braintreeStatus !== 'CANCELLED' && braintreeStatus !== 'NONE') {
          allowUpdate = true;
        }

        return {
          id: subscription.hippoSub.id,
          item: subscription.hippoSub.title || '<none>',
          hippoGroup: subscription.hippoGroup,
          hippostat: badgeForStatus(hippoStatus),
          btstat: badgeForStatus(braintreeStatus),
          started: subscription,
          ended: subscription,
          transactions,
          billing: allowUpdate ? subscription : null,
          cancel: allowUpdate ? subscription : null,
          delete: subscription
        };
      })}
    />
  );
}
