import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { notify } from 'react-notify-toast';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  DeleteOutlined,
  CaretUpOutlined,
  CaretDownOutlined
} from '@ant-design/icons';
import { Button, Spin } from 'antd';

import EditableLabel from '../../components/EditableLabel/EditableLabel';
import TopicView from './TopicView';
import * as API from '../../API';

import './TopicGroupView.css';

const getListStyle = () => ({
  marginBottom: 18
});

const getRowStyle = (isDragging, draggableStyle) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 2,
  justifyContent: 'space-between',
  userSelect: 'none',
  padding: 12,
  background: isDragging ? '#eee' : '#fff',
  boxShadow: isDragging ? 'box-shadow: 0 10px 6px -6px #777' : 'none',
  ...draggableStyle
});

export default class TopicGroupView extends React.Component {
  static propTypes = {
    courseId: PropTypes.string,
    attestationCme: PropTypes.bool
  };

  static defaultProps = {
    courseId: '',
    attestationCme: false
  };

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      topicGroups: [],
      topicGroupOpen: {},
      pendingQueries: 0
    };

    this.topicListRefs = {};
    this.lectureListRefs = {};
  }

  componentDidMount() {
    this.fetchTopicGroups();
  }

  onDragEnd = event => {
    if (!event.destination) {
      return;
    }
    if (event.type === 'topicGroup') {
      this.reorderTopicGroups(event);
    }

    if (event.type === 'topic') {
      this.reorderTopics(event);
    }

    if (event.type === 'lecture' || event.type === 'sublecture') {
      this.reorderLectures(event);
    }
  };

  onQueryStart = count => {
    const pendingQueries = this.state.pendingQueries + count;
    this.setState({ pendingQueries });
  };

  onQueryEnd = () => {
    const pendingQueries = this.state.pendingQueries - 1;
    this.setState({ pendingQueries });
  };

  setTopicListRef = (id, ref) => {
    this.topicListRefs[id] = ref;
  };

  setLectureListRef = (id, ref) => {
    this.lectureListRefs[id] = ref;
  };

  reorderTopicGroups = async event => {
    try {
      let { topicGroups } = this.state;
      const removedTopicGroup = topicGroups.splice(event.source.index, 1);
      topicGroups.splice(event.destination.index, 0, removedTopicGroup[0]);

      this.onQueryStart(topicGroups.length);
      topicGroups = await Promise.all(
        topicGroups.map(async (topicGroupIn, index) => {
          const topicGroup = topicGroupIn;
          topicGroup.displayOrder = index;

          await API.topicGroup.update({
            id: topicGroup.id,
            attributes: { displayOrder: index * 10 }
          });

          this.onQueryEnd();
          return topicGroup;
        })
      );
      this.setState({ topicGroups });
    } catch (error) {
      this.setState({ error });
    }
  };

  reorderTopics = event => {
    const sourceList = this.topicListRefs[event.source.droppableId];
    const destinationList = this.topicListRefs[event.destination.droppableId];

    if (sourceList === destinationList) {
      sourceList.moveItem(event.source.index, event.destination.index);
    } else {
      destinationList.insertItem(
        event.destination.index,
        sourceList.getTopic(event.draggableId)
      );
      sourceList.removeItem(event.source.index);
    }
  };

  reorderLectures = event => {
    const sourceList = this.lectureListRefs[event.source.droppableId];
    const destinationList = this.lectureListRefs[event.destination.droppableId];

    if (sourceList === destinationList) {
      sourceList.moveItem(event.source.index, event.destination.index);
    } else {
      destinationList.insertItem(
        event.destination.index,
        sourceList.getLecture(event.draggableId)
      );
      sourceList.removeItem(event.source.index);
    }
  };

  createTopicGroup = async () => {
    try {
      this.onQueryStart(1);
      await API.topicGroup.create({
        attributes: {
          title: `New TopicGroup ${this.state.topicGroups.length + 1}`,
          displayOrder: this.state.topicGroups.length
            ? this.state.topicGroups[this.state.topicGroups.length - 1]
                .attributes.displayOrder + 10
            : 0
        },
        relationships: {
          course: {
            data: {
              type: 'course',
              id: this.props.courseId
            }
          }
        }
      });

      this.onQueryEnd();
      this.fetchTopicGroups();
    } catch (error) {
      this.setState({ error });
    }
  };

  deleteTopicGroup = async topicGroup => {
    try {
      if (
        !window.confirm(
          `Are you sure you want to delete topic group "${topicGroup.attributes.title}"?`
        )
      ) {
        return;
      }
      this.onQueryStart(1);
      await API.topicGroup.delete({ id: topicGroup.id });
      this.onQueryEnd();
      this.fetchTopicGroups();
    } catch (error) {
      this.setState({ error });
    }
  };

  updateTopicGroup = async (id, field, value) => {
    try {
      const { topicGroups } = this.state;
      const topicGroupIndex = _.findIndex(topicGroups, { id });
      const topicGroup = _.clone(topicGroups[topicGroupIndex]);
      topicGroup.attributes[field] = value;

      this.onQueryStart(1);
      await API.topicGroup.update({
        id: topicGroup.id,
        attributes: topicGroup.attributes
      });
      this.onQueryEnd();
      this.setState({ topicGroups });
    } catch (error) {
      if (error.data) {
        this.onQueryEnd();
        notify.show(error.data.errors[0].detail[0].message, 'error');
      } else {
        throw error;
      }
    }
  };

  fetchTopicGroups = async () => {
    try {
      if (!this.props.courseId) {
        return;
      }
      this.onQueryStart(1);
      const topicGroups = await API.topicGroup.where({
        filter: {
          course: this.props.courseId
        }
      });
      this.setState({ topicGroups: topicGroups.data });
      this.onQueryEnd();
    } catch (error) {
      this.setState({ error });
      return [];
    }
  };

  isTopicGroupOpen = id => {
    if (this.state.topicGroupOpen[id] === undefined) {
      return false;
    }
    return this.state.topicGroupOpen[id];
  };

  toggleTopicGroupOpen = id => {
    const { topicGroupOpen } = this.state;
    if (topicGroupOpen[id] === undefined) {
      topicGroupOpen[id] = true;
    } else {
      topicGroupOpen[id] = !topicGroupOpen[id];
    }
    this.setState({ topicGroupOpen });
  };

  render() {
    if (this.state.error !== null) {
      throw this.state.error;
    }

    return (
      <div className="topic-group-view table">
        <div className="header-row">
          <h5 className="header">Topics</h5>
        </div>
        <div className="topic-group-header">
          <div className="column-span-small">
            {this.state.pendingQueries > 0 && <Spin />}
          </div>
          <div
            className="column-span-large"
            style={{ flexGrow: 1, marginLeft: '1em' }}
          >
            <b>Topic Group Title</b>
          </div>
          <div className="column-span-medium" />
        </div>
        {this.state.topicGroups.length === 0 && (
          <div style={getRowStyle(false)}>No data</div>
        )}
        <div>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="topicGroupDroppable" type="topicGroup">
              {(providedDrop, snapshotDrop) => (
                <div
                  {...providedDrop.droppableProps}
                  ref={providedDrop.innerRef}
                  style={getListStyle(snapshotDrop.isDraggingOver)}
                >
                  {this.state.topicGroups.map((child, index) => (
                    <div key={child.id}>
                      {/* TopicGroup info */}
                      <Draggable
                        draggableId={child.id}
                        index={index}
                        type="topicGroup"
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getRowStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <div className="column-span-small">
                              <Button
                                type="primary"
                                icon={
                                  this.isTopicGroupOpen(child.id) ? (
                                    <CaretDownOutlined />
                                  ) : (
                                    <CaretUpOutlined />
                                  )
                                }
                                style={{
                                  lineHeight: '100%'
                                }}
                                onClick={() =>
                                  this.toggleTopicGroupOpen(child.id)
                                }
                              />
                            </div>
                            <div
                              className="column-span-large"
                              style={{ flexGrow: 1, marginLeft: '1em' }}
                            >
                              <EditableLabel
                                defaultValue={child.attributes.title}
                                onChange={value =>
                                  this.updateTopicGroup(
                                    child.id,
                                    'title',
                                    value
                                  )
                                }
                              />
                            </div>
                            <div className="column-span-medium button-column">
                              <Button
                                type="danger"
                                icon={<DeleteOutlined />}
                                style={{ lineHeight: '100%' }}
                                onClick={() => this.deleteTopicGroup(child)}
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                      {/* End topicGroup info */}
                      {/* Lecture info -- only if expanded */}
                      {this.isTopicGroupOpen(child.id) && (
                        <TopicView
                          topicGroupId={child.id}
                          courseId={this.props.courseId}
                          attestationCme={this.props.attestationCme}
                          ref={ref => this.setTopicListRef(child.id, ref)}
                          setTopicListRef={this.setTopicListRef}
                          setLectureListRef={this.setLectureListRef}
                          onQueryStart={this.onQueryStart}
                          onQueryEnd={this.onQueryEnd}
                        />
                      )}
                    </div>
                  ))}
                  {providedDrop.placeholder}

                  <div className="topic-group-footer">
                    <div className="column-span-small">
                      <Button
                        type="primary"
                        style={{ lineHeight: '100%' }}
                        onClick={this.createTopicGroup}
                      >
                        New Topic Group
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <div className="footer" />
      </div>
    );
  }
}
