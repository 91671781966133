import React, { useState } from 'react';
import * as API from '../../API';
import moment from 'moment';
import _ from 'underscore';
import { notify } from 'react-notify-toast';
import { Select, Tag } from 'antd';
import {
  DeleteOutlined,
  PlusOutlined,
  WarningOutlined,
  MailOutlined,
  WarningTwoTone,
  MinusCircleTwoTone
} from '@ant-design/icons';
import {
  Popconfirm,
  Button,
  Table,
  Row,
  Col,
  Checkbox,
  Card,
  Space,
  Tooltip,
  DatePicker
} from 'antd';
import UploadCSVModal from './UploadCSVModal';
import UserAddModal from '../account/UserAddModal';
import CustomEmailModal from '../../components/CustomEmailModal/CustomEmailModal';
import { ResourceSelectorModal } from '../../components/ResourceSelectorModal';
import { Label } from 'reactstrap';
import orderBy from 'lodash/orderBy';
import makeListString from '../../utils/makeListString';
import { createSubscription } from '../../utils/subscription';
import classNames from 'classnames';

import './Group.css';

export default function GroupMembers({
  memberType,
  group,
  accounts,
  subscriptions,
  history,
  loadingAccounts,
  loadAccounts,
  loadSubscriptions
}) {
  const [showAddMember, setShowAddMember] = useState(false);
  const [showAddAdmin, setShowAddAdmin] = useState(false);
  const [showCreateMember, setShowCreateMember] = useState(false);
  const [showCreateAdmin, setShowCreateAdmin] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [currentSelectedEmailUsers, setCurrentSelectedEmailUsers] = useState(
    []
  );
  const [memberSelectedRows, setMemberSelectedRows] = useState([]);
  const [adminSelectedRows, setAdminSelectedRows] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [courseFilter, setCourseFilter] = useState(null);
  const [subscriptionDateFilter, setSubscriptionDateFilter] = useState(null);

  const newMemberIndicatorTime = moment().subtract(30, 'minutes');
  const isMemberAdminTable = memberType === 'Admin';
  const member = isMemberAdminTable
    ? {
        titleName: 'Group Admin(s)',
        addMember: setShowAddAdmin,
        createMember: setShowCreateAdmin,
        groupMemberType: 'isAdmin',
        orderBy: 'account.email',
        actionsTitle: '',
        selectedRows: adminSelectedRows
      }
    : {
        titleName: 'Member Name',
        addMember: setShowAddMember,
        createMember: setShowCreateAdmin,
        groupMemberType: 'isMember',
        orderBy: 'account.firstName',
        actionsTitle: 'Actions',
        selectedRows: memberSelectedRows
      };

  const isGroupInList = (groupBindings, groupId) =>
    !!groupBindings.filter(binding => binding.program.id === groupId).length;

  const AccountBindingWarning = ({ groupBindings, groupId }) => {
    const activeGroupBindings = groupBindings.filter(
      binding => !binding.deletedAt
    );
    const isInThisGroup = isGroupInList(activeGroupBindings, groupId);
    const tooltipTitle = isInThisGroup
      ? 'Member is already in this group'
      : `Member is already in another group : ${makeListString(
          activeGroupBindings.map(binding => binding.program.name)
        )}`;

    return activeGroupBindings?.length ? (
      <Tooltip title={tooltipTitle}>
        {isInThisGroup ? (
          <MinusCircleTwoTone color="#ccc" />
        ) : (
          <WarningTwoTone color="pink" />
        )}
      </Tooltip>
    ) : null;
  };

  const getCourseSubscriptions = subscriptions => {
    return subscriptions.reduce((courses, subscription) => {
      if (courses[subscription.course.id] !== undefined) {
        courses[subscription.course.id].subscriptions.push(
          subscription.subscriptions
        );
        courses[subscription.course.id].subscriptions = courses[
          subscription.course.id
        ].subscriptions.flat();
      } else {
        courses[subscription.course.id] = {
          subscriptions: subscription.subscriptions,
          title: subscription.course.title,
          id: subscription.course.id
        };
      }
      return courses;
    }, {});
  };

  const getCourseSubscriptionList = (courseSubscriptions, columns) => {
    const courseSubscriptionsList = [];
    const now = moment();

    const generateSubscriptionText = (startedAt, endedAt) => {
      return (
        (startedAt
          ? moment(startedAt).format('MM[/]DD[/]YY')
          : 'no start date') +
        ' - ' +
        (endedAt ? moment(endedAt).format('MM[/]DD[/]YY') : 'no end date')
      );
    };

    /* eslint-disable no-unused-vars */
    for (const [key, value] of Object.entries(courseSubscriptions)) {
      courseSubscriptionsList.push(value);
    }
    courseSubscriptionsList.sort((a, b) => a.title - b.title);

    courseSubscriptionsList.forEach(subscription => {
      const filterOptions = subscription.subscriptions
        .reduce((options, sub) => {
          const subText = generateSubscriptionText(sub.startedAt, sub.endedAt);
          options.push({
            text: subText,
            value:
              (sub.startedAt
                ? moment(sub.startedAt).format('MM[/]DD[/]YY')
                : null) +
              (sub.endedAt ? moment(sub.endedAt).format('MM[/]DD[/]YY') : null)
          });
          return options;
        }, [])
        .filter((element, index, array) => {
          return array?.findIndex(e => e.value === element.value) === index;
        });
      const expiredfilterOptions = [
        {
          text: 'Expired',
          value: 'expired'
        },
        {
          text: 'Expires Within 30 days',
          value: 'expiring-soon'
        },
        ...filterOptions
      ];
      const selectOptions = subscription.subscriptions
        .filter(
          subscription =>
            subscription.account === null &&
            (subscription.endedAt === null ||
              moment(subscription.endedAt).isAfter(moment())) &&
            moment(subscription.startedAt).isBefore(moment())
        )
        .reduce((options, sub) => {
          const subText = generateSubscriptionText(sub.startedAt, sub.endedAt);
          const existingSubscription = options.find(
            existingOption => existingOption.text === subText
          );
          if (existingSubscription) {
            existingSubscription.subscriptions.push(sub.id);
          } else {
            options.push({
              text: subText,
              value: subText,
              subscriptions: [sub.id]
            });
          }

          return options;
        }, []);

      if (!courseFilter || courseFilter === subscription.title) {
        columns.push({
          title: subscription.title,
          key: subscription.title,
          filters: expiredfilterOptions,
          filteredValue: subscriptionDateFilter
            ? subscriptionDateFilter[subscription.title]
            : [],
          onFilter: (value, record) => {
            const currentSub = subscription.subscriptions.find(
              sub => sub.account && sub.account.id === record.account.id
            );

            if (value === 'expired') {
              return currentSub?.endedAt
                ? moment(currentSub.endedAt).isBefore(now)
                : null;
            }
            if (value === 'expiring-soon') {
              return currentSub?.endedAt
                ? moment(currentSub.endedAt).isAfter(now) &&
                    moment(currentSub.endedAt)
                      .subtract(31, 'days')
                      .isBefore(now)
                : null;
            }

            return (
              value ===
              (currentSub?.startedAt
                ? moment(currentSub.startedAt).format('MM[/]DD[/]YY')
                : null) +
                (currentSub?.endedAt
                  ? moment(currentSub?.endedAt).format('MM[/]DD[/]YY')
                  : null)
            );
          },
          render: (text, record) => {
            const currentSub = subscription.subscriptions.find(
              sub => sub.account && sub.account.id === record.account.id
            );

            const currentSubDateRange =
              (currentSub?.startedAt
                ? moment(currentSub.startedAt).format('MM[/]DD[/]YY')
                : null) +
              ' - ' +
              (currentSub?.endedAt
                ? moment(currentSub?.endedAt).format('MM[/]DD[/]YY')
                : null);

            const activeSub = subscription.subscriptions.find(
              sub =>
                sub.account &&
                sub.account.id === record.account.id &&
                moment(sub.endedAt).isAfter(now)
            )
              ? true
              : false;

            const expiredSub = subscription.subscriptions.find(
              sub =>
                sub.account &&
                sub.account.id === record.account.id &&
                !moment(sub.endedAt).isAfter(now)
            )
              ? true
              : false;

            const emptySub = !subscription.subscriptions.find(
              sub => sub.account && sub.account.id === record.account.id
            )
              ? true
              : false;

            const canAddMembers = subscription.subscriptions.reduce(
              (canAddMembers, sub) => {
                const now = moment();
                if (
                  sub.account === null &&
                  (sub.endedAt === null || moment(sub.endedAt).isAfter(now))
                ) {
                  return true;
                }
                return canAddMembers;
              },
              false
            );

            if (subscription.subscriptions.length > 0) {
              return (
                <div className="member-sub__options">
                  <Select
                    placeholder={emptySub && <p>+ Add Sub</p>}
                    dropdownMatchSelectWidth={true}
                    className={classNames(
                      activeSub && 'member-sub__options-active-sub',
                      expiredSub && 'member-sub__options-expired-sub'
                    )}
                    value={currentSub ? currentSubDateRange : null}
                    onSelect={async value => {
                      const subscriptionValue = selectOptions.find(
                        sub => sub.value === value
                      );
                      await updateMemberSubscriptionAssignment({
                        memberId: record.id,
                        subscriptionId: subscription.id,
                        previousBoolean: !!subscription.subscriptions.find(
                          sub =>
                            sub.account && sub.account.id === record.account.id
                        ),
                        accountId: record.account.id,
                        subscriptions: subscription.subscriptions,
                        newSubscriptionId: subscriptionValue.subscriptions[0]
                      });
                      loadAccounts();
                    }}
                    allowClear={true}
                    onClear={async () => {
                      await updateMemberSubscriptionAssignment({
                        memberId: record.id,
                        subscriptionId: subscription.id,
                        previousBoolean: !!subscription.subscriptions.find(
                          sub =>
                            sub.account && sub.account.id === record.account.id
                        ),
                        accountId: record.account.id,
                        subscriptions: subscription.subscriptions
                      });
                      loadAccounts();
                    }}
                  >
                    {selectOptions.map((sub, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={sub.value}
                          disabled={
                            sub.account?.id ? true : false || !canAddMembers
                          }
                        >
                          {sub.text}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
              );
            } else {
              return <p>No subs available</p>;
            }
          }
        });
      }
    });
    return columns;
  };

  const updateMemberSubscriptionAssignment = async ({
    memberId,
    subscriptionId,
    previousBoolean,
    accountId,
    subscriptions,
    newSubscriptionId
  }) => {
    const bindingToUpdate = await API.programMemberCourseBinding.where({
      filter: {
        programAccountBinding: memberId,
        course: subscriptionId
      },
      options: {}
    });
    if (previousBoolean) {
      try {
        const subscriptionToRemoveMember = subscriptions.find(
          subscription =>
            subscription.account && subscription.account.id === accountId
        );
        await API.subscription.update({
          id: subscriptionToRemoveMember.id,
          relationships: {
            account: {
              data: null
            }
          }
        });
        await API.programMemberCourseBinding.update({
          id: bindingToUpdate.data[0]?.id,
          attributes: {
            deletedAt: new Date()
          }
        });
      } catch (error) {
        if (error.data) {
          notify.show(error.data.errors[0].detail[0].message, 'error');
        } else {
          throw error;
        }
      }
    } else {
      try {
        if (newSubscriptionId) {
          await API.subscription.update({
            id: newSubscriptionId,
            relationships: {
              account: {
                data: {
                  type: 'account',
                  id: accountId
                }
              }
            }
          });
          if (bindingToUpdate.data[0]?.id) {
            await API.programMemberCourseBinding.update({
              id: bindingToUpdate.data[0].id,
              attributes: {
                deletedAt: null
              }
            });
          } else {
            await API.programMemberCourseBinding.create({
              attributes: {
                createdAt: new Date(),
                updatedAt: new Date()
              },
              relationships: {
                programAccountBinding: {
                  data: { type: 'programAccountBinding', id: memberId }
                },
                course: { data: { type: 'course', id: subscriptionId } }
              }
            });
          }
        } else {
          notify.show('No active subscriptions available to add', 'error');
        }
      } catch (error) {
        if (error.data) {
          notify.show(error.data.errors[0].detail[0].message, 'error');
        } else {
          throw error;
        }
      }
    }
    // Load only subscriptions
    loadSubscriptions();
  };

  const getMemberTableColumns = subscriptions => {
    const columns = [
      {
        title: member.titleName,
        key: 'name',
        filters: [
          {
            text: 'Active',
            value: 'active'
          },
          {
            text: 'Inactive',
            value: 'inactive'
          }
        ],
        fixed: 'left',
        render: (text, record) => (
          <>
            {moment(record.account.createdAt).isAfter(
              newMemberIndicatorTime
            ) && <Tag color="green">New User!</Tag>}
            {moment(record.createdAt).isAfter(newMemberIndicatorTime) && (
              <Tag color="green">New Member!</Tag>
            )}
            <span
              key={record.account.id}
              className="group__link"
              onClick={e => {
                history.push(`/account/${record.account.id}`);
              }}
            >
              {`${record.account.firstName} ${record.account.lastName}`}
            </span>
          </>
        ),
        onFilter: (value, account) => {
          return value === 'active'
            ? account.courses.length > 0
            : account.courses.length === 0;
        }
      },
      {
        title: 'Group Member Identifier',
        key: 'memberIdentifier',
        dataIndex: 'memberIdentifier'
      },
      {
        title: 'Email',
        key: 'email',
        dataIndex: ['account', 'email']
      },
      {
        title: member.actionsTitle,
        key: 'delete',
        render: (text, record) => (
          <div className="member-actions">
            <Button
              icon={<MailOutlined />}
              size="small"
              title="Send email"
              onClick={() => {
                setShowEmailModal(true);
                setCurrentSelectedEmailUsers([record.account.id]);
              }}
            />
            <Popconfirm
              title="Remove this member from this group?"
              icon={<WarningOutlined style={{ color: 'red' }} />}
              onConfirm={() => removeAccount(record.id, showAddAdmin)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="danger"
                icon={<DeleteOutlined />}
                size="small"
                title="Remove member from group"
              />
            </Popconfirm>
          </div>
        )
      }
    ];

    const memberCourseSubscriptions = getCourseSubscriptions(subscriptions);
    getCourseSubscriptionList(memberCourseSubscriptions, columns);

    return columns;
  };

  const getResourceSelectorModalProps = () => {
    if (showAddMember || showAddAdmin) {
      return {
        title: showAddMember ? 'Members' : 'Admins',
        apiResource: API.account,
        additionalPaginatedListProps: {
          defaultOptions: { include: 'programBindings.program' }
        },
        searchPlaceholder: 'Search by Email Address',
        searchAction: (actions, value) =>
          actions.setFilter('email', value && `:${value}`),
        renderHeader: () => (
          <Row>
            <Col span={2}>Select</Col>
            <Col span={6}>Email</Col>
            <Col span={10}>Name</Col>
            <Col span={1} />
          </Row>
        ),
        renderItem: (index, account, isSelected, onClick) => (
          <Row onClick={onClick}>
            <Col span={2}>
              <Checkbox checked={isSelected} />
            </Col>
            <Col span={6}>{account.email}</Col>
            <Col span={10}>
              {account.firstName} {account.lastName}
            </Col>
            <Col span={1}>
              <AccountBindingWarning
                groupId={group.id}
                groupBindings={account.programBindings}
              />
            </Col>
          </Row>
        ),
        isItemDisabled: account => isMemberAccountUsed(account, showAddMember),
        visible: true,
        okButtonText: `Add Selected ${
          showAddMember ? 'Members' : 'Admins'
        } to Group`,
        onSelect: addAccounts,
        onClose: () => {
          setShowAddMember(false);
          setShowAddAdmin(false);
        }
      };
    }
  };

  const isMemberAccountUsed = (account, isMember) => {
    return (
      !!account.programBindings?.length &&
      isMember &&
      account.programBindings.find(binding => binding.isMember)
    );
  };

  const addAccounts = async accountIds => {
    const existingAccounts = accounts.map(account => account.account.id);

    const existingAccountBindings = accounts.filter(
      account => accountIds.includes(account.account.id) && !account.deletedAt
    );

    const newAccountIds = accountIds.filter(
      id => !existingAccounts.includes(id)
    );

    let accountsWithDeletedAt = accounts.filter(
      account => accountIds.includes(account.account.id) && account.deletedAt
    );

    accountsWithDeletedAt.map(programAccountBinding => {
      const deletedLength = accountsWithDeletedAt.filter(
        binding => programAccountBinding.account.id === binding.account.id
      ).length;

      const programAccountBindingsLength = existingAccounts.filter(
        account => account === programAccountBinding.account.id
      ).length;

      if (deletedLength === programAccountBindingsLength) {
        newAccountIds.push(programAccountBinding.account.id);
      }

      accountsWithDeletedAt = accountsWithDeletedAt.filter(
        binding => binding.account.id !== programAccountBinding.account.id
      );

      return '';
    });

    if (existingAccountBindings) {
      await Promise.all(
        existingAccountBindings.map(binding => {
          return API.programAccountBinding.update({
            id: binding.id,
            attributes: {
              isAdmin: showAddAdmin ? true : binding.isAdmin,
              isMember: showAddMember ? true : binding.isMember
            }
          });
        })
      );
    }

    if (newAccountIds) {
      await Promise.all(
        newAccountIds.map(id =>
          API.programAccountBinding.create({
            attributes: {
              isAdmin: showAddAdmin,
              isMember: showAddMember,
              hasAccepted: true
            },
            relationships: {
              account: {
                data: {
                  id,
                  type: 'account'
                }
              },
              program: {
                data: {
                  id: group.id,
                  type: 'program'
                }
              }
            }
          })
        )
      );

      loadAccounts();
    }
    setShowAddMember(false);
    setShowAddAdmin(false);
  };

  const removeAccount = async (accountBindingId, removeAdmin = false) => {
    const accountBinding = accounts.find(
      account => account.id === accountBindingId
    );

    // first, before we remove user from group, we want to remove all their
    // subscriptions associated with this group.
    const usersSubscriptions = subscriptions
      .map(groupSubscriptions =>
        groupSubscriptions.subscriptions.filter(
          subscription =>
            subscription.account &&
            subscription.account.id === accountBinding.account.id
        )
      )
      .flat();

    try {
      await Promise.all(
        usersSubscriptions.map((subscription, index) =>
          updateMemberSubscriptionAssignment({
            memberId: accountBinding.id,
            subscriptionId: subscription.course.id,
            previousBoolean: true,
            accountId: accountBinding.account.id,
            subscriptions: usersSubscriptions.slice(
              index,
              usersSubscriptions.length
            )
          })
        )
      );
    } catch (err) {
      notify.show(
        `Error removing subscriptions from Account ID ${accountBinding.account.id}, before removing user from group`
      );
    }

    if (isMemberAdminTable) {
      if (accountBinding.isMember) {
        // if is admin and member, don't add deletedAt value
        await API.programAccountBinding.update({
          id: accountBindingId,
          attributes: {
            isAdmin: false
          }
        });
      } else {
        // if is admin and not a member, add deletedAt value
        await API.programAccountBinding.update({
          id: accountBindingId,
          attributes: {
            isAdmin: false,
            deletedAt: new Date()
          }
        });
      }
    } else {
      if (accountBinding.isAdmin) {
        // if is admin and member, don't add deletedAt value
        await API.programAccountBinding.update({
          id: accountBindingId,
          attributes: {
            isMember: false
          }
        });
      } else {
        // if is a member and not admin, add deletedAt value
        await API.programAccountBinding.update({
          id: accountBindingId,
          attributes: {
            isMember: false,
            deletedAt: new Date()
          }
        });
      }
    }
    loadAccounts();
  };

  const onSelectChange = selectedRowKeys => {
    if (selectedRowKeys.length === 0) {
      isMemberAdminTable ? setAdminSelectedRows([]) : setMemberSelectedRows([]);
      setCurrentSelectedEmailUsers([]);
    }
    const selectedRowMembers = [];
    const selectedMemberAccounts = [];
    selectedRowKeys.forEach(selectedRowKey => {
      const accountBinding = accounts.find(
        account => account.id === selectedRowKey
      );

      if (accountBinding) {
        // upon selecting row, we want to add them to the possible email
        // sending list, incase the user is trying to send a mass email
        // but we can update the state outside of this loop
        selectedMemberAccounts.push(accountBinding.account.id);
      }

      const selectedSubscriptions = subscriptions
        .map(groupSubscriptions =>
          groupSubscriptions.subscriptions.filter(
            subscription =>
              subscription.account &&
              subscription.account.id === accountBinding.account.id
          )
        )
        .flat();

      selectedSubscriptions.map(subscription =>
        selectedRowMembers.push(subscription)
      );
      const filteredSelectedRows = courseFilter
        ? selectedRowMembers.filter(row => row.course?.title === courseFilter)
        : selectedRowMembers;

      isMemberAdminTable
        ? setAdminSelectedRows(filteredSelectedRows)
        : setMemberSelectedRows(filteredSelectedRows);
    });

    setCurrentSelectedEmailUsers(selectedMemberAccounts);
  };

  const tableRowSelector = isMemberAdminTable
    ? adminSelectedRows
    : memberSelectedRows;

  const rowSelection = {
    tableRowSelector,
    onChange: onSelectChange
  };

  const onChange = (pagination, filters, sorter, extra) =>
    extra.action === 'filter' && setSubscriptionDateFilter(filters);

  const accountDataSource = orderBy(
    accounts
      .filter(account => account[member.groupMemberType])
      .filter(account => {
        if (courseFilter) {
          const filteredSubscription =
            account &&
            account.account &&
            account.account.subscriptions &&
            account.account.subscriptions.find(
              currentSubscription =>
                !!currentSubscription.programCourseBinding &&
                currentSubscription.course.title === courseFilter
            );
          return filteredSubscription && filteredSubscription !== null;
        }
        return true;
      }),
    member.orderBy
  );

  const courseFilterOptions = () =>
    Object.values(getCourseSubscriptions(subscriptions));

  const removeFiltersFromHiddenCourses = value => {
    const subscriptionDateFilterCopy = Object.assign(
      {},
      subscriptionDateFilter
    );

    Object.keys(subscriptionDateFilterCopy)
      .filter(key => key !== value)
      .map(filter => (subscriptionDateFilterCopy[filter] = null));

    return subscriptionDateFilterCopy;
  };

  const membersAreSelected = () =>
    isMemberAdminTable
      ? adminSelectedRows.length > 0
      : memberSelectedRows.length > 0;

  const updateGroupSubscriptions = async endDate => {
    const filteredSubscriptions = subscriptions.filter(
      subscription => subscription.course.title === courseFilter
    );

    let programCourseCounts = {};
    filteredSubscriptions.forEach(filteredSubs => {
      const counts = filteredSubs.subscriptions
        .flat()
        .reduce((bindings, subscription) => {
          // only count account enrolled subsciptions
          if (subscription.account) {
            const bindingKeys = Object.keys(bindings);
            if (
              bindingKeys.length === 0 ||
              !bindingKeys.includes(subscription.programCourseBinding.id)
            ) {
              bindings[subscription.programCourseBinding.id] = 1;
            } else {
              bindings[subscription.programCourseBinding.id]++;
            }
          }
          return bindings;
        }, {});
      programCourseCounts = {
        ...programCourseCounts,
        ...counts
      };
    });

    const selectedMemberBindingCounts = member.selectedRows.reduce(
      (accounts, subscription) => {
        const accountsKeys = Object.keys(accounts);
        if (subscription.course.title === courseFilter) {
          if (
            accountsKeys.length === 0 ||
            !accountsKeys.includes(subscription.programCourseBinding.id)
          ) {
            accounts[subscription.programCourseBinding.id] = 1;
          } else {
            accounts[subscription.programCourseBinding.id]++;
          }
        }
        return accounts;
      },
      {}
    );

    member.selectedRows.map(async memberRow => {
      const memberBindingId = memberRow.programCourseBinding.id;

      try {
        member.selectedRows.map(async memberRow => {
          const {
            data: programCourseBinding
          } = await API.programCourseBinding.find({
            id: memberRow.programCourseBinding.id
          });

          const subscriptionFound = await API.subscription.where({
            filter: {
              account: memberRow.account.id,
              programCourseBinding: programCourseBinding.id
            }
          });

          const bindingAttributes = _.clone(programCourseBinding.attributes);
          const subscriptionAttributes = _.clone(
            subscriptionFound.data[0].attributes
          );
          bindingAttributes.endDate = endDate;
          subscriptionAttributes.endedAt = endDate;

          // check if all enrolled members of course are selected to be updated
          if (
            selectedMemberBindingCounts[memberBindingId] !==
            programCourseCounts[memberBindingId]
          ) {
            subscriptionAttributes.account = null;

            const relationships = {
              course: { data: { id: memberRow.course.id, type: 'course' } },
              program: { data: { id: group.id, type: 'program' } }
            };

            // instead of updating pcb and sub, create new ones
            const {
              data: programCourseBinding
            } = await API.programCourseBinding.create({
              attributes: {
                notes: 'created through admin group renewal',
                startDate: bindingAttributes.startDate,
                endDate: bindingAttributes.endDate
              },
              relationships
            });

            await createSubscription(
              memberRow.course.id,
              subscriptionAttributes.startedAt,
              endDate,
              memberRow.account.id,
              programCourseBinding.id
            );

            // remove seat from other enrollment
            await API.subscription.update({
              id: subscriptionFound.data[0].id,
              attributes: subscriptionAttributes
            });
          } else {
            await API.programCourseBinding.update({
              id: programCourseBinding.id,
              attributes: bindingAttributes
            });

            await API.subscription.update({
              id: subscriptionFound.data[0].id,
              attributes: subscriptionAttributes
            });
          }

          loadSubscriptions();
        });
      } catch (error) {
        throw error;
      }
    });
  };

  return (
    <div className="group__form-item">
      <Label className="group__section-title">{memberType}s</Label>
      {accounts && subscriptions ? (
        <>
          {courseFilterOptions().length > 1 && (
            <Select
              placeholder="Filter Course"
              onSelect={value => {
                const subscriptionDateFilterForCourse = removeFiltersFromHiddenCourses(
                  value
                );
                setCourseFilter(value);
                setSubscriptionDateFilter(subscriptionDateFilterForCourse);
              }}
              allowClear={true}
              onClear={() => setCourseFilter(null)}
              style={{ margin: '0 0 16px 16px', width: '250px' }}
            >
              {courseFilterOptions().map(course => {
                return (
                  <Select.Option key={course.id} value={course.title}>
                    {course.title}
                  </Select.Option>
                );
              })}
            </Select>
          )}
          {
            <Button
              type="primary"
              size="small"
              style={{ marginLeft: 16, height: 32 }}
              onClick={() => setShowDatePicker(true)}
              disabled={
                !membersAreSelected() ||
                (!courseFilter && courseFilterOptions().length > 1)
              }
            >
              Renew Selected
            </Button>
          }
          <Button
            type="primary"
            icon={<MailOutlined />}
            size="small"
            style={{ marginLeft: 16, height: 32 }}
            onClick={() => setShowEmailModal(true)}
            disabled={
              currentSelectedEmailUsers && currentSelectedEmailUsers.length < 1
            }
          >
            E-mail Selected Members
          </Button>
          {showDatePicker && membersAreSelected() && (
            <DatePicker
              style={{ marginLeft: 16 }}
              onChange={value => value && updateGroupSubscriptions(value)}
            />
          )}
          <Table
            className="group__table"
            dataSource={accountDataSource}
            onChange={onChange}
            rowKey="id"
            pagination={false}
            columns={getMemberTableColumns(subscriptions)}
            rowClassName={account =>
              !isMemberAdminTable &&
              !account.courses.length > 0 &&
              'disabled-row'
            }
            rowSelection={rowSelection}
            loading={loadingAccounts}
          />
        </>
      ) : (
        <Card className="group__table">{`No ${memberType}s Assigned`}</Card>
      )}
      <hr />
      <Space>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          size="small"
          onClick={() => member.addMember(true)}
        >
          {`Add Existing ${memberType}`}
        </Button>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          size="small"
          onClick={() => member.createMember(true)}
        >
          {`Create a New ${memberType}`}
        </Button>
        {!isMemberAdminTable && <UploadCSVModal group={group} />}
      </Space>
      {(showAddMember || showAddAdmin) && (
        <ResourceSelectorModal {...getResourceSelectorModalProps()} />
      )}

      {(showCreateMember || showCreateAdmin) && (
        <UserAddModal
          group={group}
          isAdmin={isMemberAdminTable}
          modalVisible={showCreateMember || showCreateAdmin}
          setModalVisible={visible => {
            if (!visible) {
              loadAccounts();
            }

            setShowCreateMember(visible);
            setShowCreateAdmin(visible);
          }}
        />
      )}

      <div className="group__save-notice">
        All changes are automatically saved
      </div>
      <CustomEmailModal
        accountIds={currentSelectedEmailUsers}
        groupName={group?.attributes.name}
        showModal={showEmailModal}
        setShowModal={state => setShowEmailModal(state)}
      />
    </div>
  );
}
