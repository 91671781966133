import React, { useState, useEffect } from 'react';
import { TreeSelect, Modal, Button } from 'antd';

import { useQuery, useMutation } from '@apollo/client';
import { GET_ALL_TOPICS_AND_LECTURES_FOR_COURSE_QUERY } from './queries/LectureListQuery';
import { UPDATE_LECTURE_HIERARCHY_MUTATION } from './queries/LectureMutation';

import './UpdateLectureHierarchy.css';

export default function UpdateLectureHierarchy({
  visible,
  parent,
  lectureId,
  courseId,
  handleClose
}) {
  const [lectureToModify, setLectureToModify] = useState({});
  const [newAssignmentId, setNewAssignmentId] = useState('');
  const [displayValue, setDisplayValue] = useState(null);
  const [treeData, setTreeData] = useState([]);
  const [lecturesAndTopics, setLecturesAndTopics] = useState([]);
  const [
    showSublecturesWillUnnestWarning,
    setShowSublecturesWillUnnestWarning
  ] = useState(false);

  const { data } = useQuery(GET_ALL_TOPICS_AND_LECTURES_FOR_COURSE_QUERY, {
    variables: {
      courseId
    }
  });

  const [updateLecture] = useMutation(UPDATE_LECTURE_HIERARCHY_MUTATION);

  const lectureHasVideoParent = parent.type !== 'topic';

  useEffect(() => {
    if (data?.topicGroupsList) {
      const treeNodes = [];
      const allLecturesAndTopics = [];
      const parentLectureIds = [];

      const lectureIsSelf = id => id === lectureId;
      const lectureIsAssignedTo = id => id === parent.id;

      data.topicGroupsList.forEach(topicGroup => {
        topicGroup.topicsList.forEach(topic => {
          allLecturesAndTopics.push({ ...topic, isTopic: true });
          topic.lecturesList.forEach(lecture => {
            allLecturesAndTopics.push(lecture);
            if (lecture.parentId) {
              parentLectureIds.push(lecture.parentId);
            }
            if (lectureIsSelf(lecture.id)) {
              setLectureToModify(lecture);
            }
          });
        });
      });

      data.topicGroupsList.forEach(topicGroup => {
        const { id, title, topicsList } = topicGroup;

        const topicGroupNode = {
          id,
          value: id,
          selectable: false,
          title,
          children: []
        };

        // topic nodes
        topicsList.forEach(topic => {
          const { id, title, lecturesList } = topic;

          const topicNode = {
            id,
            value: id,
            disabled: lectureIsAssignedTo(id),
            title: `${title} ${
              lectureIsAssignedTo(id) ? '- CURRENTLY ASSIGNED' : ''
            }`,
            children: []
          };

          // lecture nodes
          lecturesList.forEach(lecture => {
            const { id, title, parentId } = lecture;

            if (!parentId) {
              const lectureNode = {
                id,
                value: id,
                disabled:
                  !!parentId || lectureIsSelf(id) || lectureIsAssignedTo(id),
                title: `${title} ${
                  parentLectureIds.includes(id)
                    ? '(lecture topic)'
                    : '(lecture)'
                } ${
                  lectureIsSelf(id)
                    ? '- SELF'
                    : lectureIsAssignedTo(id)
                    ? '- CURRENTLY ASSIGNED'
                    : ''
                }`,
                children: []
              };
              topicNode.children.push(lectureNode);
            }
          });
          topicGroupNode.children.push(topicNode);
        });
        treeNodes.push(topicGroupNode);
      });

      setLecturesAndTopics(allLecturesAndTopics);
      setTreeData(treeNodes);
    }
  }, [data, courseId, lectureId, parent.id]);

  const getSublecturesForLecture = lectureId =>
    lecturesAndTopics.filter(
      lectureOrTopic => lectureOrTopic.parentId === lectureId
    );

  const getSublecturesForTopic = topicId =>
    lecturesAndTopics.filter(
      lectureOrTopic => lectureOrTopic.topicId === topicId
    );

  const onChange = (newValue, node) => {
    const newAssignment = lecturesAndTopics.find(
      lectureOrTopic => lectureOrTopic.id === newValue
    );
    const sublecturesToUpdate = getSublecturesForLecture(lectureToModify.id);
    if (!newAssignment.isTopic && sublecturesToUpdate.length > 0) {
      setShowSublecturesWillUnnestWarning(true);
    } else {
      setShowSublecturesWillUnnestWarning(false);
    }

    setDisplayValue(node[0]);
    setNewAssignmentId(newValue);
  };

  const handleSubmit = async => {
    let newDisplayOrder = 0;
    const newAssignment = lecturesAndTopics.find(
      lectureOrTopic => lectureOrTopic.id === newAssignmentId
    );

    const sublecturesToUpdate = getSublecturesForLecture(lectureToModify.id);

    const newAssignmentSublectures = newAssignment.isTopic
      ? getSublecturesForTopic(newAssignmentId)
      : getSublecturesForLecture(newAssignmentId);

    if (newAssignmentSublectures.length > 0) {
      newDisplayOrder =
        newAssignmentSublectures[newAssignmentSublectures.length - 1]
          .displayOrder + 10;
    }

    // very unlikely that an Admin would want/attempt to move a parent lecture under another parent lecture, but just in case
    if (!newAssignment.isTopic && sublecturesToUpdate.length > 0) {
      let sublectureDisplayOrder = newDisplayOrder;
      sublecturesToUpdate.forEach(sublecture => {
        sublectureDisplayOrder += 10;
        updateLecture({
          variables: {
            lectureId: sublecture.id,
            parentId: newAssignment.id,
            displayOrder: sublectureDisplayOrder,
            topicId: newAssignment.topicId,
            updatedAt: new Date()
          }
        });
      });
    }

    updateLecture({
      variables: {
        lectureId: lectureToModify.id,
        displayOrder: newDisplayOrder,
        parentId: !newAssignment.isTopic ? newAssignment.id : null,
        topicId: newAssignment.isTopic
          ? newAssignment.id
          : newAssignment.topicId,
        updatedAt: new Date()
      },
      onCompleted: () => window.location.reload()
    });
  };

  return (
    <Modal
      wrapClassName="update-lecture-hierarchy__modal"
      width="60vw"
      visible={visible}
      labelInValue={true}
      onCancel={handleClose}
      title={`Update Lecture Hierarchy for: ${lectureToModify.title}`}
      footer={<Button onClick={handleSubmit}>Submit</Button>}
    >
      <>
        <div className="update-lecture-hierarchy__parent-title">
          {`Currently under ${lectureHasVideoParent ? 'Lecture' : ''} Topic: `}
          <b>{`${parent.attributes.title}`}</b>
        </div>
        <hr />
        <div className="update-lecture-hierarchy__assign">
          <div className="update-lecture-hierarchy__title">
            Assign to a different lecture or topic*:
          </div>
          <TreeSelect
            className="update-lecture-hierarchy__select"
            style={{
              width: '100%',
              marginTop: '0.75rem'
            }}
            value={displayValue}
            dropdownStyle={{
              maxHeight: 600,
              overflow: 'auto'
            }}
            placeholder="Please Select"
            showSearch={true}
            treeNodeFilterProp="title"
            onChange={(value, node) => {
              onChange(value, node);
            }}
            treeData={treeData}
          />
          <hr />
          <div>
            *Lectures (videos) can be re-assigned to a topic, lecture topic, or
            lecture.
          </div>
          <div>
            *Lectures cannot be assigned to a topic group or a sublecture.
          </div>
          <div>
            *Any sublectures will continue to be sublectures of this lecture.
          </div>
        </div>
        {getSublecturesForLecture(lectureToModify.id).length > 0 && (
          <div className="update-lecture-hierarchy__warning">
            Warning - you are about to move a parent lecture that has
            sublecture(s). All of it's associated sublectures will still move
            with it to the new parent lecture, keeping this lecture as their
            parent.
          </div>
        )}
        {showSublecturesWillUnnestWarning && (
          <div className="update-lecture-hierarchy__warning">
            Warning - you are about to move a parent lecture, which has
            sublecture(s), under another video lecture. All of it's associated
            sublectures will move with it to the new parent lecture, but will no
            longer have this lecture as the parent, since it will become a
            sublecture. Did you mean to move this parent lecture under a topic,
            instead?
          </div>
        )}
      </>
    </Modal>
  );
}
