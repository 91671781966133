import React, { useState, useEffect, useCallback } from 'react';
import { Button, Modal, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import CREATE_ADMIN_MUTATION from './queries/CreateAccountMutation';
import CREATE_ORGANIZATION_ADMIN_BINDING_MUTATION from './queries/CreateOrganizationAdminBindingMutation';
import { useMutation } from '@apollo/client';
import { emailExists } from '../../utils/email';

const CreateAdminModal = ({ manager, refreshParent, organizationId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [suffix, setSuffix] = useState('');
  const [email, setEmail] = useState('');
  const [formIsValid, setFormIsValid] = useState(false);
  const [firstNameErrorText, setFirstNameErrorText] = useState('');
  const [lastNameErrorText, setLastNameErrorText] = useState('');
  const [suffixErrorText, setSuffixErrorText] = useState('');
  const [emailErrorText, setEmailErrorText] = useState('');
  const alphanumericErrorText =
    'only alpha-numeric, hyphen(-), or underscore(_) characters are allowed';
  const validateForm = useCallback(async () => {
    const alphaNumericLimiter = /^([a-zA-Z0-9_-]+)$/;

    let isFirstNameValid = firstName
      ? firstName.match(alphaNumericLimiter)
      : false;
    if (isFirstNameValid) {
      setFirstNameErrorText('');
    } else {
      setFirstNameErrorText(
        firstName.length > 0 ? alphanumericErrorText : '* required'
      );
      isFirstNameValid = false;
    }

    let isLastNameValid = lastName
      ? lastName.match(alphaNumericLimiter)
      : false;
    if (isLastNameValid) {
      setLastNameErrorText('');
    } else {
      setLastNameErrorText(
        lastName.length > 0 ? alphanumericErrorText : '* required'
      );
      isLastNameValid = false;
    }

    const suffixValidator = /^[\w., -]*$/;
    let isSuffixValid = suffix ? suffix.match(suffixValidator) : true; // suffix is optional so default to true here if no suffix found
    if (isSuffixValid) {
      setSuffixErrorText('');
    } else {
      setSuffixErrorText(alphanumericErrorText);
      isSuffixValid = false;
    }

    const emailValidator = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let isEmailValid = email ? email.match(emailValidator) : false; // suffix is optional so default to true here if no suffix found

    if (isEmailValid) {
      setEmailErrorText('');
    } else {
      setEmailErrorText(
        email.length > 0
          ? alphanumericErrorText +
              ' and must include @ and a domain / extension, ie "@google.com.'
          : '* required'
      );
      isEmailValid = false;
    }

    const emailAlreadyExists = email && (await emailExists(email));

    if (emailAlreadyExists) {
      setEmailErrorText('Email already exists');
      isEmailValid = false;
    }

    setFormIsValid(
      isFirstNameValid && isLastNameValid && isSuffixValid && isEmailValid
    );
  }, [firstName, lastName, suffix, email]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const [createAdminBinding] = useMutation(
    CREATE_ORGANIZATION_ADMIN_BINDING_MUTATION,
    {
      onCompleted: async (data, clientOptions) => {
        if (refreshParent) {
          await refreshParent();
          setIsModalVisible(false);
        }
      }
    }
  );

  const [createAdmin] = useMutation(CREATE_ADMIN_MUTATION, {
    onCompleted: async (data, clientOptions) => {
      await createAdminBinding({
        variables: {
          id: uuidv4(),
          organizationId,
          adminAccountId: data.createAccount.account.id
        }
      });
    }
  });

  const showModal = () => {
    setFirstName('');
    setLastName('');
    setSuffix('');
    setEmail('');
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      await createAdmin({
        variables: {
          id: uuidv4(),
          firstName,
          lastName,
          suffix,
          email,
          isAdmin: false,
          createdAt: new Date(),
          updatedAt: new Date()
        }
      });
    } catch (error) {
      // todo : determine how to error handle
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleEmailChange = event => {
    setEmail(event.target.value.toLowerCase().trim());
    validateForm();
  };

  const handleSuffixChange = event => {
    setSuffix(event.target.value);
    validateForm();
  };

  const handleLastNameChange = event => {
    setLastName(event.target.value);
    validateForm();
  };

  const handleFirstNameChange = event => {
    setFirstName(event.target.value);
    validateForm();
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        <PlusOutlined style={{ display: 'contents' }} />
        Create a New Admin
      </Button>
      <Modal
        title="Create a New Admin"
        visible={isModalVisible}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            disabled={!formIsValid}
          >
            Create Admin
          </Button>
        ]}
      >
        <Form>
          <Form.Item label="First Name">
            <input
              type="text"
              value={firstName}
              onChange={handleFirstNameChange}
            />
            {firstNameErrorText && (
              <h6 style={{ color: 'red', fontSize: '1em' }}>
                {firstNameErrorText}
              </h6>
            )}
          </Form.Item>
          <Form.Item label="Last Name">
            <input
              type="text"
              value={lastName}
              onChange={handleLastNameChange}
            />
            {lastNameErrorText && (
              <h6 style={{ color: 'red', fontSize: '1em' }}>
                {lastNameErrorText}
              </h6>
            )}
          </Form.Item>
          <Form.Item label="suffix">
            <input type="text" value={suffix} onChange={handleSuffixChange} />
            {suffixErrorText && (
              <h6 style={{ color: 'red', fontSize: '1em' }}>
                {suffixErrorText}
              </h6>
            )}
          </Form.Item>
          <Form.Item label="email">
            <input type="text" value={email} onChange={handleEmailChange} />
            {emailErrorText && (
              <h6 style={{ color: 'red', fontSize: '1em' }}>
                {emailErrorText}
              </h6>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateAdminModal;
