import React, { useState } from 'react';
import classNames from 'classnames';
import { Button, Modal, Card, Input, Empty } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import * as API from '../../../API';
import PaginatedList from '../../../components/PaginatedList/PaginatedList';

import './TagSelectModal.css';

export default function TagSelectModal({
  onSelection,
  disabled,
  initialSearch = null,
  initialSelectedTagId = null,
  hippoOnly = true,
  buttonTitle
}) {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedTagId, setSelectedTagId] = useState(initialSelectedTagId);
  const [hasModifiedSearch, setHasModifiedSearch] = useState(false);

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (
    <>
      <Button
        onClick={() => setModalVisible(true)}
        disabled={disabled}
        className="tag-select-modal__button"
      >
        {buttonTitle || 'Add Associated Tag'}
      </Button>
      <Modal
        title="Tags"
        visible={modalVisible}
        width={600}
        onCancel={handleCancel}
        footer={[
          <Button
            key="back"
            className="tag-select-modal__cancel"
            onClick={handleCancel}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            className="tag-select-modal__add"
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => {
              onSelection(selectedTagId);
              setModalVisible(false);
            }}
          >
            Select Tag
          </Button>
        ]}
      >
        <PaginatedList
          resource={API.tag}
          onLoadRows={resource => API.simplifyResource(resource)}
          pageLimit={20}
          defaultOptions={{ include: 'tagType' }}
          defaultFilter={
            initialSearch && !hasModifiedSearch && { title: initialSearch }
          }
          renderHeader={(actions, refresh) => (
            <>
              <div className="tag-select-modal__header">
                <Input
                  placeholder="Search tags"
                  defaultValue={
                    initialSearch && !hasModifiedSearch && initialSearch
                  }
                  className="tag-select-modal__header-search"
                  onChange={e => {
                    setHasModifiedSearch(true);
                    actions.setFilter(
                      'title',
                      e.target.value && `:${e.target.value}`
                    );
                  }}
                />
                <Card size="small">
                  <div className="tag-select-modal__header-title">
                    <div>Title</div>
                    <div>Tag Type</div>
                  </div>
                </Card>
              </div>
            </>
          )}
          renderListItem={(resourceItem, refresh, i) => (
            <>
              {resourceItem.tagType &&
                (!hippoOnly || resourceItem.tagType.name === 'Hippo') && (
                  <div
                    className="tag-select-modal__list-item"
                    key={resourceItem.id}
                  >
                    <Card
                      size="small"
                      key={resourceItem.id}
                      className={classNames({
                        'tag-select-modal__item--selected':
                          resourceItem.id === selectedTagId
                      })}
                      onClick={() => setSelectedTagId(resourceItem.id)}
                    >
                      <div className="tag-select-modal__item">
                        <div>{resourceItem.title}</div>
                        <div>{resourceItem.id}</div>
                        <div>
                          {resourceItem.tagType
                            ? resourceItem.tagType.name
                            : 'N/A'}
                        </div>
                      </div>
                    </Card>
                  </div>
                )}
            </>
          )}
          renderEmptyList={() => <Empty description="No tags to show" />}
        />
      </Modal>
    </>
  );
}
