import slugify from 'slugify';

export default function getSlug(id, string, siblings, keyName = 'urlSlug') {
  const baseSlug = slugify(string, {
    lower: true,
    remove: /[^A-Za-z0-9-\s]/g
  });

  const slugs = siblings
    .filter(item => item.id !== id)
    .map(item => item[keyName] || item.attributes[keyName]);

  let index = 1;
  let slug = baseSlug;
  while (slugs.includes(slug)) {
    slug = `${baseSlug}-${index}`;
    index += 1;
  }

  return slug;
}
