import React from 'react';
import { Tag, Button, Checkbox } from 'antd';
import { Label } from 'reactstrap';
import { PlusOutlined } from '@ant-design/icons';
import * as API from '../../API';
import { notify } from 'react-notify-toast';
import { ResourceSelectorModal } from '../../components/ResourceSelectorModal';

export default class CommentsModeratorSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showFacultySelect: false };
  }
  render() {
    const addModeratorsToCourse = async facultyIds => {
      try {
        for (const id of facultyIds) {
          await API.courseModeratorBinding.create({
            attributes: {},
            relationships: {
              course: {
                data: {
                  type: 'course',
                  id: this.props.courseId
                }
              },
              faculty: {
                data: {
                  type: 'faculty',
                  id
                }
              }
            }
          });
        }
      } catch (e) {
        notify.show(`Error: ${e.data.errors[0].detail}`, 'error');
      }
      this.setState({ showFacultySelect: false });
      this.props.refresh();
    };

    const removeModeratorFromCourse = async moderatorBindingId => {
      try {
        await API.courseModeratorBinding.delete({
          id: moderatorBindingId
        });
      } catch (e) {
        notify.show(`Error: ${e.data.errors[0].detail}`, 'error');
      }
      this.props.refresh();
    };

    return (
      <>
        <Label className="comments__moderator-section">Moderators</Label>
        {this.props.course &&
          this.props.course.courseModeratorBindings.map(moderatorBinding => (
            <Tag
              key={moderatorBinding.id}
              closable={true}
              onClose={() => removeModeratorFromCourse(moderatorBinding.id)}
              className="comments__moderator"
            >
              {moderatorBinding.faculty.fullName}
            </Tag>
          ))}
        <Button
          className="comments__add-moderator-button"
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => this.setState({ showFacultySelect: true })}
        >
          Add Moderator
        </Button>
        <ResourceSelectorModal
          title="Faculty Search"
          apiResource={API.faculty}
          defaultOptions={{
            include: 'courseModeratorBindings'
          }}
          renderHeader={() => (
            <div className="comments__faculty-search-modal-header">
              <div className="comments__faculty-search-modal--checkbox">
                Selected
              </div>
              <div className="comments__faculty-search-modal--name">Name</div>
            </div>
          )}
          searchPlaceholder="Search faculty"
          searchAction={(actions, value) =>
            actions.setFilter('fullName', value && `:${value}`)
          }
          renderItem={(index, item, isSelected, onClick) => {
            const boundToThisCourse = item.courseModeratorBindings.find(
              binding => binding.course.id === this.props.courseId
            );
            return (
              <div
                key={index}
                className="comments__faculty-search-modal-rendered-item"
              >
                <div className="comments__faculty-search-modal--checkbox">
                  <Checkbox
                    checked={isSelected}
                    disabled={!!boundToThisCourse}
                  />
                </div>
                <div className="comments__faculty-search-modal--name">
                  {item.fullName}
                </div>
              </div>
            );
          }}
          disabledIds={
            this.props.course &&
            this.props.course.courseModeratorBindings.map(
              binding => binding.faculty.id
            )
          }
          visible={this.state.showFacultySelect}
          okButtonText="Add Selected Faculty to Course Moderators"
          onSelect={selectedIds => addModeratorsToCourse(selectedIds)}
          onClose={() => this.setState({ showFacultySelect: false })}
        />
      </>
    );
  }
}
