/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';
import { Button, Col, Input, Row } from 'reactstrap';
import bt from 'braintree-web';
import * as Sentry from '@sentry/browser';

import { getTokenFromCookie } from '../../utils/cookie';

const ELEMENT_IDS = {
  ACCOUNT: 'card-number',
  CVV: 'cvv',
  EXP: 'expiration-date'
};

const dropDownValues = [
  { PREPAID: 'User has already paid' },
  { FREE: 'Free Subscription' }
];

const hide = { display: 'none' };

const inputContainer = {
  height: 40,
  border: '1px solid #ccc',
  borderRadius: 3,
  marginBottom: 32
};

const promoInput = {
  width: 80,
  height: 40,
  border: '1px solid #ccc',
  borderRadius: 3,
  marginBottom: 12
};

export default class Step3 extends React.Component {
  state = {
    typeToggle: 'NO_CHARGE', // CARD

    promotionCode: '',
    verifiedPromotionCode: '',

    discountAmount: '',
    discountPercentage: '',

    originalPrice: '',
    priceAfterDisount: '',
    prepaidAmount: '', // overrides the above two
    complimentarySubType: '',

    isPrepaid: false,
    adminComments: '',

    currentPromoInvalid: null,
    hostedFieldsInstance: null,
    isValidCardInfo: false
  };

  async componentDidMount() {
    this.setState({
      originalPrice: this.props.purchaseData.item.price
    });
    this.setState({
      hostedFieldsInstance: this.props.hostedFieldsInstance
    });

    if (!this.props.prepaidOnly) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_JSONAPI_SERVER}/api/braintree/client-token?forAccountId=${this.props.purchaseData.account.id}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${getTokenFromCookie()}`
            },
            credentials: 'same-origin'
          }
        );
        const token = await response.text();
        const client = await bt.client.create({ authorization: token });
        const hostedFieldsInstance = await bt.hostedFields.create({
          client,
          styles: {
            input: {
              'font-size': '14pt',
              color: '#3A3A3A',
              padding: '0 0 0 4px',
              height: 40,
              border: '1px solid #ccc',
              'border-radius': '3px'
            },
            '.number': {
              'font-family': 'monospace',
              width: '250px'
            },
            '.valid': {
              color: 'limegreen'
            },
            '.invalid': {
              color: 'tomato'
            },
            '#cvv': {
              width: 60
            }
          },
          fields: {
            number: {
              selector: `#${ELEMENT_IDS.ACCOUNT}`,
              placeholder: '1111 1111 1111 1111'
            },
            cvv: {
              selector: `#${ELEMENT_IDS.CVV}`,
              placeholder: '•••'
            },
            expirationDate: {
              selector: `#${ELEMENT_IDS.EXP}`,
              placeholder: '12/34'
            }
          }
        });
        this.setState({ hostedFieldsInstance });

        this.state.hostedFieldsInstance.on('validityChange', hf => {
          this.setState({
            isValidCardInfo: Object.keys(hf.fields).reduce(
              (isValid, field) => isValid && hf.fields[field].isValid,
              true
            )
          });
        });
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  }

  async next() {
    const returnData = {};

    if (this.state.prepaidAmount) {
      returnData.isPrepaid = true;
      returnData.price = this.state.prepaidAmount;
      if (this.state.complimentarySubType === 'FREE') {
        returnData.isPrepaid = false;
        returnData.paymentMethodNonce = 'BYPASS_PAYMENT';
      }
    } else if (this.state.priceAfterDisount === '0') {
      returnData.price = '0';

      try {
        const payload = await this.state.hostedFieldsInstance.tokenize();
        returnData.paymentMethodNonce = payload.nonce;
      } catch (e) {
        Sentry.captureException('Not adding PMT because: ', e);
      }
    } else {
      const payload = await this.state.hostedFieldsInstance.tokenize();

      returnData.paymentMethodNonce = payload.nonce;
      returnData.price = this.state.originalPrice;
    }

    if (this.state.adminComments) {
      returnData.adminComments = this.state.adminComments;
    }

    if (this.state.promotionCode) {
      if (!this.state.verifiedPromotionCode) {
        const advance = window.confirm(
          'You have an unapplied promotion. Continue?'
        );
        if (!advance) {
          return;
        }
      } else {
        returnData.price = this.state.priceAfterDisount;
        returnData.promotionCodes = [this.state.promotionCode];
      }
    }

    this.props.onComplete(returnData);
  }

  async applyPromo() {
    let price = this.state.originalPrice;

    const itemId = this.props.purchaseData.item.id;
    const promotionCode = this.state.promotionCode;

    const promoResponse = await fetch(
      `${process.env.REACT_APP_JSONAPI_SERVER}/api/promotion/validate?code=${promotionCode}&itemId=${itemId}`,
      {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          Authorization: `Bearer ${getTokenFromCookie()}`
        }
      }
    );
    const status = promoResponse.status;
    const promo = await promoResponse.json();

    if (status >= 400) {
      window.alert(promo.message);
      return;
    }

    const promoValidForItem = promo.items.includes(itemId);

    if (!promoValidForItem) {
      window.alert("This promotion isn't valid for item " + itemId);
      return;
    }

    // Calculate the new price
    const discountAmount = parseFloat(promo['Promotion.discountAmount']);
    const discountPercentage = parseFloat(
      promo['Promotion.discountPercentage']
    );

    if (discountAmount) {
      this.setState({ discountAmount });
      price = '' + (parseFloat(price) - discountAmount);
    } else if (discountPercentage) {
      this.setState({ discountPercentage });
      const percentageAmount = parseFloat(price) * (discountPercentage / 100);
      price = '' + (parseFloat(price) - percentageAmount).toFixed(2);
    }

    this.setState({
      verifiedPromotionCode: promotionCode,
      priceAfterDisount: price
    });
  }

  changePaymentType(type) {
    if (type === 'CARD') {
      this.setState({ isPrepaid: false, prepaidAmount: '' });
    }

    this.setState({ typeToggle: type });
  }

  onPaymentMenuChange = option => {
    if (option === 'FREE') {
      this.setState({
        prepaidAmount: '0'
      });
    }
  };

  render() {
    const checkoutStyles = {
      backgroundColor: '#e9ecef',
      padding: 18,
      borderRadius: 3,
      marginBottom: 10
    };

    const description = [];
    // Just in case format is weird in db.
    try {
      const list = JSON.parse(this.props.purchaseData.item.description);
      list.forEach(listItem => {
        description.push(listItem);
      });
    } catch (e) {
      // Nothing.
    }

    return (
      <Row>
        {this.props.prepaidOnly ? (
          <Col xs="6">
            <select
              onChange={e => {
                this.setState({ complimentarySubType: e.target.value });
                this.onPaymentMenuChange(e.target.value);
              }}
            >
              <option>SELECT ONE</option>
              {dropDownValues.map((subType, index) => {
                return (
                  <option value={Object.keys(subType)[0]} key={index}>
                    {Object.values(subType)[0]}
                  </option>
                );
              })}
            </select>
            {this.state.complimentarySubType === 'PREPAID' && (
              <div>
                <strong>Prepaid Amount</strong>&nbsp;&nbsp;$
                <input
                  style={promoInput}
                  id={ELEMENT_IDS.CVV}
                  onChange={e => {
                    if (
                      !window.isNaN(e.target.value) ||
                      e.target.value === ''
                    ) {
                      this.setState({ prepaidAmount: e.target.value });
                    }
                  }}
                  defaultValue={0}
                  value={this.state.prepaidAmount}
                />
              </div>
            )}
            <div>
              <strong>Admin comments</strong>
              <Input
                style={{ marginBottom: 12 }}
                type="textarea"
                name="text"
                onChange={e => this.setState({ adminComments: e.target.value })}
                value={this.state.adminComments}
              />
            </div>
            <Button
              color="primary"
              onClick={this.next.bind(this)}
              disabled={(() => {
                if (this.state.complimentarySubType === 'FREE') {
                  return false;
                }
                return !this.state.prepaidAmount;
              })()}
            >
              Submit
            </Button>
          </Col>
        ) : (
          <Col xs="6">
            <div style={{ marginBottom: 20 }}>
              <strong>Choose payment type:</strong>&nbsp;&nbsp;
              <label style={{ marginRight: 20 }}>
                <input
                  name="pmttype"
                  type="radio"
                  checked={this.state.typeToggle === 'NO_CHARGE'}
                  onChange={() =>
                    this.changePaymentType.bind(this)('NO_CHARGE')
                  }
                />{' '}
                Prepaid
              </label>
              <label>
                <input
                  name="pmttype"
                  type="radio"
                  checked={this.state.typeToggle === 'CARD'}
                  onChange={() => this.changePaymentType.bind(this)('CARD')}
                />{' '}
                Credit card
              </label>
            </div>
            {!this.state.hostedFieldsInstance &&
            this.state.typeToggle === 'CARD' ? (
              <p>Loading payment interface.</p>
            ) : null}
            {this.state.hostedFieldsInstance &&
            this.state.typeToggle === 'CARD' ? (
              <div style={{ marginBottom: 8 }}>
                <strong>Enter payment information to create purchase.</strong>
              </div>
            ) : null}
            <div
              style={
                this.state.hostedFieldsInstance &&
                this.state.typeToggle === 'CARD'
                  ? null
                  : hide
              }
            >
              <div
                style={
                  this.state.priceAfterDisount === '0' ? { opacity: 0.25 } : {}
                }
              >
                <label htmlFor={ELEMENT_IDS.ACCOUNT}>Account number</label>
                <div style={inputContainer} id={ELEMENT_IDS.ACCOUNT} />
                <label htmlFor={ELEMENT_IDS.EXP}>Expiration</label>
                <div style={inputContainer} id={ELEMENT_IDS.EXP} />
                <label htmlFor={ELEMENT_IDS.CVV}>CVV</label>
                <div style={inputContainer} id={ELEMENT_IDS.CVV} />
              </div>
              <div>
                <label>
                  <div style={{ marginBottom: 12 }}>
                    <strong>Promo Code</strong>&nbsp;
                  </div>
                  <input
                    style={promoInput}
                    id={ELEMENT_IDS.CVV}
                    onChange={e =>
                      this.setState({ promotionCode: e.target.value })
                    }
                    value={this.state.promotionCode}
                  />
                  <Button color="link" onClick={this.applyPromo.bind(this)}>
                    Apply
                  </Button>
                </label>
              </div>
            </div>
            <div style={this.state.typeToggle === 'NO_CHARGE' ? null : hide}>
              <div>
                <strong>Prepaid Amount</strong>&nbsp;&nbsp;$
                <input
                  style={promoInput}
                  id={ELEMENT_IDS.CVV}
                  onChange={e => {
                    if (
                      !window.isNaN(e.target.value) ||
                      e.target.value === ''
                    ) {
                      this.setState({ prepaidAmount: e.target.value });
                    }
                  }}
                  value={this.state.prepaidAmount}
                />
              </div>
            </div>
            <div style={this.state.hostedFieldsInstance ? null : hide}>
              <strong>Admin comments</strong>
              <Input
                style={{ marginBottom: 12 }}
                type="textarea"
                name="text"
                onChange={e => this.setState({ adminComments: e.target.value })}
                value={this.state.adminComments}
              />
            </div>
            <Button
              color="primary"
              onClick={this.next.bind(this)}
              style={
                !this.state.hostedFieldsInstance &&
                this.state.typeToggle === 'CARD'
                  ? hide
                  : null
              }
              disabled={
                !this.state.isValidCardInfo &&
                !this.state.prepaidAmount &&
                !this.state.priceAfterDisount === 0
              }
            >
              Submit
            </Button>
          </Col>
        )}
        <Col xs="6">
          <div style={checkoutStyles}>
            <div>
              <strong>{this.props.purchaseData.item.title}</strong>
              <span style={{ float: 'right' }}>
                ${this.state.originalPrice}
              </span>
              <br />({this.props.purchaseData.item.sku})
              <br />
              {description.length > 0 ? (
                <ul>
                  {description.map((listItem, index) => {
                    return <li key={index}>{listItem}</li>;
                  })}
                </ul>
              ) : null}
            </div>
            {this.state.verifiedPromotionCode ? (
              <p>
                <strong>Promotion Code</strong>
                <span style={{ float: 'right' }}>
                  {this.state.discountAmount ? (
                    <span>- ${this.state.discountAmount}</span>
                  ) : null}
                  {this.state.discountPercentage ? (
                    <span>- {this.state.discountPercentage}%</span>
                  ) : null}
                </span>
                <br />
                {this.state.verifiedPromotionCode}
              </p>
            ) : null}
            <p>
              <strong>Total</strong>
              <span style={{ float: 'right' }}>
                ${this.state.priceAfterDisount || this.state.originalPrice}
              </span>
            </p>
          </div>
        </Col>
      </Row>
    );
  }
}
