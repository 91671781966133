import React from 'react';
import { Route } from 'react-router-dom';

import Item from '../containers/items/Item';

const ItemRoutes = () => {
  return (
    <>
      <Route exact={true} path="/item/:id" component={Item} />
    </>
  );
};

export default ItemRoutes;
