import React from 'react';
import QuestionsList from './QuestionsList';
import BreadcrumbConstants from '../../../routes/BreadcrumbConstants';
import ContentHeader from '../ContentHeader';

export default function QuestionsView() {
  const questionsBreadcrumb = [
    BreadcrumbConstants.HippoAdmin,
    BreadcrumbConstants.Content,
    BreadcrumbConstants.Questions
  ];

  return (
    <div>
      <ContentHeader
        breadCrumb={questionsBreadcrumb}
        title="QBank & Assessment Questions"
        hasTitle={true}
      />
      <QuestionsList />
    </div>
  );
}
