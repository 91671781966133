import React from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { orderBy } from 'lodash';
import { Link } from 'react-router-dom';
import { Table, Button, Input, Form, Card, message } from 'antd';
import { gql, ApolloConsumer } from '@apollo/client';

import * as API from '../../API';
import BreadcrumbConstants from '../../routes/BreadcrumbConstants';
import BreadcrumbNavigation from '../../components/BreadcrumbNavigation/BreadcrumbNavigation';
import RichTextEditor from '../../components/RichTextEditor';
import { isActive } from '../../utils/cme';
import './CmeManage.css';

import { CheckOutlined } from '@ant-design/icons';

// CME Types
const EPISODE = 'episode';
const COURSE = 'course';

const CREATE_COURSE_DISCLOSURE_MUTATION = gql`
  mutation CreateCourseDisclosure(
    $id: UUID!
    $courseId: UUID!
    $now: Datetime!
  ) {
    createCourseDisclosure(
      input: {
        courseDisclosure: {
          id: $id
          courseId: $courseId
          checkboxText: "I agree to take this test to the best of my abilities without reference materials"
          body: "<ul><li>If you stop at any time, your progess will be automatically saved.</li> <li>Your first pass is a complete assessment. You will not be able to go back and change your answer choices during your first pass, so choose carefully.</li> <li>When you have completed all {NUMBER_OF_QUESTIONS} questions, you will receive a detailed report of your performance. Review your incorrect answers and re-take the exam to earn 100%.</li> <li>Earn up to {NUMBER_OF_CREDITS} <i>{NAME_OF_ACCREDITOR} CME Credits</i>™.</li></ul>"
          updatedAt: $now
          createdAt: $now
        }
      }
    ) {
      courseDisclosure {
        id
        checkboxText
        body
      }
    }
  }
`;
const DELETE_COURSE_DISCLOSURE_MUTATION = gql`
  mutation DeleteCourseDisclosure($id: UUID!) {
    deleteCourseDisclosure(input: { id: $id }) {
      clientMutationId
    }
  }
`;
const CHANGE_COURSE_DISCLOSURE_MUTATION = gql`
  mutation UpdateCourseDisclosure(
    $id: UUID!
    $body: String
    $checkboxText: String
  ) {
    updateCourseDisclosure(
      input: { id: $id, patch: { body: $body, checkboxText: $checkboxText } }
    ) {
      courseDisclosure {
        id
        checkboxText
        body
      }
    }
  }
`;

class CmeManage extends React.Component {
  state = {
    course: null,
    newCourse: null,
    episode: null,
    cmeType: null,
    baseLink: null,
    breadcrumbs: [],
    error: null,
    loading: true
  };

  onClickLink(link) {
    this.props.history.push(link);
  }

  async componentDidMount() {
    const { episodeId, course } = this.props.match.params;
    if (episodeId) {
      this.setState({
        cmeType: EPISODE,
        baseLink: `/content/episode/${episodeId}/`
      });
      await this.loadEpisode(episodeId);
    } else {
      this.setState({
        cmeType: COURSE,
        baseLink: `/course/${course}/`
      });
      await this.loadCourse(course);
    }
    this.loadBreadcrumbs();
    this.setState({ loading: false });
  }

  loadEpisode = async episodeId => {
    try {
      const episode = API.simplifyResource(
        await API.episode.find({
          id: episodeId,
          options: {
            include:
              'cmeDisclosures,course,course.cmeBuckets,course.cmeBuckets.cmeAccreditor'
          }
        })
      );

      this.setState({ episode });
    } catch (error) {
      this.setState({ error });
    }
  };

  loadCourse = async courseId => {
    try {
      const course = API.simplifyResource(
        await API.course.find({
          id: courseId,
          options: {
            include:
              'cmeDisclosures,cmeBuckets,cmeBuckets.cmeAccreditor,courseType,courseDisclosures'
          }
        })
      );
      this.setState({
        course,
        loading: false
      });
    } catch (error) {
      this.setState({ error });
    }
  };

  getDisclosures() {
    const rows = [];
    const { cmeType } = this.state;

    if (cmeType === EPISODE) {
      this.state.episode.cmeDisclosures.map((disclosure, i) => {
        return rows.push({
          key: disclosure.id,
          active: isActive(disclosure),
          title: `${disclosure.title}`,
          activatedAt: disclosure.activatedAt,
          expiredAt: disclosure.expiredAt,
          edit: `cme_disclosure/${disclosure.id}`
        });
      });
    } else {
      this.state.course.cmeDisclosures.map((disclosure, i) => {
        return rows.push({
          key: disclosure.id,
          active: isActive(disclosure),
          title: `${disclosure.title}`,
          activatedAt: disclosure.activatedAt,
          expiredAt: disclosure.expiredAt,
          edit: `cme_disclosure/${disclosure.id}`
        });
      });
    }

    return orderBy(rows, ['activatedAt'], ['desc']);
  }

  getCmeBuckets() {
    const rows = [];
    const { cmeType } = this.state;

    if (cmeType === EPISODE) {
      const { episode } = this.state;
      episode.course.cmeBuckets.map((bucket, i) => {
        return rows.push({
          key: bucket.id,
          active: isActive(bucket),
          title: `${bucket.cmeAccreditor.name}`,
          activatedAt: bucket.activatedAt,
          expiredAt: bucket.expiredAt,
          edit: `/content/episode/${episode.id}/cme_bucket/${bucket.id}`
        });
      });
    } else {
      const { course } = this.state;
      course.cmeBuckets.map((bucket, i) => {
        return rows.push({
          key: bucket.id,
          active: isActive(bucket),
          title: `${bucket.cmeAccreditor.name}`,
          activatedAt: bucket.activatedAt,
          expiredAt: bucket.expiredAt,
          edit: `/course/${this.state.course.id}/cme_bucket/${bucket.id}`
        });
      });
    }

    return orderBy(rows, ['activatedAt'], ['desc']);
  }

  loadBreadcrumbs = () => {
    const { cmeType, course, baseLink } = this.state;
    const { pathname } = this.props.location;

    if (cmeType === 'episode') {
      const breadcrumbData = [
        BreadcrumbConstants.HippoAdmin,
        BreadcrumbConstants.Content,
        BreadcrumbConstants.Episode,
        {
          to: baseLink,
          title: 'Episode'
        },
        {
          to: pathname,
          title: 'Manage Episode CME'
        }
      ];
      this.setState({ breadcrumbData });
    } else {
      const breadcrumbData = [
        BreadcrumbConstants.HippoAdmin,
        BreadcrumbConstants.Courses,
        {
          to: `/courses/${course.courseType.title}`,
          title: `${course.courseType.title}`
        },
        { to: `/course/${course.id}`, title: `${course.title}` },
        { to: pathname, title: 'Manage CME' }
      ];
      this.setState({ breadcrumbData });
    }
  };

  deleteCourseDisclosure = async () => {
    try {
      await this.props.client.mutate({
        mutation: DELETE_COURSE_DISCLOSURE_MUTATION,
        variables: {
          id: this.state.course.courseDisclosures[0].id
        },
        fetchPolicy: 'network-only'
      });
      this.setState({
        course: {
          ...this.state.course,
          courseDisclosures: []
        }
      });
    } catch (e) {
      message.error('Error deleting course disclosure: ' + e);
    }
  };

  createCourseDisclosure = async () => {
    try {
      const result = await this.props.client.mutate({
        mutation: CREATE_COURSE_DISCLOSURE_MUTATION,
        variables: {
          id: uuidv4(),
          courseId: this.state.course.id,
          now: new Date()
        },
        fetchPolicy: 'network-only'
      });

      this.setState({
        course: {
          ...this.state.course,
          courseDisclosures: [
            result.data.createCourseDisclosure.courseDisclosure
          ]
        }
      });
    } catch (e) {
      message.error('Error creating course disclosure: ' + e);
    }
  };

  updateCourseDisclosure = async (field, value) => {
    try {
      const result = await this.props.client.mutate({
        mutation: CHANGE_COURSE_DISCLOSURE_MUTATION,
        variables: Object.assign(
          {
            id: this.state.course.courseDisclosures[0].id,
            body: this.state.course.courseDisclosures[0].body,
            checkboxText: this.state.course.courseDisclosures[0].checkboxText
          },
          { [field]: value }
        ),
        fetchPolicy: 'network-only'
      });

      this.setState({
        course: {
          ...this.state.course,
          courseDisclosures: [
            result.data.updateCourseDisclosure.courseDisclosure
          ]
        }
      });
    } catch (e) {
      message.error('Error updating course disclosure: ' + e);
    }
  };

  render() {
    const { loading, breadcrumbData } = this.state;

    return (
      !loading && (
        <div className="cme-manage">
          <BreadcrumbNavigation data={breadcrumbData} />

          <div className="cme-manage__note">
            Display timezone: {process.env.REACT_APP_DISPLAY_TIMEZONE} <br />
            Current time:{' '}
            {moment()
              .tz(process.env.REACT_APP_DISPLAY_TIMEZONE)
              .format('lll')}
          </div>

          <div className="cme-manage__disclosures section-header">
            <h5>Disclosures</h5>
            <Button onClick={this.onClickLink.bind(this, 'cme_disclosure')}>
              Add
            </Button>
          </div>
          <Table
            className="cme-manage__disclosures-table"
            pagination={false}
            columns={[
              {
                title: 'Current?',
                dataIndex: 'active',
                key: 'active',
                align: 'center',
                render: active => (active ? <CheckOutlined /> : '')
              },
              {
                title: '',
                dataIndex: 'title',
                key: 'title'
              },
              {
                title: 'Starts',
                dataIndex: 'activatedAt',
                key: 'activatedAt',
                render: date =>
                  moment(date)
                    .tz(process.env.REACT_APP_DISPLAY_TIMEZONE)
                    .format('lll')
              },
              {
                title: 'Ends',
                dataIndex: 'expiredAt',
                key: 'expiredAt',
                render: date =>
                  moment(date)
                    .tz(process.env.REACT_APP_DISPLAY_TIMEZONE)
                    .format('lll')
              },
              {
                title: '',
                dataIndex: 'edit',
                key: 'edit',
                render: url => (!url ? '' : <Link to={url}>Edit</Link>)
              },
              {
                title: '',
                dataIndex: 'delete',
                key: 'delete',
                render: url => (!url ? '' : <Link to={url}>Delete</Link>)
              }
            ]}
            dataSource={this.getDisclosures()}
          />

          <div className="cme-manage__credits section-header">
            <h5>Certificates</h5>
            <Button onClick={this.onClickLink.bind(this, 'cme_bucket')}>
              Add
            </Button>
          </div>
          <Table
            className="cme-manage__credits-table"
            pagination={false}
            onRow={record => ({
              onClick: () => {
                window.location = record.edit;
              }
            })}
            columns={[
              {
                title: 'Current?',
                dataIndex: 'active',
                key: 'active',
                align: 'center',
                render: active => (active ? <CheckOutlined /> : '')
              },
              {
                title: 'Accreditor',
                dataIndex: 'title',
                key: 'title'
              },
              {
                title: 'Starts',
                dataIndex: 'activatedAt',
                key: 'activatedAt',
                render: date =>
                  moment(date)
                    .tz(process.env.REACT_APP_DISPLAY_TIMEZONE)
                    .format('lll')
              },
              {
                title: 'Ends',
                dataIndex: 'expiredAt',
                key: 'expiredAt',
                render: date =>
                  moment(date)
                    .tz(process.env.REACT_APP_DISPLAY_TIMEZONE)
                    .format('lll')
              },
              {
                title: '',
                dataIndex: 'edit',
                key: 'edit',
                render: url => (!url ? '' : <Link to={url}>Edit</Link>)
              },
              {
                title: '',
                dataIndex: 'delete',
                key: 'delete',
                render: url => (!url ? '' : <Link to={url}>Delete</Link>)
              }
            ]}
            dataSource={this.getCmeBuckets()}
          />
          {this.state.course ? (
            <Form layout="vertical">
              <div className="section-header">
                <div>
                  <h5>
                    Course Disclosure
                    {this.state.course.courseDisclosures.length ? (
                      <Button onClick={() => this.deleteCourseDisclosure()}>
                        Remove
                      </Button>
                    ) : (
                      <Button onClick={() => this.createCourseDisclosure()}>
                        Add
                      </Button>
                    )}
                  </h5>
                  <small>
                    Appears before showing course content the first time a user
                    accesses a course.
                    <strong> Currently Assessments only.</strong>
                  </small>
                </div>
              </div>
              {this.state.course.courseDisclosures.length ? (
                <Card>
                  <Form.Item label="Body" name="body" required={true}>
                    <RichTextEditor
                      defaultValue={this.state.course.courseDisclosures[0].body}
                      onChange={value => {
                        if (!value) {
                          message.info(
                            'Course Disclosure Body is required. Change not saved.'
                          );
                        } else {
                          this.updateCourseDisclosure('body', value);
                        }
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Checkbox Text"
                    name="checkboxText"
                    extra="(Optional, hides checkbox if empty)"
                  >
                    <Input
                      defaultValue={
                        this.state.course.courseDisclosures[0].checkboxText
                      }
                      onChange={event => {
                        this.updateCourseDisclosure(
                          'checkboxText',
                          event.target.value
                        );
                      }}
                    />
                  </Form.Item>
                </Card>
              ) : null}
            </Form>
          ) : null}
        </div>
      )
    );
  }
}

export default props => (
  <ApolloConsumer>
    {client => <CmeManage {...props} client={client} />}
  </ApolloConsumer>
);
