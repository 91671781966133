import React, { useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Button, message, Modal, Upload, Spin } from 'antd';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import Papa from 'papaparse';

import { getTokenFromCookie } from '../../utils/cookie';

const { Dragger } = Upload;

export default function UploadCSVModal({ group }) {
  const [fileList, updateFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const history = useHistory();

  const handleOk = async () => {
    if (!uploadCompleted) {
      if (fileList.length === 1) {
        await createNewUsers(fileList[0]);
      }
    } else {
      setShowModal(false);
    }
  };

  const handleCancel = async () => {
    setShowModal(false);
  };

  const createNewUsers = async file => {
    setLoading(true);

    Papa.parse(file.originFileObj, {
      header: true,
      complete: async results => {
        if (results) {
          const response = await fetch(
            `${process.env.REACT_APP_JSONAPI_SERVER}/api/upload/users`,
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getTokenFromCookie()}`
              },
              credentials: 'same-origin',
              method: 'POST',
              body: JSON.stringify({
                users: results.data,
                programId: group ? group.id : null
              })
            }
          );
          setLoading(false);

          const responseText = await response.text();

          if (response.status === 200) {
            setUploadCompleted(true);
            setError(false);
            // Download CSV of responses
            const url = encodeURI(responseText);
            const temporaryLink = document.createElement('a');
            temporaryLink.download = `CreatedUsers-${moment().format(
              'MM-DD-YYYY'
            )}.csv`;
            temporaryLink.href = `data:text/csv;charset=utf-8,${url}`;
            document.body.appendChild(temporaryLink);
            temporaryLink.click();
            document.body.removeChild(temporaryLink);
          } else {
            setError(responseText);
          }
        } else {
          setError('Your CSV does not contain any values.');
        }
      }
    });
  };

  const props = {
    fileList,
    beforeUpload: file => {
      if (file.type !== 'text/csv') {
        message.error(`${file.name} is not a CSV file`);
      }
      return false;
    },
    onChange: info => {
      updateFileList(info.fileList);
    },
    multiple: false
  };

  return (
    <>
      <Button
        type="primary"
        icon={<UploadOutlined />}
        size={group ? 'small' : 'middle'}
        onClick={() => setShowModal(true)}
      >
        Upload User CSV
      </Button>
      <Modal
        title="Upload User CSV"
        visible={showModal}
        onOk={handleOk}
        okButtonProps={{
          disabled: fileList.length === 0 || loading
        }}
        okText={uploadCompleted ? 'Close' : loading ? 'Loading...' : 'Upload'}
        onCancel={handleCancel}
      >
        {loading && <Spin />}
        {!loading && (
          <>
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Uploads a CSV file with all users.
              </p>
            </Dragger>
            {uploadCompleted && (
              <>
                <br />
                <p>
                  Successfully uploaded users. View the downloaded CSV for more
                  details. Click the refresh button below to refresh the page
                  and see the new users that were added.
                </p>
                <br />
                <Button
                  type="primary"
                  size="small"
                  onClick={() => history.go(0)}
                >
                  Refresh
                </Button>
                <br />
              </>
            )}
            {error && !uploadCompleted && (
              <>
                <br />
                <p>There was an error when uploading this CSV: {error}</p>
                <p>Please try again once the error is resolved.</p>
              </>
            )}
            <br />
            <h5>Notes:</h5>
            <ul>
              <li>
                Get the template{' '}
                <a
                  href="https://docs.google.com/spreadsheets/d/1JSZjry0CUwN3hsNG5lj9hmWsqJXEpKvQeI0-ym1TleI/edit?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                .
              </li>
              <li>
                Subscriptions without a specified end date will automatically be
                created for one year, starting today. Subscriptions with a
                specified end date will automatically be created from today to
                the end date, assuming that the end date is formatted correctly.
              </li>
              <li>
                Subscriptions will not be created for a user that has ever had a
                subscription to that product.
              </li>
              {!group && (
                <li>This uploader marks all subscriptions as "free".</li>
              )}
              {!group && (
                <li>
                  This uploader will automatically send a welcome email with
                  login details to all new uploaded users at the time the users
                  are uploaded.
                </li>
              )}
            </ul>
          </>
        )}
      </Modal>
    </>
  );
}
