import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Popover, Button } from 'antd';

import './ListHeaderMenu.css';

const ListHeaderMenu = ({
  className,
  overlayClassName,
  buttonPrefix = '',
  defaultValue = '',
  options,
  onChange
}) => {
  const [value, setValue] = useState(defaultValue);
  const [visible, setVisible] = useState(false);

  return (
    <Popover
      overlayClassName={classNames('list-header-menu', overlayClassName)}
      trigger="click"
      visible={visible}
      onVisibleChange={setVisible}
      content={
        <>
          {Object.keys(options).map(key => (
            <Button
              key={key}
              className={classNames('list-header-menu__option', {
                'list-header-menu__option--selected': key === value
              })}
              type="link"
              onClick={() => {
                setVisible(false);
                setValue(key);
                onChange(key);
              }}
            >
              {options[key]}
            </Button>
          ))}
        </>
      }
      placement="bottomRight"
    >
      <Button className={className}>
        {buttonPrefix}
        {options[value]}
      </Button>
    </Popover>
  );
};

ListHeaderMenu.propTypes = {
  className: PropTypes.string,
  options: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired
};

export default ListHeaderMenu;
