import axios from 'axios';

export function validateEmail(email) {
  // eslint-disable-next-line
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return emailRegex.test(email);
}

export async function emailExists(email) {
  const { data } = await axios.get(
    `${process.env.REACT_APP_JSONAPI_SERVER}/_isEmailAvailable`,
    {
      params: { email }
    }
  );

  if (data?.data?.length > 0 && data.data[0].attributes) {
    const emailAvailable = data.data[0].attributes.emailIsAvailable;
    return !emailAvailable;
  }
}
