import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AttachmentFileInputUpload from './AttachmentFileInputUpload';
import './ResourceLibraryAttachmentsInput.css';

const ResourceLibraryAttachmentsInput = ({
  onCreateItem,
  handleToggleAttachmentInput,
  sectionId
}) => {
  const defaultFileData = {
    key: '',
    fileName: '',
    fileSize: '',
    mimeType: '',
    isContentSummary: false
  };
  const [title, setTitle] = useState('');
  const [fileData, setFileData] = useState(defaultFileData);
  const [newAttachmentError, setNewAttachmentError] = useState('');

  const resetForm = () => {
    setTitle('');
    setFileData(defaultFileData);
    setNewAttachmentError('');
  };

  return (
    <div className="resource-library-attachments-input">
      <AttachmentFileInputUpload
        title={title}
        fileData={fileData}
        showSubtitleInput={false}
        setFileData={setFileData}
        setTitle={setTitle}
        newAttachmentError={newAttachmentError}
        setNewAttachmentError={setNewAttachmentError}
        resetForm={resetForm}
        onClose={() => {
          setFileData(defaultFileData);
          return handleToggleAttachmentInput(sectionId);
        }}
        onCreateItem={onCreateItem}
        showCloseButton={true}
        isContentSummary={false}
      />
    </div>
  );
};

ResourceLibraryAttachmentsInput.propTypes = {
  onCreateItem: PropTypes.func.isRequired,
  onClose: PropTypes.func
};

ResourceLibraryAttachmentsInput.defaultProps = {
  onClose: () => {}
};

export default ResourceLibraryAttachmentsInput;
