import { gql } from '@apollo/client';

const CREATE_ORGANIZATION_ADMIN_BINDING_MUTATION = gql`
  mutation createOrganizationAdminBinding(
    $id: UUID!
    $organizationId: UUID!
    $adminAccountId: UUID!
  ) {
    createOrganizationAdminBinding(
      input: {
        organizationAdminBinding: {
          id: $id
          organizationId: $organizationId
          adminAccountId: $adminAccountId
        }
      }
    ) {
      clientMutationId
    }
  }
`;

export default CREATE_ORGANIZATION_ADMIN_BINDING_MUTATION;
