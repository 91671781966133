import React from 'react';
import { message, Table, Tag, Tooltip } from 'antd';
import moment from 'moment';
import CommentsActionButton from './CommentsActionButton';
import Comments3DotMenu from './Comments3DotMenu';
import CommentsSubTable from './CommentsSubTable';
import CommentsFacultyFlair from './CommentsFacultyFlair';

const { Column } = Table;

export default function CommentsMainTable(props) {
  return (
    <Table
      key="loading-done"
      dataSource={props.comments}
      showExpandColumn={false}
      onChange={(pagination, filters, sorters) => {
        document.body.scrollTop = 100; // For Safari
        document.documentElement.scrollTop = 100;
      }}
      expandable={{
        expandedRowRender: record => {
          return (
            <CommentsSubTable
              record={record}
              handleActionButtonClick={props.handleActionButtonClick}
              currentUserAccount={props.currentUserAccount}
              currentFacultyAccount={props.currentFacultyAccount}
              currentCourseId={props.currentCourseId}
              commentAsList={props.commentAsList}
              BGColor="#F0F0F0"
            />
          );
        },
        onExpand: props.onExpand,
        expandedRowKeys: props.expandRowKeys,
        // rowExpandable: record => record.childrenComments !== undefined,
        // set to -1 to hide expand column; we automatically expand all with props.expandRowKeys
        expandIconColumnIndex: -1
      }}
    >
      <Column
        className="bodytext-column"
        title="Comment/Question"
        dataIndex="bodyText"
        render={(_, { commentText, isFacultyComment }) => {
          return (
            <>
              {commentText.tags.map(tag => {
                return (
                  <div key={tag.name} className={`tag ${tag.name}`}>
                    <Tag color={tag.color}>{tag.name}</Tag>
                  </div>
                );
              })}
              <div>
                {isFacultyComment && <CommentsFacultyFlair />}
                {commentText.bodyText}
              </div>
            </>
          );
        }}
      />
      <Column
        width="8vw"
        className="userAlias-column"
        title="User Alias"
        dataIndex="alias"
        render={(_, { alias, email }) => {
          return (
            <Tooltip className="comments-info-icon" title={email}>
              <div
                onClick={e => {
                  navigator.clipboard.writeText(email);
                  message.info('Copied ' + email + ' to clipboard');
                  e.stopPropagation();
                }}
              >
                {alias}
              </div>
            </Tooltip>
          );
        }}
      />
      <Column width="10vw" title="Course" dataIndex="courseTitle" />
      <Column width="15vw" title="Content Title" dataIndex="contentTitle" />
      <Column
        title="Date"
        width="2vw"
        render={comment => {
          return moment(comment.date).format('MM/DD/YYYY');
        }}
        sorter={(a, b) => {
          return moment(a.date) - moment(b.date);
        }}
        sortDirections={['ascend']}
        defaultSortOrder="none"
      />
      <Column
        width="2vw"
        className="action-column"
        render={(comment, { key, action }) => (
          <CommentsActionButton
            handleActionButtonClick={props.handleActionButtonClick}
            comment={comment}
            action={action}
          />
        )}
      />
      <Column
        width="1vw"
        className="menu-column"
        render={(comment, { key, menuItems }) => (
          <Comments3DotMenu
            handleActionButtonClick={props.handleActionButtonClick}
            comment={comment}
            menuItems={menuItems}
          />
        )}
      />
    </Table>
  );
}
