import React from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import { notify } from 'react-notify-toast';

import './CmeCertificateModal.css';
import { getTokenFromCookie } from '../utils/cookie';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Input, DatePicker, Modal } from 'antd';

import EditorInput from './EditorInput/EditorInput';

class CmeCertificateModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      certificate: props.certificate,
      isNew: props.certificate === null,
      error: null,
      open: false,
      loading: false,
      onOk() {},
      onCancel() {}
    };
  }

  static getDerivedStateFromProps(props, prevState) {
    return {
      certificate: props.certificate
        ? props.certificate
        : CmeCertificateModal.generateDefaultCertificate(),
      isNew: props.certificate === null,
      open: props.open,
      onOk: props.onOk,
      onCancel: props.onCancel
    };
  }

  handleServerError(json) {
    if (json.errors && json.errors.length > 0) {
      this.setState({ error: json.errors, loading: false });
      notify.show(
        `Error: ${json.errors[0].title} - ${json.errors[0].detail[0].message}`,
        'error'
      );
      return true;
    }
    return false;
  }

  async handleOk(e) {
    e.preventDefault();
    try {
      await this.props.form.validateFields();
    } catch (error) {
      return;
    }

    try {
      const formData = {
        data: {
          type: 'certificate',
          attributes: {
            name: this.props.form.getFieldValue('name'),
            body: this.props.form.getFieldValue('body'),
            activatedAt: this.props.form
              .getFieldValue('activatedAt')
              .utc()
              .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            expiredAt: this.props.form
              .getFieldValue('expiredAt')
              .utc()
              .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          },
          relationships: {}
        }
      };

      const uri = this.state.isNew
        ? process.env.REACT_APP_JSONAPI_SERVER + '/certificate/'
        : process.env.REACT_APP_JSONAPI_SERVER +
          '/certificate/' +
          this.state.certificate.id;

      const response = await fetch(uri, {
        method: this.state.isNew ? 'POST' : 'PATCH',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + getTokenFromCookie()
        },
        body: JSON.stringify(formData)
      });

      const json = await response.json();
      if (this.handleServerError(json)) {
        return;
      }

      notify.show(
        `Certificate "${this.props.form.getFieldValue(
          'name'
        )}" was saved successfully.`,
        'success'
      );

      this.setState({
        open: false
      });

      this.state.onOk(json.data, e);
    } catch (error) {
      notify.show(`Error: ${error.message}`, 'error');
    }
  }

  async handleCancel(e) {
    this.state.onCancel(e);
  }

  static generateDefaultCertificate() {
    // Default to midnight today and midnight a year from now in the app timezone
    const start = moment()
      .tz(process.env.REACT_APP_DISPLAY_TIMEZONE)
      .startOf('day')
      .utc();
    const end = moment()
      .add(1, 'year')
      .tz(process.env.REACT_APP_DISPLAY_TIMEZONE)
      .startOf('day')
      .utc();

    return {
      type: 'certificate',
      attributes: {
        name: 'Untitled Certificate',
        body: '',
        activatedAt: start.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        expiredAt: end.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      },
      relationships: {}
    };
  }

  getInitialValue(field) {
    let value = '';
    if (
      typeof this.state.certificate === 'object' &&
      this.state.certificate !== null
    ) {
      value = this.state.certificate.attributes[field];
    }

    if (field === 'activatedAt' || field === 'expiredAt') {
      value = value
        ? moment(value).tz(process.env.REACT_APP_DISPLAY_TIMEZONE)
        : null;
    }

    return value;
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        title={this.state.isNew ? 'New Certificate' : 'Edit Certificate'}
        className="cme-certificate-modal"
        visible={this.state.open}
        onOk={this.handleOk.bind(this)}
        onCancel={this.handleCancel.bind(this)}
      >
        <Form>
          <Form.Item label="Name">
            {getFieldDecorator('name', {
              initialValue: this.getInitialValue('name'),
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: 'Please enter a name.'
                }
              ]
            })(<Input />)}
          </Form.Item>
          <div className="date-range">
            <Form.Item label="Activation Date">
              {getFieldDecorator('activatedAt', {
                initialValue: this.getInitialValue('activatedAt'),
                rules: [
                  {
                    type: 'object',
                    required: false,
                    whitespace: true,
                    message:
                      'Please enter the date that the certificate begins.'
                  }
                ]
              })(<DatePicker />)}
            </Form.Item>
            <Form.Item label="Expiry Date">
              {getFieldDecorator('expiredAt', {
                initialValue: this.getInitialValue('expiredAt'),
                rules: [
                  {
                    type: 'object',
                    required: false,
                    whitespace: true,
                    message:
                      'Please enter the date that the certificate expires.'
                  }
                ]
              })(<DatePicker />)}
            </Form.Item>
          </div>
          <Form.Item label="Body">
            {getFieldDecorator('body', {
              initialValue: this.getInitialValue('body'),
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: 'Please enter the certificate body.'
                }
              ]
            })(<EditorInput />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(CmeCertificateModal);
