import React from 'react';
import { Route } from 'react-router-dom';

import Groups from '../containers/groups/Groups';
import Group from '../containers/groups/Group';

const GroupRoutes = () => {
  return (
    <>
      <Route exact={true} path="/group-management/groups" component={Groups} />
      <Route
        exact={true}
        path="/group-management/group/:id"
        component={Group}
      />
    </>
  );
};

export default GroupRoutes;
