import React, { useState } from 'react';
import moment from 'moment';
import { Table, Button, Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { getTokenFromCookie } from '../../utils/cookie';

export function OrdersTable({ accountId, orders }) {
  const [loading, setLoading] = useState(false);

  const handleDownloadReceipt = async orderId => {
    setLoading(orderId);
    try {
      await fetch(
        `${process.env.REACT_APP_JSONAPI_SERVER}/api/receipt?forAccountId=${accountId}`,
        {
          method: 'POST',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getTokenFromCookie()
          },
          body: JSON.stringify({
            orderId
          })
        }
      )
        .then(res => res.blob())
        .then(async response => {
          // Create a Blob from the PDF Stream
          const file = new Blob([response], {
            type: 'application/pdf'
          });
          // Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          // Create a "fake" anchor and click it to start a download
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = 'hippo_receipt.pdf';
          link.click();

          URL.revokeObjectURL(fileURL);
          setLoading(false);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const formatCurrency = num =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
      minimumFractionDigits: Number.isInteger(num) ? 0 : 2
    }).format(num);

  return (
    <Table
      pagination={false}
      dataSource={orders}
      columns={[
        {
          title: 'Order ID',
          dataIndex: 'id',
          key: 'id'
        },
        {
          title: 'Order Date',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: text => moment(text).format('MM/DD/YYYY')
        },
        {
          title: 'Order Items',
          dataIndex: 'OrderItems',
          key: 'OrderItems',
          render: (text, record) => {
            return (
              <Popover
                title="Order Items"
                content={
                  <div>
                    {record.OrderItems.map(orderItem => (
                      <div key={orderItem.id}>
                        <small>
                          {orderItem.productTitle} -{' '}
                          {formatCurrency(orderItem.price)}{' '}
                          {orderItem.discount
                            ? `(Discount: ${formatCurrency(
                                orderItem.discount
                              )})`
                            : ''}
                        </small>
                      </div>
                    ))}
                  </div>
                }
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {record.OrderItems.length}{' '}
                  {record.OrderItems.length === 1 ? 'item' : 'items'}
                  <InfoCircleOutlined style={{ margin: '0 0.5rem' }} />
                </div>
              </Popover>
            );
          }
        },
        {
          title: 'Total',
          dataIndex: 'total',
          key: 'total',
          render: text => formatCurrency(text)
        },
        {
          title: 'Item Total',
          dataIndex: 'itemTotal',
          key: 'itemTotal',
          render: text => formatCurrency(text)
        },
        {
          title: 'Promotion Total',
          dataIndex: 'promotionTotal',
          key: 'promotionTotal',
          render: text => formatCurrency(text)
        },
        {
          title: 'Promotion Code',
          dataIndex: 'promotionCode',
          key: 'promotionCode'
        },
        {
          title: 'Order Admin Comments',
          dataIndex: 'adminComments',
          key: 'adminComments'
        },
        {
          title: 'Download Receipt',
          dataIndex: 'downloadReceipt',
          key: 'downloadReceipt',
          render: (text, record) => {
            return (
              <Button onClick={() => handleDownloadReceipt(record.id)}>
                {loading && loading === record.id
                  ? 'Generating...'
                  : 'Download Receipt'}
              </Button>
            );
          }
        }
      ]}
    />
  );
}
