import React from 'react';
import {
  Button,
  Card,
  Collapse,
  Divider,
  message,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography
} from 'antd';
import { useQuery, gql } from '@apollo/client';

import { Link, useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import TagSelectModal from './TagSelectModal';
import { EditOutlined } from '@ant-design/icons';
import { mergeTags } from '../../../utils/merge';

const { Panel } = Collapse;
const { Text, Paragraph } = Typography;

const TAG_QUERY = gql`
  query TagQuery($tagId: UUID!) {
    tag(id: $tagId) {
      id
      title
      image {
        url
      }
      tagType {
        id
        name
      }
      chapterTagBindingsList {
        chapter {
          id
          title
          chapterTagBindingsList {
            tag {
              id
              title
              tagType {
                name
              }
            }
          }
          episodeChapterBindingsList {
            episode {
              id
              title
              activatedAt
              course {
                id
                title
                courseType {
                  shortname
                }
              }
            }
          }
        }
      }
      topicTagBindingsList {
        topic {
          id
          title
          topicTagBindingsList {
            tag {
              id
              title
              tagType {
                name
              }
            }
          }
          topicGroup {
            id
            title
            course {
              id
              title
              courseType {
                shortname
              }
            }
          }
        }
      }
      questionUniversalTagBindingsList {
        question {
          id
          body
          questionUniversalTagBindingsList {
            tag {
              title
              tagType {
                name
              }
              id
            }
          }
          course {
            id
            title
            courseType {
              shortname
            }
          }
        }
      }
    }
  }
`;

function getEpisodeFromChapterBinding(binding) {
  if (binding.chapter.episodeChapterBindingsList[0]) {
    return binding.chapter.episodeChapterBindingsList[0].episode;
  } else {
    return null;
  }
}

function TagList(parentTag, bindingList) {
  return (
    <>
      {bindingList.map(({ tag }) => (
        <Tooltip
          key={tag.id}
          title={`${tag.tagType.name}${
            tag.id === parentTag.id ? ' (this tag)' : ''
          }`}
        >
          <Tag
            color={
              tag.id === parentTag.id
                ? ''
                : tag.tagType.name === 'Hippo'
                ? 'blue'
                : 'orange'
            }
          >
            {tag.title}
          </Tag>
        </Tooltip>
      ))}
    </>
  );
}

function TagDisplay({ title, tag, footer }) {
  if (!tag) {
    return null;
  }
  const sortedChapterBindings = _.sortBy(tag.chapterTagBindingsList, [
    'chapter.episodeChapterBindingsList[0].episode.course.title',
    'chapter.episodeChapterBindingsList[0].episode.activatedAt'
  ]);
  const sortedTopicBindings = _.sortBy(tag.topicTagBindingsList, [
    'topic.topicGroup.course.title',
    'topic.topicGroup.title'
  ]);
  const sortedQuestionBindings = _.sortBy(
    tag.questionUniversalTagBindingsList,
    ['question.course.title', 'question.body']
  );

  return (
    <Card
      title={
        <>
          <Text>{title + ': ' + tag.title}</Text> <br />
          <Link to={`/content/tags/${tag.id}`}>{tag.id}</Link>
        </>
      }
      style={{ width: '50%' }}
      extra={
        <Space>
          {tag.image && <img src={tag.image.url} alt="Tag" height="50" />}
          <Tag color={tag.tagType.name === 'Hippo' ? 'blue' : 'orange'}>
            {tag.tagType.name}
          </Tag>
        </Space>
      }
    >
      <Collapse>
        <Panel header="Chapters" extra={sortedChapterBindings.length}>
          <Table
            pagination={false}
            dataSource={sortedChapterBindings}
            columns={[
              {
                title: 'Course',
                key: 'Course',
                render: (value, record) =>
                  getEpisodeFromChapterBinding(record)?.course?.title
              },
              {
                title: 'Episode',
                key: 'episode',
                render: (value, record) => (
                  <>
                    {getEpisodeFromChapterBinding(record)?.title}
                    <Link
                      to={`/content/episode/${
                        getEpisodeFromChapterBinding(record).id
                      }`}
                    >
                      <EditOutlined />
                    </Link>
                  </>
                )
              },
              {
                title: 'Date',
                key: 'activatedAt',
                render: (value, record) =>
                  getEpisodeFromChapterBinding(record)?.activatedAt
                    ? moment(
                        getEpisodeFromChapterBinding(record)?.activatedAt
                      ).format('MMM YYYY')
                    : null
              },
              {
                title: 'Chapter',
                key: 'chapter',
                render: (value, record) => (
                  <>
                    {record.chapter.title}
                    <Link to={`/content/audio/${record.chapter.id}`}>
                      <EditOutlined />
                    </Link>
                  </>
                )
              },
              {
                title: 'Tags',
                key: 'tags',
                render: (value, record) =>
                  TagList(tag, record.chapter.chapterTagBindingsList)
              }
            ]}
          />
        </Panel>

        <Panel header="Topics" extra={sortedTopicBindings.length}>
          <Table
            pagination={false}
            dataSource={sortedTopicBindings}
            columns={[
              {
                title: 'Course',
                key: 'course',
                render: (value, record) => (
                  <Link to={`/course/${record.topic.topicGroup.course.id}`}>
                    {record.topic.topicGroup.course.title}
                  </Link>
                )
              },
              {
                title: 'Topic Group',
                key: 'topic_group',
                render: (value, record) => record.topic.topicGroup.title
              },
              {
                title: 'Topic',
                key: 'topic',
                render: (value, record) => record.topic.title
              },

              {
                title: 'Tags',
                key: 'tags',
                render: (value, record) =>
                  TagList(tag, record.topic.topicTagBindingsList)
              }
            ]}
          />
        </Panel>
        <Panel header="Questions" extra={sortedQuestionBindings.length}>
          <Table
            pagination={false}
            dataSource={sortedQuestionBindings}
            columns={[
              {
                title: 'Course',
                key: 'course',
                render: (value, record) => record.question.course.title
              },
              {
                title: 'Body',
                key: 'body',
                render: (value, record) => (
                  <>
                    <Paragraph
                      ellipsis={{ rows: 5, expandable: true, symbol: 'more' }}
                    >
                      {record.question.body}
                    </Paragraph>
                    <Link to={`/content/questions/${record.question.id}`}>
                      <EditOutlined />
                    </Link>
                  </>
                )
              },
              {
                title: 'Tags',
                key: 'tags',
                render: (value, record) =>
                  TagList(tag, record.question.questionUniversalTagBindingsList)
              }
            ]}
          />
        </Panel>
      </Collapse>
      {footer && <Divider />}
      {footer}
    </Card>
  );
}

export default function TagsMergeView() {
  const history = useHistory();
  const { tagId, tagId2 } = useParams();

  const { data: tag1, refetch } = useQuery(TAG_QUERY, {
    variables: { tagId },
    fetchPolicy: 'network-only'
  });
  const { data: tag2 } = useQuery(TAG_QUERY, {
    skip: !tagId2,
    fetchPolicy: 'network-only',
    variables: { tagId: tagId2 }
  });

  const handleMergeTags = async (sourceTag, targetTag) => {
    const { error } = await mergeTags(sourceTag, targetTag);

    if (error) {
      message.error(error);
    } else {
      message.success('Success!');
      history.push(`/content/tags/merge/${targetTag}/`);
      refetch();
    }
  };

  const tag1Invalid =
    tag1?.tag &&
    !!tag1.tag.questionUniversalTagBindingsList.find(
      binding =>
        binding.question.course.courseType.shortname === 'qbank' ||
        binding.question.course.courseType.shortname === 'practice-exam'
    );

  const tag2Invalid =
    tag2?.tag &&
    !!tag2.tag.questionUniversalTagBindingsList.find(
      binding =>
        binding.question.course.courseType.shortname === 'qbank' ||
        binding.question.course.courseType.shortname === 'practice-exam'
    );

  const invalidMergeTarget = tag1Invalid || tag2Invalid;

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <TagDisplay
        title="Tag 1"
        tag={tag1 && tag1.tag}
        footer={
          <Space style={{ width: '100%' }} align="end" direction="vertical">
            {tag1 && tag2 ? (
              invalidMergeTarget ? (
                tag1Invalid ? (
                  <strong>Merging Qbank Tags Not Supported</strong>
                ) : (
                  <span>Merging Qbank Tags Not Supported</span>
                )
              ) : (
                <Button onClick={() => handleMergeTags(tagId2, tagId)}>
                  Merge and keep this tag
                </Button>
              )
            ) : null}
          </Space>
        }
      />
      {tag1 ? (
        tag2 ? (
          <TagDisplay
            title="Tag 2"
            tag={tag2 && tag2.tag}
            footer={
              <Space style={{ width: '100%' }} align="end" direction="vertical">
                <TagSelectModal
                  hippoOnly={false}
                  buttonTitle="Change Tag"
                  onSelection={value =>
                    history.push(`/content/tags/merge/${tagId}/${value}`)
                  }
                  initialSelectedTagId={tag1.tag.id}
                  initialSearch={tag1.tag.title}
                />
                {tag1 && tag2 ? (
                  invalidMergeTarget ? (
                    tag2Invalid ? (
                      <strong>Merging Qbank Tags Not Supported</strong>
                    ) : (
                      <span>Merging Qbank Tags Not Supported</span>
                    )
                  ) : (
                    <Button onClick={() => handleMergeTags(tagId, tagId2)}>
                      Merge and keep this tag
                    </Button>
                  )
                ) : null}
              </Space>
            }
          />
        ) : (
          <Card title="Tag 2: " style={{ width: '50%' }}>
            <Space style={{ width: '100%' }} align="end" direction="vertical">
              <TagSelectModal
                hippoOnly={false}
                buttonTitle="Choose a Tag"
                onSelection={value =>
                  history.push(`/content/tags/merge/${tagId}/${value}`)
                }
                initialSelectedTagId={tag1.tag.id}
                initialSearch={tag1.tag.title}
              />
            </Space>
          </Card>
        )
      ) : null}
    </div>
  );
}
