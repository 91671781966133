import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Modal, Select, Input, Spin } from 'antd';
import { Label } from 'reactstrap';

import { getEmails } from '../../emails/index.js';

import * as API from '../../API';

import './CustomEmailModal.css';

export default function CustomEmailModal({
  accountIds,
  groupName,
  showModal,
  setShowModal
}) {
  const [customMessageByAccount, setCustomMessageByAccount] = useState({});
  const [subject, setSubject] = useState(null);
  const [emailType, setEmailType] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const [courseNamesByAccount, setCourseNamesByAccount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeAccountPreview, setActiveAccountPreview] = useState(null);

  useEffect(() => {
    if (!showModal) {
      setAccounts(null);
    }

    if (!activeAccountPreview && accounts) {
      setActiveAccountPreview(accounts[0]);
    }
  }, [accounts, showModal, activeAccountPreview]);

  useEffect(() => {
    if (accountIds && !accounts && showModal) {
      const getAccount = async () => {
        try {
          const accounts = API.simplifyResource(
            await API.account.where({
              filter: { id: accountIds },
              options: {
                include: 'subscriptions,subscriptions.course'
              }
            })
          );
          setAccounts(accounts);
          const now = moment();

          // In case we are sending emails to multiple users, we need to ensure
          // that each user's course names are accurate to them, so we create a
          // map of each account and the string of courses for that account id.
          const coursesByAccount = accounts.reduce((result, account) => {
            const courses = account.subscriptions
              .map(subscription => {
                if (
                  moment(subscription.startedAt).isBefore(now) &&
                  moment(subscription.endedAt).isAfter(now)
                ) {
                  return subscription.course.title;
                } else {
                  return null;
                }
              })
              .filter(course => course !== null)
              .reduce((arr, course) => {
                if (!arr.includes(course)) {
                  arr.push(course);
                }
                return arr;
              }, [])
              .join(', ')
              .replace(/, ((?:.(?!, ))+)$/, ' and $1');

            result[account.id] = courses;
            return result;
          }, {});
          setCourseNamesByAccount(coursesByAccount);
        } catch (e) {
          throw e;
        }
      };
      getAccount();
      setLoading(false);
    }
  }, [accountIds, accounts, showModal]);

  const emails = getEmails();

  const closeModal = () => {
    setShowModal(false);
    // Reset fields
    setCustomMessageByAccount(null);
    setSubject(null);
    setEmailType(null);
    setActiveAccountPreview(null);
  };

  const handleSend = () => {
    for (const account of accounts) {
      emailType.sendEmail(account, subject, customMessageByAccount[account.id]);
    }
    closeModal();
  };

  const handleCancel = () => {
    closeModal();
  };

  return (
    <>
      <Modal
        title={
          accounts
            ? `Send Email to ${accounts
                .map((account, index) => {
                  if (index < 3) {
                    return `${account.email}${
                      index !== accounts.length - 1 ? ', ' : ''
                    }`;
                  } else if (index === 3) {
                    return `and ${accounts.length - index} more.`;
                  }

                  return null;
                })
                .join('')}`
            : 'Send Email'
        }
        visible={showModal}
        width={1000}
        okText={`${
          accounts && accounts.length > 1 ? 'Send Emails' : 'Send Email'
        }`}
        onOk={handleSend}
        onCancel={handleCancel}
        okButtonProps={{
          disabled: !emailType
        }}
      >
        {loading ? (
          <div className="custom-email-modal__loading-spinner">
            <Spin />
          </div>
        ) : (
          <>
            <div>
              <Label>Email Type: </Label>
              <Select
                className="custom-email-modal__email-type-select"
                placeholder={
                  !accounts || accounts.length === 0
                    ? 'Loading accounts...'
                    : 'Select Email'
                }
                disabled={!accounts || accounts.length === 0}
                onSelect={value => {
                  setEmailType(emails[value]);
                  setSubject(emails[value].defaultSubject);
                  setCustomMessageByAccount(() => {
                    if (accounts) {
                      return accounts.reduce((result, account) => {
                        result[account.id] = emails[value].getMessage(
                          courseNamesByAccount[account.id],
                          groupName ? groupName : 'GROUP NAME'
                        );

                        return result;
                      }, {});
                    }
                  });
                }}
              >
                {emails.map((email, index) => (
                  <Select.Option key={index}>{email.title}</Select.Option>
                ))}
              </Select>
            </div>
            <div className="custom-email-modal__subject">
              <Label>Subject: </Label>
              <Input
                className="custom-email-modal__subject-input"
                value={
                  subject
                    ? subject
                    : emailType
                    ? emailType.defaultSubject
                    : null
                }
                onChange={e => setSubject(e.target.value)}
              />
            </div>
            {accounts && accounts.length > 1 ? (
              <div>
                <Label>Showing user: </Label>
                <Select
                  className="custom-email-modal__email-type-select"
                  placeholder="Select User"
                  value={activeAccountPreview && activeAccountPreview.email}
                  disabled={!emailType}
                  onSelect={value => {
                    setActiveAccountPreview(accounts[value]);
                  }}
                >
                  {accounts &&
                    accounts.map((account, index) => (
                      <Select.Option key={index}>{account.email}</Select.Option>
                    ))}
                </Select>
              </div>
            ) : null}
            <div>
              <Label>Customize Message: </Label>
              <Input.TextArea
                value={
                  activeAccountPreview && customMessageByAccount
                    ? customMessageByAccount[activeAccountPreview.id]
                    : ''
                }
                rows={4}
                onChange={e =>
                  setCustomMessageByAccount(prev => {
                    return {
                      ...prev,
                      [activeAccountPreview.id]: e.target.value
                    };
                  })
                }
              />
            </div>
            <div className="custom-email-modal__preview">
              <hr />
              <Label>Preview: </Label>
              <div>
                {emailType && activeAccountPreview ? (
                  <>
                    {accounts && activeAccountPreview && (
                      <p>Hi {activeAccountPreview.firstName},</p>
                    )}
                    {customMessageByAccount[activeAccountPreview.id] && (
                      <p>{customMessageByAccount[activeAccountPreview.id]}</p>
                    )}
                    {emailType.includesLink && <p>LINK WILL BE ADDED HERE</p>}
                    <p>If you have any questions, contact our support team.</p>
                    <p> - The Hippo Education Team</p>
                  </>
                ) : null}
              </div>
            </div>
          </>
        )}
      </Modal>
    </>
  );
}
