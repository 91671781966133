import { gql } from '@apollo/client';

const UPDATE_ORGANIZATION_MUTATION = gql`
  mutation UpdateOrganization(
    $id: UUID!
    $type: EnumOrganizationsType!
    $name: String!
    $managerId: UUID!
    $updatedAt: Datetime!
  ) {
    updateOrganization(
      input: {
        patch: {
          name: $name
          managerId: $managerId
          type: $type
          updatedAt: $updatedAt
        }
        id: $id
      }
    ) {
      clientMutationId
    }
  }
`;

export default UPDATE_ORGANIZATION_MUTATION;
