import React from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Row
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { getTokenFromCookie } from '../utils/cookie';

export default class EditPromotionCode extends React.Component {
  state = {
    promotionCode: null,
    successMessage: '',
    errorMessage: ''
  };

  componentDidMount() {
    fetch(
      `${process.env.REACT_APP_JSONAPI_SERVER}/api/promotion-code/${this.props.match.params.id}`,
      {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          Authorization: `Bearer ${getTokenFromCookie()}`
        }
      }
    )
      .then(response => {
        response.json().then(promotionCode => this.setState({ promotionCode }));
      })
      .catch(window.setError);
  }

  updateField(key, value) {
    const promotionCode = this.state.promotionCode;
    promotionCode[key] = value;
    this.setState({ promotionCode });
  }

  handleSubmit() {
    fetch(
      `${process.env.REACT_APP_JSONAPI_SERVER}/api/promotion-code/${this.props.match.params.id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getTokenFromCookie()}`
        },
        method: 'PUT',
        credentials: 'same-origin',
        body: JSON.stringify(this.state.promotionCode)
      }
    )
      .then(response => {
        window.scrollTo(0, 0);

        if (response.status >= 400) {
          response.json().then(responseJson => {
            this.setState({ errorMessage: responseJson.message });
          });
        } else {
          response.json().then(responseJson => {
            this.setState({ successMessage: 'Successfully updated promotion' });
          });
        }

        setTimeout(() => {
          this.setState({ successMessage: '', errorMessage: '' });
        }, 2000);
      })
      .catch(err => window.setError(err));
  }

  render() {
    if (!this.state.promotionCode) {
      return <div />;
    }

    const promotionId = this.state.promotionCode.PromotionId;
    const promotionCodeId = this.state.promotionCode.id;
    const promotionCode = this.state.promotionCode.code;

    return (
      <div>
        <Row>
          <Col>
            <Breadcrumb style={{ padding: '20px 0' }}>
              <Breadcrumb.Item>
                <Link to="/dashboard">Hippo Admin</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/promotions">Promotions</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/promotion/${promotionId}/view`}>{promotionId}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active={true}>
                <Link to={`/promotion_code/${promotionCodeId}/edit`}>
                  {promotionCode}
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        {this.state.successMessage ? (
          <Row>
            <Col>
              <Alert color="success">{this.state.successMessage}</Alert>
            </Col>
          </Row>
        ) : null}
        {this.state.errorMessage ? (
          <Row>
            <Col>
              <Alert color="danger">{this.state.errorMessage}</Alert>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col>
            <Form>
              <FormGroup>
                <Label>
                  Code <small>(required)</small>
                </Label>
                <Input
                  type="textarea"
                  bsSize="sm"
                  value={this.state.promotionCode.code}
                  onChange={e =>
                    this.updateField.bind(this)('code', e.target.value)
                  }
                />
                <p>
                  <small>
                    This is the code the customer enters to receive the
                    promotion. Not case-sensitive.
                  </small>
                </p>
              </FormGroup>
              <FormGroup check={true}>
                <Label check={true}>
                  <Input
                    type="checkbox"
                    checked={this.state.promotionCode.isLimitedUse}
                    onChange={e =>
                      this.updateField.bind(this)(
                        'isLimitedUse',
                        e.target.checked
                      )
                    }
                  />
                  One-time use
                </Label>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Button color="primary" onClick={this.handleSubmit.bind(this)}>
              Submit
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
