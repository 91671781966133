import React from 'react';
import { Menu, Dropdown, Tooltip } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';

export default class Comments3DotMenu extends React.Component {
  render() {
    const menu = (
      <Menu
        onClick={menuItem => {
          this.props.handleActionButtonClick(this.props.comment, menuItem.key);
        }}
      >
        {this.props.menuItems.map(item => {
          return item.disabled ? (
            <Menu.Item
              title={item.title}
              key={item.key}
              disabled={item.disabled}
            >
              <Tooltip className="comments-info-icon" title={item.disabledText}>
                {item.link ? (
                  <a rel="noopener noreferrer" target="_blank" href={item.link}>
                    {item.text}
                  </a>
                ) : (
                  item.text
                )}
              </Tooltip>
            </Menu.Item>
          ) : (
            <Menu.Item
              title={item.title}
              key={item.key}
              disabled={item.disabled}
            >
              {item.link ? (
                <a rel="noopener noreferrer" target="_blank" href={item.link}>
                  {item.text}
                </a>
              ) : (
                item.text
              )}
            </Menu.Item>
          );
        })}
      </Menu>
    );
    return (
      <>
        <Dropdown overlay={menu} trigger={['click']}>
          <span onClick={e => e.preventDefault()}>
            <EllipsisOutlined
              style={{ fontSize: '25px' }}
              className="more-menu"
            />
          </span>
        </Dropdown>
      </>
    );
  }
}
