import { gql } from '@apollo/client';

const COURSE_SALES_PAGE_QUERY = gql`
  query CourseSalesPageUrlsQuery {
    coursesList(condition: { showOnWebInternal: true }) {
      id
      salesPageUrl
    }
  }
`;

const COURSE_QUERY = gql`
  query CourseQuery($courseId: UUID!) {
    course(id: $courseId) {
      id
      courseAttachmentBindingsList(condition: { courseId: $courseId }) {
        id
        expiredAt
        activatedAt
        attachment {
          id
          title
          subtitle
          key
          fileName
          updatedAt
          signedUrl
          hasAccountCmeAttempts
        }
      }
    }
  }
`;

export { COURSE_SALES_PAGE_QUERY, COURSE_QUERY };
