import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import { Descriptions, Table } from 'antd';

import BreadcrumbConstants from '../../routes/BreadcrumbConstants';
import BreadcrumbNavigation from '../../components/BreadcrumbNavigation/BreadcrumbNavigation';
import { Pagination } from 'antd';

import * as API from '../../API';

import { Label } from 'reactstrap';
const { Column } = Table;

export default function Ratings() {
  const history = useHistory();
  const { course: courseId } = useParams();
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);

  const [course, setCourse] = useState(null);
  const [courseType, setCourseType] = useState(null);
  const [averageRating, setAverageRating] = useState(null);
  const [totalNumberofRatings, setTotalNumberofRatings] = useState(null);
  const [totalNumberofComments, setTotalNumberofComments] = useState(false);
  const [totalContentCount, setTotalContentCount] = useState(null);

  const [contentType, setContentType] = useState('');
  const [contentSummary, setContentSummary] = useState([]);

  useEffect(() => {
    async function fetchCourseData() {
      const accountRatingSummary = (
        await API.sequelizeGraphql({
          query: `{
            adminCourseAccountRatings(
              courseId:"${courseId}"
              paginationOptions: {
                limit:${limit}
                offset:${offset}
              }
            ){
              course{
                id
                title
                courseType{
                  shortname
                }
              }
              contentCount
              count
              countWithComments
              averageRating
              chapters{
                id
                title
                activatedAt
                count
                countWithComments
                averageRating
              }
              lectures{
                id
                title
                topic
                count
                countWithComments
                averageRating
              }
              questions{
                id
                body
                tags
                count
                countWithComments
                averageRating
              }
            }
          }`
        })
      ).adminCourseAccountRatings;

      setCourse(accountRatingSummary.course);
      setCourseType(accountRatingSummary.course.courseType.shortname);
      setTotalNumberofRatings(accountRatingSummary.count);
      setTotalNumberofComments(accountRatingSummary.countWithComments);
      setTotalContentCount(accountRatingSummary.contentCount);
      setAverageRating(accountRatingSummary.averageRating);
      setContentSummary(
        (accountRatingSummary.chapters.length &&
          accountRatingSummary.chapters) ||
          (accountRatingSummary.lectures.length &&
            accountRatingSummary.lectures) ||
          accountRatingSummary.questions
      );

      if (accountRatingSummary.course.courseType.shortname === 'podcast') {
        setContentType('Chapter');
      } else if (accountRatingSummary.course.courseType.shortname === 'video') {
        setContentType('Lecture');
      } else {
        setContentType('Question');
      }
    }

    fetchCourseData();
  }, [courseId, limit, offset]);

  return (
    <div className="ratings">
      {course && (
        <div>
          <BreadcrumbNavigation
            data={[
              BreadcrumbConstants.HippoAdmin,
              BreadcrumbConstants.Courses,
              BreadcrumbConstants.breadcrumbByCourseType(courseType),
              {
                to: `/course/${courseId}`,
                title: course ? course.title : 'course'
              },
              { title: `Overview of ${course.title} Ratings` }
            ]}
          />
          <Descriptions
            size="small"
            column={1}
            bordered={true}
            style={{
              marginTop: '10px',
              backgroundColor: '#fff',
              borderRadius: '5px'
            }}
          >
            <Descriptions.Item
              style={{
                width: '200px'
              }}
              label="Course"
            >
              {course.title}
            </Descriptions.Item>
            <Descriptions.Item label={`Total ${contentType}s with Ratings`}>
              {totalContentCount}
            </Descriptions.Item>
            <Descriptions.Item label="Average Rating">
              {_.round(averageRating, 2)}
            </Descriptions.Item>
            <Descriptions.Item label="Total Number of Ratings">
              {totalNumberofRatings}
            </Descriptions.Item>
            <Descriptions.Item label="Total Number of Comments">
              {totalNumberofComments}
            </Descriptions.Item>
          </Descriptions>
        </div>
      )}
      <Label className="comments__comment-section">
        Ratings by {contentType}
      </Label>
      <Table
        dataSource={contentSummary}
        rowKey="id"
        pagination={false}
        onRow={ratingSummary => ({
          onClick: event => {
            history.push({
              pathname: `/course/${courseId}/ratings/${contentType.toLowerCase()}/${
                ratingSummary.id
              }`,
              state: {
                ratingSummary
              }
            });
          }
        })}
      >
        <Column
          title={`${contentType} ${
            courseType === 'podcast' || courseType === 'video' ? 'Title' : ''
          }`}
          key="title"
          {...(courseType !== 'podcast' && courseType !== 'video'
            ? { width: '60%' }
            : {})}
          render={(value, ratingSummary) => (
            <span>
              {courseType === 'podcast' || courseType === 'video' ? (
                ratingSummary.title
              ) : (
                <span
                  dangerouslySetInnerHTML={{ __html: ratingSummary.body }}
                />
              )}
            </span>
          )}
        />
        <Column
          title={
            courseType === 'podcast'
              ? `${contentType} Release Date`
              : courseType === 'video'
              ? 'Topic'
              : 'Tags'
          }
          key="customField"
          render={(value, ratingSummary) => (
            <span>
              {courseType === 'podcast'
                ? moment(parseInt(ratingSummary.activatedAt, 10)).format(
                    'MMMM Do YYYY'
                  )
                : courseType === 'video'
                ? ratingSummary.topic
                : ratingSummary.tags}
            </span>
          )}
        />
        <Column
          title={`Average ${contentType} Rating`}
          key="averageRating"
          render={(value, ratingSummary) => (
            <span>{_.round(ratingSummary.averageRating, 2)}</span>
          )}
        />
        <Column
          title={`Total ${contentType} Rating Count`}
          key="totalRatingCount"
          render={(value, ratingSummary) => <span>{ratingSummary.count}</span>}
        />
        <Column
          title={`Total ${contentType} Comment Count`}
          key="totalCommentCount"
          render={(value, ratingSummary) => (
            <span>{ratingSummary.countWithComments}</span>
          )}
        />
      </Table>
      <Pagination
        current={offset / limit + 1}
        onChange={(pageNumber, pageSize) => {
          setLimit(pageSize);
          setOffset(pageSize * (pageNumber - 1));
        }}
        total={totalContentCount}
        pageSize={limit}
        showSizeChanger={true}
      />
    </div>
  );
}
