import React, { useState, useEffect } from 'react';
import './Organizations.css';
import { Button, Input, Modal, Select } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { Label } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { useMutation } from '@apollo/client';
import CREATE_ORGANIZATION_MUTATION from './queries/CreateOrganizationMutation';
import UPDATE_ORGANIZATION_MUTATION from './queries/UpdateOrganizationMutation';
import config from '../../config';

import { getAccountsByPermissionId } from '../../utils/rolesAndPermissions';
export default function AddOrganizationsModal(props) {
  const [visible, setVisible] = useState(false);
  const [managerSelected, setManagerSelected] = useState('');
  const [typeSelected, setTypeSelected] = useState('');

  const [orgName, setOrgName] = useState('');
  const [validated, setValidated] = useState(false);
  const [accountManagers, setAccountManagers] = useState(null);
  const showModal = () => {
    if (props.isUpdating) {
      setManagerSelected(props.managerId);
      setTypeSelected(props.type);
      setOrgName(props.name);
    }
    setVisible(true);
  };

  const [addOrganization] = useMutation(CREATE_ORGANIZATION_MUTATION, {
    onCompleted: (data, clientOptions) => {
      handleClose(data);
      props.callback();
    }
  });

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION_MUTATION, {
    onCompleted: async (data, clientOptions) => {
      handleClose(data);
      if (props.callback) {
        await props.callback();
      }
    }
  });
  const handleOk = e => {
    if (props.isUpdating) {
      updateOrganization({
        variables: {
          id: props.id,
          name: orgName,
          managerId: managerSelected,
          updatedAt: new Date(),
          type: typeSelected
        }
      });
    } else {
      addOrganization({
        variables: {
          id: uuidv4(),
          name: orgName,
          managerId: managerSelected,
          updatedAt: new Date(),
          type: typeSelected,
          createdAt: new Date()
        }
      });
    }
  };
  const handleClose = e => {
    setOrgName(props.isUpdating ? props.name : '');
    setManagerSelected(props.isUpdating ? props.managerId : '');
    setTypeSelected(props.isUpdating ? props.type : '');
    setVisible(false);
  };

  useEffect(() => {
    if (!accountManagers) {
      const loadAccountManagers = async () => {
        try {
          const accountManagers = await getAccountsByPermissionId(
            config.permissions.canManageGroups
          );
          const defaultValue = {
            disabled: true,
            firstName: 'Select',
            id: 'select'
          };
          setAccountManagers([defaultValue, ...accountManagers]);
        } catch (e) {
          throw e;
        }
      };
      loadAccountManagers();
    }
  }, [accountManagers]);

  const validate = async () => {
    const valid = typeSelected && managerSelected && orgName;
    if (valid !== validated) {
      setValidated(typeSelected && managerSelected && orgName);
    }
  };

  validate();

  return (
    <>
      <Button
        type={props.isUpdating ? 'default' : 'primary'}
        onClick={showModal}
      >
        {props.isUpdating ? (
          <>
            <EditOutlined style={{ display: 'contents' }} /> Edit Organization
            Info
          </>
        ) : (
          <>
            <PlusOutlined style={{ display: 'contents' }} /> New Organization
          </>
        )}
      </Button>

      <Modal
        width="20rem"
        title={props.isUpdating ? 'Edit Organization' : 'Add New Organization'}
        visible={visible}
        okText={props.isUpdating ? 'Edit Organization' : 'Add New Organization'}
        onOk={handleOk}
        onCancel={handleClose}
        okButtonProps={{
          disabled: !validated
        }}
      >
        <div>
          <Label>Organization Name </Label>
          <br />
          <Input
            style={{
              width: '100%',
              marginBottom: '1rem'
            }}
            value={orgName}
            onChange={async e => {
              setOrgName(e.target.value);
            }}
          />
        </div>
        <div>
          <Label>Hippo Organization Manager:</Label>
          {accountManagers && (
            <Select
              value={managerSelected}
              className="group__details__manager"
              defaultValue="select"
              style={{
                width: '100%',
                marginBottom: '1rem'
              }}
              onChange={async e => {
                setManagerSelected(e);
              }}
            >
              {accountManagers.map(account => (
                <Select.Option
                  disabled={account.disabled}
                  key={account.id}
                  value={account.id}
                >
                  {account.firstName}
                </Select.Option>
              ))}
            </Select>
          )}
        </div>
        <div>
          <Label>Organization Type</Label>
          <br />
          <Select
            value={typeSelected}
            defaultValue="select"
            style={{
              width: '100%'
            }}
            onChange={async e => {
              setTypeSelected(e);
            }}
            options={[
              {
                value: 'select',
                disabled: true,
                label: 'Select'
              },
              {
                value: 'MEDICAL',
                label: 'Medical Organization'
              },
              {
                value: 'EDUCATIONAL',
                label: 'Educational Organization'
              }
            ]}
          />
        </div>
      </Modal>
    </>
  );
}
