import React from 'react';
import { Route } from 'react-router-dom';

import Associations from '../containers/associations/Associations';
import Association from '../containers/associations/Association';

const BundleRoutes = () => {
  return (
    <>
      <Route exact={true} path="/associations" component={Associations} />
      <Route exact={true} path="/association/:id" component={Association} />
    </>
  );
};

export default BundleRoutes;
