import { gql } from '@apollo/client';

export const CREATE_CLOSED_CAPTION_ATTACHMENT_MUTATION = gql`
  mutation UpdateMedia($attachmentId: UUID!, $id: UUID!) {
    updateMedia(
      input: { patch: { vttFileAttachmentId: $attachmentId }, id: $id }
    ) {
      clientMutationId
    }
  }
`;
