import React from 'react';
import BreadcrumbNavigation from '../../components/BreadcrumbNavigation/BreadcrumbNavigation';

export default function ContentHeader({ breadCrumb, hasTitle, title, backTo }) {
  return (
    <div>
      <BreadcrumbNavigation data={breadCrumb} backTo={backTo} />
      <hr />
      {hasTitle && (
        <div className="section-header">
          <h5>{title}</h5>
        </div>
      )}
    </div>
  );
}
