import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Cookie from 'js-cookie';
import Notifications, { notify } from 'react-notify-toast';
import { Route, Redirect, withRouter, matchPath } from 'react-router-dom';

import SidebarPage from './components/SidebarPage';

import Login from './routes/Login';
import Dashboard from './routes/Dashboard';
import ReportRoutes from './routes/ReportRoutes';
import AccountRoutes from './routes/AccountRoutes';
import GroupRoutes from './routes/GroupRoutes';
import OrganizationRoutes from './routes/OrganizationRoutes';
import PromotionRoutes from './routes/PromotionRoutes';
import LectureRoutes from './routes/LectureRoutes';
import CourseRoutes from './routes/CourseRoutes';
import ContentRoutes from './routes/ContentRoutes';
import OrderRoutes from './routes/OrderRoutes';
import ProductRoutes from './routes/ProductRoutes';
import CommentRoutes from './routes/CommentRoutes';
import ItemRoutes from './routes/ItemRoutes';
import BundleRoutes from './routes/BundleRoutes';
import AssociationRoutes from './routes/AssociationRoutes';

import * as API from './API';

import {
  getCodeFromLocation,
  fetchTokenFromServer
} from './utils/authentication';
import { getDecodedTokenFromCookie } from './utils/cookie';

import './App.css';

class App extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    defaultOpenKeys: [],
    redirectTo: null
  };

  constructor(props) {
    super(props);
    const match = matchPath(props.location.pathname, {
      path: '/:key'
    });
    const defaultOpenKeys = match
      ? [match.params.key, match.params.key + 's']
      : [];
    const redirectTo = null;

    const subMatch = matchPath(props.location.pathname, {
      path: '/:key/:subkey'
    });
    if (subMatch && subMatch.params.subkey) {
      defaultOpenKeys.push(subMatch.params.subkey);
    }

    const code = getCodeFromLocation(window.location);

    if (code) {
      fetchTokenFromServer().then(() => window.location.reload());
    } else {
      this.state = {
        defaultOpenKeys,
        redirectTo
      };

      this.validateSession();
    }
  }

  validateSession = async () => {
    const token = getDecodedTokenFromCookie();

    if (token) {
      // TODO: I think we should bundle isAdmin with the web token, but since
      // this bug is live and has already dragged on a bit, this works
      // equivalently as affectively, but just requires one additional request.
      // This could break if we remove this endpoint, but a) we probably won't
      // do that and b) it would be caught right away / could be addressed.
      const adminOnlyUrl = '/api/account/84aa19eb-99e3-4ef9-9d04-0e9973adfbc5';

      try {
        await API.raw('GET', adminOnlyUrl);
        this.setState({ showDashboard: true });
      } catch (e) {
        notify.show(
          `Error proceeding to the dashboard (${e.message})`,
          'error'
        );
      }
    }
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (this.state.redirectTo !== null) {
      this.setState({ redirectTo: null });
    }
  };

  logout = () => {
    this.setState({ showDashboard: false, redirectTo: '/' }, () => {
      Cookie.remove(process.env.REACT_APP_SESSION_COOKIE_NAME);
      Cookie.remove('hippo-session:expiration_time');
      Cookie.remove('hippo_education_cache_2', {
        domain: process.env.REACT_APP_COOKIE_DOMAIN
      });
      Cookie.remove('code-verifier', {
        domain: process.env.REACT_APP_COOKIE_DOMAIN
      });
    });
  };

  render() {
    const { redirectTo } = this.state;
    if (redirectTo) {
      return <Redirect to={redirectTo} push={true} />;
    }

    return (
      <>
        <Notifications />
        <Route path="/" component={Login} />
        {this.state.showDashboard && (
          <SidebarPage
            defaultOpenKeys={this.state.defaultOpenKeys}
            logout={this.logout}
          >
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/report" component={ReportRoutes} />
            <Route path={['/accounts', '/account']} component={AccountRoutes} />
            <Route
              path={['/group-management/groups', '/group-management/group']}
              component={GroupRoutes}
            />
            <Route
              path={[
                '/group-management/organizations',
                '/group-management/organization'
              ]}
              component={OrganizationRoutes}
            />
            <Route path={['/courses', '/course']} component={CourseRoutes} />
            <Route path={['/content']} component={ContentRoutes} />
            <Route path={['/orders', '/order']} component={OrderRoutes} />
            <Route path={['/products']} component={ProductRoutes} />
            <Route path={['/comments']} component={CommentRoutes} />
            <Route path={['/item']} component={ItemRoutes} />
            <Route path={['/bundles', '/bundle']} component={BundleRoutes} />
            <Route
              path={['/associations', '/association']}
              component={AssociationRoutes}
            />
            <Route
              path={['/promotions', '/promotion', '/promotion_code']}
              component={PromotionRoutes}
            />
            <Route path="/lecture" component={LectureRoutes} />
          </SidebarPage>
        )}
      </>
    );
  }
}

window.asTitle = function asTitle(str) {
  return str.replace(/\b\S/g, t => t.toUpperCase());
};

export default withRouter(App);
