import React from 'react';

import ListHeaderMenu from './ListHeaderMenu';

import { Button, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import './ListHeaderActionItems.css';

export default function ListHeaderActionItems({
  actions,
  onCreateNew,
  createButtonText,
  filterOptions,
  sortOptions,
  getResourceOptions,
  filter,
  useCustomSearchChildren = false,
  children
}) {
  return (
    <div className="list-header">
      {useCustomSearchChildren ? (
        children
      ) : (
        <Input
          placeholder="Search title/date/tag/faculty"
          onChange={e => actions.setOption('_adminSearch', e.target.value)}
        />
      )}
      <Button
        className="list-header__new"
        icon={<PlusOutlined />}
        type="primary"
        onClick={onCreateNew}
      >
        {`New ${createButtonText}`}
      </Button>
      {filterOptions && (
        <ListHeaderMenu
          className="list-header__filter"
          buttonPrefix="Filter: "
          options={filterOptions}
          defaultValue="all"
          onChange={newValue => filter(newValue)}
        />
      )}
      <ListHeaderMenu
        className="list-header__sort"
        buttonPrefix="Sort: "
        options={sortOptions}
        defaultValue={getResourceOptions().sort}
        onChange={newValue => actions.setOption('sort', newValue)}
      />
    </div>
  );
}
