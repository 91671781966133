import { gql } from '@apollo/client';

export const COURSE_DETAIL_QUERY = gql`
  query courseDetailQuery($accountId: UUID!, $bucketIds: [UUID!]) {
    accountEarnedCmesList(
      condition: { accountId: $accountId }
      filter: { cmeBucketId: { in: $bucketIds } }
    ) {
      course {
        id
        title
      }
      credits
      product {
        id
        title
      }
      accountEarnedCmeAttemptBindingsList {
        accountCmeAttempt {
          ...AccountCmeAttemptFragment
        }
      }
      accountCmeAttempt {
        ...AccountCmeAttemptFragment
      }
      cmeBucket {
        id
        title
        maxCredits
        cmeAccreditor {
          name
        }
        course {
          id
          title
          courseType {
            id
            shortname
          }
        }
        activatedAt
        expiredAt
      }
    }
  }

  fragment AccountCmeAttemptFragment on AccountCmeAttempt {
    id
    activityTimeInSeconds
    credits
    passed
    status
    createdAt
    attemptedAt
    assessment {
      id
      title
    }
    assessmentTitle: title
    lecture {
      ...LectureFragment
    }
    topic {
      ...TopicFragment
    }
    chapter {
      ...ChapterFragment
    }
    episode {
      ...EpisodeFragment
    }
    attachment {
      id
      title
      subtitle
      activityTimeInSeconds
      lectureAttachmentBindingsList {
        lecture {
          ...LectureFragment
        }
      }
      chapterAttachmentBindingsList {
        chapter {
          ...ChapterFragment
        }
      }
    }
    accountAssessmentSummariesList {
      id
      totalAnswers
      correctAnswers
      isComplete
      assessment {
        id
        title
      }
    }
  }

  fragment ChapterFragment on Chapter {
    id
    title
    media {
      duration
    }
    accountChapterProgressesList(condition: { accountId: $accountId }) {
      highestPosition
    }
    episodeChapterBindingsList {
      displayOrder
      episode {
        ...EpisodeFragment
      }
    }
  }

  fragment EpisodeFragment on Episode {
    id
    title
    activatedAt
    courseId
  }

  fragment LectureFragment on Lecture {
    id
    title
    displayOrder
    media {
      duration
    }
    accountLectureProgressesList(condition: { accountId: $accountId }) {
      highestPosition
    }
    parent {
      displayOrder
    }
    topic {
      ...TopicFragment
    }
  }

  fragment TopicFragment on Topic {
    id
    title
    displayOrder
    topicGroup {
      displayOrder
    }
  }
`;
