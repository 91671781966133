import React from 'react';
import PropTypes from 'prop-types';

import './Heading.css';

export default function Heading({ children }) {
  return (
    <strong className="heading">
      <span>{children}</span>
    </strong>
  );
}

Heading.propTypes = {
  children: PropTypes.node.isRequired
};
