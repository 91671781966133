import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Breadcrumb, Table } from 'antd';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_PROMOTION_ORDERS_QUERY } from './queries/PromotionQueries';

export default function ViewPromotionDetails() {
  const [promotion, setPromotion] = useState(null);
  const promotionMatch = useRouteMatch({ path: '/promotion/:id/details' });
  const { data } = useQuery(GET_PROMOTION_ORDERS_QUERY, {
    variables: { promotionId: promotionMatch.params.id }
  });

  useEffect(() => {
    if (data?.promotionOrderStats) {
      setPromotion(data.promotionOrderStats);
    }
  }, [data]);

  return (
    <div>
      <Row>
        <Col>
          <Breadcrumb style={{ padding: '20px 0' }}>
            <Breadcrumb.Item>
              <Link to="/dashboard">Hippo Admin</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/promotions">Promotions</Link>
            </Breadcrumb.Item>
            {promotion ? (
              <Breadcrumb.Item>
                <Link to={`/promotion/${promotion.promotion.id}/view`}>
                  {promotion.promotion.title}
                </Link>
              </Breadcrumb.Item>
            ) : null}
            {promotion ? (
              <Breadcrumb.Item active={true}>
                <Link to={`/promotion/${promotion.promotion.id}/details`}>
                  Promotion use
                </Link>
              </Breadcrumb.Item>
            ) : null}
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>Orders</h5>
        </Col>
      </Row>

      <Table
        style={{ backgroundColor: '#fff', margin: '10px 0 20px 0' }}
        pagination={true}
        columns={[
          {
            title: 'Date',
            key: 'date',
            dataIndex: 'monthyear'
          },
          {
            title: 'Total Num of Orders',
            key: 'num orders',
            dataIndex: 'numOrders'
          },
          {
            title: 'Total Num of Customers that Used the Promo',
            key: 'num customers used promo',
            dataIndex: 'totalCustomersThatHaveUsedPromo'
          },
          {
            title: 'Total Num of New Accounts Created',
            key: 'num new accounts created',
            dataIndex: 'totalNumNewAccountsCreated'
          },
          {
            title: 'Avg Num of Items Purchased',
            key: 'avg num items purchased',
            dataIndex: 'averageNumItemsPurchased'
          },
          {
            title: 'Avg Order Value',
            key: 'avg order value',
            dataIndex: 'averageTotalPerOrder'
          },
          {
            title: 'Gross Sales',
            key: 'gross sales',
            dataIndex: 'grossSalesTotal'
          },
          {
            title: 'Total Amount Discounted',
            key: 'total amount discounted',
            dataIndex: 'totalAmountDiscounted'
          }
        ]}
        dataSource={
          promotion?.roiData?.lineItems ? promotion.roiData.lineItems : []
        }
      />
    </div>
  );
}
