import React from 'react';
import { Form, Row, Select, Checkbox, Input } from 'antd';

const { Option } = Select;

export default function AddOnFields({
  parentOfItem,
  activeItem,
  allItems,
  existingAddOnItem,
  handleUpdate,
  handleClearAddOnParentId
}) {
  return (
    <div className="item__field-sections">
      <Row>
        <Form.Item
          name="isAddOn"
          valuePropName="checked"
          style={{ marginRight: '0.75rem' }}
        >
          <Checkbox checked={activeItem.isAddOn}>Is Add On?</Checkbox>
        </Form.Item>
        <Form.Item
          name="isRequiredAddon"
          valuePropName="checked"
          hidden={!activeItem.isAddOn}
        >
          <Checkbox checked={activeItem.isRequiredAddon}>
            Is Required Add On?
          </Checkbox>
        </Form.Item>
        <Form.Item
          name="allowStandalone"
          valuePropName="checked"
          hidden={!activeItem.isAddOn}
          extra="Add on can be purchased as a standalone item"
        >
          <Checkbox checked={activeItem.allowStandalone}>
            Allow Standalone?
          </Checkbox>
        </Form.Item>
      </Row>
      <Form.Item
        name="parentId"
        label="Make this Item an Add On to:"
        hidden={!activeItem.isAddOn && !existingAddOnItem}
        extra={
          activeItem.isAddOn && existingAddOnItem ? (
            <div>
              Go To Current Parent Item:{' '}
              <a href={`/item/${existingAddOnItem.id}`}>
                {existingAddOnItem.title}
              </a>
            </div>
          ) : null
        }
      >
        <Select
          initialvalues={existingAddOnItem && existingAddOnItem.id}
          onChange={handleUpdate}
          placeholder="Add Parent Item..."
          allowClear={true}
          onClear={handleClearAddOnParentId}
        >
          {allItems &&
            allItems.map(item => (
              <Option key={item.id} value={item.id}>
                {item.title} - <small>{item.sku}</small>
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Add on Text"
        name="addOnText"
        extra="Item description under Cart Summary 'card' if the item is an Add On, i.e UCB/A"
        hidden={!activeItem.isAddOn}
      >
        <Input />
      </Form.Item>
      {parentOfItem && (
        <div className="item__is-parent-message">
          <b>{activeItem.title}</b> is parent for add on item:{' '}
          <a href={`/item/${parentOfItem.id}`}>{parentOfItem.title}</a>
        </div>
      )}
    </div>
  );
}
