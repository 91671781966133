import React, { useState } from 'react';
import { Button, Col, notification, Popconfirm, Row, Typography } from 'antd';
import { LinkOutlined, MenuOutlined, WarningOutlined } from '@ant-design/icons';

import * as API from '../../API';

import SortableList from '../../components/SortableList';

import './AssessmentsView.css';

const { Text } = Typography;

export default function QuestionsTableView({
  assessment,
  refresh,
  showHeader = false
}) {
  const [assessmentQuestionBindings, setAssessmentQuestionBindings] = useState(
    assessment.assessmentQuestionBindings
  );
  const onQuestionDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const removedItem = assessmentQuestionBindings.splice(
      result.source.index,
      1
    );
    assessmentQuestionBindings.splice(
      result.destination.index,
      0,
      removedItem[0]
    );

    setAssessmentQuestionBindings(assessmentQuestionBindings);
    reorderBindings(assessmentQuestionBindings);
  };

  const reorderBindings = async bindings => {
    await Promise.all(
      bindings.map(async (binding, index) => {
        binding.displayOrder = index * 10;
        await API.assessmentQuestionBinding.update({
          id: binding.id,
          attributes: {
            displayOrder: index * 10,
            updatedAt: new Date()
          }
        });
        return binding;
      })
    );
    refresh();
  };

  const unlinkQuestion = async binding => {
    try {
      await API.assessmentQuestionBinding.delete({ id: binding.id });
      refresh();
    } catch (e) {
      notification.error({
        message: `Error deleting ${binding.id}`,
        description: e.message
      });
    }
  };

  const isBootcampAssessment =
    assessment.course.courseType.shortname === 'bootcamp-assessment';

  return (
    <>
      {showHeader && (
        <Row className="assessment-questions__header">
          <Col span={1} />
          {isBootcampAssessment && <Col span={1}>#</Col>}
          <Col span={12}>Question</Col>
          <Col span={4}>Correct Option</Col>
          <Col span={1} />
          <Col span={4}>Id</Col>
        </Row>
      )}
      <SortableList
        itemType="questions"
        items={assessment.assessmentQuestionBindings
          ?.filter(
            binding => binding.assessmentVersion === assessment.currentVersion
          )
          .sort((q1, q2) => q1.displayOrder - q2.displayOrder)}
        onDragEnd={onQuestionDragEnd}
        className="assessment-questions"
        renderItem={(item, index) => (
          <div key={index}>
            <Row>
              <Col span={1} className="assessment-questions__item-column">
                <MenuOutlined />
              </Col>
              {isBootcampAssessment && (
                <Col span={1} className="assessment-questions__item-column">
                  <Text>{(item.displayOrder + 10) / 10}</Text>
                </Col>
              )}
              <Col span={12} className="assessment-questions__item-column">
                <Text className="assessment-questions__item-body">
                  <div
                    dangerouslySetInnerHTML={{ __html: item.question.body }}
                  />
                </Text>
              </Col>
              <Col span={4} className="assessment-questions__item-column">
                <Text className="assessment-questions__item-body">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.question.options.find(
                        option => !!option.correctOption
                      )?.body
                    }}
                  />
                </Text>
              </Col>
              <Col span={1} className="assessment-questions__item-column">
                <Popconfirm
                  title="Unlink this question from this assessment?"
                  icon={<WarningOutlined style={{ color: 'red' }} />}
                  onConfirm={() => unlinkQuestion(item)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    type="text"
                    icon={<LinkOutlined />}
                    size="small"
                    title="Unlink question"
                  />
                </Popconfirm>
              </Col>
              <Col span={4} className="assessment-questions__item-column">
                <Text>{item.question.id}</Text>
              </Col>
            </Row>
          </div>
        )}
      />
    </>
  );
}
