import React from 'react';

const FACULTY_CHECKMARK = require('../../images/verified-faculty-checkmark.png');

export default class CommentsFacultyFlair extends React.Component {
  render() {
    return (
      <>
        <span className="comments-faculty-text-flair">
          <img
            className="comments-faculty-badge"
            src={FACULTY_CHECKMARK}
            alt="verified faculty checkmark"
          />
          Faculty
        </span>
        &nbsp;–&nbsp;
      </>
    );
  }
}
