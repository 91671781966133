import { gql } from '@apollo/client';

const UPDATE_ORGANIZATION_EMAIL_DOMAIN_ISENABLED_MUTATION = gql`
  mutation MyMutation($id: UUID!, $isEnabled: Boolean!) {
    updateOrganizationEmailDomain(
      input: { patch: { isEnabled: $isEnabled }, id: $id }
    ) {
      clientMutationId
    }
  }
`;

export default UPDATE_ORGANIZATION_EMAIL_DOMAIN_ISENABLED_MUTATION;
