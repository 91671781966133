export const getRandomLoadingMessage = () => {
  const loadingMessages = [
    'Reticulating splines',
    'Ensuring kittens are still adorable',
    'Loading two electrodes and a lemon',
    'Scattering Hippo Food Sources',
    'Enjoying the elevator music',
    'Checking whether everything in this universe is a potato or not a potato',
    'Herding the datacats',
    'Asking Brandon whether hot dogs are tacos'
  ];

  return loadingMessages[Math.floor(Math.random() * loadingMessages.length)];
};
