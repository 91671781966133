import {
  sendEmail,
  getBaseTemplate,
  getMergeVars,
  getEmailAddress,
  getFullName
} from './mandrill';
import { getResetPasswordUrl } from '../utils/account';

export function getEmails() {
  // The default custom message is the message that will be sent if no custom message is added.
  return [
    {
      title: 'Welcome',
      includesLink: true,
      defaultSubject: 'Welcome to Hippo Education!',
      getMessage: (courseName, groupName) =>
        `Welcome to Hippo Education! You now have access to ${
          courseName ? courseName : 'Hippo content'
        }. Below is a link to set up your password (your username is your email address). Once you have logged in, click on “Courses” and then the course name to get started.`,
      sendEmail: async (account, subject, customMessage) =>
        await sendWelcomeEmail(account, subject, customMessage)
    },
    {
      title: 'Group Invite',
      includesLink: true,
      defaultSubject: 'Welcome to Hippo Education',
      getMessage: (courseName, groupName) =>
        `Welcome to Hippo Education! As a member of ${groupName}, you now have access to ${
          courseName ? courseName : 'Hippo content'
        }. Below is a link to set up your password (your username is your email address). Once you have logged in, click on “Courses” and then the course name to get started.`,
      sendEmail: async (account, subject, customMessage) =>
        await sendWelcomeEmail(account, subject, customMessage)
    },
    {
      title: 'Forgot Password',
      includesLink: true,
      defaultSubject: 'Password reset link for Hippo Education',
      getMessage: (courseName, groupName) =>
        'Below is a link that will let you reset your Hippo Education password.',
      sendEmail: async (account, subject, customMessage) =>
        await sendForgotPasswordEmail(account, subject, customMessage)
    }
  ];
}

export async function sendForgotPasswordEmail(account, subject, customMessage) {
  const resetPasswordLink = await getResetPasswordUrl(account.email);
  const template = getBaseTemplate('hippo', 'forgot-password');
  template.template_name = 'forgot-password';
  template.template_content = []; // This is required but unused

  template.message.to = [
    {
      email: getEmailAddress(account),
      name: getFullName(account),
      type: 'to'
    }
  ];

  template.message.global_merge_vars.push(
    ...getMergeVars({
      subject,
      custom_message: customMessage,
      reset_password_url: resetPasswordLink,
      first_name: account.firstName
    })
  );

  return sendEmail(template);
}

export async function sendWelcomeEmail(account, subject, customMessage) {
  const resetPasswordLink = await getResetPasswordUrl(account.email);
  const template = getBaseTemplate('hippo', 'welcome');
  template.template_name = 'welcome-email';
  template.template_content = []; // This is required but unused

  template.message.to = [
    {
      email: getEmailAddress(account),
      name: getFullName(account),
      type: 'to'
    }
  ];

  template.message.global_merge_vars.push(
    ...getMergeVars({
      subject,
      custom_message: customMessage,
      reset_password_url: resetPasswordLink,
      first_name: account.firstName
    })
  );

  return sendEmail(template);
}
