import { gql } from '@apollo/client';

export const CREATE_COURSE_ATTACHMENT_BINDING = gql`
  mutation CreateCourseAttachmentBinding(
    $id: UUID!
    $courseId: UUID!
    $attachmentId: UUID!
    $createdAt: Datetime!
    $updatedAt: Datetime!
  ) {
    createCourseAttachmentBinding(
      input: {
        courseAttachmentBinding: {
          id: $id
          courseId: $courseId
          attachmentId: $attachmentId
          createdAt: $createdAt
          updatedAt: $updatedAt
        }
      }
    ) {
      courseAttachmentBinding {
        id
        courseId
        attachmentId
      }
    }
  }
`;

export const UPDATE_COURSE_ATTACHMENT_BINDING = gql`
  mutation UpdateCourseAttachmentBinding(
    $id: UUID!
    $activatedAt: Datetime
    $expiredAt: Datetime
    $updatedAt: Datetime!
  ) {
    updateCourseAttachmentBinding(
      input: {
        patch: {
          activatedAt: $activatedAt
          expiredAt: $expiredAt
          updatedAt: $updatedAt
        }
        id: $id
      }
    ) {
      courseAttachmentBinding {
        id
        activatedAt
        expiredAt
      }
    }
  }
`;

export const DELETE_COURSE_ATTACHMENT_BINDING = gql`
  mutation DeleteCourseAttachmentBinding($id: UUID!) {
    deleteCourseAttachmentBinding(input: { id: $id }) {
      clientMutationId
    }
  }
`;
