import React from 'react';
import {
  DeleteOutlined,
  MergeCellsOutlined,
  PlusOutlined
} from '@ant-design/icons';
import {
  Button,
  Empty,
  Input,
  notification,
  Table,
  Popover,
  Space
} from 'antd';
import moment from 'moment';

import EditableLabel from '../../../components/EditableLabel/EditableLabel';
import FillMainView from '../../../components/FillMainView';
import ListHeaderMenu from '../../../components/PaginatedList/ListHeaderMenu';
import PaginatedList from '../../../components/PaginatedList/PaginatedList';
import BreadcrumbConstants from '../../../routes/BreadcrumbConstants';
import * as API from '../../../API';

import TagView from './TagView';
import './TagsView.css';
import ContentHeader from '../ContentHeader';
import { Link } from 'react-router-dom';

export default class TagsView extends React.Component {
  state = {
    editingTag: null,
    tagSearch: ''
  };

  componentDidMount() {
    if (this.props.match.params.tagId) {
      this.loadTagToEdit(this.props.match.params.tagId);
    }
  }

  loadTagToEdit = async tagId => {
    const { data: editingTag } = await API.tag.find({ id: tagId });
    this.setState({ editingTag });
  };

  createTag = async () => {
    try {
      const resource = await API.tag.create({
        attributes: { title: 'Unnamed tag', isRecommended: false }
      });
      const newTag = API.simplifyResource(resource);
      this.setState({ editingTag: newTag._original });
    } catch (e) {
      notification.error({
        message: 'Error creating new tag',
        description: e.message
      });
    }
  };

  updateTag = async (id, key, value) => {
    try {
      await API.tag.update({
        id,
        attributes: {
          [key]: value
        }
      });
    } catch (e) {
      notification.error({
        message: 'Error updating tag title',
        description: e.message
      });
    }
  };

  deleteTag = async tag => {
    const response = window.confirm(
      'Are you sure you want to delete this tag? It is linked to ' +
        `${tag.chapterTagBindings.length} chapters and ` +
        `${tag.questionUniversalTagBindings.length} questions. Deleting the link will ` +
        'remove the tag from those chapters.'
    );

    if (response) {
      try {
        await API.tag.delete({ id: tag.id });
      } catch (e) {
        notification.error({
          message: `Error deleting ${tag.id}`,
          description: e.message
        });
      }
    }
  };

  getResourceOptions = () => ({
    sort: '-createdAt',
    include: 'tagType,image'
  });

  tagBreadcrumb = [
    BreadcrumbConstants.HippoAdmin,
    BreadcrumbConstants.Content,
    BreadcrumbConstants.Tags
  ];

  stopEditing = () => {
    this.setState({ editingTag: null });
    this.props.history.push('/content/tags');
  };

  startEditing = editingTag => {
    this.setState({ editingTag });
    this.props.history.push(`/content/tags/${editingTag.id}`);
  };

  render() {
    const sortOptions = {
      title: 'Title A-Z',
      '-title': 'Title Z-A',
      '-createdAt': 'Creation Date DESC',
      createdAt: 'Creation Date ASC'
    };

    return (
      <div>
        <ContentHeader breadCrumb={this.tagBreadcrumb} hasTitle={false} />
        {this.state.editingTag ? (
          <FillMainView
            onClose={this.stopEditing}
            closeButtonText="Back to Tags"
            breadcrumbData={this.tagBreadcrumb}
          >
            <TagView tag={this.state.editingTag} />
          </FillMainView>
        ) : (
          <PaginatedList
            resource={API.tag}
            defaultOptions={this.getResourceOptions()}
            renderEmptyList={() => <Empty description="No tags to show" />}
            onLoadRows={resource => API.simplifyResource(resource)}
            renderHeader={(actions, refresh) => (
              <div className="menu-bar">
                <Button
                  icon={<PlusOutlined />}
                  type="primary"
                  onClick={() => this.createTag()}
                >
                  New Tag
                </Button>
                <Input
                  placeholder="Search title"
                  value={this.state.tagSearch}
                  onPressEnter={e => {
                    this.setState({ tagSearch: e.target.value });
                    if (e.target.value) {
                      actions.setFilter(
                        'title',
                        e.target.value && `:${e.target.value}`
                      );
                    }
                  }}
                  onChange={e => {
                    this.setState({ tagSearch: e.target.value });
                    if (e.target.value) {
                      actions.setFilter(
                        'title',
                        e.target.value && `:${e.target.value}`
                      );
                    }
                  }}
                />
                <ListHeaderMenu
                  className="episode-header__sort"
                  buttonPrefix="Sort: "
                  options={sortOptions}
                  defaultValue={this.getResourceOptions().sort}
                  onChange={newValue => actions.setOption('sort', newValue)}
                />
              </div>
            )}
            renderList={(data, refresh) => {
              return (
                <Table
                  className="chapter-tag-table"
                  style={{ backgroundColor: '#fff', marginTop: '20px' }}
                  pagination={false}
                  bordered={true}
                  rowKey={tag => tag.id}
                  onRow={record => ({
                    onClick: () => {
                      this.startEditing(record._original);
                    }
                  })}
                  columns={[
                    {
                      title: 'Tag',
                      key: 'title',
                      render: tag => (
                        <EditableLabel
                          defaultValue={tag.title}
                          onChange={async value => {
                            await this.updateTag(tag.id, 'title', value);
                            refresh();
                          }}
                        />
                      )
                    },
                    {
                      title: 'Tag type',
                      key: 'tagType',
                      render: tag => {
                        if (tag.tagType) {
                          return <div>{tag.tagType.name}</div>;
                        }

                        return null;
                      }
                    },
                    {
                      title: 'Tag image',
                      key: 'tagImage',
                      render: tag => {
                        if (tag.image) {
                          return (
                            <Popover
                              content={
                                <img
                                  src={tag.image.url}
                                  alt="Tag"
                                  height="200"
                                />
                              }
                            >
                              <div style={{ textAlign: 'center' }}>
                                <img
                                  src={tag.image.url}
                                  alt="Tag"
                                  height="20"
                                />
                              </div>
                            </Popover>
                          );
                        }

                        return null;
                      }
                    },
                    {
                      title: 'Total uses',
                      key: 'id',
                      render: tag => (
                        <div>
                          {tag.chapterTagBindings.length +
                            tag.topicTagBindings.length +
                            tag.questionUniversalTagBindings.length}
                        </div>
                      ),
                      sorter: (a, b) =>
                        a.chapterTagBindings.length +
                        a.topicTagBindings.length +
                        a.questionUniversalTagBindings.length -
                        (b.chapterTagBindings.length +
                          b.topicTagBindings.length +
                          b.questionUniversalTagBindings.length)
                    },
                    {
                      title: 'Date created',
                      key: 'createdAt',
                      render: tag => (
                        <span>
                          {moment(tag.createdAt)
                            .tz(process.env.REACT_APP_DISPLAY_TIMEZONE)
                            .format('MMM D YYYY @ H:mm zz')}
                        </span>
                      ),
                      sorter: (a, b) =>
                        new Date(a.createdAt) - new Date(b.createdAt)
                    },
                    {
                      title: 'Delete',
                      key: 'delete',
                      render: tag => {
                        return (
                          <Space className="child-fully-centered">
                            <Button
                              title="Delete"
                              size="small"
                              default={true}
                              type="danger"
                              onClick={async e => {
                                e.stopPropagation();
                                await this.deleteTag(tag);
                                refresh();
                              }}
                            >
                              <DeleteOutlined />
                            </Button>

                            <Button
                              to=""
                              title="Merge"
                              size="small"
                              default={true}
                              type="ghost"
                            >
                              <Link to={`/content/tags/merge/${tag.id}`}>
                                <MergeCellsOutlined />
                              </Link>
                            </Button>
                          </Space>
                        );
                      }
                    }
                  ]}
                  dataSource={data}
                  size="small"
                />
              );
            }}
            pageLimit={20}
          />
        )}
      </div>
    );
  }
}
