import React, { useState, useEffect } from 'react';
import { Modal, Button, List, Input, Checkbox } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import sortByKey from '../../utils/sortByKey';
import { useMutation } from '@apollo/client';

import CREATE_ORGANIZATION_PROGRAM_BINDING_MUTATION from './queries/CreateOrganizationProgramBindingMutation';
import DELETE_ORGANIZATION_PROGRAM_BINDING_MUTATION from './queries/DeleteOrganizationProgramBindingMutation';

const MapExistingClinicModal = props => {
  const {
    clinics,
    refreshParent,
    organizationId,
    preselectedClinics,
    orgType
  } = props;
  const [filteredClinics, setFilteredClinics] = useState();
  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedClinics, setSelectedClinics] = useState(
    preselectedClinics ? preselectedClinics : []
  );
  const [addOrganizationProgramBinding] = useMutation(
    CREATE_ORGANIZATION_PROGRAM_BINDING_MUTATION,
    {
      onCompleted: async (data, clientOptions) => {
        if (refreshParent) {
          await refreshParent();
        }
      }
    }
  );

  const openModal = () => {
    setShowModal(true);
    setSelectedClinics(preselectedClinics);
  };

  const [deleteOrganizationDefaultSubscription] = useMutation(
    DELETE_ORGANIZATION_PROGRAM_BINDING_MUTATION,
    {
      onCompleted: async (data, clientOptions) => {
        if (refreshParent) {
          await refreshParent();
        }
      }
    }
  );
  const onSearch = e => {
    setSearchText(e.target.value);
  };

  const onSelectClinic = clinic => {
    const clinicSelected =
      selectedClinics.findIndex(selectedClinic => {
        return selectedClinic.id === clinic.id;
      }) >= 0;
    if (clinicSelected) {
      const selectClinics = selectedClinics.filter(selectedClinic => {
        return selectedClinic.id !== clinic.id;
      });

      setSelectedClinics(selectClinics);
    } else {
      setSelectedClinics([...selectedClinics, { ...clinic }]);
    }
  };

  const onAddClinics = () => {
    sortByKey(selectedClinics, 'name');

    selectedClinics.forEach(async clinic => {
      if (
        preselectedClinics.findIndex(
          prevClinic => prevClinic.id === clinic.id
        ) >= 0
      ) {
        // do nothing -- already added
      } else {
        // must add
        await addOrganizationProgramBinding({
          variables: {
            id: uuidv4(),
            clinicName: clinic.name,
            organizationId,
            createdAt: new Date(),
            updatedAt: new Date(),
            isEnabled: true,
            programId: clinic.id
          }
        });
      }
    });
    preselectedClinics.forEach(async propClinic => {
      if (
        selectedClinics.findIndex(
          selectedClinic => selectedClinic.id === propClinic.id
        ) >= 0
      ) {
        // do nothing -- already added
      } else {
        // is in the db but was removed -- must delete
        await deleteOrganizationDefaultSubscription({
          variables: {
            id: propClinic.bindingId
          }
        });
      }
    });

    setShowModal(false);
  };

  useEffect(() => {
    if (clinics && clinics !== preselectedClinics) {
      setSelectedClinics(preselectedClinics);
      setFilteredClinics(
        clinics.filter(clinic =>
          clinic.name.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }
  }, [searchText, clinics, preselectedClinics]);

  return (
    <>
      <span style={{ display: 'inline-flex', width: '10rem' }}>
        <Button type="primary" onClick={openModal}>
          Map Existing {orgType === 'MEDICAL' ? 'Clinic(s)' : 'Cohort(s)'}
        </Button>
      </span>
      <Modal
        width="600px"
        title={orgType === 'MEDICAL' ? 'Clinic' : 'Cohort'}
        visible={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="add"
            type="primary"
            disabled={!selectedClinics || selectedClinics.length === 0}
            onClick={onAddClinics}
          >
            Update Selected {orgType === 'MEDICAL' ? 'Clinic' : 'Cohort'}
          </Button>
        ]}
      >
        <Input
          placeholder={
            orgType === 'MEDICAL'
              ? 'Seach Clinic by Name'
              : 'Seach Cohort by Name'
          }
          onChange={onSearch}
        />
        <List
          size="small"
          header={
            <div>
              <span style={{ display: 'inline-flex' }}>
                <Checkbox />
              </span>
              <span style={{ display: 'inline', marginLeft: '2rem' }}>
                {orgType === 'MEDICAL' ? 'Clinic Name' : 'Cohort Name'}{' '}
              </span>
              <span style={{ display: 'inline', float: 'right' }}>Members</span>
            </div>
          }
          bordered={true}
          dataSource={filteredClinics}
          renderItem={clinic => {
            const checked =
              selectedClinics.findIndex(selectedClinic => {
                return selectedClinic.id === clinic.id;
              }) >= 0;
            return (
              <List.Item>
                <Checkbox
                  style={{ display: 'inline-flex' }}
                  checked={checked}
                  onChange={() => onSelectClinic(clinic)}
                />
                <span style={{ marginRight: 'auto', marginLeft: '2rem' }}>
                  {clinic.name}
                </span>
                <span style={{ display: 'inline', float: 'right' }}>
                  {clinic.members}
                </span>
              </List.Item>
            );
          }}
          pagination={{ defaultPageSize: 10 }}
        />
      </Modal>
    </>
  );
};
export default MapExistingClinicModal;
