import { gql } from '@apollo/client';

const UPDATE_SUBSCRIPTION_TEMPLATE = gql`
  mutation UpdateSubscriptionTemplate(
    $id: UUID!
    $defaultSubscriptionLength: Int
    $title: String
    $isCmeEnabled: Boolean
    $isCmeArchiveEnabled: Boolean
    $isFree: Boolean
    $updatedAt: Datetime
  ) {
    updateSubscriptionTemplate(
      input: {
        patch: {
          title: $title
          defaultSubscriptionLength: $defaultSubscriptionLength
          isCmeEnabled: $isCmeEnabled
          isCmeArchiveEnabled: $isCmeArchiveEnabled
          updatedAt: $updatedAt
          isFree: $isFree
        }
        id: $id
      }
    ) {
      clientMutationId
    }
  }
`;

export default UPDATE_SUBSCRIPTION_TEMPLATE;
