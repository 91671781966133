import _ from 'underscore';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Tooltip, Spin, Switch, Radio, Button, Alert, Input, Form } from 'antd';
import { Label } from 'reactstrap';
import { notify } from 'react-notify-toast';

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import CourseSelector from '../../course/CourseSelector';
import SingleImageInput from '../../../components/Upload/SingleImageInput';
import RichTextEditor from '../../../components/RichTextEditor';
import QuestionOption from '../../../components/QuestionOption/QuestionOption';
import ProductLinkSelector from '../../../components/ProductLinkSelector/ProductLinkSelector';
import TagSelector from '../../../components/TagSelector/TagSelector';

import * as API from '../../../API';
import { getTokenFromCookie } from '../../../utils/cookie';
import { CourseTypeTitles } from '../../../constants';
import { useGetDisplayableCourseIdsByCourseType } from '../../courseTypes/hooks/useGetDisplayableCourseIdsByCourseType';

export default function QuestionAddEdit({ questionId, isNew, onSave }) {
  const history = useHistory();
  const [question, setQuestion] = useState();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [correctAnswerIndex, setCorrectAnswerIndex] = useState(0);
  const [questionImages, setQuestionImages] = useState([]);
  const [questionImageBindings, setQuestionImageBindings] = useState([]);
  const [
    questionProductLinkBindingId,
    setQuestionProductLinkBindingId
  ] = useState(null);
  const [questionProductLinkId, setQuestionProductLinkId] = useState(null);
  const [questionAttachment, setQuestionAttachment] = useState(null);
  const [
    questionAttachmentBindingId,
    setQuestionAttachmentBindingId
  ] = useState(null);
  const [questionFinishedLoading, setQuestionFinishedLoading] = useState(false);

  const questionCourseTypeTitlesToDisplay = [
    CourseTypeTitles.ASSESSMENT,
    CourseTypeTitles.BOOTCAMP_ASSESSMENT,
    CourseTypeTitles.PRACTICE_EXAM,
    CourseTypeTitles.QBANK
  ];
  const {
    courseIds: questionCourseIds,
    loading: loadingQuestionCourseIds
  } = useGetDisplayableCourseIdsByCourseType(questionCourseTypeTitlesToDisplay);

  useEffect(() => {
    if (!question) {
      loadQuestion();
    }
  });

  const loadQuestion = async () => {
    try {
      const question = await API.question.find({
        id: questionId,
        options: {
          include:
            'course,questionImageBinding,questionImageBinding.image,questionProductLinkBinding,questionProductLinkBinding.productLink,questionAttachmentBinding,questionAttachmentBinding.attachment,questionUniversalTagBindings,questionUniversalTagBindings.tag'
        }
      });

      // Set up question
      setQuestion(question.data);
      question.data.attributes.options.forEach((option, index) => {
        if (option.correctOption) {
          setCorrectAnswerIndex(index);
        }
      });

      // Set up question option number
      const course = question.included.find(
        includes => includes.type === 'course'
      );
      const numberOfQuestionOptions =
        course.attributes.defaultQuestionOptionNumber;

      checkQuestionOptions(question.data, numberOfQuestionOptions);

      // Set up question images
      const images = question.included
        .filter(includes => includes.type === 'image')
        .sort(
          (a, b) =>
            moment(a.attributes.updatedAt) - moment(b.attributes.updatedAt)
        );
      setQuestionImages(images);
      setQuestionImageBindings(
        images.map(image => {
          return question.included
            .filter(includes => includes.type === 'questionImageBinding')
            .find(
              includes => includes.relationships.image.data.id === image.id
            );
        })
      );

      // Set up question product link binding
      const questionProductLinkBinding = question.included.find(
        includes => includes.type === 'questionProductLinkBinding'
      );
      if (questionProductLinkBinding) {
        setQuestionProductLinkBindingId(questionProductLinkBinding.id);
        setQuestionProductLinkId(
          questionProductLinkBinding.relationships.productLink.data.id
        );
      }

      // Set up question attachment for external link
      const questionAttachmentBinding = question.included.find(
        includes => includes.type === 'questionAttachmentBinding'
      );
      if (questionAttachmentBinding) {
        const attachment = question.included.find(
          includes =>
            includes.type === 'attachment' &&
            includes.id ===
              questionAttachmentBinding.relationships.attachment.data.id
        );
        setQuestionAttachment({
          title: attachment.attributes.title,
          key: attachment.attributes.key,
          id: attachment.id
        });
        setQuestionAttachmentBindingId(questionAttachmentBinding.id);
      } else {
        setQuestionAttachment({
          title: '',
          key: ''
        });
      }

      setQuestionFinishedLoading(true);
    } catch (e) {
      throw e;
    }
  };

  const updateQuestionAttribute = (field, value) => {
    const updatedQuestion = _.clone(question);
    updatedQuestion.attributes[field] = value;

    setQuestion(updatedQuestion);
  };

  const updateQuestionOptions = (option, index) => {
    const options = [...question.attributes.options];
    options[index] = option;
    updateQuestionAttribute('options', getUpdatedCorrectAnswerIndex(options));
  };

  const getUpdatedCorrectAnswerIndex = options => {
    return options.map((option, index) => {
      if (index === correctAnswerIndex) {
        return { ...option, correctOption: true };
      } else {
        return { ...option, correctOption: false };
      }
    });
  };

  const checkQuestionOptions = (questionData, numberOfQuestionOptions) => {
    // Change question option number
    while (questionData.attributes.options.length < numberOfQuestionOptions) {
      // Add a question option
      questionData.attributes.options = [
        ...questionData.attributes.options,
        { body: '<p></p>', correctOption: false, explanation: '<p></p>' }
      ];
    }

    // Remove question options for new questions
    if (
      isNew &&
      questionData.attributes.options.length > numberOfQuestionOptions
    ) {
      questionData.attributes.options = questionData.attributes.options.slice(
        0,
        numberOfQuestionOptions
      );
    }

    // Warning: question has more options than set by the course
    if (
      !isNew &&
      questionData.attributes.options.length > numberOfQuestionOptions
    ) {
      notify.show(
        'This question has more options in the database than are allowed by the course.',
        'error'
      );
    }
  };

  const updateQuestionCourse = async courseId => {
    // Questions still have a required relationship with Product, so we must
    // update the Product AND Course relationship
    const course = (
      await API.course.find({
        id: courseId
      })
    ).data;

    const numberOfQuestionOptions =
      course.attributes.defaultQuestionOptionNumber;

    const updatedQuestion = _.clone(question);
    if (updatedQuestion.relationships.course.data) {
      updatedQuestion.relationships.course.data.id = course.id;
      updatedQuestion.relationships.product.data.id =
        course.relationships.product.data.id;
    } else {
      updatedQuestion.relationships.course = {
        data: {
          type: 'course',
          id: course.id
        }
      };
      updatedQuestion.relationships.product = {
        data: {
          type: 'product',
          id: course.relationships.product.data.id
        }
      };
    }

    checkQuestionOptions(updatedQuestion, numberOfQuestionOptions);

    setQuestion(updatedQuestion);
  };

  const createImageBinding = async (questionId, imageId) => {
    return (
      await API.questionImageBinding.create({
        attributes: {},
        relationships: {
          question: {
            data: {
              type: 'question',
              id: questionId
            }
          },
          image: {
            data: {
              type: 'image',
              id: imageId
            }
          }
        }
      })
    ).data;
  };

  const updateQuestionImage = async (image, index) => {
    // No images yet, add the new image
    if (questionImages.length === 0 && image && index === 0) {
      const binding = await createImageBinding(question.id, image.id);
      setQuestionImages([image]);
      setQuestionImageBindings([binding]);
    }

    // No images yet, add the new image on the right
    else if (questionImages.length === 0 && image && index === 1) {
      const binding = await createImageBinding(question.id, image.id);
      setQuestionImages([null, image]);
      setQuestionImageBindings([null, binding]);
    }

    // Only one image, add the new image to image 2
    else if (questionImages.length === 1 && image) {
      const binding = await createImageBinding(question.id, image.id);
      const images = [...questionImages];
      images[1] = image;
      const bindings = [...questionImageBindings];
      bindings[1] = binding;
      setQuestionImages(images);
      setQuestionImageBindings(bindings);
    }

    // Remove image 1
    else if (!image && index === 0) {
      await API.questionImageBinding.delete({
        id: questionImageBindings[0].id
      });
      if (questionImages.length === 1) {
        setQuestionImages([]);
        setQuestionImageBindings([]);
      } else if (questionImages.length === 2) {
        const images = [...questionImages];
        images[0] = null;
        const bindings = [...questionImageBindings];
        bindings[0] = null;
        setQuestionImages(images);
        setQuestionImageBindings(bindings);
      }
    }

    // Remove image 2
    else if (!image && index === 1 && questionImages.length === 2) {
      await API.questionImageBinding.delete({
        id: questionImageBindings[1].id
      });
      const images = [questionImages[0]];
      const bindings = [questionImageBindings[0]];
      setQuestionImages(images);
      setQuestionImageBindings(bindings);
    }

    // Replace image 1
    else if (image && index === 0 && questionImages.length === 2) {
      const binding = await createImageBinding(question.id, image.id);
      const images = [...questionImages];
      images[0] = image;
      const bindings = [...questionImageBindings];
      bindings[0] = binding;
      setQuestionImages(images);
      setQuestionImageBindings(bindings);
    }
  };

  const updateQuestionProductLink = async productLinkId => {
    // Delete existing product link binding, if it exists, as we are currently
    // only allowing one per question
    if (questionProductLinkBindingId) {
      await API.questionProductLinkBinding.delete({
        id: questionProductLinkBindingId
      });
    }

    // Create new product link binding
    const questionProductLinkBinding = await createQuestionProductLinkBinding(
      question.id,
      productLinkId
    );

    setQuestionProductLinkBindingId(questionProductLinkBinding.data.id);
    setQuestionProductLinkId(productLinkId);
  };

  const updateQuestionAttachment = async questionId => {
    if (
      (questionAttachment.title.length > 0 &&
        questionAttachment.key.length === 0) ||
      (questionAttachment.key.length > 0 &&
        questionAttachment.title.length === 0)
    ) {
      notify.show(
        'Both a title and a url must be included for an external reference to be saved.',
        'error'
      );
    } else if (
      questionAttachment.title.length > 0 &&
      questionAttachment.key.length > 0
    ) {
      if (questionAttachmentBindingId) {
        // Find existing attachment
        const questionAttachmentBinding = await API.questionAttachmentBinding.find(
          {
            id: questionAttachmentBindingId,
            options: {
              include: 'attachment'
            }
          }
        );
        const attachment = questionAttachmentBinding.included[0];

        // Add form values
        const attributes = { ...attachment.attributes, ...questionAttachment };

        // Remove extra values that don't validate
        delete attributes.url;
        delete attributes.expiredAt;

        // Update the attachment
        await API.attachment.update({
          id: attachment.id,
          attributes
        });
      } else {
        // Create new attachment
        const attachment = await API.attachment.create({
          attributes: {
            key: questionAttachment.key,
            title: questionAttachment.title,
            fileName: questionAttachment.key,
            mimeType: 'text/x-uri',
            fileSize: 0,
            activityTimeInSeconds: 0,
            isContentSummary: false
          },
          relationships: {}
        });

        // Create new question attachment binding
        const questionAttachmentBinding = await createQuestionAttachmentBinding(
          questionId,
          attachment.data.id
        );

        setQuestionAttachmentBindingId(questionAttachmentBinding.data.id);
      }
    }
  };

  const addRelationshipsToQuestion = () => {
    const relationships = {};

    if (question.relationships.course?.data?.id) {
      relationships.course = {
        data: question.relationships.course.data
      };
    }
    if (question.relationships.product?.data?.id) {
      relationships.product = {
        data: question.relationships.product.data
      };
    }
    return relationships;
  };

  const addQuestionBindings = async editedQuestionId => {
    await Promise.all(
      questionImageBindings.map(imageBinding =>
        createImageBinding(
          editedQuestionId,
          imageBinding.relationships.image.data.id
        )
      )
    );
    if (questionProductLinkId) {
      await createQuestionProductLinkBinding(
        editedQuestionId,
        questionProductLinkId
      );
    }

    if (questionAttachmentBindingId) {
      await createQuestionAttachmentBinding(
        editedQuestionId,
        questionAttachment.id
      );
    }
  };

  const createQuestionAttachmentBinding = async (questionId, attachmentId) => {
    return await API.questionAttachmentBinding.create({
      attributes: {},
      relationships: {
        question: {
          data: {
            type: 'question',
            id: questionId
          }
        },
        attachment: {
          data: {
            type: 'attachment',
            id: attachmentId
          }
        }
      }
    });
  };

  const createQuestionProductLinkBinding = async (
    questionId,
    productLinkId
  ) => {
    return await API.questionProductLinkBinding.create({
      attributes: {
        type: 'Explanation'
      },
      relationships: {
        question: {
          data: {
            type: 'question',
            id: questionId
          }
        },
        productLink: {
          data: {
            type: 'productLink',
            id: productLinkId
          }
        }
      }
    });
  };

  const createNewQuestion = async group => {
    const id = uuidv4();
    const relationships = addRelationshipsToQuestion();

    const newQuestion = await API.question.create({
      attributes: {
        id,
        explanation: question.attributes.explanation,
        body: question.attributes.body,
        options: question.attributes.options,
        inReview: question.attributes.inReview,
        group,
        createdAt: moment(),
        updatedAt: moment(),
        version: 1,
        correctAnswerIndex: question.attributes.correctAnswerIndex,
        isMostRecentVersion: true
      },
      relationships
    });

    return newQuestion;
  };

  const saveAsMajorEdit = async () => {
    const originalQuestionId = question.id;
    const newQuestion = await createNewQuestion(originalQuestionId);
    const editedQuestionId = newQuestion.data.id;
    await addQuestionBindings(editedQuestionId);
    const query = JSON.stringify({
      query: `
        mutation {
            saveAsMajorEdit(input: {
              originalQuestionId: ${JSON.stringify(originalQuestionId)},
              editedQuestionId: ${JSON.stringify(editedQuestionId)},
        })
          {
            assessment {
              id
            }
          }
        }
      `
    });
    try {
      await fetch(`${process.env.REACT_APP_JSONAPI_SERVER}/graphql`, {
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getTokenFromCookie()}`
        },
        method: 'POST',
        body: query
      });
      setShowSuccessAlert(true);
    } catch (error) {
      if (error.data) {
        notify.show(error.data.errors[0].detail[0].message, 'error');
      } else {
        throw error;
      }
    }
    history.push('/content/questions/');
  };

  const updateQuestion = async () => {
    try {
      const updatedQuestion = _.clone(question);
      updatedQuestion.attributes.options = getUpdatedCorrectAnswerIndex(
        question.attributes.options
      );
      updatedQuestion.attributes.correctAnswerIndex = correctAnswerIndex;
      const response = (
        await API.question.update({
          id: updatedQuestion.id,
          attributes: updatedQuestion.attributes,
          relationships: {
            course: updatedQuestion.relationships.course,
            product: updatedQuestion.relationships.product
          }
        })
      ).data;

      setQuestion(response);
      await updateQuestionAttachment(question.id);
      setShowSuccessAlert(true);
      if (onSave) {
        onSave(response.id);
      }
    } catch (error) {
      if (error.data) {
        notify.show(error.data.errors[0].detail[0].message, 'error');
      } else {
        throw error;
      }
    }
  };

  return (
    <>
      {!question || !questionFinishedLoading ? (
        <Spin />
      ) : (
        <div className="question-view">
          {questionCourseIds.length > 0 && !loadingQuestionCourseIds ? (
            <CourseSelector
              onCourseSelected={value => updateQuestionCourse(value)}
              courseIdList={questionCourseIds}
              defaultValue={question.relationships.course.data?.id || null}
            />
          ) : (
            <Spin />
          )}
          <div className="question-view__question-id">
            <Label>Question Id: {question.id}</Label>
          </div>
          <div className="question-view__question-id">
            <Label>
              Last Updated:&nbsp;
              {moment(question.attributes.updatedAt).format('MMMM Do, YYYY')}
            </Label>
          </div>
          <div className="question-view__main-content">
            <div className="question-view__left-content">
              <Label>Question Stem</Label>
              <RichTextEditor
                className="question-view__question-body"
                defaultValue={question.attributes.body}
                onChange={value => updateQuestionAttribute('body', value)}
              />
              <div className="question-view__question-images">
                <Label>Question Images</Label>
                <div className="question-view__question-images-image-entries">
                  <SingleImageInput
                    name="image1"
                    value={questionImages.length > 0 ? questionImages[0] : null}
                    onChange={image => {
                      updateQuestionImage(image, 0);
                    }}
                    path="assessments/images"
                  />
                  <SingleImageInput
                    name="image2"
                    value={questionImages.length > 1 ? questionImages[1] : null}
                    onChange={image => {
                      updateQuestionImage(image, 1);
                    }}
                    path="assessments/images"
                  />
                </div>
                <div className="question-view__question-images-warning">
                  2 image limit (.jpg, .png, .gif)
                </div>
              </div>
              <div className="question-view__question-options">
                <Radio.Group
                  onChange={value => setCorrectAnswerIndex(value.target.value)}
                  defaultValue={correctAnswerIndex}
                >
                  {question.attributes.options.map((option, index) => (
                    <div key={index}>
                      <div key={`option-${index}`}>Option</div>
                      <QuestionOption
                        option={option}
                        index={index}
                        updateOption={value =>
                          updateQuestionOptions(value, index)
                        }
                        key={index}
                      />
                    </div>
                  ))}
                </Radio.Group>
              </div>
              <ProductLinkSelector
                onSave={value => updateQuestionProductLink(value)}
                productLinkId={questionProductLinkId}
              />

              <div className="question-view__external-reference">
                {questionAttachment && (
                  <Form
                    onValuesChange={value =>
                      setQuestionAttachment({ ...questionAttachment, ...value })
                    }
                    initialValues={
                      questionAttachment
                        ? questionAttachment
                        : { title: '', key: '' }
                    }
                  >
                    <Label>Display Text for External Link</Label>
                    <Form.Item name="title">
                      <Input placeholder="Link display text" />
                    </Form.Item>
                    <Form.Item name="key">
                      <Input placeholder="Link to external reference" />
                    </Form.Item>
                    <div className="question-view__external-reference-disclaimer">
                      UC LLSA PubMed link required if applicable
                    </div>
                  </Form>
                )}
              </div>

              <div className="question-view__save-buttons">
                <Tooltip
                  placement="top"
                  title="For major changes to a question that will result in a new question being created. Users that have already answered this question will continue to see old versions of the question."
                >
                  {!isNew && (
                    <Button disabled={isNew} onClick={() => saveAsMajorEdit()}>
                      Create New Question
                    </Button>
                  )}
                </Tooltip>
                <Tooltip
                  placement="top"
                  title="For small changes like typos or first saves of a question. Will not generate a new question."
                >
                  <Button
                    onClick={() => updateQuestion()}
                    className="question-view__minor-edit-save"
                  >
                    {isNew ? 'Save Question' : 'Update Existing Question'}
                  </Button>
                </Tooltip>
              </div>
            </div>
            <div className="question-view__right-content">
              <div>
                <Switch
                  defaultChecked={!question.attributes.inReview}
                  onChange={value =>
                    updateQuestionAttribute('inReview', !value)
                  }
                />
                <Label className="question-view__live-indicator-label">
                  Live
                </Label>
              </div>
              <TagSelector
                primaryItemId={question.id}
                primaryResourceType="question"
              />
            </div>
          </div>
          {showSuccessAlert && (
            <Alert
              message="Question successfully saved"
              className="question-view__success-alert"
              type="success"
              closable={true}
              onClose={() => setShowSuccessAlert(false)}
            />
          )}
        </div>
      )}
    </>
  );
}
