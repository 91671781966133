import { gql } from '@apollo/client';

export const GET_ATTACHMENTS_FOR_COURSE = gql`
  query GetAttachmentsAndSectionsForCourse($courseId: UUID!) {
    courseAttachmentSectionBindingsList(condition: { courseId: $courseId }) {
      attachmentSection {
        id
        title
        displayOrder
      }
    }
    courseAttachmentBindingsList(condition: { courseId: $courseId }) {
      attachment {
        id
        title
        fileName
        attachmentSectionId
        positionWithinSection
        isUpdatedSpotlightEnabled
        hasAccountCmeAttempts
        courseAttachmentBindingsList(condition: { courseId: $courseId }) {
          id
          activatedAt
          expiredAt
          attachmentId
        }
      }
    }
  }
`;
