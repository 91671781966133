import React from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Empty, Input, Table } from 'antd';

import { withRouter } from 'react-router-dom';

import * as API from '../../API';
import PaginatedList from '../../components/PaginatedList/PaginatedList';
import ListHeaderMenu from '../../components/PaginatedList/ListHeaderMenu';

import './FacultyList.css';

const { Column } = Table;

class FacultyList extends React.Component {
  state = {
    editingFacultyMember: null,
    error: null,
    facultySearch: ''
  };

  createFaculty = async () => {
    try {
      const response = await API.faculty.create({
        attributes: {
          fullName: 'Full Name',
          title: [],
          imageIconName: 'default',
          isArchived: false,
          hasNoDisclosures: false
        }
      });

      this.setState({ editingFacultyMember: response.data });
    } catch (error) {
      this.setState({ error });
    }
  };

  getResourceFilter = () => ({
    isArchived: false
  });

  getResourceOptions = () => ({
    sort: '-createdAt',
    include: 'account'
  });

  stopEditing = () => this.setState({ editingFacultyMember: null });

  archiveFaculty = async faculty => {
    try {
      const msg = `Are you sure you want to archive faculty member ${faculty.fullName}?`;

      if (window.confirm(msg)) {
        await API.faculty.update({
          id: faculty.id,
          attributes: { isArchived: true }
        });
      }
    } catch (error) {
      this.setState({ error });
    }
  };

  render() {
    if (this.state.error !== null) {
      throw this.state.error;
    }

    // TODO: sorting & filtering belong to a different task and will be
    // updated in a subsequent PR
    const sortOptions = {
      fullName: 'Name A-Z',
      '-fullName': 'Name Z-A'
    };
    const filterOptions = {
      false: 'Unarchived',
      true: 'Archived'
    };

    return (
      <PaginatedList
        resource={API.faculty}
        defaultOptions={this.getResourceOptions()}
        defaultFilter={this.getResourceFilter()}
        onLoadRows={resource => API.simplifyResource(resource)}
        pageLimit={20}
        renderHeader={(actions, refresh) => (
          <div className="faculty-header">
            <Button
              className="faculty-header__new"
              icon={<PlusOutlined />}
              type="primary"
              onClick={this.createFaculty}
            >
              New Faculty Member
            </Button>
            <Input
              placeholder="Search faculty by name/title/specialty/institution"
              value={this.state.facultySearch}
              onChange={e => {
                this.setState({ facultySearch: e.target.value });
                actions.setOption('_adminSearch', e.target.value);
              }}
              onPressEnter={e => {
                this.setState({ facultySearch: e.target.value });
                actions.setOption('_adminSearch', e.target.value);
              }}
            />
            <ListHeaderMenu
              className="faculty-header__filter"
              buttonPrefix="Filter: "
              options={filterOptions}
              defaultValue={this.getResourceFilter().isArchived}
              onChange={value => actions.setFilter('isArchived', value)}
            />
            <ListHeaderMenu
              className="faculty-header__sort"
              buttonPrefix="Sort: "
              options={sortOptions}
              defaultValue={this.getResourceOptions().sort}
              onChange={newValue => actions.setOption('sort', newValue)}
            />
          </div>
        )}
        renderList={(data, refresh) => (
          <Table
            className="faculty-table"
            dataSource={data}
            rowKey="id"
            pagination={false}
            onRow={record => ({
              onClick: () =>
                this.props.history.push(`/accounts/faculty/${record.id}`)
            })}
          >
            <Column
              title="Full Name"
              key="fullName"
              render={(value, faculty) => <span>{faculty.fullName}</span>}
            />
            <Column
              title="Email"
              key="email"
              render={(value, faculty) => (
                <span>
                  {faculty.account
                    ? faculty.account.email
                    : 'No account linked'}
                </span>
              )}
            />
            <Column
              title="Specialty"
              key="specialty"
              render={(value, faculty) =>
                faculty.specialty && <span>{faculty.specialty.join(', ')}</span>
              }
            />
            <Column
              title=""
              key="action"
              render={(value, faculty) => {
                return !faculty.isArchived ? (
                  <span>
                    <Button
                      type="danger"
                      icon={<DeleteOutlined />}
                      onClick={async e => {
                        e.stopPropagation();
                        await this.archiveFaculty(faculty);
                        refresh();
                      }}
                    />
                  </span>
                ) : null;
              }}
            />
          </Table>
        )}
        renderEmptyList={() => <Empty description="No faculty to show" />}
      />
    );
  }
}

export default withRouter(FacultyList);
