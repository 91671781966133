import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const getRowStyle = (isDragging, draggableStyle) => ({
  background: isDragging ? '#eee' : '#fff',
  boxShadow: isDragging ? 'box-shadow: 0 10px 6px -6px #777' : 'none',
  ...draggableStyle
});

const SortableList = ({
  items,
  itemType,
  renderItem,
  onDragEnd,
  className
}) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={itemType + 'Droppable'} type={itemType}>
        {(providedDrop, snapshotDrop) => (
          <div
            {...providedDrop.droppableProps}
            ref={providedDrop.innerRef}
            className={className}
          >
            {items &&
              items.map((child, index) => (
                <Draggable
                  key={child.id}
                  draggableId={child.id}
                  index={index}
                  type={itemType}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={className + '__item'}
                      style={getRowStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {renderItem(child, index)}
                    </div>
                  )}
                </Draggable>
              ))}
            {providedDrop.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default SortableList;
