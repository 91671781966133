import { gql } from '@apollo/client';

const ADD_REPLY = gql`
  mutation addReply(
    $id: UUID!
    $accountId: UUID!
    $chapterId: UUID
    $bodyText: String!
    $createdAt: Datetime!
    $updatedAt: Datetime!
    $parentId: UUID
    $lectureId: UUID
    $facultyAccountId: UUID
  ) {
    createComment(
      input: {
        comment: {
          bodyText: $bodyText
          createdAt: $createdAt
          updatedAt: $updatedAt
          accountId: $accountId
          status: PENDING
          parentId: $parentId
          chapterId: $chapterId
          lectureId: $lectureId
          id: $id
          facultyAccountId: $facultyAccountId
        }
      }
    ) {
      clientMutationId
    }
  }
`;

export default ADD_REPLY;
