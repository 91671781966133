import React from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Row
} from 'reactstrap';
import { Link } from 'react-router-dom';

import { Breadcrumb } from 'antd';

import { getTokenFromCookie } from '../utils/cookie';

export default class EditAdminNote extends React.Component {
  state = {
    promotion: null,
    successMessage: '',
    errorMessage: ''
  };

  componentDidMount() {
    fetch(
      `${process.env.REACT_APP_JSONAPI_SERVER}/api/promotion/${this.props.match.params.id}`,
      {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          Authorization: `Bearer ${getTokenFromCookie()}`
        }
      }
    )
      .then(response => {
        response
          .json()
          .then(promotion => this.setState({ promotion: promotion.promotion }));
      })
      .catch(window.setError);
  }

  updateField(key, value) {
    const promotion = this.state.promotion;
    promotion[key] = value;
    this.setState({ promotion });
  }

  handleSubmit() {
    fetch(
      `${process.env.REACT_APP_JSONAPI_SERVER}/api/promotion/${this.props.match.params.id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getTokenFromCookie()}`
        },
        method: 'POST',
        credentials: 'same-origin',
        body: JSON.stringify({ promotion: this.state.promotion })
      }
    )
      .then(response => {
        window.scrollTo(0, 0);

        if (response.status >= 400) {
          response.json().then(responseJson => {
            this.setState({ errorMessage: responseJson.message });
          });
        } else {
          response.json().then(responseJson => {
            this.setState({ successMessage: 'Successfully updated promotion' });
          });
        }

        setTimeout(() => {
          this.setState({ successMessage: '', errorMessage: '' });
        }, 2000);
      })
      .catch(err => window.setError(err));
  }

  render() {
    if (!this.state.promotion) {
      return <div />;
    }

    return (
      <div>
        <Row>
          <Col>
            <Breadcrumb style={{ margin: '20px 0' }}>
              <Breadcrumb.Item>
                <Link to="/dashboard">Hippo Admin</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/promotions">Promotions</Link>
              </Breadcrumb.Item>
              {this.state.promotion.title ? (
                <Breadcrumb.Item>
                  <Link to={`/promotion/${this.state.promotion.id}/view`}>
                    {window.asTitle(this.state.promotion.title)}
                  </Link>
                </Breadcrumb.Item>
              ) : null}
              <Breadcrumb.Item active="true">
                <Link to={window.location.pathname}>Note</Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        {this.state.successMessage ? (
          <Row>
            <Col>
              <Alert color="success">{this.state.successMessage}</Alert>
            </Col>
          </Row>
        ) : null}
        {this.state.errorMessage ? (
          <Row>
            <Col>
              <Alert color="danger">{this.state.errorMessage}</Alert>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col>
            <Form>
              <FormGroup>
                <Label>Public note</Label>
                <p>
                  <small>
                    Note is for administrative purposes and will not be
                    displayed to the customer.
                  </small>
                </p>
                <Input
                  type="textarea"
                  bsSize="sm"
                  value={this.state.promotion.note || ''}
                  onChange={e =>
                    this.updateField.bind(this)('note', e.target.value)
                  }
                />
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Button color="primary" onClick={this.handleSubmit.bind(this)}>
              Submit
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
