import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Table } from 'antd';
import ContentHeader from '../content/ContentHeader';
import { useQuery, gql } from '@apollo/client';
import { getDecodedTokenFromCookie } from '../../utils/cookie';
import { processData } from './CommentsProcessData';
import ActionButtonClickHandler from './CommentsHandleActionButtonClick';
import GET_COURSE_COMMENTS_QUERY from './commentsGQLQueries/CommentsGetCourseCommentsGQLQuery';
import BreadcrumbConstants from '../../routes/BreadcrumbConstants';
import CommentsModeratorSection from './CommentsModeratorSection';
import CommentsMainTable from '../../components/Comments/CommentsMainTable';
import './Comments.css';

export default function Comments() {
  const location = useLocation();
  const [courseId, setCourseId] = useState('');
  const [comments, setComments] = useState(null);
  const [courseLoaded, setCourseLoaded] = useState(false);
  const [courseTitle, setCourseTitle] = useState('');
  const [course, setCourse] = useState(null);
  const [expandRowKeys, setExpandRowKeys] = useState([]);
  const [rowKeysBeingRepliedTo, setRowKeysBeingRepliedTo] = useState([]);
  const [currentFaculty, setCurrentFaculty] = useState({});
  const currentUserAccount = getDecodedTokenFromCookie().account;

  const GET_FACULTY_QUERY = gql`
    query getFacultyFromAccount($accountId: UUID!) {
      facultiesList(filter: { accountId: { equalTo: $accountId } }) {
        fullName
        accountId
        id
      }
    }
  `;
  const { data: facultyData } = useQuery(GET_FACULTY_QUERY, {
    variables: { accountId: currentUserAccount }
  });

  useEffect(() => {
    if (facultyData && facultyData.facultiesList.length > 0) {
      setCurrentFaculty(facultyData.facultiesList[0]);
    }
  }, [facultyData]);

  const GET_FACULTY_COURSE_COMMENTAS_QUERY = gql`
    query getFacultyCourseCommentAs($facultyId: UUID!, $courseId: UUID!) {
      facultyCourseCommentAsBindingsList(
        filter: {
          facultyId: { equalTo: $facultyId }
          courseId: { equalTo: $courseId }
        }
      ) {
        course {
          id
          title
        }
        commentAsFaculty {
          id
          fullName
          account {
            id
            commentingAlias
          }
        }
        faculty {
          id
          fullName
        }
        id
      }
    }
  `;

  const [commentAsList, setCommentAsList] = useState();
  const facultyCourseCommentAsBindings = useQuery(
    GET_FACULTY_COURSE_COMMENTAS_QUERY,
    {
      variables: {
        facultyId: currentFaculty?.id,
        courseId
      },
      skip: !currentFaculty // This will skip the query if currentFaculty is not set
    }
  );
  useEffect(() => {
    if (
      currentFaculty &&
      facultyCourseCommentAsBindings.data &&
      facultyCourseCommentAsBindings.data.facultyCourseCommentAsBindingsList
    ) {
      setCommentAsList(
        facultyCourseCommentAsBindings.data.facultyCourseCommentAsBindingsList
      );
    }
  }, [currentFaculty, facultyCourseCommentAsBindings]);
  if (courseId !== location.pathname.replace('/comments/', '')) {
    setComments(null);
    setCourseId(location.pathname.replace('/comments/', ''));
    setCourseLoaded(false);
  }

  const { data, refetch } = useQuery(GET_COURSE_COMMENTS_QUERY, {
    variables: { courseIdForQuery: courseId }
  });

  const refresh = async () => {
    await refetch({ $courseId: courseId });
    setCourseLoaded(false);
  };

  const externalProcessesData = useCallback(
    async (
      courseId,
      courseLoaded,
      data,
      rowKeysBeingRepliedTo,
      currentUserAccount,
      faculty
    ) => {
      const results = await processData(
        courseId,
        courseLoaded,
        data,
        rowKeysBeingRepliedTo,
        currentUserAccount,
        faculty
      );
      if (results) {
        setComments(results.mappedResults);
        setCourseLoaded(true);
        setExpandRowKeys(results.commentKeys);
        setCourseTitle(data.course.title);
        setCourse(results.courseResult);
        setCurrentFaculty(results.currentFaculty);
      }
    },
    []
  );

  useEffect(() => {
    if (data && !courseLoaded) {
      externalProcessesData(
        courseId,
        courseLoaded,
        data,
        rowKeysBeingRepliedTo,
        currentUserAccount,
        currentFaculty
      );
    }
  }, [
    courseId,
    courseLoaded,
    currentUserAccount,
    data,
    comments,
    location,
    course,
    rowKeysBeingRepliedTo,
    externalProcessesData,
    currentFaculty
  ]);

  const commentsBreadcrumb = [
    BreadcrumbConstants.HippoAdmin,
    { title: 'Comments' },
    { title: courseTitle }
  ];

  const handleActionButtonClick = async (comment, action) => {
    const refreshOptions = await ActionButtonClickHandler(
      comment,
      action,
      rowKeysBeingRepliedTo
    );
    if (refreshOptions.newRowKeys) {
      setRowKeysBeingRepliedTo(refreshOptions.newRowKeys);
    }
    if (refreshOptions.softRefresh) {
      setComments(JSON.parse(JSON.stringify(comments))); // deep copy comments to force a re-render without having to reprocess all data
    }
    if (refreshOptions.hardRefresh) {
      await refresh();
    }
  };

  return (
    <>
      <ContentHeader
        breadCrumb={commentsBreadcrumb}
        hasTitle={true}
        title={courseTitle}
      />
      {course && (
        <CommentsModeratorSection
          refresh={refresh}
          course={course}
          courseId={courseId}
        />
      )}
      <div className="comments">
        {comments ? (
          <CommentsMainTable
            comments={comments}
            expandRowKeys={expandRowKeys}
            handleActionButtonClick={handleActionButtonClick}
            currentUserAccount={currentUserAccount}
            currentFacultyAccount={currentFaculty}
            currentCourseId={courseId}
            commentAsList={commentAsList}
          />
        ) : (
          <Table loading={true} dataSource={[]} key="loading-not-done" />
        )}
      </div>
    </>
  );
}
