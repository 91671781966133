import React, { useState } from 'react';
import { Card } from 'antd';

import { AddNote } from './AddNote';
import { ListNotes } from './ListNotes';

import './Notes.css';

const Notes = ({ title, bindingType, bindingTypeId }) => {
  const [fetchNotes, setFetchNotes] = useState(true);
  return (
    <div className="notes__content">
      <Card>
        <p className="notes__title">{title}</p>
        <AddNote
          type={bindingType}
          id={bindingTypeId}
          setFetchNotes={setFetchNotes}
        />
        <ListNotes
          bindingType={bindingType}
          bindingTypeId={bindingTypeId}
          fetchNotes={fetchNotes}
          setFetchNotes={setFetchNotes}
        />
      </Card>
    </div>
  );
};

export default Notes;
