import React from 'react';
import { Route } from 'react-router-dom';

import { ApolloConsumer } from '@apollo/client';

import Lecture from '../containers/lecture/Lecture';

const LectureRoutes = () => {
  return (
    <>
      <Route
        exact={true}
        path="/lecture/:id"
        render={routeProps => {
          return (
            <ApolloConsumer>
              {client => <Lecture client={client} {...routeProps} />}
            </ApolloConsumer>
          );
        }}
      />
    </>
  );
};

export default LectureRoutes;
