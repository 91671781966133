import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  message,
  Modal,
  notification,
  Upload,
  Select,
  Spin
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { Label } from 'reactstrap';
import Papa from 'papaparse';

import * as API from '../../API';
import { getTokenFromCookie } from '../../utils/cookie';
import { useGetDisplayableCourseIdsByCourseType } from '../courseTypes/hooks/useGetDisplayableCourseIdsByCourseType';
import { CourseTypeTitles } from '../../constants';

import './UploadCSVQuestionsModal.css';

const { Dragger } = Upload;
const UPLOAD_QUESTION_LIMIT = 10;

export default function UploadCSVQuestionsModal() {
  const [showModal, setShowModal] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [courses, setCourses] = useState(null);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [selectedAssessmentId, setSelectedAssessmentId] = useState(null);
  const [assessments, setAssessments] = useState(null);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const assessmentCourseTypeTitlesToDisplay = [
    CourseTypeTitles.BOOTCAMP_ASSESSMENT,
    CourseTypeTitles.ASSESSMENT
  ];
  const {
    courseIds: assessmentCourseIds,
    loading: assessmentCourseIdsLoading
  } = useGetDisplayableCourseIdsByCourseType(
    assessmentCourseTypeTitlesToDisplay
  );

  const questionCourseTypeTitlesToDisplay = [
    CourseTypeTitles.BOOTCAMP_ASSESSMENT,
    CourseTypeTitles.ASSESSMENT,
    CourseTypeTitles.PRACTICE_EXAM,
    CourseTypeTitles.QBANK
  ];
  const {
    courseIds: questionCourseIds,
    loading: questionCourseIdsLoading
  } = useGetDisplayableCourseIdsByCourseType(questionCourseTypeTitlesToDisplay);

  useEffect(() => {
    if (!courses && !questionCourseIdsLoading && questionCourseIds.length > 0) {
      async function getCourses() {
        const availableCourses = await API.simplifyResource(
          await API.course.where({
            filter: {
              id: questionCourseIds
            }
          })
        );
        setCourses(availableCourses);
      }
      getCourses();
    }
  }, [courses, questionCourseIds, questionCourseIdsLoading]);

  useEffect(() => {
    if (
      !assessments &&
      !assessmentCourseIdsLoading &&
      assessmentCourseIds.length > 0
    ) {
      async function getAssessments() {
        const availableAssessments = await API.simplifyResource(
          await API.assessment.where({
            filter: {
              course: assessmentCourseIds
            }
          })
        );
        setAssessments(availableAssessments);
      }
      getAssessments();
    }
  }, [assessments, assessmentCourseIds, assessmentCourseIdsLoading]);

  const createQuestions = async file => {
    setLoading(true);

    Papa.parse(file.originFileObj, {
      header: true,
      complete: async results => {
        if (results) {
          if (results.data.length > UPLOAD_QUESTION_LIMIT) {
            const questions = [];
            for (
              let i = 0;
              i < results.data.length;
              i += UPLOAD_QUESTION_LIMIT
            ) {
              const chunk = results.data.slice(i, i + UPLOAD_QUESTION_LIMIT);
              questions.push(chunk);
              // do whatever
            }
            for (const [index, chunk] of questions.entries()) {
              const response = await fetch(
                `${process.env.REACT_APP_JSONAPI_SERVER}/api/upload/questions`,
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${getTokenFromCookie()}`
                  },
                  credentials: 'same-origin',
                  method: 'POST',
                  body: JSON.stringify({
                    questions: chunk,
                    courseId: selectedCourseId,
                    assessmentId: selectedAssessmentId
                      ? selectedAssessmentId
                      : undefined
                  })
                }
              );
              if (response.status !== 200) {
                const errorMessage = await response.json();
                setLoading(false);
                showMessage(
                  'error',
                  'Upload failure',
                  `The CSV did not upload. Reverting all changes.

                   ${
                     errorMessage
                       ? `Detailed Error Msg: ${JSON.stringify(
                           errorMessage,
                           null,
                           3
                         )}`
                       : 'Please try again.'
                   }`,
                  0
                );
                break;
              } else {
                if (index === questions.length - 1) {
                  setLoading(false);
                  showMessage(
                    'success',
                    'Upload complete',
                    'Successfully uploaded questions',
                    0
                  );
                }
              }
            }
          } else {
            const response = await fetch(
              `${process.env.REACT_APP_JSONAPI_SERVER}/api/upload/questions`,
              {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${getTokenFromCookie()}`
                },
                credentials: 'same-origin',
                method: 'POST',
                body: JSON.stringify({
                  questions: results.data,
                  courseId: selectedCourseId,
                  assessmentId: selectedAssessmentId
                    ? selectedAssessmentId
                    : undefined
                })
              }
            );
            if (response.status !== 200) {
              const errorMessage = await response.json();
              showMessage(
                'error',
                'Upload failure',
                `The CSV did not upload. Reverting all changes.

                   ${
                     errorMessage
                       ? `Detailed Error Msg: ${JSON.stringify(
                           errorMessage,
                           null,
                           3
                         )}`
                       : 'Please try again.'
                   }`,
                0
              );
            } else {
              showMessage(
                'success',
                'Upload complete',
                'Successfully uploaded questions',
                0
              );
            }
            setLoading(false);
          }
        } else {
          showMessage(
            'error',
            'Upload failure',
            'Your CSV does not contain any values.'
          );
        }
      }
    });
  };

  const showMessage = (type, message, description, duration = 4.5) => {
    notification[type]({
      message,
      description,
      btn: (
        <Button type="primary" size="small" onClick={() => history.go(0)}>
          Refresh
        </Button>
      ),
      onClose: () => history.go(0),
      duration
    });
  };

  return (
    <>
      <Modal
        title="Upload CSV"
        visible={showModal}
        okText={
          loading ? (
            <div className="">
              <Spin />
            </div>
          ) : (
            'Upload'
          )
        }
        onOk={async () => {
          if (fileList.length === 1) {
            await createQuestions(fileList[0]);
          }
        }}
        okButtonProps={{
          disabled: !selectedCourseId || fileList.length === 0 || loading
        }}
        cancelText="Close Modal (upload continues in background)"
        onCancel={() => setShowModal(false)}
      >
        <div className="csv-question-upload__selectors">
          <div className="csv-question-upload__courses">
            <Label>Select Course (required)</Label>
            <Select
              className="csv-question-upload__course-selector"
              onChange={setSelectedCourseId}
            >
              {courses &&
                courses.map(course => (
                  <Select.Option key={course.id}>{course.title}</Select.Option>
                ))}
            </Select>
          </div>

          <div className="csv-question-upload__assessments">
            <Label>Select Assessment (optional)</Label>
            <Select
              className="csv-question-upload__assessment-selector"
              onChange={setSelectedAssessmentId}
            >
              {assessments &&
                assessments.map(assessment => (
                  <Select.Option key={assessment.id}>
                    {assessment.title}
                  </Select.Option>
                ))}
            </Select>
          </div>
        </div>

        <Dragger
          fileList={fileList}
          beforeUpload={file => {
            if (file.type !== 'text/csv') {
              message.error(`${file.name} is not a CSV file`);
            }
            return false;
          }}
          onChange={info => {
            setFileList(info.fileList);
          }}
          multiple={false}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">Uploads a CSV file with questions</p>
        </Dragger>
      </Modal>
      <Button
        className="csv-question-upload__button"
        onClick={() => setShowModal(true)}
      >
        Upload Question CSV
      </Button>
    </>
  );
}
