import React, { useState } from 'react';
import { Button, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import * as API from '../../API';
import QuestionSelectModal from '../content/questions/QuestionSelectModal';
import QuestionAddModal from '../content/questions/QuestionAddModal';
import config from '../../config';

import './AssessmentsView.css';

export default function QuestionsTableHeader({ assessment }) {
  const [modalVisible, setModalVisible] = useState(null);
  const [newQuestionModalVisible, setNewQuestionModalVisible] = useState(false);
  const [questionId, setQuestionId] = useState(null);

  const addNewQuestion = async () => {
    const id = uuidv4();
    const question = await API.question.create({
      attributes: {
        id,
        body: '<p>New Question Stem</p>',
        options: [
          { body: '', explanation: '', correctOption: true },
          { body: '', explanation: '', correctOption: false },
          { body: '', explanation: '', correctOption: false },
          { body: '', explanation: '', correctOption: false }
        ],
        inReview: true,
        group: id,
        createdAt: moment(),
        updatedAt: moment(),
        version: 1,
        correctAnswerIndex: 0,
        isMostRecentVersion: true
      },
      relationships: {
        course: {
          data: {
            type: 'course',
            id: config.defaults.defaultQuestionCourseId
          }
        },
        product: {
          data: {
            type: 'product',
            id: config.defaults.defaultQuestionProductId
          }
        }
      }
    });
    setQuestionId(question.data.id);
  };

  return (
    <>
      <Space>
        <Button
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => {
            setModalVisible(true);
          }}
        >
          Add Existing Question
        </Button>
        <Button
          icon={<PlusOutlined />}
          type="primary"
          onClick={async () => {
            await addNewQuestion();
            setNewQuestionModalVisible(true);
          }}
        >
          Create a New Question
        </Button>
      </Space>
      <QuestionSelectModal
        assessment={assessment}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
      <QuestionAddModal
        assessment={assessment}
        modalVisible={newQuestionModalVisible}
        setModalVisible={setNewQuestionModalVisible}
        questionId={questionId}
      />
    </>
  );
}
