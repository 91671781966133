import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Modal } from 'antd';

import QuestionAddEdit from './QuestionAddEdit';

import * as API from '../../../API';

export default function QuestionAddModal({
  assessment,
  modalVisible,
  setModalVisible,
  questionId
}) {
  const history = useHistory();

  const handleCancel = () => {
    setModalVisible(false);
  };

  const getNextDisplayOrder = displayOrders => {
    if (!displayOrders) {
      return 0;
    }
    const max = Math.max(...displayOrders);
    return max <= 0 ? 0 : max + 10;
  };

  const onSaveQuestion = async newQuestionId => {
    const nextDisplayOrder = getNextDisplayOrder(
      assessment.assessmentQuestionBindings?.map(
        binding => binding.displayOrder
      )
    );

    await API.assessmentQuestionBinding.create({
      attributes: {
        displayOrder: nextDisplayOrder,
        assessmentVersion: assessment.currentVersion
      },
      relationships: {
        assessment: {
          data: {
            type: 'assessment',
            id: assessment.id
          }
        },
        question: {
          data: {
            type: 'question',
            id: newQuestionId
          }
        }
      }
    });
    setModalVisible(false);
    history.go(0);
  };

  return (
    <Modal
      title="New Question"
      visible={modalVisible}
      width={1000}
      onCancel={handleCancel}
      footer={[
        <Button
          key="back"
          className="question-select_modal__cancel"
          onClick={handleCancel}
        >
          Cancel
        </Button>
      ]}
    >
      <QuestionAddEdit
        isNew={true}
        questionId={questionId}
        onSave={onSaveQuestion}
      />
    </Modal>
  );
}
