import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { Input, Button, Select, Row, Col, Card, DatePicker } from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';
import moment from 'moment';
import * as API from '../../API';

import '../associations/Associations.css';

const { Option } = Select;
const { TextArea } = Input;

export default function Associations({ itemId, itemTitle }) {
  const [associationBindingItems, setAssociationBindingItems] = useState([]);
  const [allAssociations, setAllAssociations] = useState([]);
  const [hasStaleData, setHasStaleData] = useState(true);

  useEffect(() => {
    const fetchAssociations = async () => {
      const fetchedAssociationItemBindings = await API.associationItemBinding.where(
        {
          filter: { item: itemId },
          options: {
            include: 'association,item'
          }
        }
      );

      setAssociationBindingItems(fetchedAssociationItemBindings.data);

      const associations = await API.association.all();

      setAllAssociations(associations.data);
    };

    if (hasStaleData) {
      fetchAssociations();
      setHasStaleData(false);
    }
  }, [
    hasStaleData,
    associationBindingItems,
    setAssociationBindingItems,
    itemId
  ]);

  const handleDeleteItemFromAssociation = async associationItemBindingId => {
    await API.associationItemBinding.delete({
      id: associationItemBindingId
    });

    setHasStaleData(true);
  };

  const handleUpdateAssociation = debounce(
    async (value, associationBindingId, field) => {
      await API.associationItemBinding.update({
        id: associationBindingId,
        attributes: {
          [field]: value
        }
      });
    }
  );

  const handleCreateNewAssociationFromItem = async () => {
    const newAssociation = await API.association.create({
      attributes: {
        title: 'New association',
        memberTitle: 'New association member title'
      }
    });

    await API.associationItemBinding.create({
      attributes: {
        title: 'New association binding title',
        description: 'New association binding description'
      },
      relationships: {
        item: {
          data: {
            type: 'item',
            id: itemId
          }
        },
        association: {
          data: {
            type: 'association',
            id: newAssociation.data.id
          }
        }
      }
    });

    setHasStaleData(true);
  };

  const handleUpdateAssociationRelationship = async (
    newAssociationId,
    bindingId
  ) => {
    await API.associationItemBinding.update({
      id: bindingId,
      relationships: {
        association: {
          data: {
            type: 'association',
            id: newAssociationId
          }
        }
      }
    });

    setHasStaleData(true);
  };

  return (
    <div className="association-item__container">
      <h2>Associations</h2>
      <Button
        className="association-item__create-button"
        type="primary"
        onClick={handleCreateNewAssociationFromItem}
      >
        + Create New Association With {itemTitle}
      </Button>
      {associationBindingItems.map(associationBinding => (
        <Card key={associationBinding.id} className="association-item__card">
          <DeleteTwoTone
            className="association-item__delete-icon"
            onClick={() =>
              handleDeleteItemFromAssociation(associationBinding.id)
            }
            twoToneColor="red"
            size="large"
          />
          <Row className="association-item" gutter={24}>
            <Col justify="space-between">
              <div className="association-item__title-container">
                <div className="association-item__title">Title:</div>
                <Input
                  defaultValue={associationBinding.attributes.title}
                  onChange={event =>
                    handleUpdateAssociation(
                      event.target.value,
                      associationBinding.id,
                      'title'
                    )
                  }
                />
              </div>
            </Col>
            <Col>
              <Select
                placeholder="Association"
                onChange={association => {
                  return handleUpdateAssociationRelationship(
                    association,
                    associationBinding.id
                  );
                }}
                defaultValue={
                  associationBinding.relationships.association.data.id
                }
              >
                {allAssociations &&
                  allAssociations.map(association => (
                    <Option key={association.id} value={association.id}>
                      {association.attributes.title}{' '}
                      <small>({association.attributes.memberTitle})</small>
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col>
              <div className="association-item__date-container">
                <div className="association-item__title-container">
                  <div className="association-item__title">Starts:</div>
                  <DatePicker
                    defaultValue={
                      associationBinding.attributes.startedAt !== null
                        ? moment(associationBinding.attributes.startedAt)
                        : null
                    }
                    onChange={date =>
                      handleUpdateAssociation(
                        date,
                        associationBinding.id,
                        'startedAt'
                      )
                    }
                  />
                </div>
                <div className="association-item__date-optional">Optional</div>
              </div>
            </Col>
            <Col>
              <div className="association-item__date-container">
                <div className="association-item__title-container">
                  <div className="association-item__title">Ends:</div>
                  <DatePicker
                    defaultValue={
                      associationBinding.attributes.endedAt !== null
                        ? moment(associationBinding.attributes.endedAt)
                        : null
                    }
                    onChange={date =>
                      handleUpdateAssociation(
                        date,
                        associationBinding.id,
                        'endedAt'
                      )
                    }
                  />
                </div>
                <div className="association-item__title-container">
                  <div className="association-item__date-optional">
                    Optional
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Row>
              <Col>
                <div className="association-item__discount-title-container">
                  <div className="association-item__discount-title">
                    Discount Amount:
                  </div>
                  <Input
                    defaultValue={
                      associationBinding.attributes.discountAmount !== null
                        ? associationBinding.attributes.discountAmount
                        : null
                    }
                    onChange={event =>
                      handleUpdateAssociation(
                        event.target.value,
                        associationBinding.id,
                        'discountAmount'
                      )
                    }
                  />
                </div>
              </Col>
              <Col>
                <div className="association-item__discount-title-container">
                  <div className="association-item__discount-title">
                    Discount Percentage:
                  </div>
                  <Input
                    defaultValue={
                      associationBinding.attributes.discountPercentage !== null
                        ? associationBinding.attributes.discountPercentage * 100
                        : null
                    }
                    onChange={event =>
                      handleUpdateAssociation(
                        event.target.value !== 0
                          ? event.target.value / 100
                          : null,
                        associationBinding.id,
                        'discountPercentage'
                      )
                    }
                  />
                </div>
              </Col>
            </Row>
            <Row className="association-item__discount-subtext">
              <Col>
                <div>
                  *Only discount amount OR discount percentage will apply
                </div>
              </Col>
            </Row>
          </Row>
          <Row>
            <Col className="association_description-column">
              <TextArea
                defaultValue={associationBinding.attributes.description}
                onChange={event =>
                  handleUpdateAssociation(
                    event.target.value,
                    associationBinding.id,
                    'description'
                  )
                }
              />
            </Col>
          </Row>
        </Card>
      ))}
    </div>
  );
}
