import { gql } from '@apollo/client';

const CREATE_SUGGESTED_ITEM_MUTATION = gql`
  mutation CreateSuggestedItem(
    $id: UUID!
    $itemId: UUID!
    $itemSuggestedId: UUID!
    $displayOrder: Int!
    $createdAt: Datetime!
    $updatedAt: Datetime!
  ) {
    createSuggestedItem(
      input: {
        suggestedItem: {
          id: $id
          itemId: $itemId
          itemSuggestedId: $itemSuggestedId
          displayOrder: $displayOrder
          createdAt: $createdAt
          updatedAt: $updatedAt
        }
      }
    ) {
      suggestedItem {
        displayOrder
        id
        itemSuggestedId
        item {
          id
          sku
          title
        }
      }
    }
  }
`;

const UPDATE_SUGGESTED_ITEM_MUTATION = gql`
  mutation UpdateSuggestedItem(
    $id: UUID!
    $displayOrder: Int!
    $updatedAt: Datetime!
  ) {
    updateSuggestedItem(
      input: {
        patch: { displayOrder: $displayOrder, updatedAt: $updatedAt }
        id: $id
      }
    ) {
      clientMutationId
    }
  }
`;

const DELETE_SUGGESTED_ITEM_MUTATION = gql`
  mutation DeleteSuggestedItem($id: UUID!) {
    deleteSuggestedItem(input: { id: $id }) {
      clientMutationId
    }
  }
`;

export {
  CREATE_SUGGESTED_ITEM_MUTATION,
  UPDATE_SUGGESTED_ITEM_MUTATION,
  DELETE_SUGGESTED_ITEM_MUTATION
};
