import React from 'react';
import {
  Alert,
  Button,
  Card,
  CardTitle,
  CardText,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import { Link } from 'react-router-dom';

import { Breadcrumb } from 'antd';

import { getTokenFromCookie } from '../utils/cookie';

export default class GenerateCodes extends React.Component {
  state = {
    promotion: null,
    numCodes: 5,
    codePrefix: '',
    isLimitedUse: false,
    showSuccess: false
  };

  componentDidMount() {
    fetch(
      `${process.env.REACT_APP_JSONAPI_SERVER}/api/promotion/${this.props.match.params.id}`,
      {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          Authorization: `Bearer ${getTokenFromCookie()}`
        }
      }
    )
      .then(response => {
        response
          .json()
          .then(promotion => this.setState({ promotion: promotion.promotion }));
      })
      .catch(window.setError);
  }

  addCodes() {
    if (!this.state.codePrefix) {
      return alert('You must provide a code.');
    }

    if (!this.state.numCodes) {
      return alert('You must say how many codes you want to create.');
    }

    const params = {
      PromotionId: this.props.match.params.id,
      isLimitedUse: this.state.isLimitedUse,
      num: this.state.numCodes,
      codePrefix: this.state.codePrefix
    };

    fetch(`${process.env.REACT_APP_JSONAPI_SERVER}/api/promotion-code`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromCookie()}`
      },
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify(params)
    })
      .then(response => {
        response.json().then(() => {
          this.setState({ showSuccess: true });

          setTimeout(() => {
            this.setState({ showSuccess: false });
          }, 1500);
        });
      })
      .catch(window.setError);
  }

  render() {
    if (!this.state.promotion) {
      return <div />;
    }

    return (
      <div>
        <Row>
          <Col>
            <Breadcrumb style={{ padding: '20px 0' }}>
              <Breadcrumb.Item>
                <Link to="/dashboard">Hippo Admin</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/promotions">Promotions</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/promotion/${this.state.promotion.id}/view`}>
                  {this.state.promotion.title}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active={true}>
                <Link
                  to={`/promotion/${this.state.promotion.id}/generatecodes`}
                >
                  Generate codes
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        {this.state.showSuccess ? (
          <Row>
            <Col>
              <Alert color="success">Successfully created codes</Alert>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col>
            <Card body={true} style={{ width: 400 }}>
              <CardTitle>Generate codes in bulk</CardTitle>
              <CardText>
                <Form>
                  <FormGroup>
                    <Label>Number of codes (required)</Label>
                    <Input
                      bsSize="sm"
                      type="code"
                      name="code"
                      value={this.state.numCodes}
                      onChange={e => {
                        const number = parseInt(e.target.value, 10) || 1;
                        this.setState({ numCodes: number });
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Code prefix (required)</Label>
                    <Input
                      bsSize="sm"
                      type="code"
                      name="code"
                      placeholder="New promotion code"
                      value={this.state.codePrefix}
                      onChange={e =>
                        this.setState({ codePrefix: e.target.value })
                      }
                    />
                    <p style={{ fontSize: 12, paddingTop: 12 }}>
                      All generated codes will be begin with this prefix
                      followed by a dash and then a random string. If left
                      blank, generated codes will be completely random.
                    </p>
                  </FormGroup>
                  <FormGroup check={true}>
                    <Label check={true}>
                      <Input
                        type="checkbox"
                        value={this.state.isLimitedUse}
                        onChange={e =>
                          this.setState({ isLimitedUse: e.target.checked })
                        }
                      />
                      One-time use
                    </Label>
                  </FormGroup>
                </Form>
              </CardText>
              <Button color="primary" onClick={this.addCodes.bind(this)}>
                Create
              </Button>
              <Link to={`/promotion/${this.state.promotion.id}/view`}>
                Go back
              </Link>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
