import { gql } from '@apollo/client';

const DELETE_ORGANIZATION_MUTATION = gql`
  mutation DeleteOrganization($id: UUID!) {
    deleteOrganization(input: { id: $id }) {
      clientMutationId
      deletedOrganizationNodeId
    }
  }
`;

export default DELETE_ORGANIZATION_MUTATION;
