import React from 'react';

import { Button, Layout, Menu } from 'antd';

import { redirectToSignIn } from '../utils/authentication';

const { Header, Footer } = Layout;

const HIPPO_LOGO = require('../images/hippo.png');
const logoStyles = {
  height: '31px',
  backgroundColor: 'rgba(255,255,255,.2)',
  margin: '16px 24px 16px 0',
  float: 'left'
};

const relativeContainer = {
  position: 'relative'
};

const absoluteContainer = {
  position: 'fixed',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'stretch',
  width: '100%',
  height: '100%'
};

export default class Login extends React.Component {
  state = {
    error: null,
    loading: false
  };

  onLogin(e) {
    e && e.preventDefault();

    if (!this.state.email) {
      return this.setState({
        error: new Error('You need to provide an email.')
      });
    }

    if (!validateEmail(this.state.email)) {
      return this.setState({
        error: new Error('Email field is improperly formatted.')
      });
    }
  }

  render() {
    return (
      <div style={relativeContainer}>
        <div style={absoluteContainer}>
          <Layout className="layout">
            <Header>
              <img src={HIPPO_LOGO} style={logoStyles} alt="Hippo Education" />
              <Menu
                theme="dark"
                mode="horizontal"
                defaultSelectedKeys={['2']}
                style={{ lineHeight: '64px' }}
              >
                <Menu.Item style={{ float: 'right' }}>
                  <Button onClick={redirectToSignIn}>Log in</Button>
                </Menu.Item>
              </Menu>
            </Header>

            <Footer style={{ textAlign: 'center' }}>
              ©{new Date().getFullYear()} Hippo Education
            </Footer>
          </Layout>
        </div>
      </div>
    );
  }
}

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
