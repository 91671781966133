import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Table } from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';
import BreadcrumbNavigation from '../../components/BreadcrumbNavigation/BreadcrumbNavigation';
import BreadcrumbConstants from '../../routes/BreadcrumbConstants';
import * as API from '../../API';
import moment from 'moment';

export default function Bundles() {
  const history = useHistory();
  const [allBundles, setAllBundles] = useState([]);

  const fetchBundles = async () => {
    const bundles = await API.fetchAllPages(
      API.bundle.all({ options: { sort: 'title' } })
    );
    setAllBundles(bundles);
  };

  useEffect(() => {
    fetchBundles();
  }, []);

  const handleDeleteBundle = async bundleId => {
    await API.bundle.delete({
      id: bundleId
    });

    fetchBundles();
  };

  const handleCreateNewBundle = async () => {
    const newBundle = await API.bundle.create({
      attributes: {
        title: 'New Bundle'
      }
    });

    return history.push(`/bundle/${newBundle.data.id}`);
  };

  return (
    <div>
      <BreadcrumbNavigation
        data={[BreadcrumbConstants.HippoAdmin, { title: 'Bundles' }]}
      />
      <hr />
      <Button color="primary" onClick={handleCreateNewBundle}>
        + Create New Bundle
      </Button>
      <Table
        className="bundle__table"
        pagination={{ defaultPageSize: 20, position: ['bottomLeft'] }}
        columns={[
          {
            title: 'Bundle Name',
            key: 'bundleName',
            render: item => (
              <Link to={`/bundle/${item.id}`}>{item.attributes.title}</Link>
            )
          },
          {
            title: 'Start Date',
            key: 'startedAt',
            render: item =>
              item.attributes.startedAt
                ? moment(item.attributes.startedAt).format('MM[/]DD[/]YY')
                : 'N/A'
          },
          {
            title: 'End Date',
            key: 'endedAt',
            render: item =>
              item.attributes.endedAt
                ? moment(item.attributes.endedAt).format('MM[/]DD[/]YY')
                : 'N/A'
          },
          {
            title: '# of Products',
            key: 'numberOfProducts',
            render: item => item.relationships.bundleItemBindings.data.length
          },
          {
            title: 'Delete?',
            key: 'delete',
            render: item => (
              <DeleteTwoTone
                twoToneColor="red"
                size="large"
                onClick={() => handleDeleteBundle(item.id)}
              />
            )
          }
        ]}
        dataSource={allBundles}
      />
    </div>
  );
}
