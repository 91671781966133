import React, { useState, useEffect } from 'react';
import { Button, Input, message } from 'antd';
import { useMutation } from '@apollo/client';
import UPDATE_SHORTNAME_ORGANIZATION_MUTATION from './queries/UpdateShortnameOrganizationMutation';
import { notify } from 'react-notify-toast';

export default function URLSection(props) {
  const [errorText, setErrorText] = useState('');
  const [initialized, setInitialized] = useState(false);
  const [submitUrlDisabled, setSubmitUrlDisabled] = useState(true);
  const [copyButtonDisabled, setCopyButtonDisabled] = useState(true);
  const [groupURL, setGroupURL] = useState('');

  const [editShortname] = useMutation(UPDATE_SHORTNAME_ORGANIZATION_MUTATION, {
    onCompleted: async (d, clientOptions) => {
      await props.refreshParent();
      setCopyButtonDisabled(false);
    }
  });

  const handleShortNameSubmit = async (e, url) => {
    e.preventDefault();
    await editShortname({
      variables: {
        id: props.id,
        shortname: url
      }
    });
    notify.show(`shortname "${url}" was saved successfully.`, 'success');
  };
  const handleCopyClick = (e, url) => {
    navigator.clipboard.writeText('hippoed.com/groupsignon/' + url);
    message.info('Copied hippoed.com/groupsignon/' + url + ' to clipboard');
    e.stopPropagation();
  };

  const handleShortNameChange = event => {
    setCopyButtonDisabled(true);
    const val = event.target.value;
    const validated = validateInput(val);
    setSubmitUrlDisabled(val.length === 0 || !validated);
    setGroupURL(val);
  };

  const validateInput = val => {
    const alphaNumericLimiter = /^([a-zA-Z0-9_-]+)$/;
    let isValid = val ? val.match(alphaNumericLimiter) : false;
    if (isValid) {
      setErrorText('');
    } else {
      setErrorText(
        'only alpha-numeric, hyphen(-), or underscore(_) characters are allowed'
      );
      isValid = false;
    }
    return isValid;
  };

  useEffect(() => {
    if (!initialized) {
      setGroupURL(props.shortname);
      if (props.shortname) {
        setCopyButtonDisabled(false);
      }
      validateInput(props.shortname);
      setInitialized(true);
    }
  }, [initialized, props.shortname]);

  return (
    <div style={{ display: 'block' }}>
      <span style={{ float: 'left', display: 'flex' }}>
        <span style={{ marginTop: '.5rem' }}>
          hippoed.com/groupsignon/&nbsp;
        </span>
        <span>
          <div>
            <Input
              value={groupURL}
              placeholder="shortname"
              onChangeCapture={handleShortNameChange}
            />
            {errorText && (
              <h6 style={{ color: 'red', fontSize: '1em' }}>{errorText}</h6>
            )}
          </div>
        </span>
        <span>
          <Button
            type="primary"
            disabled={submitUrlDisabled}
            onClick={e => {
              return handleShortNameSubmit(e, groupURL);
            }}
          >
            Submit
          </Button>
        </span>
      </span>
      <span style={{ float: 'right' }}>
        <Button
          type="primary"
          disabled={groupURL === '' || copyButtonDisabled}
          onClick={e => {
            return handleCopyClick(e, groupURL);
          }}
        >
          Copy to Clipboard
        </Button>
      </span>
    </div>
  );
}
