import _ from 'underscore';
import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Button, Table, Tag, Input, Select } from 'antd';

import moment from 'moment';

import { getTokenFromCookie } from '../../utils/cookie';
import BreadcrumbConstants from '../../routes/BreadcrumbConstants';
import ContentHeader from '../content/ContentHeader';
import { debounce } from 'lodash';

const DEFAULT_PAGE_SIZE = 20;

const { Option } = Select;

export default class Promotions extends React.Component {
  state = {
    promotions: [],
    products: [],
    selected: [],
    loading: false,
    searchTerm: '',
    currentPage: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    titleSortDirection: 'ASC',
    filterByProduct: []
  };

  componentDidMount() {
    this.setState({
      loading: true
    });
    this.getProducts();
    this.getPromotions();
  }

  promotionsBreadcrumb = [
    BreadcrumbConstants.HippoAdmin,
    BreadcrumbConstants.Promotions
  ];

  handleError(error) {
    this.setState({
      loading: false
    });
    window.setError(error);
  }

  getProducts() {
    fetch(`${process.env.REACT_APP_JSONAPI_SERVER}/api/product`, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${getTokenFromCookie()}`
      }
    })
      .then(response => {
        response.json().then(products => this.setState({ products }));
      })
      .catch(this.handleError);
  }

  getPromotions = debounce(() => {
    const url = new URL(
      `${process.env.REACT_APP_JSONAPI_SERVER}/api/promotion`
    );
    url.search = new URLSearchParams({
      search: this.state.searchTerm,
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      titleSortDirection: this.state.titleSortDirection,
      filterByProduct: this.state.filterByProduct.join(',')
    }).toString();

    fetch(url, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${getTokenFromCookie()}`
      }
    })
      .then(response => {
        const status = response.status;

        response.json().then(response => {
          if (status < 400) {
            this.setState({
              promotions: response,
              loading: false,
              totalResults:
                (response && response[0] && response[0].totalResults) || 0
            });
          } else {
            this.handleError(new Error(response.message));
          }
        });
      })
      .catch(this.handleError);
  }, 500);

  deletePromotion(id, title) {
    const answer = window.confirm(`Are you sure you want to delete "${title}"`);
    if (answer) {
      fetch(`${process.env.REACT_APP_JSONAPI_SERVER}/api/promotion/${id}`, {
        method: 'DELETE',
        credentials: 'same-origin',
        headers: {
          Authorization: `Bearer ${getTokenFromCookie()}`
        }
      })
        .then(this.getPromotions.bind(this))
        .catch(this.handleError);
    }
  }

  addSortAndFilterFields() {
    const productFilterList = [
      { text: 'Multiple Products', value: 'Multiple Products' }
    ];

    if (this.state.products) {
      this.state.products.forEach(product => {
        const promotionProductIds = [];
        this.state.promotions.forEach(promotion => {
          if (product.id === promotion.promotion.ProductId) {
            // add table's sort fields for product and title
            Object.assign(promotion, {
              productName: product.title,
              title: promotion.promotion.title
            });
          }
          // add sort fields when no ProductId exists (multiple products)
          !promotion.productName &&
            Object.assign(promotion, {
              productName: 'Multiple Products',
              title: promotion.promotion.title
            });

          promotionProductIds.push(promotion.promotion.ProductId);
        });

        if (promotionProductIds.includes(product.id)) {
          // create product list for the table's Product filter
          productFilterList.push({ text: product.title, value: product.title });
        }
      });
    }
    return productFilterList;
  }

  handleChange(pagination, filters, sorter, extra) {
    this.setState(
      {
        currentPage: pagination.current,
        pageSize: pagination.pageSize,
        loading: true,
        ...(sorter &&
          sorter.columnKey === 'title' && {
            titleSortDirection:
              (sorter.order && sorter.order === 'ascend') || !sorter.order
                ? 'ASC'
                : 'DESC'
          })
      },
      () => {
        this.getPromotions();
      }
    );
  }

  handleFilterByProduct(products) {
    this.setState({ filterByProduct: products, loading: true });
    this.getPromotions();
  }

  render() {
    const productFilterList = this.addSortAndFilterFields();

    return (
      <div>
        <ContentHeader
          breadCrumb={this.promotionsBreadcrumb}
          hasTitle={true}
          title="Promotions"
        />
        <Route
          render={props => (
            <Button
              color="primary"
              onClick={() => props.history.push('/promotion/create')}
            >
              New Promotion
            </Button>
          )}
        />
        <Input
          className="promotion-input"
          value={this.state.searchTerm}
          onChange={e => {
            this.setState({ searchTerm: e.target.value, loading: true });
            this.getPromotions();
          }}
          placeholder="Search by Title or Promotion Code"
        />
        <Select
          className="promotion-input"
          mode="multiple"
          allowClear={true}
          placeholder="Filter by Product"
          onChange={this.handleFilterByProduct.bind(this)}
        >
          {this.state.products
            .sort((a, b) => a.title.localeCompare(b.title))
            .map(product => (
              <Option key={product.id}>{product.title}</Option>
            ))}
        </Select>
        <Table
          style={{ backgroundColor: '#fff', marginTop: '20px' }}
          pagination={{
            defaultPageSize: this.state.pageSize,
            current: this.state.currentPage,
            position: ['bottomLeft'],
            total: this.state.totalResults
          }}
          onChange={(pagination, filters, sorter, extra) =>
            this.handleChange(pagination, filters, sorter, extra)
          }
          columns={[
            {
              title: 'Product',
              filters: productFilterList,
              onFilter: (value, record) => record.productName === value,
              key: 'product name',
              render: item => item.productName
            },
            {
              title: 'Title',
              key: 'title',
              sorter: true,
              sortOrder:
                this.state.titleSortDirection === 'ASC' ? 'ascend' : 'descend',
              defaultSortOrder: 'ascend',
              sortDirections: ['ascend', 'descend'],
              render: item => {
                return (
                  <Link to={'/promotion/' + item.promotion.id + '/view'}>
                    {item.promotion.title}
                  </Link>
                );
              }
            },
            {
              title: 'Active period',
              key: 'active period',
              render: item => {
                let node = null;

                const start = item.promotion.startedAt;
                const end = item.promotion.endedAt;

                if (!start && !end) {
                  node = <Tag color="green">Always active</Tag>;
                }
                if (start && end) {
                  node = (
                    <span>
                      {moment(start).format('MMMM Do YYYY, h:mm a')} to <br />
                      {moment(end).format('MMMM Do YYYY, h:mm a')}
                    </span>
                  );
                }
                if (start && !end) {
                  node = (
                    <span>
                      From {moment(start).format('MMMM Do YYYY, h:mm a')}
                    </span>
                  );
                }
                if (!start && end) {
                  node = (
                    <span>
                      Ending {moment(end).format('MMMM Do YYYY, h:mm a')}
                    </span>
                  );
                }

                return node;
              }
            },
            {
              title: 'Discount',
              key: 'discount',
              render: item => {
                let node = <Tag>None</Tag>;

                if (item.promotion.discountAmount) {
                  node = <span>${item.promotion.discountAmount}</span>;
                }
                if (item.promotion.discountPercentage) {
                  node = <span>{item.promotion.discountPercentage}%</span>;
                }

                return node;
              }
            },
            {
              title: 'Orders',
              key: 'orders',
              render: item => {
                return <span>{item.orders.length}</span>;
              }
            },
            {
              title: 'Total',
              key: 'total',
              render: item => {
                return <span>{Math.round(item.roiData.grossSalestotal)}</span>;
              }
            },
            {
              title: 'Delete',
              key: 'delete',
              render: item => {
                return (
                  <Button
                    type="danger"
                    onClick={() =>
                      this.deletePromotion.bind(this)(
                        item.promotion.id,
                        item.promotion.title
                      )
                    }
                  >
                    Delete
                  </Button>
                );
              }
            }
          ]}
          dataSource={_.sortBy(
            this.state.promotions,
            item => -item.promotion.title
          )}
          expandedRowRender={item => {
            return <span>{item.promotion.note || '(No notes to show)'}</span>;
          }}
          size="small"
          loading={this.state.loading}
        />
      </div>
    );
  }
}
