import PropTypes from 'prop-types';
import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, DatePicker } from 'antd';
import { Label } from 'reactstrap';
import moment from 'moment';

import AttachmentUpload from '../Upload/AttachmentUpload';
import './AttachmentCourseGuideItem.css';
import { useAttachmentMutator } from '../../containers/attachments/hooks/useAttachmentHooks';

const AttachmentCourseGuideItem = ({
  item,
  courseAttachmentBinding,
  onAttachmentUpdate,
  updateActivatedAt,
  updateExpiredAt,
  canEdit
}) => {
  const { replaceFileOnAttachment } = useAttachmentMutator();

  return (
    <div key={item.id} className="attachment-item-container">
      <div className="attachment-item">
        <Label>Current File Name: </Label>
        <AttachmentUpload
          filename={item.fileName}
          type="inline"
          onComplete={fileData => {
            replaceFileOnAttachment(fileData, item, onAttachmentUpdate);
          }}
          canEdit={canEdit}
        />
        <div>
          {item.signedUrl && (
            <Button href={item.signedUrl}>
              <DownloadOutlined />
              <span>Download</span>
            </Button>
          )}
        </div>
        <span className="attachment-item__last-updated-on">
          Last Updated On: {new Date(item.updatedAt).toDateString()}{' '}
        </span>
      </div>
      <div className="attachment-item__dates-container">
        <div className="attachment-item__activated-at">
          <Label className="attachment-item__activated-at-label">
            Activated at
          </Label>
          <DatePicker
            defaultValue={
              moment(courseAttachmentBinding.activatedAt)._isValid
                ? moment.utc(courseAttachmentBinding.activatedAt).startOf('day')
                : null
            }
            onChange={activatedAt =>
              updateActivatedAt(courseAttachmentBinding.id, activatedAt)
            }
          />
        </div>
        <div className="attachment-item__expired-at">
          <Label className="attachment-item__expired-at-label">
            Expired at
          </Label>
          <DatePicker
            defaultValue={
              moment(courseAttachmentBinding.expiredAt)._isValid
                ? moment.utc(courseAttachmentBinding.expiredAt).startOf('day')
                : null
            }
            onChange={expiredAt =>
              updateExpiredAt(courseAttachmentBinding.id, expiredAt)
            }
          />
        </div>
      </div>
    </div>
  );
};
AttachmentCourseGuideItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    key: PropTypes.string,
    updatedAt: PropTypes.string
  }).isRequired,
  onAttachmentUpdate: PropTypes.func,
  updateActivatedAt: PropTypes.func,
  updateExpiredAt: PropTypes.func,
  onDelete: PropTypes.func,
  showCmeFields: PropTypes.bool
};
AttachmentCourseGuideItem.defaultProps = {
  onAttachmentUpdate: null,
  onDelete: null
};
export default AttachmentCourseGuideItem;
