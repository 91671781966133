import { gql } from '@apollo/client';

const DELETE_ORGANIZATION_ADMIN_BINDING_MUTATION = gql`
  mutation DeleteOrganizationAdminBinding($id: UUID!) {
    deleteOrganizationAdminBinding(input: { id: $id }) {
      clientMutationId
    }
  }
`;

export default DELETE_ORGANIZATION_ADMIN_BINDING_MUTATION;
