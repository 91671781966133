import React from 'react';
import PropTypes from 'prop-types';
import { Input, DatePicker } from 'antd';
import classnames from 'classnames';
import moment from 'moment';

import './EditableLabel.css';

const getValueFromString = (value, type) => {
  if (value && type === 'date') {
    return moment(value);
  }

  return value;
};

const getStringFromValue = (value, type) => {
  if (value && type === 'date') {
    return value.utc().toISOString();
  }

  return value;
};

export default class EditableLabel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    onNextField: PropTypes.func,
    formatLabel: PropTypes.func,
    defaultValue: PropTypes.node,
    inputType: PropTypes.string,
    validation: PropTypes.instanceOf(RegExp),
    errorMessage: PropTypes.string,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    className: '',
    onChange: () => {},
    onNextField: () => {},
    formatLabel: undefined,
    defaultValue: '',
    inputType: 'input',
    validation: undefined,
    errorMessage: 'Invalid entry.',
    disabled: false
  };

  constructor(props) {
    super(props);
    this.input = React.createRef();

    this.state = {
      editing: false,
      defaultValue: getValueFromString(props.defaultValue, props.inputType),
      value: getValueFromString(props.defaultValue, props.inputType),
      hasError: false
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const stringValue = getStringFromValue(
      prevState.defaultValue,
      nextProps.inputType
    );
    if (nextProps.defaultValue !== stringValue) {
      return {
        defaultValue: getValueFromString(
          nextProps.defaultValue,
          nextProps.inputType
        ),
        value: getValueFromString(nextProps.defaultValue, nextProps.inputType)
      };
    }
    return null;
  }

  componentDidUpdate() {
    if (this.input.current !== null) {
      this.input.current.focus();
    }
  }

  defaultFormatLabel = value => {
    if (value === null || value === undefined) {
      return '(N/A)';
    }

    if (value && this.props.inputType === 'date') {
      return value.tz(process.env.REACT_APP_DISPLAY_TIMEZONE).format('ll');
    }

    return value;
  };

  handleFieldChange = (result, saveAfterEdit) => {
    let processedValue;
    if (this.props.inputType === 'date') {
      processedValue = result
        ? result
            .tz(process.env.REACT_APP_DISPLAY_TIMEZONE)
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .utc()
        : result;
    } else {
      processedValue = result.target.value;
    }

    this.setState(
      {
        value: processedValue
      },
      () => {
        if (saveAfterEdit) {
          this.saveEdits();
        }
      }
    );
  };

  startEditing = e => {
    e.stopPropagation();

    if (!this.props.disabled) {
      this.setState({ editing: true });
    }
  };

  saveEdits = () => {
    const stringValue = getStringFromValue(
      this.state.value,
      this.props.inputType
    );
    if (this.props.validation) {
      if (!this.props.validation.test(stringValue)) {
        this.setState({ hasError: true });
        return;
      }
    }
    this.setState({ hasError: false });

    this.setState({
      editing: false,
      defaultValue: this.state.value
    });
    this.props.onChange(stringValue);
  };

  discardEdits = () => {
    this.setState({
      editing: false,
      value: this.state.defaultValue,
      hasError: false
    });
  };

  handleBlur = () => {
    this.saveEdits();
  };

  handleKeyDown = event => {
    if (event.key === 'Enter') {
      this.saveEdits();
    } else if (event.key === 'Tab') {
      this.saveEdits();
      if (this.props.onNextField) {
        this.props.onNextField();
      }
    } else if (event.key === 'Escape') {
      this.discardEdits();
    }
  };

  handleKeyDownLabel = event => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      this.startEditing();
    }
  };

  render() {
    if (!this.state.editing) {
      return (
        <span
          onClick={this.startEditing}
          onKeyDown={this.handleKeyDownLabel}
          role="button"
          tabIndex={0}
          className={classnames('editable-label-label', this.props.className, {
            'editable-label__label-disabled': this.props.disabled
          })}
        >
          {this.props.formatLabel
            ? this.props.formatLabel(this.state.value)
            : this.defaultFormatLabel(this.state.value)}
        </span>
      );
    }

    const props = {
      ref: this.input,
      value: this.state.value,
      className: classnames('editable-label-input', {
        'has-error': this.state.hasError
      })
    };

    let input = null;
    if (this.props.inputType === 'input') {
      input = Input;
      props.onBlur = this.handleBlur;
      props.onChange = this.handleFieldChange;
    } else if (this.props.inputType === 'date') {
      input = DatePicker;
      props.onChange = value => {
        this.handleFieldChange(value, true);
      };
    } else {
      throw new Error(
        `Unsupported input type ${this.props.inputType} in EditableLabel.`
      );
    }

    return (
      <div className={this.props.className} onKeyDown={this.handleKeyDown}>
        {this.state.hasError && (
          <div className="editable-label-error">{this.props.errorMessage}</div>
        )}
        {React.createElement(input, props)}
      </div>
    );
  }
}
