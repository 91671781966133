import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Select } from 'antd';
import { Label } from 'reactstrap';

import { getTokenFromCookie } from '../../utils/cookie';

import './CmeCertificateDownload.css';

const findYears = startDates => {
  const years = [];
  if (startDates && startDates.length > 1) {
    const today = moment();
    const currentYear = today.year();
    let earliestStartDate = moment(startDates[0]);
    startDates.forEach(startDate => {
      if (moment(startDate).isBefore(earliestStartDate)) {
        earliestStartDate = moment(startDate);
      }
    });

    while (
      earliestStartDate.isBefore(today) &&
      earliestStartDate.year() !== currentYear
    ) {
      years.push(earliestStartDate.format('YYYY'));
      earliestStartDate = earliestStartDate.add(1, 'year');
    }
    years.push(today.format('YYYY'));
  }
  return years;
};

const CmeCertificateDownload = ({
  accountId,
  courses,
  startDates,
  onError
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedCourseIds, setSelectedCourseIds] = useState(null);
  const [loading, setLoading] = useState(false);
  const years = findYears(startDates);
  const token = getTokenFromCookie();

  const downloadCertificate = async (
    accountId,
    startDate,
    endDate,
    courses
  ) => {
    setLoading(true);

    const downloadCertificateUrl = generateCertificateUrl(
      accountId,
      startDate,
      endDate,
      courses,
      token
    );

    await fetch(downloadCertificateUrl, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
      .then(res => {
        if (res.status === 200) {
          return res.blob();
        } else if (res.status === 404) {
          onError(
            'No CME certificate found for these filters for this account.'
          );
        } else {
          onError(
            'There was an error getting the CME certificate for this account. Please contact the dev team for more help if needed.'
          );
        }
      })
      .then(async response => {
        if (response) {
          // Create a Blob from the PDF Stream
          const file = new Blob([response], { type: 'application/pdf' });
          // Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          // Create a "fake" anchor and click it to start a download
          const link = document.createElement('a');
          link.href = fileURL;

          link.download = 'hippo_cme.pdf';
          link.click();
          URL.revokeObjectURL(fileURL);
          setLoading(false);
        }
      });
    setLoading(false);
  };

  const generateCertificateUrl = (
    accountId,
    startDate,
    endDate,
    courses,
    token
  ) => {
    let certificateUrl = `${process.env.REACT_APP_JSONAPI_SERVER}/api/cme-certificate?accountId=${accountId}&userToken=${token}`;

    if (startDate) {
      certificateUrl += `&startDate=${startDate}`;
    }

    if (endDate) {
      certificateUrl += `&endDate=${endDate}`;
    }

    let courseQueryArrayString = '';
    if (courses && courses.length > 0) {
      courses.map(course => (courseQueryArrayString += `&course=${course}`));
      certificateUrl += courseQueryArrayString;
    }

    return certificateUrl;
  };

  return (
    <div className="cme-certificate-download">
      <div className="cme-certificate-download__start-year">
        <Label>Start Year (optional)</Label>
        <Select
          className="cme-certificate-download__start-year-select"
          onChange={value => setStartDate(value + '-01-01')}
        >
          {years.map(year => (
            <Select.Option key={year}>{year}</Select.Option>
          ))}
        </Select>
      </div>

      <div className="cme-certificate-download__end-year">
        <Label>End Year (optional)</Label>
        <Select
          className="cme-certificate-download__end-year-select"
          onChange={value => setEndDate(value + '-12-31')}
        >
          {years.map(year => (
            <Select.Option key={year}>{year}</Select.Option>
          ))}
        </Select>
      </div>

      <div className="cme-certificate-download__courses">
        <Label>Courses (optional)</Label>
        <Select
          mode="multiple"
          className="cme-certificate-download__courses-select"
          onChange={value => setSelectedCourseIds(value)}
        >
          {courses.map(course => (
            <Select.Option key={course.id}>{course.title}</Select.Option>
          ))}
        </Select>
      </div>

      <Button
        onClick={() =>
          downloadCertificate(accountId, startDate, endDate, selectedCourseIds)
        }
      >
        {loading ? 'Loading...' : 'Download CME Certificate'}
      </Button>
    </div>
  );
};

CmeCertificateDownload.propTypes = {
  accountId: PropTypes.string,
  courses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string
    })
  ),
  startDates: PropTypes.arrayOf(PropTypes.string),
  onError: PropTypes.func
};

CmeCertificateDownload.defaultProps = {
  accountId: null,
  courses: [],
  startDates: [],
  onError: message => console.log(message)
};

export default CmeCertificateDownload;
