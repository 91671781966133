import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';

export function LectureRowLastReviewedAtPicker({ lecture, onChange }) {
  const [isEditing, setIsEditing] = useState(false);

  if (isEditing) {
    // EditableLabel strips time from the date (and other quirks)
    // so better to use the raw DatePicker for lastReviewedAt
    return (
      <DatePicker
        defaultValue={
          lecture.attributes.lastReviewedAt
            ? moment(lecture.attributes.lastReviewedAt)
            : null
        }
        onKeyDown={event => {
          if (event.key === 'Escape') {
            setIsEditing(false);
          }
        }}
        onChange={value => {
          onChange(value);
          setIsEditing(false);
        }}
      />
    );
  }

  return (
    <span onClick={() => setIsEditing(true)}>
      {lecture.attributes.lastReviewedAt
        ? new Intl.DateTimeFormat('en-US', {
            dateStyle: 'medium'
          }).format(new Date(lecture.attributes.lastReviewedAt))
        : 'N/A'}
    </span>
  );
}
