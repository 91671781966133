import React from 'react';
import { Form, Input, Select } from 'antd';
import SortableList from '../SortableList';
import { DeleteTwoTone, MenuOutlined } from '@ant-design/icons';

const { Option } = Select;

export default function SuggestedItems({
  activeItem,
  allItems,
  handleCreate,
  handleReorder,
  handleDelete
}) {
  return (
    <div>
      <h2>Sales</h2>
      <div className="item__field-sections">
        <Form.Item
          label="People Also Purchased (Suggested Items))"
          name="suggestedItems"
        >
          <Input.Group compact={true}>
            <SortableList
              items={activeItem.suggestedItemsList}
              itemType="suggestedItems"
              className="item__sortable-list-container--suggested"
              onDragEnd={e => handleReorder(e)}
              renderItem={(suggestedItem, index) => (
                <div
                  key={index}
                  className="item__sortable-list-item--suggested"
                >
                  <MenuOutlined style={{ width: '5%' }} twoToneColor="red" />
                  <p key={index} className="item__sortable-list-item__text">
                    {suggestedItem.itemSuggested.title} (sku:{' '}
                    {suggestedItem.itemSuggested.sku})
                  </p>
                  <DeleteTwoTone
                    style={{ width: '5%' }}
                    onClick={() =>
                      handleDelete(suggestedItem.id, suggestedItem.displayOrder)
                    }
                    twoToneColor="red"
                    size="large"
                  />
                </div>
              )}
            />
            <Select
              defaultValue={null}
              className="item__select--suggested-item"
              onChange={value => handleCreate(value)}
              value={null}
              placeholder="+ Select to Add More Suggested Items"
            >
              {allItems &&
                allItems.map(item => (
                  <Option
                    key={item.id}
                    value={item.id}
                    disabled={
                      item.id === activeItem.id ||
                      activeItem.suggestedItemsList.find(suggestedItem => {
                        return suggestedItem.itemSuggested.id === item.id;
                      })
                    }
                  >
                    {item.title} (sku: {item.sku})
                  </Option>
                ))}
            </Select>
          </Input.Group>
        </Form.Item>
      </div>
    </div>
  );
}
