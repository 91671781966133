import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactPlayer from 'react-player';
import { FileImageOutlined } from '@ant-design/icons';
import { Button, Empty } from 'antd';
import Heading from './Heading';
import { mediaUrl, mediaSignedUrl } from '../utils/media';

import './MediaInput.css';

// View Full Size button only shows when selectedMedia has a posterFrame
// posterFrame field is accepted separately to allow blob preview to override while AWS upload is occuring

const MediaInput = ({
  selectedMedia,
  isSignedUrl,
  posterFrame,
  onSelectMedia,
  onCapturePoster,
  onDetach,
  isSavingPoster
}) => (
  <>
    {selectedMedia ? (
      <ReactPlayer
        id="lecture-media"
        className="media-input__player"
        controls={true}
        url={
          isSignedUrl ? mediaSignedUrl(selectedMedia) : mediaUrl(selectedMedia)
        }
      />
    ) : (
      <Empty description={<span>No video selected.</span>} />
    )}

    <div className="media-input__media-toolbar">
      {selectedMedia ? (
        <>
          <Button onClick={onSelectMedia}>Change Video</Button>
          <Button disabled={isSavingPoster} onClick={onCapturePoster}>
            Set Poster Frame
          </Button>
          <Button type="danger" onClick={onDetach}>
            Detach Existing Video
          </Button>
        </>
      ) : (
        <Button onClick={onSelectMedia}>Select Video</Button>
      )}
    </div>

    {selectedMedia && (
      <>
        <div className="media-input__original-filename">
          <Heading>Original Filename</Heading>
          <div className="media-input__original-filename-container">
            <div>{selectedMedia.originalFileName}</div>
          </div>
        </div>
        <div className="media-input__poster-frame">
          <Heading>Poster Frame</Heading>
          <div className="media-input__poster-frame-image-container">
            <div
              className={classNames('media-input__poster-frame-image', {
                'media-input__poster-frame-image--loading': isSavingPoster
              })}
            >
              {posterFrame ? (
                <img src={posterFrame} alt="" />
              ) : (
                <FileImageOutlined
                  style={{ fontSize: '70px', margin: '.5em' }}
                />
              )}
            </div>
          </div>
          {selectedMedia && selectedMedia.posterFrameUrl && (
            <div className="media-input__poster-frame-toolbar">
              <Button
                disabled={isSavingPoster}
                href={selectedMedia.posterFrameUrl}
                target="_blank"
              >
                View Full Size Poster Frame
              </Button>
            </div>
          )}
        </div>
      </>
    )}
  </>
);

MediaInput.propTypes = {
  selectedMedia: PropTypes.shape({
    originalFileName: PropTypes.string,
    posterFrame: PropTypes.string,
    createdAt: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string
    ])
  }),
  posterFrame: PropTypes.string,
  onSelectMedia: PropTypes.func,
  onCapturePoster: PropTypes.func,
  onDetach: PropTypes.func,
  isSavingPoster: PropTypes.bool
};

MediaInput.defaultProps = {
  selectedMedia: {},
  posterFrame: '',
  onSelectMedia: () => {},
  onCapturePoster: () => {},
  onDetach: () => {},
  isSavingPoster: false
};

export default MediaInput;
