import _ from 'underscore';
import captureFrame from 'capture-frame';
import uuidv4 from 'uuid/v4';
import uploadToS3 from './uploadToS3';
import * as API from '../API';

//
// Useful functions for dealing with Media objects
//

export function mediaSignedUrl(media) {
  const encoding = _.find(
    media.mediaEncodingsList,
    theEncoding => theEncoding.title === 'Automatic'
  );

  if (!encoding) {
    return '';
  }

  return encoding.signedUrl;
}

export function mediaUrl(media) {
  const encoding = _.find(
    media.mediaEncodings,
    theEncoding => theEncoding.title === 'Automatic'
  );

  if (!encoding) {
    return '';
  }

  return encoding.url;
}

export const posterFrameUrl = mediaOrUrl =>
  _.isObject(mediaOrUrl)
    ? mediaOrUrl.posterFrame
    : `${process.env.REACT_APP_MEDIA_CDN}/${mediaOrUrl}`;

function transformJsonApiDataToMedia(data) {
  return {
    ...data.attributes,
    id: data.id
  };
}

// fetches all Media objects from the api including all data necessary for AttachMediaModal to display
export function fetchAllMedia(url) {
  return API.raw('GET', url || 'media', '')
    .then(async response => {
      const mediaData = response.data;
      const fixedMedia = mediaData.data.map(transformJsonApiDataToMedia);

      if (mediaData.links.next) {
        const nextPage = await fetchAllMedia(mediaData.links.next);
        return fixedMedia.concat(nextPage);
      }
      return fixedMedia;
    })
    .catch(window.setError);
}

export function fetchMediaById(mediaId) {
  return API.graphql({
    query: `
    {
      media(id: "${mediaId}") {
        id,
        posterFrame,
        scrubberImage,
        scrubberImageVTT,
        mediaEncodings(title: "Automatic") {
          id
          title
          url
        }
      }
    }`
  }).then(response => response.data);
}

// Given a CSS Selector for a <video> element and Media object
// saves a screen grab of the <video> element to AWS and returns the public url
// An image blob, and then the full url are returned to the progressCallback as work is done
export const savePosterFrame = async (
  videoSelector,
  media,
  successCallback,
  failureCallback,
  progressCallback
) => {
  const buf = captureFrame(videoSelector, 'jpeg');
  const frame = new window.Blob([buf], { type: 'image/jpeg' });
  const dataUri = window.URL.createObjectURL(frame);
  progressCallback(dataUri);

  const access = media.isPublic ? 'public' : 'private';

  // Include UUIDv4 in file key to deal with cache invalidation.
  const key = `media/${access}/${media.id}/poster-frame-${uuidv4()}.jpg`;

  await uploadToS3(key, frame);

  // Update media posterFrame field.
  API.media
    .update({
      id: media.id,
      attributes: { posterFrame: key }
    })
    .then(response => {
      const updatedMedia = transformJsonApiDataToMedia(response.data);
      successCallback(updatedMedia);
    })
    .catch(failureCallback);
};
