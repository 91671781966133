import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table } from 'antd';
import capitalize from 'lodash/capitalize';
import * as API from '../API';
import MessageLoader from '../components/MessageLoader';
import BreadcrumbConstants from '../routes/BreadcrumbConstants';
import ContentHeader from './content/ContentHeader';

export default class Courses extends React.Component {
  static propTypes = {
    courseType: PropTypes.string
  };

  state = {
    error: '',
    courses: [],
    courseTypes: [],
    loading: false
  };

  componentDidUpdate(prevProps) {
    if (this.props.courseType !== prevProps.courseType) {
      this.setState({
        loading: true
      });
      this.setState({ courses: [] });
      this.loadCourses(this.props.courseType);
    }
  }

  async componentDidMount() {
    this.setState({
      loading: true
    });
    await this.loadCourseTypes();
    this.loadCourses(this.props.courseType);
  }

  async loadCourseTypes() {
    const courseTypes = Object.assign(
      {},
      ...(await API.courseType.all()).data.map(courseType => ({
        [courseType.attributes.shortname]: courseType.id
      }))
    );
    this.setState({ courseTypes });
  }

  async loadCourses(courseType) {
    const filter = courseType
      ? { courseType: this.state.courseTypes[courseType] }
      : {};
    const courses = (
      await API.fetchAllPages(
        await API.course.where({
          filter,
          options: {
            sort: 'title'
          }
        })
      )
    ) // key props keep React and the antd.Table happy
      .map(course => Object.assign({ key: course.id }, course));
    const defaultCourseId = courses[0] && courses[0].id;

    this.setState({
      loading: false
    });
    this.setState({ courses, defaultCourseId });
  }

  render() {
    const tableColumns = [
      {
        title: 'Title',
        dataIndex: ['attributes', 'title'],
        key: 'title'
      },
      {
        title: 'Select',
        key: 'select',
        render: (text, record) => (
          <Link to={'/course/' + record.id}>Select</Link>
        )
      }
    ];

    const coursesBreadcrumb = [
      BreadcrumbConstants.HippoAdmin,
      BreadcrumbConstants.Courses,
      {
        title: capitalize(this.props.courseType)
      }
    ];

    return (
      <div>
        <ContentHeader breadCrumb={coursesBreadcrumb} />
        {!this.state.loading && (
          <Table
            style={{ backgroundColor: '#fff' }}
            dataSource={this.state.courses}
            columns={tableColumns}
            pagination={false}
            onRow={record => ({
              onClick: () => {
                window.location = '/course/' + record.id;
              }
            })}
          />
        )}
        {this.state.loading && <MessageLoader withRandom={true} />}
        <br />
      </div>
    );
  }
}
