import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { EditorState, Modifier } from 'draft-js';

class PlaceholderToolbar extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.instanceOf(EditorState),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        pattern: PropTypes.instanceOf(RegExp),
        boolean: PropTypes.boolean,
        example: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    )
  };

  static defaultProps = {
    onChange: () => {},
    options: [],
    editorState: undefined
  };

  constructor(props) {
    super(props);
    this.addPlaceholder = this.addPlaceholder.bind(this);
    this.state = {
      open: false,
      options: props.options ? props.options : []
    };
  }

  openPlaceholderDropdown = () => this.setState({ open: !this.state.open });

  addPlaceholder = placeholder => {
    const { editorState, onChange } = this.props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      placeholder,
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  };

  render() {
    const listItems = this.state.options.map(item => (
      <li
        onClick={() => this.addPlaceholder(item.value)}
        key={item.key}
        className="rdw-dropdownoption-default placeholder-li"
      >
        {item.text}
      </li>
    ));

    return (
      <div
        onClick={this.openPlaceholderDropdown}
        className="rdw-block-wrapper"
        aria-label="rdw-block-control"
      >
        <div
          className="rdw-dropdown-wrapper rdw-block-dropdown"
          aria-label="rdw-dropdown"
        >
          <div className="rdw-dropdown-selectedtext" title="Placeholders">
            <span>Placeholder</span>
            <div
              className={`rdw-dropdown-caretto${
                this.state.open ? 'close' : 'open'
              }`}
            />
          </div>
          <ul
            className={`rdw-dropdown-optionwrapper ${
              this.state.open ? '' : 'placeholder-ul'
            }`}
          >
            {listItems}
          </ul>
        </div>
      </div>
    );
  }
}

export default PlaceholderToolbar;
