import _ from 'underscore';
import React from 'react';
import { Alert, Button, Col, Progress, Row } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { Breadcrumb } from 'antd';

import { Link } from 'react-router-dom';

import Step1 from './createOrderSteps/Step1';
import Step2 from './createOrderSteps/Step2';
import Step3 from './createOrderSteps/Step3';

import { getTokenFromCookie } from '../utils/cookie';

const LOADING_IMAGE = require('../images/loading.gif');

export default class OrderSearch extends React.Component {
  state = {
    step: 0,
    loading: false,
    receipt: null,
    error: null,
    componentsForSteps: [Step1, Step2, Step3],
    hostedFieldsInstance: null,
    namesForSteps: ['Account', 'Product', 'Payment'],

    /* NOTE: This is the data that is accumulated over the course of the
     * purchase steps. Each step should return an object that updates this
     * object. The "purchaseData" attribute will be modified slightly before
     * being passed onto the pay server.
     */
    purchaseData: {
      // The "account" is the account (obj) being purchased for. It should come from
      // Step1
      account: null,

      // The single item being purchased
      // Step2
      item: null,

      // The one-time nonce for this purchase.
      // Step3
      paymentMethodNonce: null,

      // The single promotion code (if any) provided in Step 3
      promotionCode: null,

      // A price that is verified in the checkout flow
      price: null,

      // step 3 - if set, it overrides all other amounts
      isPrepaid: false,

      // step 3
      adminComments: ''
    }
  };

  async verifyAndSubmitPurchase() {
    try {
      const promotionCodes = this.state.purchaseData.promotionCode
        ? [this.state.purchaseData.promotionCode]
        : [];

      const requestData = {
        adminComments: this.state.purchaseData.adminComments,
        items: [this.state.purchaseData.item.id],
        price: `${this.state.purchaseData.price}`, // API is expecting price as a string
        promotionCodes,
        isAdminPurchase: true
      };

      if (this.state.purchaseData.isPrepaid) {
        requestData.isPrepaid = true;
      }

      if (this.state.purchaseData.paymentMethodNonce) {
        requestData.paymentMethodNonce = this.state.purchaseData.paymentMethodNonce;
      }

      this.setState({ loading: true });
      const purchaseResponse = await fetch(
        `${process.env.REACT_APP_JSONAPI_SERVER}/api/purchase?forAccountId=${this.state.purchaseData.account.id}`,
        {
          method: 'POST',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getTokenFromCookie()}`
          },
          body: JSON.stringify(requestData)
        }
      );
      if (!purchaseResponse.ok) {
        const errorData = await purchaseResponse.json();
        throw new Error(
          `An error occurred creating this order: ${
            errorData && errorData.error
              ? errorData.error
              : 'Please try again later, or contact a developer for assistance.'
          }`
        );
      }
      const data = await purchaseResponse.json();
      this.setState({ receipt: data.receipt, loading: false });
    } catch (e) {
      this.setState({ loading: false, error: e });
    }
  }

  render() {
    if (this.state.componentsForSteps.length === 0) {
      throw new Error(
        'There should be at least one step involved in creating orders.'
      );
    }

    if (
      this.state.step < 0 ||
      this.state.step > this.state.componentsForSteps.length - 1
    ) {
      throw new Error(
        "Trying to access an OrderCreate step that doesn't exist"
      );
    }

    const StepComponent = this.state.componentsForSteps[this.state.step];
    const stepName = this.state.namesForSteps[this.state.step];
    const stepPercent = Math.round(
      ((this.state.step + 1) / this.state.componentsForSteps.length) * 100
    );

    return (
      <div>
        <Row>
          <Col>
            <Breadcrumb style={{ padding: '18px 0' }}>
              <Breadcrumb.Item>
                <Link to="/dashboard">Hippo Admin</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active="true">
                <Link to="/order/create">Order Create</Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row style={{ marginBottom: 20 }}>
          <Col>
            <div className="text-center">
              Step {this.state.step + 1} - {stepName}
            </div>
            <Progress animated={true} value={stepPercent} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>
              Create order: Step {this.state.step + 1} of 3 - {stepName}
            </h3>
          </Col>
        </Row>
        <Row>
          <Col>
            {this.state.error ? (
              <Alert color="danger">{this.state.error.message}</Alert>
            ) : null}
          </Col>
        </Row>
        <StepComponent
          purchaseData={this.state.purchaseData}
          onComplete={data => {
            const purchaseData = this.state.purchaseData;
            _.extend(purchaseData, data);
            this.setState({ purchaseData });

            if (this.state.step < this.state.componentsForSteps.length - 1) {
              this.setState({ step: this.state.step + 1 });
            } else {
              this.verifyAndSubmitPurchase();
            }
          }}
        />
        {this.state.loading ? <img src={LOADING_IMAGE} alt="loading" /> : null}
        {this.state.receipt !== null ? (
          <Modal
            isOpen={this.state.receipt !== null}
            modalTransition={{ timeout: 700 }}
            backdropTransition={{ timeout: 1300 }}
          >
            <ModalHeader>Order Placed</ModalHeader>
            <ModalBody>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'center'
                }}
              >
                <img
                  style={{ width: 64, margin: 20 }}
                  src={require('./checkmark.png')}
                  alt="checkmark"
                />
                <table>
                  <tbody>
                    <tr>
                      <td style={{ paddingBottom: 12 }}>Account Information</td>
                      <td />
                    </tr>
                    <tr>
                      <td style={{ paddingRight: 50 }}>
                        <strong>Account ID</strong>
                      </td>
                      <td>{this.state.receipt.order.AccountId}</td>
                    </tr>
                    <tr>
                      <td style={{ paddingRight: 50 }}>
                        <strong>Email</strong>
                      </td>
                      <td>{this.state.receipt.order.email}</td>
                    </tr>
                    <tr>
                      <td style={{ paddingTop: 20, paddingBottom: 12 }}>
                        Order Information
                      </td>
                      <td />
                    </tr>
                    <tr>
                      <td style={{ paddingRight: 50 }}>
                        <strong>Order ID</strong>
                      </td>
                      <td>
                        <Link to={`/orders/${this.state.receipt.order.id}`}>
                          {this.state.receipt.order.id}
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingRight: 50 }}>
                        <strong>Tx ID</strong>
                      </td>
                      <td>{this.state.receipt.receipt.paymentMethodTxId}</td>
                    </tr>
                    <tr>
                      <td style={{ paddingRight: 50 }}>
                        <strong>Original total</strong>
                      </td>
                      <td>{formatMoney(this.state.receipt.order.itemTotal)}</td>
                    </tr>
                    <tr>
                      <td style={{ paddingRight: 50 }}>
                        <strong>Promotion total</strong>
                      </td>
                      <td>
                        {formatMoney(this.state.receipt.order.promotionTotal)}
                      </td>
                    </tr>
                    <tr>
                      {this.state.receipt.order.isPrepaid ? (
                        <td style={{ paddingRight: 50 }}>
                          <strong>Amount charged</strong>
                        </td>
                      ) : (
                        <td style={{ paddingRight: 50 }}>
                          <strong>Amount prepaid</strong>
                        </td>
                      )}
                      <td>{formatMoney(this.state.receipt.order.total)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => window.location.reload()}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        ) : null}
      </div>
    );
  }
}

function formatMoney(amt) {
  return '$' + amt.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}
