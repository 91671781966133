import axios from 'axios';
import { ResourceClient } from '@reststate/client';

import { getTokenFromCookie } from './utils/cookie';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_JSONAPI_SERVER,
  headers: {
    'Content-Type': 'application/json'
  }
});

httpClient.interceptors.request.use(config => {
  const token = getTokenFromCookie();

  if (!token) {
    delete config.headers.Authorization;
  } else {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

const makeClient = name => new ResourceClient({ name, httpClient });

// url can be relative to REACT_APP_JSONAPI_SERVER or an absolute url
export const raw = (method, url, data, timeout = null) => {
  if (timeout === null) {
    return httpClient.request({ method, url, data });
  } else {
    return httpClient.request({ method, url, data, timeout });
  }
};

export const graphql = data => raw('post', '', data);
export const sequelizeGraphql = data =>
  raw('post', '/graphql', data).then(result => result.data.data);
export const graphileGraphql = data =>
  raw('post', '/graph', data).then(result => result.data.data);

export const account = makeClient('account');

export const course = makeClient('course');
export const courseAttachmentBinding = makeClient('courseAttachmentBinding');
export const courseType = makeClient('courseType');
export const topicGroup = makeClient('topicGroup');
export const topic = makeClient('topic');
export const lecture = makeClient('lecture');
export const lectureAttachmentBinding = makeClient('lectureAttachmentBinding');
export const lectureFacultyBinding = makeClient('lectureFacultyBinding');
export const episode = makeClient('episode');
export const playlistType = makeClient('playlistType');
export const episodeChapterBinding = makeClient('episodeChapterBinding');
export const chapter = makeClient('chapter');
export const topicAttachmentBinding = makeClient('topicAttachmentBinding');
export const chapterAttachmentBinding = makeClient('chapterAttachmentBinding');
export const chapterFacultyBinding = makeClient('chapterFacultyBinding');
export const chapterEditorBinding = makeClient('chapterEditorBinding');
export const faculty = makeClient('faculty');
export const comment = makeClient('comment');
export const courseModeratorBinding = makeClient('courseModeratorBinding');
export const subscription = makeClient('subscription');
export const subscriptionTemplate = makeClient('subscriptionTemplate');
export const pearl = makeClient('pearl');
export const media = makeClient('media');
export const attachment = makeClient('attachment');
export const assessment = makeClient('assessment');
export const assessmentQuestionBinding = makeClient(
  'assessmentQuestionBinding'
);
export const image = makeClient('image');
export const tag = makeClient('tag');
export const tagType = makeClient('tagType');
export const chapterTagBinding = makeClient('chapterTagBinding');
export const report = makeClient('report');
export const cmeDisclosure = makeClient('cmeDisclosure');
export const cmeBucket = makeClient('cmeBucket');
export const cmeAccreditor = makeClient('cmeAccreditor');
export const certificate = makeClient('certificate');
export const question = makeClient('question');
export const productLink = makeClient('productLink');
export const questionProductLinkBinding = makeClient(
  'questionProductLinkBinding'
);
export const questionAttachmentBinding = makeClient(
  'questionAttachmentBinding'
);
export const questionUniversalTagBinding = makeClient(
  'questionUniversalTagBinding'
);
export const questionImageBinding = makeClient('questionImageBinding');
export const courseTagTypeBinding = makeClient('courseTagTypeBinding');

export const program = makeClient('program');
export const programAccountBinding = makeClient('programAccountBinding');
export const programCourseBinding = makeClient('programCourseBinding');
export const programMemberCourseBinding = makeClient(
  'programMemberCourseBinding'
);
export const profession = makeClient('profession');
export const specialty = makeClient('specialty');
export const country = makeClient('country');
export const provState = makeClient('provState');
export const accountAddress = makeClient('accountAddress');
export const accountSpecialtyBinding = makeClient('accountSpecialtyBinding');
export const note = makeClient('note');
export const organizationNoteBinding = makeClient('organizationNoteBinding');
export const programNoteBinding = makeClient('programNoteBinding');
export const accountNoteBinding = makeClient('accountNoteBinding');
export const noteAttachmentBinding = makeClient('noteAttachmentBinding');

export const topicTagBinding = makeClient('topicTagBinding');
export const accountRating = makeClient('accountRating');
export const product = makeClient('product');
export const item = makeClient('item');
export const price = makeClient('price');
export const itemIncludes = makeClient('itemIncludes');
export const bundle = makeClient('bundle');
export const bundleItemBinding = makeClient('bundleItemBinding');
export const suggestedItem = makeClient('suggestedItem');
export const association = makeClient('association');
export const associationItemBinding = makeClient('associationItemBinding');

// Recursively fetch all JSONAPI pages in the promise's links.next response.
// `apiPromise` may be a raw Axios promise or a @reststate/client promise (which returns the jsonapi response directly)
// Note that this only builds the data array, it does not capture included objects.
export const fetchAllPages = async apiPromise => {
  try {
    let response = await apiPromise;
    // If this was a raw axios promise, get the jsonapi response
    if (!response.jsonapi) {
      response = response.data;
    }

    let data = response.data;
    if (response.links.next) {
      const nextPage = await fetchAllPages(raw('get', response.links.next, ''));
      data = data.concat(nextPage);
    }
    return data;
  } catch (e) {
    throw e;
  }
};

// Accepts a JSONAPI Response.
// Flattens attributes and included objects into resource objects, including nested included objects.
export const simplifyResource = (resource, skipExpandRelations = []) => {
  if (resource.data instanceof Array) {
    return resource.data.map(data =>
      simplifyResource({ data, included: resource.included })
    );
  } else {
    const findRelations = (relationship, included, skipExpandRelations) => {
      if (!relationship) {
        return null; // return null for null relationships
      } else if (relationship instanceof Array) {
        return relationship.map(related =>
          findRelations(related, included, skipExpandRelations)
        );
      } else {
        const related = included.find(obj => obj.id === relationship.id);
        // reecursivley simplify related object if included, otherwise return original {id,type} relation object
        return related
          ? simplifyResource({ data: related, included }, skipExpandRelations)
          : relationship;
      }
    };

    const obj = resource.data;
    const included = resource.included || [];
    const relationships = Object.keys(obj.relationships || [])
      .filter(key => skipExpandRelations.indexOf(key) === -1) // Don't expand relationships that have already been expanded... prevent infinite recursion
      .map(key => ({
        [key]: findRelations(obj.relationships[key].data, included, [
          ...skipExpandRelations,
          key
        ])
      }));

    return Object.assign(
      { id: obj.id, _original: obj },
      obj.attributes,
      ...relationships
    );
  }
};

export const fetchAllAndSimplify = async apiPromise => {
  try {
    let response = await apiPromise;
    // If this was a raw axios promise, get the jsonapi response
    if (!response.jsonapi) {
      response = response.data;
    }

    let data = simplifyResource(response);
    if (response.links.next) {
      const nextPage = await fetchAllAndSimplify(
        raw('get', response.links.next, '')
      );
      data = data.concat(nextPage);
    }
    return data;
  } catch (e) {
    throw e;
  }
};
