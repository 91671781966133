import { gql } from '@apollo/client';

const UPDATE_ACTIVATEDAT_COURSEATTACHMENTBINDING_MUTATION = gql`
  mutation UpdateActivatedAtCourseAttachmentBinding(
    $courseAttachmentBindingId: UUID!
    $activatedAt: Datetime
    $updatedAt: Datetime!
  ) {
    updateCourseAttachmentBinding(
      input: {
        patch: { activatedAt: $activatedAt, updatedAt: $updatedAt }
        id: $courseAttachmentBindingId
      }
    ) {
      clientMutationId
    }
  }
`;

const UPDATE_EXPIREDAT_COURSEATTACHMENTBINDING_MUTATION = gql`
  mutation UpdateExpiredAtCourseAttachmentBinding(
    $courseAttachmentBindingId: UUID!
    $expiredAt: Datetime
    $updatedAt: Datetime!
  ) {
    updateCourseAttachmentBinding(
      input: {
        patch: { expiredAt: $expiredAt, updatedAt: $updatedAt }
        id: $courseAttachmentBindingId
      }
    ) {
      clientMutationId
    }
  }
`;

export {
  UPDATE_ACTIVATEDAT_COURSEATTACHMENTBINDING_MUTATION,
  UPDATE_EXPIREDAT_COURSEATTACHMENTBINDING_MUTATION
};
