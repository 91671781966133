import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AccountSearchPage from '../containers/AccountSearchPage';
import Account from '../containers/Account';
import AccountCme from '../containers/AccountCme';
import Faculty from '../containers/Faculty';
import FacultyMember from '../containers/account/FacultyMember';

const AccountRoutes = () => {
  return (
    <>
      <Switch>
        <Route
          exact={true}
          path="/account/search"
          component={AccountSearchPage}
        />
        <Route exact={true} path="/account/:id" component={Account} />
      </Switch>
      <Route
        exact={true}
        path="/account/:id/cme/:courseId?"
        component={AccountCme}
      />
      <Route exact={true} path="/accounts/faculty" component={Faculty} />
      <Route
        exact={true}
        path="/accounts/faculty/:id"
        component={FacultyMember}
      />
    </>
  );
};

export default AccountRoutes;
