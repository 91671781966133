import { gql } from '@apollo/client';

const CREATE_ITEM_INCLUDE_MUTATION = gql`
  mutation CreateItemInclude(
    $id: UUID!
    $itemId: UUID!
    $icon: String!
    $text: String!
    $displayOrder: Int!
    $createdAt: Datetime!
    $updatedAt: Datetime!
  ) {
    createItemInclude(
      input: {
        itemInclude: {
          id: $id
          itemId: $itemId
          icon: $icon
          text: $text
          displayOrder: $displayOrder
          createdAt: $createdAt
          updatedAt: $updatedAt
        }
      }
    ) {
      itemInclude {
        id
        icon
        text
        displayOrder
        itemId
      }
    }
  }
`;

const UPDATE_ITEM_INCLUDE_MUTATION = gql`
  mutation UpdateItemInclude(
    $id: UUID!
    $icon: String
    $text: String
    $displayOrder: Int
    $updatedAt: Datetime
  ) {
    updateItemInclude(
      input: {
        patch: {
          icon: $icon
          text: $text
          displayOrder: $displayOrder
          updatedAt: $updatedAt
        }
        id: $id
      }
    ) {
      clientMutationId
    }
  }
`;

const DELETE_ITEM_INCLUDE_MUTATION = gql`
  mutation DeleteItemInclude($id: UUID!) {
    deleteItemInclude(input: { id: $id }) {
      clientMutationId
    }
  }
`;

export {
  CREATE_ITEM_INCLUDE_MUTATION,
  UPDATE_ITEM_INCLUDE_MUTATION,
  DELETE_ITEM_INCLUDE_MUTATION
};
